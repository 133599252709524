import { useEffect, useState } from 'react';
import specialInputsDrugManufacturers from '../../../specialDrugsManufacturer.json';

import { FormInput } from '@commons/components';
import InfoMessage from '@commons/components/Orders/InfoMessage/InfoMessage';
import FormInputPicker from '@commons/EXComponentsLibrary/FormInputPicker/FormInputPicker';
import { EX6370 } from 'config/flags';
import { useModalCredentialsContext } from 'context/ModalCredentials/ModalCredentials-context';
import { t } from 'i18next';
import {
    requiresAvailableBranches,
    requiresCatalog,
    requiresClientType,
    requiresDeposit,
    requiresDispatchAddresses,
    requiresPaymentMethod,
} from 'utils/drugManufacturers/specialDrugs';
import { TypeConnection } from 'utils/enums/InitialConfig';
import { useUser } from 'utils/hooks';
import usePaymentMethods from 'utils/hooks/usePaymentMethods';
import { DrugManufacturersId } from 'utils/interfaces';

const NewSpecialFormManufacturedV4 = ({
    idDrugManufactered,
    typeConnectionName,
    selectedAddressInOtherPos,
    pointOfSaleAddressName,
    catalogs,
    formValue,
    formError,
    depositsByDrugManufacturer,
    clientTypes,
    availableBranches,
    setAvailableBranches,
    setDisableUserAndPasswordInput,
    pointOfSaleId,
    dispatchAddresses,
    setDispatchAddresses,
    paymentMethods,
    setPaymentMethods,
    setErrorMessage,
    disableUserAndPasswordInput,
}) => {
    const { user } = useUser({});
    const { setFormValue } = useModalCredentialsContext();
    const { getPaymentMethods, results } = usePaymentMethods();

    const [formCredential, setFormCredential] = useState(null);
    const [prevDrugId, setPrevDrugId] = useState(null); // Guardar el drugId previo
    const [prevCredentials, setPrevCredentials] = useState(null); // Guardar las credenciales previas

    const { isSaveSuccess, isLoadingLoginExtraInformation, usePostApiCredential } = useModalCredentialsContext();

    const { loginExtraInformation, isError, mutateRequestInformation } = usePostApiCredential(formCredential);

    const EX5305 = user?.EX5305;
    const EX5877 = user?.EX5877;
    const EX5990 = user?.EX5990;
    const EX6090 = user?.EX6090;

    // exclude CofarmenMendoza and CofarmenSanJuan because the scrapper now requires a different value in client_identifier
    const filteredSpecialInputs = Object.fromEntries(
        Object.entries(specialInputsDrugManufacturers).filter(
            ([key]) => +key !== DrugManufacturersId.CofarmenMendoza && +key !== DrugManufacturersId.CofarmenSanJuan,
        ),
    );

    const specialInputDrugManufacturer : any = filteredSpecialInputs[idDrugManufactered];

    const catalogsData = catalogs?.map((item) => ({
        name: item.name,
        id: item.id,
        label: item.name,
        value: item.id,
    }));

    const depositsData =
        EX5305 && +idDrugManufactered === DrugManufacturersId.FarmaMX
            ? availableBranches
            : depositsByDrugManufacturer?.map((item) => ({
                  label: item,
                  value: item,
              }));

    const clientTypeData = clientTypes?.map((item) => ({
        label: item,
        value: item,
    }));

    const handleChangeInputPicker = (e, nameInput) => {
        if (requiresAvailableBranches(idDrugManufactered)) {
            if (
                (EX5305 && +idDrugManufactered === DrugManufacturersId.FarmaMX) ||
                (EX6370 && +idDrugManufactered === DrugManufacturersId.Calderon) ||
                +idDrugManufactered === DrugManufacturersId.CofarmenMendoza ||
                +idDrugManufactered === DrugManufacturersId.CofarmenSanJuan
            ) {
                const newDeposit = availableBranches?.find((deposit) => deposit?.label === e);
                const newValue = {
                    ...formValue,
                    client_identifier: `${newDeposit?.value}&&${newDeposit?.label}`,
                };
                setFormValue(newValue);
                return;
            } else if (
                (EX5877 && +idDrugManufactered === DrugManufacturersId.Solfarma) ||
                (EX6090 && +idDrugManufactered === DrugManufacturersId.Calderon && !EX6370)
            ) {
                const newBranch = availableBranches?.find((branch) => branch?.label === e);
                const newValue = { ...formValue, client_identifier: newBranch?.label };
                setFormValue(newValue);
                return;
            } else {
                // setDeposit(e);
                const newDeposit = availableBranches?.find((deposit) => deposit?.value === e);
                // setDepositSelected(newDeposit?.value);
                const newValue = { ...formValue, client_identifier: newDeposit?.value };
                setFormValue(newValue);
            }
        } else if (requiresDispatchAddresses(idDrugManufactered)) {
            if (nameInput === 'dispatch_address') {
                const newAddress = dispatchAddresses?.find((address) => address?.label === e);
                const newValue = { ...formValue, dispatch_address: newAddress };
                setFormValue(newValue);
            } else if (nameInput === 'client_identifier') {
                const newDeposit = depositsByDrugManufacturer?.find((deposit) => deposit === e);
                const newValue = { ...formValue, client_identifier: newDeposit };
                setFormValue(newValue);
            }
        } else if (requiresPaymentMethod(idDrugManufactered)) {
            const newPaymentMethod = paymentMethods?.filter((payment) => payment.id === e);
            const newValue = { ...formValue, payment_method_id: newPaymentMethod[0]?.id };
            setFormValue(newValue);
        } else if (requiresDeposit(idDrugManufactered)) {
            const newDeposit = depositsByDrugManufacturer?.find((deposit) => deposit === e);
            const newValue = { ...formValue, client_identifier: newDeposit };
            setFormValue(newValue);
        } else if (requiresClientType(idDrugManufactered)) {
            const newClientType = clientTypes?.find((client) => client === e);
            const newValue = { ...formValue, client_identifier: newClientType };
            setFormValue(newValue);
        } else if (requiresCatalog(idDrugManufactered)) {
            const newCatalogId = catalogs?.find((catalog) => catalog.id === e)?.id;
            const newValue = { ...formValue, catalog_id: newCatalogId };
            setFormValue(newValue);
        }
    };

    useEffect(() => {
        // replace literal ID with enum's drugmanufacturer ID
        if (requiresPaymentMethod(idDrugManufactered)) {
            getPaymentMethods(idDrugManufactered);
        }
    }, []);

    useEffect(() => {
        if (results?.paymentMethods.length > 0) {
            setPaymentMethods(results?.paymentMethods);
        }
    }, [results]);

    let component;

    const handlePlaceholderType = (type, id) => {
        if (id === 1) {
            return t('credentialplaceholder_twentyEight', { type: type.toLowerCase() });
        } else {
            return t('credentialplaceholder_twentyNine', { type: type.toLowerCase() });
        }
    };

    const handleRequestAdditionalInformation = (drugId) => {
        if (drugId && !!formValue?.username && !!formValue?.password) {
            setDisableUserAndPasswordInput(true);
            const formToLogin = {
                username: formValue?.username,
                password: window.btoa(formValue?.password), // Codifica la contraseña en base64
                client_identifier: formValue?.client_identifier,
                point_of_sale_id: parseInt(pointOfSaleId),
                drug_manufacturer_id: drugId,
                type_id: !!typeConnectionName && typeConnectionName.id,
            };

            // Evita actualizar el estado si el drugId es el mismo
            if (
                prevCredentials?.username !== formToLogin?.username ||
                prevCredentials?.password !== formToLogin?.password
            ) {
                setFormCredential(formToLogin);
            }
        }
    };

    useEffect(() => {
        if (
            formCredential &&
            (prevCredentials?.username !== formCredential?.username ||
                prevCredentials?.password !== formCredential?.password) &&
            !isSaveSuccess
        ) {
            mutateRequestInformation(formCredential); // Dispara la mutación manualmente
            setPrevDrugId(formCredential.drug_manufacturer_id); // Actualiza el drugId previo
            setPrevCredentials({
                drug_manufacturer_id: formCredential?.drug_manufacturer_id,
                username: formCredential?.username,
                password: formCredential?.password,
            }); // Actualiza las credenciales previas
        }
    }, [formCredential, prevDrugId, mutateRequestInformation, isSaveSuccess]);

    useEffect(() => {
        if (!!isError) {
            setDisableUserAndPasswordInput(false);
            setErrorMessage({
                status: isError?.status,
                msgError: isError?.message,
            });
        } else if (!!loginExtraInformation?.drug_manufacturer_id) {
            setErrorMessage({
                status: '',
                msgError: '',
            });
            if (requiresAvailableBranches(loginExtraInformation?.drug_manufacturer_id)) {
                setAvailableBranches(loginExtraInformation?.available_branches);
            } else if (requiresDispatchAddresses(loginExtraInformation?.drug_manufacturer_id)) {
                setDispatchAddresses(loginExtraInformation?.dispatch_addresses);
            }
        }
    }, [loginExtraInformation, isError]);

    useEffect(() => {
        if (requiresAvailableBranches(idDrugManufactered)) {
            if (formValue?.client_identifier?.length > 0) {
                if (availableBranches?.length === 0) {
                    if (
                        +idDrugManufactered === DrugManufacturersId.FarmaMX ||
                        (EX6370 && +idDrugManufactered === DrugManufacturersId.Calderon) ||
                        +idDrugManufactered === DrugManufacturersId.CofarmenMendoza ||
                        +idDrugManufactered === DrugManufacturersId.CofarmenSanJuan
                    ) {
                        const valueClientIdentifier = formValue?.client_identifier?.split('&&')[0];
                        const labelClientIdentifier = formValue?.client_identifier?.split('&&')[1];
                        setAvailableBranches({
                            value: valueClientIdentifier,
                            label: labelClientIdentifier,
                        });
                    } else {
                        setAvailableBranches({
                            value: formValue?.client_identifier,
                            label: formValue?.client_identifier,
                        });
                    }
                }
            }
        } else if (requiresDispatchAddresses(idDrugManufactered)) {
            if (!!formValue?.dispatch_address) {
                if (dispatchAddresses?.length === 0) {
                    if(Object.values(formValue?.dispatch_address).every(value => value !== undefined)) {
                        setDispatchAddresses(formValue?.dispatch_address);
                    }
                }
            }
        }
    }, [formValue]);

    const valueDispatchAddress =
        typeof formValue?.dispatch_address === 'object'
            ? formValue?.dispatch_address?.label
            : formValue?.dispatch_address;

    const valueAvaiableBranches =
        idDrugManufactered === DrugManufacturersId.FarmaMX ||
        (EX6370 && +idDrugManufactered === DrugManufacturersId.Calderon) ||
        +idDrugManufactered === DrugManufacturersId.CofarmenMendoza ||
        +idDrugManufactered === DrugManufacturersId.CofarmenSanJuan
            ? formValue?.client_identifier?.split('&&')[1]
            : formValue?.client_identifier;

    switch (true) {
        case requiresClientType(idDrugManufactered):
            return (
                <FormInputPicker
                    data={clientTypeData}
                    value={formValue?.client_types}
                    onChange={(e) => handleChangeInputPicker(e, 'client_identifier')}
                    size="lg"
                    name="client_identifier"
                    label={EX5990 ? t('credentialplaceholder_twentyFour') : 'Tipo de cliente'}
                    error={formError['client_identifier']}
                    placeholder={
                        EX5990
                            ? handlePlaceholderType(t('credentialplaceholder_twentyFour'), 2)
                            : 'Selecciona un tipo de cliente de la lista'
                    }
                    isRequired
                />
            );
        case requiresCatalog(idDrugManufactered):
            return (
                <>
                    {specialInputDrugManufacturer?.map(({ name, placeholder }, idx) =>
                        name === 'client_identifier' ? (
                            <FormInput key={idx} type="text" name={name} label={placeholder} error={formError[name]} />
                        ) : (
                            <FormInputPicker
                                data={catalogsData}
                                value={formValue?.catalog_id}
                                onChange={(e) => handleChangeInputPicker(e, 'catalog_id')}
                                size="lg"
                                name="catalog_id"
                                label={EX5990 ? t('credentialplaceholder_twentySeven') : 'Catálogo'}
                                error={formError['catalog_id']}
                                placeholder={
                                    EX5990
                                        ? handlePlaceholderType(t('credentialplaceholder_twentySeven'), 2)
                                        : 'Selecciona un depósito de la lista'
                                }
                                isRequired
                            />
                        ),
                    )}
                </>
            );
        case Array.isArray(specialInputDrugManufacturer) && specialInputDrugManufacturer !== null:
            {
            }
            component = specialInputDrugManufacturer.filter(
                (elem) =>
                    elem.credentialType.toLowerCase() === typeConnectionName.typeOfConnection ||
                    (elem.credentialType.toLowerCase() === TypeConnection.API &&
                        typeConnectionName.typeOfConnection === TypeConnection.WEB_ACTUAL) ||
                    (elem.credentialType.toLowerCase() === TypeConnection.WEB &&
                        typeConnectionName.typeOfConnection === TypeConnection.WEB_ACTUAL) ||
                    (elem.credentialType.toLowerCase() === TypeConnection.WEB_NEW &&
                        typeConnectionName.typeOfConnection === TypeConnection.WEB_NEW),
            );

            return (
                <>
                    {component.map(({ name, placeholder }, idx) => (
                        <FormInput key={idx} type="text" name={name} label={placeholder} error={formError[name]} />
                    ))}
                </>
            );

        case typeof specialInputDrugManufacturer === 'object' &&
            specialInputDrugManufacturer !== null &&
            !specialInputDrugManufacturer?.fieldType:
            return (
                <>
                    {
                        <FormInput
                            type="text"
                            name={specialInputDrugManufacturer.name}
                            label={specialInputDrugManufacturer.placeholder}
                            error={formError[specialInputDrugManufacturer.name]}
                        />
                    }
                </>
            );

        case typeof specialInputDrugManufacturer === 'object' &&
            specialInputDrugManufacturer.fieldType === 'autoComplete':
            return (
                <>
                    {/* render the input picker for the address disabled */}
                    {typeConnectionName.typeOfConnection === TypeConnection.WEB &&
                        (idDrugManufactered === DrugManufacturersId.FarmaceuticosTenorioSAdeCV ||
                            idDrugManufactered === DrugManufacturersId.PacificPharmaGroup) && (
                            <FormInputPicker
                                data={paymentMethods}
                                value={formValue?.payment_method_id}
                                onChange={(e) => handleChangeInputPicker(e, 'payment_method_id')}
                                size="lg"
                                name="payment_method_id"
                                label={EX5990 ? t('credentialplaceholder_twentySix') : 'Método de pago'}
                                error={formError['payment_method_id']}
                                placeholder={
                                    EX5990
                                        ? handlePlaceholderType(t('credentialplaceholder_twentySix'), 2)
                                        : 'Seleccioná un método de pago'
                                }
                                isRequired
                                showLoaderIconCaret={results?.loading}
                                valueKey="id"
                                labelKey="label"
                            />
                        )}
                    {/* render the input picker for deposits in step 1 */}
                    {requiresDeposit(idDrugManufactered) && (
                        <FormInputPicker
                            data={depositsData}
                            value={formValue?.client_identifier}
                            onChange={(e) => handleChangeInputPicker(e, 'client_identifier')}
                            size="lg"
                            name="client_identifier"
                            label={EX5990 ? t('credentialplaceholder_twentyFive') : 'Depósito'}
                            error={formError['client_identifier']}
                            placeholder={
                                EX5990
                                    ? handlePlaceholderType(t('credentialplaceholder_twentyFive'), 2)
                                    : 'Selecciona un depósito de la lista'
                            }
                            isRequired={idDrugManufactered === DrugManufacturersId.Vicma ? false : true}
                        />
                    )}
                    {selectedAddressInOtherPos && (
                        <InfoMessage
                            children={
                                <>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <span>
                                            El punto de venta <b>{pointOfSaleAddressName}</b> tiene la misma dirección
                                            de entrega
                                        </span>
                                        <span>¿De todas formas querés recibir tus pedidos en esta dirección?</span>
                                    </div>
                                </>
                            }
                            backgroundColor={'#FFF9E6'}
                            IconColor={'#FFC700'}
                        />
                    )}
                </>
            );

        case requiresAvailableBranches(idDrugManufactered):
            return (
                <FormInputPicker
                    data={availableBranches}
                    value={valueAvaiableBranches}
                    onChange={(e) => handleChangeInputPicker(e, 'client_identifier')}
                    size="lg"
                    name="client_identifier"
                    label={EX5990 ? t('pointOfSale.labelAddressName') : 'Dirección de entrega'}
                    error={formError['client_identifier']}
                    placeholder={EX5990 ? t('pointOfSale.labelSelectAddress') : 'Seleccioná una dirección'}
                    valueKey="label"
                    labelKey="label"
                    isRequired
                    onClick={() => handleRequestAdditionalInformation(idDrugManufactered)}
                    showLoaderIconCaret={!isSaveSuccess && isLoadingLoginExtraInformation}
                />
            );
        case requiresDispatchAddresses(idDrugManufactered):
            return (
                <>
                    {idDrugManufactered === DrugManufacturersId.MedicinasRosario && (
                        <FormInputPicker
                            data={depositsData}
                            value={formValue?.client_identifier}
                            onChange={(e) => handleChangeInputPicker(e, 'client_identifier')}
                            size="lg"
                            name="client_identifier"
                            label={EX5990 ? t('credentialplaceholder_twentyFive') : 'Depósito'}
                            error={formError['client_identifier']}
                            placeholder={
                                EX5990
                                    ? handlePlaceholderType(t('credentialplaceholder_twentyFive'), 2)
                                    : 'Selecciona un depósito de la lista'
                            }
                            isRequired
                            disabled={disableUserAndPasswordInput}
                        />
                    )}
                    <FormInputPicker
                        data={dispatchAddresses}
                        value={valueDispatchAddress}
                        onChange={(e) => handleChangeInputPicker(e, 'dispatch_address')}
                        size="lg"
                        name="dispatch_address"
                        label={EX5990 ? t('pointOfSale.labelAddressName') : 'Dirección de entrega'}
                        error={formError['dispatch_address']}
                        placeholder={EX5990 ? t('pointOfSale.labelSelectAddress') : 'Seleccioná una dirección'}
                        valueKey="label"
                        isRequired
                        onClick={() => handleRequestAdditionalInformation(idDrugManufactered)}
                        showLoaderIconCaret={!isSaveSuccess && isLoadingLoginExtraInformation}
                    />
                </>
            );
        default:
            return null;
    }
};

export default NewSpecialFormManufacturedV4;
