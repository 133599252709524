import { useTheme } from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import { ButtonSolid } from 'commons/components/Button';
import { ButtonOutline } from 'commons/components/Button/ButtonOutline';
import { EX3986, EX4600, EX5057 } from 'config/flags';
import * as gtag from 'utils/gtag';
import { drugManufacturerGtagEvent } from 'utils/gtagEventDM';
import { useUser } from 'utils/hooks';
import { DrugManufacturersId } from 'utils/interfaces';
import { handleIconsCloseCard } from '../CredentialCardBody/Tabs/helper';
import useStyles from './CredentialCardHeader.styles';
import { useTranslation } from 'react-i18next';

function CredentialCardHeader({
    title,
    onClick,
    enabled,
    erp_status,
    deleteComponent,
    failedPasswordUsername,
    drugmanufacturerId,
    failedClientIdentifierCatalog,
    enabledCatalog,
    enabledFarmaciasABC,
    enabledConnectionCdO,
}) {
    const { t } = useTranslation();
    const classes = useStyles();
    const { palette } = useTheme();
    const { hasAuthorization } = useUser({});
    const index = title.indexOf(' ') > 0 ? title.indexOf(' ') : title.length;

    const handleClickLoadCredentials = () => {
        gtag.event({
            action: 'click',
            category: 'settings-pos',
            label: 'button__load-credentials-d' + title.substring(0, index).toLowerCase(),
            value: 0,
        });
        onClick();
    };

    const handleSwitchIcon = () => {
        switch (erp_status) {
            case 'VALIDATED':
                return (
                    <CheckCircle
                        style={{
                            color: palette.Lu[400],
                            marginLeft: 16,
                            height: 24,
                            width: 24,
                        }}
                    />
                );
            case 'UNKNOWN':
                return (
                    <ErrorIcon
                        style={{
                            color: palette.Ma[400],
                            marginLeft: 16,
                            height: 24,
                            width: 24,
                        }}
                    />
                );
            case 'VALIDATION_PENDING':
                return <WarningIcon style={{ color: '#FFC11B', marginLeft: 16, height: 24, width: 24 }} />;
            default:
                return null;
        }
    };

    const renderHtmlG4Tag = () => {
        const nameEvent = drugManufacturerGtagEvent.find(
            (drug_manufacturer) => drug_manufacturer.drug_manufacturer_name.toLowerCase() === title.toLowerCase(),
        )?.name_event;
        return `button__load-credentials-d${nameEvent?.toLowerCase()}`;
    };

    const handleValidationSpecialDrugmanufacturer = () => {
        if (EX4600) {
            return !enabledCatalog && drugmanufacturerId !== DrugManufacturersId.FarmaciasABC;
        } else {
            return !enabledCatalog;
        }
    };

    return (
        <div className={classes.root}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <span className={classes.title}>{title}</span>
                {drugmanufacturerId !== DrugManufacturersId.CentralDeOfertas &&
                    handleValidationSpecialDrugmanufacturer() &&
                    handleSwitchIcon()}
            </div>

            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    visibility: !onClick ? 'hidden' : undefined,
                    height: '36.5px',
                }}
            >
                {enabledConnectionCdO
                    ? handleIconsCloseCard(!enabledConnectionCdO)
                    : (enabled || enabledCatalog || enabledFarmaciasABC) &&
                      !!EX3986 &&
                      handleIconsCloseCard(failedPasswordUsername || failedClientIdentifierCatalog)}
                {deleteComponent}
                {drugmanufacturerId !== DrugManufacturersId.CentralDeOfertas &&
                    (!enabled && !enabledCatalog && !enabledFarmaciasABC ? (
                        <ButtonOutline
                            onClick={() => {
                                enabled ? onClick() : handleClickLoadCredentials();
                            }}
                            display={
                                !hasAuthorization &&
                                (drugmanufacturerId === DrugManufacturersId.AlmacenDeDrogas ||
                                    (EX4600 && drugmanufacturerId === DrugManufacturersId.FarmaciasABC)) &&
                                'none'
                            }
                            text={
                                <span className={renderHtmlG4Tag()}>
                                    {EX4600 &&
                                        drugmanufacturerId === DrugManufacturersId.FarmaciasABC &&
                                        'Ingresar credenciales'}
                                    {drugmanufacturerId === DrugManufacturersId.AlmacenDeDrogas &&
                                        (enabledCatalog ? 'Editar' : 'Seleccionar catálogo')}
                                    {drugmanufacturerId !== DrugManufacturersId.AlmacenDeDrogas &&
                                        drugmanufacturerId !== DrugManufacturersId.FarmaciasABC &&
                                        (enabled
                                            ? !!EX5057
                                                ? t('pointOfSale.labelModifyCredentials')
                                                : 'Editar credenciales'
                                            : !!EX5057
                                            ? t('settings.editPointsOfSale.sett_addCredencialesTextPointsOfSale')
                                            : 'Ingresar credenciales')}
                                </span>
                            }
                            width={''}
                            height={40}
                        />
                    ) : (
                        <ButtonSolid
                            onClick={() => {
                                enabled ? onClick() : handleClickLoadCredentials();
                            }}
                            display={
                                !hasAuthorization &&
                                (drugmanufacturerId === DrugManufacturersId.AlmacenDeDrogas ||
                                    (EX4600 && drugmanufacturerId === DrugManufacturersId.FarmaciasABC)) &&
                                'none'
                            }
                            text={
                                <span className={renderHtmlG4Tag()}>
                                    {EX4600 && drugmanufacturerId === DrugManufacturersId.FarmaciasABC && 'Editar'}
                                    {drugmanufacturerId === DrugManufacturersId.AlmacenDeDrogas &&
                                        (enabledCatalog ? 'Editar' : 'Seleccionar catálogo')}
                                    {drugmanufacturerId !== DrugManufacturersId.AlmacenDeDrogas &&
                                        drugmanufacturerId !== DrugManufacturersId.FarmaciasABC &&
                                        (enabled
                                            ? !!EX5057
                                                ? t('pointOfSale.labelModifyCredentials')
                                                : 'Editar credenciales'
                                            : !!EX5057
                                            ? t('settings.editPointsOfSale.sett_addCredencialesTextPointsOfSale')
                                            : 'Ingresar credenciales')}
                                </span>
                            }
                            width={''}
                            height={40}
                        />
                    ))}
            </div>
        </div>
    );
}

export default CredentialCardHeader;
