// rsuite
import { IconButton } from 'rsuite';
//mui
import CloseIcon from '@mui/icons-material/Close';
// style
import style from './CloseButtonIcon.module.css';

const CloseButtonIcon = ({ onClickClose, setVisibleClear }) => {
    return (
        <div className={`icon__clean-search-1 ${ style['close-icon-button']}`}>
            <IconButton
                className={`icon__clean-search-1 ${style['icon-button-index']}`}
                size="xs"
                icon={<CloseIcon className="icon__clean-search-1" />}
                onClick={(evt) => {
                    setVisibleClear(false), evt.stopPropagation(), onClickClose();
                }}
            />
        </div>
    );
};

export default CloseButtonIcon;
