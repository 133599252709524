import { t } from 'i18next';
// COMPONENTS
import { RsuiteTooltip } from '@commons/components/RsuiteTooltip';
import { Placeholder } from 'rsuite';
// UTILS
import { tooltipYouAreSavings } from '../../utils';
import { useAreSaving } from './hooks';
// STYLES
import cellStyles from '../../../CellStyles.module.css';
import styles from '../TableSaving.module.css';

const YouAreSaving = ({ skeleton }): JSX.Element => {
    const { displayValue } = useAreSaving();

    return (
        <div className={styles['youAreSaving']}>
            <div>
                <RsuiteTooltip
                    trigger={'hover'}
                    placement={'top'}
                    text={tooltipYouAreSavings(t)}
                    marginTop={-2}
                    minWidth="200px"
                    padding="4px 32px"
                >
                    <img src="/assets/images/savings.svg" alt="icon saving" width={24} height={24} />
                </RsuiteTooltip>
            </div>
            <div>
                {skeleton ? (
                    <span className={styles['placeholder']} style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                        <p
                            style={{
                                color: '#07AD4B',
                                fontSize: 18,
                                fontWeight: 600,
                            }}
                        >
                            {t('efficientPurchasing.comparative.efficientPurchasing_comparativeSeven')}
                        </p>
                        <Placeholder.Paragraph
                            active
                            rows={1}
                            rowHeight={16}
                            rowMargin={0}
                            className={cellStyles['placeholder-price']}
                        />
                    </span>
                ) : (
                    <p
                        style={{
                            color: '#07AD4B',
                            fontSize: 18,
                            fontWeight: 600,
                            flexWrap: 'wrap',
                        }}
                    >
                        {t('efficientPurchasing.comparative.efficientPurchasing_comparativeSeven')}
                        {displayValue}
                    </p>
                )}
            </div>
        </div>
    );
};

export default YouAreSaving;
