import React, { useEffect, useState } from 'react';
import { DrawerHeader, DrawerFooter, DrawerBody } from '.';
import { NewUseCart, useCreateOrder, usePointOfSale } from 'context';
import styles from './DrawerCart.module.css';
import { useRouter } from 'next/router';
import { handlerLink } from 'commons/components/Layouts/Sidebar/BodySidebar/handlers/handler.routing';
import { itemsLinks } from 'commons/components/Layouts/Sidebar/routes/links';
import { useUser } from 'utils/hooks';
import { hiddenCart } from 'commons/components/Layouts/utils/hiddenCart';
import { useTranslation } from 'react-i18next';
import { useStatusContext } from 'context/control-state';
import { apiPharmaInstance } from 'utils/axios';
import { UTM_CAMPAIGN_WT, UTM_CONTENT_WT, UTM_MEDIUM_WT, UTM_SOURCE_WT, UTM_TERM_WT } from 'config/flags';

const mockIsStep3 = [
    {
        id: 12720,
        cart_id: 2211,
        barcode: '7791824112743',
        description: 'Ibupirac 800mg x 20 comp. Pfizer',
        is_recommended: 1,
        is_included: 2,
        quantity: 30,
        is_unknown: false,
    },
    {
        id: 12721,
        cart_id: 2211,
        barcode: '7795314026213',
        description: 'Halopidol 2 Mg/ml Sol.oral X 30 Ml',
        is_recommended: 1,
        is_included: 2,
        quantity: 6,
        is_unknown: false,
    },
    {
        id: 12722,
        cart_id: 2211,
        barcode: '7795345120393',
        description: 'Frenaler Cort Jbe.x 60 Ml',
        is_recommended: 1,
        is_included: 2,
        quantity: 15,
        is_unknown: false,
    },
    {
        id: 12723,
        cart_id: 2211,
        barcode: '7798029233754',
        description: 'Dramamine Comp.x 24',
        is_recommended: 1,
        is_included: 2,
        quantity: 12,
        is_unknown: false,
    },
    {
        id: 12724,
        cart_id: 2211,
        barcode: '7792183487879',
        description: 'Gadopril 10 Mg Comp.ran.x 60',
        is_recommended: 1,
        is_included: 2,
        quantity: 10,
        is_unknown: false,
    },
    {
        id: 12725,
        cart_id: 2211,
        barcode: '7795345122809',
        description: 'Lidil Cort Plus Spray Nasal X 120',
        is_recommended: 1,
        is_included: 2,
        quantity: 7,
        is_unknown: false,
    },
];

const DrawerCart = () => {
    // state
    const [hasScroll, setHasScroll] = useState(false);

    // hooks & context
    const {
        visibility,
        toggle,
        isExpanded,
        expand,
        quantityCart,
        productsList,
        openModalDelete,
        setOpenModalDelete,
        animation,
        updateProduct,
        fetching,
        initComparation,
        animationInProduct,
        setVisibility,
        setProductsList,
    } = NewUseCart();

    const { isFetching } = useCreateOrder();

    const { user, mutateUser } = useUser({});

    const { pointOfSale } = usePointOfSale();

    const { counterStep, continueStatus } = useStatusContext();

    // router
    const router = useRouter();
    const { replace, query } = router;

    //translation
    const { t } = useTranslation();

    // object used for push home
    const redirect = itemsLinks(t)[0];

    // listen scroll
    useEffect(() => {
        const drawerBody: any = document.getElementById('drawer-body'); // Reemplaza con el selector adecuado

        if (drawerBody.scrollHeight > drawerBody.clientHeight) {
            setHasScroll(true);
        } else {
            setHasScroll(false);
        }
    }, [productsList.length]);

    const validateOnlyInResults = router?.pathname === '/[clientId]/efficient-purchase/[posId]/search-results';

    const fetchCompletedWalkthrough = async () => {
        try {
            const response = await apiPharmaInstance.post('api/free-trial/walkthrough/completed');
            if (response?.status === 204) {
                setTimeout(() => {
                    mutateUser();
                   /*  replace(
                        {
                            pathname: `/${query.clientId}/efficient-purchase/${query.posId}`,
                            query: {
                                utm_campaign: UTM_CAMPAIGN_WT,
                                utm_source: UTM_SOURCE_WT,
                                utm_medium: UTM_MEDIUM_WT,
                                utm_term: UTM_TERM_WT,
                                utm_content: UTM_CONTENT_WT,
                            },
                        },
                        undefined,
                        { shallow: true },
                    ); */
                }, 1200);
            }
            return;
        } catch (error) {
            console.log(error.message);
        }
    };

    useEffect(() => {
        if (counterStep === '#step2') {
            setVisibility(true);
            setProductsList(mockIsStep3);
        }
        if (continueStatus === 'finished' || continueStatus === 'skipped') {
            setVisibility(false);
            setProductsList([]);
            fetchCompletedWalkthrough();
        }
    }, [counterStep, continueStatus]);

    return (
        <>
            {counterStep === '#step3' && <div className={styles['backdrop-step3']}></div>}
            <div
                id="step3"
                className={`${styles['drawer-wrapper']} 
                ${isExpanded && !validateOnlyInResults && styles['is-expanded']}
                ${isExpanded && !!validateOnlyInResults && styles['is-expanded-results']}
                ${visibility && styles['drawer-fade-in']}
                ${animation && styles['drawer-fade-out']}`}
                style={{
                    position: router.pathname === '/[clientId]/efficient-purchase/[posId]' ? 'fixed' : 'initial',
                }}
            >
                <DrawerHeader
                    toggle={toggle}
                    expand={expand}
                    isExpanded={isExpanded}
                    quantityCart={quantityCart()}
                    setOpenModalDelete={() => setOpenModalDelete(!openModalDelete)}
                    fetching={fetching}
                    allowExpand={!hiddenCart(router.pathname)}
                    handlePushRouter={() => {
                        handlerLink(redirect.href, router, user, pointOfSale);
                    }}
                    pointOfSaleName={pointOfSale?.name}
                />
                <DrawerBody
                    products={productsList}
                    quantityCart={quantityCart()}
                    handleUpdateProduct={updateProduct}
                    pointOfSale={pointOfSale}
                    fetching={fetching}
                    animationInProduct={animationInProduct}
                />
                {!!quantityCart() && !fetching && (
                    <DrawerFooter
                        initComparation={() => initComparation()}
                        hasScroll={hasScroll}
                        isFetching={isFetching}
                    />
                )}
            </div>
        </>
    );
};

export default DrawerCart;
