// component
import { PanelConnection } from '@commons/components/WidgetsDrugConnections/PanelConnection';
// style
import style from './ConnectedDashboard.module.css';
// utils
import stringAvatar from 'utils/avatar-name';
import { t } from 'i18next';
const ConnectedDashboard = ({ categorizedResults, where, fxs, triggerModal }) => {
    
    return (
        <div className={style['container-panel-connected']}>
            {categorizedResults?.connected.map(({ id, name, type, credential_types }, idx) => {
                const idError = fxs.returnStatusObjectConnected(credential_types);
                const {label} = fxs.getStatusByObjectLabel(idError);

                return (
                    <PanelConnection
                        btnAppearance="outline"
                        btnLabel={t('home.tabs.updateButton')}
                        initialName={stringAvatar(name, false, id)}
                        label={label}
                        name={name}
                        status={idError}
                        key={id}
                        where={where}
                        statusAvatar={idError ? "error": "connected"}
                        handleClick={() => { 
                            fxs.initializeForm(name, credential_types[0], credential_types[0].name);
                            triggerModal(id,credential_types, type.id) 
                        }}

                    />
                );
            })}
        </div>
    );
};

export default ConnectedDashboard;
