import React from 'react';
// STYLES
import style from './RecommendedCardHeader.module.css';
// FF
import { EX4796 } from 'config/flags';

export const RecommendedCardHeader = ({ recommended }) => {
    return (
        <>
            {recommended?.offer_label && !!recommended?.offer_label ? (
                <>
                    <div
                        className={style['box-offer-label']}
                        style={{
                            color: !!recommended?.offer_label ? '#fff' : 'transparent',
                            visibility: !!recommended?.offer_label ? 'visible' : 'hidden',
                            minHeight: EX4796 ? 22 : '',
                            maxHeight: EX4796 ? 22 : '',
                        }}
                    >
                        <span className={style['text-offer-label']}>{recommended?.offer_label}</span>
                    </div>
                    {!EX4796 && <div className={style['box-empty']}></div>}
                </>
            ) : (
                !EX4796 && (
                    <div className={style['empty-offer-label']}>
                        <span></span>
                        <br />
                    </div>
                )
            )}
        </>
    );
};
