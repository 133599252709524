import React from 'react';
import style from './SubscriptionDetail.module.css';
import { Panel } from 'rsuite';
import LockIcon from '@mui/icons-material/Lock';
const SubscriptionDetail = ({ customerObject }) => {
    // data
    const { merchant_name, plan_name, plan_amount,plan_currency } = customerObject;

    return (
        <>
            <div className={style['payment-detail-wrapper']}>
                <Panel
                    bordered
                    header={<p className={style['payment-customer-detail']}>{merchant_name}</p>}
                    className={style['payment-detail-container']}
                >
                    <div>
                        <div className={style['container-payment-detail-title']}>
                            <p className={style['payment-detail-title']}>Detalle del pago</p>
                        </div>

                        {/* Línea entre detalle de pago y total a pagar */}
                        <div className={style['payment-detail-line']}></div>
                        <div className={style['container-payment-total-amount']}>
                            <p className={style['payment-total']}>Total a pagar:</p>
                            <p className={style['payment-amount']}>$ {plan_amount} {plan_currency}</p>
                        </div>

                        {/* Línea entre precio y concepto */}
                        <div className={style['payment-detail-line']}></div>
                        <div className={style['container-concept-detail']}>
                            <p className={style['payment-concept']}>Concepto:</p>
                            <span>Plan: {plan_name}</span>
                        </div>
                    </div>
                </Panel>
            </div>
            <div className={style['payment-label-openpay']}>
                <p className={style['payment-processBy']}>
                    <LockIcon />
                    Pago procesado por Openpay by BBVA
                </p>
            </div>
        </>
    );
};

export default SubscriptionDetail;
