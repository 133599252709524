import React from 'react';
import style from './SubscriptionClientForm.module.css';
import { Form, Row, Col } from 'rsuite';
import { FormInput } from '@commons/EXComponentsLibrary';
const SubscriptionClientForm = ({ onChangeClient, error }) => {
    const forms = [
        {
            label: 'Nombre',
            type: 'text',
            name: 'first_name',
            placeholder: 'Nombre',
        },
        {
            label: 'Apellido',
            type: 'text',
            name: 'last_name',
            placeholder: 'Apellido',
        },
        {
            label: 'Email',
            type: 'text',
            name: 'email',
            placeholder: 'Email',
        },
    ];

    return (
        <div className={style['container-form-contact']}>
            <p className={style['form-contact']}>Datos del cliente</p>
            <Form>
                <Row gutter={20}>
                    {forms.map(({ label, type, name, placeholder }) => {
                        return (
                            <Col xs={8}>
                                <FormInput label={label} type={type} name={name} placeholder={placeholder} onChange={(evt) => onChangeClient(evt,name)} error={label === 'Email' && error}/>
                            </Col>
                        );
                    })}
                </Row>
            </Form>
        </div>
    );
};

export default SubscriptionClientForm;
