// components
import { Placeholder } from 'rsuite';
// interface
import { IHeaderProps } from './Header.interface';
// styles
import styles from './Header.module.css';

const Header = ({ item }: IHeaderProps) => {
    return (
        <div className={`${styles['accordion-header']}`}>
            <div>
                <img
                    src={'/assets/images/chevron-down.svg'}
                    alt="dropdown arrow"
                    width={24}
                    height={24}
                    draggable={false}
                    className={`${styles['arrow']} ${styles['down']}`}
                />
                <Placeholder.Paragraph rows={1} rowMargin={0} rowHeight={16} className={styles['placeholder']} active />
            </div>
            <div className={`${styles['label']} ${item?.section === 2 ? styles['custom-label'] : ''}`}>
                <Placeholder.Paragraph
                    rows={1}
                    rowMargin={0}
                    rowHeight={16}
                    className={styles['placeholder-label']}
                    active
                />
            </div>
        </div>
    );
};

export default Header;
