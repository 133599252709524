// react
import { useState, useEffect } from 'react';
import { useConfirmationContext } from 'context/confirmation.context';
// components
import { SettingsTimeHourDelivery } from '../';
import { ChipDelivery } from '@commons/components/TableComparisonV2/TableHeader/TableHeaderErrors/DefaultWithoutError/ChipDelivery';
import { DeliveryInfo } from '@commons/components/TableComparisonV2/TableHeader/TableHeaderErrors/DefaultWithoutError/ChipDelivery/ChipDelivery.interface';
// utils
import { auxiliarFunctions } from './utils';
// style
import style from './DeliveryTimeConfirmation.module.css';

const DeliveryTimeConfirmation = ({ deliveryDate, data }) => {
    // state
    const { setTimes } = useConfirmationContext();
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const [availableTimes, setAvailableTimes] = useState<string[]>([]);
    const [selectedAvailableTime, setSelectedAvailableTime] = useState<string>('');
    const [deliveryInfo, setDeliveryInfo] = useState<DeliveryInfo>();

    // logic
    const auxFx = auxiliarFunctions();

    const formatDateString = auxFx.formatDate(selectedDate);

    const selectedItem = auxFx.itemSelected(data, formatDateString, selectedAvailableTime);

    useEffect(() => {
        setTimes([
            {
                delivery_id: selectedItem?.delivery_id,
                delivery_time_id: selectedItem?.id,
            },
        ]);
    }, [selectedItem]);

    useEffect(() => {
        if (deliveryDate) {
            setDeliveryInfo(deliveryDate);
        }
    }, [deliveryDate]);

    useEffect(() => {
        if (selectedDate) {
            const chipDateInfo = auxFx.getChipDateInfo(selectedDate);
            setDeliveryInfo(chipDateInfo);
        }
    }, [selectedDate]);

    return (
        <>
            {data.length > 0 && (
                <div className={style['container']} onClick={(e) => e.stopPropagation()}>
                    {deliveryInfo && <ChipDelivery delivery={deliveryInfo} />}

                    <SettingsTimeHourDelivery
                        selectedDate={selectedDate}
                        setSelectedDate={setSelectedDate}
                        availableTimes={availableTimes}
                        setAvailableTimes={setAvailableTimes}
                        selectedAvailableTime={selectedAvailableTime}
                        setSelectedAvailableTime={setSelectedAvailableTime}
                        data={data}
                    />
                </div>
            )}
        </>
    );
};

export default DeliveryTimeConfirmation;
