import { useEffect, useState } from 'react';
import { t } from 'i18next';
// contexts
import { useProductComparison } from 'context/product-comparison.context';
import { usePrioritiesPercentagesCeContext } from 'context/PrioritiesPercentagesCe/PrioritiesPercentagesCe-context';
import { useDrugManufacturersContext, useProductsContext, useSnackbar } from 'context';
// apis
import { deletePriority } from 'api/priority';
// utils
import { ConfigPriorities } from './utils';
// types
import { ServiceId } from 'common/types';
import { useUser } from 'utils/hooks';
import { productHasCheckedQuantity } from 'utils/productComparison/productHasCheckedQuantity';

function ModulePriorities({ open, setOpen }) {
    const { setRetryLoading } = useProductComparison();
    const { products } = useProductsContext();

    const { drugManufacturer, enabledOnOff, isEnabledDrugManufacturer } = useDrugManufacturersContext();
    const [allProductAreSelected, setAllProductAreSelected] = useState(false);
    const [service, setService] = useState(0);
    const [unassigned, setUnassigned] = useState([]);
    const [array, setArray] = useState([]);
    const [available, setAvailable] = useState([]);
    const [count, setCount] = useState(1);
    const [show, setShow] = useState(false);
    const EX3526 = process.env.NEXT_PUBLIC_EX3526 === 'true';
    const { user } = useUser({});
    const EX5937 = user?.EX5937;

    const [msg, setMsg] = useState(
        EX3526
            ? t('efficientPurchasing.priorityAndTolerance.efficientPurchasing_priorityAndToleranceSeventeen')
            : 'La prioridad ha sido guardada.',
    );
    const serviceIsPrioritiesOrDistribution = service === ServiceId.ByPercentage;

    const snackbar = useSnackbar();
    // new context by prioritys and percentages
    const { data, serviceId, refetch } = usePrioritiesPercentagesCeContext();

    // new effect
    useEffect(() => {
        if (serviceId === ServiceId.ByPriority) {
            handleGetDataPriorities();
        }
    }, [data]);

    const handleGetDataPriorities = async () => {
        const { assigned, all_available, unassigned } = data || {};

        if (assigned?.length > 0) {
            let responsePriorities = assigned;

            if (assigned.length > drugManufacturer.length) {
                const ids = new Set(all_available.map((item) => item.id));
                let priorityCounter = 1;

                responsePriorities = assigned.map((item) => {
                    if (ids.has(item.drug_manufacturer_id)) {
                        return { ...item, priority_number: priorityCounter++ };
                    }
                    return item;
                });
            }

            setArray(responsePriorities);
            setAvailable(responsePriorities);

            if (unassigned?.length > 0) {
                setUnassigned(unassigned);
            } else {
                setUnassigned([]);
            }
        }
    };

    const handleDelete = async (orderId) => {
        const resultDeletePriority = await deletePriority(orderId);
        if (resultDeletePriority.status === 204) {
            refetch();
            setRetryLoading(true);
        }
    };

    useEffect(() => {
        if (show) {
            snackbar.show({
                message: msg,
            });
            setShow(false);
        }
    }, [show]);

    useEffect(() => {
        setAllProductAreSelected(
            !(productHasCheckedQuantity(products, enabledOnOff, isEnabledDrugManufacturer, EX5937).length === 0),
        );
    }, [products]);

    const hadleOpen = (value) => {
        setOpen(value);
    };

    return (
        <>
            <ConfigPriorities
                serviceId={serviceIsPrioritiesOrDistribution}
                setMsg={setMsg}
                setShow={setShow}
                open={open}
                available={available}
                setOpen={hadleOpen}
                deletePriority={handleDelete}
                unassigned={unassigned}
                array={array}
                count={count}
                setCount={setCount}
                priorityRecorset={data}
            />
        </>
    );
}
export default ModulePriorities;
