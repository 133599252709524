import { useState } from 'react';

export const useCredentialSuccess = () => {
    const [isSuccessCredential, setIsSuccessCredential] = useState<any>({
        credId: [],
        bool: [],
    });

    const onlyTrueSuccess: number = isSuccessCredential?.bool.filter((item) => item === true).length;

    return {
        isSuccessCredential,
        setIsSuccessCredential,
        onlyTrueSuccess,
    };
};
