import { useMemo } from 'react';
import { useRouter } from 'next/router';
// utils
import { COUNTRY } from 'utils/country';
import { useUser } from 'utils/hooks';
import { StatusCdo } from 'utils/interfaces';

export const useCDOStatus = () => {
    const { user } = useUser({});
    const { query } = useRouter();

    const findedPos = useMemo(() => {
        return user?.pointsOfSale.find((item) => String(item.id) === query?.posId);
    }, [user?.pointsOfSale, query?.posId]);

    const CDO_FF = useMemo(() => {
        return user?.EX4562; // cdo
    }, [user?.EX4562]);

    const IS_ARG = useMemo(() => {
        return user?.client?.country_alfa_2 === COUNTRY.AR;
    }, [user?.client?.country_alfa_2]);

    const CDO_STATUS_BY_POS = useMemo(() => {
        return findedPos?.cdo_status_id;
    }, [findedPos?.cdo_status_id]);

    const CDO_IS_PENDING_BY_POS = useMemo(() => {
        return CDO_STATUS_BY_POS === StatusCdo.PENDING;
    }, [CDO_STATUS_BY_POS]);

    const CDO_IS_DEFAULT_BY_POS = useMemo(() => {
        return CDO_STATUS_BY_POS === StatusCdo.DISABLED;
    }, [CDO_STATUS_BY_POS]);

    const CDO_IS_VISIBLE = useMemo(() => {
        return CDO_FF && IS_ARG;
    }, [CDO_FF, IS_ARG]);

    return {
        CDO_STATUS_BY_POS,
        CDO_IS_PENDING_BY_POS,
        CDO_IS_DEFAULT_BY_POS,
        CDO_IS_VISIBLE,
        CDO_FF,
        IS_ARG,
    };
};
