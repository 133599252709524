import React, { useRef } from 'react';
import { t } from 'i18next';
// CONFIGS
import { EX4698 } from 'config/flags';
// COMPONENTS
import { DetailProduct } from '@commons/components/DetailProduct';
import { Placeholder } from 'rsuite';
// UTILS
import { capitalizeEveryFirstLetter } from 'utils/strings/capitalizeEveryFirstLetter';
// STYLES
import styles from '../CellProduct.module.css';
import { CellProductDetailProps } from './CellProductDetail.interface';
import { auxiliarUtils } from './utils/aux.utils';
import { usePointOfSale } from 'context';
import { isZettiFormat } from 'utils/pointOfSale';

const CellProductDetail = ({
    skeleton,
    idx,
    barcode,
    category,
    laboratory,
    description,
    drug,
    generics,
    showChildren,
    setShowChildren,
    parentRef,
    isRecommended,
    isGenerics,
    isZero,
    user,
    tag,
}: CellProductDetailProps): JSX.Element => {
    //ref
    const popoverRef = useRef(null);
    // validation is zetti
    const { pointOfSale: pos } = usePointOfSale();
    const isZetti = isZettiFormat(pos);

    // ff
    const EX4800 = user?.EX4800 && !isZetti;

    let openPopover;

    switch (true) {
        case drug:
            openPopover = false;
            break;
        case !drug:
            openPopover = true;
            break;
        default:
            break;
    }
    const fx = auxiliarUtils();

    const getStylesContainer = () => {
        return { margin: 0, fontSize: 14, display: 'flex', gap: 2 };
    };

    const getStyleEllipsis = (): any => {
        return {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        };
    };

    const stylesCustomGeneric = (): any => {
        return {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        };
    };

    return (
        <div style={getStyleEllipsis()}>
            {isRecommended && !isGenerics && (
                <div className={styles['recommended-product']}>
                    {!skeleton ? (
                        <span>Recomendado</span>
                    ) : (
                        <Placeholder.Paragraph rowHeight={8} rows={1} active className={styles['placeholder-idx']} />
                    )}
                </div>
            )}
            <div>
                {!skeleton ? (
                    <p style={getStylesContainer()}>
                        {!isGenerics && (
                            <>
                                <span style={{ fontWeight: 600 }}>#{idx}</span> |
                            </>
                        )}
                        <span className={styles['barcode']} style={getStyleEllipsis()}>
                            {barcode}
                        </span>
                    </p>
                ) : (
                    <Placeholder.Paragraph rowHeight={8} rows={1} active className={styles['placeholder-idx']} />
                )}
            </div>
            <div
                className={styles['description']}
                style={{
                    WebkitLineClamp: generics || isRecommended ? 1 : 2,
                    wordBreak: 'break-all',
                    textDecoration: isZero && EX4800 ? 'line-through' : 'none',
                }}
            >
                {EX4698 ? (
                    <DetailProduct
                        category={category}
                        placement={idx === 1 || idx === 2 ? 'bottomStart' : 'topStart'}
                        innerRef={popoverRef}
                        onClick={() => popoverRef?.current?.close()}
                        laboratory={laboratory}
                        description={capitalizeEveryFirstLetter(description)}
                        activePrinciple={drug}
                        disabled={openPopover}
                        imageSrc={`${process.env.NEXT_PUBLIC_CLOUDFRONT_IMAGES_EP}/${process.env.NEXT_PUBLIC_CLOUDFRONT_FOLDER_ONE}/${barcode}.png`}
                        container={parentRef}
                    >
                        {!skeleton ? (
                            <p className={`${openPopover !== true && styles['text-label']}`}>
                                {capitalizeEveryFirstLetter(description)}
                            </p>
                        ) : (
                            <Placeholder.Paragraph
                                rowHeight={8}
                                rows={2}
                                active
                                className={styles['placeholder-description']}
                            />
                        )}
                    </DetailProduct>
                ) : (
                    <>
                        {!skeleton ? (
                            <p>{capitalizeEveryFirstLetter(description)}</p>
                        ) : (
                            <Placeholder.Paragraph
                                rowHeight={8}
                                rows={2}
                                active
                                className={styles['placeholder-description']}
                            />
                        )}
                    </>
                )}
            </div>
            {isGenerics && !!tag && (
                <div className={styles['wrapper-tag']}>
                    {tag === 'bioequivalent' && (
                        <img src={'/assets/images/star_orange.svg'} alt="star icon" width={16} height={16} />
                    )}
                    <p className={styles['tag']} style={{ color: tag === 'bioequivalent' ? '#ff6300' : '#6B6F93' }}>
                        {tag === 'bioequivalent'
                            ? t('efficientPurchasing.comparative.bioTag')
                            : t('efficientPurchasing.comparative.promoTag')}
                    </p>
                </div>
            )}
            {EX4800 && generics && !isGenerics && (
                <div className={styles['box-generic']} style={stylesCustomGeneric()} onClick={() => setShowChildren()}>
                    <img
                        src="/assets/images/expand_more_orange.svg"
                        alt="expand icon"
                        className={showChildren ? styles['rotate-arrow'] : ''}
                    />
                    <p>{showChildren ? 'Ocultar genéricos' : 'Mostrar genéricos'}</p>
                </div>
            )}
        </div>
    );
};

export default CellProductDetail;
