export interface IOrderCards {
    dateTime?:string,
    orderNumber?:string,
    drugmanufactur?:string,
    value?:string
    avatar?:string,
    client?:string,
    noMedicinals?:string,
    oneDrugmanufacturerName?:string,
    imageOneDrugmanufacturer?:string,
    drugmanufacturers?:any,
    selectorList?:any,
    timerSkeleton?:boolean,
    initialLettersDrugmanufName?:string,
    orderNumberByDrugmanufact?:string,
    referencePointOfSale?: string,
    drug_manufacturer_id?:number
}

export enum EOrderCards {
    typeOne = 'myOrders',
    typeTwo = 'ordersByDrugmanufacturer'
}