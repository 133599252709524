import { useEffect } from 'react';
import { useRouter } from 'next/router';
// configs
import { EX6778 } from 'config/flags';
// apis
import { apiCredentialRetry } from 'api';
// CONTEXTS
import { useProductComparison } from 'context/product-comparison.context';
import {
    FooterProvider,
    GenericsProvider,
    InsuficientCreditProvider,
    MinimunPurchaseProvider,
    ModalMinimunQuantityProvider,
    RecommendationsProvider,
    SelectAllDrugManufacturerProvider,
    SelectAllStateProvider,
    useContainerRefContext,
    useDrugManufacturersContext,
    useFetchingContext,
    useProductsContext,
    ModalWithoutCredit as ModalWithoutCreditProvider,
} from 'context';
import { useModalWithoutStock } from 'context/ModalWithoutStock/modalWithoutStock';
import { useModalCredentialsContext } from 'context/ModalCredentials/ModalCredentials-context';
// COMPONENTS
import { TableToolbar } from './TableToolbar';
import { TableHeader } from './TableHeader';
import { TableProducts } from './TableProducts';
import { TableFooter } from './TableFooter';
import { WithoutSearching } from './WithoutSearching';
import { NewHeader } from '../Layouts';
import WrapperTable from './WrapperTable';
// utils && hooks
import { useAlertBanner, useProductGroups } from './hooks';
import { useAlerts, useOpenModalWithoutStock } from '@commons/modules/TableProductComparisonV2/hooks';
import Pusher from 'utils/pusher';
// STYLES
import styles from './Layout.module.css';

const TableComparison = ({ children }): JSX.Element => {
    const {
        query: { orderId },
    } = useRouter();

    const {
        isSuccessModal,
        handlerOnCloseModal,
        getDrugInformationById,
        typeConnectionName,
    } = useModalCredentialsContext();

    const { containerRef } = useContainerRefContext();

    const { withoutSearchings } = useProductComparison();

    const { drugManufacturer, mutateDrugManufacturers } = useDrugManufacturersContext();

    const { setProductGroups } = useProductsContext();

    const { setLoading } = useFetchingContext();

    const showAlert: boolean = useAlertBanner();

    const { hasAlert } = useAlerts();

    const { openModal, closeModal } = useModalWithoutStock();

    const { allProductsOutOfStock } = useOpenModalWithoutStock();

    const isShowSomeAlert: boolean = showAlert || hasAlert;

    const {
        productGroupsResponse,
        productGroupsFooterResponse,
        fetchProductGroup,
        setProductGroupsFooterResponse,
        setProductGroupsResponse,
    } = useProductGroups();

    useEffect(() => {
        if (productGroupsResponse?.length && drugManufacturer?.length) {
            setProductGroups(productGroupsResponse);
            setLoading(false);
            setProductGroupsFooterResponse({});
            setProductGroupsResponse([]);
        }
    }, [productGroupsResponse, drugManufacturer]);

    useEffect(() => {
        if (allProductsOutOfStock) {
            openModal();
        } else {
            closeModal();
        }
    }, [allProductsOutOfStock]);

    useEffect(() => {
        if (EX6778 && isSuccessModal) {
            const execute = async () => {
                const credential = getDrugInformationById()?.credential_types?.find(
                    (elem) => elem.id === typeConnectionName.id,
                );
                const channel = Pusher.subscribe(`order.${orderId}`);
                setLoading(true);

                try {
                    await apiCredentialRetry(orderId, credential?.api_credential_id);
                    handlerOnCloseModal();

                    channel.bind(`App\\Events\\TaskComplete`, async (data) => {
                        if (data) {
                            setProductGroups([]);
                            await mutateDrugManufacturers();
                            await fetchProductGroup()
                                .then(() => {})
                                .catch((e) => console.error(e))
                                .finally(() => {
                                    setTimeout(() => {
                                        setLoading(false);
                                    },1000);
                                });
                        }
                    });

                    return () => {
                        Pusher.unsubscribe(`order.${orderId}`);
                        channel.unbind_all();
                    };
                } catch (error) {
                    console.error('Error en apiCredentialRetry:', error);
                }
            };

            execute();
        }
    }, [isSuccessModal]);

    return (
        <div
            className={`${styles['layout-grid-container-v2']}`}
            ref={containerRef}
            style={{
                height: '100vh',
                gridTemplateRows: isShowSomeAlert ? `56px max-content 64px auto` : `56px 64px auto`,
            }}
        >
            {/* header layout */}
            <div className={styles['header']}>
                <NewHeader orders={null} delivery={null} />
            </div>
            {/* alerts */}
            {isShowSomeAlert && (
                <div className={styles['alerts-wrapper']}>
                    {children
                        ?.filter?.((c) => c !== false)
                        ?.map((alert, index) => (
                            <div key={index} className="alert">
                                {alert}
                            </div>
                        ))}
                </div>
            )}
            <SelectAllStateProvider>
                <GenericsProvider>
                    {/* toolbar */}
                    <TableToolbar />
                    {/* table */}
                    <WrapperTable isShowSomeAlert={isShowSomeAlert}>
                        {/* table header */}
                        {!withoutSearchings && (
                            <InsuficientCreditProvider>
                                <SelectAllDrugManufacturerProvider>
                                    <TableHeader fetchProductGroup={fetchProductGroup} />
                                </SelectAllDrugManufacturerProvider>
                            </InsuficientCreditProvider>
                        )}
                        <RecommendationsProvider>
                            {/* table products */}
                            {!!withoutSearchings ? (
                                // render no data
                                <WithoutSearching />
                            ) : (
                                <ModalWithoutCreditProvider>
                                    <TableProducts />
                                </ModalWithoutCreditProvider>
                            )}
                            {/* footer totals and savings */}
                            {
                                <ModalMinimunQuantityProvider>
                                    <FooterProvider data={productGroupsFooterResponse}>
                                        <MinimunPurchaseProvider>
                                            <TableFooter />
                                        </MinimunPurchaseProvider>
                                    </FooterProvider>
                                </ModalMinimunQuantityProvider>
                            }
                        </RecommendationsProvider>
                    </WrapperTable>
                </GenericsProvider>
            </SelectAllStateProvider>
        </div>
    );
};

export default TableComparison;
