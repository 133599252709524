import { t } from 'i18next';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { EX5057, FULLSCREEN } from 'config/flags';
import { fullScreen, handlerClickConfiguration, handlerLogout, handlerOpenModalChangePassword } from '../handlers';

function createDropdownItem(id, label, onClick, icon, className) {
    return {
        id: id,
        label: label,
        onClick: onClick,
        icon: icon,
        className: className,
    };
}

export function generateDropdownItems(isFullScreen) {
    const labelConfig = EX5057 ? t('header.header_dropdownOne') : 'Configuración';
    const labelChangePassword = EX5057 ? t('header.header_dropdownTwo') : 'Cambiar Contraseña';
    const labelExit = EX5057 ? t('header.header_dropdownThree') : 'Salir';

    const itemsDropdown = [
        createDropdownItem(
            1,
            labelConfig.toString(),
            handlerClickConfiguration,
            <SettingsOutlinedIcon />,
            'button__config',
        ),
        createDropdownItem(
            2,
            labelChangePassword.toString(),
            handlerOpenModalChangePassword,
            <LockOutlinedIcon />,
            'button__change-password-2',
        ),
        createDropdownItem(4, labelExit, handlerLogout, <ExitToAppOutlinedIcon />, 'button__log-out'),
    ];

    if (!!FULLSCREEN) {
        if (isFullScreen) {
            itemsDropdown.splice(
                2,
                0,
                createDropdownItem(3, 'Salir pantalla completa', fullScreen, <FullscreenExitIcon />, ''),
            );
        } else {
            itemsDropdown.splice(2, 0, createDropdownItem(3, 'Pantalla completa', fullScreen, <FullscreenIcon />, ''));
        }
    }

    return itemsDropdown;
}
