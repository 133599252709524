import { useState } from 'react';
// next
import { useRouter } from 'next/router';
// components
import {
    ConnectedDashboard,
    AvailableDashboard,
    TabsConnectionsDashboard,
} from '@commons/components/WidgetsDrugConnections';
// enum
import { EButtonData } from './WidgetsConnectionsHome.enum';
// hook
import { useDrugManufacturersWithoutPagination } from 'utils/hooks';
import useCategorizedDrugManufacturers from '@commons/components/WidgetsDrugConnections/hook/useCategorizedDrugManufacturers';
// utils
import { auxiliary } from '@commons/components/WidgetsDrugConnections/utils/aux.utils';
import { useModalCredentialsContext } from 'context/ModalCredentials/ModalCredentials-context';

const WidgetsConnectionsHome = () => {
    // next
    const router = useRouter();
    const { posId } = router.query;
    // state
    const [activeKey, setActiveKey] = useState(EButtonData.available);
    // hook
    const { drugManufacturers } = useDrugManufacturersWithoutPagination(posId, true, '', 1);

    const categorizedResults = useCategorizedDrugManufacturers(drugManufacturers);
    // aux
    const fxs = auxiliary();

    const { toggleModal, handlerDrugConnection, handlerVendorConnection } = useModalCredentialsContext();

    const triggerModal = (id, credential_types, typeId) => {
        if (typeId === 3) {
            handlerVendorConnection(id);
        } else {
            handlerDrugConnection('', id, credential_types);
        }
        toggleModal.toggle();
    };

    return (
        <>
            <TabsConnectionsDashboard
                categorizedResults={categorizedResults}
                activeKey={activeKey}
                setActiveKey={setActiveKey}
            />

            {activeKey === EButtonData.available && (
                <AvailableDashboard categorizedResults={categorizedResults} fxs={fxs} triggerModal={triggerModal} />
            )}

            {activeKey === EButtonData.connected && (
                <ConnectedDashboard
                    where={activeKey === EButtonData.connected}
                    fxs={fxs}
                    categorizedResults={categorizedResults}
                    triggerModal={triggerModal}
                />
            )}
        </>
    );
};

export default WidgetsConnectionsHome;
