// react
import { useEffect, useMemo } from 'react';
// components
import { DaySetting, VerticalLineDivider, TimeSetting } from '@commons/components';
// style
import style from './SettingTimeHourDelivery.module.css';

// utils
import { auxiliaryFx } from './utils';



const SettingsTimeHourDelivery = ({
    selectedDate,
    setSelectedDate,
    availableTimes, 
    setAvailableTimes,
    selectedAvailableTime, 
    setSelectedAvailableTime,
    data
}) => {
   

    //logic
    const fx = auxiliaryFx();

    const availableDates = useMemo(() => {
        return fx.availableDates(data);
    }, []);

    const currentlySelectedDate = useMemo(() => selectedDate || new Date(), [selectedDate])

    useEffect(() => {
        (async () => {
            const { availableTimes } = fx.processDateAndTimes(currentlySelectedDate, data);
            setAvailableTimes(availableTimes);
            if (availableTimes.length > 0) {
                setSelectedAvailableTime(availableTimes[0]);
            } else {
                setSelectedAvailableTime('');
            }
        })();
    }, [currentlySelectedDate]);

    useEffect(() => {
        (async () =>
            await fx.predeterminateDateHour(
                availableDates,
                setSelectedDate,
                setAvailableTimes,
                setSelectedAvailableTime,
                data,
            ))();
    }, [availableDates]);

    return (
        <div className={style['container-configuration']}>
            <DaySetting value={currentlySelectedDate} setSelectedDate={setSelectedDate} availableDates={availableDates} />

            <VerticalLineDivider />
            {
                availableTimes.length > 0 && (

                    <TimeSetting
                        hoursRecordset={availableTimes}
                        setSelectedAvailableTime={setSelectedAvailableTime}
                        selectedAvailableTime={selectedAvailableTime}
                    />
                )
            }
        </div>
    );
};

export default SettingsTimeHourDelivery;
