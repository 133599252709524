import { useState } from 'react';
// next
import { useRouter } from 'next/router';
// component
import { LineDivider } from '.';
// interface
import { ISettings } from './Settings.interface';
// rsuite
import { Dropdown } from 'rsuite';
// style
import style from './Settings.module.css';
// utils
import { generateDropdownItems } from './utils/items';
// helper
import { executeOnClick } from './helper';
// context
import { NewUseCart } from 'context';
import { useStatusContext } from 'context/control-state';
import { EX5783 } from 'config/flags';

const Settings = ({ title, execModal, execLogout, isZetti }: ISettings) => {
    // router
    const router = useRouter();
    //context
    const { counterStep } = useStatusContext();
    const { handleResetVisibility } = NewUseCart();

    // state
    const [isFullScreen, setIsFullScreen] = useState(false);

    return (
        <div className={style['container-setting']}>
            <Dropdown
                placement={'bottomEnd'}
                title={title}
                className={style['dropdown-content']}
                noCaret
                appearance="subtle"
                // menuStyle={{
                //     marginLeft: -50,
                //     right: 0,
                //     minWidth: 200,
                // }}
            >
                {generateDropdownItems(isFullScreen).map(({ label, icon, className, id, onClick }) => {
                    return (
                        <Dropdown.Item
                            key={`header_settings_${label}`}
                            eventKey={id}
                            className={className}
                            onClick={() => {
                                handleResetVisibility();
                                executeOnClick(
                                    id,
                                    label,
                                    onClick,
                                    router,
                                    execLogout,
                                    execModal,
                                    setIsFullScreen,
                                    isFullScreen,
                                );
                            }}
                        >
                            <div className={`${className} ${style['wrapper-dropdown-item']}`}>
                                <div className={style['wrapper-icon']}>{icon}</div>
                                <span className={className}>{label}</span>
                            </div>
                        </Dropdown.Item>
                    );
                })}
            </Dropdown>
        </div>
    );
};

export default Settings;
