import { useEffect, useState } from 'react';
// configs
import { EX6551, EX6809 } from 'config/flags';
// contexts
import { useDrugManufacturersContext, useFetchingContext, useFooterContext } from 'context';
// utils && hooks
import useCurrencyFormatter from 'utils/hooks/useCurrencyFormatter/useCurrencyFormatter';
import { useCalculate } from '../../../hooks';
import { useUser } from 'utils/hooks';
import { getEstimatedSavings } from '../../../utils/getEstimatedSavings';

export const useAreSaving = () => {
    const { formatCurrency } = useCurrencyFormatter();
    const { footerValues, setYouAreSavings } = useFooterContext();

    const { products } = useCalculate();

    const { loading } = useFetchingContext();

    const { drugManufacturer } = useDrugManufacturersContext();

    const { user, userLocaleCountryCode } = useUser({});
    const EX4562 = user?.EX4562;

    const { fullValue: footerValuesAreSaving, originalValue: originalValueFromBackend } = formatCurrency({
        value: footerValues?.are_saving,
    });

    const { fullValue: estimatedSavingsAreSaving, originalValue: originalValueEstimatedSavings } = formatCurrency({
        value: getEstimatedSavings('youAreSaving', products, drugManufacturer, userLocaleCountryCode, EX4562),
    });

    const [init, setInit] = useState<boolean>(true);
    const [displayValue, setDisplayValue] = useState<string>('0');
    const DEPENDENCIES = EX6809
        ? [footerValuesAreSaving, loading, drugManufacturer, products]
        : EX6551
        ? [footerValuesAreSaving, estimatedSavingsAreSaving, loading, drugManufacturer]
        : [estimatedSavingsAreSaving];

    useEffect(() => {
        if (EX6551) {
            if (init && !loading && !footerValuesAreSaving?.includes('NAN') && drugManufacturer?.length > 0) {
                setYouAreSavings(`${originalValueFromBackend}`);
                setDisplayValue(footerValuesAreSaving);
                setTimeout(() => {
                    setInit(false);
                }, 1500);
            } else {
                if (!init && !loading && !footerValuesAreSaving?.includes('NAN') && drugManufacturer?.length > 0) {
                    if (EX6809) {
                        setYouAreSavings(`${originalValueEstimatedSavings}`);
                    } else {
                        setYouAreSavings(`${originalValueEstimatedSavings}`);
                        setDisplayValue(estimatedSavingsAreSaving);
                    }
                }
            }
        } else {
            if (init) {
                setDisplayValue(footerValuesAreSaving);
                setInit(false);
            } else {
                setDisplayValue(estimatedSavingsAreSaving);
            }
        }
    }, DEPENDENCIES);

    return {
        displayValue,
    };
};
