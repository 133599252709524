import React from 'react';
// UTILS
import { unitsByDrugManufacturer } from '@commons/components/TableComparisonV2/utils';
// STYLES
import styles from '../../../../TableTotals.module.css';
import cellStyles from '../../../../../../CellStyles.module.css';
// INTERFACES
import { QuantityUnitsProps } from './QuantityUnits.interface';

const QuantityUnits = ({
    skeleton,
    drugManufacturerEnabled,
    t,
    quantityUnitsByDrugManufacturer,
    productQuantityByDrugManufacturer,
}: QuantityUnitsProps): JSX.Element => (
    <div className={styles['quantity-units']}>
        {!skeleton && (
            <p
                className={`${
                    !drugManufacturerEnabled && !skeleton && productQuantityByDrugManufacturer === 0
                        ? cellStyles['drugManufacturerDisabled']
                        : ''
                }`}
            >
                {unitsByDrugManufacturer(t, quantityUnitsByDrugManufacturer)}
            </p>
        )}
    </div>
);

export default QuantityUnits;
