// next
import { useRouter } from 'next/router';
// react
import { useEffect, useRef } from 'react';
// components
import { EmptyList, PaginationList, EmptyListV2 } from '.';
import { RenderMenuItem } from './RenderMenuItem';
// interface
import { IListResults } from './ListResults.interface';
// rsuite
import { List, Panel, Placeholder, Row } from 'rsuite';
// style
import style from './ListResults.module.css';
// handler
import { handleKeyDownInputPicker } from 'utils/dataKeyPress';
import { useUser } from 'utils/hooks';
import { handleLimitChange, handlerChangePage } from './handlers';
import LoadingBody from '@components/LoadingBody/LoadingBody';

const ListResults = ({
    recordset,
    pagination,
    onClickAddProductCart,
    handleNextPage,
    handlePreviousPage,
    handleItemsPerPage,
    isLoading,
    isOpen,
    existsProductInCart,
    getProductByBarcode,
    updateProduct,
    pointOfSale,
    setSelectedIndex,
    selectedIndex,
    setActiveSearches,
}: IListResults) => {
    const router = useRouter();
    const { user } = useUser({});
    const EX5095 = user?.EX5095;
    const listElementRef = useRef();
    useEffect(() => {
        if (!isOpen) {
            const element: any = document?.getElementById('list-results');

            element?.focus();
            element?.click();
        }
    }, [isOpen]);


    
    return (
        <div className={`${(style['container-panel'], isOpen ? style['backdrop'] : '')}`}>
            <Panel bodyFill shaded className={style['panel-header']}>
                {recordset.length === 0 && !isLoading ? (
                    <EmptyList />
                ) : (
                    <div ref={listElementRef} style={{position: 'relative'}}>
                        <List style={{borderRadius: 8}}>
                            <List.Item className={style['list-items']}>
                                {!user?.EX5178 && (
                                    <div className={style['container-quantity-item']}>
                                        {isLoading ? (
                                            <div className={style['wrapper-paragraph']}>
                                                <Placeholder.Paragraph rows={1} rowHeight={15} active />
                                            </div>
                                        ) : (
                                            <p className={style['quantity-items']}>
                                                {pagination?.total} resultados encontrados
                                            </p>
                                        )}
                                    </div>
                                )}
                                <Row className={style['wrapper-row']}>
                                    {isLoading ? (
                                        <LoadingBody isSearchResults rows={10} />
                                    ) : (
                                        recordset.map(({ _source }, idx) => {
                                            if (isLoading) {
                                                return <LoadingBody />;
                                            } else {
                                                return (
                                                    <RenderMenuItem
                                                        key={idx + 1}
                                                        idx={idx + 1}
                                                        index={idx}
                                                        _source={_source}
                                                        onClickAddProductCart={(e) => onClickAddProductCart(_source)}
                                                        existsProductInCart={existsProductInCart}
                                                        quantity={getProductByBarcode(_source.ean[0])?.quantity}
                                                        cartId={getProductByBarcode(_source.ean[0])?.id}
                                                        pointOfSaleId={pointOfSale?.id}
                                                        handleUpdateProduct={updateProduct}
                                                        onKeyDown={(evt) =>
                                                            handleKeyDownInputPicker(
                                                                evt,
                                                                setSelectedIndex,
                                                                recordset,
                                                                router,
                                                                '',
                                                                pointOfSale?.id,
                                                                recordset,
                                                                'listResults',
                                                                setActiveSearches,
                                                            )
                                                        }
                                                        selectedIndex={selectedIndex}
                                                        parentRef={listElementRef}
                                                    />
                                                );
                                            }
                                        })
                                    )}
                                </Row>
                                {!EX5095 && <hr className={style['liner-divider']} />}
                                {!EX5095 && (
                                    <PaginationList
                                        total={pagination?.total}
                                        activePage={pagination?.current_page}
                                        limit={pagination?.per_page}
                                        onChangePage={(new_page) =>
                                            new_page > pagination?.current_page
                                                ? handlerChangePage(new_page, handleNextPage)
                                                : handlerChangePage(new_page, handlePreviousPage)
                                        }
                                        onChangeLimit={(limit) => handleLimitChange(limit, handleItemsPerPage)}
                                        pages={pagination?.total_pages}
                                        isLoading={isLoading}
                                    />
                                )}
                            </List.Item>
                        </List>
                    </div>
                )}
            </Panel>
        </div>
    );
};

export default ListResults;
