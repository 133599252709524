import React from 'react';
import Link from 'next/link';
import { t } from 'i18next';
// components
import { Form } from 'rsuite';
import { ExButton, FormInput } from '@commons/EXComponentsLibrary';
import MobileWrapper from './Mobile/MobileWrapper';
import { HeaderMobile } from '../../HeaderMobile';
import MessageOnlyDesktop from '../../MessageOnlyDesktop/MessageOnlyDesktop';
// utils && hooks
import { useLogin, useMailTo, useShowPassword } from '../../hooks';
// styles
import styles from './FormAuth.module.css';

const FormAuth = () => {
    const { loading, formError, onSubmit, updateFormState, formState } = useLogin();
    const { showMailTo } = useMailTo();
    const { showPassword, handleShowPassword } = useShowPassword();

    return (
        <>
            <div className={styles['container-content']}>
                <div className={styles['content']}>
                    {/* title */}
                    <p className={styles['title-form']}>{t('login.login4')}</p>
                    {/* form */}
                    <Form fluid className={styles['form']} formValue={formState} onSubmit={onSubmit}>
                        {/* error text */}
                        {formError && <p className={styles['error-form']}>{t('login.login25')}</p>}
                        <div className={`${styles['input']}`}>
                            <FormInput
                                label={t('login.login10')}
                                placeholder={t('login.login10')}
                                isRequired={true}
                                type={'text'}
                                /*  startIcon={<img src="/assets/images/mail.png" />} */
                                /* showStartIcon */
                                maxWidth
                                autoComplete={'username'}
                                showErrorIcon={false}
                                error={null}
                                name={'username'}
                                customStyleStartIcon={{ backgroundColor: '#FFF', marginRight: 0 }}
                                onChange={(event) => updateFormState(event, 'email')}
                                applyBorderErrorStyle={formError}
                            />
                        </div>
                        <div className={`${styles['input']} ${styles['box-password']}`}>
                            <FormInput
                                label={t('login.login11')}
                                placeholder={t('login.login11')}
                                isRequired={true}
                                type={showPassword ? 'text' : 'password'}
                                /* startIcon={<img src="/assets/images/lock.png" />} */
                                /* showStartIcon */
                                maxWidth
                                autoComplete={'password'}
                                showErrorIcon={false}
                                error={null}
                                name={'password'}
                                customStyleStartIcon={{ backgroundColor: '#FFF', marginRight: 0 }}
                                onChange={(event) => updateFormState(event, 'password')}
                                applyBorderErrorStyle={formError}
                                onEyeClick={() => handleShowPassword()}
                            />
                            <Link
                                href={'/login/recovery'}
                                className={`link__forgot-password-2 ${styles['reset-password']}`}
                            >
                                <p className={`link__forgot-password-2`}>{t('login.login17')}</p>
                            </Link>
                        </div>
                        <ExButton
                            type="submit"
                            status="initial"
                            className={'button__log-in'}
                            withArrow={true}
                            fullWidth
                            size="lg"
                            loading={loading}
                        >
                            {t('login.login43')}
                        </ExButton>
                    </Form>
                </div>
                <div className={`link__login-contact-us ${styles['contact-us']}`} onClick={showMailTo}>
                    {t('login.login21')}
                </div>
            </div>
            {/* MOBILE */}
            <MobileWrapper>
                <div className={styles['content']}>
                    <div className={styles['header']}>
                        <HeaderMobile />
                        <div className={styles['title-form']}>
                            <p>{t('login.login4')}</p>
                        </div>
                    </div>
                    {/* form */}
                    <Form fluid className={styles['form']} formValue={formState} onSubmit={onSubmit}>
                        {/* error text */}
                        {formError && <p className={styles['error-form']}>{t('login.login25')}</p>}
                        <div className={`${styles['input']}`}>
                            <FormInput
                                label={t('login.login10')}
                                placeholder={t('login.login10')}
                                isRequired={true}
                                type={'text'}
                                /*  startIcon={<img src="/assets/images/mail.png" />} */
                                /* showStartIcon */
                                maxWidth
                                autoComplete={'username'}
                                showErrorIcon={false}
                                error={null}
                                name={'username'}
                                customStyleStartIcon={{ backgroundColor: '#FFF', marginRight: 0 }}
                                onChange={(event) => updateFormState(event, 'email')}
                                applyBorderErrorStyle={formError}
                            />
                        </div>
                        <div className={`${styles['input']} ${styles['box-password']}`}>
                            <FormInput
                                label={t('login.login11')}
                                placeholder={t('login.login11')}
                                isRequired={true}
                                type={showPassword ? 'text' : 'password'}
                                /* startIcon={<img src="/assets/images/lock.png" />} */
                                /* showStartIcon */
                                maxWidth
                                autoComplete={'password'}
                                showErrorIcon={false}
                                error={null}
                                name={'password'}
                                customStyleStartIcon={{ backgroundColor: '#FFF', marginRight: 0 }}
                                onChange={(event) => updateFormState(event, 'password')}
                                applyBorderErrorStyle={formError}
                                onEyeClick={() => handleShowPassword()}
                            />
                            <Link
                                href={'/login/recovery'}
                                className={`link__forgot-password-2 ${styles['reset-password']}`}
                            >
                                <p className={`link__forgot-password-2`}>{t('login.login17')}</p>
                            </Link>
                        </div>
                        <ExButton
                            type="submit"
                            status="initial"
                            className={'button__log-in'}
                            withArrow={true}
                            fullWidth
                            size="lg"
                            loading={loading}
                        >
                            {t('login.login43')}
                        </ExButton>

                        <MessageOnlyDesktop />
                    </Form>
                </div>
            </MobileWrapper>
            <div className={`link__login-contact-us ${styles['contact-us-mobile']}`} onClick={showMailTo}>
                {t('login.login21')}
            </div>
        </>
    );
};

export default FormAuth;
