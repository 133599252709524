import { t } from 'i18next';
import { EX3918, EX5057 } from 'config/flags';
import {
    createErrorObject,
    extractNames,
    filterErrorsByCode,
    getErrorSubtextTooltip,
    getErrorTitleTooltip,
} from '../helpers';

export const switchMessageErrors = (extractedDataError, quantity) => {
    // Enum for error codes
    const ErrorCode = {
        UnknownError: 1,
        WrongUsernamePassword: 2,
        OperationForbidden: 3,
        CreditLimitReached: 4,
    };

    // Filter error arrays
    const wrongUserNameArray = filterErrorsByCode(extractedDataError, ErrorCode.WrongUsernamePassword);
    const creditLimitArray = filterErrorsByCode(extractedDataError, ErrorCode.CreditLimitReached);

    // Extract names
    const nameDrugmanufacturerCredit = extractNames(creditLimitArray);
    const nameDrugmanufacturer = extractNames(wrongUserNameArray);

    // Create error objects
    const errorAlertWrongUserPassword = createErrorObject(
        t('efficientPurchasing.credentialsError.efficientPurchasing_credentialsErrorTen'),
        getErrorTitleTooltip(quantity),
        t('efficientPurchasing.credentialsError.efficientPurchasing_credentialsErrorEleven', {
            value: nameDrugmanufacturer,
        }),
        getErrorSubtextTooltip(quantity, nameDrugmanufacturer),
        ErrorCode.WrongUsernamePassword,
        nameDrugmanufacturer,
        t,
    );
    const errorAlertCreditLimit = createErrorObject(
        EX5057 ? t('efficientPurchasing.insufficientCredit.labelInsufficientCredit') : 'Crédito insuficiente',
        EX5057 ? t('efficientPurchasing.insufficientCredit.labelInsufficientCredit') : 'Crédito insuficiente',
        `No pudimos conectarnos con ${nameDrugmanufacturerCredit} porque tu crédito es insuficiente.`,
        t('efficientPurchasing.insufficientCredit.efficientPurchasing_insufficientCreditTwo'),
        ErrorCode.CreditLimitReached,
        nameDrugmanufacturerCredit,
        t,
    );

    return {
        wrongUserPass: { errorAlertWrongUserPassword, wrongUserNameArray },
        creditLimit: { errorAlertCreditLimit, creditLimitArray },
    };
};
