// components
import Image from 'next/image';
// components
import { Modal } from 'rsuite';
import { ExButton } from '@commons/EXComponentsLibrary';
// interfaces
import { IErrorModal } from './ErrorModal.interface';
// styles
import styles from './ErrorModal.module.css';

const ErrorModal = ({ open, title, text, handleClose, buttonText }: IErrorModal) => {
    return (
        <Modal open={open} onClose={handleClose} className={styles['container']}>
            <Modal.Header className={styles['header']}>
                <Modal.Title className={styles['title']}>
                    {title}
                    <Image src={'/assets/images/warning.svg'} alt="warning icon" width={18} height={18} />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={styles['body']}>{text}</Modal.Body>
            <Modal.Footer className={styles['footer']}>
                <ExButton onClick={handleClose} appearance="primary" fullWidth>
                    {buttonText}
                </ExButton>
            </Modal.Footer>
        </Modal>
    );
};

export default ErrorModal;
