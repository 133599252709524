import { sendRequestCdO } from 'api';
import { useModalCdOAlert } from 'context/alertModalCdo/alertModalCdo';
import { useCredentialErrors } from 'context/CredentialsErrors/CredentialsErrors';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useUser } from 'utils/hooks';
import { StatusCdo } from 'utils/interfaces';

export const useCDORequestModal = () => {
    const { query } = useRouter();
    const { user } = useUser({});
    // context modal cdo
    const { openModal } = useModalCdOAlert();
    // context credential with error
    const { setIsFetchingUpdate } = useCredentialErrors();

    const findedPos: any = user?.pointsOfSale.find((item) => String(item.id) === query?.posId);
    /* #region CDO */
    const [hasSeenModalCDO, setHasSeenModalCDO] = useState<boolean>(false);

    const [requestCdoState, setRequestCdoState] = useState<any>({});

    const [isLoading, setIsLoading] = useState<boolean>(false);

    //client has Seen
    const clientHasSeenModal = () => {
        const infoClientsString = localStorage.getItem('alertModalCDO');
        if (infoClientsString === null) {
            return false; // No hay información almacenada
        }

        try {
            const infoClients = JSON.parse(infoClientsString);
            if (infoClients) {
                for (let i = 0; i < infoClients.length; i++) {
                    const infoCliente = infoClients[i];
                    if (
                        infoCliente.idClient === user?.client?.id &&
                        infoCliente.idPos === findedPos?.id &&
                        !!infoCliente.seen
                    ) {
                        return true;
                    }
                }
            }
        } catch (error) {
            console.error('Error parsing JSON:', error);
        }
        return false;
    };

    //listener client has seen modal cdo
    useEffect(() => {
        if (!hasSeenModalCDO && !clientHasSeenModal() && findedPos?.cdo_status_id !== StatusCdo.ENABLED) {
            openModal();
        }
    }, [hasSeenModalCDO, findedPos?.cdo_status_id, user]);

    const requestCdO = async () => {
        try {
            setIsLoading(true);

            const data = await sendRequestCdO(query?.posId);
            setRequestCdoState(data?.data);

            setIsLoading(false);
            setIsFetchingUpdate(true);

            return;
        } catch (error) {
            setIsLoading(false);
            console.log(error.message);
        }
    };

    return {
        setHasSeenModalCDO,
        setIsLoading,
        isLoading,
        requestCdoState,
        requestCdO,
    };
};
