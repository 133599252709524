export const auxiliaryFunctions = () => {
    /**
     * Toggles the expand state of a specific key in the expanded object.
     * If the key is currently expanded, it collapses it; otherwise, it expands it.
     * @param {string} key - The key whose expand state is to be toggled.
     * @param {Object} expanded - The object representing the current expand states.
     * @param {Function} setExpanded - The function to update the expand states.
     */
    const toggleExpand = (key, expanded, setExpanded) => {
        setExpanded({ ...expanded, [key]: !expanded[key] });
    };

    /**
     * Determines the language-specific label for a given key based on the user's country.
     * @param {string} key - The key for which the language label is determined.
     * @param {Object} user - The user object containing country information.
     * @returns {string} The language-specific label for the given key and user's country.
     */
    const keyLanguages = (key, user) => {
        let lang;
        const country = user?.client?.country_alfa_2;
        switch (key + '|' + country) {
            case 'laboratory|AR':
            case 'laboratory|CO':
            case 'laboratory|MX':
            case 'laboratory|BR':
                lang = 'Laboratorio';
                break;
            case 'presentation|AR':
            case 'presentation|CO':
            case 'presentation|MX':
                lang = 'Presentación';
                break;
            case 'drug|AR':
            case 'drug|CO':
            case 'drug|MX':
                lang = 'Principio activo';
                break;
            case 'drug|BR':
                lang = 'Ingrediente ativo';
                break;
            case 'presentation|BR':
                lang = 'Apresentação';
                break;
            case 'drug|US':
                lang = 'Ingredients';
                break;
            case 'presentation|US':
                lang = 'Presentation';
                break;
            default:
                break;
        }
        return lang;
    };

    return {
        toggleExpand,
        keyLanguages,
    };
};
