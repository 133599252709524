//react
import { useRef } from 'react';
// translation
import { t } from 'i18next';
// configs
import { EX6778 } from 'config/flags';
// component
import { RsuiteTooltip } from '@commons/components/RsuiteTooltip';
import { RsuitePopover } from '@commons/components/RsuitePopover';
import { UpdateCredentialText } from '../UpdateCredentialText';
import { CardTypesError } from '../CardTypesError';
// styles
import styles from './ExpiredPasswordError.module.css';

const ExpiredPasswordError = ({
    truncateText,
    haveExpiredPasswordError,
    title,
    subtitle,
    placement,
    handleOpenConnectionModal,
}) => {
    // ref
    const triggerRef = useRef(null);
    const closeTootip = () => triggerRef?.current?.close();

    return (
        <div className={styles['container-name']}>
            <div className={styles['container-text-image']}>
                <img src="/assets/images/error_FILL.svg" className={styles['image']} />
                <RsuiteTooltip
                    placement="top"
                    text={truncateText.texts}
                    trigger="hover"
                    disabled={!truncateText.hasEllipsis}
                >
                    <div className={styles['name']}>{truncateText.texts}</div>
                </RsuiteTooltip>
            </div>
            {EX6778 ? (
                <UpdateCredentialText
                    name={truncateText.texts?.props?.children}
                    key={truncateText.texts}
                    handleOpenConnectionModal={handleOpenConnectionModal}
                />
            ) : (
                <RsuitePopover
                    //open={false} // testing put in true
                    ref={triggerRef}
                    placement={placement}
                    trigger="hover"
                    popoverClassName={styles['popover-container']}
                    childOne={<CardTypesError openModalCredentials={closeTootip()} subtitle={subtitle} title={title} />}
                    disabled={!!haveExpiredPasswordError ? false : true}
                >
                    <p className={styles['error-text']}>{t('efficientPurchasing.comparative.showError')}</p>
                </RsuitePopover>
            )}
        </div>
    );
};

export default ExpiredPasswordError;
