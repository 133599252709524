export const setterUnitsByDrugManufacturers = (products, setter) => {
    const selectedUnits = [];
    products.forEach(({ drugManufacturerIdSelected, barcode, products, quantity }) => {
        if (Array.isArray(drugManufacturerIdSelected)) {
            drugManufacturerIdSelected?.forEach((id) => {
                let quantitySelected = 0;
                if (products[id]?.quantity_suggested) {
                    quantitySelected = products[id].quantity_suggested;
                } else {
                    quantitySelected = quantity;
                }
                selectedUnits.push({
                    products: [{ barcode: barcode, quantity: quantitySelected }],
                    drugManufacturerId: id,
                });
            });
        } else {
            selectedUnits.push({
                products: [{ barcode: barcode, quantity: quantity }],
                drugManufacturerId: drugManufacturerIdSelected,
            });
        }
    });

    setter(selectedUnits);
};
