import { memo } from 'react';
// components
import { ListItems } from './ListItems';
import { HeaderBox } from './HeaderBox';
// styles
import styles from './ListBox.module.css';

const ListBox = ({ fetching }): JSX.Element => {
    return (
        <div className={styles['container']}>
            <HeaderBox />
            <ListItems fetching={fetching} />
        </div>
    );
};

export default memo(ListBox);
