import { Placeholder } from 'rsuite';
import style  from './ParagraphLoader.module.css'
import {IParagraphLoader} from './ParagraphLoader.interface'

const ParagraphLoader: React.FC<IParagraphLoader> = (props) => {
    return <div className={style['wrapper-paragraph']}>
        <Placeholder.Paragraph {...props}/>
    </div>
}

export default ParagraphLoader;