import React, { useState } from 'react';
// COMPONENTS
import { Placeholder } from 'rsuite';
// INTERFACES
import { ImageBarcodeProps } from './ImageBarcode.interface';
// STYLES
import styles from '../RecommendedCellProduct.module.css';

const ImageBarcode = ({ skeleton, recommendation }: ImageBarcodeProps): JSX.Element => {
    const [imageLoad, setImageLoad] = useState<boolean>(false);

    return (
        <div className={styles['img']}>
            {(skeleton || !imageLoad) && (
                <Placeholder.Graph
                    active
                    style={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                />
            )}
            {!skeleton && (
                <img
                    src={`${process.env.NEXT_PUBLIC_CLOUDFRONT_IMAGES_EP}/${process.env.NEXT_PUBLIC_CLOUDFRONT_FOLDER_TWO}/${recommendation?.[0]?.barcode}.png`}
                    alt="product image"
                    onError={(e: any) => (e.target.src = '/assets/images/default.png')}
                    onLoad={() => setImageLoad(true)}
                    width={55}
                    height={55}
                    style={{
                        display: !imageLoad ? 'none' : 'inline',
                        objectFit: 'cover',
                    }}
                />
            )}
        </div>
    );
};

export default ImageBarcode;
