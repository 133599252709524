import React, { memo } from 'react';
// utils && hooks
import { usePasswordConditionText } from './hooks';
// interfaces
import { IPasswordConditionsProps } from './PasswordConditionsText.interface';
// styles
import styles from './PasswordConditionsText.module.css';

const PasswordConditionsText = ({
    showError,
    password,
    callbackValidation,
    textString,
    isRepeatNewPassword,
    newPassword,
    repeatNewPassword,
}: IPasswordConditionsProps) => {
    const { text } = usePasswordConditionText({
        textString,
        password,
        callbackValidation,
        isRepeatNewPassword,
        newPassword,
        repeatNewPassword,
    });

    return <>{showError && <p className={styles['error']}>{text}</p>}</>;
};

export default memo(PasswordConditionsText);
