import { t } from 'i18next';
import useCurrencyFormatter from 'utils/hooks/useCurrencyFormatter/useCurrencyFormatter';
import { MinimumPurchaseDetailProps } from './MinimumPurchaseDetail.interface';
import styles from './MinimumPurchaseDetail.module.css';

const MinimumPurchaseDetail = ({ min_purchase }: MinimumPurchaseDetailProps) => {
    const { formatCurrency } = useCurrencyFormatter();
    const minPurchaseFormatted = formatCurrency({
        value: min_purchase,
        digitsValidation: 7,
    });
    return (
        <div className={styles.minimumPurchaseDetail}>
            <span className={styles.minimumPurchaseDetailLabel}>{t('minimumAmount.min2')}</span>
            <span className={styles.minimumPurchaseDetailValue}>{minPurchaseFormatted?.fullValue}</span>
        </div>
    );
};
export default MinimumPurchaseDetail;
