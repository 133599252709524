// react
import { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';
//components
import { RsuiteTooltip } from '@commons/components/RsuiteTooltip';
// rsuite
import { Checkbox } from 'rsuite';
// style
import style from './ActionCheckbox.module.css';
// interface
import { IActionCheckbox } from './ActionCheckbox.interface';
// context
import { useNewSearchProduct } from 'context';
import { capitalizeEveryFirstLetter } from 'utils/strings/capitalizeEveryFirstLetter';

// TODO: TO BE EVENTUALLY REPLACED WITH CUSTOM CHECKBOX COMPONENT...
// https://www.figma.com/design/CqPUqtnvmhDcRi5ef0q5u4/Documentation?node-id=1289-4999&t=pgsRDx8dWsLs5Nld-0
const ActionCheckbox = ({ value, qty, tag, onChecked, disabled }: IActionCheckbox) => {
    // context
    const { aggregationsTypes } = useNewSearchProduct();
    // local state
    const [isTooltipDisabled, setIsTooltipDisabled] = useState(false);

    const checkedStyles = {
        borderRadius: '8px',
        backgroundColor: '#DCEDFF'
    };

    const isChecked = useMemo(() => {
        return Object.hasOwn(aggregationsTypes, tag) && aggregationsTypes[tag].includes(value);
    }, [aggregationsTypes])

    const valueRef: MutableRefObject<HTMLParagraphElement | null> = useRef(null);

    useEffect(() => {
        const checkOverflow = () => {
            if (valueRef.current) {
                const element = valueRef.current;
                setIsTooltipDisabled(element.scrollWidth <= element.clientWidth);
            }
        };

        const observer = new ResizeObserver(checkOverflow);
        if (valueRef.current) {
            observer.observe(valueRef.current);
        }

        checkOverflow();

        return () => {
            if (valueRef.current) {
                observer.unobserve(valueRef.current);
            }
        };
    }, [value]);

    return (
        <Checkbox
            className={disabled && style['actionCheckbox']}
            disabled={disabled}
            value={value}
            checked={isChecked}
            onChange={(evt, checked) => onChecked({evt, checked, tag})}
            style={{
                margin: 0, 
                ...isChecked && {...checkedStyles}
            }}
        >
            <RsuiteTooltip text={capitalizeEveryFirstLetter(value)} placement="topStart" trigger="hover" disabled={isTooltipDisabled}>
                <div className={style['container-text']}>
                    <p ref={valueRef} className={style['text-value']}>{capitalizeEveryFirstLetter(value)}</p>
                    <span className={style['quantity']}>({qty})</span>
                </div>
            </RsuiteTooltip>
        </Checkbox>
    );
};

export default ActionCheckbox;
