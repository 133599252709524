import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { rem, rgba } from 'polished';

const useStyles = makeStyles<Theme, { EX4904?: boolean }>((theme) => ({
    title: {
        fontSize: rem('32px'),
        color: theme.palette.neutral[900],
        textAlign: 'center',
        fontWeight: 600,
        fontFamily: 'Source Sans Pro',
    },
    headerWrapper: {
        paddingLeft: rem('20px'),
        paddingTop: '16px',
    },
    headerBoxShadows: {
        boxShadow: '0px 2px 6px #00000040',
    },
    newSubtitleTotalResume: {
        color: '#40435B',
        fontFamily: 'Source Sans Pro',
        fontSize: 18,
        fontWeight: 700,
    },
    drugManufacturerListItemWrapper: {
        width: '100%',
        boxShadow: `${theme.shadows[1]}`,
        borderTop: `${rem('4px')} solid  #0171E6`,
        padding: `${rem('17px')} ${rem('32px')}`,
        borderRadius: rem('4px'),
    },
    gridContainer: {
        borderBottom: ({ EX4904 }) => (!!EX4904 ? '' : `${rem('2px')} solid ${theme.palette.neutral[500]}`),
        padding: ({ EX4904 }) => (!!EX4904 ? '' : '10px 0px 10px 0px'),
    },
    gridContainerNew: {
        backgroundColor: `#ACAEC4`,
        height: '0.5px',
        width: '100%',
        padding: '16px 0px',
    },
    orderSummaryWrapper: {
        position: 'relative',
        width: '100%',
        backgroundColor: `${rgba(theme.palette.neutral[300], 0.5)}`,
        borderRadius: rem('8px'),
        padding: `${rem('16px')} ${rem('20px')}`,
        minWidth: rem('370px'),
    },
    wrapperContainer: {
        position: 'fixed',
        maxWidth: 544,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    newOrderSummaryWrapper: {
        width: '100%',
        backgroundColor: `#E3ECFF`,
        borderRadius: `8px 8px 0px 0px`,
        padding: `${rem('16px')} ${rem('20px')}`,
        minWidth: rem('370px'),
    },
    bottomSummaryWrapper: {
        position: 'relative',
        bottom: -12,
    },
    cardPercentageContainer: {
        padding: ` 1px 12px 2px 12px`,
        borderRadius: ` 20px`,
        backgroundColor: ` rgba(1, 113, 230, 0.15)`,
        marginRight: ` 8px`,
        marginLeft: ` 8px`,
    },
    newCardPercentageContainer: {
        borderRadius: 4,
        border: '0.5px solid #BED0E5',
        background: '#F0F5FF',
        display: 'flex',
        padding: '2px 8px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        marginRight: 8,
    },
    alertWrapper: {
        position: 'absolute',
        bottom: rem('-96px'),
        left: 0,
        right: 0,
    },
    fullQuantity: {
        color: '#6B6F93',
        fontSize: 14,
        fontWeight: 600,
    },
    totalQuantity: {
        color: '#6B6F93',
        fontSize: 14,
        fontWeight: 400,
    },
    subtitleTotalResume: {
        color: '#40435B',
        fontFamily: 'Source Sans Pro',
        fontSize: 16,
        fontWeight: 600,
    },
    totalQuantityPrice: {
        color: '#6B6F93',
        fontFamily: 'Source Sans Pro',
        fontSize: 14,
        fontWeight: 600,
    },
    panelContainer: {
        borderTop: '2px solid  #0171E6',
        borderRadius: 8,
        background: 'white',
        '&.rs-panel-body': {
            padding: '0px !important',
        },
    },
    titleNewResume: {
        color: '#40435B',
        fontSize: 20,
        fontWeight: 600,
        position: 'fixed',
        paddingLeft: 20,
    },
    titleNew: {
        color: '#40435B',
        fontSize: 20,
        fontWeight: 600,
    },
    panelLine: {
        borderTop: '0.5px solid #ACAEC4 !important',
        marginBottom: '16px !important',
    },
    avatar: {
        width: 32,
        height: 32,
        background: '#EBF5FF',
        color: '#0171E6',
        fontSize: 12,
        fontWeight: 600,
        textTransform: 'uppercase',
        marginRight: 8,
    },
    price: {
        color: '#40435B',
        fontSize: 16,
        fontWeight: 700,
    },
    decimals: {
        color: '#40435B',
        fontSize: 12,
        fontWeight: 700,
    },
    secondPrice: {
        color: '#40435B',
        fontSize: 16,
        fontWeight: 600,
    },
    secondDecimals: {
        color: '#40435B',
        fontSize: 12,
        fontWeight: 600,
    },
    lineTotal: {
        width: 464,
        height: 2,
        backgroundColor: 'red',
    },
    tooltip: {
        marginLeft: 4,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        top: '-1px',
    },
    truncate: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '280px',
        display: 'block',
        fontWeight: 400,
    },
    drugManufacturerName: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 8,
        fontWeight: 400,
        fontSize: 16,
        color: '#E22E2E',
    },
    tagFreeQuantity: {
        borderRadius: 4,
        border: '0.5px solid #DDF6E2',
        background: '#EBF9F3',
        display: 'flex',
        padding: '0px 4px',
        justifyContent: 'center',
        alignItems: 'center',
    },
    textTagFreeQuantity: {
        color: '#40435B',
        fontSize: 12,
        fontStyle: 'normal',
        fontWeight: 400,
        marginLeft: 4,
    },
    textFreeQuantity: {
        color: '#07AD4B',
        fontSize: 14,
        fontWeight: 600,
    },
    containerNameChipDelivery: {
        textTransform: 'uppercase',
        marginRight: '8px',
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
    },
}));

export default useStyles;
