export const handlerChangePage = (evt: any,value:any, setPages: (evts: any) => void) => {
  setPages(value);
};

export const handlerChangeItemsRow = (value:number, setPages: (evts: any) => void, setItemRow: (evts: any) => void) => {
  setPages(1);
  setItemRow(value);
};

export const newHandlerChangePage = (value:number, setPages: (evts: any) => void) => {
  setPages(value);
};