// components
import { CredentialsDrawer } from 'commons/components';

const CredentialCardDrawer = ({ onlyTrueSuccess, isSuccessCredential, setIsSuccessCredential }) => {
    return (
        <CredentialsDrawer
            onlyTrueSuccess={onlyTrueSuccess}
            isSuccessCredential={isSuccessCredential}
            setIsSuccessCredential={setIsSuccessCredential}
        />
    );
};

export default CredentialCardDrawer;
