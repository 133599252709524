import { memo } from 'react';
// contexts
import {
    useDrugManufacturersContext,
    useFetchingContext,
    useGenericsContext,
    usePointOfSale,
    useProductsContext,
} from 'context';
import { PrioritiesPercentagesCeProvider } from 'context/PrioritiesPercentagesCe/PrioritiesPercentagesCe-context';
// components
import { GenericSwitch } from '../GenericSwitch';
import { ToolbarCE } from '@commons/modules';
import { ModalWithoutSelectionContainer } from '@commons/components/ModalWithoutSelectionContainer';
// utils && hooks
import { auxiliaryUtilsFunctions } from './utils/aux.utils';
import { useUser } from 'utils/hooks';
import { isZettiFormat } from 'utils/pointOfSale';
import { useAlertBanner } from '../hooks';
import { useAlerts } from '@commons/modules/TableProductComparisonV2/hooks';
// styles
import styles from './TableToolbar.module.css';

const ToolbarSection = () => {
    const { productGroups } = useProductsContext();

    const { drugManufacturer } = useDrugManufacturersContext();

    const { loading } = useFetchingContext();

    const { showAllGenerics, setShowAllGenerics } = useGenericsContext();

    const { user } = useUser({});
    // validation is zetti
    const { pointOfSale: pos } = usePointOfSale();
    const isZetti = isZettiFormat(pos);
    // ff
    const EX4800 = user?.EX4800 && !isZetti;
    const EX6144 = user?.EX6144;
    // aux
    const fx = auxiliaryUtilsFunctions();

    const haveGenerics = fx.handleHaveGenerics(productGroups);

    const showAlert: boolean = useAlertBanner();

    const { hasAlert } = useAlerts();

    const isShowSomeAlert: boolean = showAlert || hasAlert;

    return (
        <div
            className={`${styles['toolbar-section']} ${EX6144 ? styles['custom-position'] : ''}`}
            style={{
                gridRow: EX6144 ? (isShowSomeAlert ? 3 : 2) : 1,
            }}
        >
            <PrioritiesPercentagesCeProvider>
                {drugManufacturer?.length > 0 && productGroups?.length > 0 && !loading ? (
                    <ModalWithoutSelectionContainer user={user} />
                ) : null}
                <ToolbarCE setOpenDropdown={() => {}} openDropdown={() => {}} />
            </PrioritiesPercentagesCeProvider>
            {EX4800 && haveGenerics && (
                <GenericSwitch enabled={haveGenerics} onChangeToggle={() => setShowAllGenerics(!showAllGenerics)} />
            )}
        </div>
    );
};

export default memo(ToolbarSection);
