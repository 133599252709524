import style from '../../ModalTerms.module.css';
// Import the main component
import { Viewer } from '@react-pdf-viewer/core';

import '@react-pdf-viewer/core/lib/styles/index.css';
// Import the styles
import { EX4922, EX5747 } from 'config/flags';

import handleError from 'utils/pdfViewer/handleError';

const TermsAndConditionsModalMX = () => {
    return EX4922 ? (
        <Viewer
            fileUrl={EX5747 ? '/pdf/termsAndConditions/mx/termsMXv4.pdf' : '/pdf/termsAndConditions/mx/termsMX.pdf'}
            renderError={handleError}
        />
    ) : (
        <div className={style['wrapper-body']}>
            <p className={style['sections-title']}>¡Bienvenido a EXTENDEAL!</p>
            <p className={style['sections-body']}>
                La relación comercial entre usted (la “<u>Farmacia</u>”) y Extendeal MX S.A de C.V., RFC EMX2308211E9,
                Calle Londres 241 Piso 4, Colonia Juárez, Cuauhtemoc, Ciudad de México, Estados Unidos Mexicanos (“
                <u>Extendeal</u>” o la “<u>Compañía</u>” y junto con la Farmacia, las “<u>Partes</u>” y, cada uno de
                ellos individual e indistintamente, una “<u>Parte</u>”) se regirá por los presentes términos y
                condiciones de servicio (conforme los mismos sean actualizados y modificados periódicamente, los “
                <u>Términos y Condiciones</u>”).
            </p>

            <p className={style['sections-body']}>
                Los Términos y Condiciones podrán ser consultados en http://www.extendeal.com (el “<u>Sitio</u>”).
            </p>

            <p className={style['sections-body']}>
                La Farmacia estará sujeta a los Términos y Condiciones conforme los mismos sean actualizados,
                modificados y/o complementados periódicamente, a discreción de Extendeal (las “<u>Modificaciones</u>
                ”). Las nuevas versiones de los Términos y Condiciones y los cambios en el Precio (conforme se define en
                la <u>Cláusula 3.1</u>) serán notificadas a la Farmacia mediante correo electrónico y/o publicadas en el
                Sitio, con una anticipación no inferior a 30 días de la fecha de su entrada en vigor.
            </p>

            <p className={style['sections-body']}>
                Al utilizar el Servicio, el Sitio y todas sus funcionalidades, la Farmacia conoce y acepta total e
                incondicionalmente los Términos y Condiciones, y las decisiones que la Compañía adopte sobre cuestiones
                no previstas en estos. La Farmacia que no acepte estos Términos y Condiciones deberá abstenerse de
                utilizar el Servicio, el Sitio y todas sus funcionalidades.
            </p>

            <p className={style['sections-body']}>
                El Servicio es implementado a través de Amazon Web Services (“
                <u>AWS</u>”). Por lo tanto, la Farmacia declara conocer y aceptar las condiciones y políticas de uso de
                AWS, la cual se puede acceder a través del siguiente link https://aws.amazon.com/es/terms/
            </p>

            <p className={style['sections-title']}>
                1. <u>Definiciones e interpretación</u>
            </p>

            <p className={style['sections-body']}>
                1.1 Los términos cuya primera letra figura en mayúscula en los Términos y Condiciones (salvo cuando se
                deba exclusivamente a que inician una oración o se trate de un nombre propio), tienen el significado que
                se les asigna en la presente <u>Cláusula 1.1</u>.
            </p>
            <p className={style['sections-body']}>
                “<u>AWS</u>” tiene el significado que se le asigna en el Encabezado.
            </p>
            <p className={style['sections-body']}>
                “<u>Compañía</u>” tiene el significado que se le asigna en el Encabezado.
            </p>
            <p className={style['sections-body']}>
                “<u>Contenido</u>” tiene el significado que se le asigna en la <u>Cláusula 11.2</u>.
            </p>
            <p className={style['sections-body']}>
                “<u>Credenciales</u>” tiene el significado que se le asigna en la <u>Cláusula 2.2</u>.
            </p>
            <p className={style['sections-body']}>
                “<u>Cuenta</u>” tiene el significado que se le asigna en la <u>Cláusula 2.2</u>.
            </p>
            <p className={style['sections-body']}>
                “<u>Distribuidores Mayoristas</u>” tiene el significado que se le asigna en la <u>Cláusula 2.1</u>.
            </p>
            <p className={style['sections-body']}>
                “<u>Dólares Estadounidenses</u>” significa la moneda de curso legal de los Estados Unidos de América.
            </p>
            <p className={style['sections-body']}>
                “<u>Extendeal</u>” tiene el significado que se le asigna en el Encabezado.
            </p>
            <p className={style['sections-body']}>
                “<u>Factura</u>” tiene el significado que se le asigna en la <u>Cláusula 3.2</u>.
            </p>
            <p className={style['sections-body']}>
                “<u>Farmacia</u>” significa cualquier persona jurídica o física que contrate el Servicio. A los efectos
                de los presentes Términos y Condiciones, el término Farmacia también incluirá al Personal de la
                Farmacia.
            </p>
            <p className={style['sections-body']}>
                “<u>Modificaciones</u>” tiene el significado que se le asigna en el Encabezado.
            </p>
            <p className={style['sections-body']}>
                “<u>Orden de Servicio</u>” tiene el significado que se le asigna en la <u>Cláusula 3.1</u>.
            </p>
            <p className={style['sections-body']}>
                “<u>Parte</u>” o “<u>Partes</u>” tiene el significado que se le asigna en el Encabezado.
            </p>
            <p className={style['sections-body']}>
                “<u>Personal</u>” significa todos los empleados, apoderados y/o cualquier persona que utilice los
                Servicios con las Credenciales de cada Farmacia.
            </p>
            <p className={style['sections-body']}>
                “<u>Pesos Mexicanos</u>” significa la moneda de curso legal en los Estados Unidos Mexicanos.
            </p>
            <p className={style['sections-body']}>
                “<u>Plazo de Pago</u>” tiene el significado que se le asigna en la <u>Cláusula 3.3</u>.
            </p>
            <p className={style['sections-body']}>
                “<u>Precio</u>” tiene el significado que se le asigna en la <u>Cláusula 3.1</u>.
            </p>
            <p className={style['sections-body']}>
                “<u>Productos</u>” tiene el significado que se le asigna en la <u>Cláusula 2.1</u>.
            </p>
            <p className={style['sections-body']}>
                “<u>Sitio</u>” tiene el significado que se le asigna en el Encabezado.
            </p>
            <p className={style['sections-body']}>
                “<u>Servicio</u>” tiene el significado que se le asigna en la <u>Cláusula 2.1</u>.
            </p>
            <p className={style['sections-body']}>
                “<u>Términos y Condiciones</u>” tiene el significado que se le asigna en el Encabezado.
            </p>
            <p className={style['sections-body']}>
                Tal y como se utiliza en los Términos y Condiciones (i) según el contexto, las palabras en singular
                incluyen el plural, y las palabras en plural incluyen el singular; (ii) la referencia a las Secciones,
                Cláusulas y párrafos son referencias a las Secciones, Cláusulas y párrafos de estos Términos y
                Condiciones; (iii) los títulos se insertan únicamente para facilitar la referencia y no se tendrán en
                cuenta a efectos de la interpretación de las cláusulas y no tienen ningún efecto legal ni contractual;
                (iv) el uso del término “incluyendo” se interpretará seguido de las palabras “sin limitación”, a
            </p>

            <p className={style['sections-title']}>
                2. <u>El Servicio</u>
            </p>

            <p className={style['sections-body']}>
                2.1 La misión de Extendeal es optimizar el proceso de compra y venta de productos medicinales y no
                medicinales (los “<u>Productos</u>”) entre las Farmacias y los distribuidores mayoristas (los “
                <u>Distribuidores</u>”) incorporando soluciones en la cadena de abastecimiento que permitan eliminar
                intermediarios, automatizar funciones, optimizar costos financieros, logísticos y de gestión de
                inventario, entre otros, y así fomentar una industria transparente y efectiva que genere productos de
                calidad a precios competitivos.
            </p>

            <p className={style['sections-body']}>
                Para ello, la Compañía ofrece una herramienta basada en una plataforma SaaS informatizada que facilita,
                automatiza y eficientiza el proceso de adquisición de Productos de las Farmacias a los Distribuidores
                (el “<u>Servicio</u>”).
            </p>

            <p className={style['sections-body']}>
                Para hacer uso del Servicio, la Farmacia deberá suscribir la Orden de Servicio (conforme se define en la{' '}
                <u>Cláusula 3.1</u>) y generar un usuario y contraseña para acceder al Sitio (la “<u>Cuenta</u>”). La
                Farmacia ingresará en la Cuenta y cargará los datos de acceso con los que accede a las plataformas de
                los Distribuidores (las “<u>Credenciales</u>”).
            </p>

            <p className={style['sections-body']}>
                Una vez ingresado a la Cuenta, la Farmacia detallará los Productos que necesita adquirir y el Servicio
                proveerá la información disponible de los mismos en cada Distribuidor. De esta manera, el Servicio
                funcionará como una herramienta para que la Farmacia pueda tomar decisiones de compra de Productos
                estratégicas en base a toda la información provista por los Distribuidores de manera rápida, segura y
                eficiente.
            </p>

            <p className={style['sections-body']}>
                En dicho proceso, en ningún momento Extendeal se involucrará en el proceso de compra ni en la relación
                entre la Farmacia y el Distribuidor. La Farmacia, a su sola y única discreción, determinará si desea
                adquirir los Productos y en qué Distribuidor.
            </p>

            <p className={style['sections-title']}>
                3. <u>Pagos y suscripción al Servicio</u>
            </p>

            <p className={style['sections-body']}>
                Al contratar el Servicio por primera vez, la Farmacia suscribirá una orden de servicio la cual contendrá
                las principales condiciones del Servicio, incluyendo el precio denominado en Dólares Estadounidenses (el
                “<u>Precio</u>”) (la “<u>Orden de Servicio</u>”). El Precio podrá ser abonado en Pesos Mexicanos
                utilizando el tipo de cambio vigente del día anterior a la fecha de emisión de la Factura. Al Precio se
                le agregará el Impuesto al Valor Agregado.
            </p>
            <p className={style['sections-body']}>
                El Servicio se facturará mensualmente por mes adelantado (la “<u>Factura</u>”). Las Facturas serán
                enviadas a la Farmacia únicamente en formato digital por los medios informados según lo establecido en
                la
                <u>Cláusula 3.6</u>.
            </p>

            <p className={style['sections-body']}>
                El pago de las Facturas se realizará dentro de los 5 días corridos de la fecha de emisión de la Factura
                (el “<u>Plazo de Pago</u>”), ya sea mediante un enlace de pago, transferencia bancaria, débito
                automático, tarjeta de crédito o tarjeta de débito. Ante la falta de pago en el Plazo de Pago, la
                Compañía suspenderá la provisión del Servicio a la Farmacia. Salvo cancelación de la suscripción al
                Servicio conforme lo establecido en la
                <u>Cláusula 6</u>, para continuar utilizando el Servicio la Farmacia deberá abonar la Factura impaga con
                más los intereses por mora hasta la fecha de su efectivo pago.
            </p>

            <p className={style['sections-body']}>
                3.4 La Farmacia autoriza a que la Compañía recurra a un tercero a fin de procesar los pagos del Precio y
                de cualquier otro importe comprometido al pago, y acepta la divulgación de su información de pago a
                dicho operador.
            </p>

            <p className={style['sections-body']}>
                3.5 La Farmacia podrá cancelar su suscripción al Servicio en cualquier momento informándolo por escrito
                a Extendeal con 30 días de anticipación.
            </p>

            <p className={style['sections-body']}>
                3.6 La Farmacia autoriza a que Extendeal se contacte a través de correo electrónico y/o teléfonos
                provistos en la Orden de Servicio en relación con el pago del Precio o de cualquier otro importe
                comprometido al pago.
            </p>

            <p className={style['sections-title']}>
                4. <u>Capacidad y representación</u>
            </p>

            <p className={style['sections-body']}>
                4.1 El Servicio es ofrecido a personas con capacidad legal para contratar. No podrán contratar el
                Servicio las Farmacias que hayan sido suspendidas, inhabilitadas o canceladas por Extendeal, en los
                términos la <u>Cláusula 6</u> de estos Términos y Condiciones.
            </p>

            <p className={style['sections-body']}>
                4.2 La persona física que intervenga ante Extendeal en representación de la Farmacia deberá tener
                capacidad y facultades suficientes para contratar a nombre de la Farmacia y de obligar a la misma bajo
                estos Términos y Condiciones y adquirir los Productos a través del uso del Servicio. No podrán utilizar
                el Servicio en representación de la Farmacia las personas físicas que no tengan capacidad legal para
                contratar, incluyendo sin limitación, los menores de edad y demás supuestos establecidos por la ley
                mexicana.
            </p>

            <p className={style['sections-title']}>
                5. <u>Cuenta y seguridad</u>
            </p>

            <p className={style['sections-body']}>
                5.1 La Cuenta es única y de uso exclusivo de la Farmacia. Por lo tanto, la Farmacia será responsable por
                todas las operaciones efectuadas a través de su Cuenta.
            </p>

            <p className={style['sections-body']}>
                5.2 La Farmacia se compromete a notificar a Extendeal en forma inmediata y por medio fehaciente,
                cualquier uso no autorizado de su Cuenta.
            </p>

            <p className={style['sections-title']}>
                6. <u>Suspensión, inhabilitación y cancelación</u>
            </p>

            <p className={style['sections-body']}>
                6.1 Extendeal podrá suspender o inhabilitar la Cuenta y/o de cancelar la suscripción al Servicio a su
                sola discreción sin exponer las razones de su decisión y sin que ello genere algún derecho a
                indemnización o resarcimiento.
            </p>

            <p className={style['sections-body']}>
                6.2 Sin perjuicio de otras medidas, Extendeal podrá suspender o inhabilitar la Cuenta y/o cancelar la
                suscripción al Servicio en los siguientes casos:
            </p>

            <ul>
                <p className={style['sections-body']}>
                    (a) Si considerara que la Farmacia violó alguna ley, regulación y/o estos Términos y Condiciones;
                </p>

                <p className={style['sections-body']}>
                    (b) Si la Farmacia incurriera, al sólo criterio de Extendeal, en conductas o actos dolosos o
                    fraudulentos; y/o
                </p>

                <p className={style['sections-body']}>
                    (c) Si Extendeal no pudiera verificar cualquier información proporcionada por la Farmacia o dicha
                    información fuere errónea o engañosa.
                </p>
            </ul>

            <p className={style['sections-body']}>
                6.3 Sin perjuicio de la suspensión o inhabilitación de la Cuenta y cancelación de la suscripción al
                Servicio, la Compañía podrá iniciar las acciones legales que estime pertinentes.
            </p>

            <p className={style['sections-title']}>
                7. <u>Declaraciones de la Farmacia</u>
            </p>

            <p className={style['sections-body']}>La Farmacia declara y manifiesta que:</p>

            <p className={style['sections-body']}>
                7.1 El Personal tiene capacidad legal en los términos de la <u>Cláusula 4.2</u>y posee todos los
                permisos de la Farmacia para utilizar el Servicio y para llevar a cabo la adquisición de los Productos a
                través del Sitio y del Servicio. Extendeal no será responsable por las compras de los Productos por
                parte del Personal.
            </p>

            <p className={style['sections-body']}>
                7.2 La suscripción al Servicio no contraviene, entra en conflicto con, ni resulta en un incumplimiento
                contractual (o constituye un acontecimiento que, con su notificación o transcurso del tiempo, o ambos,
                se tornaría un incumplimiento) ni de ninguna otra obligación con terceros.
            </p>

            <p className={style['sections-body']}>
                7.3 Utilizará el Servicio y el Sitio de acuerdo con las normas y leyes vigentes y los presentes Términos
                y Condiciones.
            </p>

            <p className={style['sections-body']}>
                7.4 No realizará ninguna acción o uso de dispositivo, software u otro medio tendiente a interferir tanto
                en las actividades y operatoria de Extendeal como en las ofertas, descripciones, cuentas o bases de
                datos de Extendeal.
            </p>

            <p className={style['sections-body']}>
                7.5 Entiende que la Compañía no hace un análisis previo sobre la veracidad o exactitud de la información
                sobre los Productos provista por los Distribuidores ni tiene control alguno sobre la calidad, seguridad
                o legalidad de los Productos y/o servicios que intercambia la Farmacia y los Distribuidores mediante el
                uso del Servicio y del Sitio. Sin embargo, Extendeal conserva el derecho a su entera discreción para
                rechazar, eliminar o quitar aquel contenido que se haya puesto a disposición de la Farmacia y que
                implique una violación a estos Términos y Condiciones o por cualquier otro motivo.
            </p>

            <p className={style['sections-body']}>
                7.6 Entiende que la Compañía no hace un análisis previo sobre la capacidad de las Farmacias o el
                Personal para utilizar el Servicio y el Sitio, adquirir los Productos y/o utilizar servicios
                relacionados.
            </p>

            <p className={style['sections-body']}>
                7.7 Entiende que la si bien la Compañía ha adoptado las medidas de seguridad de índole técnica y
                organizativas necesarias para garantizar la seguridad de los datos de carácter personal y confidencial y
                evitar su alteración, pérdida y tratamiento y/o acceso no autorizado de conformidad con lo establecido
                en la <u>Cláusula 9</u> de los presentes Términos y Condiciones, las medidas de seguridad en Internet no
                son inexpugnables ni totalmente fiables. Extendeal no puede garantizar la inexistencia de virus u otros
                elementos que pudieran producir alteraciones en los sistemas informáticos (software y hardware) de la
                Farmacia, ni se hace responsable en dichos casos.
            </p>

            <p className={style['sections-title']}>
                8. <u>Limitación de responsabilidad</u>
            </p>

            <p className={style['sections-body']}>
                8.1 El correcto funcionamiento del Servicio está sujeto principalmente a la información proporcionada
                por las Farmacias y los Distribuidores. Extendeal no será responsable por ningún error u omisión de
                dicha información. Extendeal no estará en incumplimiento de la prestación del Servicio ni será
                responsable ante la Farmacia y/o ningún tercero por cualquier daño que pueda sufrir, bajo cualquier
                teoría de responsabilidad, incluyendo, sin limitación, cualquier pérdida de beneficios (incurrida
                directa o indirectamente), pérdida de fondo de comercio o reputación comercial, pérdida de datos, costo
                de adquisición de bienes o servicios sustitutos o cualquier otra pérdida intangible, en relación con la
                limitación o restricción total en el acceso a la información de los Productos provista por los
                Distribuidores o la limitación, bloqueo, modificación de las Credenciales y/o cualquier otro acción y/u
                omisión por parte de las Farmacias y/o Distribuidores que restrinja el acceso de Extendeal a información
                acerca de los Productos esenciales para el correcto funcionamiento del Servicio.
            </p>

            <p className={style['sections-body']}>
                8.2 El Servicio tiene como única y exclusiva fuente de información aquella provista por los
                Distribuidores. Extendeal no será responsable por la veracidad o correctitud de ella ni por los
                resultados comparativos generados por Extendeal como consecuencia.
            </p>

            <p className={style['sections-body']}>
                8.3 El Servicio funciona como una herramienta para la compra de Productos en base a información
                comparativa de estos. La Compañía no se involucra y no será responsable por las decisiones y ejecución
                de las compras de Productos realizadas a través del uso del Sitio y del Servicio, lo cual quedará a
                exclusiva discreción de la Farmacia.
            </p>

            <p className={style['sections-body']}>
                8.4 La Compañía no es responsable por la calidad o características de los Productos. Extendeal no será
                responsable hacia las Farmacias por lucro cesante, pérdida de ganancias, de datos o financieras, ni por
                daños indirectos, especiales, emergentes, ejemplares o punitorios.
            </p>

            <p className={style['sections-body']}>
                8.5 El acceso y uso del Servicio y el Sitio es bajo el propio riesgo y responsabilidad de la Farmacia.
                Extendeal no será responsable por cualquier daño, perjuicio o pérdida de las Farmacias en ocasión del
                acceso y uso del Servicio y del Sitio incluyendo aquellos que fueran causados por fallas en el sistema,
                en el servidor o en Internet. La Compañía tampoco será responsable por cualquier virus que pudiera
                infectar el equipo de las Farmacias como consecuencia del acceso, uso o examen del Sitio o a raíz de
                cualquier transferencia de datos, archivos, imágenes, textos, o contenidos en el mismo. Extendeal no
                garantiza el acceso y uso continuado o ininterrumpido del Sitio. El Servicio y el Sitio pueden
                eventualmente no estar disponibles debido a dificultades técnicas o fallas de Internet, o por cualquier
                otra circunstancia ajena a Extendeal, en cuyo caso se procurará restablecerlo con la mayor celeridad
                posible. Las Farmacias no podrán imputarle a la Compañía responsabilidad alguna ni exigir pago por lucro
                cesante, en virtud de perjuicios resultantes de dificultades técnicas o fallas en los sistemas o en
                Internet que dificulten o impidan acceso y uso del Servicio y del Sitio.
            </p>

            <p className={style['sections-title']}>
                9. <u>Políticas de privacidad y confidencialidad</u>
            </p>

            <p className={style['sections-body']}>
                9.1 La Farmacia es responsable por la veracidad, exactitud, vigencia y autenticidad de la información
                proporcionada a Extendeal y se compromete a mantenerla actualizada en todo momento. Las Farmacias
                prestan expresa conformidad con que Extendeal utilice diversos medios para identificar sus datos
                personales, asumiendo la Farmacia la obligación de revisarlos y mantenerlos actualizados.
            </p>

            <p className={style['sections-body']}>
                9.2 Extendeal almacenará los datos personales en servidores o medios magnéticos que mantienen altos
                estándares de seguridad y protección tanto física como tecnológica. La Compañía realizará esfuerzos
                razonables para adoptar las medidas de seguridad para el tratamiento y conservación de datos personales
                en medios informatizados, a fin de garantizar un nivel adecuado de seguridad de la información, respecto
                al tratamiento de los datos personales.
            </p>

            <p className={style['sections-body']}>
                9.3 La Farmacia podrá solicitar el acceso, la rectificación, actualización y, en caso de corresponder,
                supresión de los datos personales. El titular de los datos personales tiene la facultad de ejercer el
                derecho de acceso a los mismos en forma gratuita a intervalos no inferiores a seis meses, salvo que se
                acredite un interés legítimo al efecto.
            </p>

            <p className={style['sections-body']}>
                9.4 Mediante el uso del del Sitio y del Servicio, la Farmacia reconoce y acepta que la Compañía puede
                acceder y recopilar ciertos datos e información mediante el uso de cookies y otras tecnologías,
                incluyendo: (i) información sobre la interacción y uso del Sitio y del Servicio, tal como el
                comportamiento de clics, las funcionalidades utilizadas, las preferencias indicadas y la adquisición de
                Productos; (ii) intereses y preferencias en Productos; (iii) comportamiento frente a diferencias de
                precios y promociones en los Productos; (iv) datos técnicos como información sobre la URL, datos de
                cookies, dirección IP y; (v) el país y el huso horario. La Farmacia puede gestionar, aceptar o denegar
                el uso de cookies. No obstante, si la Farmacia configura el navegador para desactivar las cookies u
                otras tecnologías, es posible que no pueda acceder a determinadas partes del Sitio o del Servicio o que
                estos no funcionen correctamente o no estén disponibles.
            </p>

            <p className={style['sections-body']}>
                9.5 Mediante el uso del del Sitio y del Servicio, la Farmacia reconoce y acepta que la Compañía podrá
                procesar los datos personales o información recabada conforme la <u>Cláusula 9</u> para ciertos
                propósitos, incluyendo: (i) para ofrecer información sobre el Servicio y con otros fines promocionales,
                publicitarios o de marketing ; (ii) para analizar la prestación del Servicio y potenciales mejoras,
                nuevas funciones, y/o solución de errores; (iii) para entender el comportamiento y preferencias de la
                Farmacia; (iv) para planificación, presentación de informes y proyección de negocios; (v) para realizar
                encuestas; (vi) para usarlos y/o compartirlos para actividades como análisis estadístico y estudios
                académicos, en un formato de seudónimo mediante el uso de un código en lugar del nombre de la Farmacia u
                otra información que identifique a la Farmacia de forma directa.
            </p>

            <p className={style['sections-body']}>
                9.6 Mediante el uso del del Sitio y del Servicio, la Farmacia reconoce y conviene en que Extendeal
                puede, a su sola discreción, conservar por tiempo indeterminado o divulgar información de la Farmacia
                recabada conforme la <u>Cláusula 9</u>, siempre que considere que es necesario por ley o creyendo de
                buena fe que dicha conservación o divulgación es razonablemente necesaria para: (i) cumplir con un
                proceso legal acatar resoluciones judiciales, requerimientos administrativos o la legislación aplicable;
                (ii) exigir el cumplimiento de estos Términos y Condiciones; (iii) responder a reclamos sobre la
                violación de los derechos de terceros en virtud del contenido; (iv) responder reclamos de que
                información de contacto (por ejemplo, número telefónico, domicilio, calle) de un tercero fue anunciada o
                transmitida sin su consentimiento o como una forma de acoso; (v) proteger los derechos, la propiedad, o
                la seguridad de Extendeal, las Farmacias o del público en general, evitando o detectando fraudes,
                abusos, usos ilegales y la vulneración de los Términos y Condiciones.
            </p>

            <p className={style['sections-body']}>
                9.7 Extendeal podrá comunicar información de la Farmacia recabada conforme la <u>Cláusula 9</u> a: a)
                sus sociedades relacionadas; b) proveedores externos de servicios que tratan los datos personales en
                nombre de la Compañía, por ejemplo, con fines de tratamiento de tarjetas de crédito y pagos, gestión y
                mantenimiento de los datos, distribución de correos electrónicos, investigación y análisis, gestión de
                marca y promociones de producto, así como administración de determinados servicios y funciones; c) otros
                terceros en la medida necesaria para: (i) cumplir con requerimientos administrativos, resoluciones
                judiciales o la legislación aplicable; (ii) evitar el uso ilegal del Sitio y/o del Servicio o la
                vulneración de estos Términos y Condiciones y demás políticas de Extendeal; (iii) defensa frente a las
                reclamaciones de terceros; y (iv) ayudar en la prevención o investigación del fraude (por ejemplo,
                falsificación); d) asimismo, la Compañía podrá ceder los datos personales de las Farmacias en el
                supuesto de la venta o transmisión de la totalidad o parte del negocio o activos de Extendeal (incluido
                en caso de reorganización, escisión, disolución o liquidación). La Farmacia entiende y acepta que los
                terceros podrían encontrarse domiciliados fuera del país, incluyendo en algunos casos países que no
                poseen las mismas garantías de protección de datos que las ofrecidas en su país. La Farmacia autoriza
                expresamente la transferencia internacional de los datos personales.
            </p>

            <p className={style['sections-body']}>
                9.8 El Sitio puede enlazar a otros sitios web y aplicaciones (de terceros), con sus propias cláusulas y
                políticas de privacidad, sobre los cuales Extendeal no es responsable. La Compañía se deslinda de toda
                responsabilidad por su contenido, el uso de estos o sus prácticas en materia de privacidad en la medida
                en la que estos sitios web o aplicaciones no sean de propiedad o escapen al control de Extendeal.
                Asimismo, si el enlace al Sitio se obtiene a través de una página ajena, Extendeal no se hace
                responsable de la política de privacidad o prácticas de dicha página.
            </p>

            <p className={style['sections-body']}>
                9.9 En virtud del uso del Sitio y del Servicio, Extendeal tendrá acceso las Credenciales de las
                Farmacias. La Compañía solo utilizará las Credenciales a los fines descriptos por y para los asuntos
                relacionados con la <u>Clausula 2.2</u>. La Compañía protegerá dicha información del mismo modo que
                protege la confidencialidad de información de su propiedad de similar naturaleza y dentro de los
                términos de la <u>Cláusula 8.5</u>.
            </p>

            <p className={style['sections-body']}>
                9.10 Cuando suba, ingrese, almacene, envíe o reciba contenido a o de nuestro sistema informático o a
                través de él, la Farmacia otorgará automáticamente a Extendeal (y a aquellos con quienes trabajamos) una
                licencia internacional para alojar, utilizar, almacenar, reproducir, generar estadísticas, adaptaciones
                o modificaciones del contenido mencionado anteriormente, con la finalidad de brindar un mejor Servicio y
                cumplir con los objetivos de Extendeal.
            </p>

            <p className={style['sections-title']}>
                10. <u>Indemnidades</u>
            </p>

            <p className={style['sections-body']}>
                10.1 La Farmacia indemnizará y mantendrá indemne a Extendeal y a sus afiliadas, directores, agentes y
                empleados de cualquier reclamo, pleito, acción daños, pérdidas, responsabilidades, obligaciones,
                sanciones, intereses, honorarios, gastos (incluidos, entre otros, los honorarios y gastos de los
                abogados y otros profesionales) y costes (incluidos, entre otros, los costes judiciales, los costes de
                liquidación y los costes de búsqueda de indemnizaciones y seguros) relacionados con (a) el uso o mal uso
                de la Farmacia del Sitio y del Servicio o incumplimiento de estos Términos y Condiciones o condiciones
                adicionales que resulten aplicables relacionados al Sitio y al Servicio; (b) cualquier violación o
                incumplimiento de cualquier representación, garantía, convenio o acuerdo hecho por la Farmacia; o (c) la
                violación de la Farmacia de los derechos de un tercero, incluyendo otra Farmacia o Distribuidor.
            </p>

            <p className={style['sections-body']}>
                10.2 La Farmacia se compromete a notificar inmediatamente a la Compañía cualquier reclamo de terceros y
                a cooperar con la Compañía en la defensa de dicho reclamo.
            </p>

            <p className={style['sections-title']}>
                11. <u>Propiedad intelectual e industrial</u>
            </p>

            <p className={style['sections-body']}>
                11.1 La Farmacia reconoce y acepta que todos los derechos de propiedad intelectual e industrial del
                Servicio y del Sitio pertenecen a Extendeal.
            </p>

            <p className={style['sections-body']}>
                11.2 Los contenidos del Servicio y del Sitio incluyendo los programas, código fuente, bases de datos,
                funcionalidad, software, diseño del Sitio, redes, archivos que permiten a las Farmacias acceder y usar
                su Cuenta, y las marcas registradas, las marcas de servicio y los logotipos ( el “<u>Contenido</u>”) son
                de propiedad de Extendeal y están protegidas por las leyes vigentes en los Estados Unidos Mexicanos y
                los tratados internacionales de propiedad intelectual, derecho de autor, marcas, patentes, modelos y
                diseños industriales. El uso indebido y la reproducción total o parcial de dichos contenidos quedan
                prohibidos, salvo autorización expresa y por escrito de Extendeal.
            </p>

            <p className={style['sections-body']}>
                11.3 El uso del Servicio y del Sitio no otorga derecho de propiedad intelectual alguno sobre el Servicio
                y el Sitio o sobre el Contenido al que acceda mediante estos. No podrá utilizar el Contenido a menos que
                obtenga el permiso de Extendeal o que ello esté permitido por las funcionalidades propias del Servicio.
                Estos Términos y Condiciones no otorgan a la Farmacia el derecho de utilizar el Contenido. La Farmacia
                no podrá copiar, modificar, distribuir, vender ni otorgar licencia de parte alguna del Contenido, ni
                podrá realizar ingeniería inversa o intentar extraer el código fuente del software.
            </p>

            <p className={style['sections-title']}>
                12. <u>Misceláneas</u>
            </p>

            <p className={style['sections-body']}>
                12.1 Las Partes son contratistas independientes y nada de lo contenido de estos Términos y Condiciones
                podrá dar lugar a una interpretación que avale la existencia de una relación societaria, de trabajo, ni
                de colaboración empresaria, <i>joint venture</i>, de mandato, de franquicia o de cualquier otra relación
                asociativa o laboral entre las Partes.
            </p>

            <p className={style['sections-body']}>
                12.2 La Farmacia no podrá ceder los derechos y obligaciones establecidas en los Términos y Condiciones.
                La Farmacia, por la presente, autoriza a que la Compañía ceda total o parcialmente los derechos y las
                obligaciones establecidas en los Términos y Condiciones a cualquiera de sus compañías relacionadas o
                vinculadas. Extendeal podrá transferir la información de la Farmacia en caso de venta o fusión o de una
                adquisición de los activos principales de Extendeal o cualquier otra clase de transferencia de la
                Compañía a otra entidad.
            </p>

            <p className={style['sections-body']}>
                12.3 El hecho de que un término o cláusula de los Términos y Condiciones sea considerado inválido, nulo
                o no aplicable por un tribunal con jurisdicción competente en cualquier jurisdicción no afectará la
                validez o ejecución de los Términos y Condiciones o la validez o ejecución del término o cláusula
                objetable en cualquier otra situación o en cualquier otra jurisdicción. Si una sentencia definitiva de
                un tribunal con jurisdicción competente resolviese que cualquier término o cláusula de los Términos y
                Condiciones es inválido, nulo o inaplicable, las Partes negociarán de buena fe para modificar los
                Términos y Condiciones para mantener en la mayor medida posible el espíritu de dicho término o cláusula
                inválido o inaplicable.
            </p>

            <p className={style['sections-body']}>
                12.4 La Farmacia podrá contactarse con Extendeal al siguiente correo electrónico hola@extendeal.com en
                caso de que tenga alguna duda sobre los Términos y Condiciones o aplicación de estos.
            </p>

            <p className={style['sections-body']}>
                12.5 A los efectos de los Términos y Condiciones, Extendeal constituye domicilio especial en la calle
                Londres 241 Piso 4, Colonia Juárez, Cuauhtemoc, Ciudad de México, Estados Unidos Mexicanos y la Farmacia
                en el domicilio informado en la suscripción al Servicio.
            </p>

            <p className={style['sections-body']}>
                12.6 Los Términos y Condiciones se regirán por, y se interpretarán de acuerdo con las leyes de los
                Estados Unidos Mexicanos.
            </p>

            <p className={style['sections-body']}>
                12.7 Toda controversia que se suscite entre las partes con relación a los Términos y Condiciones, su
                existencia, validez, calificación, interpretación, alcance, cumplimiento o resolución, se resolverá
                definitivamente por el Tribunal Superior de Justicia de la Ciudad de México, con exclusión de cualquier
                otro fuero o jurisdicción que pudiera corresponder. Cada Parte de manera irrevocable e incondicionada
                renuncia a cualquier objeción que pueda tener actualmente o en el futuro a dicha jurisdicción incluyendo
                objeciones por razón de falta de jurisdicción personal, jurisdicción improcedente o foro inconveniente.
            </p>
        </div>
    );
};

export default TermsAndConditionsModalMX;
