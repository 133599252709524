import { isNullOrUndefined } from 'utils/strings/isNullOrUndefined';

export const quantityProductsByDrugManufacturer = (t, productQuantity) => {
    if (isNullOrUndefined(productQuantity)) {
        return '';
    }
    return `${productQuantity} ${
        productQuantity === 1
            ? t('efficientPurchasing.comparative.labelFooterProduct')
            : t('efficientPurchasing.comparative.labelFooterProducts')
    }`;
};
