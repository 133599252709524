import { useState, useMemo } from 'react';

const useSubtitleProcessing = (subtitle: string) => {
    const [firstPart, setFirstPart] = useState('');
    const [secondPart, setSecondPart] = useState('');

    useMemo(() => {
        if (subtitle) {
            const [first, second] = subtitle?.split(/,(.+)/)?.map((part) => part?.trim()) || [];
            setFirstPart(first);
            const cleanSecond = second?.endsWith('.') ? second?.slice(0, -1).trim() : second;
            setSecondPart(cleanSecond);
        }
    }, [subtitle]);

    return { firstPart, secondPart };
};

export default useSubtitleProcessing;
