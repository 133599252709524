// components
import { Skeletons } from 'commons/components/Orders/Skeletons';
// rsuite
import { Avatar, Whisper, Tooltip, Popover, AvatarGroup } from 'rsuite';
// interface
import { IMyOrdersList } from './MyOrdersList.interface';
// utils
import { addImageManufactured } from 'commons/modules/ListOrders/ListOrders.utils';
// style
import style from './MyOrdersList.module.css';
import stringAvatar from 'utils/avatar-name';
import { t } from 'i18next';

// mock data
//import { drugmanufacturers } from './data'
const max = 4;
const MyOrdersList = ({ drugmanufacturers, timerSkeleton, initialLettersDrugmanufName }: IMyOrdersList) => {

  return (
        <div>
            <AvatarGroup stack>
                {!!drugmanufacturers &&
                    drugmanufacturers?.length > 0 &&
                    drugmanufacturers
                        .filter((drugs, i) => i < max)
                        .map((drugs, idx) => {
                            // get images
                            const drugList = addImageManufactured(drugs, 'list');

                            return (
                                <Whisper
                                    key={idx}
                                    placement="bottom"
                                    speaker={<Tooltip className={style['drug-tooltip-label']}>{drugList.name}</Tooltip>}
                                >
                                    {!!timerSkeleton ? (
                                        <Skeletons width={24} height={24} animation="wave" variant="circular" />
                                    ) : (
                                        <Avatar circle size="sm" className={style['not-image-avatar']}>
                                            <span className={style['not-image-text']}>
                                                {stringAvatar(drugList.name, false, drugList.id)}
                                            </span>
                                        </Avatar>
                                    )}
                                </Whisper>
                            );
                        })}

                {drugmanufacturers.length - max > 0 && (
                    <Whisper
                        placement="bottomStart"
                        speaker={
                            <Popover arrow={false} className={style['wrapper-popover']}>
                                {!!drugmanufacturers &&
                                    drugmanufacturers.length > 0 &&
                                    drugmanufacturers
                                        .slice(-(drugmanufacturers.length - max))
                                        .map((drug_manufacturer, idx) => {
                                            return (
                                                <div key={idx} className={style['popover-container']}>
                                                    {max - idx > 4 && (
                                                        <div className={style['line-container']}>
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="130"
                                                                height="2"
                                                                viewBox="0 0 130 2"
                                                                fill="none"
                                                            >
                                                                <path
                                                                    d="M0 1H130"
                                                                    stroke="#E0E1E9"
                                                                    stroke-width="0.5"
                                                                />
                                                            </svg>
                                                        </div>
                                                    )}
                                                    <div style={{ display: 'flex' }}>
                                                        <Avatar
                                                            circle
                                                            size="sm"
                                                            className={style['not-image-avatar-list']}
                                                        >
                                                            <span className={style['not-image-text']}>
                                                                {/* @ts-ignore */}
                                                                {stringAvatar(drug_manufacturer.name, false, drug_manufacturer.id)}
                                                            </span>
                                                        </Avatar>
                                                        <p className={style['popover-text']}>
                                                            {/* @ts-ignore */}
                                                            {drug_manufacturer.name}{' '}
                                                        </p>
                                                    </div>
                                                </div>
                                            );
                                        })}
                            </Popover>
                        }
                    >
                        {!!timerSkeleton ? (
                            <Skeletons animation="wave" variant="circular" width={24} height={24} />
                        ) : (
                            <Avatar className={style['avatar-moreQuantity']}>
                                <span className={style['text_avatarMoreQuantity']}>
                                    +{t('orderList.moreDrugManufacturerText', {value: drugmanufacturers.length - max})}
                                </span>
                            </Avatar>
                        )}
                    </Whisper>
                )}
            </AvatarGroup>
        </div>
    );
};

export default MyOrdersList;
