// Import the main component
import { Viewer } from '@react-pdf-viewer/core';

// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import { EX5747 } from 'config/flags';

import handleError from 'utils/pdfViewer/handleError';

const TermsAndConditionsModalCO = () => {
    return (
        <Viewer
            fileUrl={EX5747 ? '/pdf/termsAndConditions/co/termsCOv2.pdf' : '/pdf/termsAndConditions/co/termsCO.pdf'}
            renderError={handleError}
        />
    );
};

export default TermsAndConditionsModalCO;
