// rsuite
import { Form } from 'rsuite'
// interface
import { IFieldFormControl } from './FieldFormControl.interface'
// style
import style from './FieldFormControl.module.css'

const FieldFormControl = ({ field, requiredSteps, state, keyField, handlerChangeInput }: IFieldFormControl) => {
  return (
    <Form.Control
    name={field.name}
    required={requiredSteps.includes(keyField)}
    defaultValue={state.form[field.name]}
    onChange={(value: any) => handlerChangeInput(value, field.name)}
    errorMessage={state.errorsForms[field.name]}
    placeholder={field.placeholder}
    className={style['container']}
/>
  )
}

export default FieldFormControl
