// components
import { Banner } from 'commons/components';
// style
import style from './OutOfOrders.module.css';
// interface
import { IOutOfOrders } from './OutOfOrders.interface';
//gtag
import * as gtag from 'utils/gtag';
import { EX4085, EX4201, EX5057, EX5232 } from 'config/flags';
import { useTranslation } from 'react-i18next';
import { useUser } from 'utils/hooks';
import useChangeLanguage from 'utils/hooks/use-change-language';

const OutOfOrders = ({ handlerClearFilters, customsFilters }: IOutOfOrders) => {
    // translate by country
    const { t } = useTranslation();
    const { user } = useUser({});
    const userLocaleCountryCode = user?.client?.country_alfa_2;
    if (EX4201 || EX4085 || EX5057) useChangeLanguage(userLocaleCountryCode);

    const filters = () => {
        gtag.event({
            action: 'click',
            category: 'my-orders',
            label: 'button__clean-filters-1',
            value: 0,
        });
        handlerClearFilters('all', true);
    };

    return (
        <section className={style['container-out-of-orders']}>
            <div className={style['container-banner']}>
                <Banner
                    image="/images/Group.svg"
                    vector="/images/Vector.svg"
                    title={
                        customsFilters
                            ? EX5232
                                ? t('myOrders.customFiltersViewOne')
                                : 'Lo siento, no pudimos encontrar ningún resultado para tu búsqueda'
                            : EX5232
                            ? t('myOrders.customFiltersViewTwo')
                            : 'No encontramos pedidos en los últimos 7 días'
                    }
                    subtitle={
                        customsFilters
                            ? !EX5057
                                ? 'Revisá los filtros aplicados o realizá una nueva búsqueda. Recordá que podes buscar por número de pedido, puntos de venta, usuarios y drogueria.'
                                : t('myOrders.textHelperCheckFilters')
                            : !EX4085
                            ? 'Podés ampliar la fecha de búsqueda para obtener más resultados'
                            : t('myOrders.textHelperNotFoundOrdersLastSearch')
                    }
                    label={customsFilters ? (EX5232 ? t('myOrders.clearFiltersLabel') : 'Limpiar filtros') : ''}
                    onClick={filters}
                    styleTitle={{ width: customsFilters ? '360px' : '260px ' }}
                    gtagClass='button__clean-filters-1'
                />
            </div>
        </section>
    );
};

export default OutOfOrders;
