// components
import { Dropdown } from 'rsuite';
import { Content } from './Content';
import { Title } from './Title';
// interfaces
import { IDropdownProps } from './DropdownOrder.interface';
// styles
import styles from './DropdownOrder.module.css';

const DropdownOrder = ({ loading, title, data, onSelect, disabled, dropdownOrderRef, gtagClass }: IDropdownProps) => {
    return (
        <Dropdown
            ref={dropdownOrderRef}
            title={<Title loading={loading} title={title} disabled={disabled} />}
            className={`${gtagClass} ${styles['dropdown']}`}
            onSelect={onSelect}
            disabled={disabled}
            noCaret={loading}
        >
            <Content data={data} />
        </Dropdown>
    );
};

export default DropdownOrder;
