import { FC } from 'react';
// utils && hooks
import useChangeLanguage from 'utils/hooks/use-change-language';
import { COUNTRY } from 'utils/country';
// styles
import styles from './LayoutMobile.module.css';

const LayoutMobile: FC = ({ children }) => {
    // ---------------------- translation ----------------
    const userLocale = typeof window !== 'undefined' ? navigator?.language : null;

    switch (userLocale) {
        case 'es-AR':
            useChangeLanguage(COUNTRY.AR);
            break;
        case 'en-US':
            useChangeLanguage(COUNTRY.US);
            break;
        case 'pt-BR':
            useChangeLanguage(COUNTRY.BR);
            break;
        case 'es-MX':
            useChangeLanguage(COUNTRY.MX);
            break;
        case 'es-CO':
            useChangeLanguage(COUNTRY.CO);
            break;
        case 'es-ES':
            useChangeLanguage(COUNTRY.ES);
            break;
        default:
            useChangeLanguage('es');
            break;
    }

    return <div className={styles['container']}>{children}</div>;
};

export default LayoutMobile;
