// Configuración del formulario
export const subscriptionForm = [
    {
        label: 'Nombre del titular de la tarjeta',
        type: 'text',
        name: 'credit_card_holder_name',
        onlyLetters: true,
        mask: false,
        placeholder: 'Nombre del titular de la tarjeta',
    },
    {
        label: 'Número de la tarjeta',
        type: 'text',
        name: 'credit_card_number',
        onlyNumbers: true,
        mask: true,
        placeholder: '#### #### #### ####',
        masked: [
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            ' ',
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            ' ',
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            ' ',
            /\d/,
            /\d/,
            /\d/,
            /\d/,
        ], // Máscara para tarjeta
    },
    {
        label: 'Fecha de vencimiento',
        type: 'text',
        name: 'expiration_date',
        mask: true,
        placeholder: 'MM/YY',
        masked: [/\d/, /\d/, '/', /\d/, /\d/], // Máscara para fecha,
        onlyNumbers: true,
    },
    {
        label: 'Código de seguridad',
        type: 'text',
        name: 'credit_card_cvc',
        onlyNumbers: true,
        mask: true,
        placeholder: '###',
        masked: [/\d/, /\d/, /\d/], // Máscara para código de seguridad
    },
];

 /* Payload: {
        "client_id": 123 (OPCIONAL)
        "credit_card_number": "4444444444445555",
        "credit_card_expiration_month": "09",
        "credit_card_expiration_year": "28",
        "credit_card_cvc": "123"
       } */