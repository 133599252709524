import { memo, useEffect } from 'react';
import isEqual from 'lodash/isEqual';
// COMPONENTS
import { CellProduct } from './CellProduct';
import { Cell } from './Cell';
import { Generics } from './Generics';
// INTERFACE
import { RowProps } from './Row.interface';
// utils
import { EDrugManufacturerTypeId } from 'utils/enums/drugmanufacturerTypeId/DrugManufacturerTypeId';
import { isEmptyObjectAtrribute } from 'utils/object';
import { ServiceId } from 'common/types';
import { useTableRow } from './hooks/useTableRow';
// STYLES
import grid from '../../Grid.module.css';
import styles from './Row.module.css';

const Row = ({
    idx,
    item,
    removeEvent,
    getCreditById,
    setProductInfo,
    openModalWithoutCredit,
    setTypeModalWithoutCredit,
    loading,
}: RowProps) => {
    const {
        gridRef,
        handleCheckboxOnChange,
        hasDrugManufacturerError,
        productGroup,
        setIsManualSelected,
        bonus,
        isAppliedIndex,
        unitPrice,
        lastUnitPrice,
        applyToleranceInDistribution,
        handleOnCheck,
        isChecked,
        drugManufacturerIdSelected,
        areAllItemChecked,
        lowest,
        isSuggested,
        updateQuantityRequired,
        uncheckAllProducts,
        childrenGenerics,
        handleSplitClick,
        productCant,
        drugManufacturer,
        isEnabledDrugManufacturer,
        setShowChildren,
        showChildren,
        generics,
        isGenerics,
        CDO_DRUGMANUFACTURER,
        isLowestSuggested,
        genericsIdsAdd,
        setGenericsIdsAdd,
        EX4800,
        EX6017,
        user,
    } = useTableRow({
        item,
        getCreditById,
        setProductInfo,
        openModalWithoutCredit,
        setTypeModalWithoutCredit,
    });

    // useEffect(() => {
    //     console.log('render ===>', idx, item?.description);
    // },[item]);

    // useEffect(() => {
    //     console.log('productGroup ===>', idx, productGroup?.description);
    // },[productGroup]);

    return (
        <>
            <div
                className={`${grid['grid-system']} 
                ${showChildren ? styles['row-container-expanded'] : styles['row-container']}`}
                style={{ marginTop: idx === 1 ? 0 : 4, marginBottom: generics && showChildren ? 0 : 4 }}
                id={`productIndex-${productGroup?.order_product_id}`}
            >
                <CellProduct
                    idx={idx}
                    description={productGroup?.description}
                    barcode={productGroup?.barcode}
                    laboratory={productGroup?.laboratory}
                    drug={productGroup?.drug}
                    category={productGroup?.category}
                    skeleton={loading}
                    quantity={productGroup?.quantity}
                    handleCheckboxOnChange={handleCheckboxOnChange}
                    withoutChecks={
                        !loading && productGroup?.barcode
                            ? !!areAllItemChecked() && !isEmptyObjectAtrribute(productGroup?.products || [])
                            : false
                    }
                    removeEvent={removeEvent}
                    showChildren={showChildren}
                    setShowChildren={setShowChildren}
                    generics={generics}
                    parentRef={gridRef}
                    isRecommended={productGroup?.is_recommended}
                    orderProductId={productGroup?.order_product_id}
                    user={user}
                    updateQuantityRequired={updateQuantityRequired}
                    drugmanufacturerIdSelected={drugManufacturerIdSelected()}
                    childrenGenerics={childrenGenerics}
                    uncheckAllProducts={uncheckAllProducts}
                    handleSplitClick={handleSplitClick}
                    is_split={productGroup?.is_split}
                    allow_split={productGroup?.allowed_split}
                    maxQuantitySplit={productGroup?.max_quantity ?? 0}
                    initial_quantity={productGroup?.initial_quantity ?? 0}
                    productMaxMin={productCant(drugManufacturerIdSelected())}
                    setIsManualSelected={setIsManualSelected}
                    parentGenericProduct={productGroup?.isFatherGeneric}
                    genericsIdsAdd={genericsIdsAdd}
                />
                <div
                    className={`${!CDO_DRUGMANUFACTURER ? styles['middle-without-cdo'] : styles['middle']} ${
                        grid['grid-column-drugManufacturers']
                    }`}
                    id={`row-product-groups-${idx}`}
                >
                    {drugManufacturer.map(
                        (
                            { id: drugManufacturerId, name, cdo_status_id, type_id, vendor_accepted, enabled },
                            drugManufacturerIdx,
                        ) => {
                            return (
                                <Cell
                                    key={drugManufacturerIdx + 1}
                                    // PRODUCT BY DRUGMANUFACTURER ID
                                    product={productGroup && productGroup?.products[drugManufacturerId]}
                                    productPVP={productGroup?.pvp}
                                    // SKELETON
                                    skeleton={loading}
                                    // DRUGMANUFACTURER
                                    cdo_drugManufacturer={CDO_DRUGMANUFACTURER}
                                    drugManufacturerId={drugManufacturerId}
                                    drugManufacturerEnabled={isEnabledDrugManufacturer(drugManufacturerId)}
                                    drugManufacturerName={name}
                                    hasDrugManufacturerError={hasDrugManufacturerError(drugManufacturerId)}
                                    // CHECKED
                                    checked={isChecked(drugManufacturerId)}
                                    handleOnCheck={handleOnCheck}
                                    // IS LOWEST
                                    lowest={lowest(drugManufacturerId)}
                                    // IS SUGGESTED
                                    isSuggested={isSuggested(drugManufacturerId)}
                                    // SERVICE
                                    productGroupHasServiceByPriority={Boolean(
                                        productGroup?.service?.id === ServiceId.ByPriority,
                                    )}
                                    applyToleranceInDistribution={applyToleranceInDistribution(drugManufacturerId)}
                                    isBestByToleranceInPriorityService={
                                        productGroup?.bestByTolerance === drugManufacturerId
                                    }
                                    // PAYMENT DUE
                                    paymentDue={
                                        productGroup &&
                                        productGroup?.products[drugManufacturerId] &&
                                        productGroup?.products[drugManufacturerId]?.payment_due
                                    }
                                    // BONUS
                                    bonus={bonus(drugManufacturerId)}
                                    isAppliedIndex={isAppliedIndex}
                                    // USER
                                    user={user}
                                    // WITHOUT CHECK
                                    withoutChecks={!!areAllItemChecked()}
                                    // DISCOUNT
                                    lastUnitPrice={lastUnitPrice(drugManufacturerId)}
                                    unitPrice={unitPrice(drugManufacturerId)}
                                    // generics
                                    isGenerics={isGenerics}
                                    // QUANTITY
                                    quantity={productGroup?.quantity}
                                    // identify parent generic product
                                    parentGenericProduct={productGroup?.isFatherGeneric}
                                    //Splited
                                    allowSplit={productGroup?.allowed_split}
                                    isProductGroupSplit={productGroup?.is_split}
                                    // disabled radio
                                    isDisabledVendors={
                                        EX6017 && !vendor_accepted && type_id === EDrugManufacturerTypeId.vendor
                                    }
                                    isLowestSuggested={isLowestSuggested()}
                                />
                            );
                        },
                    )}
                </div>
            </div>

            <div className={styles['row-generics-container']}>
                {EX4800 &&
                    productGroup?.generics?.map((itemGeneric) => {
                        return (
                            <Generics
                                key={itemGeneric?.barcode}
                                drugManufacturer={drugManufacturer}
                                cdo_drugManufacturer={CDO_DRUGMANUFACTURER}
                                idx={idx}
                                skeleton={loading}
                                removeEvent={removeEvent}
                                showChildren={showChildren}
                                setShowChildren={setShowChildren}
                                generics={generics}
                                isEnabledDrugManufacturer={isEnabledDrugManufacturer}
                                user={user}
                                isGenerics={isGenerics}
                                itemGeneric={itemGeneric}
                                orderProductNonGenericId={productGroup?.order_product_id}
                                getCreditById={getCreditById}
                                setProductInfo={setProductInfo}
                                setGenericsIdsAdd={setGenericsIdsAdd}
                                openModalWithoutCredit={openModalWithoutCredit}
                                setTypeModalWithoutCredit={setTypeModalWithoutCredit}
                            />
                        );
                    })}
            </div>
        </>
    );
};

export default memo(Row, (prevProps, nextProps) => isEqual(prevProps.item, nextProps.item));
