// interface
import { ICardHeader } from './CardHeader.interface';
// style
import style from './CardHeader.module.css';

const CardHeader = ({ title , subtitle }: ICardHeader) => {
  return (
    <div className={style['container']}>
      <h3 className={style['title']}>{title}</h3>
      <p className={style['subtitle']}>{subtitle}</p>
    </div>
  )
}

export default CardHeader