import axios from "axios";
import { useState } from "react";
import { apiPharmaInstance } from "utils/axios";

export type PaymentMethod = {
    id: number;
    label: string;
}

const usePaymentMethods = () => {

    const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    
    const getPaymentMethods = async (id: string) => {
        const url = `/api/payment-methods?filters[drug_manufacturer_id]=${id}`
        setLoading(true)
        try {
            const res = await apiPharmaInstance.get(url);
            if (res.data) {
                const formattedMethods: PaymentMethod[] = res.data.data.map(method => {
                    return {
                        id: method.id,
                        label: method.name
                    }
                })
                setPaymentMethods(formattedMethods)
            }
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false);
        }
    }

    return {
        getPaymentMethods,
        results: {
            paymentMethods,
            error,
            loading
        }
    }

}

export default usePaymentMethods;