// COMPONENT
import { Placeholder } from 'rsuite';
// STYLES
import styles from './RecommendedEmpty.module.css';
// FF
import { EX4796 } from 'config/flags';

export const RecommendedEmptyV2 = () => {
    const fake = Array.from({ length: 10 }, (_, idx) => {});

    return (
        <>
            {fake.map((f, index) => {
                return (
                    <div className={styles['container']} key={`recommendedEmptyV2_${index}`}>
                        <div className={styles['parent']} style={EX4796 ? { gap: 4 } : {}}>
                            <Placeholder.Paragraph
                                className={styles['child-offer-label']}
                                active
                                rows={1}
                                rowHeight={EX4796 ? 22 : 24}
                                style={{
                                    minHeight: EX4796 ? 22 : 24,
                                }}
                            />

                            <div className={styles['child-img']}>
                                <Placeholder.Paragraph
                                    graph="image"
                                    active
                                    rows={0}
                                    style={{
                                        height: 150,
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                />
                            </div>
                            <div
                                style={{
                                    minHeight: 41,
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 4,
                                }}
                            >
                                <Placeholder.Paragraph
                                    className={styles['child']}
                                    active
                                    rows={1}
                                    rowHeight={20}
                                    style={{
                                        minHeight: 20,
                                    }}
                                />
                            </div>
                            <div
                                style={{
                                    minHeight: 62,
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 4,
                                }}
                            >
                                <Placeholder.Paragraph
                                    className={styles['child']}
                                    active
                                    rows={1}
                                    rowHeight={20}
                                    style={{
                                        minHeight: 20,
                                    }}
                                />
                                <Placeholder.Paragraph
                                    className={styles['child']}
                                    active
                                    rows={1}
                                    rowHeight={22}
                                    style={{
                                        minHeight: 22,
                                    }}
                                />
                            </div>
                            <Placeholder.Graph
                                className={styles['button']}
                                active
                                style={{
                                    minHeight: 48,
                                }}
                            />
                        </div>
                    </div>
                );
            })}
        </>
    );
};

export default RecommendedEmptyV2;
