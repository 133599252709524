import Image from 'next/image';
import { t } from 'i18next';
import { EX5907 } from 'config/flags';
import { RsuiteTooltip } from '@commons/components/RsuiteTooltip';

interface StatusBadgeProps {
    offline: boolean;
    isEnabled: boolean;
    unknown?: boolean;
    disabledTooltip?: boolean;
}

const StatusBadge = ({ offline, isEnabled, unknown = false, disabledTooltip = false } : StatusBadgeProps) => {
    const VerifiedIconsComponent = () => {
        let iconSrc;
        let altText;
        let tooltipText;

        switch (true) {
            case EX5907 && !isEnabled && !unknown:
                iconSrc = '/assets/images/verified_disabled.svg';
                altText = 'Offline';
                tooltipText = !offline
                    ? t('offlineDrugManufacturer.tooltip.verified')
                    : t('offlineDrugManufacturer.tooltip.notVerified');
                break;
            case offline:
                iconSrc = '/assets/images/not_verified.svg';
                altText = 'Offline';
                tooltipText = t('offlineDrugManufacturer.tooltip.notVerified');
                break;
            case unknown: 
                iconSrc = '/assets/images/not_verified.svg';
                altText = 'Unknown';
                tooltipText = "";
                break;
            default:
                iconSrc = '/assets/images/verified.svg';
                altText = 'Online';
                tooltipText = t('offlineDrugManufacturer.tooltip.verified');
                break;
        }

        return (
            <RsuiteTooltip placement="bottom" text={tooltipText} disabled={disabledTooltip} trigger="hover" marginTop={8}>
                <Image src={iconSrc} alt={altText} width={16} height={16} draggable={false}/>
            </RsuiteTooltip>
        );
    };

    return <VerifiedIconsComponent />;
};

export default StatusBadge;
