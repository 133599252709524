import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { t } from 'i18next';
// components
import { PercentageDistribution } from './PercentageDistribution';
// contexts
import { usePointOfSale } from 'context';
import { useStatusContext } from 'context/control-state';
import { usePrioritiesPercentagesCeContext } from 'context/PrioritiesPercentagesCe/PrioritiesPercentagesCe-context';
// utils
import { useUser } from 'utils/hooks';
// types
import { ServiceId } from 'common/types';

const ModuleDistribution = ({ openModalDistribution, setOpenModalDistribution }) => {
    const router = useRouter();
    const { orderId } = router.query;
    const { user } = useUser({});
    const [newDrugmanufacturer, setNewDrugmanufacturer] = useState([]);
    const [percentages, setPercentages] = useState([]);
    const { pointOfSale } = usePointOfSale();
    const [service, setService] = useState(0);
    const [array, setArray] = useState([]);
    const { response } = useStatusContext();
    const [reloadDistribution, setReloadDistribution] = useState(false);
    const EX3526 = process.env.NEXT_PUBLIC_EX3526 === 'true';
    // new context by prioritys and percentages
    const { data, serviceId } = usePrioritiesPercentagesCeContext();

    // new effect
    useEffect(() => {
        if (serviceId === ServiceId.ByPercentage) {
            handleGetDataDistribution();
        }
    }, [data]);

    const handleGetDataDistribution = async () => {
        if (Object.keys(data)?.length === 0) {
            setPercentages([]);
        } else {
            const responseDistribution = data?.percentages_distributed;
            setPercentages(data);
            setArray(responseDistribution);

            let haveOneCeroPercentage = handleDistributionCeroCounter(responseDistribution);

            const responseWithOutDistribution = data?.without_distribution;
            const updatedValues = [...newDrugmanufacturer];
            if (response && responseWithOutDistribution?.length !== 0 && haveOneCeroPercentage) {
                responseWithOutDistribution.map((item) => {
                    updatedValues[item.id] = {
                        text: EX3526
                            ? t('modals.configModal_newDrugstores')
                            : 'Asígnale un porcentaje a las droguerías nuevas.',
                    };
                });
                setNewDrugmanufacturer(updatedValues);
            } else if (response && responseWithOutDistribution?.length !== 0 && !haveOneCeroPercentage) {
                responseWithOutDistribution.map((item) =>
                    responseDistribution.push({
                        id: null,
                        percentage: 0,
                        drug_manufacturer_id: item.id,
                        distribution_id: null,
                    }),
                );
                setArray(responseDistribution);
            }
        }
    };

    const handleDistributionCeroCounter = (percentages_distributed) => {
        let response = percentages_distributed.find((drugmanufacturer) => drugmanufacturer.percentage === 0);
        return response ? true : false;
    };

    return (
        <PercentageDistribution
            orderId={orderId}
            pointOfSale={pointOfSale}
            percentages={percentages}
            open={openModalDistribution}
            reloadDistribution={reloadDistribution}
            newDrugmanufacturer={newDrugmanufacturer}
            setReloadDistribution={setReloadDistribution}
            setOpen={setOpenModalDistribution}
        />
    );
};

export default ModuleDistribution;
