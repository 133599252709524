import { useModalCredentialsContext } from 'context/ModalCredentials/ModalCredentials-context';
import { ModalConfirmUser } from '../../CredentialCard';
import NewModalCredentials from './NewModalCredentials/NewModalCredentials';
import PreventAlertV2 from './PreventAlert/PreventAlertV2';
import NewModalVendor from './NewModalVendor/NewModalVendor';

const NewCredentialCardConnectionV4 = ({ toggleCard, mutateClientPos }) => {
    // context
    const {
        showPreventAlert,
        modalUser,
        setSendValues,
        handlerOnCloseModal,
        errorMessage,
        fullUser,
    } = useModalCredentialsContext();

    return showPreventAlert ? (
        <PreventAlertV2 />
    ) : (
        <>
            <ModalConfirmUser
                modal={modalUser}
                setSendValues={setSendValues}
                credentials={errorMessage.msgError}
                credential_username={fullUser?.username}
            />
            <NewModalVendor />
            <NewModalCredentials
                modalUser={modalUser}
                mutateClientPos={mutateClientPos}
                toggleCard={toggleCard}
                handlerOnCloseModal={handlerOnCloseModal}
            />
        </>
    );
};

export default NewCredentialCardConnectionV4;
