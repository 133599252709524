import { useTranslation } from 'react-i18next';
// components
import { exclamationMark } from './Icons';
// utils
import useChangeLanguage from 'utils/hooks/use-change-language';
import { useUser } from 'utils/hooks';
// styles
import style from './CartHaveProductsAlerts.module.css';

function CartHaveProductsAlert() {
    const { t } = useTranslation();
    const { userLocaleCountryCode, user } = useUser({});
    const EX6141 = user?.EX6141;
    useChangeLanguage(userLocaleCountryCode);

    return (
        <div className={style['container']} style={EX6141 ? {top: 20} : {}}>
            {exclamationMark}
            <span className={style['text']}>
                {t('efficientPurchasing.fileUpload.efficientPurchasing_fileUploadThirtyOne')}
            </span>
        </div>
    );
}

export default CartHaveProductsAlert;
