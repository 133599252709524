import { EX5232 } from 'config/flags';
import { IFiltersResultsHeader } from './FiltersResultsHeader.interface';
import style from './FiltersResultsHeader.module.css';
import { useTranslation } from 'react-i18next';

const FiltersResultsHeader = ({ searchTerm, resultsCount }: IFiltersResultsHeader) => {
    // translation by Country
    const { t } = useTranslation();
    return (
        <div className={style['result-header-wrapper']}>
            <p className={style['result-text']}>{searchTerm}</p>
            <small className={style['result-qty-label']}>
                {EX5232
                    ? t('results.searchFiltersResultsHeader', { resultsCount: resultsCount })
                    : `${resultsCount} resultados`}
            </small>
        </div>
    );
};

export default FiltersResultsHeader;
