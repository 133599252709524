// COMPONENT
import { Placeholder } from 'rsuite';
// STYLES
import styles from './SkeletonItems.module.css';

export const SkeletonItems = () => {
    const fake = Array.from({ length: 3 }, (_, idx) => {});
    return (
        <>
            {fake.map((f, index) => {
                return <Placeholder.Graph active />;
            })}
        </>
    );
};

export default SkeletonItems;
