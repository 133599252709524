import React from 'react';
// COMPONENTS
import { NumberPart } from '../NumberPart';
import { AmountIconError } from '../AmountIconError';
// UTILS
import { validationMinimumPurchase } from '@commons/components/TableComparisonV2/TableFooter/utils';
import useCurrencyFormatter from 'utils/hooks/useCurrencyFormatter/useCurrencyFormatter';
// STYLES
import styles from '../../../../TableTotals.module.css';
import cellStyles from '../../../../../../CellStyles.module.css';
// INTERFACES
import { ValueProps } from './Value.interface';
import { Placeholder } from 'rsuite';

const Value = ({
    isDisabled,
    user,
    min_purchase,
    subTotalValueColumn,
    t,
    typeRenderFormat,
    skeleton,
    productQuantityByDrugManufacturer,
}: ValueProps): JSX.Element => {
    const dontHavePriceSelection = productQuantityByDrugManufacturer === 0;

    if (skeleton) {
        return <Placeholder.Paragraph rowHeight={16} rows={1} active className={cellStyles['placeholder-price']} />;
    }

    /* #region formatter currency */
    const { formatCurrency } = useCurrencyFormatter();
    const { detailedValues } = formatCurrency({
        value: subTotalValueColumn,
        digitsValidation: 7,
    });
    const minPurchaseFormatted = formatCurrency({
        value: min_purchase,
        digitsValidation: 7,
    });
    const { mainInteger: integer, decimalSeparator, currencySymbol: symbol, cents: decimal } = detailedValues;
    /* #endregion */

    return (
        <>
            {typeRenderFormat ? (
                <div className={styles['total-column']}>
                    <NumberPart
                        content={`${integer}${decimalSeparator}`}
                        disabled={isDisabled}
                        isDecimal={false}
                        dontHavePriceSelection={dontHavePriceSelection}
                    />
                    <NumberPart
                        content={decimal}
                        disabled={isDisabled}
                        isDecimal={true}
                        dontHavePriceSelection={dontHavePriceSelection}
                    />
                    <NumberPart
                        content={symbol}
                        disabled={isDisabled}
                        isDecimal={false}
                        dontHavePriceSelection={dontHavePriceSelection}
                    />
                </div>
            ) : (
                <div className={styles['total-column']}>
                    {validationMinimumPurchase(user, min_purchase, subTotalValueColumn) && (
                        <AmountIconError t={t} value={minPurchaseFormatted?.fullValue} />
                    )}
                    <NumberPart
                        content={symbol}
                        disabled={isDisabled}
                        isDecimal={false}
                        dontHavePriceSelection={dontHavePriceSelection}
                    />
                    <NumberPart
                        content={`${integer}${decimalSeparator}`}
                        disabled={isDisabled}
                        isDecimal={false}
                        dontHavePriceSelection={dontHavePriceSelection}
                    />
                    <NumberPart
                        content={decimal}
                        disabled={isDisabled}
                        isDecimal={true}
                        dontHavePriceSelection={dontHavePriceSelection}
                    />
                </div>
            )}
        </>
    );
};

export default Value;
