import { Grid, Placeholder, Row } from 'rsuite';
import style from './Header.module.css';
import { IHeader } from './Header.interface';
import InfoMessage from 'commons/components/Orders/InfoMessage/InfoMessage';
import { EX4201, EX5057 } from 'config/flags';
import useChangeLanguage from 'utils/hooks/use-change-language';
import { useUser } from 'utils/hooks';
import { useTranslation } from 'react-i18next';

export const Header = ({ total_orders_send, type }: IHeader) => {
    const { t } = useTranslation();
    const { user } = useUser({});
    const userLocaleCountryCode = user?.client?.country_alfa_2;
    if (EX4201) useChangeLanguage(userLocaleCountryCode);
    return (
        <Grid fluid>
            {total_orders_send ? (
                <Row style={{ padding: 4 }}>
                    {total_orders_send && (
                        <span className={style['title']}>
                            {type === 'Manual delivery'
                                ? !!EX5057
                                    ? t('myOrders.drugmanufacturersWithManualDispatch', {
                                          total_orders_send: total_orders_send,
                                      })
                                    : `${total_orders_send} con envío manual`
                                : `${total_orders_send} enviadas`}
                        </span>
                    )}

                    <div className={style['line']} style={{ marginBottom: type === 'Manual delivery' ? 24 : 4 }}></div>
                    {type === 'Manual delivery' && (
                        <InfoMessage
                            children={
                                <span>
                                    {!EX4201
                                        ? 'Si aún no lo hiciste, descargá el TXT y subilo en el sitio web de la droguería. Si esta no permite subir archivos, agregá de a uno los productos al carrito.'
                                        : t('myOrders.orderDetails.textHelperDownloadTxt')}
                                </span>
                            }
                            backgroundColor={'#FFF9E6'}
                            IconColor={'#FFC700'}
                        />
                    )}
                </Row>
            ) : (
                <div style={{ padding: 8 }}>
                    <Placeholder.Paragraph />
                </div>
            )}
        </Grid>
    );
};
