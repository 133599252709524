import { Col, Divider, Placeholder, Row } from "rsuite";
import style from './LoadingBody.module.css';
import SearchResultsLoader from "./SearchResultsLoader/SearchResultsLoader";
import DrawerBodyLoader from "./DrawerBodyLoader/DrawerBodyLoader";
import { ILoadingBody } from "./LoadingBody.interface";

const LoadingBody = ({ isSearchResults, rows = 5 } : ILoadingBody ) => {

    const counterColSize = isSearchResults ? 2 : 7;
    const containerClassName = isSearchResults ? 'search-results-container' : 'container';

    return (
        <>
            {
                Array.from({ length: rows }, (_, idx) => {
                    return (
                        <div key={idx + 1}>
                            <Row className={style[containerClassName]}>
                                {isSearchResults ? <SearchResultsLoader /> : <DrawerBodyLoader />}
                                <Col className={style['button']} xs={counterColSize}>
                                    <Placeholder.Graph
                                        style={{
                                            marginTop: 6,
                                            height: 45
                                        }}
                                        active
                                    />
                                </Col>
                            </Row>
                            {!isSearchResults && <Divider style={{ margin: 0 }} />}
                        </div>
                    )
                })
            }
        </>
    )
}

export default LoadingBody;