import React from 'react';
// CONTEXTS
import { useModalCdO } from 'context/modalCdO/modalCdO';
// COMPONENTS
import { Chip } from '../../../../Chip';
import { Badges } from '../../../../Badges';
import { Price } from '../Price';
import { CellWrapper } from '../CellWrapper';
import { Radio } from '../Radio';
import { PriceDiscount } from '../PriceDiscount';
import { BestPriceBadge } from '../BestPriceBadge';
// UTILS
import { CHIP_DISCOUNT_COLOR } from 'utils/enums/chipDiscount';
import { shouldDisplayChip, shouldDisplayPriceDiscount } from '../utils';
import { handlerClickRadio, shouldDisplayBestPriceBadge, shouldDisplayRadio } from './utils';
// INTERFACES
import { DrugManufacturersId } from 'utils/interfaces';
// STYLES
import styles from './CellCdo.module.css';
import cellStyles from '../../../../CellStyles.module.css';
import { OfferCenters } from 'utils/enums';

const CellCdo = ({
    checked,
    onChecked,
    skeleton,
    product,
    productPVP,
    chipColor,
    isLowest,
    applyToleranceAccordingToService,
    cdo_status_id,
    isSuggested,
    drugManufacturerEnabled,
    drugManufacturerName,
    hasDrugManufacturerError,
    bonus,
    isAppliedIndex,
    user,
    withoutChecks,
    lastUnitPrice,
    unitPrice,
    quantity,
    isProductGroupSplit = false,
    isCellSplit,
    isLowestSuggested
}) => {
    /* #region context modal cdo */
    const { openModal } = useModalCdO();
    /* #endregion */

    return (
        <CellWrapper
            // CDO
            isCdo={true}
            cdo_status_id={cdo_status_id}
            // PRODUCT
            product={product}
            // BACKGROUND COLOR
            isSuggested={isSuggested}
            isLowest={isLowest}
            applyToleranceAccordingToService={applyToleranceAccordingToService}
            checked={checked}
            withoutChecks={withoutChecks}
            isProductGroupSplit={isProductGroupSplit}
            isLowestSuggested={isLowestSuggested}
        >
            <div>
                <div className={`${cellStyles['top-without-padding']}`}>
                    {shouldDisplayBestPriceBadge({ skeleton, isLowest, cdo_status_id }) ? (
                        <BestPriceBadge />
                    ) : (
                        !!product &&
                        !hasDrugManufacturerError && (
                            <Badges
                                drugManufacturerId={DrugManufacturersId.CentralDeOfertas}
                                product={product}
                                depositName={null}
                                paymentDue={null}
                                applyToleranceAccordingToService={applyToleranceAccordingToService}
                                drugManufacturerEnabled={drugManufacturerEnabled}
                            />
                        )
                    )}
                    <div className={`${cellStyles['price-section']} ${styles['cdo-price-section']}`}>
                        {shouldDisplayRadio({ cdo_status_id, hasDrugManufacturerError }) && (
                            <Radio
                                skeleton={skeleton}
                                hasDrugManufacturerError={hasDrugManufacturerError}
                                product={product}
                                checked={checked}
                                withoutChecks={withoutChecks && cdo_status_id !== OfferCenters.Disabled}
                                handleClick={(e: any) => handlerClickRadio({ e, cdo_status_id, openModal, onChecked })}
                                quantity={quantity}
                            />
                        )}
                        <Price
                            drugManufacturerEnabled={drugManufacturerEnabled}
                            skeleton={skeleton}
                            hasDrugManufacturerError={hasDrugManufacturerError}
                            product={product}
                            checked={checked}
                            isLowest={isLowest}
                            chipColor={chipColor}
                            userLocale={user?.client?.country_alfa_2}
                            unitPrice={unitPrice}
                        />
                        {shouldDisplayPriceDiscount({ skeleton, product, hasDrugManufacturerError }) && (
                            <PriceDiscount lastUnitPrice={lastUnitPrice} />
                        )}
                    </div>
                </div>
                {shouldDisplayChip({
                    skeleton,
                    product,
                    bonus,
                    hasDrugManufacturerError,
                    isCellSplit: isCellSplit,
                }) && (
                    <Chip
                        skeleton={skeleton}
                        chipColor={!drugManufacturerEnabled && !skeleton ? CHIP_DISCOUNT_COLOR.DISABLED : chipColor}
                        product={product}
                        productPVP={productPVP}
                        drugManufacturerName={drugManufacturerName}
                        drugManufacturerId={DrugManufacturersId.CentralDeOfertas}
                        bonus={bonus}
                        quantityForBonus={product?.quantity}
                        isAppliedIndex={isAppliedIndex}
                        checked={checked}
                        user={user}
                    />
                )}
            </div>
        </CellWrapper>
    );
};

export default CellCdo;
