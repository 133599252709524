import React from 'react';
import { t } from 'i18next';
// components
import { ModalTerms } from '@commons/components/Modals/ModalT&C';
import { ModalPrivacyPolicy } from '@commons/components/Modals/ModalPrivacyPolicy';
// styles
import styles from './PolicyOrTerms.module.css';

const PolicyOrTerms = ({
    checkIfHasPolicyAndTerms,
    checkIfHasTerms,
    checkIfHasPolicy,
    openModalTerms,
    setOpenModalTerms,
    openModalPolicy,
    setOpenModalPolicy,
    userLocaleCountryCode,
}) => {
    return (
        <p className={`link__login-terms-and-conditions-1 ${styles['msg-terms-conditions']}`}>
            {t('login.login18')} {/* Terms & Cond */}
            {checkIfHasTerms(userLocaleCountryCode?.CountryCode) && (
                <>
                    <a
                        className={`link__login-terms-and-conditions-1 ${styles['as-link']}`}
                        onClick={() => {
                            setOpenModalTerms(!openModalTerms);
                        }}
                    >
                        {t('footer.footer_Two')}
                    </a>
                    <ModalTerms
                        openModal={openModalTerms}
                        setOpenModal={() => setOpenModalTerms(!openModalTerms)}
                        setLinkActive={() => {}}
                        expandedFooter={false}
                        userLocaleCountryCode={userLocaleCountryCode?.CountryCode}
                        fullWidth={true}
                    />
                </>
            )}
            {checkIfHasPolicyAndTerms(userLocaleCountryCode?.CountryCode) && ' y '}
            {/*Privacy and Policy*/}
            {checkIfHasPolicy(userLocaleCountryCode?.CountryCode) && (
                <>
                    <a
                        className={`link__login-privacy-and-policy-1 ${styles['as-link']}`}
                        onClick={() => {
                            setOpenModalPolicy(!openModalPolicy);
                        }}
                    >
                        {t('footer.footer_Six')}
                    </a>
                    <ModalPrivacyPolicy
                        openModal={openModalPolicy}
                        setOpenModal={() => setOpenModalPolicy(!openModalPolicy)}
                        setLinkActive={() => {}}
                        expandedFooter={false}
                    />
                </>
            )}
        </p>
    );
};

export default PolicyOrTerms;
