// @ts-nocheck

import { COUNTRY } from './country';

export function formatMoney(amount, decimalCount = 2, decimal = ',', thousands = '.') {
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = Number.isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? '-' : '';

        const i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))).toString();
        const j = i.length > 3 ? i.length % 3 : 0;

        return (
            negativeSign +
            (j ? i.substr(0, j) + thousands : '') +
            i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thousands}`) +
            (decimalCount
                ? decimal +
                  Math.abs(amount - i)
                      .toFixed(decimalCount)
                      .slice(2)
                : '')
        );
    } catch (e) {
        console.log(e);
    }
}

export const withTaxPrice = (amount, priceSymbol) => `${priceSymbol} ${amount} +IVA`;

// formatear moneda según pais (user)
export const formatMoneyCurrency = (
    user,
    userLocaleCountryCode,
    amount,
    withSpace = false, //esta prop genera un espacio entre el simbolo y el numero al retornar
    decimalCount = 2,
    decimal = ',',
    thousands = '.',
) => {
    if (userLocaleCountryCode !== COUNTRY.ES && userLocaleCountryCode !== COUNTRY.BR) {
        if (withSpace) {
            return `$ ${formatMoney(amount)}`;
        } else {
            return `$${formatMoney(amount)}`;
        }
    } else if (userLocaleCountryCode === COUNTRY.ES) {
        if (withSpace) {
            return `${formatMoney(amount)} €`;
        } else {
            return `${formatMoney(amount)}€`;
        }
    } else if (userLocaleCountryCode === COUNTRY.BR) {
        if (withSpace) {
            return `R$ ${formatMoney(amount)} `;
        } else {
            return `R$${formatMoney(amount)}`;
        }
    }
};
