import { useEffect, useRef, useState } from 'react';
// contexts
import { useProgressBarPriceContext } from 'context';

interface IuseCircleProps {
    inProgress: boolean;
    isLastItem: boolean;
    isComplete: boolean;
}

const useCircle = ({ inProgress, isLastItem, isComplete }: IuseCircleProps) => {
    // context
    const { redirectToComparative, failed, currentStep, handleCompleted, completed } = useProgressBarPriceContext();
    // refs
    const scaleUpCenterRef: any = useRef(null);
    // delay aplicado para comenzar con la animación de la barra
    const [animation, setAnimation] = useState<boolean>(false);
    // ready apply fade-out in icon and push
    const [fadeIconCheck, setFadeIconCheck] = useState<boolean>(false);
    // activate final animation
    const [activateFinalAnimation, setActivateFinalAnimation] = useState<boolean>(false);
    // expanding background final animation
    const [expandingCircle, setExpandingCircle] = useState<boolean>(false);
    // heartbeat animation
    const [heartbeat, setHeartbeat] = useState<boolean>(false);

    useEffect(() => {
        if (currentStep === 4 && isLastItem && isComplete) {
            setTimeout(() => {
                setActivateFinalAnimation(true);
            }, 200);
        }
    }, [isComplete, currentStep]);

    const handleRedirectTable = () => {
        setFadeIconCheck(true);
    };

    useEffect(() => {
        // delay aplicado para comenzar con la animación de la barra
        if (!!inProgress) {
            setTimeout(() => {
                setAnimation(true);
            }, 300);
        }
    }, [inProgress]);

    const showExpandingCircle = (e: any) => {
        // heartbeat es la animación de pulso final
        if (/fade-in-right/.test(e?.animationName) && isLastItem && !failed) {
            // heartbeat es la animación de pulso final
            setHeartbeat(true);
        }
        if (/heartbeat/.test(e?.animationName) && !failed) {
            handleCompleted();
            setExpandingCircle(true);
        }
        if (/fade-out/.test(e?.animationName) && isLastItem && !failed) {
            setTimeout(() => {
                redirectToComparative();
            },700);
        }
    };

    return {
        animation,
        fadeIconCheck,
        scaleUpCenterRef,
        handleRedirectTable,
        currentStep,
        failed,
        completed,
        activateFinalAnimation,
        expandingCircle,
        showExpandingCircle,
        heartbeat,
    };
};

export default useCircle;
