import { COUNTRY } from 'utils/country';
import { formatMoney } from 'utils/number';

export const youAreSaving = (t, value) => {
    let youAreSavingValue = '';
    if (value === 0) {
        youAreSavingValue = '0';
    } else {
        youAreSavingValue = formatMoney(value);
    }
    return t('efficientPurchasing.comparative.efficientPurchasing_comparativeSeven', {
        valueMoney: youAreSavingValue,
    });
};

export const youCanSaving = (value, symbol, userLocaleCountryCode) => {
    let youCanSavingValue = '';
    if (value === 0) {
        youCanSavingValue = '0';
    } else {
        youCanSavingValue = formatMoney(value);
    }
    return value
        ? userLocaleCountryCode !== COUNTRY.ES
            ? ' ' + symbol + youCanSavingValue
            : youCanSavingValue + symbol
        : ' ' + symbol + 0;
};
