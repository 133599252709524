import React, { memo } from 'react';
import Image from 'next/image';

const Header = () => {
    return (
        <>
            <Image
                src={'/assets/images/IsologoVertical.svg'}
                alt="extendeal logo"
                width={161}
                height={83.7}
                draggable={false}
            />
        </>
    );
};

export default memo(Header);
