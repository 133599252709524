import { makeStyles } from "@material-ui/core/styles";
import { rem } from "polished";

export const useStyles = makeStyles((theme) => ({
  label: {
    backgroundColor: "white",
    paddingLeft: 10,
    paddingRight: 10,
  },
  dirty: {
    position: "relative",
    "&::before": {
      content: '""',
      position: "absolute",
      backgroundColor: "#ffa900",
      width: rem("6px"),
      height: rem("6px"),
      borderRadius: rem("6px"),
      left: -10,
      bottom: 20,
    },
  },
  menuSelect: {
    height: "40px",
    width: "96px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    color: "#646464",
    border: "1px solid #ACACAC",
    font: "normal normal normal 16px/17px Source Sans Pro",
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    justifyContent: "space-between",
    paddingLeft: 10,
    paddingRight: 10,
    marginLeft: 32,
    transition: "all 0.02s linear",
    "&:hover": {
      paddingRight: "calc(10px - 2px)",
      paddingLeft: "calc(10px - 2px)",
      height: "calc(40px + 3px)",
      border: "3px solid #ACACAC",
    },
  },
  inputLabel: {
    "&.MuiFormLabel-root.Mui-focused": {
      color: "rgb(29, 202, 211)",
    },
    marginLeft: 10,
    backgroundColor: "white",
    paddingRight: 20,
  },
  inputSelect: {
    "&.MuiFormLabel-root.Mui-focused": {
      color: "#1DCAD3",
    },
    "&.MuiOutlinedInput-root": {
      "&.Mui-focused": {
        "&.MuiOutlinedInput-notchedOutline": {
          borderColor: "#1DCAD3",
          borderWidth: 2,
        },
      },
    },
  },
}));

export default useStyles;
