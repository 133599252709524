// next
import Link from 'next/link';
// interface
import { IRenderFooter } from './RenderFooter.interface';
// style
import style from './RenderFooter.module.css';
// mui v5
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const RenderFooter = ({ values, clientId, valueSearch, handleOpen, pointOfSale }: IRenderFooter) => {
  return (
    <Link
      href={`/${clientId}/efficient-purchase/${pointOfSale}/search-results?search=${valueSearch}`}
      onClick={() => {
        handleOpen(false)
      }}
    >
      <div className={style['footer-item']}>
        <p
          className={style['footer-title']}
        >{values && `Mostrar los ${values} resultados`}</p>
        {values && <ChevronRightIcon className={style['icon-footer']} />}
      </div>
    </Link>
  );
};

export default RenderFooter;
