import React from 'react';
import styles from './FooterSidebarHelp.module.css';
import CloseIcon from '@mui/icons-material/Close';
import { t } from 'i18next';
const FooterSidebarHelp = ({ closePopover }) => {
    return (
        <div className={styles.frameParent}>
        <div className={styles.contentWrapper}>
            <div className={styles.necesitasAyuda}>{t('PLG.Step4.Title')}</div>
            <div className={styles.siTensDudas}>
                {t('PLG.Step4.Label')}
            </div>
        </div>
        <button className={styles.closeButton} onClick={closePopover}>
            <CloseIcon />
        </button>
    </div>
    
    );
};

export default FooterSidebarHelp;
