// next
import Link from 'next/link';
import { t } from 'i18next';
// rsuite
import { Sidenav } from 'rsuite';
// style
import style from './HeadSidebar.module.css';
import { NewUseCart, useAlert, useCreateOrder, useNewSearchProduct, usePointOfSale, useSnackbar } from 'context';
import { EX4378, EX5057, EX5225 } from 'config/flags';
import { useDragAndDrop } from 'context/dragAndDropContext';
import { useRouter } from 'next/router';
import { handlerLink } from '../BodySidebar/handlers/handler.routing';
import { isZettiFormat } from 'utils/pointOfSale';
import { useUser } from 'utils/hooks';

const HeadSidebar = ({ clientId }) => {
    // router
    const router = useRouter();
    const { user } = useUser({});
    // reset visibility on switch routes while progress bar is active
    const { handleResetVisibility } = NewUseCart();
    const { handleOpen } = useNewSearchProduct();
    const { setVisibleDragDrop } = useDragAndDrop();
    const { showLoadingScreen, handleSwitchViewsCancelOrder } = useCreateOrder();
    // show modal
    const snackbar = useSnackbar();
    const { show } = useAlert();
    const { pointOfSale } = usePointOfSale();

    let posSelected = null;

    // retrieve a Point of Sale from localStorage, ensuring we are in a client-side rendering environment.
    if (typeof window !== 'undefined') {
        const posItem = localStorage.getItem('pdvSelected');
        if (posItem) {
            posSelected = JSON.parse(posItem);
        }
    }

    const href =
        clientId && posSelected?.id
            ? `/${clientId}/efficient-purchase/${posSelected?.id}`
            : `/${clientId}/efficient-purchase`;

    // handler for the link click if the flag and showLoadingScreen is not active will use the href of Link component
    const handleLinkClick = (event) => {
        if (EX4378 && showLoadingScreen) {
            event.preventDefault();
            show({
                className: '',
                title: EX5225
                    ? t('efficientPurchasing.fileUpload.efficientPurchasing_fileUploadTwentyEight')
                    : 'Descartar',
                cancel: true,
                size: 'xs',
                body: EX5225
                    ? t('efficientPurchasing.fileUpload.efficientPurchasing_fileUploadTwentyNine')
                    : 'Se descartará la obtención de datos.',
                action: EX5057 ? t('efficientPurchasing.comparative.labelConfirmDiscard') : 'Si, descartar',
                onConfirm: () => {
                    handlerLink(href, router, clientId, posSelected?.id);

                    if (!isZettiFormat(pointOfSale)) {
                        setVisibleDragDrop(false);
                    }

                    handleOpen(false);
                    handleResetVisibility();
                    setTimeout(() => {
                        handleSwitchViewsCancelOrder();
                        snackbar.show({
                            message: EX5225 ? t('snackbar.labelDiscardOrder') : 'Se descartó la compra.',
                        });
                    }, 100);
                },
                onCancel: () => {},
            });
        }
    };

    return (
        <Sidenav.Header>
            <Link href={!EX4378 ? `/${clientId}/efficient-purchase` : href} onClick={handleLinkClick}>
                <div className={style['wrapper-image']}>
                    <img src="/assets/images/vectores.svg" alt="isologo extendeal" />
                </div>
            </Link>
        </Sidenav.Header>
    );
};

export default HeadSidebar;
