import { t } from "i18next";
import { Modal } from "components";
import Button from "components/Button";
import { useStyles } from "./ModalPercentageDistribution.styles";

export default function ConfirmDeletePercentage({
  modal,
  handleDeletePercentage,
  handleConfirmDelete,
}) {
  const classes = useStyles();
  
  return (
    <Modal fullWidth Width="300px" Percentage={true} open={modal}>
      {" "}
      <Modal.Title>{t('modals.comparativeOne') || 'Eliminar porcentajes'}</Modal.Title>
      <Modal.Content style={{ paddingTop: 0, color: "#646464", fontSize: 16 }}>
       { t('modals.comparativeTwo') || 'Se eliminará la distribución de compra por porcentajes y deberás seleccionar manualmente los precios iguales.'}
      </Modal.Content>
      <Modal.Actions style={{ marginTop: 8 }}>
        <Button
          variant="outlined"
          className={classes.button}
          onClick={handleConfirmDelete}
        >
          {t('modals.comparativeFour') || 'Cancelar'}
        </Button>

        <Button
          variant="contained"
          className={classes.buttonBackgroung}
          onClick={handleDeletePercentage}
        >
          {t('modals.comparativeThree') || 'Eliminar'}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
