// react
import { useEffect, useRef } from 'react';
// Component
import { ExButton, FormInput } from '@commons/EXComponentsLibrary';
// Rsuite
import { Panel, Form, SelectPicker, CheckPicker, Checkbox, Button } from 'rsuite';
// style
import style from './ClientFormOpenpay.module.css';
// hook
import { useUser } from 'utils/hooks';

const ClientFormOpenpay = ({ handleSendFormData, stateManagers }) => {
    // Ref
    const picker = useRef(null);
    
    // State Manager
    const {
        setFormData,
        setErrors,
        setEmailError,
        plans,
        formData,
        posByClients,
        setSelectedPos,
        setIsButtonDisabled,
        emailError,
        selectedPos,
        errors,
        isLoading,
        isButtonDisabled,
        fetching,
    } = stateManagers;

    // context
    const { user } = useUser({});
    // flag
    const EX7454 = user?.EX7454;

    // Handles form field changes and updates the corresponding state
    const handleChange = (value, name) => {
        setFormData(prevData => ({ ...prevData, [name]: value }));
        
        setErrors(prevErrors => ({ 
            ...prevErrors, 
            [name]: prevErrors[name] ? '' : prevErrors[name] 
        }));
    
        name === 'email' && validateEmail(value);
    };

    // Validates email format
    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setEmailError(email && !emailRegex.test(email) ? 'Por favor ingresa un correo electrónico válido.' : '');
    };

    // Determines the amount of the selected plan or provides a placeholder message
    const selectedPlanAmount =
        plans.find((plan) => plan.value === formData.plan)?.amount || 'seleccioná un plan para ver el valor';
    const currencyAmount = !!formData.plan ? 'MXN' : '';

    // Check all POS
    const allValues = posByClients.map((plan) => plan.value);
    const handleCheckAll = (checked) => {
        const newPos = checked ? allValues : []; // Determina el nuevo valor para pos
        setSelectedPos(newPos); // Actualiza selectedPos
        setFormData((prevData) => ({
            ...prevData,
            pos: newPos, // Actualiza formData
        }));
    };

    useEffect(() => {
        setIsButtonDisabled(
            !(formData.pos && formData.plan && formData.email && !emailError)
        );
    }, [formData, emailError]);

    return (
        <Panel
            className={style['container-form-panel']}
            style={{ width: EX7454 ? '100%' : '600px' }}
            header={
                <span className={style['header-panel-form']}>
                    ¿A qué punto/s de venta queres enviarle un link de pago?
                </span>
            }
        >
            <Form>
                {/* Selector de pos*/}
                {EX7454 && (
                    <Form.Group controlId="pos">
                        <Form.ControlLabel className={style['container-label']}>
                            Puntos de venta <span style={{ color: '#e22e2e' }}>*</span>
                        </Form.ControlLabel>
                        <CheckPicker
                            ref={picker}
                            loading={fetching.pos}
                            data={posByClients}
                            className={
                                errors.pos ? style['input-picker-selector-error'] : style['input-picker-selector']
                            }
                            placeholder="Seleccioná uno o más puntos de venta"
                            value={selectedPos} // Agregar selectedPos como value
                            onChange={(value) => {
                                setSelectedPos(value);
                                handleChange(value, 'pos');
                            }}
                            renderExtraFooter={() =>
                                posByClients.length > 0 ? (
                                    <div className={style['footerStyles']}>
                                        <Checkbox
                                            indeterminate={
                                                selectedPos.length > 0 && selectedPos.length < allValues.length
                                            }
                                            checked={selectedPos.length === allValues.length}
                                            onChange={(_, checked) => handleCheckAll(checked)}
                                        >
                                            Seleccionar todo
                                        </Checkbox>

                                        <Button
                                            className={style['footerButtonStyle']}
                                            appearance="primary"
                                            size="sm"
                                            onClick={() => picker.current?.close()}
                                        >
                                            Aplicar
                                        </Button>
                                    </div>
                                ) : null
                            }
                        />
                        {errors.pos && <p className={style['error-text']}>{errors.pos}</p>}
                    </Form.Group>
                )}

                {/* Selector de planes */}
                <Form.Group controlId="plan">
                    <Form.ControlLabel className={style['container-label']}>
                        Planes de suscripción <span style={{ color: '#e22e2e' }}>*</span>
                    </Form.ControlLabel>
                    <SelectPicker
                        data={plans}
                        loading={fetching.plan}
                        className={errors.plan ? style['input-picker-selector-error'] : style['input-picker-selector']}
                        placeholder="Seleccioná un plan"
                        value={formData.plan}
                        onChange={(value) => handleChange(value, 'plan')}
                    />
                    {errors.plan && <p className={style['error-text']}>{errors.plan}</p>}
                    <p className={style['text-amount']}>
                        Valor: {selectedPlanAmount} {currencyAmount}
                    </p>
                </Form.Group>

                {/* Campo de email */}
                <FormInput
                    name="email"
                    type="email"
                    label="Email"
                    placeholder="Ingresá el email de quien va a recibir el link de pago"
                    value={formData.email}
                    onChange={(value) => handleChange(value, 'email')}
                    error={emailError || errors.email}
                    isRequired={true}
                />
                {/* Botón de envío */}
                {!EX7454 && (
                    <div className={style['container-button-send']}>
                        <ExButton
                            loading={isLoading}
                            disabled={isButtonDisabled || isLoading}
                            className={style['button']}
                            onClick={handleSendFormData}
                            style={{ width: EX7454 ? '10%' : '30%' }}
                        >
                            Enviar
                        </ExButton>
                    </div>
                )}
            </Form>
        </Panel>
    );
};

export default ClientFormOpenpay;
