// components
import { ConnectedButtons } from './ConnectedButtons';
import { SuggestedButtons } from './SuggestedButtons';
// interfaces
import { IActionsButtonsProps } from './ActionsButtons.interface';

const ActionsButtons = ({
    child,
    from,
    handleRemove,
    onClickRequestVendor,
    isVendorRequested,
    disabledEdit,
}: IActionsButtonsProps) => {
    return (
        <div style={{ justifyContent: from === 2 ? 'flex-end' : 'flex-start' }}>
            {from === 2 ? (
                <ConnectedButtons child={child} from={from} disabledEdit={disabledEdit} />
            ) : (
                <SuggestedButtons
                    child={child}
                    handleRemove={handleRemove}
                    onClickRequestVendor={onClickRequestVendor}
                    isVendorRequested={isVendorRequested}
                />
            )}
        </div>
    );
};

export default ActionsButtons;
