import { getEnabledDrugManufacturers } from 'api/orders';

const useFetchEnabledDrugManufacturers = () => {
    
    const fetchEnabledDrugManufacturers = async (orderId, setDrugManufacturer) => {
        const enabledDrugManufacturer = await getEnabledDrugManufacturers(orderId);
        const response = enabledDrugManufacturer?.data?.data;
        setDrugManufacturer(response);
    };

    return {
        fetchEnabledDrugManufacturers
    };
}

export default useFetchEnabledDrugManufacturers;