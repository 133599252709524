import { useEffect, useRef } from 'react';
// COMPONENTS
import { CellProductHeader } from './CellProductHeader';
import { CellDrugManufacturerHeader } from './CellDrugManufacturerHeader';
import { CellDrugManufacturerCdo } from './CellDrugManufacturerCdo';
// UTILS
import { InsufficientCreditWarning, useProductComparison } from 'context/product-comparison.context';
import { useUser } from 'utils/hooks';
import {
    useDrugManufacturersContext,
    useFetchingContext,
    useInsuficientCreditContext,
    useProductsContext,
    useScrollFooterContext,
} from 'context';
import { useCDOStatus } from '@commons/modules/TableProductComparisonV2/hooks';
// INTERFACES
import { DrugManufacturersId } from 'utils/interfaces';
// STYLES
import styles from './TableHeader.module.css';
import grid from '../Grid.module.css';

const TableHeader = ({ fetchProductGroup }) => {
    const { user } = useUser({});
    const popoverPortalDiv = useRef();
    const EX5506 = user?.EX5506; //FF epic insufficientCredit V2
    const EX6144 = user?.EX6144;

    const { getSubtotalById, getSubtotalByIdByKellerhoff } = useProductComparison();
    const { products } = useProductsContext();

    const { loading } = useFetchingContext();

    const { setInsufficientCreditIDs } = useInsuficientCreditContext();

    const { drugManufacturer } = useDrugManufacturersContext();

    const { CDO_IS_VISIBLE } = useCDOStatus();

    const { headerRef, handleHeaderScroll } = useScrollFooterContext();

    useEffect(() => {
        if (EX5506 && products.length > 0) {
            const manufacturersWithCreditInfo = drugManufacturer.filter(
                (manufacturer) => manufacturer.credit_available !== null,
            );
    
            const manufacturersWithInsufficientCredit = manufacturersWithCreditInfo.filter((manufacturer) => {
                return manufacturer.credit_available < getSubtotalById(manufacturer.id);
            });
    
            // IDs de Kellerhoff
            const kellerhoffIds = [6, 13, 14, 15, 16, 17, 22, 23];
    
            // Buscar si hay algún Kellerhoff en manufacturersWithCreditInfo
            const kellerhoffManufacturer = manufacturersWithCreditInfo.find((manufacturer) =>
                kellerhoffIds.includes(manufacturer.id)
            );
    
            // Determinar si el crédito de Kellerhoff es insuficiente
            const isKellerhoffInsufficient =
                kellerhoffManufacturer && kellerhoffManufacturer.credit_available < getSubtotalByIdByKellerhoff(kellerhoffManufacturer.id);
    
            // Lista de IDs con crédito insuficiente
            const insufficientIDs: InsufficientCreditWarning[] = [
                ...manufacturersWithInsufficientCredit.map(({ id }, idx) => ({
                    id,
                    isWarningOpen: idx === 0, // Solo el primero tiene tooltip abierto
                })),
                ...(isKellerhoffInsufficient
                    ? kellerhoffIds.map((id,idx) => ({
                          id,
                          isWarningOpen: idx === 0, // Si Kellerhoff tiene crédito insuficiente, activamos el tooltip para todas sus IDs
                      }))
                    : []),
            ];
    
            setInsufficientCreditIDs(insufficientIDs);
        }
    }, [EX5506, drugManufacturer, getSubtotalById, products, loading]);
    

    return (
        <div
            className={`${grid['grid-system']} ${styles['table-header']}`}
            style={{ top: EX6144 ? 64 : 0, gridRow: EX6144 ? 1 : 2 }}
        >
            <div style={{ display: 'grid', gridColumn: '1 / 2' }}>
                <CellProductHeader />
            </div>
            <div
                style={{
                    display: 'grid',
                    gridColumn: '2 / 3',
                    gridAutoFlow: 'column',
                    overflowX: 'auto',
                    overflowY: 'hidden',
                }}
                className={`${CDO_IS_VISIBLE ? styles['middle'] : styles['middle-without-cdo']}`}
                ref={headerRef}
                onScroll={handleHeaderScroll}
            >
                {drugManufacturer.map((item: any, idx) => {
                    return (
                        <div key={idx + 1}>
                            {item.id === DrugManufacturersId.CentralDeOfertas ? (
                                <CellDrugManufacturerCdo
                                    item={item}
                                    parentHeaderRef={popoverPortalDiv}
                                    fetchProductGroup={fetchProductGroup}
                                />
                            ) : (
                                <CellDrugManufacturerHeader
                                    item={item}
                                    idx={idx + 1}
                                    parentHeaderRef={popoverPortalDiv}
                                    fetchProductGroup={fetchProductGroup}
                                />
                            )}
                        </div>
                    );
                })}
            </div>
            <div ref={popoverPortalDiv} style={{ gridColumn: '2 / 3', position: 'relative' }} />
        </div>
    );
};

export default TableHeader;
