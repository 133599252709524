// components
import { ExAvatarConnections, ExButtonONOFF } from '@commons/EXComponentsLibrary';
import { Description } from './Description';
// utils && hooks
import stringAvatar from 'utils/avatar-name';
import { typesOfErrors } from 'utils/drugManufacturers/errors';
import { EDrugManufacturerTypeId } from 'utils/enums/drugmanufacturerTypeId/DrugManufacturerTypeId';
// interfaces
import { IInformationProps } from './Information.interface';

const Information = ({ from, drugmanufacturer, parentRef, idx, disabledEdit }: IInformationProps) => {
    return (
        <div>
            {from === 1 ? (
                <ExAvatarConnections
                    initialsName={stringAvatar(drugmanufacturer?.name, false, 1)}
                    status={drugmanufacturer?.type?.id === EDrugManufacturerTypeId.vendor ? 'extendeal' : 'disconnected'}
                    style={{ width: 32, height: 32, fontSize: 12 }}
                />
            ) : (
                <ExButtonONOFF
                    initialState={
                        typesOfErrors(drugmanufacturer)?.hasSomeError ? null : drugmanufacturer?.is_on ? 'ON' : 'OFF'
                    }
                    drugManufacturerId={drugmanufacturer?.id}
                    record={drugmanufacturer}
                    from='home'
                />
            )}
            {/* -------------------- name drugmanufacturer -------------------- */}
            <Description child={drugmanufacturer} from={from} parentRef={parentRef} idx={idx} disabledEdit={disabledEdit} />
        </div>
    );
};

export default Information;
