import { Notification, Button } from 'rsuite'
import styles from './RsuiteNotification.module.css'

export const RsuiteNotification = ({ text, action, handlePress, closeable = false, gtagClass }) => {
    return (
        <div className={styles['wrapper-notification']}>
            <Notification type={'info'} header={''} closable={closeable} className={gtagClass}>
                <p>{text}</p>
                {action && (
                    <Button onClick={handlePress} className={gtagClass}>
                        <span className={gtagClass}>{action}</span>
                    </Button>
                )}
            </Notification>
        </div>
    )
}
