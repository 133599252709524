// next
import { useRouter } from 'next/router';
// components
import { ExButton } from '@commons/EXComponentsLibrary';
// style
import style from './RenderFormButton.module.css';
// rsuite
import { ButtonToolbar } from 'rsuite';
// interface
import { IRenderFormButton } from './RenderFormButton.interface';
// context
import { useAlert } from 'context';
import { auxiliary } from './utils';

const RenderFormButton = ({ state, setState, clientId, fx, toaster }: IRenderFormButton) => {
    // next
    const router = useRouter();
    
    // context
    const { show } = useAlert();

    const fxs = auxiliary();

    const redirect = () =>
        fxs.preventRedirect(`/clients/${router.query.clientId}/${router.query.typeId}`, router, show, setState);

    return (
        <div className={style['actions-container']}>
            <div className={style['container-buttons']}>
                <ButtonToolbar>
                    <ExButton
                        appearance="ghost"
                        disabled={!state.isFormModified}
                        className={style['secondary-button']}
                        onClick={() => redirect()}
                    >
                        Cancelar
                    </ExButton>
                    <ExButton
                        appearance="primary"
                        disabled={!state.isFormModified}
                        className={style['primary-button']}
                        onClick={() =>
                            fx.updateInformationOfDrugmanufacturer(setState, clientId, state, toaster)
                        }
                        loading={state.isLoadingEdit}
                    >
                        Guardar cambios
                    </ExButton>
                </ButtonToolbar>
            </div>
        </div>
    );
};

export default RenderFormButton;
