import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Image from 'next/image';
// configs
import { EX4332 } from 'config/flags';
// components
import { InstitutionalHeader, InstitutionalLayout } from '@commons/components';
import { Content } from './Content';
import { Footer } from './Content/Form/Footer';
import { FooterMobile } from '../FormSignupMobile';

const FormSignup = () => {
    const { push } = useRouter();
    const [isMobile, setIsMobile] = useState(false);
    const MOBILEBREAKPOINT = 768;

    useEffect(() => {
        // Redirigir si no está habilitado EX4332
        if (!EX4332) {
            push('/login');
            return;
        }

        // Manejo de redimensionamiento de pantalla
        const handleResize = () => {
            setIsMobile(window.innerWidth <= MOBILEBREAKPOINT);
        };

        handleResize(); // Ejecuta la función al cargar
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [push]);

    return (
        <>
            <InstitutionalLayout>
                <InstitutionalHeader>
                    <a href="https://extendeal.com/es-mx/plg-la-plataforma-de-compra-inteligente?hs_preview=tWsmLXHs-186119984912">
                        <Image
                            src={'/assets/images/institutional_logo.svg'}
                            alt="institutional logo"
                            width={210}
                            height={25}
                            priority={true}
                        />
                    </a>
                </InstitutionalHeader>
                {isMobile ? (
                    <>
                        <Content />
                        <FooterMobile />
                    </>
                ) : (
                    <>
                        <Content />
                        <Footer />
                    </>
                )}
            </InstitutionalLayout>
        </>
    );
};

export default FormSignup;
