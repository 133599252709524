import React from 'react';
import { Panel } from 'rsuite';
import style from './DeliveriesCard.module.css';
import DetailsDeliveries from './DetailsDeliveries';
import Header from './Header';
import { DeliveryTypes, IDeliveriesCard } from './DeliveriesCard.interface';

function DeliveriesCard({ order_deliveries, type }: IDeliveriesCard) {
    return (
        <Panel
            shaded
            className={style['wrapper-card']}
            style={{ borderTopColor: type === DeliveryTypes.MANUAL ? '#FFC700' : '#07AD4B' }}
            header={<Header total_orders_send={order_deliveries?.length} type={type} />}
        >
            {order_deliveries?.map((order) => (
                <DetailsDeliveries order_deliveries={order} type={type} deliveryDetail={false} />
            ))}
        </Panel>
    );
}

export default DeliveriesCard;
