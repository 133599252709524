import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";
import { Col } from "rsuite";
import { Button } from "rsuite";
import { IFooter } from "./Footer.interface";
import { useUser } from "utils/hooks";
//gtag
import * as gtag from "utils/gtag";
// hook
import { useUrlContext } from "context/url-context";
import { ButtonSolid } from "commons/components/Button";
import { EX5057 } from 'config/flags';
import { useTranslation } from 'react-i18next';

function Footer({ delivery_id, order_number }: IFooter) {
  const { t } = useTranslation();
  const { user } = useUser({});
  const router = useRouter();
  // context
  const { savePrevUrl, urlPrev } = useUrlContext();

  const handleOrderDetail = () => {
    gtag.event({
      action: "click",
      category: "my-orders",
      label: "button__order-detail",
      value: 0,
    });
  };
  return (
    <Col
      xs={24}
      sm={24}
      style={{ display: "flex", justifyContent: "flex-end" }}
    >
      <Link
        href={`/${user?.client?.id}/my-orders/deliveries/${delivery_id}/${order_number}`}
        onClick={() => {
          handleOrderDetail();
          savePrevUrl(router.asPath);
        }}
      >
        <ButtonSolid
          icon={""}
          text={!!EX5057
            ? t('myOrders.labelshowDetails')
            :"Ver detalle"}
          width={88}
          height={32}
          detailType={true}
          disabled={false}
          gtagClass="button__order-detail"
        />
      </Link>
    </Col>
  );
}

export default Footer;
