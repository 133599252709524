// next
import Link from 'next/link'
// interface
import { IRenderFooter } from './RenderFooter.interface'
// style
import style from './RenderFooter.module.css'
// mui v5
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

const RenderFooter = ({ values, clientId, valueSearch, handleOpen, pointOfSale }: IRenderFooter) => {
    return (
        <Link
            href={`/${clientId}/efficient-purchase/${pointOfSale}/search-results?search=${valueSearch}`}
            onClick={() => handleOpen(false)}
            className="link__show-results"
        >
            <div className={`link__show-results ${style['footer-item']}`}>
                <p className={`link__show-results ${style['footer-title']}`}>{`Mostrar los ${values} resultados`}</p>
                <ChevronRightIcon className={`link__show-results ${style['icon-footer']}`} />
            </div>
        </Link>
    )
}

export default RenderFooter
