import { t } from 'i18next';
// interfaces
import { IErrorsProps } from './Errors.interface';
// styles
import styles from './Errors.module.css';

const Errors = ({ errors, errorRefContainer }: IErrorsProps) => {
    return (
        <div className={`${styles['errors']} ${styles['fade-in']}`} ref={errorRefContainer}>
            <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                    <path
                        d="M8.5 11.3333C8.68888 11.3333 8.84722 11.2694 8.975 11.1416C9.10277 11.0138 9.16666 10.8555 9.16666 10.6666C9.16666 10.4777 9.10277 10.3194 8.975 10.1916C8.84722 10.0638 8.68888 9.99992 8.5 9.99992C8.31111 9.99992 8.15277 10.0638 8.025 10.1916C7.89722 10.3194 7.83333 10.4777 7.83333 10.6666C7.83333 10.8555 7.89722 11.0138 8.025 11.1416C8.15277 11.2694 8.31111 11.3333 8.5 11.3333ZM8.5 8.66659C8.68888 8.66659 8.84722 8.6027 8.975 8.47492C9.10277 8.34714 9.16666 8.18881 9.16666 7.99992V5.33325C9.16666 5.14436 9.10277 4.98603 8.975 4.85825C8.84722 4.73047 8.68888 4.66659 8.5 4.66659C8.31111 4.66659 8.15277 4.73047 8.025 4.85825C7.89722 4.98603 7.83333 5.14436 7.83333 5.33325V7.99992C7.83333 8.18881 7.89722 8.34714 8.025 8.47492C8.15277 8.6027 8.31111 8.66659 8.5 8.66659ZM8.5 14.6666C7.57777 14.6666 6.71111 14.4916 5.9 14.1416C5.08888 13.7916 4.38333 13.3166 3.78333 12.7166C3.18333 12.1166 2.70833 11.411 2.35833 10.5999C2.00833 9.78881 1.83333 8.92214 1.83333 7.99992C1.83333 7.0777 2.00833 6.21103 2.35833 5.39992C2.70833 4.58881 3.18333 3.88325 3.78333 3.28325C4.38333 2.68325 5.08888 2.20825 5.9 1.85825C6.71111 1.50825 7.57777 1.33325 8.5 1.33325C9.42222 1.33325 10.2889 1.50825 11.1 1.85825C11.9111 2.20825 12.6167 2.68325 13.2167 3.28325C13.8167 3.88325 14.2917 4.58881 14.6417 5.39992C14.9917 6.21103 15.1667 7.0777 15.1667 7.99992C15.1667 8.92214 14.9917 9.78881 14.6417 10.5999C14.2917 11.411 13.8167 12.1166 13.2167 12.7166C12.6167 13.3166 11.9111 13.7916 11.1 14.1416C10.2889 14.4916 9.42222 14.6666 8.5 14.6666ZM8.5 13.3333C9.98888 13.3333 11.25 12.8166 12.2833 11.7833C13.3167 10.7499 13.8333 9.48881 13.8333 7.99992C13.8333 6.51103 13.3167 5.24992 12.2833 4.21659C11.25 3.18325 9.98888 2.66659 8.5 2.66659C7.01111 2.66659 5.75 3.18325 4.71666 4.21659C3.68333 5.24992 3.16666 6.51103 3.16666 7.99992C3.16666 9.48881 3.68333 10.7499 4.71666 11.7833C5.75 12.8166 7.01111 13.3333 8.5 13.3333Z"
                        fill="#E22E2E"
                    />
                </svg>
                <p className={styles['text-error']}>{t('progressBarPrice.body4')?.split(':')?.[0]}:</p>
            </div>
            <div>
                {errors?.map((error, idx) => {
                    return (
                        <div className={`${styles['box']} ${styles['fade-in']}`}>
                            <p>{error?.name}</p>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Errors;
