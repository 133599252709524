import React from 'react';
// STYLES
import cdoStyles from '../CellCdo.module.css';

const RenderUpcomingMessage = (): JSX.Element => (
    <div>
        <img src="/assets/images/schedule.svg" alt="watch icon" width={24} height={24} />
        <p className={cdoStyles['isRequestedText']}>Próximamente</p>
    </div>
);

export default RenderUpcomingMessage;
