import React from 'react';
import style from './Footer.module.css';
import { Col } from 'rsuite';
import Link from 'next/link';
import { EX5057 } from 'config/flags';
import { useTranslation } from 'react-i18next';

function Footer() {
    //translation by Country
    const { t } = useTranslation();

    const showMailTo = () => {
        window.open('mailto:hola@extendeal.com?subject=Extendeal Pharma | Contacto', '_blank');
    };

    return (
        <Col xs={24} className={style['col-container']}>
            <span className={`link__login-contact-us ${style['span-colContainer-contact']}`} onClick={showMailTo}>
                {EX5057 ? t('login.login3') : 'Contáctanos'}
            </span>
        </Col>
    );
}

export default Footer;
