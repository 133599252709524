import { useState, useEffect } from 'react';

const usePositionArrowRight = (containerRef: any, showCdo: boolean, tableRef: any, renderTable: boolean) => {
    const [arrowRight, setArrowRight] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            if (showCdo) {
                if (tableRef?.current) {
                    // Accedemos al segundo elemento hijo del grid
                    const secondElement = tableRef?.current?.children[1]?.children[1];

                    if (secondElement && secondElement?.children?.length > 0) {
                        // Accedemos al último hijo del segundo elemento
                        const lastChild = secondElement?.children[secondElement?.children?.length - 1];

                        if (lastChild) {
                            // Obtenemos el ancho y la posición del último hijo
                            const { offsetWidth } = lastChild;

                            setArrowRight(offsetWidth + 15);
                        }
                    }
                }
            } else {
                const container = containerRef?.current;
                if (container) {
                    const containerRect = container?.getBoundingClientRect();
                    const containerLeft = containerRect?.left; // Margen izquierdo del contenedor
                    setArrowRight(containerLeft + 15);
                }
            }
        };

        handleScroll(); // Llama a la función para posicionar la flecha al cargar la página

        const container = containerRef?.current;
        if (container) {
            window?.addEventListener('resize', handleScroll);
        }

        return () => {
            if (container) {
                window?.removeEventListener('resize', handleScroll);
            }
        };
    }, [containerRef?.current, tableRef?.current, showCdo, renderTable]); // Escucha los cambios en containerRef para recalcular la posición de la flecha

    return { arrowRight };
};

export default usePositionArrowRight;
