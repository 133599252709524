// configs
import { EX7066 } from 'config/flags';
// context
import { useModalCdO } from 'context/modalCdO/modalCdO';
import { useDrugManufacturersContext, useSelectAllStateContext } from 'context';
// gtag
import * as gtag from 'utils/gtag';
// utils
import { drugManufacturerGtagEvent } from 'utils/gtagEventDM';
import { StatusCdo } from 'utils/interfaces';
// api
import { postEnabledDrugManufacturers } from 'api';

export const cellEvents = (flag, item, selectAll, setSelectAll, isEnabled, id, query, fetchProductGroup) => {
    const { openModal } = useModalCdO();

    // const {
    //     selectAllProductByDMId,
    //     // setSelectAllState,
    //     // cleanProducts
    // } = useProductComparison();

    const {
        enabledOnOff,
        setEnabledOnOff,
        setDrugManufacturer,
        drugManufacturer,
        isFailedConnectionByDrugManufacturerId,
        lastDrugManufacturerEnabled,
    } = useDrugManufacturersContext();

    const { setSelectAllState } = useSelectAllStateContext();

    const CDO_VALIDATION_STATUS = !!flag && flag === 'cdo' && item.cdo_status_id !== StatusCdo.ENABLED;

    /**
     * Handles all by id.
     */
    // const handleSelectAllById = (id) => {
    //     setSelectAll(true);
    //     setSelectAllState(id);
    //     selectAllProductByDMId(id);
    // };
    /**
     * Handles the selection of all items.
     */
    const handlerSelectAll = () => {
        if (CDO_VALIDATION_STATUS) {
            // Close popover if it exists and open modal
            openModal();
            return;
        } else {
            // Toggle selectAll state and close popover if it exists
            setSelectAll(!selectAll);
        }
    };

    /**
     * Handles the unselection of all items.
     */
    const handlerUnselectAll = () => {
        // Clean products, set selectAll to false, reset selectAllState, and enable retry loading
        setSelectAll(false);
        setSelectAllState(null);
        fetchProductGroup();
    };

    /**
     * Handles the on/off event.
     */
    const handleOnOffEvent = (flag = null) => {
        // If validation status is true, do nothing
        if (CDO_VALIDATION_STATUS) {
            return;
        }

        // Deselect all if both selectAll and isEnabled are true
        if (selectAll && isEnabled) {
            setSelectAll(false);
        }

        // Handle click on column and log execution
        handleClickOnColumn(flag);

        // if (!selectAll) {
        //     setSelectAllState(null);
        // }
    };

    /**
     * Handles click on column.
     */
    const handleClickOnColumn = async (flag = null) => {
        // If validation status is true, do nothing
        if (CDO_VALIDATION_STATUS) {
            return;
        }

        // Check for failed connection or single enabled manufacturer
        if (
            isFailedConnectionByDrugManufacturerId(id) ||
            (lastDrugManufacturerEnabled()?.length === 1 && lastDrugManufacturerEnabled()[0]?.id === id)
        ) {
            return;
        }

        // Toggle the enabled status of the manufacturer
        const updatedDrugManufacturers = drugManufacturer.map((manufacturer) =>
            manufacturer.id === id ? { ...manufacturer, enabled: !manufacturer.enabled } : manufacturer,
        );

        // Filter enabled manufacturer IDs and update state
        const onlyEnabledIds = updatedDrugManufacturers
            .filter((item) => item.enabled && !isFailedConnectionByDrugManufacturerId(item.id))
            .map((item) => item.id);
        const enabledDrugManufacturers = await postEnabledDrugManufacturers(query?.orderId, onlyEnabledIds);

        if(EX7066) {
            if(flag === null) {
                fetchProductGroup();
            }
        }else {
            fetchProductGroup();
        }
        setDrugManufacturer(enabledDrugManufacturers?.data?.data); //actualizar array de droguerias
        const oneDisabledDrugManufacturer = enabledDrugManufacturers?.data?.data?.some((item) => {
            return item.enabled === false;
        });
        if (enabledOnOff !== oneDisabledDrugManufacturer) setEnabledOnOff(oneDisabledDrugManufacturer); //actualizar si existe alguna apagada
        if(!EX7066) {
            if (!selectAll) {
                setSelectAllState(null);
            }
        }
    };

    /**
     * Logs Google Analytics event for on/off drug manufacturer.
     * @param {Array} enabledDMResponse - Array of enabled drug manufacturers
     * @param {string} drugManufacturerId - ID of the drug manufacturer
     */
    const gtagEventOnOffDrugManufacturer = (enabledDMResponse, drugManufacturerId) => {
        // Retrieve manufacturer details and determine update type
        const drugManufacturer = enabledDMResponse.find((dm) => dm.id === drugManufacturerId);
        const updatedDrugManufacturerNameEvent = getDrugManufacturerEventByDMId(drugManufacturerId);
        const typeUpdate = drugManufacturer?.enabled ? 'on' : 'off';

        // Log gtag event
        gtag.event({
            action: 'click',
            category: 'efficent-purchase__on-off-drugstore',
            label: `button__${typeUpdate}-d${updatedDrugManufacturerNameEvent}`,
            value: 0,
        });
    };

    /**
     * Gets the event name for the given drug manufacturer ID.
     * @param {string} drugManufacturerId - ID of the drug manufacturer
     * @returns {string} - Event name
     */
    const getDrugManufacturerEventByDMId = (drugManufacturerId) => {
        // Retrieve the event name for the given manufacturer ID
        const drugManufacturer = drugManufacturerGtagEvent.find((dm) => dm.id === drugManufacturerId);
        return drugManufacturer?.name_event;
    };

    return {
        handleOnOffEvent,
        handlerUnselectAll,
        handlerSelectAll,
        // handleSelectAllById,
    };
};
