import React from 'react';
// COMPONENTS
import PlusIcon from '@rsuite/icons/Plus';
// INTERFACES
import { AddButtonInterfaceProps } from './AddButton.interface';
// STYLES
import styles from '../RecommendedCellProduct.module.css';

const AddButton = ({
    skeleton,
    addRecommendation,
    recommendation,
    drugManufacturer,
}: AddButtonInterfaceProps): JSX.Element => (
    <div className={styles['button']}>
        <button onClick={() => addRecommendation(recommendation?.[0])} disabled={skeleton}>
            <PlusIcon color={skeleton ? '#ACAEC4' : '#0171E6'} className="button_add-recommended-product-2" />
            {drugManufacturer?.length <= 7 && !skeleton && <span className={styles['add-text']}>Agregar</span>}
        </button>
    </div>
);

export default AddButton;
