import { useEffect, useState } from 'react';
// components
import { Form } from './Form';
import { SuccessView } from '../../SuccessView';
import { EX7648 } from 'config/flags';
import { Loader } from 'rsuite';

const Content = () => {
    const [step, setStep] = useState<number>(0);
    const [formValues, setFormValues] = useState<any>({});

    useEffect(() => {
        setFormValues({});
        setStep(0);
    }, []);

    

    return (
        <>
            {step === 0 ? (
                <Form setStep={setStep} formValues={formValues} setFormValues={setFormValues} />
            ) : !EX7648 ? (
                <SuccessView email={formValues.email} />
            ) : (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: 50,
                        marginBottom: 142,
                        color: '#fff',
                        fontSize: 14,
                        fontFamily: 'Plus Jakarta Sans',
                    }}
                >
                    Loading...{' '}
                    <span style={{ marginLeft: 8 }}>
                        <Loader color="cyan" />
                    </span>
                </div>
            )}
        </>
    );
};

export default Content;
