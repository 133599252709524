import { t } from 'i18next';
import { useState, useEffect, useRef } from 'react';
import Joyride, { STATUS } from 'react-joyride';
import { Tooltip } from './Steps';
import { useStatusContext } from 'context/control-state';
import { usePointOfSale } from 'context';
import { isZettiFormat } from 'utils/pointOfSale';
import { newNormalOnboarding } from './array';
import { apiPharmaInstance } from 'utils/axios';
import { useUser } from 'utils/hooks';
import { useRouter } from 'next/router';
import { UTM_CAMPAIGN_WT, UTM_CONTENT_WT, UTM_MEDIUM_WT, UTM_SOURCE_WT, UTM_TERM_WT } from 'config/flags';
const NewWalkthrough = () => {
    const router = useRouter();
    const { replace, pathname, query } = router;
    const { clientId, posId } = query;
    const joyrideRef = useRef(null);
    const { user, mutateUser } = useUser({});
    const {
        setCounterStep,
        isTourOpen,
        setIsTourOpen,
        setDisplayModalOnBoarding,
        hideSuggestionPDV,
        setContinueStatus,
    } = useStatusContext();
    const EX4332 = user?.EX4332;
    const [{ run, steps }, setState] = useState<any>({
        run: user?.show_walkthrough_free_trial && EX4332 && pathname === '/[clientId]/efficient-purchase/[posId]' && !hideSuggestionPDV,
        steps: newNormalOnboarding(),
    });
    const [stepIndex, setStepIndex] = useState(5);
    const [domLoaded, setDomLoaded] = useState(false);
    let firstLogin = '';
    let checkOnboarding = false;
    let pdv = '';
    if (typeof window !== 'undefined') {
        firstLogin = localStorage.getItem('firstLogin');
        checkOnboarding = localStorage.getItem('checkOnboarding') !== 'true';
        pdv = localStorage.getItem('pdvSelected');
    }
    const { pointOfSale } = usePointOfSale();

    const isZetti = isZettiFormat(pointOfSale);


    useEffect(() => {
        setDomLoaded(true);

        if (firstLogin === 'true') {
            setStepIndex(3);
        }

        if (localStorage.getItem('pdvSelected') !== null && !isZetti && checkOnboarding) {
            setStepIndex(0);
            setIsTourOpen(true);
            setDisplayModalOnBoarding(true);
        }
    }, [firstLogin]);

    const handleJoyrideCallback = (data) => {
        let finish = false;
        const { action, index, type, status } = data;

        if (action === 'close') {
            finish = true;
            setStepIndex(0);
            setState({ run: false });
            //fetchCompletedWalkthrough();
            //mutateUser();
        }
        if (action === 'prev' && type === 'step:after' && !finish) {
            setStepIndex(index - 1);
        }
        if (action === 'next' && type === 'step:after' && !finish) {
            setStepIndex(index + 1);
        }
        if (action === 'close' && type === 'tour:end') {
            setStepIndex(0);
            setState({ run: false });
            setContinueStatus(status);
            
            
        }
    };

    const handleStepActions = (obj) => {
        if (obj.action === 'close' || obj.action === 'stop') {
            setCounterStep('body');
            setIsTourOpen(false);
            if (obj.step.target === '#step4') {
                localStorage.setItem('checkOnboarding', 'true');
            }
        } else {
            setCounterStep(obj.step.target);
        }
    };
    return (
        <>
            {!domLoaded && stepIndex !== 20 ? (
                <> </>
            ) : (
                <>
                    <Joyride
                        //@ts-ignore
                        tooltipComponent={Tooltip}
                        steps={steps}
                        run={run}
                        stepIndex={stepIndex}
                        callback={(obj) => {
                            handleJoyrideCallback(obj);
                            handleStepActions(obj);
                        }}
                        continuous
                        disableOverlayClose
                        disableScrollParentFix
                        disableScrolling
                        spotlightClicks={false}
                        disableOverlay={false}
                        styles={{
                            options: {
                                overlayColor: 'transparent',
                                primaryColor: 'blue',
                                textColor: '#004a14',
                                width: 382,
                                zIndex: 99999,
                            },
                        }}
                        ref={joyrideRef}
                    />
                </>
            )}
        </>
    );
};

export default NewWalkthrough;
