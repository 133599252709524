import React from 'react';
// COMPONENTS
import { Placeholder } from 'rsuite';
// UTILS
import { CHIP_DISCOUNT_COLOR } from 'utils/enums/chipDiscount';
import { renderPrice } from '@commons/components/TableComparisonV2/utils';
import { PriceValue } from '@commons/components/TableComparisonV2/PriceValue';
// STYLES
import styles from '../RecommendedCellPrice.module.css';
import cellStyles from '../../../CellStyles.module.css';
import { PriceSectionProps } from './PriceSection.interface';
import { shouldDisplayPriceDiscount } from '@commons/components/TableComparisonV2/TableProducts/Row/Cell/utils';
import { EX5788 } from 'config/flags';

const PriceSection = ({
    recommendationLimit,
    skeleton,
    drugManufacturerEnabled,
    hasDrugManufacturerError,
    product,
    chipColor,
    cdo_is_visible,
    isLastCell,
    setShowRecommendations,
    userLocale,
    lastUnitPrice,
    unitPrice,
}: PriceSectionProps): JSX.Element => {


    const renderPriceValueComponent = () => {
        if (skeleton) {
            return <Placeholder.Paragraph rowHeight={16} rows={1} active className={cellStyles['placeholder-price']} />;
        } else if (!hasDrugManufacturerError) {
            return <PriceValue product={product} userLocale={userLocale} chipColor={chipColor} unitPrice={unitPrice} />;
        } else if(hasDrugManufacturerError) {
            return <p className={cellStyles['line']}>-</p>
        }
        return null;
    };

    return (
        <div className={`${cellStyles['price-section']}`}>
            {!recommendationLimit && (
                <div
                    className={`${cellStyles['price']} ${
                        !drugManufacturerEnabled && !skeleton ? cellStyles['drugManufacturerDisabled'] : ''
                    }`}
                >
                    <p>{renderPriceValueComponent()}</p>
                </div>
            )}
            {!skeleton &&
                !recommendationLimit && !EX5788 &&
                shouldDisplayPriceDiscount({ skeleton, product, hasDrugManufacturerError }) && (
                    <div className={cellStyles['price-discount']}>
                        <p> {renderPrice(lastUnitPrice)}</p>
                    </div>
                )}
            {!cdo_is_visible && recommendationLimit && isLastCell && !skeleton && (
                <div className={styles['limit-recommendation-close']}>
                    <p onClick={() => setShowRecommendations()}>
                        Cerrar <img src="/assets/images/close_blue.png" width={16} height={16} />
                    </p>
                </div>
            )}
        </div>
    );
};

export default PriceSection;
