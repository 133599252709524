import React from "react";
import style from "./InfoMessage.module.css";
import InfoIcon from "@mui/icons-material/Info";
function InfoMessage({ children, backgroundColor, IconColor }) {
  return (
    <div
      className={style["alert-message"]}
      style={{ background: backgroundColor }}
    >
      <InfoIcon className={style["icon"]} style={{ color: IconColor }} />
      {children}
    </div>
  );
}

export default InfoMessage;
