import { Col, Placeholder, Row } from 'rsuite';
import { ICardBody } from './CardBody.interface';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { CardRow } from '../CardRow';
import style from './CardBody.module.css';
import { EX4201, EX4396, EX5057 } from 'config/flags';
import useChangeLanguage from 'utils/hooks/use-change-language';
import { useUser } from 'utils/hooks';
import { useTranslation } from 'react-i18next';
import { currencyFormatter } from '../../utils/currencyTotal';
import { splitFormattedNumber } from 'utils/formattersMoney/splitFormattedNumber';
import { COUNTRY } from 'utils/country';
import useCurrencyFormatter from 'utils/hooks/useCurrencyFormatter/useCurrencyFormatter';
const CardBody = ({
    credit_for_agreements,
    estimated_savings,
    total_products,
    total_price_products,
    products_quantity,
    free_unit_products_quantity,
}: ICardBody) => {
    //translation by Country
    const { t } = useTranslation();
    const { user } = useUser({});
    const userLocaleCountryCode = user?.client?.country_alfa_2;
    if (EX4201 || EX4396) useChangeLanguage(userLocaleCountryCode);
    const {formatCurrency} = useCurrencyFormatter();

    const textTooltipCreditAgreements = !EX4201
        ? 'Es la suma del crédito obtenido en las órdenes de compra de este pedido, por los acuerdos comerciales que tenés con las droguerías.'
        : t('myOrders.orderDetails.textTooltipCreditAgreements');

    const savings = formatCurrency({value: estimated_savings}).fullValue;
    const credit = formatCurrency({value: credit_for_agreements}).fullValue;
    const total = formatCurrency({value: total_price_products}).fullValue;

    const { symbol } = splitFormattedNumber(total, userLocaleCountryCode);

    const products_quantity_with_free = products_quantity + (free_unit_products_quantity || 0);

    const obj = [
        {
            name: `Total`,
            total_products: total_products,
            products_quantity: products_quantity_with_free,
            value: total,
            haveTooltip: false,
        },
        {
            name: !!EX5057 ? t('myOrders.labelSavingFounds') : 'Ahorro estimado',
            value: savings,
            haveTooltip: true,
            icon: <HelpOutlineOutlinedIcon style={{ color: '#646464', height: 16, width: 16, marginLeft: 4 }} />,
            tooltip: !!EX5057
                ? t('myOrders.labelCalcPricePerProduct')
                : 'Se calcula sumando las diferencias de precio por producto, entre el precio más alto y el precio seleccionado.',
        },
        {
            name: !!EX5057 ? t('myOrders.labelAgreementsAndCredit') : 'Crédito por acuerdos',
            value: credit,
            haveTooltip: true,
            icon: <HelpOutlineOutlinedIcon style={{ color: '#646464', height: 16, width: 16, marginLeft: 4 }} />,
            tooltip: textTooltipCreditAgreements,
        },
    ];

    return (
        <Row style={{ padding: '0px 24px' }}>
            {!estimated_savings ? (
                <Placeholder.Paragraph style={{ paddingBottom: 24 }} />
            ) : (
                obj.map((item) => (
                    <CardRow
                        name={item.name}
                        value={item.value}
                        haveTooltip={item.haveTooltip}
                        products_quantity={products_quantity_with_free}
                        total_products={total_products}
                        icon={item.icon}
                        text={item.tooltip}
                    />
                ))
            )}
            <Col xs={24} className={style['cardbody-footer']}>
                {!!EX5057
                    ? userLocaleCountryCode === COUNTRY.ES
                        ? t('myOrders.labelValuesWithoutTax') + ' ' + symbol
                        : symbol + ' ' + t('myOrders.labelValuesWithoutTax')
                    : symbol + '(Los valores no incluyen IVA)'}
            </Col>
        </Row>
    );
};

export default CardBody;
