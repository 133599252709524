import React from "react";
import style from "./SkeletonDetailsCard.module.css";
import { Skeletons } from "commons/components/Orders/Skeletons";

function SkeletonDetailsCard() {
  return (
    <div className={style["container"]}>
      <Skeletons width={104} height={14} />
      <div className={style["line"]}></div>
      <div className={style["card"]}>
        <div style={{ display: "flex" }}>
          <Skeletons width={32} height={32} variant="circular" />
          <div style={{ marginLeft: 16 }}>
            <Skeletons width={200} height={14} />
            <Skeletons width={80} height={14} />
          </div>
        </div>

        <div className={style["secondRow-card"]}>
          <Skeletons width={104} height={14} />
        </div>
        <div className={style["button-container"]}>
          <div className={style["button"]}></div>
        </div>
      </div>
    </div>
  );
}

export default SkeletonDetailsCard;
