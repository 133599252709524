// next
import Link from 'next/link'
// react
import { useEffect } from 'react'
// mui
import ScheduleIcon from '@mui/icons-material/Schedule'
// style
import style from './RenderLastSearch.module.css'

const RenderLastSearch = ({
    productName,
    handleOpen,
    clientId,
    onKeyDown,
    pointOfSale,
    selectedIndex,
    index,
    setProductNameSelected,
}) => {
    // init
    useEffect(() => {
        if (selectedIndex === index) {
            setProductNameSelected(productName)
        }
    }, [selectedIndex])

    return (
        <Link
            href={`/${clientId}/efficient-purchase/${pointOfSale}/search-results?search=${productName}`}
            onClick={() => handleOpen(false)}
            className={
                selectedIndex === index
                    ? `results__recent-search ${style['container-hover']}`
                    : `results__recent-search ${style['container']}`
            }
            style={{
                textDecoration: 'none',
            }}
            onKeyDown={onKeyDown}
            tabIndex={0}
        >
            <ScheduleIcon className="results__recent-search" />
            <div className="results__recent-search">
                <p className="results__recent-search">{productName}</p>
            </div>
        </Link>
    )
}

export default RenderLastSearch
