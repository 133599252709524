import React from 'react';
import { Animation, Divider, IconButton } from 'rsuite';
import { DrawerCounter } from '../index';
import style from './DrawerRow.module.css';
import { DrawerDescription } from '../DrawerDescription';
import { NewUseCart } from 'context';
import { useUser } from 'utils/hooks';
import { RsuiteTooltip } from '@commons/components/RsuiteTooltip';

const DrawerRow = ({ products, handleUpdateProduct, pointOfSale, animationInProduct }) => {
    const { removeProductInState } = NewUseCart();
    const { user } = useUser({});
    return (
        <>
            {products
                .sort((a: any, b: any) => b.id - a.id)
                .map((product: any, idx: number) => {
                    const isUnknownProduct = product.description === null && !!product?.is_unknown;

                    return animationInProduct?.barcode.includes(product?.barcode) ? (
                        <Animation.Slide in={animationInProduct.fade === 'add'} placement="left" key={idx + 1}>
                            <div key={idx + 1}>
                                <div className={style['container']}>
                                    {/* TRASH & DESCRIPTION */}
                                    <div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                alignItems: 'center',
                                                gap: 16,
                                            }}
                                        >
                                            <Animation.Slide in={true} placement="left" className={style['icon']}>
                                                <IconButton
                                                    className="icon__remove-cart"
                                                    value={''}
                                                    disabled={false}
                                                    onClick={() => removeProductInState(product)}
                                                    style={{
                                                        color: 'rgba(226, 46, 46, 1)',
                                                        minWidth: 24,
                                                        padding: 0,
                                                        background: 'none',
                                                    }}
                                                >
                                                    <span className="icon__remove-cart">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                        >
                                                            <path
                                                                d="M7 21C6.45 21 5.97917 20.8042 5.5875 20.4125C5.19583 20.0208 5 19.55 5 19V6C4.71667 6 4.47917 5.90417 4.2875 5.7125C4.09583 5.52083 4 5.28333 4 5C4 4.71667 4.09583 4.47917 4.2875 4.2875C4.47917 4.09583 4.71667 4 5 4H9C9 3.71667 9.09583 3.47917 9.2875 3.2875C9.47917 3.09583 9.71667 3 10 3H14C14.2833 3 14.5208 3.09583 14.7125 3.2875C14.9042 3.47917 15 3.71667 15 4H19C19.2833 4 19.5208 4.09583 19.7125 4.2875C19.9042 4.47917 20 4.71667 20 5C20 5.28333 19.9042 5.52083 19.7125 5.7125C19.5208 5.90417 19.2833 6 19 6V19C19 19.55 18.8042 20.0208 18.4125 20.4125C18.0208 20.8042 17.55 21 17 21H7ZM17 6H7V19H17V6ZM9 17H11V8H9V17ZM13 17H15V8H13V17Z"
                                                                fill="#E22E2E"
                                                            />
                                                        </svg>
                                                    </span>
                                                </IconButton>
                                            </Animation.Slide>
                                            <div className={style['description']}>
                                                <DrawerDescription product={product} />
                                                <div className={style['wrapper-description']}>
                                                    <p className={style['barcode']}>
                                                        {isUnknownProduct
                                                            ? 'Vas a ver el producto en la comparativa'
                                                            : product.barcode}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* COUNTER */}
                                    <div className={style['button']}>
                                        <DrawerCounter
                                            idx={idx}
                                            product={product}
                                            quantity={product.quantity}
                                            handleUpdateProduct={handleUpdateProduct}
                                            pointOfSale={pointOfSale}
                                        />
                                    </div>
                                </div>
                                <Divider style={{ margin: 0 }} />
                            </div>
                        </Animation.Slide>
                    ) : (
                        <div key={idx + 1}>
                            <div className={style['container']}>
                                {/* TRASH & DESCRIPTION */}
                                <div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            gap: 16,
                                        }}
                                    >
                                        <Animation.Slide in={true} placement="left" className={style['icon']}>
                                            <IconButton
                                                className="icon__remove-cart"
                                                value={''}
                                                disabled={false}
                                                onClick={() => removeProductInState(product)}
                                                style={{
                                                    color: 'rgba(226, 46, 46, 1)',
                                                    minWidth: 24,
                                                    padding: 0,
                                                    background: 'none',
                                                }}
                                            >
                                                <span className="icon__remove-cart">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                    >
                                                        <path
                                                            d="M7 21C6.45 21 5.97917 20.8042 5.5875 20.4125C5.19583 20.0208 5 19.55 5 19V6C4.71667 6 4.47917 5.90417 4.2875 5.7125C4.09583 5.52083 4 5.28333 4 5C4 4.71667 4.09583 4.47917 4.2875 4.2875C4.47917 4.09583 4.71667 4 5 4H9C9 3.71667 9.09583 3.47917 9.2875 3.2875C9.47917 3.09583 9.71667 3 10 3H14C14.2833 3 14.5208 3.09583 14.7125 3.2875C14.9042 3.47917 15 3.71667 15 4H19C19.2833 4 19.5208 4.09583 19.7125 4.2875C19.9042 4.47917 20 4.71667 20 5C20 5.28333 19.9042 5.52083 19.7125 5.7125C19.5208 5.90417 19.2833 6 19 6V19C19 19.55 18.8042 20.0208 18.4125 20.4125C18.0208 20.8042 17.55 21 17 21H7ZM17 6H7V19H17V6ZM9 17H11V8H9V17ZM13 17H15V8H13V17Z"
                                                            fill="#E22E2E"
                                                        />
                                                    </svg>
                                                </span>
                                            </IconButton>
                                        </Animation.Slide>
                                        <div className={style['description']}>
                                            <DrawerDescription product={product} />
                                            <RsuiteTooltip
                                                text="Vas a ver el producto en la comparativa"
                                                trigger="hover"
                                                placement="bottom"
                                                disabled={!isUnknownProduct}
                                            >
                                                <div className={style['wrapper-description']}>
                                                    <p className={style['barcode']}>
                                                        {isUnknownProduct
                                                            ? 'Vas a ver el producto en la comparativa'
                                                            : product.barcode}
                                                    </p>
                                                </div>
                                            </RsuiteTooltip>
                                        </div>
                                    </div>
                                </div>
                                {/* COUNTER */}
                                <div className={style['button']}>
                                    <DrawerCounter
                                        idx={idx}
                                        product={product}
                                        quantity={product.quantity}
                                        handleUpdateProduct={handleUpdateProduct}
                                        pointOfSale={pointOfSale}
                                    />
                                </div>
                            </div>
                            <Divider style={{ margin: 0 }} />
                        </div>
                    );
                })}
        </>
    );
};

export default DrawerRow;
