import { useMemo } from 'react';

const useCategorizedDrugManufacturers = (drugManufacturers) => {
    return useMemo(() => {
        if (!drugManufacturers?.data) {
            return {
                availables: [],
                connected: [],
                quantityAvailable: 0,
                connectedAvailable: 0,
                errorsConnected: 0
            };
        }

        const initialState = {
            availables: [],
            connected: [],
            quantityAvailable: 0,
            connectedAvailable: 0,
            errorsConnected: 0
        };

        const categorizedResults = drugManufacturers.data.reduce((acc, drugmanufacturer) => {
            drugmanufacturer.credential_types.forEach((credential) => {
                const manufacturerWithExtraFields = {
                    ...drugmanufacturer,
                    hasTicketFormatValidating: [],
                    errorUserPasswordConnection: [],
                };

                // Filtrar credenciales disponibles (api_credential_id === null)
                if (credential.api_credential_id === null) {
                    acc.availables.push(manufacturerWithExtraFields);
                    acc.quantityAvailable++;
                }

                // Filtrar credenciales conectadas (api_credential_id !== null)
                if (credential.api_credential_id !== null) {
                    acc.connected.push(manufacturerWithExtraFields);
                    acc.connectedAvailable++;
                }

                if(credential.errors.length > 0){
                    acc.errorsConnected++;
                }
            });
            return acc;
        }, initialState);

        return categorizedResults;
    }, [drugManufacturers]);
};

export default useCategorizedDrugManufacturers;