export const getProductsSelected = (products: any, EX5937: boolean): any[] => {
    if (EX5937) {
        const selectedProduct = [];
        products.forEach((product) => {
            const isArray = Array.isArray(product?.drugManufacturerIdSelected);
            const drugManufacturerId = product?.drugManufacturerIdSelected;
            if (!isArray) {
                const barcode = product.barcode;
                const api_product_id = product.products[drugManufacturerId]?.id;
                const quantity = product?.quantity;
                const max_quantity = product?.products[drugManufacturerId]?.maxcant;
                if (!quantity || !api_product_id) {
                    return;
                }

                selectedProduct.push({
                    api_product_id,
                    quantity,
                    max_quantity,
                });
            } else {
                drugManufacturerId.forEach((id) => {
                    const barcode = product.barcode;
                    const quantity = product?.products[id]?.quantity_suggested || product?.quantity;
                    const max_quantity = product?.products[id]?.maxcant;
                    const api_product_id = product.products[id]?.id;

                    if (!quantity || !api_product_id) {
                        return;
                    }

                    selectedProduct.push({
                        api_product_id,
                        quantity,
                        max_quantity,
                    });
                });
            }
        });

        return selectedProduct?.filter((product) => product);
    } else {
        return products
            .map((product) => {
                const drugManufacturerId = product?.drugManufacturerIdSelected;
                const quantity = product?.quantity;
                const max_quantity = product?.products[drugManufacturerId]?.maxcant;
                const api_product_id = product.products[drugManufacturerId]?.id;
                if (!quantity || !api_product_id) {
                    return;
                }
                return {
                    api_product_id,
                    quantity,
                    max_quantity,
                };
            })
            ?.filter((product) => product);
    }
};
