export const getProductQuantity = (products: any[], EX5937: boolean) => {
    if (EX5937) {
        return products?.reduce((count, product) => {
            if (product?.drugManufacturerIdSelected?.length > 0 && product?.quantity > 0) {
                count++;
            }
            return count;
        }, 0);
    } else {
        return products?.reduce((count, product) => {
            if (product?.drugManufacturerIdSelected && product?.quantity > 0) {
                count++;
            }
            return count;
        }, 0);
    }
};
