// rsuite
import { Nav } from 'rsuite';
// style
import style from './TabsConnectionsDashboard.module.css';
// get info buttons
import { getInfoButtons } from '@commons/components/WidgetsDrugConnections/TabsConnectionsDashboard/helper';
import { EButtonData } from '@commons/modules/WidgetsConnectionsHome/WidgetsConnectionsHome.enum';

const TabsConnectionsDashboard = ({ categorizedResults, activeKey, setActiveKey }) => {
    // get buttons
    const infoButtons = getInfoButtons(categorizedResults.errorsConnected);

    return (
        <Nav
            appearance="tabs"
            activeKey={activeKey}
            onSelect={setActiveKey}
            style={{ marginBottom: 20 }}
            className={style['button-tabs']}
        >
            {infoButtons.map(({ id, label, errorsCount }) => {
                const quantity = id === EButtonData.connected
                        ? categorizedResults?.connectedAvailable
                        : categorizedResults?.quantityAvailable;
                return (
                    <Nav.Item eventKey={`${id}`}>
                        <div>
                            {label} 
                            <span className={style['button-tabs-counters']}>({quantity})</span>
                        </div>
                        {errorsCount > 0 && (
                            <div className={`${style['button-tabs-connected-error']} ${style['button-tabs-counters']}`}>
                                <img width={16} height={16} src="/assets/images/error_FILL.svg" />
                                ({errorsCount})
                            </div>
                        )}
                    </Nav.Item>
                );
            })}
        </Nav>
    );
};

export default TabsConnectionsDashboard;
