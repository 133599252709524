import { t } from 'i18next';
// component
import { Breadcrumbs, SelectList, SelectInput, DateInput, SelectTag } from 'commons/components';
// interface
import { IFilterbars } from './Filterbars.interface';
// mui v5
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import LocalPharmacyOutlinedIcon from '@mui/icons-material/LocalPharmacyOutlined';
import StorefrontIcon from '@mui/icons-material/Storefront';
// style
import style from './Filterbars.module.css';
// rsuite
import { Col } from 'rsuite';
import { EX4201, EX5057 } from 'config/flags';

const Filterbars = ({
    flag,
    isHiddenFilters,
    setSearchTerms,
    setDateTerms,
    dateTerms,
    setListDrugTerms,
    setListTagTerms,
    listTagTerms,
    listDrugTerms,
    flagIcon,
    goBack,
    text,
    nOrder,
    savePrevUrl,
    urlPrev,
    handlerClearFilters,
    setSelectedItem,
    selectedItem,
    selectedTag,
    setSelectedTag,
    currentSearch,
    setCurrentSearch,
    setSelectedDate,
    selectedDate,
    timerSkeleton,
}: IFilterbars) => {
    return (
        <div className={style['container']}>
            <div className={style['inputs-container']}>
                {!!flag && (
                    <Col xs={24} sm={10}>
                        <Breadcrumbs
                            text={text}
                            flagIcon={flagIcon}
                            goBack={goBack}
                            nOrder={nOrder}
                            savePrevUrl={savePrevUrl}
                            urlPrev={urlPrev}
                        />
                    </Col>
                )}
                {!!isHiddenFilters && (
                    <div className={style['wrapper-inputs']}>
                        <SelectInput
                            timerSkeleton={timerSkeleton}
                            placeholder={
                                !EX4201 ? 'Buscar EAN, droguería, PDV, y más...' : t('myOrders.searchInputPlaceHolder')
                            }
                            setSearchTerms={setSearchTerms}
                            currentSearch={currentSearch}
                            setCurrentSearch={setCurrentSearch}
                            gtagClass='input__search-orders'
                        />

                        <DateInput
                            timerSkeleton={timerSkeleton}
                            placeholder="Fecha"
                            icon={
                                <CalendarTodayIcon
                                    className={timerSkeleton ? style['icons-disabled'] : style['icons']}
                                />
                            }
                            setDateTerms={setDateTerms}
                            dateTerms={dateTerms}
                            handlerClearFilters={handlerClearFilters}
                            setSelectedDate={setSelectedDate}
                            selectedDate={selectedDate}
                        />

                        <SelectTag
                            timerSkeleton={timerSkeleton}
                            placeholder={EX5057 ? t('myOrders.labelPointOfSales') : 'Puntos de venta'}
                            icon={
                                <StorefrontIcon className={timerSkeleton ? style['icons-disabled'] : style['icons']} />
                            }
                            setListTagTerms={setListTagTerms}
                            listTagTerms={listTagTerms}
                            handlerClearFilters={handlerClearFilters}
                            selectedTag={selectedTag}
                            setSelectedTag={setSelectedTag}
                        />

                        <SelectList
                            timerSkeleton={timerSkeleton}
                            placeholder={!EX4201 ? 'Droguerías' : t('myOrders.selectListPlaceHolder')}
                            icon={
                                <LocalPharmacyOutlinedIcon
                                    className={timerSkeleton ? style['icons-disabled'] : style['icons']}
                                />
                            }
                            setListDrugTerms={setListDrugTerms}
                            listDrugTerms={listDrugTerms}
                            handlerClearFilters={handlerClearFilters}
                            selectedItem={selectedItem}
                            setSelectedItem={setSelectedItem}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default Filterbars;
