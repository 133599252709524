import { Badge } from 'rsuite';
import style from './StepsCard.module.css';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import { useStatusContext } from 'context/control-state';
import { useTranslation } from 'react-i18next';
import { EX5057 } from 'config/flags';

const StepsCard = ({ continuous, index, step, backProps, closeProps, primaryProps, tooltipProps }) => {
    const { t } = useTranslation();
    const badge = [1, 2, 3];

    const { counterStep } = useStatusContext();
    const counterStepWithoutHash = counterStep?.slice(1);

    const getButtonClass = (buttonType) => {
        switch (buttonType) {
            case 'next':
                return index === 3
                    ? `button__step-6-end ${style['button-end']}`
                    : `button__${counterStepWithoutHash}-next ${style['button-foward']}`;
            case 'skip':
                return `link__${counterStepWithoutHash}-skip ${style['close-button']}`;
            case 'step3':
                return `button__${counterStepWithoutHash}-skip ${style['close-button-step3']}`;
        }
    };

    const arrowForwardIconClass = getButtonClass('next')?.split(' ')[0];

    return (
        <>
            <div
                className={
                    counterStep === '#step6'
                        ? style['panel-container-pos']
                        : counterStep === '#step4'
                        ? style['panel-container-step4']
                        : style['panel-container']
                }
            >
                {/* Contenedor del título con el botón de cierre */}
                <div className={style['header']}>
                    <p className={style['title']}>{step.title}</p>
                    <button {...closeProps} className={style['closeButton']}>
                        <CloseIcon />
                    </button>
                </div>

                <p className={style['content']}>{step.content}</p>
                <div className={style['line']}></div>

                <div className={style['footer']}>
                    <div className={style['footer-badge']}>
                        <span className={style['step']}>{index}/3</span>

                        {badge?.map((item) => (
                            <Badge
                                key={item}
                                style={{
                                    background: item === index ? '#1A66FC' : '#E0E1E9',
                                    marginRight: 8,
                                }}
                            />
                        ))}
                    </div>

                    <div className={style['footer-actions']}>
                        {index > 1 && (
                            <button {...backProps} className={style['button-back']}>
                                {!!EX5057 ? t('walkthrough.labelStepBack') : 'Atrás'}
                            </button>
                        )}
                        {index === 3 ? (
                            <button {...closeProps} className={`button__walk-finish ${getButtonClass('next')}`}>
                                {!!EX5057 ? t('walkthrough.labelEndWalkthrough') : 'Finalizar'}
                                <ArrowForwardIcon className={`${arrowForwardIconClass} ${style['icon-arrow-end']}`} />
                            </button>
                        ) : (
                            <button
                                {...primaryProps}
                                className={`${index === 1 ? 'button__walk-next-step1' : ''}${
                                    index === 2 ? 'button__walk-next-step2' : ''
                                }${getButtonClass('next')}`}
                            >
                                {!!EX5057 ? t('walkthrough.labelNext') : 'Siguiente'}
                                <ArrowForwardIcon className={`${arrowForwardIconClass} ${style['icon-arrow']}`} />
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default StepsCard;
