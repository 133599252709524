// Function to escape special characters in regular expressions
const escapeRegExp = (string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

export const getHighlightedText = (text, highlight) => {
  // Escape special characters in the highlight term
  const escapedHighlight = escapeRegExp(highlight);
  
  // Split the text into parts using the escaped highlight in a regular expression
  const parts = text.split(new RegExp(`(${escapedHighlight})`, 'gi'));

  return (
    <span>
      {' '}
      {parts.map((part, i) => (
        <span
          key={i}
          style={
            part.toLowerCase() === highlight.toLowerCase()
              ? { fontWeight: 'bold', textTransform: 'capitalize' }
              : {}
          }
        >
          {part}
        </span>
      ))}{' '}
    </span>
  );
};