import { memo } from 'react';
// components
import { Placeholder } from 'rsuite';
// interfaces
import { IItemProps } from './Item.interfaces';
// styles
import styles from './Item.module.css';

const Item = ({ from }: IItemProps) => {
    return (
        <div className={styles['item']}>
            <div className={styles['item-content']}>
                {/* avatar or button on/off */}
                <div>
                    {from === 1 ? (
                        <Placeholder.Paragraph graph="circle" active className={styles['circle']} />
                    ) : (
                        <Placeholder.Paragraph graph="square" active className={styles['onOff']} />
                    )}
                </div>

                {/* buttons rigth */}
                <div>
                    <Placeholder.Paragraph graph="square" active className={styles['button']} rows={0} />
                </div>
            </div>
        </div>
    );
};

export default memo(Item);
