// component
import { Banner } from 'commons/components';
// style
import style from './SearchOrders.module.css';

// interface
import { ISearchOrders } from './SearchOrders.interface';
import { EX5232 } from 'config/flags';
import { useTranslation } from 'react-i18next';

const SearchOrders = ({ searchingData }: ISearchOrders) => {
    // translation by country
    const { t } = useTranslation();
    return (
        <div className={style['container-search-orders']}>
            <div className={style['container-banner']}>
                <Banner
                    image="/images/searching.gif"
                    styleImage={style['image-style']}
                    title={
                        EX5232
                            ? t('myOrders.customFiltersTransitionOne')
                            : 'Estamos buscando los resultados que coincidan con tu búsqueda'
                    }
                    subtitle={
                        EX5232
                            ? t('myOrders.customFiltersTransitionTwo')
                            : 'Esto puede llevar un momento mientras recopilamos la información necesaria.'
                    }
                    styleTitle={{ marginTop: searchingData ? '0' : '35px' }}
                />
            </div>
        </div>
    );
};

export default SearchOrders;
