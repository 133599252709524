import { ServiceId } from 'common/types';
import { useEffect, useState } from 'react';
import { useFetchingContext, usePointOfSale } from 'context';
import { useUser } from 'utils/hooks';

export const ServiceConfig = () => {
    const { user } = useUser({});
    const { pointOfSale } = usePointOfSale();
    const { loading } = useFetchingContext();

    const [tolerance, setTolerance] = useState(false);
    const [service, setService] = useState(0);

    useEffect(() => {
        const point = user.pointsOfSale.find((p) => pointOfSale?.id === p.id);
        if (point) {
            setService(point.service_id);
            setTolerance(service === ServiceId.ByPercentage ? point.d_tolerance > 0 : point.tolerance > 0);
        }
    }, [user, loading, pointOfSale?.id, service]);

    return { tolerance, service: service === ServiceId.ByPercentage };
};
