import { Drawer, IconButton } from 'rsuite';
import { useUser } from 'utils/hooks';
import style from './ModalTerms.module.css';
import TermsAndConditionsModalAR from './locales/ar/TermsAndConditionsModalAR';
import TermsAndConditionsModalMX from './locales/mx/TermsAndConditionsModalMX';
// import { TermsAndConditionsCountryCode } from "utils/enums/TermsAndConditions/TermsAndConditionsCountryCode";
import { EX4163 } from 'config/flags';
import { COUNTRY } from 'utils/country';
import TermsAndConditionsModalCO from './locales/co/TermsAndConditionsModalCO';
import { useTranslation } from 'react-i18next';

const ModalTerms = ({
    openModal,
    setOpenModal,
    setLinkActive,
    expandedFooter,
    userLocaleCountryCode,
    fullWidth = false,
}) => {
    const { t } = useTranslation();
    const handleClose = () => {
        setLinkActive({ value: '', state: false });
        setOpenModal();
    };

    // const { userLocaleCountryCode } = useUser({});

    return (
        <Drawer
            className={
                expandedFooter
                    ? style['wrapper-content']
                    : fullWidth
                    ? style['wrapper-content-large-fullWidth']
                    : style['wrapper-content-large']
            }
            size={'full'}
            placement={'bottom'}
            open={openModal}
            onClose={() => handleClose()}
            style={{
                zIndex: 3500,
            }}
        >
            <div className={fullWidth ? style['wrapper-header-fullWidth'] : style['wrapper-header']}>
                <p className={style['header']}>{t('footer.footer_Two')}</p>
                <IconButton
                    onClick={() => handleClose()}
                    appearance="link"
                    icon={<img color="rgba(64, 67, 91, 1)" src={'/assets/images/close.png'} />}
                />
            </div>
            {!EX4163 ? (
                <TermsAndConditionsModalAR />
            ) : (
                (userLocaleCountryCode === COUNTRY.AR && <TermsAndConditionsModalAR />) ||
                (userLocaleCountryCode === COUNTRY.MX && <TermsAndConditionsModalMX />) ||
                (userLocaleCountryCode === COUNTRY.CO && <TermsAndConditionsModalCO />)
            )}
        </Drawer>
    );
};

export default ModalTerms;
