import { getPlansSubscriptions } from "api/backoffice/backoffice";

// Fetches subscription plans and pos from the API and formats them for the SelectPicker
export const getPlansForSubscriptions = async () => {
    try {
        const response = await getPlansSubscriptions();
        const formattedPlans = response?.data?.map(({ name, id, amount }) => ({
            label: name,
            value: id,
            amount,
        }));

        return formattedPlans || []
    } catch (error) {
        console.error('Error obteniendo planes de suscripción:', error.message);
    }
};