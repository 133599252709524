import { memo } from 'react';
// components
import { Item } from './Item';
import { Header } from './Header';
// utils && hooks
import { useDropdown } from './hooks';
// styles
import styles from './Dropdowns.module.css';
import { useModalCredentialsContext } from 'context/ModalCredentials/ModalCredentials-context';

const Dropdowns = ({ parentRef }) => {
    const { contentRefs, toggleAccordion, openSections, content, hasSomeError } = useDropdown();
    
    return (
        <div className={`${styles['accordion']} ${styles['fade-in']}`}>
            {content.map((item: any, index) => {
                if (item?.content?.length > 0) {
                    

                    return (
                        <div key={index + 1} className={styles['wrapper']}>
                            {/* titulo de sección de desplegable */}
                            <Header
                                toggleAccordion={toggleAccordion}
                                index={index + 1}
                                openSections={openSections}
                                item={item}
                                href={`${item.href}#connect`}
                                content={item?.content}
                                hasSomeError={hasSomeError}
                            />
                            <div
                                ref={(el) => (contentRefs.current[index + 1] = el)}
                                className={`${styles['accordion-content']} ${
                                    openSections.includes(index + 1) ? styles['show'] : ''
                                }`}
                            >
                                {index + 1 === 1
                                    ? item.content?.slice(0, 3)?.map((child, idx) => {
                                          // --------------- sugeridas ---------------
                                          return (
                                              <Item
                                                  key={child?.id}
                                                  child={child}
                                                  from={index + 1}
                                                  parentRef={parentRef}
                                                  idx={idx}
                                              />
                                          );
                                      })
                                    : item.content?.map((child, idx) => {
                                          // --------------- conectadas ---------------
                                          return (
                                              <Item
                                                  key={child?.id}
                                                  child={child}
                                                  from={index + 1}
                                                  parentRef={parentRef}
                                                  idx={idx}
                                              />
                                          );
                                      })}
                            </div>
                        </div>
                    );
                }
            })}
        </div>
    );
};

export default memo(Dropdowns);
