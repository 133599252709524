import { TypeConnection } from "utils/enums/InitialConfig";
import { DrugManufacturersId } from "utils/interfaces";
import { urlWithoutProtocol } from 'utils/formattersUrl';

export const auxiliaryFunctions = () => {
    
    const setNewUrls = (drugmanufacturerId, nameType, drugManufacturerUrl) => {
        let url;

        switch (drugmanufacturerId) {
            case DrugManufacturersId.Cofaloza: {
                if (nameType === TypeConnection.WEB_NEW) {
                    url = urlWithoutProtocol(drugManufacturerUrl)?.toLowerCase();
                } else {
                    url = 'carrito.cofaloza.com.ar/intranet/login.php?class=user';
                }

                break;
            }
            case DrugManufacturersId.DrogueriasDelSud: {
                if (nameType === TypeConnection.WEB_NEW) {
                    url = 'pedidos.delsud.com.ar';
                } else {
                    url = urlWithoutProtocol(drugManufacturerUrl)?.toLowerCase();
                }

                break;
            }
            default:url = urlWithoutProtocol(drugManufacturerUrl)
                break;
        }

        return url;
    };

    return {
        setNewUrls
    }
}