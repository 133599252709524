import { memo } from 'react';
import { t } from 'i18next';
// configs
import { EX6534 } from 'config/flags';
// components
import { ExButton } from '@commons/EXComponentsLibrary';
// utils && hooks
import { roundAllowZero } from './utils';
import { useFooter } from './hooks';
// interfaces
import { IFooterProps } from './Footer.interface';
// styles
import styles from './Footer.module.css';

const Footer = ({ confirmCancelation, failed }: IFooterProps) => {
    const { animationClass, displayedStep, currentStep, progress, timeLeft } = useFooter();

    const childrens = [
        {
            children: (
                <>
                    {!failed && (
                        <>
                            <p className={styles['percentage']}>{roundAllowZero(progress)} %</p>
                            <p className={`${styles['text']} ${currentStep === 4 ? styles['hidden'] : ''}`}>
                                {EX6534
                                    ? roundAllowZero(timeLeft) === 1
                                        ? t('progressBarPrice.title5', { value: roundAllowZero(timeLeft) })
                                        : t('progressBarPrice.title3', { value: roundAllowZero(timeLeft) })
                                    : t('progressBarPrice.title3', { value: roundAllowZero(timeLeft) })}
                            </p>
                        </>
                    )}
                </>
            ),
        },
        {
            children: (
                <ExButton
                    type="button"
                    status="initial"
                    size="md"
                    loading={false}
                    className={`${styles['button']}`}
                    appearance={'primary'}
                    onClick={() => confirmCancelation()}
                    disabled={false}
                >
                    {t('progressBarPrice.button')}
                </ExButton>
            ),
        },
    ];

    return <div className={`${styles['container']} ${animationClass}`}>{childrens[displayedStep]?.children}</div>;
};

export default memo(Footer);
