import { useState } from 'react';
//rsuite
import { Panel } from 'rsuite';
//css
import { ModalTerms } from '@commons/components/Modals/ModalT&C';
import { EX5036, EX5057 } from 'config/flags';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';
import { COUNTRY } from 'utils/country';
import useChangeLanguage from 'utils/hooks/use-change-language';
import { InputFormLogin } from '../InputFormLogin';
import style from './FormLogin.module.css';
import { ModalPrivacyPolicy } from '@commons/components/Modals/ModalPrivacyPolicy';

const Card = ({
    title,
    haveLogo,
    formValues,
    onSubmit,
    updateValues,
    values,
    errorUser,
    onChangeCaptcha,
    activeUser,
    disableInputMail,
}) => {
    return (
        <Panel bordered className={style['container-form']}>
            <div className={style['form']}>
                <div className={style['header']}>
                    {haveLogo && (
                        <img
                            src="/assets/images/IsologoVertical.svg"
                            alt="IsologoVertical"
                            style={{ marginBottom: 32 }}
                        />
                    )}
                    {title && <span style={{ height: haveLogo ? 24 : 0 }}>{title}</span>}
                </div>
                <InputFormLogin
                    formValues={formValues}
                    onSubmit={onSubmit}
                    updateValues={updateValues}
                    values={values}
                    errorUser={errorUser}
                    onChangeCaptcha={onChangeCaptcha}
                    activeUser={activeUser}
                    disableInputMail={disableInputMail}
                />
            </div>
        </Panel>
    );
};

function FormLogin({
    formValues,
    onSubmit,
    updateValues,
    values,
    errorUser,
    haveLogo,
    title,
    onChangeCaptcha,
    activeUser,
    disableInputMail,
}) {
    // states footer
    const [expandedFooter, setExpandedFooter] = useState<boolean>(false);
    const [openModalTerms, setOpenModalTerms] = useState<boolean>(false);
    const [linkActive, setLinkActive] = useState<any>({
        value: '',
        state: false,
    });
    const [openModalPolicy, setOpenModalPolicy] = useState<boolean>(false);
    //translation by Country
    const { t } = useTranslation();
    const userLocale = typeof window !== 'undefined' ? navigator?.language : null;
    const userLocaleCountryCode = typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('locale')) : null;
    switch (userLocale) {
        case 'es-AR':
            useChangeLanguage(COUNTRY.AR);
            break;
        case 'en-US':
            useChangeLanguage(COUNTRY.US);
            break;
        case 'pt-BR':
            useChangeLanguage(COUNTRY.BR);
            break;
        case 'es-MX':
            useChangeLanguage(COUNTRY.MX);
            break;
        case 'es-CO':
            useChangeLanguage(COUNTRY.CO);
            break;
        case 'es-ES':
            useChangeLanguage(COUNTRY.ES);
            break;
        default:
            useChangeLanguage('es');
            break;
    }
    const countriesWithPolicy = [COUNTRY.MX, COUNTRY.CO];
    const countriesWithTerms = [COUNTRY.AR, COUNTRY.MX, COUNTRY.CO];

    const checkIfHasPolicy = (userLocale: string) => {
        return countriesWithPolicy.some((country) => country === userLocale);
    }

    const checkIfHasTerms = (userLocale: string) => {
        return countriesWithTerms.some((country) => country === userLocale);
    }

    const checkIfHasPolicyAndTerms = (userLocale: string) => {
        return checkIfHasPolicy(userLocale) && checkIfHasTerms(userLocale);
    }

    const checkIfHasPolicyOrTerms = (userLocale: string) => {
        const policy = checkIfHasPolicy(userLocale);
        const terms = checkIfHasTerms(userLocale)
        return policy || terms;
    }

    return (
        <div className={style['container-msg-terms-conditions']}>
            <Card
                formValues={formValues}
                onSubmit={onSubmit}
                updateValues={updateValues}
                values={values}
                errorUser={errorUser}
                haveLogo={haveLogo}
                title={title}
                onChangeCaptcha={onChangeCaptcha}
                activeUser={activeUser}
                disableInputMail={disableInputMail}
            />
            {activeUser && checkIfHasPolicyOrTerms(userLocaleCountryCode?.CountryCode) && (
                <p className={`link__login-terms-and-conditions-1 ${style['msg-terms-conditions']}`}>
                    {EX5057 ? t('login.login18') : 'Al activar mi cuenta acepto los'}{' '}
                    {EX5036 ? (
                        <>
                                {/* Terms & Cond */}
                                {
                                    checkIfHasTerms(userLocaleCountryCode?.CountryCode) &&
                                    <>
                                    <a
                                        className={`link__login-terms-and-conditions-1 ${style['as-link']}`}
                                        onClick={() => {
                                            setOpenModalTerms(!openModalTerms);
                                        }}
                                    >
                                        {EX5057 ? t('footer.footer_Two') : 'Términos y condiciones'}
                                    </a>
                                        <ModalTerms
                                            openModal={openModalTerms}
                                            setOpenModal={() => setOpenModalTerms(!openModalTerms)}
                                            setLinkActive={setLinkActive}
                                            expandedFooter={expandedFooter}
                                            userLocaleCountryCode={userLocaleCountryCode?.CountryCode}
                                            fullWidth={true}
                                        />
                                    </>
                                }
                                {checkIfHasPolicyAndTerms(userLocaleCountryCode?.CountryCode) && ' y ' }
                                {/*Privacy and Policy*/}
                                {
                                    checkIfHasPolicy(userLocaleCountryCode?.CountryCode) &&
                                    <>
                                        <a className={`link__login-privacy-and-policy-1 ${style['as-link']}`}
                                        onClick={() => {
                                            setOpenModalPolicy(!openModalPolicy);
                                        }}>
                                            {t('footer.footer_Six')}
                                        </a>
                                        <ModalPrivacyPolicy 
                                            openModal={openModalPolicy} 
                                            setOpenModal={() => setOpenModalPolicy(!openModalPolicy)} 
                                            setLinkActive={setLinkActive}  
                                            expandedFooter={expandedFooter}
                                        />
                                    </>
                                }

                        </>
                    ) : (
                        <span className="link__login-terms-and-conditions-1" style={{ color: '#1A66FC' }}>
                            <Link
                                style={{ color: 'inherit' }}
                                href={{
                                    pathname: '/login/terms',
                                    query: { page: 0 },
                                }}
                                className="link__login-terms-and-conditions-1"
                            >
                                {EX5057 ? t('login.login19') : 'Términos y condiciones.'}
                            </Link>
                        </span>
                    )}
                </p>
            )}
        </div>
    );
}

export default FormLogin;
