export const auxiliarFunctions = () => {
    
    const linksRoutes = (id: number) => {
        let routing;
        switch (id) {
            case 1:
                routing = '/clients/new';
                break;
            case 2:
                routing = '/clients/drugmanufacturers';

            default:
                break;
        }

        return routing;
    };

    return { linksRoutes };
};
