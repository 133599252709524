import React from 'react';
// COMPONENTS
import { RecommendedCardBodyImg } from './Photo/RecommendedCardBodyImg';
import { RecommendedCardBodyDescription } from './Description/RecommendedCardBodyDescription';
import { RecommendedCardPrice } from './Price/RecommendedCardPrice';

export const RecommendedCardBody = ({ imageLoad, setImageLoad, recommended, isFifthElem }) => {
    return (
        <>
            <RecommendedCardBodyImg imageLoad={imageLoad} setImageLoad={setImageLoad} recommended={recommended} />
            <RecommendedCardBodyDescription recommended={recommended} isFifthElem={isFifthElem} />
            <RecommendedCardPrice recommended={recommended} />
        </>
    );
};
