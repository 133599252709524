import { memo } from 'react';
// contexts
import { Step, useProgressBarPriceContext } from 'context';
// components
import { Circle } from './Circle';
// styles
import styles from './ProgressBar.module.css';

const ProgressBar = () => {
    const { steps, currentStep } = useProgressBarPriceContext();
    return (
        <div className={styles['container']}>
            {steps.map((item: Step, idx: number) => (
                <Circle
                    key={idx + 1}
                    icon={item.icon}
                    confirmIcon={item?.confirmIcon}
                    errorIcon={item?.errorIcon}
                    isLastItem={idx === steps.length - 1}
                    inProgress={idx + 1 === currentStep}
                    isComplete={idx + 1 < currentStep}
                />
            ))}
        </div>
    );
};

export default memo(ProgressBar);
