import React from 'react';
import { t } from 'i18next';
// COMPONENTS
import SettingsIcon from '@mui/icons-material/Settings';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { RsuiteTooltip } from '@commons/components/RsuiteTooltip';
// CONFIGS
import { EX5225, EX5788 } from 'config/flags';
// INTEFACES
import { BadgesProps } from './Badges.interface';
import { DrugManufacturersId } from 'utils/interfaces';
// STYLES
import cellStyles from '../CellStyles.module.css';
import { renderProductCantText } from './utils';

const Badges = ({
    drugManufacturerId,
    depositName,
    paymentDue,
    product,
    applyToleranceAccordingToService,
    drugManufacturerEnabled,
}: BadgesProps) => {
    /* #region product props */
    const { maxcant, mincant, agreement } = product;
    /* #endregion */

    const availableDeposit =
        drugManufacturerId === DrugManufacturersId.MonroeAmericana ||
        drugManufacturerId === DrugManufacturersId.Difarmer;

    return (
        <div className={cellStyles['avatar-section']}>
            <div>
                <RsuiteTooltip trigger={'hover'} placement={'left'} text={renderProductCantText(maxcant, mincant)}>
                    <p>{renderProductCantText(maxcant, mincant)}</p>
                </RsuiteTooltip>
            </div>
            <div>
                {availableDeposit && !!depositName && (
                    <div>
                        <RsuiteTooltip
                            trigger={'hover'}
                            placement={'left'}
                            text={t('efficientPurchasing.comparative.efficientPurchasing_comparativeThirtyOne', {
                                depositName: depositName,
                            })}
                        >
                            <img src={'/assets/images/source_environment.svg'} alt={'deposit icon'} />
                        </RsuiteTooltip>
                    </div>
                )}
                {drugManufacturerId === DrugManufacturersId.Acofar && !!paymentDue && (
                    <div>
                        <RsuiteTooltip
                            trigger={'hover'}
                            placement={'left'}
                            text={t('efficientPurchasing.comparative.labelDeadline', {
                                paymentDue: paymentDue,
                            })}
                        >
                            <CalendarTodayIcon style={{ width: 14, height: 14 }} htmlColor="#0171E6" />
                        </RsuiteTooltip>
                    </div>
                )}
                {applyToleranceAccordingToService && (
                    <div>
                        <RsuiteTooltip
                            trigger={'hover'}
                            placement={'left'}
                            text={t('efficientPurchasing.comparative.labelTolerance')}
                        >
                            <SettingsIcon htmlColor="#0171E6" />
                        </RsuiteTooltip>
                    </div>
                )}
                {!!agreement && (
                    <div
                        className={cellStyles['agremeent']}
                        style={{
                            textDecoration: EX5788 && !agreement?.applies ? 'line-through' : '',
                            background: !drugManufacturerEnabled ? '#E0E1E9' : '',
                        }}
                    >
                        <RsuiteTooltip
                            trigger={'hover'}
                            placement={'top'}
                            text={
                                EX5225
                                    ? t('efficientPurchasing.comparative.labelCommercialAgreements')
                                    : 'Acuerdo comercial'
                            }
                            marginTop={-6}
                        >
                            <p style={{ color: !drugManufacturerEnabled ? '#ACAEC4' : '' }}>{agreement?.tag}</p>
                        </RsuiteTooltip>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Badges;
