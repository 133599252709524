// contexts
import { useCredentialErrors } from 'context/CredentialsErrors/CredentialsErrors';
// configs
import { EX4514 } from 'config/flags';

export const useCredentialError = () => {
    // context credential with error
    const {
        updatedCredentials,
        setUpdatedCredentials,
        drugmanufacturersEnabled,
        setCloseDrawerAndReload,
    } = useCredentialErrors();

    //get the drugmanufacturers that were updated
    const filterUpdatedCredentials: any =
        EX4514 &&
        drugmanufacturersEnabled &&
        drugmanufacturersEnabled.length &&
        drugmanufacturersEnabled?.filter((item: any) => updatedCredentials?.includes(item?.id));

    //get the drugmanufacturers name
    const drugsName: any =
        EX4514 && filterUpdatedCredentials && filterUpdatedCredentials.length
            ? filterUpdatedCredentials?.map((item: any) => item?.name)
            : [];

    //function to reset credentialError context states
    const handleClick = (): void => {
        setUpdatedCredentials([]);
        setCloseDrawerAndReload(false);
    };

    return {
        drugsName,
        handleClick,
    };
};
