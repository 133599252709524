import React from 'react';
// COMPONENTS
import { FormattedNumber } from '../FormattedNumber';
import { Placeholder } from 'rsuite';
// STYLES
import styles from '../../TableTotals.module.css';
import cellStyles from '../../../../CellStyles.module.css';
// UTILS
import { COUNTRY } from 'utils/country';
// INTERFACES

const SubTotalValue = ({ skeleton, subtotal, userLocaleCountryCode }): JSX.Element => {
    return (
        <div>
            <p className={styles['subtotal']}>
                Total:
                {!skeleton ? (
                    <FormattedNumber typeRenderFormat={userLocaleCountryCode === COUNTRY.ES} subtotal={subtotal} />
                ) : (
                    <Placeholder.Paragraph rowHeight={16} rows={1} active className={cellStyles['placeholder-price']} />
                )}
            </p>
        </div>
    );
};

export default SubTotalValue;
