import React from 'react';
// COMPONENTS
import { Chip } from '../../Chip';
// UTILS
import { CHIP_DISCOUNT_COLOR } from 'utils/enums/chipDiscount';
// STYLES
import Animations from '../Animations.module.css';
import cellStyles from '../../CellStyles.module.css';
import styles from './RecommendedCellCdo.module.css';
import { CloseMessage } from './CloseMessage';
import { PriceSection } from '../RecommendedCellPrice/PriceSection';

const RecommendedCellCdo = ({
    product,
    productPVP,
    fadeOutRecommended,
    recommendationLimit,
    setShowRecommendations,
    skeleton,
    chipColor,
    drugManufacturerEnabled,
    drugManufacturerName,
    hasDrugManufacturerError,
    drugManufacturerLength,
    drugManufacturerId,
    user,
    cdo_is_visible,
    isLastCell,
    lastUnitPrice,
    unitPrice,
    isRecommended
}) => {
    return (
        <div
            className={`${styles['cell']} ${fadeOutRecommended ? Animations['fade-out'] : ''}`}
            style={{
                backgroundColor: recommendationLimit ? '#ffece0' : drugManufacturerLength ? '#ffece0' : '#fff6ef',
                borderLeft: recommendationLimit ? 'none' : '',
            }}
        >
            {!recommendationLimit && (
                <>
                    <div className={`${cellStyles['top']}`}>
                        <PriceSection
                            recommendationLimit={recommendationLimit}
                            skeleton={skeleton}
                            drugManufacturerEnabled={drugManufacturerEnabled}
                            hasDrugManufacturerError={hasDrugManufacturerError}
                            product={product}
                            chipColor={chipColor}
                            cdo_is_visible={cdo_is_visible}
                            isLastCell={isLastCell}
                            setShowRecommendations={setShowRecommendations}
                            userLocale={user?.client?.country_alfa_2}
                            lastUnitPrice={lastUnitPrice}
                            unitPrice={unitPrice}
                        />
                    </div>
                    {!skeleton && !!product && !!product?.discount && !hasDrugManufacturerError && (
                        <Chip
                            chipColor={!drugManufacturerEnabled && !skeleton ? CHIP_DISCOUNT_COLOR.DISABLED : chipColor}
                            skeleton={skeleton}
                            product={product}
                            productPVP={productPVP}
                            drugManufacturerName={drugManufacturerName}
                            drugManufacturerId={drugManufacturerId}
                            bonus={product?.bonus}
                            checked={false}
                            isAppliedIndex={null}
                            multiplierPerAppliedPromo={false}
                            quantityForBonus={0}
                            user={user}
                            isRecommended={isRecommended}
                        />
                    )}
                </>
            )}
            {recommendationLimit && <CloseMessage setShowRecommendations={setShowRecommendations} />}
        </div>
    );
};

export default RecommendedCellCdo;
