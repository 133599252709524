import { EErrorCode } from 'utils/enums/comparativeAlerts/comparativeAlerts';

export const checkErrors = (error, id) => {
    
    const hasErrorCredential = error?.some(
        (item) =>
            item.id === id &&
            item?.error_type?.name === 'wrong_username_password' &&
            item?.error_type?.id === EErrorCode.WRONG_USERNAME_PASSWORD,
    );

    const hasErrorCredit = error?.some(
        (item) =>
            item.id === id &&
            item?.error_type?.name === 'credit_limit_reached' &&
            item?.error_type?.id === EErrorCode.CREDIT_LIMIT_REACHED,
    );

    const hasUnknownError = error?.some(
        (item) =>
            item.id === id &&
            item?.error_type?.name === 'unknown_error' &&
            item?.error_type?.id === EErrorCode.UNKNOWN_ERROR,
    );

    const hasErrorExpiredPassword = error?.some(
        (item) =>
            item.id === id &&
            item?.error_type?.name === 'expired_password' &&
            item?.error_type?.id === EErrorCode.EXPIRED_PASSWORD,
    );

    const hasErrorMissingAccount = error?.some(
        (item) =>
            item.id === id &&
            item?.error_type?.name === 'missing_account' &&
            item?.error_type?.id === EErrorCode.MISSING_ACCOUNT,
    );
    const hasErrorMissingDispatchAddress = error?.some(
        (item) =>
            item.id === id &&
            item?.error_type?.name === 'missing_dispatch_addres' &&
            item?.error_type?.id === EErrorCode.MISSING_DISPATCH_ADDRESS,
    );
    const hasIncorrectFormatFile = error?.some(
        (item) =>
            item.id === id &&
            item?.error_type?.name === 'incorrect_format_file' &&
            item?.error_type?.id === EErrorCode.MISSING_FORMAT,
    );

    return {
        hasErrorCredential,
        hasErrorCredit,
        hasUnknownError,
        hasErrorExpiredPassword,
        hasErrorMissingAccount,
        hasErrorMissingDispatchAddress,
        hasIncorrectFormatFile
    };
};
