interface shouldDisplayChipProps {
    skeleton: boolean;
    product: any;
    bonus: any;
    hasDrugManufacturerError: boolean;
    isCellSplit?: boolean;
}

// export const shouldDisplayChip = ({
//     skeleton,
//     product,
//     bonus,
//     hasDrugManufacturerError,
//     isCellSplit = false
// }: shouldDisplayChipProps): boolean => {
//     return !skeleton && !!product && (!!product?.discount || bonus?.length > 0) && !hasDrugManufacturerError;
// };

// Función refactorizada para mejor legibilidad
export const shouldDisplayChip = ({
    skeleton,
    product,
    bonus,
    hasDrugManufacturerError,
    isCellSplit = false,
}: shouldDisplayChipProps): boolean => {
    if (skeleton || hasDrugManufacturerError) {
        return false;
    }

    if (product) {
        if (isCellSplit || product.discount || bonus.length > 0) {
            return true;
        }
    }

    return false;
};
