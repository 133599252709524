import React, { FC, memo } from 'react';
import { Modal } from 'components';
import { Grid, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from './ModalDiscounts.styles';
import PerfectScrollBar from 'react-perfect-scrollbar';
import { EX5057 } from 'config/flags';
import { t } from 'i18next';

interface ModalDiscountsProps {
    open: boolean;
    setOpen: (v) => void;
    discounts: Discount[];
    drugManufacturerName: string;
}

export interface Discount {
    max_quantity: number;
    min_quantity: number;
    price: number;
    discountPercentage: number;
    multiple?: number;
}

const ModalMultiple: FC<ModalDiscountsProps> = ({ open, setOpen, discounts, drugManufacturerName }) => {
    const classes = useStyles();

    const renderText = (discount: Discount) => {
        if (discount.multiple) {
            return (
                <Typography style={{ color: '#40435B', display: 'flex' }}>
                    Múltiplos de {discount.multiple} unidades{' '}
                    <span style={{ marginLeft: 12 }}>${discount.price.toString().replace(/\./g, ',')} u.</span>
                    {/* <button className={classes.button}>Actualizar cantidad</button> */}
                </Typography>
            );
        }
        if (discount.max_quantity !== null && discount.min_quantity !== null) {
            // MAX AND MIN
            return (
                <Typography>
                    Seleccionando{' '}
                    <span>
                        {EX5057 ? t('efficientPurchasing.comparative.discountLabelFrom') : 'desde'}{' '}
                        {discount.min_quantity} u.{' '}
                        {EX5057 ? t('efficientPurchasing.comparative.discountLabelTo') : 'hasta'}{' '}
                        {discount.max_quantity} u.
                    </span>
                </Typography>
            );
        } else if (discount.max_quantity !== null && discount.min_quantity === null) {
            // ONLY MAX
            return (
                <Typography>
                    Seleccionando{' '}
                    <span>
                        {EX5057 ? t('efficientPurchasing.comparative.discountLabelTo') : 'hasta'}{' '}
                        {discount.max_quantity}{' '}
                        {EX5057 ? t('efficientPurchasing.comparative.labelFooterUnits') : 'unidades'}
                    </span>
                </Typography>
            );
        } else {
            // ONLY MIN
            return (
                <Typography>
                    Seleccionando{' '}
                    <span>
                        {EX5057 ? t('efficientPurchasing.comparative.discountLabelFrom') : 'desde'}{' '}
                        {discount.min_quantity}{' '}
                        {EX5057 ? t('efficientPurchasing.comparative.labelFooterUnits') : 'unidades'}
                    </span>
                </Typography>
            );
        }
    };

    const sortDiscounts = () => discounts.sort((a, b) => a.discountPercentage - b.discountPercentage);

    return (
        <Modal
            className={classes.containerModalMultiple}
            Width={discounts.some((discount: Discount) => discount.max_quantity !== null) ? '376px' : '340px'}
            open={open}
            onClose={setOpen}
        >
            {open && (
                <Grid container direction="column" alignItems="center" justify="center" className={classes.content}>
                    <Grid item className={classes.firstSection}>
                        <Grid item className={classes.sectionIcons}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="40"
                                height="40"
                                viewBox="0 0 40 40"
                                fill="none"
                            >
                                <path
                                    d="M12.4993 18.3333C10.8882 18.3333 9.51324 17.7639 8.37435 16.625C7.23546 15.4861 6.66602 14.1111 6.66602 12.5C6.66602 10.8889 7.23546 9.51389 8.37435 8.37501C9.51324 7.23612 10.8882 6.66667 12.4993 6.66667C14.1105 6.66667 15.4855 7.23612 16.6243 8.37501C17.7632 9.51389 18.3327 10.8889 18.3327 12.5C18.3327 14.1111 17.7632 15.4861 16.6243 16.625C15.4855 17.7639 14.1105 18.3333 12.4993 18.3333ZM12.4993 15C13.1938 15 13.7841 14.7569 14.2702 14.2708C14.7563 13.7847 14.9993 13.1944 14.9993 12.5C14.9993 11.8056 14.7563 11.2153 14.2702 10.7292C13.7841 10.2431 13.1938 10 12.4993 10C11.8049 10 11.2146 10.2431 10.7285 10.7292C10.2424 11.2153 9.99935 11.8056 9.99935 12.5C9.99935 13.1944 10.2424 13.7847 10.7285 14.2708C11.2146 14.7569 11.8049 15 12.4993 15ZM27.4993 33.3333C25.8882 33.3333 24.5132 32.7639 23.3743 31.625C22.2355 30.4861 21.666 29.1111 21.666 27.5C21.666 25.8889 22.2355 24.5139 23.3743 23.375C24.5132 22.2361 25.8882 21.6667 27.4993 21.6667C29.1105 21.6667 30.4855 22.2361 31.6243 23.375C32.7632 24.5139 33.3327 25.8889 33.3327 27.5C33.3327 29.1111 32.7632 30.4861 31.6243 31.625C30.4855 32.7639 29.1105 33.3333 27.4993 33.3333ZM27.4993 30C28.1938 30 28.7841 29.7569 29.2702 29.2708C29.7563 28.7847 29.9993 28.1944 29.9993 27.5C29.9993 26.8056 29.7563 26.2153 29.2702 25.7292C28.7841 25.2431 28.1938 25 27.4993 25C26.8049 25 26.2146 25.2431 25.7285 25.7292C25.2424 26.2153 24.9993 26.8056 24.9993 27.5C24.9993 28.1944 25.2424 28.7847 25.7285 29.2708C26.2146 29.7569 26.8049 30 27.4993 30ZM7.83268 32.1667C7.52713 31.8611 7.37435 31.4722 7.37435 31C7.37435 30.5278 7.52713 30.1389 7.83268 29.8333L29.8327 7.83334C30.1382 7.52778 30.5271 7.37501 30.9993 7.37501C31.4716 7.37501 31.8605 7.52778 32.166 7.83334C32.4716 8.13889 32.6243 8.52778 32.6243 9.00001C32.6243 9.47223 32.4716 9.86112 32.166 10.1667L10.166 32.1667C9.86046 32.4722 9.47157 32.625 8.99935 32.625C8.52713 32.625 8.13824 32.4722 7.83268 32.1667Z"
                                    fill="#1A66FC"
                                />
                            </svg>
                            <CloseIcon className={classes.closeIcon} onClick={setOpen} />
                        </Grid>
                        <Grid item>
                            <Typography className={classes.nameTitleModal}>{drugManufacturerName}</Typography>
                            <Typography className={classes.titleModal}>Descuentos por múltiplo</Typography>
                        </Grid>
                        <Grid item>
                            <Typography className={classes.subtitleModal}>
                                Al seleccionar la cantidad, automáticamente aplicaremos los descuentos que correspondan
                                a estas promociones:
                            </Typography>
                        </Grid>
                    </Grid>
                    <PerfectScrollBar
                        style={{
                            overflowY: 'auto',
                            maxHeight: 308,
                            width: '100%',
                        }}
                    >
                        <Grid item className={classes.secondSection}>
                            {sortDiscounts().map((discount: Discount, idx) => {
                                return (
                                    <Grid item key={idx + 1}>
                                        <div>
                                            <Typography>
                                                {discount.discountPercentage}% <span>OFF</span>
                                            </Typography>
                                        </div>
                                        {renderText(discount)}
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </PerfectScrollBar>
                </Grid>
            )}
        </Modal>
    );
};

export default memo(ModalMultiple);
