// react
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
// components
import { CardHeader, DragDropUploader, InputSearch } from '.';
import { ModalConfirmation } from '../../index';
// context
import { NewUseCart, useCreateOrder, usePointOfSale } from 'context';
import { useStatusContext } from 'context/control-state';
import { useDragAndDrop } from 'context/dragAndDropContext';
import { useNewSearchProduct } from 'context/openSearch/newSearchDataContext';
// hooks
import { useDebounce, useUser } from 'utils/hooks';
// utils
import { isZettiFormat } from 'utils/pointOfSale';
// handlers
import { handlerAddProductCart } from './handlers';
// rsuite
import { Panel } from 'rsuite';
// style
import style from './Card.module.css';

// fake data
import { useTranslation } from 'react-i18next';
import useChangeLanguage from 'utils/hooks/use-change-language';
import { EX5057 } from 'config/flags';

const Card = ({ containerRef }) => {
    // translation
    const { t } = useTranslation();
    const { user } = useUser({});
    const userLocaleCountryCode = user?.client?.country_alfa_2;
    useChangeLanguage(userLocaleCountryCode);
    // next
    const router = useRouter();
    // states
    const [searchValue, setSearchValue] = useState('');
    const [visibleClear, setVisibleClear] = useState(false);
    const searchValueDebounce = useDebounce(searchValue, 200);
    const [click, setClick] = useState<boolean>(false);
    const [productNameSelected, setProductNameSelected] = useState(null);
    const [activeSearches, setActiveSearches] = useState(false);
    const { isTourOpen } = useStatusContext();
    // context
    const {
        searchData,
        currentSearch,
        lastSearch,
        setLastSearch,
        setCurrentSearch,
        isLoading,
        pagination,
        handleOpen,
        isOpen,
        handleResetSearch,
    } = useNewSearchProduct();

    const {
        addProduct,
        openModalDelete,
        setOpenModalDelete,
        emptyCart,
        existsProductInCart,
        getProductByBarcode,
        updateProduct,
        visibility,
        toggle,
        isExpanded,
    } = NewUseCart();

    const { openDragAndDropIconClose, setVisibleDragDrop, visibleDragDrop, isDragging } = useDragAndDrop();

    const {
        handleUpload,
        handlerDrop,
        blockerFormat,
        errors,
        dataWithError,
        isFetching,
        formatFile,
        handlerUploadWithErrors,
        setErrors,
        isLoadingFileWithErrors
    } = useCreateOrder();

    const { pointOfSale } = usePointOfSale();
    const { counterStep } = useStatusContext();

    const isZetti = isZettiFormat(pointOfSale);

    const clearSearchValue = () => {
        setClick(true);
    };
    const handlerSendWithEnter = (value, evt) => {
        if (evt?.key === 'Enter') {
            handleOpen(false)
            if (value.length > 0) {
                router.push(
                    `/${user?.client?.id}/efficient-purchase/${pointOfSale?.id}/search-results?search=${value}`,
                );
            }
        }
    };

    const updateLastSearch = (debouncedValue) => {
        // update last search localStorage
        if (lastSearch?.length === 0 || lastSearch?.indexOf(debouncedValue) === -1) {
            lastSearch?.unshift(debouncedValue);
            const slicedLastSearch = lastSearch.slice(0, 6);
            setLastSearch(slicedLastSearch);
            localStorage.setItem('lastSearch', JSON.stringify(slicedLastSearch));
        }
    };

    useEffect(() => {
        if (searchValueDebounce.trim() !== '' && searchValueDebounce.length >= 3) {
            setCurrentSearch(searchValueDebounce);
            updateLastSearch(searchValueDebounce);
            return;
        }
    }, [searchValueDebounce]);

    useEffect(() => {
        // reset state when close dropdown
        if (!isOpen && !click) {
            handleResetSearch();
            setSearchValue('');
        }
    }, [isOpen, click]);

    useEffect(() => {
        if (click) {
            // simulate click because return focus and cursor
            const element: any = document?.getElementById('input-open-search-home');
            handleResetSearch();
            setSearchValue('');
            element.click();
            setClick(false);
        }
    }, [click]);

    const textSearchPlaceholder = t('efficientPurchasing.fileUpload.efficientPurchasing_fileUploadThirtyThree');

    useEffect(() => {
        if (!isOpen && !activeSearches) {
            setProductNameSelected(textSearchPlaceholder);
        }
        setActiveSearches(false);
    }, [isOpen]);

    useEffect(() => {
        if (isTourOpen && visibleDragDrop) {
            openDragAndDropIconClose();
        }
    }, [isTourOpen]);

    const textDropZone = !isZetti
        ? EX5057
            ? t('shoppingCart.labelAddProducts')
            : 'Agrega los productos que necesitas a tu carrito desde el buscador o cargando un archivo.'
        : 'Arrastra el archivo para iniciar la comparación de precios.';
        
    return (
        <div
            className={
                counterStep === '#step-1' || counterStep === '#step-2'
                    ? style['wrapper-card-container-blur']
                    : style['wrapper-card-container']
            }
            style={{ zIndex: isOpen ? 2000 : undefined }}
        >
            {counterStep === '#step-1' && <div className={style['wrapper-card-blur-left']}></div>}
            {counterStep === '#step-2' && <div className={style['wrapper-card-blur-right']}></div>}

            <ModalConfirmation
                open={openModalDelete}
                handleClose={() => setOpenModalDelete(!openModalDelete)}
                handleConfirm={() => emptyCart(pointOfSale?.id)}
                backdrop={true}
                title={t('shoppingCart.shoppingCart_Seventeen')}
                body={ t('shoppingCart.shoppingCart_Eighteen')}
            />
            <div className={counterStep === '#step-1' ? style['pulse'] : ''}>
                <div className={style['container-card']}>
                    <Panel
                        bordered
                        header={
                            <CardHeader title={t('shoppingCart.shoppingCart_TwentyFour')} subtitle={textDropZone} />
                        }
                        className={style['panel-card-container']}
                    >
                        <div>
                            {visibleDragDrop ||
                                (!isZetti && (
                                    <InputSearch
                                        container={() => containerRef?.current}
                                        placeholder={
                                            !searchValue && lastSearch && !!activeSearches
                                                ? productNameSelected
                                                : textSearchPlaceholder
                                        }
                                        recordset={searchData}
                                        valueSearch={currentSearch}
                                        onClickVisibleDragDrop={() => openDragAndDropIconClose()}
                                        cleanable={false}
                                        caretAs={() => ''}
                                        onKeyDown={(evt) => handlerSendWithEnter(searchValue, evt)}
                                        onSearch={(value, evt) => {
                                            // validation that resets search status
                                            if (value === '') {
                                                handleResetSearch();
                                                clearSearchValue();
                                                setVisibleClear(false);
                                            } else {
                                                setSearchValue(value);
                                                setVisibleClear(true);
                                            }
                                        }}
                                        onClickAddProduct={(evt, item) => {
                                            evt.stopPropagation(), handlerAddProductCart(item, addProduct);
                                        }}
                                        onClickClose={clearSearchValue}
                                        onOpen={() => {}}
                                        value={searchValue}
                                        existsProductInCart={existsProductInCart}
                                        getProductByBarcode={getProductByBarcode}
                                        pointOfSaleId={pointOfSale?.id}
                                        handleUpdateProduct={updateProduct}
                                        isLoading={isLoading}
                                        pagination={pagination}
                                        handleOpen={handleOpen}
                                        isOpen={isOpen}
                                        lastSearch={lastSearch}
                                        visibleClear={visibleClear}
                                        setVisibleClear={setVisibleClear}
                                        visibility={visibility}
                                        toggle={toggle}
                                        isExpanded={isExpanded}
                                        productNameSelected={productNameSelected}
                                        setProductNameSelected={setProductNameSelected}
                                        className="input__search-1"
                                        setActiveSearches={setActiveSearches}
                                    />
                                ))}
                        </div>
                    </Panel>
                </div>
            </div>

            {(visibleDragDrop || !!isZetti) && (
                <div className={style['wrapper-drag-drop']}>
                    <DragDropUploader
                        onClickCancelDragDrop={() => {
                            setVisibleDragDrop(false), setErrors(false);
                        }}
                        handleUpload={handleUpload}
                        isDragging={isDragging}
                        handlerDrop={handlerDrop}
                        blockerFormat={blockerFormat}
                        errors={errors}
                        dataWithError={dataWithError}
                        isFetching={isFetching}
                        formatFile={formatFile}
                        handlerUploadWithErrors={handlerUploadWithErrors}
                        isZetti={isZetti}
                        isLoadingFileWithErrors={isLoadingFileWithErrors}
                    />
                </div>
            )}
        </div>
    );
};

export default Card;
