export { productHasDiscount } from './discounts';

export { renderPrice } from './prices';

export { quantityProductsByDrugManufacturer } from './quantityProduct';

export { unitsByDrugManufacturer } from './units';

export { isLowest, isLowestCdo } from './isLowest';

export { applyToleranceAccordingToService } from './applyToleranceAccordingToService';

export { backgroundColorCell } from './backgroundColorCell';

export { withoutStockText } from './withoutStock';

export { productIdxByDrugManufacturerId, recommendedIdxByDrugManufacturerId } from './searchProductByIdx';

export { renderValue, renderWithoutStockText } from './renderPriceValue';

export { showCaretUp } from './caretUp';

export { isShowAlert } from './isShowAlert';
