import {
    credentialData,
    initialValue,
} from '@commons/modules/InitialConfig/PointOfSales/NewCredentialCard/NewCredentialCardConnectionV3/NewFormConnectionV3/schema/formShemaModel';
import { FORMAT_STATUS } from 'api/types';
import { t } from 'i18next';
import {
    EDrugManufacturerTypeId,
    EErrorDrugManufacturerTypeId,
} from 'utils/enums/drugmanufacturerTypeId/DrugManufacturerTypeId';

export const auxiliary = () => {
    const returnStatusObjectConnected = (credential_types) => {
        return credential_types.find((credential_type) => {
            const typeErrorCredential = credential_type?.errors?.length > 0 ? credential_type?.errors[0] : {};
            if (typeErrorCredential?.id) {
                return typeErrorCredential?.id;
            }
        })?.errors[0]?.id;
    };

    const returnStatusObjectAvailable = (type) => {
        const isVendor = type.id === EDrugManufacturerTypeId.vendor;
        return {
            buttonAppearance: isVendor ? 'primary' : 'outline',
            buttonText: isVendor ? t('home.widgetButtonRequest') : t('home.widgetButtonConnect'),
            statusAvatar: isVendor ? 'extendeal' : 'disconnected',
        };
    };

    const initializeForm = (dmName, credential, nameType) => {
        const {
            available_branches,
            dispatch_addresses,
            payment_method_id,
            username,
            password,
            client_identifier,
            catalog_id,
            credential_function,
            api_credential_id: credentialId,
        } = credential;

        const address = dispatch_addresses?.find((address) => {
            return address.selected === true;
        });

        initialValue.username = username;
        initialValue.password = password;
        initialValue.client_identifier = client_identifier ? client_identifier : '';
        initialValue.catalog_id = catalog_id ? catalog_id : '';
        initialValue.function = credential_function ? credential_function : '';
        initialValue.available_branches = available_branches ? available_branches : '';
        initialValue.dispatch_address = address
            ? {
                  value: address?.dispatch_address?.description,
                  label: address?.dispatch_address?.description,
              }
            : '';
        initialValue.payment_method_id = payment_method_id ? payment_method_id : '';
        credentialData.credentialId = credentialId;

        credentialData.nameType = nameType;
        credentialData.drugName = dmName;
    };

    const getStatusByObjects = (recordset) => {
        return recordset.map(
            ({ format_status, errorUserPasswordConnection, hasTicketFormatValidating }) => {
                const formatStatusName = format_status?.name;
                
                if (formatStatusName === FORMAT_STATUS.VALIDATED) {
                    return 'connected';
                }

                if (
                    (formatStatusName === FORMAT_STATUS.UNKNOWN && hasTicketFormatValidating?.length === 0) ||
                    !!errorUserPasswordConnection
                ) {
                    return 'error';
                }

                if (formatStatusName === FORMAT_STATUS.VALIDATION_PENDING || hasTicketFormatValidating?.length > 0) {
                    return 'disconnected';
                }

                return undefined; // O un valor por defecto si quieres asegurarte de que siempre hay un estado.
            },
        );
    };

    const getStatusByObjectLabel = (status) => {
        let label;
        switch (status) {
            case EErrorDrugManufacturerTypeId.credential_error:
                label = t('home.tabs.credentialError');
                break;
            case EErrorDrugManufacturerTypeId.invalid_format:
                label = t('home.tabs.missingFormat');
            default:
                label = t('home.tabs.connectAlready');
                break;
        }

        return {
            label,
        };
    };

    return {
        getStatusByObjects,
        getStatusByObjectLabel,
        returnStatusObjectAvailable,
        returnStatusObjectConnected,
        initializeForm,
    };
};
