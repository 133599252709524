import React, { useRef } from 'react';
// COMPONENTS
import { Placeholder } from 'rsuite';
import { RsuiteTooltip } from '@commons/components/RsuiteTooltip';
// HOOKS
import { useTruncate } from './hooks';
// UTILS
import { capitalizeEveryFirstLetter } from 'utils/strings/capitalizeEveryFirstLetter';
// STYLES
import styles from '../RecommendedCellProduct.module.css';

const ProductDescription = ({ skeleton, recommendation, containerRef }) => {
    /* #region refs */
    const paragraphRef = useRef(null); /* #region hook by tooltip */
    /* #endregion */

    /* #region hook by show tooltip */
    const { isTruncated } = useTruncate(containerRef, paragraphRef);
    /* #endregion */

    return (
        <div className={styles['product-description']}>
            {skeleton ? (
                <>
                    <Placeholder.Paragraph rowHeight={16} rows={1} active className={styles['placeholder-row']} />
                    <Placeholder.Paragraph
                        rowHeight={16}
                        rows={1}
                        active
                        className={styles['placeholder-description']}
                    />
                </>
            ) : (
                <>
                    <div className={styles['recommended']}>Recomendado</div>
                    <div className={styles['description']} ref={paragraphRef}>
                        <RsuiteTooltip
                            placement="bottom"
                            trigger="hover"
                            text={
                                recommendation?.length > 0
                                    ? capitalizeEveryFirstLetter(recommendation?.[0]?.description)
                                    : ''
                            }
                            disabled={!isTruncated}
                            marginTop={4.5}
                        >
                            <p>
                                {recommendation?.length > 0
                                    ? capitalizeEveryFirstLetter(recommendation?.[0]?.description)
                                    : ''}
                            </p>
                        </RsuiteTooltip>
                    </div>
                </>
            )}
        </div>
    );
};

export default ProductDescription;
