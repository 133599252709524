import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
// contexts
import { useProductComparison } from 'context/product-comparison.context';
// utils
import { useRecommendedProductsByOrderId } from 'utils/hooks';
import { filterRecommendedViews } from './utils';

const useRecommendedProducts = () => {
    // context
    const { query } = useRouter();
    const { recommendations } = useProductComparison();
    // new hook recommended with cache
    const { data: response } = useRecommendedProductsByOrderId(query?.posId, query?.orderId);
    // states
    const [recommendationsResponse, setRecommendationsResponse] = useState<any[]>([]);
    const [recommendationsVisible, setRecommendationsVisible] = useState<any[]>([]);

    useEffect(() => {
        if (recommendations.length === 0 && response?.data && response?.data?.length) {
            const data: any = filterRecommendedViews(response?.data);
            setRecommendationsResponse(data);
            if (data.length) {
                setRecommendationsVisible([data[0]]);
            } else {
                setRecommendationsVisible([]);
            }
        }
    }, [query?.orderId, response]);

    return { recommendationsResponse, recommendationsVisible };
};

export default useRecommendedProducts;
