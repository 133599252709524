import { useMemo } from 'react';
import { t } from 'i18next';
import { Whisper, Tooltip, Avatar } from 'rsuite';
import style from './DetailsDeliveries.module.css';
import { Grid, Row, Col } from 'rsuite';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import BodyDeliveries from './Body/BodyDeliveries';
import Footer from './Footer';
import Link from 'next/link';
import { ListProducts } from '../../DeliveriesDetails/ListProducts';
import { IDetailsDeliveries } from './DeliveriesCard.interface';
import { useRouter } from 'next/router';
import axios from 'axios';
import { handleFilename } from 'utils/filenames';
import InfoMessage from 'commons/components/Orders/InfoMessage/InfoMessage';
import stringAvatar from 'utils/avatar-name';
//gtag
import * as gtag from 'utils/gtag';
import { EX4201, EX5057, EX5225, EX6022, EX6561, EX6928 } from 'config/flags';
import { DeliveryTypes } from '../DeliveriesCard.interface';
import { DeliveryNumber } from '@commons/components/DeliveryNumber';
import { TooltipError } from '@commons/modules/Orders/TooltipError';
import { EErrorCode } from 'utils/enums/comparativeAlerts/comparativeAlerts';
import { OrderDeliveryStatus } from 'utils/enums/OrderDeliveryStatus';

function DetailsDeliveries({ order_deliveries, type, deliveryDetail }: IDetailsDeliveries) {
    const { pathname } = useRouter();
    const isOrderPath = pathname === '/[clientId]/my-orders/details/[order]';

    const isManualDelivery =
        EX6561 && !isOrderPath
            ? order_deliveries?.delivery_status?.id !== OrderDeliveryStatus.SENT
            : type === DeliveryTypes.MANUAL;

    const tooltip = (
        <Tooltip arrow={false}>
            {isManualDelivery
                ? !!EX5057
                    ? t('myOrders.labelDownloadTxt')
                    : 'Descargar TXT'
                : !!EX5057
                ? t('myOrders.labelDownloadPdf')
                : 'Descargar PDF'}
        </Tooltip>
    );

    const router = useRouter();
    const { order } = router.query;

    const file = isManualDelivery ? order_deliveries?.order_file : order_deliveries?.order_pdf;
    const isManufacturerNadro = order_deliveries?.drug_manufacturer.toLowerCase().includes('nadro');
    const filename = handleFilename(file);

    const URLPREFIX = 'https://';

    const deliveryStatus: { text: string; color: string } = useMemo(() => {
        if (isManufacturerNadro && order_deliveries?.track_id === 'Llama a Nadro para reconfirmar') {
            return {
                text: 'Enviada: Llama a Nadro para reconfirmar',
                color: 'red', // I dont have a color code here yet
            };
        }

        if (EX6928) {
            const key = isOrderPath ? order_deliveries?.status?.id : order_deliveries?.delivery_status?.id;
            if (key === OrderDeliveryStatus.ACCEPTED) {
                return {
                    text: t('successPage.labelAccepted'),
                    color: '#157840',
                };
            } else if (key === OrderDeliveryStatus.PROCESSESING_ORDER) {
                return {
                    text: 'Procesando Envío',
                    color: '#C62828',
                };
            } else if (key === OrderDeliveryStatus.REJECTED) {
                return {
                    text: t('successPage.labelRejected'),
                    color: '#C62828',
                };
            } else if (isManualDelivery) {
                return {
                    text: t('review.labelManualShipping'),
                    color: '#9F7100',
                };
            } else {
                return {
                    text: t('successPage.labelSent'),
                    color: '#07AD4B',
                };
            }
        } else {
            if (isManualDelivery) {
                return {
                    text: t('review.labelManualShipping'),
                    color: '#9F7100',
                };
            }
            return {
                text: t('successPage.labelSent'),
                color: '#07AD4B',
            };
        }
    }, [isManufacturerNadro, isManualDelivery, EX5225]);

    const DownloadFile = async () => {
        axios
            .get(file, {
                responseType: 'blob',
                headers: {
                    'Content-Type': 'application/octet-stream',
                    'Content-Disposition': `attachment; filename="${filename}"`,
                },
            })
            .then((response) => {
                // Crear un objeto URL para el archivo
                const url = window.URL.createObjectURL(new Blob([response.data]));

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);

                document.body.appendChild(link);
                link.click();

                window.URL.revokeObjectURL(url);
            })
            .catch((error) => {
                console.error('Error al descargar el archivo:', error);
            });
    };

    const handleFileButton = () => {
        if (isManualDelivery) {
            gtag.event({
                action: 'click',
                category: 'my-orders',
                label: 'icon__download-txt-1',
                value: 0,
            });
        } else {
            gtag.event({
                action: 'click',
                category: 'my-orders',
                label: 'icon__download-pdf-1',
                value: 0,
            });
        }
    };

    const handleWebButton = () => {
        gtag.event({
            action: 'click',
            category: 'my-orders',
            label: 'icon__open-in-web-1',
            value: 0,
        });
    };

    const isDetails = router?.pathname !== '/[clientId]/my-orders/deliveries/[delivery]/[order]';

    const gtagClassDownload = () => {
        if (isDetails) {
            return isManualDelivery ? 'icon__download-txt-1' : 'icon__download-pdf-1';
        } else {
            return isManualDelivery ? 'icon__download-txt-2' : 'icon__download-pdf-2';
        }
    };

    // showError is true when cause is CREDIT_LIMIT_REACHED or when has user_message
    const showError = EX6561
        ? order_deliveries?.status?.cause?.id === EErrorCode.CREDIT_LIMIT_REACHED ||
          !!order_deliveries?.status?.user_message
        : EX6022
        ? order_deliveries?.status?.cause?.id === EErrorCode.CREDIT_LIMIT_REACHED
        : false;

    // deprecated with EX6561 ON
    const typeError = (): number | any => {
        if (EX6022) {
            switch (order_deliveries?.status?.cause?.id) {
                case EErrorCode.CREDIT_LIMIT_REACHED:
                    return EErrorCode.CREDIT_LIMIT_REACHED;
                default:
                    break;
            }
        }
    };

    return (
        <Grid fluid className={style['details-card']}>
            <Row>
                <Col xs={24} sm={EX6022 ? 12 : 16}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Avatar className={style['avatar_nameDrug']}>
                            {stringAvatar(order_deliveries?.drug_manufacturer, false, order_deliveries?.id)}
                        </Avatar>
                        <div>
                            <p className={style['card-title']}>{order_deliveries?.drug_manufacturer}</p>
                            <p
                                className={style['card-type']}
                                style={{
                                    color: deliveryStatus.color,
                                    fontWeight: 600,
                                }}
                            >
                                {deliveryStatus.text}
                            </p>
                        </div>
                    </div>
                </Col>
                <Col xs={24} sm={EX6022 ? 12 : 8} style={EX6022 ? { display: 'flex', justifyContent: 'flex-end' } : {}}>
                    <div className={style['container-button']}>
                        {router?.pathname !== '/[clientId]/my-orders/deliveries/[delivery]/[order]' &&
                            !isManualDelivery &&
                            order_deliveries?.track_id !== 'Llama a Nadro para reconfirmar' &&
                            order_deliveries?.status?.id !== OrderDeliveryStatus.PROCESSESING_ORDER &&
                            !!order_deliveries?.track_id && (
                                <div className={style['container-delivery']}>
                                    <DeliveryNumber
                                        hash={order_deliveries?.track_id}
                                        label={t('trackingOne')}
                                        text={t('trackingTwo')}
                                        containerClassName={style['container-delivery-number']}
                                        labelClassName={style['label']}
                                        deliveryNumberClassName={style['hash-container']}
                                    />
                                </div>
                            )}

                        {EX6022 &&
                            showError &&
                            order_deliveries?.status?.id !== OrderDeliveryStatus.PROCESSESING_ORDER && (
                                <TooltipError typeError={EX6561 ? order_deliveries?.status : typeError()} />
                            )}

                        {isManualDelivery && order_deliveries?.drug_manufacturer_link !== URLPREFIX && (
                            <Link
                                target={'_blank'}
                                className={`${isDetails ? 'icon__open-in-web-1' : 'icon__open-in-web-2'} ${
                                    style['button-web']
                                }`}
                                onClick={() => handleWebButton()}
                                href={order_deliveries?.drug_manufacturer_link}
                            >
                                <OpenInNewOutlinedIcon
                                    style={{
                                        width: 16,
                                        height: 16,
                                        color: '#0171E6',
                                        marginRight: 4,
                                    }}
                                />
                                <span style={{ textDecoration: 'underline' }}>Web</span>
                            </Link>
                        )}

                        {filename && (
                            <Whisper placement="bottom" controlId="control-id-hover" trigger="hover" speaker={tooltip}>
                                <span
                                    aria-label="btn-download"
                                    className={`${gtagClassDownload()} ${style['button-download']}`}
                                    onClick={() => {
                                        handleFileButton();
                                        DownloadFile();
                                    }}
                                >
                                    <FileDownloadOutlinedIcon
                                        style={{
                                            color: '#0171E6',
                                            height: 20,
                                            width: 20,
                                        }}
                                    />
                                </span>
                            </Whisper>
                        )}
                    </div>
                </Col>
            </Row>
            {router.pathname === '/my-orders/deliveries/[delivery]/[order]' && isManualDelivery && (
                <div style={{ margin: '24px 0px' }}>
                    <InfoMessage
                        children={
                            <span>
                                {!EX4201
                                    ? 'Si aún no lo hiciste, descargá el TXT y subilo en el sitio web de la droguería. Si esta no permite subir archivos, agregá de a uno los productos al carrito.'
                                    : t('myOrders.orderDetails.textHelperDownloadTxt')}
                            </span>
                        }
                        backgroundColor={'#FFF9E6'}
                        IconColor={'#FFC700'}
                    />
                </div>
            )}
            <Row className={style['container-body']}>
                <BodyDeliveries order_deliveries={order_deliveries} />
            </Row>
            {!deliveryDetail ? (
                <Row>
                    <Footer delivery_id={order_deliveries?.delivery_id} order_number={order} />
                </Row>
            ) : (
                <Row>
                    <ListProducts products={order_deliveries?.products} />
                    <div className={style['line']}></div>
                </Row>
            )}
        </Grid>
    );
}

export default DetailsDeliveries;
