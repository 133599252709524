import { formatMoney } from 'utils/number';

export const isBelowMinimumPurchase = (minValue, currentValue) => {
    if (minValue === 0 || minValue === null) return false;
    return (
        parseFloat(formatMoney(minValue).replace(/\./g, '').replace(',', '.')) >=
        parseFloat(formatMoney(currentValue).replace(/\./g, '').replace(',', '.'))
    );
};

export const validationMinimumPurchase = (user: any, min_purchase, subTotalValueColumn): boolean => {
    return isBelowMinimumPurchase(min_purchase, subTotalValueColumn);
};
