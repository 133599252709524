// hooks
import { useMoreThanOne } from '../hooks';

/* #region show caret up */
interface showCaretUpProps {
    checked: boolean;
    isLowest: boolean;
    product: any;
}

export const showCaretUp = ({ checked, isLowest, product }: showCaretUpProps): boolean => {
    const { EX5749, ItsmorethanOne } = useMoreThanOne();
    if (EX5749) {
        if (ItsmorethanOne) {
            return false;
        } else {
            return checked && !isLowest && !!product;
        }
    } else {
        return checked && !isLowest && !!product;
    }
};
/* #endregion */
