import { useEffect, useState } from 'react';
import styles from './DrawerFooter.module.css';
import Image from 'next/image';
import { Loader } from 'rsuite';
import { EX5057 } from 'config/flags';
import { useTranslation } from 'react-i18next';

const DrawerFooter = ({ initComparation, hasScroll, isFetching }) => {
    //translation by Country
    const { t } = useTranslation();
    return (
        <div className={`${styles['container']} ${hasScroll && styles['has-scroll']}`}>
            <button
                className={`button__start-comparison-1 ${styles['button']}`}
                onClick={() => {
                    initComparation();
                }}
            >
                <span className={`button__start-comparison-1 ${styles['text']}`}>
                    <span className={'button__start-comparison-1'}>
                        {!!EX5057 ? t('shoppingCart.shoppingCart_Sixteen') : 'Iniciar comparativa'}
                    </span>
                    {isFetching ? (
                        <Loader
                            style={{
                                marginLeft: 5,
                            }}
                        />
                    ) : (
                        <Image
                            className={`button__start-comparison-1 ${styles['arrow']}`}
                            alt="arrow"
                            src={'/assets/images/arrow_forward.svg'}
                            width={24}
                            height={24}
                        />
                    )}
                </span>
            </button>
        </div>
    );
};

export default DrawerFooter;
