import { Col, Placeholder, Row } from "rsuite";
import ParagraphLoader from "../ParagraphLoader/ParagraphLoader";

const DrawerBodyLoader = () => {
    return <Col xs={17}>
    <Row>
        <Col xs={24}>
            <ParagraphLoader rows={1} rowHeight={15} active />
            <ParagraphLoader rows={1} rowHeight={15} active />
        </Col>
    </Row>
</Col>
}

export default DrawerBodyLoader;