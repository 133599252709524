import React from 'react';
import styles from './FooterMobile.module.css';

const FooterMobile = () => {
    const _siteMap = [
        {
            label: 'Calcular ahorro',
            href: 'https://extendeal.com/calcular-ahorro',
        },
        {
            label: 'Distribuidores',
            href: 'https://extendeal.com/distribuidores',
        },
        {
            label: 'Empleos',
            href: 'https://www.careers-page.com/extendeal',
        },
        {
            label: 'Blog',
            href: 'https://extendeal.com/blog-extendeal-pharma',
        },
        {
            label: 'Contacto',
            href: 'https://extendeal.com/contacto',
        },
    ];

    const _socialNetwork = [
        {
            src: '/assets/images/linkedin.svg',
            href: 'https://www.linkedin.com/company/extendeal',
            alt: 'linkedin icon',
        },
        {
            src: '/assets/images/insta.svg',
            href: 'https://www.instagram.com/extendealok/',
            alt: 'instagram icon',
        },
        {
            src: '/assets/images/faceb.svg',
            href: 'https://www.facebook.com/extendeal/',
            alt: 'facebook icon',
        },
        {
            src: '/assets/images/yout.svg',
            href: 'https://www.youtube.com/@extendeal',
            alt: 'youtube icon',
        },
    ];

    return (
        <div className={styles.footer}>
            <div className={styles.footerInner}>
                <div className={styles.frameParent}>
                    <div className={styles.frameGroup}>
                        <div className={styles.isologohorizontalParent}>
                            <img
                                className={styles.logoMonocromaticoBlanco1Icon}
                                alt="Logo"
                                src="/assets/images/logo-extendeal-footer.svg"
                            />
                            <div className={styles.laPlataformaDe}>
                                La plataforma de compra inteligente para farmacias
                            </div>
                        </div>
                        <div className={styles.frameContainer}>
                            <div className={styles.frameDiv}>
                                <div className={styles.mapaDeSitioWrapper}>
                                    <div className={styles.mapaDeSitio}>MAPA DE SITIO</div>
                                </div>
                                {_siteMap.map(({ label, href }) => {
                                    return (
                                        <a className={styles.calcularAhorro} href={href} target="_blank">
                                            {label}
                                        </a>
                                    );
                                })}
                            </div>
                            <div className={styles.frameParent1}>
                                <div className={styles.calcularAhorroWrapper}>
                                    <div className={styles.plataforma}>plataforma</div>
                                </div>
                                <div className={styles.iniciarSesinWrapper}>
                                    <a
                                        className={styles.calcularAhorro}
                                        href="https://pharma.extendeal.com/login"
                                        target="_blank"
                                    >
                                        Iniciar sesión
                                    </a>
                                </div>
                            </div>
                            <div className={styles.frameWrapper}>
                                <div className={styles.frameWrapper1}>
                                    <div className={styles.miscelaneasParent}>
                                        <div className={styles.frameWrapper}>
                                            <div className={styles.calcularAhorroWrapper}>
                                                <div className={styles.plataforma}>seguinos</div>
                                            </div>
                                        </div>
                                        <div className={styles.vectorParent}>
                                            {_socialNetwork.map(({ href, alt, src }) => {
                                                return (
                                                    <a href={href} target="_blank">
                                                        <img className={styles.vectorIcon} alt={alt} src={src} />
                                                    </a>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.dividerParent}>
                        {/* <img className={styles.dividerIcon} alt="" src="Divider.png" /> */}
                        <div className={styles.extendealTodosLosDerechosRWrapper}>
                            <div className={styles.extendealTodosLosDerechos}>
                                © 2024 Extendeal Todos los derechos reservados. Av. Libertador 4980 Piso 7, CP: 1426,
                                CABA, Argentina. (+5411) 3137-5638
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FooterMobile;
