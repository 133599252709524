// mui
import ScheduleIcon from '@mui/icons-material/Schedule';
// style
import style from './RenderLastSearch.module.css';

const RenderLastSearch = ({ productName }) => {
  return (
    <div className={style['container']}>
      <ScheduleIcon />

      <div>
        <p>{productName}</p>
      </div>
    </div>
  );
};

export default RenderLastSearch;
