const colors = ['rgba(255, 48, 48, 0.15)', 'rgba(7, 173, 75, 0.15)', 'rgba(255, 99, 0, 0.15)'];
const textColor = ['#8F0000', '#023B1A', '#662700'];
const arrayUsers = [];

function getIndexColor(num) {
    let res = num % 3;
    if (res === 0) {
        return 2;
    } else {
        return Math.abs(res - 1);
    }
}

export const backgroundColorChange = (avatar) => {
    const randomIndex = Math.floor(Math.random() * colors.length);
    const index = arrayUsers?.findIndex((item) => item?.name === avatar);
    let randomBackgroundColor = colors[randomIndex];
    let randomTextColor = textColor[randomIndex];
    
    if (index !== -1) {
        randomBackgroundColor = colors[getIndexColor(index)];
        randomTextColor = textColor[getIndexColor(index)];
    } else {
        arrayUsers[arrayUsers.length] = { name: avatar };
        randomBackgroundColor = colors[arrayUsers.length];
        randomTextColor = textColor[arrayUsers.length];
    }

    return {
        backgroundColor: randomBackgroundColor,
        textColor: randomTextColor,
    };
};
