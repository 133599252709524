import { useEffect, useState } from 'react';
// utils && hooks
import { COUNTRY } from 'utils/country';

export const usePolicyOrTerms = () => {
    const [openModalTerms, setOpenModalTerms] = useState<boolean>(false);
    const [openModalPolicy, setOpenModalPolicy] = useState<boolean>(false);
    const [userLocaleCountryCode, setUserLocaleCountryCode] = useState(null);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const storedLocale = JSON.parse(localStorage.getItem('locale'));
            setUserLocaleCountryCode(storedLocale);
        }
    }, []);

    const countriesWithPolicy = [COUNTRY.MX, COUNTRY.CO];
    const countriesWithTerms = [COUNTRY.AR, COUNTRY.MX, COUNTRY.CO];

    const checkIfHasPolicy = (userLocale: string) => {
        return countriesWithPolicy.some((country) => country === userLocale);
    };

    const checkIfHasTerms = (userLocale: string) => {
        return countriesWithTerms.some((country) => country === userLocale);
    };

    const checkIfHasPolicyAndTerms = (userLocale: string) => {
        return checkIfHasPolicy(userLocale) && checkIfHasTerms(userLocale);
    };

    const checkIfHasPolicyOrTerms = (userLocale: string) => {
        const policy = checkIfHasPolicy(userLocale);
        const terms = checkIfHasTerms(userLocale);
        return policy || terms;
    };

    return {
        openModalTerms,
        setOpenModalTerms,
        openModalPolicy,
        setOpenModalPolicy,
        checkIfHasPolicy,
        checkIfHasTerms,
        checkIfHasPolicyAndTerms,
        checkIfHasPolicyOrTerms,
        userLocaleCountryCode,
    };
};
