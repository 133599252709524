import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(({ palette }) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  formatRequest: {
    color: "#017C7A",
    fontSize: 12,
    fontFamily: "Source Sans Pro",
  },
  container_submitFormat: {
    display: "flex",
    height: 36.5,
    alignItems: "center",
  },
  row_submitFormat: {
    width: 400,
    display: "flex",
    flexDirection: "column",
  },
  row_flex: {
    display: "flex",
    alignItems: "center",
  },
  text_correctFormat: {
    marginLeft: 8,
    color: "#333333",
    fontSize: 16,
    fontFamily: "Source Sans Pro",
  },
  label: {
    cursor: "pointer",
    paddingLeft: 4,
    fontSize: 16,
    color: "#333333",
  },
  labelDisabled: {
    cursor: "default",
    color: "#ACACAC",
    paddingLeft: 4,
    fontSize: 16,
  },
  uploadPhoto: {
    opacity: 0,
    position: "absolute",
    zIndex: -1,
  },
}));

export default useStyles;
