import { t } from 'i18next';
// components
import { Button, Modal } from 'rsuite';
// utils && hooks
import { useParseIdToNameDrugManufacturer } from '@commons/modules/TableProductComparisonV2/hooks';
// style
import style from './ModalMinMaxQuantity.module.css';

export const ModalMinMaxQuantity = ({ open, onClose }) => {
    // parseIdToNameDrugManufacturer hook
    const { parseIdToNameDrugManufacturer } = useParseIdToNameDrugManufacturer();

    return (
        <Modal open={open} className={style['modal']}>
            <Modal.Header closeButton={false} className={style['modal-header']}>
                <p className={style['title-modal']}>{t('comparative.minMaxQuantityModal1')}</p>
            </Modal.Header>
            <Modal.Body className={style['body-modal']}>
                <p className={style['subtitle-modal']}>
                    {t('comparative.minMaxQuantityModal2', { drugmanufacturerName: parseIdToNameDrugManufacturer() })}
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button block className={style['action-button-modal']} onClick={() => onClose()}>
                    <span className={style['text-button-active']}>{t('comparative.minMaxQuantityModal3')}</span>
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
