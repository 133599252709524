import React from 'react';
// COMPONENTS
import { Placeholder } from 'rsuite';
// STYLES
import style from './RecommendedCardBodyImg.module.css';

export const RecommendedCardBodyImg = ({ imageLoad, setImageLoad, recommended }) => {
    return (
        <div className={style['container']}>
            {!imageLoad && (
                <Placeholder.Paragraph
                    graph="image"
                    active
                    style={{
                        height: 150,
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    rows={0}
                />
            )}
            <img
                src={`${process.env.NEXT_PUBLIC_CLOUDFRONT_IMAGES_EP}/${process.env.NEXT_PUBLIC_CLOUDFRONT_FOLDER_ONE}/${recommended?.product?.ean}.png`}
                alt="imagen medicamento"
                onError={(e: any) => (e.target.src = '/assets/images/default.png')}
                onLoad={(e: any) => setImageLoad(true)}
                width={150}
                height={150}
                style={{
                    display: !imageLoad ? 'none' : 'inline',
                    objectFit: 'cover', // Agregando objectFit para conservar la calidad
                }}
            />
        </div>
    );
};
