import React, { memo, useEffect } from 'react';
// COMPONENTS
import { CellCdo } from './CellCdo';
// UTILS
import { isBelowMinimumPurchase } from '../../../utils';
import { useTotalCell } from './hooks';
// INTERFACES
import { DrugManufacturersId } from 'utils/interfaces';
// STYLES
import { CellPrice } from './CellPrice';

const Cell = ({
    drugManufacturerId,
    user,
    userLocaleCountryCode,
    addMinimunPurchase,
    removeMinimunPurchase,
    min_purchase,
    expandedFooter,
    skeleton,
    cdo_is_enabled,
    cdo_status_id,
    drugManufacturer,
    drugManufacturerEnabled,
    hasDrugManufacturerError,
    drugManufacturerTypeId,
    vendor_accepted,
}) => {
    const { displayTotal, displayTotalCell, displayQuantityCell, displayProductsCell } = useTotalCell({
        drugManufacturerId,
    });

    /* #region listener update array with drugmanufacturersId by below minimun purchase */
    useEffect(() => {
        const haveProductsSelected = displayProductsCell > 0;
        if (isBelowMinimumPurchase(min_purchase, displayTotalCell) && haveProductsSelected) {
            return addMinimunPurchase(drugManufacturerId);
        } else if (!isBelowMinimumPurchase(min_purchase, displayTotalCell) || !haveProductsSelected) {
            return removeMinimunPurchase(drugManufacturerId);
        }
    }, [displayTotalCell]);
    /* #endregion */

    if (drugManufacturerId === DrugManufacturersId.CentralDeOfertas) {
        return (
            <CellCdo
                expandedFooter={expandedFooter}
                skeleton={skeleton}
                user={user}
                min_purchase={min_purchase}
                userLocaleCountryCode={userLocaleCountryCode}
                subtotal={displayTotal}
                subTotalValueColumn={displayTotalCell}
                productQuantityByDrugManufacturer={(id: number) => displayProductsCell}
                quantityUnitsByDrugManufacturer={(id: number) => displayQuantityCell}
                drugManufacturerEnabled={drugManufacturerEnabled}
                cdo_is_enabled={cdo_is_enabled}
                cdo_status_id={cdo_status_id}
                drugManufacturer={drugManufacturer}
                hasDrugManufacturerError={hasDrugManufacturerError}
                drugManufacturerId={drugManufacturerId}
            />
        );
    } else {
        return (
            <CellPrice
                expandedFooter={expandedFooter}
                skeleton={skeleton}
                min_purchase={min_purchase}
                user={user}
                userLocaleCountryCode={userLocaleCountryCode}
                subTotalValueColumn={displayTotalCell}
                subtotal={displayTotal}
                productQuantityByDrugManufacturer={(id: number) => displayProductsCell}
                quantityUnitsByDrugManufacturer={(id: number) => displayQuantityCell}
                drugManufacturerEnabled={drugManufacturerEnabled}
                hasDrugManufacturerError={hasDrugManufacturerError}
                drugManufacturerId={drugManufacturerId}
                drugManufacturerTypeId={drugManufacturerTypeId}
                vendor_accepted={vendor_accepted}
            />
        );
    }
};

export default memo(Cell);
