import { memo } from 'react';
import { t } from 'i18next';
// contexts
import { useProgressBarPriceContext } from 'context';
// components
import { Icon } from './Icon';
// styles
import styles from './Footer.module.css';

const Footer = () => {
    const { completed, failed } = useProgressBarPriceContext();
    return (
        <div className={`${styles['container']} ${styles['fade-in']}`}>
            {(failed || !completed) && (
                <>
                    <Icon />
                    <p className={styles['text']}>{t('progressBarPrice.title4')}</p>
                </>
            )}
        </div>
    );
};

export default memo(Footer);
