import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
    inputRowContainer: {
        width: 512,
        height: 40,
        backgroundColor: '#EEEEEE',
        borderRadius: 4,
        alignItems: 'center',
        display: 'flex',
    },
    inputNameValue: {
        width: 216,
        padding: '9px 16px',
        color: '#333333',
        fontSize: 16,
        fontFamily: 'Source Sans Pro',
        fontWeight: 600,
        textTransform: 'uppercase',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    inputError: {
        height: 16,
        width: 512,
        color: '#FF3030',
        fontSize: 12,
        paddingLeft: 210,
    },
    tooltipNewDrugmanufacturer: {
        alignItems: 'center',
        justifyContent: 'center',
        top: '29px',
        left: '-113px',
        padding: '0px 16px',
        position: 'absolute',
        backgroundColor: '#04273F',
        color: 'white',
        height: 24,
        fontFamily: 'Source Sans Pro',
        fontSize: 14,
        borderRadius: 4,
    },
});
