import { Schema } from 'rsuite';

const { StringType, NumberType } = Schema.Types;

export const subscriptionFormValidationSchema = Schema.Model({
    credit_card_name: StringType()
        .isRequired('El nombre del titular es obligatorio')
        .pattern(/^[a-zA-Z\s]+$/, 'El nombre debe contener solo letras y espacios'),
    card_number: StringType()
        .isRequired('El número de tarjeta es obligatorio')
        .pattern(/^\d{16}$/, 'El número de tarjeta debe tener 16 dígitos'),
    expiration_date: StringType()
        .isRequired('La fecha de vencimiento es obligatoria')
        .pattern(/^(0[1-9]|1[0-2])\/\d{2}$/, 'Debe estar en formato MM/AA (ej. 01/25)'),
    security_code: StringType()
        .isRequired('El código de seguridad es obligatorio')
        .pattern(/^\d{3,4}$/, 'El código de seguridad debe tener 3 o 4 dígitos'),
});