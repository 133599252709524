import React from 'react';
import Link from 'next/link';

import Header from '../Header/Header';
import style from './CardAlertLogin.module.css';
//rsuite
import { Panel, Button } from 'rsuite';
//mui
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { EX5057 } from 'config/flags';
import useChangeLanguage from 'utils/hooks/use-change-language';
import { COUNTRY } from 'utils/country';
import { useTranslation } from 'react-i18next';

function CardAlertLogin({ haveLogo, title, icon, subtitle, msg }) {
    // translation by Country
    const { t } = useTranslation();
    const userLocale = typeof window !== 'undefined' ? navigator?.language : null;
    switch (userLocale) {
        case 'es-AR':
            useChangeLanguage(COUNTRY.AR);
            break;
        case 'en-US':
            useChangeLanguage(COUNTRY.US);
            break;
        case 'pt-BR':
            useChangeLanguage(COUNTRY.BR);
            break;
        case 'es-MX':
            useChangeLanguage(COUNTRY.MX);
            break;
        case 'es-CO':
            useChangeLanguage(COUNTRY.CO);
            break;
        case 'es-ES':
            useChangeLanguage(COUNTRY.ES);
            break;
        default:
            useChangeLanguage('es');
            break;
    }
    return (
        <Panel bordered header={<Header haveLogo={haveLogo} title={title} />} className={style['container-form']}>
            {subtitle && (
                <p className={style['subtitle']}>
                    {icon} {subtitle}
                </p>
            )}
            {msg && <p className={style['text-msg']}>{msg}</p>}

            <Link href="/login" className="button__back-to-login-1">
                <Button appearance="primary" type="submit" className={`button__back-to-login-1 ${style['button']}`}>
                    <span className={`button__back-to-login-1 ${style['buttonText']}`}>
                        {EX5057 ? t('login.login43') : 'Ir a iniciar sesión'}
                        <ArrowForwardIcon className={`button__back-to-login-1  ${style['icon-navigation']}`} />
                    </span>
                </Button>
            </Link>
        </Panel>
    );
}

export default CardAlertLogin;
