import { t } from 'i18next';
// rsuite
import { Button, Tooltip, Whisper } from 'rsuite';
// mui
import SettingsIcon from '@mui/icons-material/Settings';
// style
import style from './ToolbarCESetting.module.css';
import { useCallback, useEffect, useState } from 'react';
import { ModulePriorities } from './modulePriorities';
import { ModuleDistribution } from './moduleDistribution';
import { ServiceConfig } from '../ToolbarCEAvatars/utils';
import { useProductComparison } from 'context/product-comparison.context';
import { EX5057 } from 'config/flags';
import { useUser } from 'utils/hooks';
import { Role } from 'common/types';

const ToolbarCESetting = () => {
    const { user } = useUser({});
    // service return true when its porcentage distribution
    const { service } = ServiceConfig();

    const [openDistribution, setOpenDistribution] = useState(false);
    const [openPriorities, setOpenPriorities] = useState(false);
    const {
        modalPriceWithOutSelection,
        openModalConfigDistribution,
        openModalConfigPriorities,
        showModalPriceWithOutSelection,
    } = useProductComparison();

    const handleZIndex = useCallback(() => {
        return showModalPriceWithOutSelection;
    }, [showModalPriceWithOutSelection]);

    const isDisableButton = () => {
        return user?.role_id === Role.Buyer;
    };

    useEffect(() => {
        if (service) {
            setOpenDistribution(openModalConfigDistribution);
        } else {
            setOpenPriorities(openModalConfigPriorities);
        }
    }, [openModalConfigDistribution, openModalConfigPriorities]);

    return (
        <div ref={modalPriceWithOutSelection}>
            <ModuleDistribution
                openModalDistribution={openDistribution}
                setOpenModalDistribution={setOpenDistribution}
            />

            <ModulePriorities open={openPriorities} setOpen={setOpenPriorities} />
            <Whisper
                trigger="hover"
                placement={'bottomStart'}
                controlId={`settings-priorities`}
                disabled={!isDisableButton()}
                speaker={
                    <Tooltip
                        style={{
                            fontSize: 14,
                            fontFamily: 'Source Sans Pro',
                            maxWidth: '100%',
                            width: '420px',
                        }}
                        visible={!isDisableButton()}
                    >
                        {isDisableButton() ? t('pointOfSale.tooltipPriorityCard') : ''}
                    </Tooltip>
                }
            >
                <Button
                    onClick={() => (service ? setOpenDistribution(true) : setOpenPriorities(true))}
                    className={
                        isDisableButton()
                            ? `chip__settings-1 ${style['button-settings-disabled']}`
                            : `chip__settings-1 ${style['button-settings']}`
                    }
                    style={{ zIndex: handleZIndex() ? 9999 : 1 }}
                    startIcon={<SettingsIcon className={style['settings-icon']} />}
                    disabled={isDisableButton()}
                >
                    {EX5057 ? t('efficientPurchasing.comparative.labelConfig') : 'Ajustes'}
                </Button>
            </Whisper>
        </div>
    );
};

export default ToolbarCESetting;
