import { RsuiteTooltip } from 'commons/components/RsuiteTooltip';
import React from 'react';
import style from './DrawerDescription.module.css';
import { useEllipsis, useUser } from 'utils/hooks';
import { capitalizeEveryFirstLetter } from 'utils/strings/capitalizeEveryFirstLetter';
import Image from 'next/image';

const DrawerDescription = ({ product }) => {
    const { user } = useUser({})

    const { isShowEllipsis, handleMouseEnter, handleMouseLeave } = useEllipsis(product?.description);
    const formattedProductDescription = capitalizeEveryFirstLetter(product.description);

    const isUnknownProduct = product.description === null && !!product?.is_unknown;
    const title = isUnknownProduct ? product?.barcode : formattedProductDescription;

    return (
        <div className={style['wrapper-description']}>
            {isUnknownProduct && <Image src={'/assets/images/frame_inspect.svg'} width={16} height={16} alt="frame_inspect" draggable={false} className={style['icon']}/>}

            {isShowEllipsis ? (
                <RsuiteTooltip trigger={'hover'} placement={'bottomEnd'} text={product.description}>
                    <p
                        className={style['product-name']}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        id={`${product.description}`}
                    >
                        {title}
                    </p>
                </RsuiteTooltip>
            ) : (
                <p
                    className={style['product-name']}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    id={`${product.description}`}
                >
                    {title}
                </p>
            )}
        </div>
    );
};

export default DrawerDescription;
