interface IDrugManufacturerGtag {
  id: number;
  drug_manufacturer_name: string;
  name_event: string;
}

export const drugManufacturerGtagEvent: IDrugManufacturerGtag[] = [
  {
    id: 1,
    drug_manufacturer_name: "Droguería del sud",
    name_event: "Delsud",
  },
  {
    id: 2,
    drug_manufacturer_name: "Suizo Argentina",
    name_event: "suizo",
  },
  {
    id: 3,
    drug_manufacturer_name: "Disval",
    name_event: "disval",
  },
  {
    id: 4,
    drug_manufacturer_name: "Asoprofarma",
    name_event: "asoprofarma",
  },
  {
    id: 5,
    drug_manufacturer_name: "Monroe Americana",
    name_event: "monroe",
  },
  {
    id: 6,
    drug_manufacturer_name: "Kellerhoff",
    name_event: "Kellerhoff",
  },
  {
    id: 7,
    drug_manufacturer_name: "Acofar",
    name_event: "Acofar",
  },
  {
    id: 8,
    drug_manufacturer_name: "20 De Junio",
    name_event: "20",
  },
  {
    id: 9,
    drug_manufacturer_name: "Cofarsur",
    name_event: "Cofarsur",
  },
  {
    id: 10,
    drug_manufacturer_name: "Droguería Sur",
    name_event: "sur",
  },
];
