import React from 'react'
import { Skeletons } from "commons/components/Orders/Skeletons";

function SkeletonHeader() {
  return (
    <div>
    <Skeletons bgcolor={"#E0E1E9"} width={40} height={14}  />
    <Skeletons bgcolor={"#E0E1E9"} width={400} height={14}  />
  </div>
  )
}

export default SkeletonHeader