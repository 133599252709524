import { makeStyles } from '@material-ui/core/styles';
import { rem } from 'polished';

const useStyles = makeStyles({
    row: {
        flexWrap: "nowrap",
        minHeight: rem("80px"),
        width: "100%",
        borderTop: "1px solid #D1D1D1",
        opacity: 1,
        padding: `${rem("19px")} ${rem("0px")}`,
        "&:hover": {
            background: "#EEEEEE 0% 0% no-repeat padding-box",
            "& #iconTrashContainer" : {
                visibility: "visible"
            }
        }
    },
    lastRow: {
        "&:hover": {
            backgroundColor: "transparent",
        }
    },
    containerButtonScrollTop: {
        width: 0,
        position: "relative",
        left: "4%",
        bottom: 0
    },    
    buttonScrollTop: {
        cursor: "pointer",
        width: rem("42px"),
        height: rem("42px"),
        display: "flex",
        borderRadius: 20,
        boxShadow: "1px 2px 6px #00000029",
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        opacity: 1,
        justifyContent: "center",
        alignItems: "center",
    },
    icon_scrollTop: {
        width: "24px",
        height: "24px",
        color: "#0171E6",
        opacity: 1,
    },
});

export default useStyles;