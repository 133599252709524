import { useFetchingContext, useProductsContext } from 'context';
import { useProductComparison } from 'context/product-comparison.context';
import { useMemo } from 'react';

const useTableData = () => {
    const { searchTerms, setWithoutSearchings } = useProductComparison();
    const { productGroups } = useProductsContext();
    const { loading } = useFetchingContext();

    const filtersByTerms = () => {
        if (!!searchTerms && searchTerms.trim().length > 0) {
            const filteredByTerms = productGroups.filter((group) => {
                const filterText = searchTerms.toLowerCase();
                if (!!searchTerms && searchTerms.trim().length > 0) {
                    return (
                        (group?.barcode && group?.barcode.includes(searchTerms)) ||
                        (group?.description && group?.description.toLowerCase().includes(filterText)) ||
                        (group?.laboratory && group?.laboratory.toLowerCase().includes(filterText)) ||
                        (group?.drug && group?.drug.toLowerCase().includes(filterText))
                    );
                }
                return true;
            });

            if (filteredByTerms.length === 0) {
                setWithoutSearchings(true);
            }
            return filteredByTerms;
        } else {
            setWithoutSearchings(false);
            return productGroups;
        }
    };

    const data = useMemo(() => {
        if (!loading) {
            return filtersByTerms();
        } else {
            return Array(6).fill(0);
        }
    }, [loading, searchTerms, productGroups]);

    return { data };
};

export default useTableData;
