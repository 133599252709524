import { useTranslation } from 'react-i18next';
// components
import { ExButton } from '@commons/EXComponentsLibrary';
import { UploadImage } from './UploadImage';
// interface
import { IMessagesDragDropUploader } from './MessagesDragDropUploader.interface';
// style
import style from './MessagesDragDropUploader.module.css';
import { Message } from 'rsuite';
import { maxProducts } from 'utils/constants';

const MessagesDragDropUploader = ({
    icon,
    src,
    text,
    linkText,
    subtitle,
    blockerFormat,
    playerRef,
    flag,
    EX6141
}: IMessagesDragDropUploader) => {
    // translation
    const { t } = useTranslation();

    return (
        <div className={style['container']}>
            <div className={style['icon-or-image-cotainer']}>
                {icon ? (
                    <>{icon}</>
                ) : (
                    <div className={style['container-img-icon']}>
                        <UploadImage src={src} />
                    </div>
                )}
            </div>
            
            <div style={{ width: !!blockerFormat ? '310px' : '480px' }} className={style['container-text-message']}>
                <p className={style['text-messages']} >{text}</p>
                { 
                    EX6141 &&
                    <>
                        <div>
                            <span className={style['text-messages']}>{t('efficientPurchasing.fileUpload.efficientPurchasing_fileUploadTwentyOne')}</span>
                            <span className={style['subtitle-text-message']}> .txt, .csv, excel</span>
                        </div>
                        <div>
                            <span className={style['text-messages']}>{t('efficientPurchasing.fileUpload.efficientPurchasing_fileUploadTwentyTwo')}</span>
                            <span className={style['subtitle-text-message']}> {maxProducts} {t('myOrders.labelProducts')}</span>
                        </div>
                </>
                }
                {linkText && <p className={style['link-text-messages']}>{linkText}</p>}
                {!!flag && (   
                    <ExButton className={style['button-upload-file']} appearance="alternative" size='lg'>
                        <span className="button__upload-file-2">{t('shoppingCart.shoppingCart_Four')}</span>
                    </ExButton>
                )}
                {subtitle && <p className={style['subtitle-text-message']}>{subtitle}</p>}
            </div>
        </div>
    );
};

export default MessagesDragDropUploader;
