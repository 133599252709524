import React from 'react';
import Slider from '@material-ui/core/Slider';
import { useStyles } from './SliderComponent.styles';

export default function SliderComponent({ handleChange, id, percentage }) {
    const classes = useStyles();
    return (
        <>
            <div className={classes.container}>
                <Slider
                    classes={{
                        root: classes.root,
                        rail: classes.rail,
                        track: classes.track,
                        mark: classes.mark,
                        markActive: classes.markActive,
                        thumb: classes.thumb,
                        valueLabel: classes.valueLabel,
                    }}
                    getAriaValueText={(value: number) => `${value}`}
                    valueLabelDisplay="auto"
                    defaultValue={0}
                    value={percentage?.percentage ?? 0}
                    onChange={(event: any, value: number | number[]) => handleChange(id, value)}
                    aria-labelledby="discrete-slider-small-steps"
                    step={1}
                    marks={Array.from({ length: 11 }, (_, index) => ({ value: index * 10 }))}
                    min={0}
                    max={100}
                />
                <div className={classes.containerValuePercentage}>
                    {percentage?.percentage ?? 0}
                    <div className={classes.iconPercentage}>
                        <img src="/assets/images/percent.svg" width={13.33} height={13.33} />
                    </div>
                </div>
            </div>
        </>
    );
}
