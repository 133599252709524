import React, { useEffect } from 'react';
// contexts
import { usePointOfSale } from 'context';
// components
import { ProgressBarPrice } from '@commons/components';
// utils && hooks
import { availableDrugManufacturers } from 'utils/drug_manufacturer';
import { useUser } from 'utils/hooks';

const ProgressBarPriceModule = ({
    dataOrderCreated,
    efficientPurchaseFromCart = false,
    setShowLoadingScreen,
    setHideBreadcrum,
}) => {
    const { user } = useUser({});
    const { pointOfSale } = usePointOfSale();

    //FF
    const EX6017 = user?.EX6017;

    useEffect(() => {
        localStorage.setItem('alertBanner', 'true');
        localStorage.setItem('warningAlert', 'true');
        if (EX6017) {
            localStorage.setItem('isRequiredVendor', 'false');
        }
    }, []);

    return (
        <ProgressBarPrice
            setHideBreadcrum={setHideBreadcrum}
            dataCreated={dataOrderCreated}
            quantityAvailableDrugManufacturers={availableDrugManufacturers(user?.pointsOfSale, pointOfSale)}
            efficientPurchaseFromCart={efficientPurchaseFromCart}
            onDiscard={setShowLoadingScreen}
        />
    );
};

export default ProgressBarPriceModule;
