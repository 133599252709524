// react
import { useEffect, useState } from 'react';
// context
import { useModalCdO } from 'context/modalCdO/modalCdO';
// enum
import { StatusCdo } from 'utils/interfaces';
// rsuite
import { Modal, Button, Loader } from 'rsuite';
// style
import style from './BannerCdoModal.module.css';
// icon
import PlusIcon from '@rsuite/icons/Plus';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

const BannerCdoModal = ({ request, recordset, loading, setterLoading }) => {
    // context
    const { isModalOpen, closeModal } = useModalCdO();
    // state
    const [isSuccess, setIsSuccess] = useState(false);
    // init
    useEffect(() => {
        if (!!recordset && recordset.cdo_status_id === StatusCdo.PENDING) {
            setIsSuccess(true);
            setterLoading(false);
            setTimeout(() => {
                closeModal();
                setIsSuccess(false);
            }, 1000);
        }
    }, [recordset.cdo_status_id]);

    return (
        <Modal open={isModalOpen} onClose={() => closeModal()} className={style['modal']}>
            <div className={style['container-button-close']}>
                <Button className={style['button-close-icon']} onClick={() => closeModal()}>
                    <CloseIcon />
                </Button>
            </div>

            <Modal.Header className={style['header-modal']} closeButton={false}>
                <Modal.Title className={style['title-modal']}>
                    <picture className={style['picture-modal']}>
                        <img src="/assets/images/beenhere.svg" />
                    </picture>
                    Estás a punto de unirte a Central de ofertas
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={style['body-modal']}>
                <p className={style['body-text-modal']}>
                    Vas a poder explorar ofertas y productos exclusivos para vos, para que ahorres aún más en cada
                    compra.
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    onClick={() => request()}
                    block
                    className={
                        `${!isSuccess && 'button__request-offer-center-3'} ` +
                        `${
                            !!loading
                                ? style['action-button-modal-loading']
                                : !!isSuccess
                                ? style['success-button-active']
                                : style['action-button-modal']
                        }`
                    }
                >
                    {!!loading ? (
                        <Loader />
                    ) : !!isSuccess ? (
                        <CheckIcon />
                    ) : (
                        <>
                            <PlusIcon className="button__request-offer-center-3" />
                            <p className={`button__request-offer-center-3 ${style['text-button-active']}`}>Solicitar</p>
                        </>
                    )}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default BannerCdoModal;
