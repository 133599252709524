import { useState } from 'react';
// contexts
import { useDrugManufacturerWidgetContext } from 'context';
// utils && hooks
import { typesOfErrors } from 'utils/drugManufacturers/errors';
import { EDrugManufacturerTypeId } from 'utils/enums/drugmanufacturerTypeId/DrugManufacturerTypeId';
import { useRouter } from 'next/router';
import { DrugManufacturersId } from 'utils/interfaces';

const useItem = () => {
    const { push, query } = useRouter();
    const { triggerModal, removeItem, requestVendor } = useDrugManufacturerWidgetContext();
    const [isDeleted, setIsDeleted] = useState<boolean>(false);
    const [isVendorRequested, setIsVendorRequested] = useState<boolean>(false);

    const onClickItem = (e: any, from: number, drugManufacturer: any) => {
        if (!disabledEdit(drugManufacturer)) {
            if (from === 2 && e?.target.tagName !== 'BUTTON' && e?.target?.tagName !== 'IMG') {
                if (drugManufacturer?.type.id === EDrugManufacturerTypeId.offline) {
                    localStorage.setItem('redirectTo', 'home');
                    push(`/${query.clientId}/settings/POS/${query?.posId}/addOfflineDrugstore/${drugManufacturer?.id}`);
                } else {
                    !typesOfErrors(drugManufacturer)?.hasSomeError &&
                        triggerModal(drugManufacturer, drugManufacturer.id, drugManufacturer.credential_types, drugManufacturer.type.id);
                }
            }
        }
    };

    const getClassNames = (drugManufacturer: any, from: number, styles) => {
        let classNames = styles['item']; // Clase base

        const applyHoverable = !typesOfErrors(drugManufacturer)?.hasSomeError && from === 2; // hoverable condition
        const applyDeleted = isDeleted; // deleted condition

        // Agregar clase para hoverable si la condición es verdadera
        if (applyHoverable && !disabledEdit(drugManufacturer)) {
            classNames += ` ${styles['hovereable']}`;
        }

        // Agregar clase para deleted si la condición es verdadera
        if (applyDeleted) {
            classNames += ` ${styles['slide-out-blurred-right']}`;
        }

        return classNames; // Devolver todas las clases concatenadas
    };

    const disabledEdit = (drugManufacturer: any): boolean =>
        [DrugManufacturersId.FarmaciasABC, DrugManufacturersId.AlmacenDeDrogas].includes(drugManufacturer.id);

    return {
        isDeleted,
        removeItem,
        setIsDeleted,
        setIsVendorRequested,
        isVendorRequested,
        requestVendor,
        onClickItem,
        getClassNames,
        disabledEdit,
    };
};

export default useItem;
