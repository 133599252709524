// handler to use across (up and down) of the keyboard. It used in list results and open search bar home 
export const handleKeyDownInputPicker = (
  evt,
  setter,
  data,
  router,
  user,
  pointOfSaleId,
  value,
  tag = null,
  setActiveSearches,
) => {
  if (evt.key === 'ArrowUp' || evt.key === 'ArrowDown') {
    setActiveSearches(true)
    setter((prevIndex) => {
      if (prevIndex === -1) {
        return evt.key === 'ArrowUp' ? data.length - 1 : 0;
      }
      const newIndex =
        evt.key === 'ArrowUp'
          ? Math.max(prevIndex - 1, 0)
          : Math.min(prevIndex + 1, data.length - 1);
      return newIndex;
    });
  } else if (evt.key === 'Enter') {
    if(tag === 'listResults'){
      return;
    }
    router.push(
      `/${user?.client?.id}/efficient-purchase/${pointOfSaleId}/search-results?search=${value}`
    );
  }
};
