import { EX3918, EX4616, EX5057 } from 'config/flags';
import { t } from 'i18next';
/**
 * Helper function to filter errors by code
 * @param errors
 * @param code
 * @returns
 */
export const filterErrorsByCode = (errors, code) => errors?.filter((elem) => elem.error_type?.id === code);

/**
 * Helper function to extract names from an array of errors
 * @param errorArray
 * @returns
 */
export const extractNames = (errorArray) => errorArray?.map((item) => item.name).join(', ');

/**
 * Helper function to get error title tooltip based on quantity
 * @param quantity
 * @returns
 */
export const getErrorTitleTooltip = (quantity) => {
    return quantity >= 2
        ? EX5057
            ? t('efficientPurchasing.credentialsError.efficientPurchasing_credentialsErrorTen')
            : 'Hay droguerías con usuario y/o contraseña incorrectos'
        : EX5057
        ? t('efficientPurchasing.credentialsError.errorUserOrPassword')
        : 'El usuario y/o contraseña son incorrectos';
};

/**
 * Helper function to get error subtext tooltip based on quantity and name
 * @param quantity
 * @param name
 * @returns
 */
export const getErrorSubtextTooltip = (quantity, name) => {
    const labelErrorSubtextTooltip =
        quantity >= 2
            ? EX4616
                ? t('efficientPurchasing.credentialsError.efficientPurchasing_credentialsErrorThirteen', {
                      value: name,
                  })
                : `Actualizá las credenciales para que ${name}, sean parte de tus comparativas.`
            : EX4616
            ? t('efficientPurchasing.credentialsError.efficientPurchasing_credentialsErrorTwelve', {
                  value: name,
              })
            : `Actualizá las credenciales para que ${name}, sea parte de tus comparativas.`;
    return labelErrorSubtextTooltip;
};

/**
 * Helper function to create error object
 * @param titleAlert
 * @param titleTooltip
 * @param subtextAlert
 * @param subtextTooltip
 * @param code
 * @param nameDrugmanufacturer
 * @param t
 * @returns
 */
export const createErrorObject = (
    titleAlert,
    titleTooltip,
    subtextAlert,
    subtextTooltip,
    code,
    nameDrugmanufacturer,
    t,
) => ({
    titleAlert,
    titleTooltip,
    subtextAlert: !EX3918 ? subtextAlert : t(subtextAlert, { nameDrugmanufacturer }),
    subtextTooltip: !EX3918 ? subtextTooltip : t(subtextTooltip, { nameDrugmanufacturer }),
    code,
});
