import React from 'react';
// COMPONENTS
import { Placeholder } from 'rsuite';
// INTERFACES
import { RadioProps } from './Radio.interface';
// STYLES
import cellStyles from '../../../../CellStyles.module.css';

const Radio = ({
    skeleton,
    hasDrugManufacturerError,
    product,
    checked,
    withoutChecks,
    handleClick,
    quantity,
    parentGenericProduct,
    isDisabledVendors,
    isLowest = false,
    EX6157 = false
}: RadioProps): JSX.Element => {
    const disabledRadio = () => {
        if(parentGenericProduct){
            if(isDisabledVendors){
                return true;
            }
            return quantity === 0;
        } 
        if(!product || isDisabledVendors){
            return true;            
        }

    }

    const validateCheckboxRoundError = () => {
        if(EX6157 && withoutChecks && quantity >= 1) {
            if(!!isLowest){
                return true;
            }
            return false;
        }
        if(withoutChecks && quantity === 0){
            return true;
        }
    }

    return (
        <div className={cellStyles['radio']}>
            {!skeleton ? (
                <input
                    type="checkbox"
                    checked={!!product && checked}
                    className={`${cellStyles['checkbox-round']} ${
                        validateCheckboxRoundError() ? cellStyles['checkbox-round-error'] : ''
                    }`}
                    onChange={handleClick}
                    //enables manual selection for generic
                    disabled={disabledRadio()}
                />
            ) : (
                <Placeholder.Paragraph graph="circle" rows={0} active className={cellStyles['placeholder-radio']} />
            )}
        </div>
    );
};

export default Radio;
