import { Schema } from 'rsuite';
const { StringType, DateType, NumberType,MixedType } = Schema.Types;

const validationSchema = Schema.Model({
  name: StringType().isRequired('Este campo es obligatorio.'),
  tax_identification_number: StringType().isRequired('Este campo es obligatorio.'),
  country_name: StringType().isRequired('Este campo es obligatorio.'),
  warehouse_address: StringType().isRequired('Este campo es obligatorio.'),
  phone_number: StringType()
    .isRequired('Este campo es obligatorio.')
    .pattern(/^[0-9]{10}$/, 'Debe ser un número de teléfono válido.'),
  email: StringType()
    .isEmail('Correo electrónico no válido.')
    .isRequired('Este campo es obligatorio.'),
  contact_name: StringType().isRequired('Este campo es obligatorio.'),
  contact_position: StringType().isRequired('Este campo es obligatorio.'),
  contact_phone_number: StringType()
    .isRequired('Este campo es obligatorio.')
    .pattern(/^[0-9]{10}$/, 'Debe ser un número de teléfono válido.'),
  contact_email: StringType()
    .isEmail('Correo electrónico no válido.')
    .isRequired('Este campo es obligatorio.'),
  commercial_license_number: StringType('Este campo es alfanumérico'),
  commercial_expiration_date: DateType('Este campo es de tipo fecha'),
  minimum_purchase_amount: NumberType().isInteger('Solo se permiten números')
});

export default validationSchema;