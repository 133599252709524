// react
import { useState, useRef } from 'react';
// hooks
import { useUser } from 'utils/hooks';
// interface
import { ISelectTag } from './SelectTag.interface';
// rsuite
import { Button, ButtonToolbar, CheckPicker, Checkbox } from 'rsuite';
// helper
import { fillingInput } from './SelectTag.helpers';
// style
import style from './SelectTag.module.css';
//gtag
import * as gtag from 'utils/gtag';
import { EX5057 } from 'config/flags';
import { useTranslation } from 'react-i18next';

const SelectTag = ({
    placeholder,
    icon,
    setListTagTerms,
    listTagTerms,
    handlerClearFilters,
    selectedTag,
    setSelectedTag,
    timerSkeleton,
}: ISelectTag) => {
    // ref
    const buttonRef = useRef(null);
    // hooks
    const { user } = useUser({});
    const { t } = useTranslation();
    //state
    const [hasValueSet, setHasValueSet] = useState(false);
    const [selectedItems, setSelectedItem] = useState([]);

    // handler
    const handleChange = (value: any[]) => {
        setSelectedItem(value);
        setSelectedTag(value);
    };

    const handleApplyClick = (evt: any) => {
        evt.preventDefault();
        setHasValueSet(selectedItems.length > 0);
        setListTagTerms(selectedTag);
        buttonRef?.current.close();
    };

    const handleCleanFilters = () => {
        handlerClearFilters('pos', hasValueSet);
        buttonRef?.current.close();
        setTimeout(() => {
            setHasValueSet(false);
        }, 1000);
    };

    // recordset
    const recorsetPos = fillingInput(user?.pointsOfSale);

    const allValue = recorsetPos?.map((item: any) => item.value);

    const pointOfSales = (evt) => {
        gtag.event({
            action: 'click',
            category: 'my-orders',
            label: 'button__apply-filter-pos-1',
            value: 0,
        });
        handleApplyClick(evt);
    };

    const handleRowPointOfSale = (item) => {
        return (
            <div className={style['row-container']}>
                {item.reference_name ? (
                    <>
                        <span className={style['name-withReference']}>{item.label}</span>
                        <span className={style['referenceName']}>{item.reference_name}</span>
                    </>
                ) : (
                    <span className={style['name']}>{item.label}</span>
                )}
            </div>
        );
    };

    return (
        <div className={style['wrapper-container']}>
            <CheckPicker
                disabled={timerSkeleton}
                ref={buttonRef}
                onClean={handleCleanFilters}
                data={recorsetPos}
                size="lg"
                className={style['select-picker']}
                placeholder={<span className={style['placeholder-text']}>{placeholder}</span>}
                renderMenuItem={(label, item) => {
                    return handleRowPointOfSale(item);
                }}
                placement="bottom"
                onChange={handleChange}
                value={selectedTag || listTagTerms}
                renderExtraFooter={() => (
                    <div className={style['footer-container']}>
                        <Checkbox
                            inline
                            indeterminate={selectedTag.length > 0 && selectedTag.length < allValue.length}
                            checked={selectedTag.length === allValue.length}
                            onChange={() => {
                                setSelectedTag(selectedTag.length === allValue.length ? [] : allValue);
                            }}
                        >
                            {!!EX5057 ? t('myOrders.labelSelectAll') : 'Seleccionar todo'}
                        </Checkbox>
                        <ButtonToolbar>
                            <Button
                                className={`button__apply-filter-pos-1 ${style['footer-button']}`}
                                appearance="primary"
                                size="sm"
                                onClick={(evt) => pointOfSales(evt)}
                            >
                                {!!EX5057 ? t('myOrders.labelApply') : 'Aplicar'}
                            </Button>
                        </ButtonToolbar>
                    </div>
                )}
            />

            <div className={style['container-icon']}>{icon}</div>
        </div>
    );
};

export default SelectTag;
