import { memo, useEffect, useState } from 'react';
import { t } from 'i18next';
// configs
import { EX6999 } from 'config/flags';
// COMPONENTS
import { Toggle } from 'rsuite';
// UTILS && HOOKS
import { useUser } from 'utils/hooks';
// INTERFACES
import { GenericSwitchProps } from './GenericSwitch.interface';
// STYLES
import styles from './GenericSwitch.module.css';

const GenericSwitch = ({ enabled, onChangeToggle }: GenericSwitchProps): JSX.Element => {
    const [isChecked, setIsChecked] = useState<boolean>(false);
    const { user } = useUser({});
    const EX6144 = user?.EX6144;

    const handleChange = (checked) => {
        if (EX6999) {
            localStorage.setItem('genericSwitch', checked);
            setIsChecked(checked);
            onChangeToggle(checked);
        }
    };

    useEffect(() => {
        if (EX6999) {
            const genericSwitchLS = JSON.parse(localStorage.getItem('genericSwitch'));
            if (genericSwitchLS === null) {
                setIsChecked(false);
                localStorage.setItem('genericSwitch', `${false}`);
            } else {
                if (!!genericSwitchLS) {
                    setIsChecked(genericSwitchLS);
                    onChangeToggle(genericSwitchLS);
                }
            }
        }
    }, []);

    return (
        <div className={styles['container']} style={{ alignItems: EX6144 ? 'flex-start' : 'center' }}>
            <div className={styles['box']}>
                <p className={styles['text']}>{t('defaultDrugs.labelSwitch')}</p>
                {EX6999 ? (
                    <Toggle
                        disabled={!enabled}
                        arial-label="Switch"
                        className={styles['switch']}
                        checked={isChecked}
                        onChange={(checked) => handleChange(checked)}
                    />
                ) : (
                    <Toggle
                        disabled={!enabled}
                        arial-label="Switch"
                        className={styles['switch']}
                        onChange={(checked) => onChangeToggle(checked)}
                    />
                )}
            </div>
        </div>
    );
};

export default memo(GenericSwitch);
