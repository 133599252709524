// rsuite
import { Col, Badge } from 'rsuite'
// mui v5
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined'
// style
import style from './CartBadge.module.css'
import { useStatusContext } from 'context/control-state'

const CartBadge = ({ quantityCart, toggle, pointOfSaleId, isZetti }) => {
    const { counterStep } = useStatusContext()

    return (
        <>
            {!isZetti && (
                <div
                    className={`button__shopping-cart ${style['container-shopping-cart']}`}
                    onClick={() => !isZetti && pointOfSaleId && toggle(pointOfSaleId)}
                    id="step-4"
                >
                    <div className={`button__shopping-cart ${style['container-cart']}`}>
                        {counterStep === '#step-5' && <div className={style['container-shopping-cart-blur']}></div>}
                        <ShoppingCartOutlinedIcon className={`button__shopping-cart ${style['icon-index']}`} />
                        <div className={style['container-badge']}>
                            <Badge className={style['badge-index']} content={quantityCart} />
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default CartBadge
