// import { useProductComparison } from 'context/product-comparison.context';
import { useEffect, useState } from 'react';
import { useUser } from 'utils/hooks';
import { DrugManufacturersId } from 'utils/interfaces';
import { OfferCenters } from 'utils/enums';
import { ModalPercentageDistribution } from '../ModalPercentageDistribution';
import { EDrugManufacturerTypeId } from 'utils/enums/drugmanufacturerTypeId/DrugManufacturerTypeId';
import { EX6381 , EX7062, EX7583} from 'config/flags';
import { useDrugManufacturersContext, useFetchingContext, useProductsContext } from 'context';

const PercentageDistribution = ({
    orderId,
    open,
    setOpen,
    pointOfSale,
    percentages,
    setReloadDistribution,
    reloadDistribution,
    newDrugmanufacturer,
}) => {
    const { user, mutateUser } = useUser({});
    const [tolerance, setTolerance] = useState('0,00');
    const [newPercentage, setNewPercentage] = useState([]);
    const [deletePercentage, setDeletePercentage] = useState(true);
    // const { 
    //     // drugManufacturer, 
    //     loading } = useProductComparison();
    const { products } = useProductsContext()
    const { loading } = useFetchingContext();
    const { drugManufacturer } = useDrugManufacturersContext();

    const FFEX6381 = EX6381;

    useEffect(() => {
        handleGetPercentageOfTolerance();
    }, [open, drugManufacturer, loading, percentages]);

    useEffect(() => {
        if (orderId) {
            let findTolerance = user?.pointsOfSale?.find((p) => pointOfSale?.id === p?.id);
            if (findTolerance?.d_tolerance === null) {
                setTolerance('0,00');
            } else {
                const toleranceValue = EX7062 ? products[0]?.d_tolerance.toString() : findTolerance?.d_tolerance?.toString();      
                
                setTolerance(toleranceValue?.replace('.', ','));
                
                
            }
        }
    }, [user, open]);

    const handleClose = (value) => {
        setOpen(value);
    };

    const handleGetPercentageOfTolerance = async () => {
        if (percentages?.percentages_distributed) {
            setDeletePercentage(false);
            handleCreateArrayDistribution(percentages?.percentages_distributed);
        } else if (percentages?.data?.data) {
            setDeletePercentage(false);
            handleCreateArrayDistribution(percentages?.data?.data?.percentages_distributed);
        } else {
            setDeletePercentage(true);
            const controlArrayCdo = [];
            drugManufacturer?.map((item) => {
                if (item?.id === DrugManufacturersId.CentralDeOfertas && item?.cdo_status_id === OfferCenters.Enabled) {
                    controlArrayCdo.push(item);
                } else if (item?.id !== DrugManufacturersId.CentralDeOfertas) {
                    controlArrayCdo.push(item);
                }
            });
            handleCreateArrayDistribution(controlArrayCdo);
        }
    };

    const handleCreateArrayDistribution = (drugManufacturers) => {
        if (EX7583) {
            const filters = FFEX6381
                ? drugManufacturers?.filter(
                      (item) =>
                          (!!item?.vendor_accepted && item?.type_id === EDrugManufacturerTypeId.vendor) ||
                          item?.type_id !== EDrugManufacturerTypeId.vendor,
                  )
                : drugManufacturers?.filter((item) => item?.type_id !== EDrugManufacturerTypeId.vendor);

            const updatedValues = filters.map((value) => ({
                drug_manufacturer_id: value.drug_manufacturer_id ? value.drug_manufacturer_id : value.id,
                percentage: value?.percentage ? value?.percentage : 0,
            }));

            handlePercentages(updatedValues);
        } else {
            const updatedValues = [...newPercentage];
            const filters = FFEX6381
                ? drugManufacturers?.filter(
                      (item) =>
                          (!!item?.vendor_accepted && item?.type_id === EDrugManufacturerTypeId.vendor) ||
                          item?.type_id !== EDrugManufacturerTypeId.vendor,
                  )
                : drugManufacturers?.filter((item) => item?.type_id !== EDrugManufacturerTypeId.vendor);
            filters.forEach((value) => {
                updatedValues[value.drug_manufacturer_id ? value.drug_manufacturer_id : value.id] = {
                    drug_manufacturer_id: value.drug_manufacturer_id ? value.drug_manufacturer_id : value.id,
                    percentage: value?.percentage ? value?.percentage : 0,
                };
            });
            handlePercentages(updatedValues);
        }
    };

    const handlePercentages = (percentagesArray) => {
        setNewPercentage(percentagesArray);
    };

    return (
        <ModalPercentageDistribution
            newDrugmanufacturer={newDrugmanufacturer}
            reloadDistribution={reloadDistribution}
            setReloadDistribution={setReloadDistribution}
            isOrderId={true}
            deletePercentage={deletePercentage}
            open={open}
            handleClose={handleClose}
            drugManufacturers={drugManufacturer}
            ID={orderId}
            percentages={newPercentage}
            mutateUser={mutateUser}
            handlePercentages={handlePercentages}
            tolerance={tolerance}
            setTolerance={setTolerance}
        />
    );
};

export default PercentageDistribution;
