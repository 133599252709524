import { FormInput } from '@commons/EXComponentsLibrary';
import React, { useState, useEffect } from 'react';
import { Form, MaskedInput } from 'rsuite';
import { subscriptionForm } from './formData';
import style from './SubscriptionForm.module.css';
const SubscriptionForm = ({ formError, onChangeMaskedInput, onChangeSingleInput, defaultValue, isValidDate, setIsValidDate, isAmex, setIsAmex }) => {
    

    // timing
    const currentYear = new Date().getFullYear(); // Año actual
    const minYear = currentYear % 100; // Últimos dos dígitos del año actual
    const maxYear = minYear + 10; // Año actual más 10

    // Manejo de ingreso de teclas para restringir caracteres
    const handleKeyPress = (e, onlyLetters, onlyNumbers) => {
        const char = String.fromCharCode(e.which);

        if (onlyLetters && !/^[a-zA-Z\s]+$/.test(char)) {
            e.preventDefault(); // Evita caracteres que no sean letras ni espacios
        }

        if (onlyNumbers && !/^\d+$/.test(char)) {
            e.preventDefault(); // Evita caracteres que no sean números
        }
    };

    const validateDate = (value) => {
        // Si no hay dato, no mostrar mensaje de error
        if (!value) {
            setIsValidDate(true);
            return;
        }

        // Asegurarse de que el formato MM/YY sea correcto
        const regex = /^(0[1-9]|1[0-2])\/(\d{2})$/; // MM/YY
        const match = value.match(regex);

        if (match) {
            const month = parseInt(match[1], 10);
            const year = parseInt(match[2], 10);

            // Validar que el mes esté entre 01 y 12
            if (month < 1 || month > 12) {
                setIsValidDate(false);
            }
            // Validar que el año esté entre el año actual (minYear) y el año actual + 10 (maxYear)
            else if (year < minYear || year > maxYear) {
                setIsValidDate(false);
            } else {
                setIsValidDate(true);
            }
        } else {
            setIsValidDate(false);
        }
    };

    // Detecta si la tarjeta es AMEX (empieza con 34 o 37)
    useEffect(() => {
        if (defaultValue?.credit_card_number) {
            const firstTwoDigits = defaultValue?.credit_card_number.substring(0, 2);
            setIsAmex(firstTwoDigits === '34' || firstTwoDigits === '37');
        }
    }, [defaultValue?.credit_card_number]);

    return (
        <>
            {subscriptionForm.map(({ label, type, name, onlyLetters, onlyNumbers, mask, placeholder, masked }) => {
                if (name === 'credit_card_cvc' && isAmex) {
                    masked = [/\d/, /\d/, /\d/, /\d/]; // Añadimos un dígito más para AMEX
                    placeholder = '####';
                }
                if(name === 'credit_card_number' && isAmex) {
                    masked = [
                        /\d/, /\d/, /\d/, /\d/, ' ',
                        /\d/, /\d/, /\d/, /\d/, ' ',
                        /\d/, /\d/, /\d/, /\d/, ' ',
                        /\d/, /\d/, /\d/
                    ];
                }

                return mask ? (
                    <div className={style['payment-container-maskedinput']}>
                        <Form.Group>
                            <Form.ControlLabel className={style['payment-maskedInput-label']}>
                                {label}
                            </Form.ControlLabel>
                            <MaskedInput
                                mask={masked}
                                showMask={false}
                                onChange={(evt) => {
                                    onChangeMaskedInput(evt, name);
                                    if (name === 'expiration_date') {
                                        validateDate(evt); // Validar la fecha al cambiar
                                    }
                                }}
                                value={defaultValue[name]}
                                name={name}
                                keepCharPositions
                                guide={true}
                                placeholderChar="_"
                                placeholder={placeholder}
                                onKeyPress={(e) => handleKeyPress(e, onlyLetters, onlyNumbers)}
                                size="lg"
                                className={
                                    !isValidDate && name === 'expiration_date'
                                        ? style['payment-input-masked-error']
                                        : style['payment-input-masked']
                                }
                            />
                            {!isValidDate && name === 'expiration_date' && (
                                <small className={style['error-message']}>
                                    La fecha debe tener el formato MM/YY. El mes debe ser entre 01 y 12, y el año entre{' '}
                                    {minYear} y {maxYear}.
                                </small>
                            )}
                        </Form.Group>
                    </div>
                ) : (
                    <FormInput
                        label={label}
                        type={type}
                        name={name}
                        error={formError[name]}
                        onKeyPress={(e) => handleKeyPress(e, onlyLetters, onlyNumbers)}
                        placeholder={placeholder}
                        onChange={(evt) => onChangeSingleInput(evt, name)}
                        value={defaultValue[name]}
                    />
                );
            })}
        </>
    );
};

export default SubscriptionForm;
