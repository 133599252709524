import { EX4698 } from 'config/flags';
import React from 'react';
import { Placeholder } from 'rsuite';

const FakeLoading = ({ imageLoad }) => {
    return (
        !imageLoad && (
            <Placeholder.Paragraph
                graph='image'
                active
                style={{
                    width: 55,
                    height: 'auto',
                    display: 'flex',
                    alignItems: 'center',
                }}
            />
        )
    );
};

export default FakeLoading;
