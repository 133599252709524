// components
import { DetailsCard } from "commons/components/Orders/OrderCards";
// style
import style from "./OrderDetails.module.css";
import { EX4780 } from "config/flags";


const OrderDetails = ({ details }) => {

  return (
    <section className={style["container-out-of-orders"]}>
      <div className={style["container-banner"]} style={{ paddingBottom: EX4780 ? 50 : '' }}>
        <DetailsCard details={details} />
      </div>
    </section>
  );
};

export default OrderDetails;
