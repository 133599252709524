import { t } from 'i18next';
import { useModalVolumeDiscount } from 'context/ModalVolumeDiscount/modalVolumeDiscount';
import { Button, Modal } from 'rsuite';
// style
import style from './ModalVolumeDiscountV2.module.css';

const ModalVolumeDiscountV2 = () => {
    // context
    const {
        isModalOpen,
        closeModal,
        drugManufacturerName,
        bonusProduct,
        isCellChecked,
        newBonusProduct,
    } = useModalVolumeDiscount();

    const closeAlertModal = () => {
        closeModal();
    };

    return (
        <Modal open={isModalOpen} className={style['modal']}>
            <Modal.Header closeButton={false} className={style['modal-header']}>
                <p>
                    <img src="/assets/images/gift.svg" alt="icon gift" width={34} height={36} />
                </p>
                <p className={style['title-modal-drugmanufacturer']}>{drugManufacturerName}</p>
                <p className={style['title-modal']}>{t('bonusByQuantity.labelBonusByQuantity')}</p>
                <Button
                    startIcon={<img src="/assets/images/close.png" alt="close icon" />}
                    onClick={() => closeAlertModal()}
                    style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        width: '24px',
                        height: '24px',
                        position: 'absolute',
                        top: '24px',
                        right: '24px',
                    }}
                />
            </Modal.Header>
            <Modal.Body className={style['body-modal']}>
                <p className={style['subtitle-modal']}>{t('bonusByQuantity.labelAutomaticApplyDiscount')}</p>
            </Modal.Body>
            <Modal.Footer className={style['modal-footer']}>
                {bonusProduct?.length > 0 &&
                    bonusProduct?.map((bonus, idx) => {
                        return (
                            <div key={idx} className={style['discount-container']}>
                                <div className={style['quantity-text-container']}>
                                    <div className={style['background-volume-discount-quantity']}>
                                        <span className={style['volume-discount-quantity-text']}>
                                            {bonus?.paid}+{bonus?.free}
                                        </span>
                                    </div>
                                    <span>
                                        {t('bonusByQuantity.labelSuggestionBuyingGift', {
                                            value: bonus?.paid,
                                            bonus: bonus?.free,
                                        })}
                                    </span>
                                </div>
                                { newBonusProduct?.free && bonus?.id === newBonusProduct?.id && (
                                    <div className={style['apply-container']}>
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="#07AD4B"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M9.54972 15.15L18.0247 6.675C18.2247 6.475 18.4622 6.375 18.7372 6.375C19.0122 6.375 19.2497 6.475 19.4497 6.675C19.6497 6.875 19.7497 7.1125 19.7497 7.3875C19.7497 7.6625 19.6497 7.9 19.4497 8.1L10.2497 17.3C10.0497 17.5 9.81639 17.6 9.54972 17.6C9.28305 17.6 9.04972 17.5 8.84972 17.3L4.54972 13C4.34972 12.8 4.25389 12.5625 4.26222 12.2875C4.27055 12.0125 4.37472 11.775 4.57472 11.575C4.77472 11.375 5.01222 11.275 5.28722 11.275C5.56222 11.275 5.79972 11.375 5.99972 11.575L9.54972 15.15Z"
                                                fill="#07AD4B"
                                            />
                                        </svg>
                                        <span className={style['text-applied']}>aplicada</span>
                                    </div>
                                )}
                            </div>
                        );
                    })}
            </Modal.Footer>
        </Modal>
    );
};

export default ModalVolumeDiscountV2;
