import { t } from 'i18next';
import { Grid, TableCell, TableRow, TextField } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import HelpIcon from '@material-ui/icons/Help';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Role } from 'common/types';
import { ButtonOutline, ButtonSolid } from 'commons/components/Button';
import Menu from 'components/Menu';
import { Table } from 'components/table';
import { EX4201, EX5057 } from 'config/flags';
import { useStatusContext } from 'context/control-state';
import React, { useCallback } from 'react';
import ReactScrollTable from 'react-perfect-scrollbar';
import styled from 'styled-components';
import * as gtag from 'utils/gtag';
import useStyle from './SelectPriority.styles';

function ModalConfirm({ open, setOpen, anchorElement, setCount, userRole }) {
    const PapertStyle = {
        width: 400,
        padding: 0,
        marginTop: 4,
    };
    const classes = useStyle();
    const EX3526 = process.env.NEXT_PUBLIC_EX3526 === 'true';
    const handleReedMore = () => {
        gtag.event({
            action: 'click',
            category: 'efficient-purchase__product-comparison',
            label: 'button__read-more',
            value: 0,
        });
        setCount(2);
        window.open('https://ayuda.extendeal.com/qu%C3%A9-es-y-c%C3%B3mo-configuro-la-tolerancia-de-precios', '_blank');
    };
    const handleOpen = () => {
        gtag.event({
            action: 'click',
            category: 'efficient-purchase__product-comparison',
            label: 'button__understood',
            value: 0,
        });
        setCount(2);
        setOpen(!open);
    };
    return (
        <>
            <Menu
                open={open}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                anchorEl={anchorElement}
                className={classes.modalInfo}
                getContentAnchorEl={null}
                style={{ left: -200, padding: 0, top: 0 }}
                PaperProps={{ style: PapertStyle }}
            >
                <Grid>
                    <div style={{ display: 'flex' }}>
                        <div>
                            <InfoOutlinedIcon className={classes.icon_InfoOutline} />
                        </div>
                        <div>
                            <p className={classes.tolerance_alertText}>
                                {EX5057
                                    ? t(
                                          'efficientPurchasing.priorityAndTolerance.efficientPurchasing_priorityAndToleranceSeven',
                                      )
                                    : 'Prioridad por tolerancia'}
                            </p>
                            <p className={classes.toleranceText}>
                                {userRole !== 2 ? (
                                    <>
                                        {EX3526
                                            ? t(
                                                  'efficientPurchasing.priorityAndTolerance.efficientPurchasing_priorityAndToleranceSix',
                                              )
                                            : 'El sistema seleccionará la droguería prioritaria cuando la diferencia con el precio más bajo no supere el porcentaje de tolerancia.'}{' '}
                                    </>
                                ) : (
                                    <>
                                        {EX3526
                                            ? t(
                                                  'efficientPurchasing.priorityAndTolerance.efficientPurchasing_priorityAndToleranceNineteen',
                                              )
                                            : ' Cuando un usuario con rol de gerente configure la tolerancia, los precios se identificarán con un fondo amarillo. '}
                                    </>
                                )}
                            </p>
                        </div>
                        <div></div>
                    </div>
                    <div className={classes.tolerance_AlertButtonContainer}>
                        <ButtonOutline
                            onClick={handleReedMore}
                            gtagClass="button__read-more-tolerance"
                            text={
                                <span className="button__read-more-tolerance">
                                    {EX5057 ? t('efficientPurchasing.priorityAndTolerance.labelReadMore') : 'Leer más'}
                                </span>
                            }
                            margin="0px 8px 0px"
                            height={40}
                            width={''}
                        />
                        <ButtonSolid
                            gtagClass="button__understood-tolerance"
                            text={
                                <span className="button__understood-tolerance">
                                    {EX5057
                                        ? t('efficientPurchasing.priorityAndTolerance.labelUnderstood')
                                        : 'Entendido'}
                                </span>
                            }
                            height={40}
                            width={''}
                            onClick={handleOpen}
                        />
                    </div>
                </Grid>
            </Menu>
        </>
    );
}

function RenderRowItem({ item, onSelect, selected, handleMouseLeave, allSelectedItems }) {

    const classes = useStyle();
    const handleOnClick = () => {
        handleMouseLeave();
        onSelect(item);
    };

    // Verifica si el item está seleccionado en el combo específico 
    const isSelected = allSelectedItems.includes(item.id);

    return (
        <TableRow
            hover
            role="checkbox"
            tabIndex={-1}
            selected={selected}
            className={classes.tableRow}
            onClick={handleOnClick}
        >
            <TableCell className={classes.tableCell}>
                <span style={{ color: isSelected ? '#0171E6' : '#646464', fontSize: 16 }}>
                    {item.name}
                </span>
                {isSelected && <span className={classes.selectionSpan}>{t('selectedItem')}</span>}
            </TableCell>
        </TableRow>
    );
}
const StyledTextField = styled(({ inputProps, ref, ...props }) => {
    const classes = useStyle();
    return (
        <TextField
            draggable="true"
            {...props}
            autoFocus={false}
            InputProps={{
                classes: { input: classes.input_placeholder },
                autoComplete: false,
                autoFocus: false,
                ref: ref,
                ...inputProps,
            }}
        />
    );
})` & .input {
  cursor:pointer;
  border:none;
  
}
input:read-only {
  cursor:pointer,
}
'&::placeholder': {
  color: "blue"
}
.MuiInput-underline:before {
  display:none
}
.MuiInput-underline:after {
  display:none
}
}}`;
const ToleranceTextField = styled(({ inputProps, ...props }) => {
    return (
        <TextField
            {...props}
            InputLabelProps={{ shrink: true }}
            InputProps={{
                ...inputProps,
            }}
        />
    );
})``;
const CustomInputContainer = (props) => {
    const classes = useStyle(props);
    return (
        <>
            <div className={props.input_position === 1 && props.open ? classes.overlay : ''}>{props.children}</div>
            {props.input_position === 1 && props.open ? <div style={{ height: 74 }}></div> : ''}
        </>
    );
};
const InputPrioridad = ({
    id,
    check,
    setCheck,
    userRole,
    tolerance,
    positions,
    priorities,
    setControl,
    unassigned,
    placeholder,
    selectArray,
    setTolerance,
    input_position,
    handlePriorities,
    handleMouseEnter,
    handleMouseLeave,
    relativePosition,
    setSelectedItems,
    selectedItems = [],
    setAllSelectedItems,
    allSelectedItems = []
}) => {
    
    const classes = useStyle();
    const [open, setOpen] = React.useState(false);
    const [showMsg, setShowMsg] = React.useState(false);
    const [drop, setDrop] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [options, setOptions] = React.useState(selectArray);
    const { status, control, count, setCount } = useStatusContext();
    const [buttonRefState, setButtonRefState] = React.useState(null);
    const [localItem, setLocalItem] = React.useState([{ name: '', id: null }]);
    const [searchValue, setSearchValue] = React.useState(localItem[input_position]?.name);
    const divInputRef = React.useRef(null);
    const EX3526 = process.env.NEXT_PUBLIC_EX3526 === 'true';
    const buttonRef = React.useRef(null);
    const modalRef = React.useRef(null);
    const svgRef = React.useRef(null);
    let regexOnlyNumbersFromCeroToOneHundred = /(^100([.,][0]{0,2})?$)|(^[1-9]{0,1}\d{0,1}$)|(^([1-9]{0,1}\d{1}[,.]\d{0,2})$)/;
    const handleSearch = (event) => {
        const query = event.target.value.toLowerCase();
        setSearchValue(event.target.value);
        const filteredItems = selectArray.filter((item) => item?.name.toLowerCase().includes(query));
        setOptions(filteredItems);
        if (filteredItems.length === 1 && filteredItems[0].name.toLowerCase() === query) {
            setOptions(filteredItems);
            handleOnSelect(filteredItems[0], true);
        }
        if (query === '' || filteredItems.length === 0) handleOptionsBlurInputs();
    };
    const handleOptionsBlurInputs = () => {
        setOptions(selectArray);
        localItem[input_position].name = '';
    };
    const handleModalOption = (isBtnHelp = false) => {
        setButtonRefState(buttonRef.current);
        let showNotification = localStorage.getItem('notification_priority_tolerance');
        if (!isBtnHelp) {
            if (showNotification === null) {
                gtag.event({
                    action: 'click',
                    category: 'efficient-purchase__product-comparison',
                    label: 'icon__help',
                    value: 0,
                });
                localStorage.setItem('notification_priority_tolerance', JSON.stringify(true));
                setOpen(!open);
            }
        } else {
            gtag.event({
                action: 'click',
                category: 'efficient-purchase__product-comparison',
                label: 'icon__help',
                value: 0,
            });
            setOpen(!open);
        }
    };
    React.useEffect(() => {
        const checkIfClickedOutside = (e) => {
            //@ts-ignore
            const validation = [
                'ps__rail-y',
                'ps__thumb-y',
                'MuiGrid-direction-xs-column',
                'scrollbar-container',
                'MuiSvgIcon-root',
            ]?.some((className) => e?.target?.classList?.contains(className));

            if (
                drop &&
                !(modalRef.current.contains(svgRef.current) && validation) &&
                (!modalRef.current.contains(e.target) || !divInputRef.current.contains(e.target))
            ) {
                setDrop(false);
            }
        };
        document.addEventListener('click', checkIfClickedOutside);
        return () => {
            document.removeEventListener('click', checkIfClickedOutside);
        };
    }, [drop]);
    React.useEffect(() => {
        if (control) {
            handleDataSend();
        }
    }, [status]);
    React.useEffect(() => {
        if (localItem[input_position]?.name) {
            handleFunctionRepeat(localItem[input_position]);
        }
    }, [check]);
    const onChange = (e) => {
        if (regexOnlyNumbersFromCeroToOneHundred.test(e.target.value)) {
            let val = e.target.value.replace('.', ',');
            setTolerance(val);
        } else {
            e.target.value = tolerance;
        }
    };
    const onBlur = () => {
        if (tolerance) {
            let val = tolerance.replace(/[,]$/, '');
            setTolerance(val);
        }
    };
    const onClick = () => {
        gtag.event({
            action: 'click',
            category: 'efficient-purchase__product-comparison',
            label: 'input__tolerance',
            value: 0,
        });
    };
    React.useEffect(() => {
        if (input_position === 1) {
            setButtonRefState(buttonRef.current);
            handleModalOption();
        }
        handleItemValue();
    }, []);
    const handleOnSelect = (element, handleEqual = false) => {
        setSelectedItems((prevSelectedItems) => {
            const newSelectedItems = { ...prevSelectedItems };
        
            // Si el elemento ya estaba seleccionado en el combo actual, lo deseleccionamos
            if (newSelectedItems[input_position]?.includes(element.id)) {
                // Elimina el elemento del combo actual
                newSelectedItems[input_position] = newSelectedItems[input_position].filter((id) => id !== element.id);
        
                // Actualiza `allSelectedItems` quitando el elemento deseleccionado
                setAllSelectedItems((prevAllSelectedItems) =>
                    prevAllSelectedItems.filter((id) => id !== element.id)
                );
            } else {
                // Si había un elemento seleccionado previamente en este combo, lo eliminamos de `allSelectedItems`
                if (newSelectedItems[input_position]?.length) {
                    const previousSelectedId = newSelectedItems[input_position][0];
                    setAllSelectedItems((prevAllSelectedItems) =>
                        prevAllSelectedItems.filter((id) => id !== previousSelectedId)
                    );
                }
        
                // Actualizamos para que sólo el nuevo elemento esté seleccionado en este combo
                newSelectedItems[input_position] = [element.id];
        
                // Agrega el nuevo elemento a `allSelectedItems` si no está ya incluido
                setAllSelectedItems((prevAllSelectedItems) =>
                    prevAllSelectedItems.includes(element.id) ? prevAllSelectedItems : [...prevAllSelectedItems, element.id]
                );
            }
        
            return newSelectedItems;
        });
    
        // Resto de la lógica específica para cada elemento
        if (priorities.length !== 0) {
            handlePriorities[element?.id].name = element.name;
        } else {
            handlePriorities[element?.id] = {
                name: element.name,
                id: element.id,
                priority_number: input_position,
            };
        }
    
        handleRepeate(element);
    
        localItem[input_position] = { name: element.name, id: element.id };
        setSearchValue(element.name);
        setDrop(handleEqual ? true : !drop);
    };
    const handleFunctionRepeat = (element) => {
        let repeated = positions.filter(
            (e, index) => e.drug_manufacturer_id === element?.id && index !== input_position - 1,
        );
        if (element?.name === '' || element?.name === undefined) {
            setShowMsg(false);
            positions[input_position - 1] = {
                drug_manufacturer_id: undefined,
                priority_number: undefined,
            };
        } else if (repeated.length !== 0) {
            setMessage(
                !EX4201
                    ? 'La droguería ya está en otro orden'
                    : t('efficientPurchasing.comparative.efficientPurchasing_comparativeTwentyFive'),
            );
            setShowMsg(true);
            positions[input_position - 1] = {
                drug_manufacturer_id: element.id,
                priority_number: input_position,
            };
        } else {
            setShowMsg(false);
            positions[input_position - 1] = {
                drug_manufacturer_id: element.id,
                priority_number: input_position,
            };
        }
    };
    const handleRepeate = (element) => {
        if (element !== undefined) {
            handleFunctionRepeat(element);
            setCheck(!check);
        }
    };
    function handleItemValue() {
        let item = handlePriorities.find((items) => items?.priority_number === input_position);
        localItem[input_position] = { name: item?.name, id: item?.id };
        setSearchValue(item?.name);
    }
    const handleDataSend = () => {
        const isValid = positions.every((item) => item?.drug_manufacturer_id === undefined);
        if (positions.length !== 0 && control && !localItem[input_position]?.name) {
            setMessage('Campo obligatorio');
            setShowMsg(true);
            setControl(!control);
        } else if (!isValid) {
            handleRepeate(localItem[input_position]);
        } else {
            setShowMsg(false);
        }
    };
    const handleSearchValue = useCallback(() => {
        if (searchValue) {
            return searchValue?.length > 30 ? searchValue?.substring(0, 30) + '...' : searchValue;
        } else {
            return null;
        }
    }, [searchValue]);
    return (
        <>
            <div className={classes.container}>
                <CustomInputContainer input_position={input_position} open={open}>
                    <div
                        ref={buttonRef}
                        className={input_position === 1 ? classes.rowInput_firstIndex : classes.rowInput}
                    >
                        <p className={classes.container_inputPosition}>{input_position}</p>
                        <div ref={divInputRef} className={classes.container_inputText}>
                            <div style={{ position: 'relative' }}>
                                {unassigned ? (
                                    <>
                                        <p
                                            className={classes.styleTooltip}
                                            style={{
                                                display:
                                                    localItem[input_position]?.name ||
                                                    localItem[input_position]?.name === ''
                                                        ? 'none'
                                                        : 'absolute',
                                            }}
                                        >
                                            {EX3526
                                                ? t(
                                                      'efficientPurchasing.priorityAndTolerance.efficientPurchasing_priorityAndToleranceTwentySix',
                                                  )
                                                : 'Asigna un orden de prioridad a las nuevas droguerías.'}
                                        </p>
                                        <StyledTextField
                                            id={id}
                                            placeholder={placeholder}
                                            value={handleSearchValue()}
                                            onChange={handleSearch}
                                            className={
                                                userRole !== 2
                                                    ? showMsg
                                                        ? classes.alert
                                                        : classes.normal
                                                    : showMsg
                                                    ? classes.alert_buyer
                                                    : classes.normal_buyer
                                            }
                                            onFocus={() => setDrop(true)}
                                            type="text"
                                            inputProps={{
                                                ref: modalRef,
                                                endAdornment: drop ? (
                                                    <KeyboardArrowUpIcon
                                                        ref={svgRef}
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => setDrop(false)}
                                                    />
                                                ) : (
                                                    <KeyboardArrowDownIcon
                                                        ref={svgRef}
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() => setDrop(true)}
                                                    />
                                                ),
                                            }}
                                        />
                                    </>
                                ) : (
                                    <StyledTextField
                                        id={id}
                                        placeholder={placeholder}
                                        className={
                                            userRole !== Role.Buyer
                                                ? showMsg
                                                    ? classes.alert
                                                    : classes.normal
                                                : showMsg
                                                ? classes.alert_buyer
                                                : classes.normal_buyer
                                        }
                                        onFocus={() => setDrop(true)}
                                        value={handleSearchValue()}
                                        onChange={handleSearch}
                                        type="text"
                                        inputProps={{
                                            ref: modalRef,
                                            endAdornment: drop ? (
                                                <KeyboardArrowUpIcon
                                                    ref={svgRef}
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => setDrop(false)}
                                                />
                                            ) : (
                                                <KeyboardArrowDownIcon
                                                    ref={svgRef}
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => setDrop(true)}
                                                />
                                            ),
                                        }}
                                    />
                                )}
                                {drop && (
                                    <div
                                        style={{
                                            position: 'absolute',
                                            bottom: relativePosition >= 158 && input_position >= 3 ? 44 : '',
                                            height: options?.length >= 5 ? '129px' : 'auto',
                                            paddingBottom:
                                                relativePosition >= 158 && input_position >= 3 ? '5px' : '0px',
                                        }}
                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={handleMouseLeave}
                                    >
                                        <ReactScrollTable className={classes.scroll_table}>
                                            <Table
                                                columns={[
                                                    {
                                                        key: 'img',
                                                    },
                                                ]}
                                                data={options}
                                                noResultsText="No se encontraron resultados"
                                                renderRow={(item) => (
                                                    <RenderRowItem
                                                        item={item}
                                                        onSelect={handleOnSelect}
                                                        handleMouseLeave={handleMouseLeave}
                                                        selected={item?.name === localItem[input_position]?.name}
                                                        allSelectedItems={allSelectedItems}
                                                    />
                                                )}
                                                loading={false}
                                            />
                                        </ReactScrollTable>
                                    </div>
                                )}
                            </div>
                            {input_position === 1 && userRole !== Role.Buyer && (
                                <div style={{ width: 141, marginLeft: 8 }} className="input__tolerance">
                                    <ToleranceTextField
                                        id="outlined-basic"
                                        size="small"
                                        fullWidth
                                        label={
                                            EX5057
                                                ? t('prioritiesAndTolerance.labelOptionalTolerance')
                                                : 'Tolerancia (opcional)'
                                        }
                                        className={`input__tolerance ${classes.tolerance}`}
                                        style={{ background: 'white' }}
                                        value={tolerance}
                                        placeholder="0,00"
                                        variant="outlined"
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        onClick={onClick}
                                        type="text"
                                        inputProps={{
                                            classes: { input: 'input__tolerance' },
                                            endAdornment: (
                                                <img
                                                    src="/assets/images/percent.svg"
                                                    width={20}
                                                    height={20}
                                                    className="input__tolerance"
                                                />
                                            ),
                                        }}
                                    />
                                </div>
                            )}
                            {input_position === 1 && (
                                <div className={classes.container_helpIcon}>
                                    <IconButton aria-label="info-modal" onClick={() => handleModalOption(true)}>
                                        <HelpIcon
                                            className="icon__help-tolerance"
                                            style={{
                                                color: '#A16205',
                                                height: 23,
                                                cursor: 'pointer',
                                                width: 23,
                                            }}
                                        />
                                    </IconButton>
                                </div>
                            )}
                        </div>
                    </div>
                    <p className={showMsg ? classes.message : classes.hide}>{message}</p>
                </CustomInputContainer>
            </div>
            <ModalConfirm
                open={open}
                setOpen={setOpen}
                anchorElement={buttonRefState}
                userRole={userRole}
                setCount={setCount}
            />
        </>
    );
};
export default InputPrioridad;