import { t } from 'i18next';
// components
import { Tooltip, Whisper } from 'rsuite';
// hooks
import { useTooltipError } from './hooks';
// styles
import styles from './TooltipError.module.css';

const TooltipError = ({ typeError }) => {
    // hook logic
    const { alertIconRef, textError, textTootip, color, icon } = useTooltipError({ typeError });

    return (
        <div className={styles['container']}>
            <Whisper
                ref={alertIconRef}
                trigger={'hover'}
                placement={'bottomStart'}
                controlId={`control-id-${textTootip}`}
                speaker={
                    <Tooltip
                        className={styles['root']}
                        style={{
                            marginTop: 4,
                            textAlign: 'left',
                        }}
                    >
                        {textTootip}
                    </Tooltip>
                }
            >
                <span
                    className={styles['alert-icon']}
                    style={{
                        color: color,
                    }}
                    ref={alertIconRef}
                >
                    {icon}
                </span>
            </Whisper>
            <p
                className={styles['error-text']}
                style={{
                    color: color,
                }}
            >
                {t(textError)}
            </p>
        </div>
    );
};

export default TooltipError;
