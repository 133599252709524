import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(({ palette }) => ({
  text: {
    fontSize: "14px",
    color: palette.neutral[800],
  },
}));

export default useStyles;
