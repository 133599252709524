export const applyToleranceAccordingToService = (
    hasServicePriority,
    applyToleranceInDistribution,
    applyToleranceInPriority,
    checked,
    isSuggested,
    isLowest,
): boolean => {
    if (!hasServicePriority) {
        return applyToleranceInDistribution && checked && !!isSuggested && !isLowest;
    } else {
        return applyToleranceInPriority && checked && !!isSuggested && !isLowest;
    }
};
