import { useEffect, useState } from 'react';
import { t } from 'i18next';

export const usePasswordCondition = ({ password, callbackValidation, repeatPassword }) => {
    const [validLength, setValidLength] = useState<boolean>(false);
    const [hasUppercase, setHasUppercase] = useState<boolean>(false);
    const [hasNumber, setHasNumber] = useState<boolean>(false);
    const [matching, setHasMatching] = useState<boolean>(false);

    const passwordCondition = [
        { name: t('login.login13'), control: validLength },
        { name: t('login.login14'), control: hasUppercase },
        { name: t('login.login15'), control: hasNumber },
        { name: t('login.login47'), control: matching },
    ];

    const checkPassword = (password: string, callbackValidation: (v: boolean) => void, repeatPassword: string) => {
        const isValidPassowrd = password?.length >= 8 && /[A-Z]/.test(password) && /\d/.test(password);
        setValidLength(password?.length >= 8);
        setHasUppercase(/[A-Z]/.test(password));
        setHasNumber(/\d/.test(password));
        let arePasswordsEqual;
        if (typeof password === 'string' && typeof repeatPassword === 'string') {
            // las contraseñas deben ser iguales
            arePasswordsEqual = password.trim() === repeatPassword.trim() && repeatPassword.trim()?.length !== 0;
            setHasMatching(arePasswordsEqual);
        }
        callbackValidation && callbackValidation(isValidPassowrd && arePasswordsEqual);
    };

    useEffect(() => {
        checkPassword(password, callbackValidation, repeatPassword);
    }, [password, repeatPassword]);

    const iconPassword = (control) => {
        return !!control;
    };

    return {
        passwordCondition,
        iconPassword,
    };
};
