import React, { FC } from 'react';
// components
import { WelcomeText } from './WelcomeText';
// utils && hooks
import useChangeLanguage from 'utils/hooks/use-change-language';
import { COUNTRY } from 'utils/country';
// styles
import styles from './Layout.module.css';
import { useRouter } from 'next/router';

const Layout: FC = ({ children }) => {
    // ---------------------- translation ----------------
    const userLocale = typeof window !== 'undefined' ? navigator?.language : null;

    switch (userLocale) {
        case 'es-AR':
            useChangeLanguage(COUNTRY.AR);
            break;
        case 'en-US':
            useChangeLanguage(COUNTRY.US);
            break;
        case 'pt-BR':
            useChangeLanguage(COUNTRY.BR);
            break;
        case 'es-MX':
            useChangeLanguage(COUNTRY.MX);
            break;
        case 'es-CO':
            useChangeLanguage(COUNTRY.CO);
            break;
        case 'es-ES':
            useChangeLanguage(COUNTRY.ES);
            break;
        default:
            useChangeLanguage('es');
            break;
    }

    const { pathname } = useRouter();

    return (
        <div className={`${styles['container']} ${pathname?.includes("activateUser") ? styles['activateUser'] : ''}`}>
            <WelcomeText />
            {children}
        </div>
    );
};

export default Layout;
