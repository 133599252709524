import { EX4201, EX5057 } from 'config/flags';
import { useTranslation } from 'react-i18next';
import { useUser } from 'utils/hooks';
import useChangeLanguage from 'utils/hooks/use-change-language';

export const btnAtributtes = () => {
  const { t } = useTranslation();
  const { user } = useUser({});
  const userLocaleCountryCode = user?.client?.country_alfa_2;
  if (EX4201) useChangeLanguage(userLocaleCountryCode);
  const labelOrdersByDrugstore = !EX4201
    ? 'Órdenes de compra por droguería'
    : t('myOrders.toggleToOrdersByDrugstore');

  const labelMyOrders = !EX5057 ? 'Mis pedidos' : t('myOrders.toggleToMyOrders');
  return [
    {
      label: labelMyOrders,
      value: 1,
      tag: 'myOrders',
      gtagClass: 'button__my-orders',
    },
    {
      label: labelOrdersByDrugstore,
      value: 2,
      tag: 'ordersByDrugmanufacturer',
      gtagClass: 'button__purchase-orders',
    },
  ];
};
