interface shouldDisplayBadgeProps {
    skeleton: boolean;
    product: any;
    hasDrugManufacturerError: boolean;
}

export const shouldDisplayBadge = ({
    skeleton,
    product,
    hasDrugManufacturerError,
}: shouldDisplayBadgeProps): boolean => {
    return !skeleton && !!product && !hasDrugManufacturerError;
};
