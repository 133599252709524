// interface
import { IProfile } from './Profile.interface';

// utils
import { obtainInitialFirstLetters, obtainRoleOfUser } from '../utils';

// rsuite
import { Col, Avatar } from 'rsuite';
// style
import style from './Profile.module.css';

const Profile = ({aliases,roleType,initials}:IProfile) => {
  return (
    
      <div className={style['container-avatar']}>
        <div className={style['container-name-role']}>
          <p className={style['text-name']}>{aliases}</p>
          <span className={style['text-role']}>{roleType}</span>
        </div>
        <Avatar size='sm' circle className={style['avatar-index']}>
          { initials }
        </Avatar>
      </div>
    
  );
};

export default Profile;
