import style from './ChipDelivery.module.css';
import { Tooltip, Whisper } from 'rsuite';
import { IChipDelivery } from './ChipDelivery.interface';
import { useMemo } from 'react';
import { EX5907 } from 'config/flags';
const Icon = ({ color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
            <mask id="mask0_9634_6242" maskUnits="userSpaceOnUse" x="0" y="0" width="10" height="10">
                <rect width="10" height="10" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_9634_6242)">
                <path
                    d="M2.91667 9.16683L4.58333 6.04183L1.25 5.62516L6.25 0.833496H7.08333L5.41667 3.9585L8.75 4.37516L3.75 9.16683H2.91667Z"
                    fill={color}
                />
            </g>
        </svg>
    );
};
const ChipDelivery = ({ delivery, isEnabled }: IChipDelivery) => {
    const dependencies = EX5907
        ? [delivery?.mark_color, delivery?.alert, isEnabled]
        : [delivery?.mark_color, delivery?.alert];
    const chipStyle: { className: string; iconColor: '#E22E2E' | '#07AD4B' | '#ACAEC4' | null } = useMemo(() => {
        if (!isEnabled && EX5907) {
            return { className: '-disabled', iconColor: delivery.mark_color === 'blue' ? null : '#ACAEC4' };
        }
        if (delivery?.alert) {
            if (delivery.mark_color === 'red') {
                return { className: '-alert', iconColor: '#E22E2E' };
            } else {
                return { className: '-green', iconColor: '#07AD4B' };
            }
        }
        return {
            className: '-blue',
            iconColor: null,
        };
    }, [dependencies]);
    return (
        <Whisper
            trigger="hover"
            placement={'bottom'}
            controlId={`control-id-bottom-chip`}
            disabled={!delivery?.hover}
            speaker={<Tooltip>{delivery?.hover}</Tooltip>}
        >
            <div className={`${style['chip-delivery']} ${style[`chip-delivery${chipStyle.className}`]}`}>
                {chipStyle.iconColor && <Icon color={chipStyle.iconColor} />}
                {delivery?.tag}
            </div>
        </Whisper>
    );
};
export default ChipDelivery;