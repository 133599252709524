import React from 'react';
import Button from 'components/Button/Button';
import { useStyles } from './ActionsButtons.styles';
import { useTheme } from '@material-ui/core';
import { useRouter } from 'next/router';
import { ButtonGhost, ButtonOutline, ButtonSolid } from 'commons/components/Button';
import { EX5057 } from 'config/flags';
import useChangeLanguage from 'utils/hooks/use-change-language';
import { useUser } from 'utils/hooks';
import { useTranslation } from 'react-i18next';

const ActionsButtons = ({
    sumTotal,
    totalError,
    disableSave,
    deletePercentage,
    handleButtonClick,
    handleCloseModal,
    handleDeletePercentage,
}) => {
    //translation by Country
    const { t } = useTranslation();
    const { userLocaleCountryCode } = useUser({});
    if (EX5057) useChangeLanguage(userLocaleCountryCode);

    const classes = useStyles();
    const { pathname } = useRouter();

    return (
        <div className={classes.totalRowContainer}>
            <div className={classes.totalContainer}>
                <div className={classes.inputTotal}>
                    Total:
                    {totalError ? <span className={classes.spanError}>{totalError}</span> : ' '}
                </div>
                <div className={classes.totalSumContainer}>
                    <p className={classes.inputSumTotal} style={{ color: totalError ? '#FF3030' : '' }}>
                        {sumTotal}{' '}
                    </p>
                    {totalError ? (
                        <img src="/assets/images/percentError.svg" width={13.33} height={13.33} />
                    ) : (
                        <img src="/assets/images/percent.svg" width={13.33} height={13.33} />
                    )}
                </div>
            </div>

            <div className={classes.containerButtonAction}>
                <ButtonGhost
                    onClick={handleDeletePercentage}
                    disabled={deletePercentage}
                    text={
                        EX5057
                            ? t(
                                  'efficientPurchasing.porcentualDistribution.efficientPurchasing_porcentualDistributionEight',
                              )
                            : 'Eliminar distribución'
                    }
                    height={40}
                    width={''}
                />
                <div>
                    <ButtonOutline
                        onClick={handleCloseModal}
                        height={40}
                        margin="0px 8px 0px"
                        width={''}
                        text={t('credentialplaceholder_three') || "Cancelar"}
                    />

                    <ButtonSolid
                        height={40}
                        width={''}
                        onClick={handleButtonClick}
                        disabled={disableSave}
                        gtagClass={
                            pathname.split('/').at(-1) === 'product-comparison'
                                ? 'button__percent-config-save-1'
                                : 'button__percent-config-save-2'
                        }
                        text={
                            <span
                                className={
                                    pathname.split('/').at(-1) === 'product-comparison'
                                        ? 'button__percent-config-save-1'
                                        : 'button__percent-config-save-2'
                                }
                            >
                                {EX5057
                                    ? t(
                                          'efficientPurchasing.priorityAndTolerance.efficientPurchasing_priorityAndToleranceFifteen',
                                      )
                                    : 'Guardar'}
                            </span>
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default ActionsButtons;
