import { EX5225 } from 'config/flags';
import { useTranslation } from 'react-i18next';

export const traslateToSpanishPossibleErrors = (errors) => {
    // translate by country
    const { t } = useTranslation();

    return errors?.errors?.map((item) => {
        let spanishError;
        let labelError;
        switch (item) {
            case 'INVALID_LINE_FORMAT': {
                EX5225
                    ? (labelError = t('efficientPurchasing.fileUpload.errorUnknowFormat'))
                    : (spanishError = 'Formato de producto desconocido');
                break;
            }
            case 'DUPLICATE_BARCODE': {
                EX5225
                    ? (labelError = `${t('efficientPurchasing.fileUpload.efficientPurchasing_fileUploadEleven')}: ${t(
                          'efficientPurchasing.fileUpload.efficientPurchasing_fileUploadLabelDuplicate',
                      )}`)
                    : (spanishError =
                          'EAN duplicados: Vamos a mostrar solo uno en la comparativa.(Verifica que la cantidad seleccionada  sea la correcta)');
                break;
            }
            case 'ZERO_QUANTITY': {
                EX5225
                    ? (labelError = t('efficientPurchasing.fileUpload.efficientPurchasing_fileUploadThirteen'))
                    : (spanishError = 'Productos con cantidad cero.');
                break;
            }
            case 'ZERO_BARCODE': {
                EX5225
                    ? (labelError = t('efficientPurchasing.fileUpload.efficientPurchasing_fileUploadTwelve'))
                    : (spanishError = 'EAN en cero: 0000000000000');
                break;
            }
            case 'QUANTITY_TOO_HIGH': {
                EX5225
                    ? (labelError = t('efficientPurchasing.fileUpload.efficientPurchasing_fileUploadFourteen'))
                    : (spanishError = 'Hay cantidades con más de 3 dígitos');
                break;
            }
            case 'EXCEEDED_PRODUCTS': {
                labelError = t('efficientPurchasing.fileUpload.efficientPurchasing_fileUploadSeventeen', { productsExceeded : errors?.exceeded_lines})
                break;
            }
            case 'MISSING_PROPERTY_CODE':
            case 'INVALID_CODE_LENGTH':
            case 'MISSING_PROPERTY_EXTERNAL_CODE':
            case 'INVALID_EXTERNAL_CODE_LENGTH': {
                labelError = t('efficientPurchasing.fileUpload.errorUnknowFormat');
                break;
            }
            default:
                break;
        }

        return EX5225 ? labelError : spanishError;
    });
};
