// component
import { RsuiteTooltip } from '@commons/components/RsuiteTooltip';
// rsuite
import { Form } from 'rsuite';
// style
import style from './FieldControlLabel.module.css';
// interface
import { IFieldControlLabel } from './FieldControlLabel.interface';

const FieldControlLabel = ({ field, requiredSteps, fieldKey }: IFieldControlLabel) => {
    const SPECIALITY = 'specialty';
    return (
        <Form.ControlLabel className={field.infoIcon ? style['label-with-icon'] : ''}>
            {
                <span className={style['label-form']}>
                    {field.label}{' '}
                    {requiredSteps.includes(fieldKey) && <span className={style['required-asterisk']}>*</span>}
                </span>
            }

            {field.info && <span className={style['info-label']}>{field.info}</span>}
            {field.infoIcon && (
                <RsuiteTooltip
                    text={
                        field.name === SPECIALITY
                            ? 'Podés ingresar distintas especialidades separándolas por coma.'
                            : 'Depende del país, puede ser: CUIT, RFC, NIF, CIF.'
                    }
                    placement="topEnd"
                    trigger="hover"
                    width="193px"
                >
                    <span>
                        <img src="/assets/images/info2.svg" />
                    </span>
                </RsuiteTooltip>
            )}
        </Form.ControlLabel>
    );
};

export default FieldControlLabel;
