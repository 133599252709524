//UTILS
import * as gtag from 'utils/gtag';

interface handlerRemoveProductProps {
    index: number;
    barcode: string;
    removeEvent: (item: any) => void;
    orderProductId?: number;
}

/* #region delete product */
export const handlerRemoveProduct = ({ index, barcode, removeEvent, orderProductId = null }: handlerRemoveProductProps): void => {
    gtag.event({
        action: 'click',
        category: 'efficient-purchase__product-comparison',
        label: 'button__delete-product',
        value: +barcode,
    });
    removeEvent({ index, barcode: barcode, clicked: true, orderProductId });
};
/* #endregion */
