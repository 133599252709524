import React from 'react';
import { ButtonToolbar } from 'rsuite';
import style from './WarningCardContent.module.css';
import { ExButton } from '@commons/EXComponentsLibrary';
import { Appearance } from '@commons/EXComponentsLibrary/ExButton/interfaces/Button.interface';
import Image from 'next/image';
import { Close } from '@material-ui/icons';
import FormattedCurrency from './FormattedCurrency/FormattedCurrency';

type ButtonProps = {
    label: string;
    action: () => void;
    appearance: Appearance;
    isDisabled?: boolean;
};

type Props = {
    title: string;
    subtitle: string;
    credit: number;
    iconSrc: string;
    buttons: ButtonProps[];
    showTip: boolean;
    tipMessage: {
        label: string;
        message: string;
    };
    onClose: () => void;
};

const WarningCardContent = ({ title, subtitle, credit, iconSrc, buttons, showTip, tipMessage, onClose }: Props) => {
    return (
        <div className={style['content-wrapper']}>
            <p className={style['title-wrapper']} style={{ width: '100%' }}>
                <div className={style['title']}>
                    <img src={iconSrc} />
                    {title} <FormattedCurrency value={credit} fontSize={18} />
                </div>
                <div className={style['close']} onClick={onClose}>
                    <Close />
                </div>
            </p>
            <p className={style['subtitle']}>{subtitle}</p>
            {showTip && (
                <>
                    <div className={style['tip-wrapper']}>
                        <Image src="/assets/images/tip.svg" alt="tip image" width={20} height={20} />
                        <div>
                            <span className={style['tip-label']}>{tipMessage.label} </span>
                            <span className={style['tip-message']}>{tipMessage.message}</span>
                        </div>
                    </div>
                </>
            )}
            <div className={`button__update-credentials-update-2 ${style['container-buttons']}`}>
                <ButtonToolbar className={`button__update-credentials-update-2 ${style['button-toolbar-content']}`}>
                    {buttons.map((button) => (
                        <ExButton disabled={button.isDisabled} onClick={button.action} appearance={button.appearance}>
                            {button.label}
                        </ExButton>
                    ))}
                </ButtonToolbar>
            </div>
        </div>
    );
};

export default WarningCardContent;
