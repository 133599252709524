import { FirstLogin } from './FirstLogin';
import { PresentationCard } from './PresentationCard';
import { StepsCard } from './StepsCard';

const Tooltip = ({ continuous, index, step, backProps, closeProps, primaryProps, tooltipProps }) => {
    switch (true) {
        case index === 5:
            return <FirstLogin index={index} step={step} />;
        case index === 0:
            return (
                <PresentationCard
                    continuous={continuous}
                    index={index}
                    step={step}
                    backProps={backProps}
                    closeProps={closeProps}
                    primaryProps={primaryProps}
                    tooltipProps={tooltipProps}
                />
            );
        default:
            return (
                <StepsCard
                    continuous={continuous}
                    index={index}
                    step={step}
                    backProps={backProps}
                    closeProps={closeProps}
                    primaryProps={primaryProps}
                    tooltipProps={tooltipProps}
                />
            );
    }
};

export default Tooltip;
