export const addEllipsis = (text) => {
    let hasEllipsis = false;
    if (text.length >= 17) {
        hasEllipsis = true;
    }
    const texts = <span>{text}</span>;
    return { texts, hasEllipsis };
};

export const truncateTextWithLimit = (text: string, characterLimit: number) => {
    return text.length > characterLimit ? text.slice(0, characterLimit - 1) + '...' : text;
};