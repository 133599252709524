import { useEffect, useRef, useState } from 'react';
// interfaces
import { RsuiteTooltip } from '@commons/components/RsuiteTooltip';
import { IDescriptionItemProps } from './DescriptionItem.interface';
// styles
import styles from './DescriptionItem.module.css';

const DescriptionItem = ({ item }: IDescriptionItemProps): JSX.Element => {
    const nameRef: any = useRef(null);
    const specialtyRef: any = useRef(null);
    const descriptionRef: any = useRef(null);
    const containerRef: any = useRef(null);
    const containerSpecialtyRef: any = useRef(null);
    const parentRef: any = useRef(null);
    const descriptionRefTooltip: any = useRef(null);
    const [isTooltipDisabled, setIsTooltipDisabled] = useState<boolean>(false);
    const [isTooltipSpecialtyDisabled, setIsTooltipSpecialtyDisabled] = useState<boolean>(false);
    const [isDescriptionTooltipDisabled, setIsDescriptiontTooltipDisabled] = useState<boolean>(false);

    /* #region listener ellipsis in filename */
    useEffect(() => {
        const checkOverflow = () => {
            if (nameRef?.current && containerRef?.current) {
                setIsTooltipDisabled(containerRef?.current?.offsetWidth === nameRef?.current.offsetWidth);
            }
        };

        // Timeout to ensure the DOM is fully updated
        const timeoutId = setTimeout(checkOverflow, 100);

        window?.addEventListener('resize', checkOverflow);
        return () => {
            clearTimeout(timeoutId);
            window?.removeEventListener('resize', checkOverflow);
        };
    }, [item?.name]);

    useEffect(() => {
        const checkOverflowSpecialty = () => {
            if (specialtyRef?.current && containerSpecialtyRef?.current) {
                setIsTooltipSpecialtyDisabled(
                    containerSpecialtyRef?.current?.offsetWidth === specialtyRef?.current.offsetWidth,
                );
            }
        };

        const timeoutId = setTimeout(checkOverflowSpecialty, 100);

        window?.addEventListener('resize', checkOverflowSpecialty);
        return () => {
            clearTimeout(timeoutId);
            window?.removeEventListener('resize', checkOverflowSpecialty);
        };
    }, [item?.specialty]);

    /* #endregion */

    /* #region listener ellipsis in filename DESCRIPTION */
    // useEffect(() => {
    //     const checkOverflow = () => {
    //         if (descriptionRef?.current && containerRef?.current) {
    //             setIsDescriptiontTooltipDisabled(
    //                 containerRef?.current?.offsetWidth === descriptionRef?.current.offsetWidth,
    //             );
    //         }
    //     };

    //     // Timeout to ensure the DOM is fully updated
    //     const timeoutId = setTimeout(checkOverflow, 100);

    //     window?.addEventListener('resize', checkOverflow);
    //     return () => {
    //         clearTimeout(timeoutId);
    //         window?.removeEventListener('resize', checkOverflow);
    //     };
    // }, [item?.url]);
    /* #endregion */

    /* #region listener hidden tooltip when scrolling */
    useEffect(() => {
        const checkScroll = () => {
            if (parentRef?.current) {
                parentRef.current.close();
            }
            if (descriptionRefTooltip?.current) {
                descriptionRefTooltip.current.close();
            }
        };

        const contentElement = document?.getElementById('content-id');
        if (contentElement) {
            contentElement?.addEventListener('scroll', checkScroll);
            return () => {
                contentElement?.removeEventListener('scroll', checkScroll);
            };
        }
    }, [parentRef]);
    /* #endregion */

    return (
        <div className={styles['left']}>
            <img
                src={`/assets/images/${item?.type_id === 1 ? 'not_verified' : 'verified'}.svg`}
                alt="icon verified or not verified"
                width={24}
                height={24}
                draggable={false}
            />
            <div ref={containerRef}>
                <RsuiteTooltip
                    parentRef={parentRef}
                    placement="top"
                    trigger="hover"
                    text={item?.name}
                    disabled={!isTooltipDisabled}
                >
                    <div className={styles['parent-name']}>
                        <p className={styles['name']} ref={nameRef}>
                            {item?.name}
                        </p>
                    </div>
                </RsuiteTooltip>
                <div ref={containerSpecialtyRef}>
                    <RsuiteTooltip
                        placement="bottom"
                        trigger="hover"
                        text={item?.specialty}
                        disabled={!isTooltipSpecialtyDisabled}
                    >
                        <div className={styles['specialty-parent']}>
                            <p className={styles['specialty']} ref={specialtyRef}>
                                {item?.specialty}
                            </p>
                        </div>
                    </RsuiteTooltip>
                </div>

                {/* <RsuiteTooltip
                    parentRef={descriptionRefTooltip}
                    placement="top"
                    trigger="hover"
                    text={item?.url}
                    disabled={!isDescriptionTooltipDisabled}
                >
                    <div className={styles['parent-name']}>
                        <p className={styles['description']} ref={descriptionRef}>
                            {item?.url}
                        </p>
                    </div>
                </RsuiteTooltip> */}
            </div>
        </div>
    );
};

export default DescriptionItem;
