import { EX3762, EX5225 } from 'config/flags';
import { DrugManufacturersId } from 'utils/interfaces';

export const textInChip = (product, productPVP, drugManufacturerId, t): string => {
    if (drugManufacturerId === DrugManufacturersId.SuizaTucuman && product?.discount?.multiple && EX3762) {
        return textSuizaTucuman(product);
    } else {
        if (!!productPVP) {
            if (product?.discount?.min_quantity && !product?.discount?.max_quantity) {
                // ONLY MIN
                return EX5225
                    ? `OFF PVP ${t('efficientPurchasing.comparative.discountLabelFrom')} ${
                          product?.discount?.min_quantity
                      } u.`
                    : `OFF PVP desde ${product?.discount?.min_quantity} u.`;
            } else if (product?.discount?.min_quantity && product?.discount?.max_quantity) {
                // MIN AND MAX
                return EX5225
                    ? `OFF PVP ${t('efficientPurchasing.comparative.discountLabelFrom')} ${
                          product?.discount?.min_quantity
                      } u. ${t('efficientPurchasing.comparative.discountLabelTo')} ${
                          product?.discount?.max_quantity
                      } u.`
                    : `OFF PVP desde ${product?.discount?.min_quantity} u. hasta ${product?.discount?.max_quantity} u.`;
            } else {
                return 'OFF PVP';
            }
        } else if (product?.discount?.min_quantity && !product?.discount?.max_quantity) {
            // ONLY MIN
            return EX5225
                ? `OFF ${t('efficientPurchasing.comparative.discountLabelFrom')} ${product?.discount?.min_quantity} u.`
                : `OFF desde ${product?.discount?.min_quantity} u.`;
        } else if (product?.discount?.min_quantity && product?.discount?.max_quantity) {
            // MIN AND MAX
            return EX5225
                ? `OFF ${t('efficientPurchasing.comparative.discountLabelFrom')} ${
                      product?.discount?.min_quantity
                  } u. ${t('efficientPurchasing.comparative.discountLabelTo')} ${product?.discount?.max_quantity} u.`
                : `OFF desde ${product?.discount?.min_quantity} u. hasta ${product?.discount?.max_quantity} u.`;
        } else {
            return 'OFF';
        }
    }
};

export const textHasDiscount = (ITS_APPLY, IS_SUIZA_TUCUMAN, product, drugManufacturerId, t): string => {
    return ITS_APPLY
        ? `${product?.discount?.discountPercentage} % ${
              IS_SUIZA_TUCUMAN ? textSuizaTucuman(product) : textInChip(product, null, drugManufacturerId, t)
          }`
        : 'Ver descuentos';
};

export const textSuizaTucuman = (product): string => {
    return `en múltiplos de ${product?.discount?.multiple} unidades`;
};
