import { memo } from 'react';
import Image from 'next/image';
import { t } from 'i18next';
// components
import { ExButton } from '@commons/EXComponentsLibrary';
import { HeaderMobile } from '@commons/modules/Auth/HeaderMobile';
// utils && hooks
import { useMailTo } from '@commons/modules/Auth/hooks';
// interfaces
import { IExpiredTokenProps } from './ExpiredTokenMobile.interface';
// styles
import styles from './ExpiredTokenMobile.module.css';

const ExpiredTokenMobile = ({ loading, redirectToLogin }: IExpiredTokenProps): JSX.Element => {
    const { showMailTo } = useMailTo();
    const [firstPart, secondPart] = t('login.no_token_text')?.split(':');
    return (
        <>
            <HeaderMobile />
            <div className={styles['title-form']}>
                <div>
                    <span>
                        <Image
                            src={'/assets/images/error.svg'}
                            alt="extendeal logo"
                            width={24}
                            height={24}
                            draggable={false}
                        />
                    </span>
                    <span>{t('login.login31')}</span>
                </div>
            </div>
            <div className={styles['subtitle']}>
                <span>{`${firstPart}:`}</span>
                <br />
                <span className={styles['welcome-text']}>{secondPart}</span>
            </div>
            <ExButton
                type="submit"
                status="initial"
                withArrow={true}
                fullWidth
                size="lg"
                loading={loading}
                onClick={redirectToLogin}
            >
                {t('login.login4')}
            </ExButton>
            {/* </Panel> */}
            <div className={`link__back-to-login-1 ${styles['contact-us']}`} onClick={showMailTo}>
                {t('login.login21')}
            </div>
        </>
    );
};

export default memo(ExpiredTokenMobile);
