import { t } from 'i18next';
// configs
import { EX4204, EX4780, EX4922, EX5036, EX5057, QR_AFIP_KEY } from 'config/flags';
// components
import { Button, Footer } from 'rsuite';
import ArrowUpLineIcon from '@rsuite/icons/ArrowUpLine';
import ArrowDownLineIcon from '@rsuite/icons/ArrowDownLine';
// utils && hooks
import { useUser } from 'utils/hooks';
import { Country } from 'common/types';
import { COUNTRY } from 'utils/country';
// styles
import style from './Footer.module.css';
import { ADDRESS } from './enum/address';
import { COPYRIGHT } from './enum/copyrigth';
import { useStatusContext } from 'context/control-state';

const FooterRsuite = ({
    expandedFooter,
    setExpandedFooter,
    setOpenModalTerms,
    setOpenModalPrivacyPolicy,
    linkActive,
    setLinkActive,
    isValidationFooterHiddenOrNot = false,
}) => {
    // context user
    const { user, userLocaleCountryCode } = useUser({});
    const EX6722 = user?.EX6722;
    // context status
     const { counterStep } = useStatusContext();
     const steps = ["#step3",'body','#step4'];
     const controlStep = steps.find((element) => counterStep === element);

    // validation is argentine
    const userIsArgentine = (): boolean => user?.client?.country === Country.ARGENTINE;

    return (
        <>
            {!controlStep ? (
        <div className={style['wrapper-footer-blur']}></div>
      ) : (
        ''
      )}

            <Footer
                className={!expandedFooter ? style['wrapper-footer'] : style['wrapper-footer-expanded']}
                style={
                    EX4780
                        ? {
                              marginLeft: EX4204 && isValidationFooterHiddenOrNot ? -48 : 0,
                              marginRight: EX4204 && isValidationFooterHiddenOrNot ? -48 : 0,
                          }
                        : {
                              marginLeft: EX4204 && isValidationFooterHiddenOrNot ? -48 : 0,
                              marginRight: EX4204 && isValidationFooterHiddenOrNot ? -48 : 0,
                              position: EX4204 && isValidationFooterHiddenOrNot ? 'relative' : 'initial',
                              bottom: EX4204 && isValidationFooterHiddenOrNot ? (!expandedFooter ? 0 : 8) : '',
                              zIndex: EX4204 && isValidationFooterHiddenOrNot ? 9999 : 0,
                          }
                }
            >
                <div>
                    <div>
                        <a href="https://www.careers-page.com/extendeal" target="_blank">
                            {t('footer.footer_One')}
                        </a>
                        <a
                            className="link__login-terms-and-conditions-2"
                            onClick={() => {
                                setOpenModalTerms();
                            }}
                        >
                            {EX5057 ? t('footer.footer_Two') : 'Términos y condiciones'}
                        </a>
                        {((EX4922 && userLocaleCountryCode === COUNTRY.MX) ||
                            (EX5036 && userLocaleCountryCode === COUNTRY.CO)) && (
                            <a onClick={() => setOpenModalPrivacyPolicy()}>Políticas de privacidad </a>
                        )}
                        <a className="link__login-help-1" href="https://ayuda.extendeal.com" target="_blank">
                            {EX5057 ? t('footer.footer_Three') : 'Ayuda'}
                        </a>
                    </div>
                    <div>
                        <p>
                            {!expandedFooter
                                ? EX5057
                                    ? t('footer.footer_Four')
                                    : 'Más información'
                                : EX5057
                                ? t('footer.footer_Five')
                                : 'Menos información'}
                        </p>
                        <Button
                            style={{
                                background: 'none',
                            }}
                            onClick={() => setExpandedFooter(!expandedFooter)}
                        >
                            {!expandedFooter ? <ArrowUpLineIcon /> : <ArrowDownLineIcon />}
                        </Button>
                    </div>
                </div>
                {expandedFooter && (
                    <div className={style['wrapper-footer-section-bottom']}>
                        <div className={style['wrapper-footer-address']}>
                            <p>Copyright © 2025 {COPYRIGHT[userLocaleCountryCode]}</p>
                            {
                                // different address by country and hidden in BR and US
                                EX6722 ? (
                                    ![COUNTRY.US, COUNTRY.BR].includes(userLocaleCountryCode) && (
                                        <>
                                            <p>|</p>
                                            <p>{ADDRESS[userLocaleCountryCode]}</p>
                                        </>
                                    )
                                ) : (
                                    <>
                                        <p>|</p>
                                        <p>Av. Del Libertador 4980, 7B, CP 1426, CABA, Argentina</p>
                                    </>
                                )
                            }
                        </div>
                        <div>
                            <img alt="aws" width={50.131} height={30} src={'/assets/images/aws.png'} />
                            <br />
                            <img alt="security" width={90.667} height={30} src={'/assets/images/security.png'} />
                            <br />
                            {!!userIsArgentine() && (
                                <a href={`http://qr.afip.gob.ar/?qr=${QR_AFIP_KEY}--w,,`} target="_blank">
                                    <img
                                        alt="fiscal"
                                        width={75}
                                        height={35}
                                        src={'/assets/images/data-fiscal-extendeal.png'}
                                    />
                                </a>
                            )}
                        </div>
                    </div>
                )}
            </Footer>
        </>
    );
};

export default FooterRsuite;
