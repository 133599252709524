import { useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { t } from 'i18next';
// contexts
import { useDrugManufacturerWidgetContext } from 'context';
// utils && hooks
import { typesOfErrors } from 'utils/drugManufacturers/errors';

interface Item {
    title: string;
    gap: number;
    content: any[];
    href: string;
}

const useDropdown = () => {
    const { query } = useRouter();
    const contentRefs = useRef([]);

    const { data } = useDrugManufacturerWidgetContext();

    const [openSections, setOpenSections] = useState<number[]>(() => {
        if (typeof window !== 'undefined') {
            const storedOpenSections = localStorage.getItem('openSections');
            return storedOpenSections ? JSON.parse(storedOpenSections) : [1, 2];
        }
    });

    const hasSomeError = data?.connected?.some((c) => typesOfErrors(c)?.hasSomeError);

    const toggleAccordion = (index) => {
        // Si la sección ya está abierta, la cerramos
        if (openSections.includes(index)) {
            setOpenSections(openSections.filter((i) => i !== index));
            // Actualiza el estado local y almacena en sessionStorage
            localStorage.setItem('openSections', JSON.stringify(openSections.filter((i) => i !== index)));
        } else {
            // Si no, la agregamos a la lista de secciones abiertas
            setOpenSections([...openSections, index]);
            // Actualiza el estado local y almacena en sessionStorage
            localStorage.setItem('openSections', JSON.stringify([...openSections, index]));
        }
    };

    const content: Item[] = [
        {
            title: t('newWidgetHome.suggested'),
            gap: 8,
            content: data?.suggested,
            href: `/${query.clientId}/settings/POS/${query?.posId}/posDetails`,
        },
        { title: t('newWidgetHome.connected'), gap: 4, content: data?.connected, href: `/${query.clientId}/settings/POS/${query?.posId}/posDetails` },
    ];

    return {
        contentRefs,
        toggleAccordion,
        openSections,
        content,
        hasSomeError,
    };
};

export default useDropdown;
