// components
import { AvatarGroups } from '@commons/components/AvatarGroup';
// contexts
import { usePrioritiesPercentagesCeContext } from 'context/PrioritiesPercentagesCe/PrioritiesPercentagesCe-context';
// utils
import { DistributionConfig, PrioritiesConfig, ServiceConfig } from './utils';
import { v4 as uuidv4 } from 'uuid';

const ToolbarCEAvatars = () => {
    const { percentage, unassigned_d } = DistributionConfig();
    const { priorities, unassigned } = PrioritiesConfig();
    const { tolerance, service } = ServiceConfig();
    // priorities and percentages context
    const { serviceId } = usePrioritiesPercentagesCeContext();
    const max = window.matchMedia('(max-width: 950px)').matches ? 2 : 4;
    
    switch (serviceId) {
        case 2:
            const key1 = uuidv4();
            return (
                <AvatarGroups
                    key={key1}
                    max={max}
                    avatars={percentage}
                    tolerance={tolerance}
                    unassigned={unassigned_d}
                    service={service}
                />
            );
        case 1:
            const key2 = uuidv4();
            return (
                <AvatarGroups
                    key={key2}
                    max={max}
                    avatars={priorities}
                    tolerance={tolerance}
                    unassigned={unassigned}
                    service={service}
                />
            );
    }
};

export default ToolbarCEAvatars;
