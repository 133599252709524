import React, { FC } from 'react';
// UTILS
import { backgroundColorCell } from '../../../../utils';
// INTERFACES
import { StatusCdo } from 'utils/interfaces';
import { CellWrapperProps } from './CellWrapper.interface';
// STYLES
import cellStyles from '../../../../CellStyles.module.css';
import styles from './CellWrapper.module.css';

const CellWrapper: FC<CellWrapperProps> = ({
    isCdo,
    cdo_status_id,
    product,
    isSuggested,
    isLowest,
    applyToleranceAccordingToService,
    checked,
    withoutChecks,
    children,
    isGenerics,
    isProductGroupSplit = false,
    isLowestSuggested = false
}): JSX.Element => {
    return (
        <div
        className={`${cellStyles['cell-price-container']} ${
            isCdo ? styles['cell-cdo'] : !!isGenerics ? styles['cell-price-generics'] : styles['cell-price']
        }`}
            style={{
                backgroundColor:
                isCdo && cdo_status_id !== StatusCdo.ENABLED
                        ? ''
                        : !!product &&
                          backgroundColorCell({
                              isSuggested,
                              isLowest,
                              product,
                              applyToleranceAccordingToService,
                              checked,
                              withoutChecks,
                              isProductGroupSplit,
                              isLowestSuggested
                          }),
            }}
        >
            {children}
        </div>
    );
};

export default CellWrapper;
