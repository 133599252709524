import { useEffect, useRef, useState } from 'react';
import { t } from 'i18next';
// contexts
import { useAlert, usePointOfSale, useProgressBarPriceContext, useSnackbar } from 'context';
import { useDragAndDrop } from 'context/dragAndDropContext';
import { useCreateOrder } from 'context';
// apis
import { continueToEfficientPurchasePromise, uploadSiafFile, cancel } from 'api';
// utils && hooks
import { DrugManufacturer } from 'common/types';
import Pusher from 'utils/pusher';
import { availableCdoByPos } from 'utils/cdo';
import { DrugManufacturersId } from 'utils/interfaces';
import { isGestorERPFormat, isZettiFormat } from 'utils/pointOfSale';
import { useAsync, Status, useUser } from 'utils/hooks';

type TaskCompleteEventStatus = 'ready' | 'error';

interface TaskCompleteEvent {
    drugManufacturer: DrugManufacturer;
    responseStatus: TaskCompleteEventStatus;
}

interface DrugManufacturerStatus {
    id: number;
    responseStatus: TaskCompleteEvent['responseStatus'];
    name: string;
}

const useProgressBarPrice = ({
    dataCreated,
    quantityAvailableDrugManufacturers,
    setHideBreadcrum,
    onDiscard,
    efficientPurchaseFromCart,
    files,
    continueToEfficientPurchase,
    parentOrderId,
    setInvalidProducts,
    setInvalidLines,
    setRequestedProductsQuantity,
    setParentOrderId,
    setErrorsFileUrl,
    setDisableDashedBorder,
}) => {
    // hooks
    const { show } = useAlert();
    const { user } = useUser({});
    const { pointOfSale } = usePointOfSale();
    const { data: fileResults, status, run } = useAsync({});
    const { resetStore } = useCreateOrder();
    const { setVisibleDragDrop, handleDragLeave } = useDragAndDrop();
    const snackbar = useSnackbar();
    const timerRef = useRef(null);

    // region los chunks son particiones de consultas y actualmente tenemos que verificar tanto en dataCreated y fileResults
    const chunks = dataCreated?.data?.data
        ? dataCreated?.data?.data?.chunks_quantity
        : fileResults?.data?.data
        ? fileResults?.data?.data?.chunks_quantity
        : 0;

    // states
    const [dispatchedJobs, setDispatchedJobs] = useState<any[]>([]);
    const [progress, setProgress] = useState(3);
    const [drugManufacturersAvailable, setDrugManufacturersAvailable] = useState<number>(
        quantityAvailableDrugManufacturers,
    );
    const [allDMFailedState, setAllDMFailedState] = useState(false);
    const [drugManufacturerStatusList, setDrugManufacturerStatusList] = useState<DrugManufacturerStatus[]>([]);
    const [finishedProcessingAllEvents, setFinishedProcessingAllEvents] = useState(false);
    const [finishedValidation, setFinishedValidation] = useState(false);
    const TIME_LEFT_VARIABLE = 1; //custom

    // FF's
    const EX5745 = user?.EX5745; //FF chunks in progress bar
    const EX5749 = user?.EX5749;

    // NEW CONTEXT VALUES PROGRESS BAR PRICES
    const {
        handleTimeLeft,
        handleQuantityRequestedProducts,
        handleCurrentStep,
        handleCurrentHeaderStep,
        currentStep,
        handleProgress,
        handleOrderId,
        handleRemainingAttempts,
        handleDrugManufacturerStatusList,
        handleFailed,
        orderId,
        currentHeaderStep,
        handleErrors,
        resetStates,
    } = useProgressBarPriceContext();

    const getRemainingDrugManufacturers = (
        drugManufacturers: DrugManufacturer[],
        drugManufacturerStatusList: DrugManufacturerStatus[],
    ): DrugManufacturerStatus[] => {
        const readyDrugManufacturers = drugManufacturerStatusList.map((d) => d.id);
        return drugManufacturers
            ?.filter((drug_manufacturer) => !readyDrugManufacturers.includes(drug_manufacturer.id))
            ?.map((drug_manufacturer) => ({
                id: drug_manufacturer.id,
                responseStatus: 'error',
                name: drug_manufacturer.name,
            }));
    };

    const getDrugManufacturersAvailable = () => {
        setDrugManufacturersAvailable(quantityAvailableDrugManufacturers);
    };

    const getFailedConnectionDrugManufacturers = () => {
        return drugManufacturerStatusList.filter((d) => d.responseStatus === 'error');
    };

    useEffect(() => {
        if (getFailedConnectionDrugManufacturers()?.length > 0) {
            handleErrors();
        }
    }, [drugManufacturerStatusList]);

    const isAnyDrugManufacturerFailedConnection = () => {
        const quantityDrugManufacturerStatusList = getFailedConnectionDrugManufacturers().length;
        return (
            quantityDrugManufacturerStatusList < drugManufacturersAvailable && quantityDrugManufacturerStatusList !== 0
        );
    };

    // correct connection amount
    const getReadyConnectionDrugManufacturers = () => {
        return drugManufacturerStatusList?.filter((d) => d?.responseStatus === 'ready');
    };

    const allDMFailed = () => {
        const result = getFailedConnectionDrugManufacturers().length === drugManufacturersAvailable;
        return result;
    };

    const quantityResponses = (): boolean => {
        // validation to know if cdo is active or not
        const availableCdo: boolean = availableCdoByPos(user?.pointsOfSale, pointOfSale);
        // number of drugstores that responded correctly
        const quantityReadyConnectionDrugManufacturers: number = getReadyConnectionDrugManufacturers()?.length;

        if (availableCdo) {
            // More than 1 drugstore responded? follow the process and do not fail
            if (quantityReadyConnectionDrugManufacturers >= 1) return false;
            return true;
        } else {
            // More than 2 drugstores responded? follow the process and do not fail
            const quantityReadyConnectionsWithoutCdo = getReadyConnectionDrugManufacturers()?.filter(
                (d) => d?.id !== DrugManufacturersId.CentralDeOfertas,
            )?.length;
            const limitValue = EX5749 ? 1 : 2;
            if (quantityReadyConnectionsWithoutCdo >= limitValue) return false;
            return true;
        }
    };

    const calculateProgressBar = () => {
        if (EX5745) {
            return 100 / chunks;
        } else {
            return 100 / drugManufacturersAvailable;
        }
    };

    useEffect(() => {
        if (currentStep === 2) {
            setTimeout(() => {
                handleCurrentStep(3);
            }, 2500);
        } else if (currentStep === 3) {
            setTimeout(() => {
                handleCurrentHeaderStep(3);
                handleCurrentStep(4);
            }, 2500);
        }
    }, [currentStep]);

    const progressBarPercentage = () => {
        const stepFactor = 100 / 3; // Cada step es 33.33% del total.

        if (currentStep === 1) {
            const progressInStep1 = (progress / 100) * stepFactor;
            return progressInStep1; // Se suma el progreso del step 1 (0-100).
        } else if (currentStep === 2) {
            const progressInStep2 = (progress / 100) * stepFactor; // Progreso dentro del step 2.
            return 33.33 + progressInStep2; // Se suma al 33.33% ya completado en el step 1.
        } else if (currentStep === 3) {
            const progressInStep3 = (progress / 100) * stepFactor; // Progreso dentro del step 3.
            return 66.66 + progressInStep3; // Se suma al 66.66% ya completado en los steps 1 y 2.
        } else if (currentStep === 4 && currentHeaderStep === 3) {
            return 100;
        }

        return 3; // Valor inicial antes de comenzar el primer step.
    };

    const calculateTimeLeft = () => {
        const steps = 3; // Tres steps en total
        const totalTime = 1 * (chunks * TIME_LEFT_VARIABLE); // Tiempo total basado en los chunks y el tiempo por chunk.

        const timePerStep = totalTime / steps; // Tiempo por step (un tercio del total).

        if (currentStep === 1) {
            // Step 1: Tiempo restante = 2/3 del tiempo total + progreso restante en el step actual.
            return 2 * timePerStep + (1 - progress / 100) * timePerStep;
        } else if (currentStep === 2) {
            // Step 2: Tiempo restante = 1/3 del tiempo total + progreso restante en el step actual.
            return timePerStep + (1 - progress / 100) * timePerStep;
        } else if (currentStep === 3) {
            // Step 3: Solo el progreso restante en este último step.
            return (1 - progress / 100) * timePerStep;
        } else if (currentStep === 4) {
            // Step 4: Progreso completado, tiempo restante es 0.
            return 0;
        }

        return totalTime; // Valor inicial con el tiempo total.
    };

    // effect calculate progress percentage and timeleft
    useEffect(() => {
        handleProgress(progressBarPercentage());
        handleTimeLeft(calculateTimeLeft());
    }, [progress, currentStep]);

    useEffect(() => {
        handleDrugManufacturerStatusList(drugManufacturerStatusList);
    }, [drugManufacturerStatusList]);

    const calculateRemainingTime = () => {
        if (EX5745) {
            return 1 * (chunks * TIME_LEFT_VARIABLE);
        } else {
            return 1 * drugManufacturersAvailable;
        }
    };

    const handleCancelModal = () => {
        resetStore();
        if (!isZettiFormat(pointOfSale) && !isGestorERPFormat(pointOfSale)) {
            setVisibleDragDrop(false);
        }
        show({
            className: '',
            title: t('efficientPurchasing.fileUpload.efficientPurchasing_fileUploadTwentyEight'),
            cancel: true,
            size: 'xs',
            body: t('efficientPurchasing.fileUpload.efficientPurchasing_fileUploadTwentyNine'),
            action: t('efficientPurchasing.comparative.labelConfirmDiscard'),
            onConfirm: () => {
                if (setHideBreadcrum) {
                    setHideBreadcrum(false);
                }
                cancel(orderId);
                confirmCancelation();
            },
            onCancel: () => {},
        });
    };

    const finishProgress = () => {
        setProgress((oldProgress) => {
            return Math.min(oldProgress + 100, 100);
        });
    };

    const confirmCancelation = () => {
        if (isZettiFormat(pointOfSale)) {
            handleDragLeave(null);
        } else {
            setVisibleDragDrop(false);
        }

        resetStates();
        snackbar.show({
            message: t('snackbar.labelDiscardOrder'),
        });
        onDiscard();
    };

    const handleStatus = () => {
        if (quantityResponses()) {
            handleCurrentHeaderStep(3);
            handleCurrentStep(4);
            handleFailed();
            setAllDMFailedState(true);
            setFinishedValidation(true);
            return;
        }

        if (allDMFailed()) {
            handleCurrentHeaderStep(3);
            handleCurrentStep(4);
            handleFailed();
            setAllDMFailedState(true);
            setFinishedValidation(true);
            return;
        }

        if (isAnyDrugManufacturerFailedConnection()) {
            setFinishedValidation(true);
            return;
        } else {
            setFinishedValidation(true);
            return;
        }
    };

    const dispatchJob = (apiData) => {
        if (setHideBreadcrum) {
            setHideBreadcrum(true);
        }

        const {
            order: { id },
            requested_products,
            dispatched_jobs,
        } = apiData?.data.data;

        setDispatchedJobs(dispatched_jobs);
        const channel = Pusher.subscribe(`order.${id}`);
        handleQuantityRequestedProducts(requested_products);
        handleOrderId(id);

        channel.bind(`App\\Events\\TaskComplete`, (data) => {
            if (data) {
                const { drugManufacturer, responseStatus } = data || {};
                const { id, name } = drugManufacturer || {};
                if (EX5745) {
                    setDrugManufacturerStatusList((actual) => {
                        const drugManufacturerStatusListIds = actual?.map((d) => d?.id);
                        if (!drugManufacturerStatusListIds?.includes(id)) {
                            return actual.concat({ id, responseStatus, name });
                        }
                        return actual;
                    });
                } else {
                    setDrugManufacturerStatusList((actual) => actual.concat({ id, responseStatus, name }));
                }
                setProgress((oldProgress) => {
                    return Math.min(oldProgress + calculateProgressBar(), 100);
                });
            }
        });

        channel.bind(`App\\Events\\AllTasksComplete`, (data) => {
            handleRemainingAttempts(data?.remainingAttempts as number);
            finishProgress();
            setFinishedProcessingAllEvents(true);
        });

        return () => {
            Pusher.unsubscribe(`order.${id}`);
            void channel.unbind_all();
        };
    };

    const DEPENDENCIES = EX5745 ? [dispatchedJobs, chunks] : [dispatchedJobs];

    useEffect(() => {
        const validation = EX5745 ? dispatchedJobs?.length > 0 && chunks > 0 : dispatchedJobs?.length > 0;
        if (validation) {
            getDrugManufacturersAvailable();
        }
    }, DEPENDENCIES);

    const DEPENDENCIES_TIME_LEFT = EX5745 ? [drugManufacturersAvailable, chunks] : [drugManufacturersAvailable];

    useEffect(() => {
        handleTimeLeft(calculateRemainingTime());
    }, DEPENDENCIES_TIME_LEFT);

    useEffect(() => {
        if (!efficientPurchaseFromCart) {
            const [file] = files;
            const point_of_sale_id = pointOfSale?.id;

            if (!file) {
                return;
            }

            if (continueToEfficientPurchase) {
                let order_id = parentOrderId;
                return run(
                    continueToEfficientPurchasePromise({
                        file,
                        point_of_sale_id,
                        order_id,
                    }),
                );
            }

            return run(uploadSiafFile({ file, point_of_sale_id }));
        }
    }, [files, run]);

    useEffect(() => {
        if (!efficientPurchaseFromCart) {
            const hasError = fileResults?.data?.data?.errors?.length > 0 || fileResults?.data?.data?.error?.length > 0;

            if (hasError) {
                setInvalidProducts(fileResults?.data?.data?.errors);
                setInvalidLines(fileResults?.data?.data?.invalid_lines);
                setRequestedProductsQuantity(fileResults?.data?.data?.requested_products);
                setParentOrderId(fileResults?.data?.data?.order?.id);
                setErrorsFileUrl(fileResults?.data?.data?.errors_file_url);
            }

            if (!hasError && status === Status.Resolved) {
                dispatchJob(fileResults);
            }
        }
    }, [status]);

    useEffect(() => {
        if (dataCreated) {
            dispatchJob(dataCreated);
        }
    }, [dataCreated]);

    useEffect(() => {
        if (finishedProcessingAllEvents && finishedValidation) {
            const remaining = getRemainingDrugManufacturers(dispatchedJobs, drugManufacturerStatusList);
            setDrugManufacturerStatusList((actual) => actual.concat(remaining));
            if (!allDMFailedState) {
                handleCurrentStep(2);
                handleCurrentHeaderStep(2);
            }
        }
    }, [finishedProcessingAllEvents, finishedValidation]);

    useEffect(() => {
        if (progress === 100) {
            timerRef.current = setTimeout(() => {
                handleStatus();
            }, 2000);
        }
    }, [progress, finishedProcessingAllEvents]);

    useEffect(() => {
        if (!efficientPurchaseFromCart) {
            setDisableDashedBorder(true);
            return () => {
                clearTimeout(timerRef.current);
                setDisableDashedBorder(false);
            };
        }
    }, []);
    return { handleCancelModal, confirmCancelation, getFailedConnectionDrugManufacturers };
};

export default useProgressBarPrice;
