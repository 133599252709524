// react
import subDays from 'date-fns/subDays';

// rsuite
import { DateRangePicker } from 'rsuite';
// style
import style from './DateInput.module.css';
import './DateInput.module.css';
// interface
import { IDateInput } from './DateInput.interface';

// helpers
import {
    locale,
    predefinedRanges,
    parseDate,
    shouldDisableDate,
    formatUserSelectionDate,
    handleWeekDay,
} from './DateInput.herlper';

//gtag
import * as gtag from 'utils/gtag';
import { useEffect, useState } from 'react';

const DateInput = ({
    placeholder,
    icon,
    setDateTerms,
    dateTerms,
    handlerClearFilters,
    selectedDate,
    setSelectedDate,
    value,
    timerSkeleton,
}: IDateInput) => {
    // external function
    const ranges = predefinedRanges()
    const locales = locale();
    const [selectDate, setSelectDate] = useState([
        formatUserSelectionDate(dateTerms.from),
        formatUserSelectionDate(dateTerms.to),
    ]);
    // handler
    const handlerSetDate = (evt) => {
        const [from, to] = evt.map((date) => parseDate(date));

        const dateRange = {
            from: from,
            to: to,
        };
        setSelectedDate({ from: dateRange.from, to: dateRange.to });
        setDateTerms({ from: dateRange.from, to: dateRange.to });
        setSelectDate([formatUserSelectionDate(dateRange.from), formatUserSelectionDate(dateRange.to)]);
    };

    const setDate = (evt) => {
        gtag.event({
            action: 'click',
            category: 'my-orders',
            label: 'button__apply-filter-date-1',
            value: 0,
        });
        handlerSetDate(evt);

        setTimeout(function () {
            handleWeekDay(evt, [subDays(new Date(), 6), new Date()]);
        }, 1000);
    };


    useEffect(() => {
        setSelectDate([formatUserSelectionDate(dateTerms.from), formatUserSelectionDate(dateTerms.to)]);
    }, [dateTerms]);

    const [pointer, setPointer] = useState('selected-filter-0');
    const btns = Array.from(document.querySelectorAll('.rs-btn.rs-btn-link.rs-btn-sm'));

    const handleSelecFilter = () => {
        btns.forEach(function (btn) {
            if (pointer === btn.classList[3]) {
                btn.classList.add('selected-filter');
            } else {
                btn.classList.remove('selected-filter');
            }
        });
    };

    const handleShourtCut = () => {
        const btns = Array.from(document.querySelectorAll('.rs-btn.rs-btn-link.rs-btn-sm'));
        btns.map(function (btn, index) {
            btn.classList.add(`selected-filter-${index}`);
        });
        handleSelecFilter();
    };
    const handleClickEvent = (element) => {
        if (
            element?.classList[0] === 'rs-calendar-table-cell-day' ||
            element?.classList[0] === 'rs-calendar-table-cell-content'
        ) {
            setPointer('');
        } else if (element?.classList[3]?.includes('selected-filter')) {
            setPointer(element?.classList[3]);
        }
    };
    return (
        <div className={style['container-wrapper-form']}>
            <div className={style['wrapper-container']} onClick={(e) => handleClickEvent(e?.target)}>
                <DateRangePicker
                    disabled={timerSkeleton}
                    onClean={() => handlerClearFilters('date', true)}
                    size="lg"
                    placeholder={<span className={style['placeholder-text']}>{placeholder}</span>}
                    className={style['date-picker']}
                    format="dd/MM/yyyy"
                    placement="bottomStart"
                    character=" - "
                    ranges={ranges}
                    editable={false}
                    isoWeek
                    locale={locales}
                    // @ts-ignore
                    caretAs={() => {
                        handleSelecFilter();
                    }}
                    onOpen={() => handleShourtCut()}
                    onOk={(evt) => {
                        setDate(evt);
                    }}
                    shouldDisableDate={shouldDisableDate}
                    cleanable={false}
                    // @ts-ignore
                    value={selectDate}
                />
                <div className={style['container-icon']}> {icon} </div>
            </div>
        </div>
    );
};

export default DateInput;
