// styles
import styles from './MessageOnlyDesktop.module.css';

const MessageOnlyDesktop = () => {
    return (
        <div className={styles['text-informative']}>
            <img src="/assets/images/monitor.svg" alt="monitor icon" draggable={false} />
            <span>Plataforma diseñada para uso en ordenadores</span>
        </div>
    );
};

export default MessageOnlyDesktop;
