import { memo, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
// configs
import { EX7066, EX7182 } from 'config/flags';
// apis
import { removeSelectAllDrugManufacturer, selectAllDrugManufacturer } from 'api';
// contexts
import { useProductsContext, useSelectAllDrugManufacturerContext, useSelectAllStateContext } from 'context';
import { useProductComparison } from 'context/product-comparison.context';
// components
import { IconButton } from 'rsuite';
import { RsuitePopover } from '@commons/components/RsuitePopover';
import { CellMenuDropdown } from '../CellDrugManufacturerHeader/CellMenuDropdown';
// icon mui
import MoreVertIcon from '@mui/icons-material/MoreVert';
// styles
import styles from './TableHeadDropdownMenu.module.css';

const TableHeadDropdownMenu = ({
    isEnabled,
    item,
    flag,
    isActive,
    isDefault,
    parentHeaderRef,
    loading,
    event,
    selectAll,
    setSelectAll,
    fetchProductGroup,
}) => {
    const { selectAllState, setSelectAllState } = useSelectAllStateContext();
    const { selectAllProductByDMId } = useProductComparison();
    const { cleanProducts } = useProductsContext();
    const { setSelectAllDrugManufacturerId } = useSelectAllDrugManufacturerContext();
    const [open, setOpen] = useState(false);
    const buttonRef = useRef(null);
    const { query } = useRouter();
    const { orderId } = query;

    const handleSelectAllById = async (id) => {
        if (EX7066) {
            setSelectAllDrugManufacturerId(id);
            await selectAllDrugManufacturer({ orderId, drugManufacturerId: id })
                .then(() => {
                    if (EX7182) {
                        cleanProducts();
                    }
                    return fetchProductGroup();
                })
                .catch((e) => console.error(e));
        } else {
            setSelectAll(true);
            setSelectAllState(id);
            selectAllProductByDMId(id);
        }
    };

    const handleOnAndSelectAllById = async (id) => {
        setSelectAllDrugManufacturerId(id);
        await selectAllDrugManufacturer({ orderId, drugManufacturerId: id })
            .then(() => event.handleOnOffEvent('no requested'))
            .then(() => {
                if (EX7182) {
                    cleanProducts();
                }
                return fetchProductGroup();
            })
            .catch((e) => console.error(e));
    };

    const handleUnselectAll = async () => {
        setSelectAllDrugManufacturerId(null);
        await removeSelectAllDrugManufacturer({ orderId })
            .then(() => {
                if (EX7182) {
                    cleanProducts();
                }
                return fetchProductGroup();
            })
            .catch((e) => console.error(e));
    };

    useEffect(() => {
        if (!EX7066) {
            if (selectAll) {
                if (!isEnabled) {
                    event.handleOnOffEvent();

                    setTimeout(() => {
                        handleSelectAllById(item?.id);
                    }, 2000);
                    return;
                }
            }
        }
    }, [selectAll]);

    return (
        <div className={styles['button']} ref={buttonRef}>
            <div className={styles['icon']}>
                <RsuitePopover
                    childOne={
                        <CellMenuDropdown
                            isEnabled={isEnabled}
                            selectAllState={selectAllState}
                            id={item.id}
                            event={event}
                            setOpen={setOpen}
                            handleSelectAllById={handleSelectAllById}
                            handleOnAndSelectAllById={handleOnAndSelectAllById}
                            handleUnselectAll={handleUnselectAll}
                        />
                    }
                    parentRef={parentHeaderRef}
                    trigger="click"
                    placement="bottomEnd"
                    popoverClassName={styles['container-popover-menu']}
                    open={open}
                >
                    <IconButton
                        className={flag === 'cdo' ? styles['container-button-cdo'] : styles['container-button']}
                        icon={<MoreVertIcon style={{ height: 20, width: 20 }} />}
                        disabled={loading || (flag === 'cdo' && isActive) || isDefault}
                        onClick={() => setOpen(!open)}
                        onBlur={() => {
                            setTimeout(() => {
                                setOpen(false);
                            }, 300);
                        }}
                    />
                </RsuitePopover>
            </div>
        </div>
    );
};

export default memo(TableHeadDropdownMenu);
