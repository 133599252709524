import React from 'react';
import { Row, Col, Divider } from 'rsuite';
import { IBodyDeliveries } from './BodyDeliveries.interface';
import { useUser } from 'utils/hooks';
import { Role } from 'common/types';
import { useTranslation } from 'react-i18next';
import useChangeLanguage from 'utils/hooks/use-change-language';
import { EX4396, EX5057 } from 'config/flags';
import style from './BodyDeliveries.module.css';
import { currencyFormatter } from '../../../utils/currencyTotal';
import { PaidOutlined } from '@mui/icons-material';

function BodyDeliveries({ order_deliveries }: IBodyDeliveries) {
    //translation by Country
    const { t } = useTranslation();
    const { user } = useUser({});
    const EX5428 = user?.EX5428;
    const userLocaleCountryCode = user?.client?.country_alfa_2;
    if (EX4396) useChangeLanguage(userLocaleCountryCode);

    const credit = currencyFormatter(order_deliveries?.credit_for_agreements);
    const total = currencyFormatter(order_deliveries?.purchased_products_total_price);

    const purchased_products =
        order_deliveries?.purchased_products > 1
            ? `${order_deliveries?.purchased_products} ${
                  !!EX5057 ? t('efficientPurchasing.comparative.labelFooterProducts') : 'productos'
              }`
            : `${order_deliveries?.purchased_products} ${
                  !!EX5057 ? t('efficientPurchasing.comparative.labelFooterProduct') : 'producto'
              }`;

    const purchased_products_quantity_with_free =
        order_deliveries?.purchased_products_quantity + (order_deliveries?.free_unit_products_quantity || 0);

    const purchased_products_quantity =
        purchased_products_quantity_with_free > 1
            ? `${purchased_products_quantity_with_free} ${!!EX5057 ? t('myOrders.labelUnits') : 'unidades'}`
            : `${purchased_products_quantity_with_free} ${!!EX5057 ? t('myOrders.labelUnit') : 'unidad'}`;

    const products_quantity_with_free =
        order_deliveries?.products_quantity + (order_deliveries?.free_unit_products_quantity || 0);

    const products_quantity =
        products_quantity_with_free > 1
            ? `${products_quantity_with_free} ${!!EX5057 ? t('myOrders.labelUnits') : 'unidades'}`
            : `${products_quantity_with_free} ${!!EX5057 ? t('myOrders.labelUnit') : 'unidad'}`;

    return (
        <Col xs={24}>
            <Row style={{ fontSize: 12, color: '#6B6F93' }}>
                <Col xs={24} sm={12}>
                    <div className={style['title']}>Total</div>
                    <p>
                        <span className={style['product_quantity']}>{purchased_products}</span>{' '}
                        <span className={style['unit_quantity']}>
                            {' '}
                            {`| ${
                                order_deliveries?.purchased_products_quantity
                                    ? purchased_products_quantity
                                    : products_quantity
                            }
                            `}
                        </span>
                    </p>
                </Col>
                <Col
                    xs={24}
                    sm={12}
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        fontWeight: 700,
                        color: '#6B6F93',
                        fontSize: 14,
                    }}
                >
                    {!EX4396
                        ? `$ ${total}`
                        : t('myOrders.cardDeliveriesBody.totalPriceProducts', {
                              valueMoney: total,
                          })}
                </Col>
            </Row>
            {order_deliveries?.credit_for_agreements > 0 && user?.role_id !== Role.Buyer && (
                <Row style={{ marginTop: 8 }}>
                    <Col xs={24} sm={12} style={{ fontSize: 12, color: '#6B6F93' }}>
                        {!!EX5057 ? t('myOrders.labelAgreementsAndCredit') : 'Crédito por acuerdos'}
                    </Col>
                    <Col
                        xs={24}
                        sm={12}
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            fontWeight: 700,
                            color: '#6B6F93',
                            fontSize: 14,
                        }}
                    >
                        {!EX4396
                            ? `$ ${credit}`
                            : t('myOrders.cardDeliveriesBody.creditForAgreements', {
                                  valueMoney: credit,
                              })}
                    </Col>
                </Row>
            )}
            {EX5428 && order_deliveries.payment_method_name && (
                <>
                    <Row>
                        <Divider style={{ margin: '8px' }} />
                    </Row>
                    <Row>
                        <Col xs={24}>
                            <div className={style.label}>
                                <PaidOutlined className={style.icon} />
                                <span>{t('paymentMethodSelection.label')}</span>
                                <span className={style.paymentMethod}>{order_deliveries.payment_method_name}</span>
                            </div>
                        </Col>
                    </Row>
                </>
            )}
        </Col>
    );
}

export default BodyDeliveries;
