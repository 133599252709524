import React, { useEffect, useState, useRef } from 'react';
import { List, Panel, Row, Col, Button, Loader, Placeholder } from 'rsuite';
import PlusIcon from '@rsuite/icons/Plus';
// mui v5
import WallpaperIcon from '@mui/icons-material/Wallpaper';
// style
import style from './RenderMenuItem.module.css';
import { formatObject, handleChangeMomentary, handleOperation } from './handlers';
import { RsuiteCounter } from 'commons/components/RsuiteCounter';
import { NewUseCart } from 'context';
import { DetailProduct, FakeLoading } from 'commons/components/DetailProduct';
import { addEllipsis } from 'utils/strings/ellipsis.utils';
import { EX4693, EX4698 } from 'config/flags';
import { capitalizeEveryFirstLetter } from 'utils/strings/capitalizeEveryFirstLetter';
import ParagraphLoader from '@components/LoadingBody/ParagraphLoader/ParagraphLoader';

const RenderMenuItem = ({
    idx,
    _source,
    onClickAddProductCart,
    existsProductInCart,
    quantity,
    cartId,
    pointOfSaleId,
    handleUpdateProduct,
    onKeyDown,
    selectedIndex,
    index,
    parentRef,
}) => {
    // STATE LOCAL INPUT
    const [value, setValue] = useState<number>(quantity);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [imageLoad, setImageLoad] = useState<boolean>(false);

    const containerRef = useRef(null);
    const popoverRef = useRef(null);

    const { toggle, visibility } = NewUseCart();
    // LISTEN UPDATE QUANTITY & SETTER STATE
    useEffect(() => {
        if (isFetching || quantity !== value) setValue(quantity);
        setIsFetching(false);
    }, [quantity]);

    // SUBS
    const handleMinus = (v) => {
        setIsFetching(true);
        return handleOperation(formatObject(_source, cartId), v, handleUpdateProduct);
    };

    // ADD
    const handlePlus = (v) => {
        setIsFetching(true);
        return handleOperation(formatObject(_source, cartId), v, handleUpdateProduct);
    };

    // WRITING MOMENTARY
    const handleWriting = (v) => {
        return handleChangeMomentary(v, setValue);
    };

    // INPUT ONBLUR
    const handleInput = (v) => {
        if (quantity !== +v) {
            setIsFetching(true);
            return handleOperation(formatObject(_source, cartId), +v, handleUpdateProduct);
        }
    };

    // init
    useEffect(() => {
        if (imageLoad) setImageLoad(false);
    }, [_source.ean[0]]);

    let openPopover;

    switch (true) {
        case _source?.drug:
            openPopover = false;
            break;
        case !_source?.drug:
            openPopover = true;
            break;
        default:
            break;
    }

    const sourceAndLab =
        _source?.laboratory !== null && _source?.laboratory !== 'null'
            ? capitalizeEveryFirstLetter(`${_source.description}. ${_source.laboratory}`)
            : capitalizeEveryFirstLetter(`${_source.description}`);

    return (
        <div
            className={selectedIndex === index ? style['wrapper-menu-item-arrow'] : style['wrapper-menu-item']}
            //@ts-ignore
            tabIndex="-1"
            onKeyDown={onKeyDown}
            id="list-results"
        >
            <Col xs={20}>
                <div className={style['container-image-text-menu']}>
                    {!!EX4693 ? (
                        <>
                            {!imageLoad && (
                                <ParagraphLoader graph="image" className={style['paragraphLoaderImage']} rows={0} />
                            )}

                            <img
                                src={`${process.env.NEXT_PUBLIC_CLOUDFRONT_IMAGES_EP}/${process.env.NEXT_PUBLIC_CLOUDFRONT_FOLDER_TWO}/${_source.ean[0]}.png`}
                                alt="imagen medicamento"
                                onError={(e: any) => (e.target.src = '/assets/images/default.png')}
                                onLoad={(e: any) => setImageLoad(true)}
                                width={55}
                                height={55}
                                style={{
                                    display: !imageLoad ? 'none' : 'inline',
                                    objectFit: 'cover', // Agregando objectFit para conservar la calidad
                                }}
                                className={style['container-image']}
                            />
                        </>
                    ) : (
                        <WallpaperIcon className={style['icon-wallpaper']} />
                    )}
                    {!!EX4698 && !!EX4693 ? (
                        <div ref={containerRef} className={style['wrapper-detail-product']}>
                            <DetailProduct
                                activePrinciple={_source.drug}
                                labBrand={_source.laboratory}
                                medicine={`${_source.description} ${_source.laboratory}`}
                                category={_source?.category}
                                fakeLoading={<FakeLoading imageLoad={imageLoad} />}
                                imageSrc={`${process.env.NEXT_PUBLIC_CLOUDFRONT_IMAGES_EP}/${process.env.NEXT_PUBLIC_CLOUDFRONT_FOLDER_ONE}/${_source.ean[0]}.png`}
                                onLoad={(e: any) => setImageLoad(true)}
                                innerRef={popoverRef}
                                onClick={() => popoverRef?.current?.close()}
                                imageLoad={imageLoad}
                                disabled={openPopover}
                                placement={index <= 2 ? 'bottomStart' : 'topStart'}
                                container={parentRef}
                                onOpen={() => {}}
                                onClose={() => {}}
                                laboratory={capitalizeEveryFirstLetter(_source.laboratory)}
                                description={`${capitalizeEveryFirstLetter(
                                    _source.description,
                                )} ${capitalizeEveryFirstLetter(_source.laboratory)}`}
                                productName={capitalizeEveryFirstLetter(_source.name)}
                                presentation={capitalizeEveryFirstLetter(_source.presentation)}
                            >
                                <div className={style['wrapper-text-menu-item']}>
                                    <p
                                        className={
                                            _source.drug !== null ? style['descriptionHover'] : style['description']
                                        }
                                    >
                                        {sourceAndLab}
                                    </p>
                                    <span className={style['container-text']}>
                                        {_source.ean[0]} {_source.drug && <div className={style['verticalBar']} />}{' '}
                                        {(!!_source.drug && _source.drug === 'null') || '' ? '' : _source.drug}
                                    </span>
                                </div>
                            </DetailProduct>
                        </div>
                    ) : (
                        <div className={style['wrapper-text-menu-item']}>
                            <p>{_source.description}</p>
                            <span>{_source.ean[0]}</span>
                        </div>
                    )}
                </div>
            </Col>
            <Col xs={4}>
                <div className={style['wrapper-button-menu-item']}>
                    {!!existsProductInCart(_source.ean[0]) ? (
                        <RsuiteCounter
                            idx={idx}
                            value={value}
                            handleWriting={handleWriting}
                            handleInput={handleInput}
                            handleMinus={handleMinus}
                            handlePlus={handlePlus}
                            hasError={false}
                            min={0}
                            isFetching={isFetching}
                            styles={style}
                            tabOrigin={'search-results-list'}
                        />
                    ) : (
                        <Button
                            className={`button__add-product-results ${style['button-add-item']}`}
                            startIcon={!isFetching && <PlusIcon className="button__add-product-results" />}
                            onClick={(evt) => {
                                setIsFetching(true);
                                onClickAddProductCart(evt);
                                if (!visibility) {
                                    toggle(pointOfSaleId);
                                }
                            }}
                        >
                            {isFetching ? (
                                <div className={`${style['wrapper-loader']}`}>
                                    <Loader />
                                </div>
                            ) : (
                                <span className={`button__add-product-results ${style['text-button-item']}`}>
                                    Agregar
                                </span>
                            )}
                        </Button>
                    )}
                </div>
            </Col>
        </div>
    );
};

export default RenderMenuItem;
