// Formatea con espacios (ejemplo: 1 55 1234 5678)
export const formattedPhone = (numbers) =>
    numbers.replace(/(\d{1})(\d{2})?(\d{4})?(\d{4})?/, (_, p1, p2, p3, p4) =>
        [p1, p2, p3, p4].filter(Boolean).join(' '),
    );

export const checkMissingKeys = (obj, requiredKeys) => {
    for (let key of requiredKeys) {
        if (!obj.hasOwnProperty(key)) {
            return true;
        }
    }
    return false;
};
