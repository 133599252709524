import { useEffect, useRef, useState } from 'react';
import { t } from 'i18next';
// components
import { RsuiteTooltip } from '@commons/components';
// utils && hooks
import { typesOfErrors } from 'utils/drugManufacturers/errors';
// interfaces
import { IDescriptionProps } from './Description.interface';
// styles
import styles from './Description.module.css';

const Description = ({ child, from, parentRef, idx, disabledEdit }: IDescriptionProps) => {
    const nameRef: any = useRef(null);
    const specialityRef: any = useRef(null);
    const [element, setElement] = useState<any>(null);
    const [elementSpeciality, setElementSpeciality] = useState<any>(null);

    // useEffect para ejecutar getElementById cuando idx cambia
    useEffect(() => {
        const newElement = document?.getElementById(`${child?.id}__${child?.name}`);
        const newElementSpeciality = document?.getElementById(`${child?.id}__${child?.speciality}`);

        setElement(newElement);
        setElementSpeciality(newElementSpeciality);
    }, [child?.id, child?.name, child?.speciality, idx]); // Agregar idx como dependencia

    /* #region listener hidden tooltip when scrolling */
    useEffect(() => {
        const checkScroll = () => {
            if (nameRef?.current) {
                nameRef.current.close();
            }
        };

        if (parentRef?.current) {
            parentRef?.current?.addEventListener('scroll', checkScroll);
            return () => {
                parentRef?.current?.removeEventListener('scroll', checkScroll);
            };
        }
    }, [nameRef]);

    /* #region listener hidden tooltip when scrolling */
    useEffect(() => {
        const checkScroll = () => {
            if (specialityRef?.current) {
                specialityRef.current.close();
            }
        };

        if (parentRef?.current) {
            parentRef?.current?.addEventListener('scroll', checkScroll);
            return () => {
                parentRef?.current?.removeEventListener('scroll', checkScroll);
            };
        }
    }, [specialityRef]);

    return (
        <div className={styles['name-section']}>
            <RsuiteTooltip
                placement="topStart"
                trigger="hover"
                text={child?.name}
                textAlign={'left'}
                marginTop={-4}
                disabled={!(element?.scrollWidth > element?.clientWidth)}
            >
                <p
                    id={`${child?.id}__${child?.name}`}
                    ref={nameRef}
                    className={styles['name']}
                    style={{
                        width: from === 2 && !typesOfErrors(child)?.hasSomeError ? '100%' : 153,
                        maxWidth: from === 2 && disabledEdit(child) ? 300 : 153,
                    }}
                >
                    {child?.name}
                </p>
            </RsuiteTooltip>
            {from === 1 && (
                <RsuiteTooltip
                    placement="topStart"
                    trigger="hover"
                    text={child?.speciality}
                    textAlign={'left'}
                    marginTop={-4}
                    disabled={!(elementSpeciality?.scrollWidth > elementSpeciality?.clientWidth)}
                >
                    <p id={`${child?.id}__${child?.speciality}`} ref={specialityRef}>
                        {child?.speciality}
                    </p>
                </RsuiteTooltip>
            )}
            {from === 2 && typesOfErrors(child)?.hasSomeError && (
                <p
                    className={styles['error']}
                    style={{ width: from === 2 && !typesOfErrors(child)?.hasSomeError ? 153 : '100%' }}
                >
                    {typesOfErrors(child)?.include_format
                        ? t('credentials.labelCurrentStatusFormat1')
                        : typesOfErrors(child)?.missing_format
                        ? t('home.tabs.missingFormat')
                        : t('newWidgetHome.credentialErrorText')}
                </p>
            )}
        </div>
    );
};

export default Description;
