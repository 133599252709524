export const percent = (t, value: string | number, total: string | number) => {
    let percentValue: number = 0;
    if (+value === 0) {
        percentValue = +value;
    } else {
        percentValue = +((+value * 100) / +total).toFixed(2);
    }
    return t('efficientPurchasing.comparative.percentageOfTotalOrder', {
        percent: percentValue,
    });
};
