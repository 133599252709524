import { makeStyles } from "@material-ui/core";
import { shadows } from "styles/mixins";

const useStyles = makeStyles(({ palette }) => ({
  root: {
    backgroundColor: "white",
    boxShadow: shadows[1],
    padding: "16px",
    marginBottom: "40px",
    borderRadius: "4px",
    borderTop: `4px solid #0171E6`,
    width: 608,
  },
  label: {
    cursor: "pointer",
    paddingLeft: 4,
    fontSize: 16,
    color: "#333333",
  },
  labelDisabled: {
    cursor: "default",
    color: "#ACACAC",
    paddingLeft: 4,
    fontSize: 16,
  },
  uploadPhoto: {
    opacity: 0,
    position: "absolute",
    zIndex: -1,
  },
  formatRequest: {
    color: "#017C7A",
    fontSize: 12,
    fontFamily: "Source Sans Pro",
  },
  container_submitFormat: {
    width: 579,
    display: "flex",
    height: 22,
    alignItems: "center",
    justifyContent: "center",
  },
  row_submitFormat: {
    width: 400,
    display: "flex",
    flexDirection: "column",
  },
  row_flex: {
    display: "flex",
    alignItems: "center",
  },
  text_correctFormat: {
    marginLeft: 8,
    color: "#333333",
    fontSize: 16,
    fontFamily: "Source Sans Pro",
  },
  
}));

export default useStyles;
