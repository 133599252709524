import { t } from 'i18next';
// components
import { RsuiteTooltip } from '@commons/components/RsuiteTooltip';
// styles
import styles from './UpdateCredentialText.module.css';

const UpdateCredentialText = ({ name, key, handleOpenConnectionModal }) => {
    return (
        <RsuiteTooltip
            placement="topStart"
            trigger="hover"
            text={t('efficientPurchasing.credentialsError.efficientPurchasing_credentialsErrorThirteen', {
                value: name,
            })}
            key={key}
            minWidth={'200px'}
            textAlign={'start'}
        >
            <p className={styles['text']} onClick={handleOpenConnectionModal}>
                {t('newWidgetHome.buttonUpdate')}
            </p>
        </RsuiteTooltip>
    );
};

export default UpdateCredentialText;
