// style
import style from './AvailableDashboard.module.css';
// component
import { PanelConnection } from '@commons/components/WidgetsDrugConnections/PanelConnection';
import { PanelAddDrugmanufacturer } from '@commons/components/WidgetsDrugConnections/PanelAddDrugmanufacturer';
// utils
import stringAvatar from 'utils/avatar-name';
import { EmptyAvailable } from './EmptyAvailable';
import { t } from 'i18next';
import { postSendEmailVendors } from 'api/vendors/vendors';
import { useToast } from '@commons/EXComponentsLibrary';
import { useRouter } from 'next/router';
import { useModalCredentialsContext } from 'context/ModalCredentials/ModalCredentials-context';
import { EDrugManufacturerTypeId } from 'utils/enums/drugmanufacturerTypeId/DrugManufacturerTypeId';

const AvailableDashboard = ({ categorizedResults, fxs, triggerModal }) => {
    const toaster = useToast();
    const router = useRouter();

    const requestDrugManufacturer = async (drugManufacturer, posId) => {
        try {
            const response: any = await postSendEmailVendors(drugManufacturer?.id, { point_of_sale_id: posId });
            if (response?.status === 200) {
                //triggerAnimation(drugManufacturer?.id);
                return toaster.showToaster({
                    type: 'success',
                    message: {
                        title: `Enviamos tu solicitud a ${drugManufacturer?.name}.`,
                        description: 'Te avisaremos por mail cuando responda.',
                    },
                });
            }
            return response;
        } catch (ex) {
            toaster.showToaster({
                type: 'success',
                message: {
                    title: `Tu solicitud a ${drugManufacturer?.name} fallo`,
                    description: 'Prueba nuevamente más tarde.',
                },
            })
            console.error(ex.message);
        }
    };


    return (
        <>
            {categorizedResults?.availables.length === 0 ? (
                <div className={style['container-panel-available-empty']}>
                    <EmptyAvailable />
                </div>
            ) : (
                <div className={style['container-panel-availables']}>
                    {categorizedResults?.availables.map(({ id, name, speciality, type, credential_types}) => {
                        const availableObject = fxs.returnStatusObjectAvailable(type);
                        return (
                            <PanelConnection
                                btnAppearance={availableObject.buttonAppearance}
                                btnLabel={availableObject.buttonText}
                                statusAvatar={availableObject.statusAvatar}
                                initialName={stringAvatar(name, false, id)}
                                label={speciality}
                                name={name}
                                status={1}
                                key={id}
                                handleClick={() => {type.id === EDrugManufacturerTypeId.vendor ? requestDrugManufacturer(id, router?.query?.posId) : triggerModal(id, credential_types)}}
                            />
                        );
                    })}
                </div>
            )}

            <PanelAddDrugmanufacturer />
        </>
    );
};

export default AvailableDashboard;
