import { useCallback, useState } from 'react';
import { useRouter } from 'next/router';
// apis
import { recoveryPassword } from 'api';
// utils
import useChangeLanguage from 'utils/hooks/use-change-language';
import { COUNTRY } from 'utils/country';

export const useRecovery = () => {
    const { push } = useRouter();
    // ---------------------- states ----------------
    const [recaptcha, setRecaptcha] = useState(null);
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    const [formState, setFormState] = useState({
        email: '',
    });

    const updateFormState = useCallback((value, key) => {
        setFormState((prevState) => ({
            ...prevState,
            [key]: value,
        }));
    }, []);

    const onSubmit = async () => {
        if (recaptcha) {
            if (!loading) {
                setLoading(true);
                await recoveryPassword({ usernameOrEmail: formState.email })
                    .then((data) => {})
                    .catch((e) => {
                        console.error(e);
                        setLoading(false);
                        setSuccess(true);
                    })
                    .finally(() => {
                        setLoading(false);
                        setSuccess(true);
                    });
            }
        }
    };

    const redirectToLogin = () => {
        return push('/login');
    };

    // ---------------------- captcha ----------------
    const onChangeCaptcha = (token) => setRecaptcha(token);

    // ---------------------- translation ----------------
    const userLocale = typeof window !== 'undefined' ? navigator?.language : null;

    switch (userLocale) {
        case 'es-AR':
            useChangeLanguage(COUNTRY.AR);
            break;
        case 'en-US':
            useChangeLanguage(COUNTRY.US);
            break;
        case 'pt-BR':
            useChangeLanguage(COUNTRY.BR);
            break;
        case 'es-MX':
            useChangeLanguage(COUNTRY.MX);
            break;
        case 'es-CO':
            useChangeLanguage(COUNTRY.CO);
            break;
        case 'es-ES':
            useChangeLanguage(COUNTRY.ES);
            break;
        default:
            useChangeLanguage('es');
            break;
    }

    return {
        formState,
        updateFormState,
        onSubmit,
        onChangeCaptcha,
        loading,
        success,
        redirectToLogin,
    };
};
