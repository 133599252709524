import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  container: {
    width: 300,
    display: "flex",
  },
  valueLabel:{
    left: 'calc(-50% + 4px)',
    top: -33,
    '& *': {
      background: '#017C7A',
      color: 'white',
    },
  },
  root: {
    color: "#00B0BC",
    width: 210,
    cursor: "pointer",
    height: 8,
    alignItems: "center",
    display: "flex",
  },
  rail: {
    height: 8,
    borderRadius: "10px",
    width: "102%",
    backgroundColor: "#80E1E4",
  },
  track: {
    display: "none",
  },
  mark: {
    height: 4,
    width: 4,
    borderRadius: "50%",
  },
  markActive: {
    backgroundColor: "#00B0BC",
  },
  thumb: {
    height: 20,
    width: 20,
    marginTop: 0,
    "&:focus, &:hover, &$active": {
      boxShadow: "1px 2px 6px #00000029",
    },
  },
  containerValuePercentage: {
    width: 86,
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: 13.33,
    alignItems: "center",
    fontSize: 16,
    color: "#646464",
  },
  iconPercentage: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: 13.33,
  },
});
