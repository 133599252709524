// react
import { CSSProperties, useState } from 'react';
// next
import { useRouter } from 'next/router';
import { EX4569, EX4927 } from 'config/flags';
// rsuite
import { Message, Row, Col } from 'rsuite';
// interface
import { IAlertBannerNotification } from './AlertBannerNotification.interface';

// style
import style from './AlertBannerNotification.module.css';

const AlertBannerNotification = ({
    closable,
    type,
    header,
    subtext,
    closeText,
    link_text,
    user = {},
}: IAlertBannerNotification) => {
    // next
    const router = useRouter();
    // state
    const [isHiddenAlert, setIsHiddenAlert] = useState(false);

    const hiddenAlert = () => {
        setIsHiddenAlert(true);
        localStorage.setItem('alertBanner', 'false');
    };

    const getStyles = (): CSSProperties => {
        if (router.pathname === '/[clientId]/efficient-purchase/[posId]/product-comparison') {
            return {
                border: type === 'warning' ? '1px solid  #FFEA9F' : '1px solid  #f8cbcb',
                background: type === 'warning' ? '#FFF9E6' : '#ffebeb',
                width: '100%',
                height:
                    !!user?.EX4927 && EX4927
                        ? router?.pathname === '/[clientId]/efficient-purchase/[posId]/product-comparison'
                            ? 70
                            : ''
                        : '',
                // position: 'absolute',
                // top: 0,
                // left: 0,
                // zIndex: 50,
                textAlign: 'left'
            };
        } else {
            return {
                border: type === 'warning' ? '1px solid  #FFEA9F' : '1px solid  #f8cbcb',
                background: type === 'warning' ? '#FFF9E6' : '#ffebeb',
                width:
                    !!EX4569 && router.pathname === '/[clientId]/efficient-purchase/[posId]/product-comparison'
                        ? '100vw'
                        : '96.5vw',
                height:
                    !!user?.EX4927 && EX4927
                        ? router?.pathname === '/[clientId]/efficient-purchase/[posId]/product-comparison'
                            ? 70
                            : ''
                        : '',
            };
        }
    };

    return (
        <Message
            closable={closable}
            type={type}
            className={!isHiddenAlert ? style['message'] : style['message-hidden']}
            style={getStyles()}
        >
            <Row>
                <div className={style['wrapper-container']}>
                    <Col xs={24} sm={18}>
                        <div className={style['flex-container-left']}>
                            <div className={style['icon-type']}>
                                <img
                                    src={
                                        type === 'error'
                                            ? '/assets/images/error_FILL.svg'
                                            : '/assets/images/warning_FILL.svg'
                                    }
                                />
                            </div>
                            <div className={style['wrapper-text']}>
                                <p className={style['header-styles']}>{header}</p>

                                <p className={style['subtext-styles']}>{subtext}</p>
                            </div>
                        </div>
                    </Col>
                    <Col xs={24} sm={6}>
                        <div className={style['flex-container-right']}>
                            <a target="_blank" href={link_text} rel="noopener noreferrer">
                                {closeText}
                            </a>
                        </div>
                    </Col>
                </div>
            </Row>
        </Message>
    );
};

export default AlertBannerNotification;
