import { ExButton } from '@commons/EXComponentsLibrary';
import { EX5511, EX6778 } from 'config/flags';
import { t } from 'i18next';
import { useMemo } from 'react';
import styles from './NewFormConnectionActionsV3.module.css';
import { Status, useUser } from 'utils/hooks';
import { useToggleSwitch } from 'context/toggleSwitch/ToggleSwitchContext';
import { useModalCredentialsContext } from 'context/ModalCredentials/ModalCredentials-context';
import { credentialData } from '../schema/formShemaModel';
import { handleSwitch } from '../../../NewCredentialCards.helpers';
import { DrugManufacturersId } from 'utils/interfaces';

const NewFormConnectionActionsV4 = ({ drugsManufacturerConnectionMethods = null }) => {
    const { isLoadingFile } = useToggleSwitch();
    const {
        setVisibilityForm,
        statusApiCreateCredential,
        drugConnection,
        finishConnection,
        getDrugInformationById,
        handlerAlertBeforeDelete,
        handlerFormSubmit,
        isEditing,
        isLoading,
        isSaveSuccess,
        formValue
    } = useModalCredentialsContext();

    const { hasAuthorization } = useUser({});

    const {
        credentialTypes,
        missing_format,
        hasSomeError,
        has_wrong_username_password,
        drugmanufacturerId,
    } = getDrugInformationById();

    const handleFunction = () => {
        setVisibilityForm(false);
        return;
    };

    const labelButtonText: { initialText: string; loadingText: string } = useMemo(() => {
        if (isEditing) {
            return {
                initialText: t(`offlineDrugManufacturer.button.save`),
                loadingText: t('credentialplaceholder_five'),
            };
        } else {
            return {
                initialText: t('settings.pointOfSales.menuConnect'),
                loadingText: t('settings.pointOfSales.menuConnecting'),
            };
        }
    }, [isEditing]);

    const blockButtonWhenSearchDirections = !formValue?.client_identifier && DrugManufacturersId.CruzVerde === drugmanufacturerId
    
    const validation = EX6778 ? !hasSomeError && isEditing : hasSomeError || isEditing;

    return (
        <div
            className={
                !hasAuthorization
                    ? styles['form-button-actions-container-client']
                    : styles['form-button-actions-container-admin']
            }
        >
            <ExButton
                size="lg"
                fullWidth
                loading={!!finishConnection ? false : isLoading}
                loadingText={labelButtonText.loadingText}
                onClick={handlerFormSubmit}
                status={!!finishConnection ? 'warning' : !!isSaveSuccess ? 'success' : 'initial'}
                type="submit"
                withArrow={!isSaveSuccess}
                className={isEditing ? 'button__save-edition-credentials' : 'button__connect-credentials'}
                disabled={isLoadingFile || blockButtonWhenSearchDirections}
            >
                {labelButtonText.initialText}
            </ExButton>

            {(validation) && (
                <ExButton
                    size="lg"
                    fullWidth
                    appearance="critical"
                    onClick={() =>
                        handlerAlertBeforeDelete(
                            credentialData?.credentialId,
                            handleSwitch(credentialData?.nameType, credentialTypes),
                            credentialData?.drugName,
                            drugConnection?.id,
                        )
                    }
                >
                    {t('settings.pointOfSales.menuDisconnect')}
                </ExButton>
            )}

            {!isEditing && !hasSomeError && drugmanufacturerId === DrugManufacturersId.DrogueriasDelSud && (
                <div className={styles['form-button-back-container']}>
                    <ExButton appearance="link" status="initial" disabled={isLoading} onClick={handleFunction}>
                        {t('pointOfSale.labelStepBack')}
                    </ExButton>
                </div>
            )}
        </div>
    );
};

export default NewFormConnectionActionsV4;
