import { memo, useEffect } from 'react';
// CONTEXTS
import { useCredentialErrors } from 'context/CredentialsErrors/CredentialsErrors';
import { useProductsContext, useScrollFooterContext, useUnitsContext } from 'context';
import { useExpandedFooterContext } from 'context/productComparison/expanded-footer';
// COMPONENTS
import { Cell } from './Cell';
// UTILS && HOOKS
import { setterUnitsByDrugManufacturers } from '../../utils/setterUnits';
// STYLES
import grid from '../../../Grid.module.css';
import styles from '../TableTotals.module.css';

export interface EstimatedSavingsProps {
    youCanSave: number;
    youAreSaving: number;
}

const CellDrugManufacturers = ({
    drugManufacturer,
    userLocaleCountryCode,
    skeleton,
    drugManufacturerEnabled,
    cdo_is_visible,
    cdo_is_enabled,
    cdo_status_id,
    user,
    addMinimunPurchase,
    removeMinimunPurchase,
}) => {
    const { expandedFooter } = useExpandedFooterContext();
    const { footerRef, handleFooterScroll } = useScrollFooterContext();
    const { products } = useProductsContext();
    const { setUnits } = useUnitsContext();
    const { extractedDataError } = useCredentialErrors();

    const hasDrugManufacturerError = (id: number) => {
        const extractedDataErrorIds = extractedDataError?.map((item: any) => item.id);
        return extractedDataErrorIds?.includes(id);
    };

    useEffect(() => {
        if (!products) {
            return;
        }
        setterUnitsByDrugManufacturers(products, setUnits);
    }, [products]);

    return (
        <div
            ref={footerRef}
            onScroll={handleFooterScroll}
            className={`${cdo_is_visible ? styles['middle'] : styles['middle-without-cdo']} ${
                grid['grid-column-drugManufacturers']
            }`}
            style={{
                overflowX: 'auto',
                overflowY: 'hidden',
            }}
        >
            {drugManufacturer.map(
                ({ id: drugManufacturerId, min_purchase, type_id, vendor_accepted, enabled }, idx) => {
                    return (
                        <Cell
                            key={drugManufacturerId}
                            drugManufacturerId={drugManufacturerId}
                            user={user}
                            userLocaleCountryCode={userLocaleCountryCode}
                            addMinimunPurchase={addMinimunPurchase}
                            removeMinimunPurchase={removeMinimunPurchase}
                            min_purchase={min_purchase}
                            expandedFooter={expandedFooter}
                            skeleton={skeleton}
                            cdo_is_enabled={cdo_is_enabled}
                            cdo_status_id={cdo_status_id}
                            drugManufacturer={drugManufacturer}
                            drugManufacturerEnabled={drugManufacturerEnabled}
                            hasDrugManufacturerError={hasDrugManufacturerError(drugManufacturerId)}
                            drugManufacturerTypeId={type_id}
                            vendor_accepted={vendor_accepted}
                        />
                    );
                },
            )}
        </div>
    );
};

export default memo(CellDrugManufacturers);
