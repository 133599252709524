import { COUNTRY } from 'utils/country';

export function splitFormattedNumber(
    amount: string,
    userLocaleCountryCode: string,
): { symbol: string; integer: string; decimal: string; tax: string } {
    try {
        const customPrice = amount.split(',');
        let useSymbol = '$ ';
        let labelTax = '+ IVA';
        switch (userLocaleCountryCode) {
            case COUNTRY.AR:
            case COUNTRY.CO:
            case COUNTRY.MX:
                break;
            case COUNTRY.BR:
                useSymbol = 'R$ ';
                labelTax = 'c/imp.';
                break;
            case COUNTRY.ES:
                useSymbol = ' €';
                break;
            case COUNTRY.US:
                useSymbol = '$ ';
                labelTax = '+ tax';
                break;
        }
        return {
            symbol: useSymbol,
            integer: customPrice[0],
            decimal: customPrice[1],
            tax: labelTax,
        };
    } catch (e) {
        console.error(e);
    }
}
