export const auxiliarUtils = () => {
    const handleToogle = (idx, setShowChildren) => {

        setShowChildren((prevState) => ({
            ...prevState,
            [idx]: !prevState[idx],
        }));
    };

    return {
        handleToogle,
    };
};