import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  containerModalDiscounts: {
    "& div div.MuiPaper-rounded": {
      borderRadius: 8,
    },
  },
  containerModalMultiple: {
    "& div div.MuiPaper-rounded": {
      borderRadius: 8,
      width: 364,
      maxWidth: "100%",
    },
  },
  button: {
    color: "#0171E6",
    fontFamily: "Source Sans Pro",
    fontSize: 12,
    fontWeight: 600,
    height: 24,
    padding: "5px 12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: 7,
    background: "white",
    borderRadius: 8,
    transition: "0.3s all",
    "&:hover": {
      background: "#DCEDFF",
    },
    "&:active": {
      background: "#BADBFF",
    },
  },
  content: {
    display: "flex",
    padding: "24px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    gap: "12px",
  },
  sectionIcons: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    width: "100%",
  },
  closeIcon: {
    width: "24px",
    height: "24px",
    cursor: "pointer",
  },
  infoIcon: {
    width: "45px",
    height: "45px",
  },
  firstSection: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    alignSelf: "stretch",
    gap: "8px",
    height: 139,
  },
  titleModal: {
    margin: 0,
    color: "#333",
    fontFamily: "Source Sans Pro",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "24px",
  },
  nameTitleModal: {
    color: "#40435B",
    fontFamily: "Source Sans Pro",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 600,
  },
  subtitleModal: {
    margin: 0,
    color: "#6B6F93",
    fontFamily: "Source Sans Pro",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "18px",
  },
  secondSection: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    width: "100%",
    "& > div": {
      display: "flex",
      alignItems: "center",
      width: "100%",
      height: 44,
      paddingTop: 8,
      paddingBottom: 8,
      gap: "12px",
      borderBottom: "0.5px solid #E0E1E9",
      "& > p": {
        margin: 0,
        color: "#333",
        fontFamily: "Source Sans Pro",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "28px",
        "& > span": {
          fontWeight: 600,
        },
      },
      "& > div": {
        display: "flex",
        width: "70px",
        height: "22px",
        padding: "2px 8px",
        alignItems: "flex-start",
        // gap: "4px",
        borderRadius: "4px",
        backgroundColor: "#FF6300",
        "& > p": {
          margin: 0,
          minWidth: 56,
          color: "#FFF",
          fontFamily: "Source Sans Pro",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: 700,
          lineHeight: "18px",
          "& > span": {
            fontWeight: 400,
          },
        },
      },
    },
  },
}));

export default useStyle;
