// next
import { useRouter } from 'next/router';
// components
import { RenderFooter, RenderMenuItem, RenderWithoutRecordset } from '.';
// interface
import { IAutoCompleteSearch } from './AutoCompleteSearch.interface';
// context
import { useNewSearchProduct } from 'context/openSearch/newSearchDataContext';
// hooks
import { useUser } from 'utils/hooks';
// rsuite
import SpinnerIcon from '@rsuite/icons/legacy/Spinner';
import { AutoComplete } from 'rsuite';
// utils
import { inputSearchRecordsetFormat } from './utils';
// mui
import SearchIcon from '@mui/icons-material/Search';
// style
import { RenderLastSearch } from 'commons/components/OpenSearchBanner/InputSearch';
import { useEffect, useState, useRef } from 'react';
import { handleKeyDownInputPicker } from 'utils/dataKeyPress';
import style from './AutoCompleteSearch.module.css';
import { UploaderButton } from '../InputSearch';
import { RenderWithoutRecordsetV2 } from './RenderWithoutRecordsetV2';
import { RenderMenuItemV2 } from './RenderMenuItemV2';

const AutoCompleteSearch = ({
    placeholder,
    recordset,
    onSearch,
    onClickAddProduct,
    valueSearch,
    existsProductInCart,
    getProductByBarcode,
    pointOfSaleId,
    handleUpdateProduct,
    isLoading,
    pagination,
    lastSearch,
    handleCleanInput,
    openDropdown,
    setOpenFocus,
    currentSearch,
    visibility,
    isExpanded,
    toggle,
    handlerSendWithEnter,
    productNameSelected,
    setProductNameSelected,
    selectedIndex,
    setSelectedIndex,
    setActiveSearches,
    activeShakeAnimation,
    onClickButtonByCode,
    onClose,
    isClickButtonByCode,
    setVisibleClear,
    onClickVisibleDragDrop,
    visibleClear,
}: IAutoCompleteSearch) => {
    const inputRef = useRef(null);
    const router = useRouter();
    const [firstSearch, setFirstSearch] = useState('');
    // context
    const { handleOpen, isOpen, updateLastSearch } = useNewSearchProduct();
    // hooks
    const { user } = useUser({});
    // recordset
    const data = inputSearchRecordsetFormat(recordset);
    // state resize width
    const [width, setWidth] = useState<number>(0);
    const [catalog, setCatalog] = useState([]);
    const [notCatalog, setNotCatalog] = useState([]);

    const isResults = router?.pathname === '/[clientId]/efficient-purchase/[posId]/search-results';

    const getSize = (): any => {
        return document.body.getElementsByClassName('rs-input');
    };

    useEffect(() => {
        setWidth(getSize()[0]?.offsetWidth);
    }, [visibility, isExpanded, openDropdown]);

    useEffect(() => {
        const element: any = document.body.getElementsByClassName('rs-picker-menu');
        if (element.length) {
            element[0].style.minWidth = `${width}px`;
        }
    }, [width, visibility, isExpanded]);

    if (typeof window !== 'undefined') {
        window.addEventListener('resize', () => {
            setWidth(getSize()[0]?.offsetWidth);
        });
    }

    const info = data && data.length > 0 ? data : lastSearch && lastSearch.length > 0 ? lastSearch : [];

    const onFooterClicked = (value: boolean) => {
        updateLastSearch(currentSearch);
        handleOpen(value);
    };

    const onAddProduct = (evt, item) => {
        updateLastSearch(currentSearch);
        onClickAddProduct(evt, item);
    };
    useEffect(() => {
        if (isClickButtonByCode) {
            const searchInput: any = document.querySelector('.rs-input');
            if (searchInput && inputRef.current) {
                inputRef.current.close();
                searchInput.focus();
                handleOpen(false);
            }
        }
    }, [isClickButtonByCode]);

    useEffect(() => {
        const catalogItems = [];
        const notCatalogItems = [];

        data.forEach((item: any) => {
            if (item._source.description) {
                catalogItems.push(item);
            } else {
                notCatalogItems.push(item);
            }
        });

        setCatalog(catalogItems);
        setNotCatalog(notCatalogItems);
    }, [recordset]);

    useEffect(() => {
        if (isClickButtonByCode) {
            const searchInput: any = document.querySelector('.rs-input');
            if (searchInput && inputRef.current) {
                inputRef.current.close();
                searchInput.value = '';
                searchInput.focus();
                handleOpen(false);
                handleCleanInput();
            }
        }
    }, [isClickButtonByCode]);
    return (
        <div
            className={`${style['container-input']} ${openDropdown() ? style['border-custom'] : ''} ${
                activeShakeAnimation && style['shake-horizontal']
            }`}
            style={{ zIndex: isOpen ? 2000 : 5, position: 'sticky', top: 0 }}
        >
            <AutoComplete
                ref={inputRef}
                id="input-open-search-results"
                autoComplete="off"
                className={style['autocomplete']}
                data={data}
                placeholder={placeholder}
                value={valueSearch}
                onChange={(e) => {
                    if (!firstSearch && valueSearch) {
                        setFirstSearch(valueSearch);
                    }
                    onSearch(e);
                }}
                open={openDropdown()}
                onFocus={() => {
                    setOpenFocus(true);
                }}
                onBlur={() => {
                    setOpenFocus(false);
                }}
                onEntering={() => {
                    setOpenFocus(true);
                }}
                onExiting={() => handleOpen(false)}
                onClose={onClose}
                renderMenu={(menu: any) => {
                    if (isLoading && valueSearch.length > 0) {
                        return (
                            <div className={style['container-loading']}>
                                <SpinnerIcon spin className={style['loading-icon']} />
                            </div>
                        );
                    } else {
                        if (lastSearch && valueSearch === '') {
                            return lastSearch?.map((item, index) => {
                                return (
                                    <RenderLastSearch
                                        key={`input_search_${index}`}
                                        productName={item}
                                        handleOpen={handleOpen}
                                        clientId={user?.client?.id}
                                        pointOfSale={pointOfSaleId}
                                        onKeyDown={(evt) => {
                                            handlerSendWithEnter(user, evt);
                                        }}
                                        selectedIndex={selectedIndex}
                                        index={index}
                                        setProductNameSelected={setProductNameSelected}
                                    />
                                );
                            });
                        } else if (data && data.length > 0) {
                            return (
                                <>
                                    {catalog.length === 0 && notCatalog.length > 0 && (
                                        <div style={{ margin: 16 }}>
                                            <RenderMenuItemV2
                                                onClickAddProductCart={onAddProduct}
                                                existsProductInCart={existsProductInCart}
                                                pointOfSaleId={pointOfSaleId}
                                                handleUpdateProduct={handleUpdateProduct}
                                                onKeyDown={(evt) =>
                                                    handleKeyDownInputPicker(
                                                        evt,
                                                        setSelectedIndex,
                                                        recordset,
                                                        router,
                                                        '',
                                                        pointOfSaleId,
                                                        recordset,
                                                        'listResults',
                                                        setActiveSearches,
                                                    )
                                                }
                                                selectedIndex={selectedIndex}
                                                //parentRef={listElementRef}
                                                recordsetNotCatalog={notCatalog}
                                                recordsetCatalog={catalog}
                                                getProductByBarcode={getProductByBarcode}
                                                pagination={pagination?.total}
                                                clientId={user?.client?.id}
                                                valueSearch={valueSearch}
                                                handleOpen={onFooterClicked}
                                                pointOfSale={pointOfSaleId}
                                            />
                                        </div>
                                    )}
                                    {catalog.length > 0 && notCatalog.length > 0 && (
                                        <div style={{ margin: 16 }}>
                                            <RenderMenuItemV2
                                                onClickAddProductCart={onAddProduct}
                                                existsProductInCart={existsProductInCart}
                                                pointOfSaleId={pointOfSaleId}
                                                handleUpdateProduct={handleUpdateProduct}
                                                onKeyDown={(evt) =>
                                                    handleKeyDownInputPicker(
                                                        evt,
                                                        setSelectedIndex,
                                                        recordset,
                                                        router,
                                                        '',
                                                        pointOfSaleId,
                                                        recordset,
                                                        'listResults',
                                                        setActiveSearches,
                                                    )
                                                }
                                                selectedIndex={selectedIndex}
                                                //parentRef={listElementRef}
                                                recordsetNotCatalog={notCatalog}
                                                recordsetCatalog={catalog}
                                                getProductByBarcode={getProductByBarcode}
                                                pagination={pagination?.total}
                                                clientId={user?.client?.id}
                                                valueSearch={valueSearch}
                                                handleOpen={onFooterClicked}
                                                pointOfSale={pointOfSaleId}
                                            />
                                        </div>
                                    )}
                                    {catalog.length > 0 &&
                                        notCatalog.length === 0 &&
                                        data?.slice(0, 5).map((item, index) => {
                                            return (
                                                <RenderMenuItem
                                                    label={item.label}
                                                    item={item}
                                                    onClickAddProduct={(evt) => onAddProduct(evt, item)}
                                                    existsProductInCart={existsProductInCart}
                                                    quantity={getProductByBarcode(item.value)?.quantity}
                                                    cartId={getProductByBarcode(item.value)?.id}
                                                    pointOfSaleId={pointOfSaleId}
                                                    handleUpdateProduct={handleUpdateProduct}
                                                    valueSearch={currentSearch}
                                                    visibility={visibility}
                                                    toggle={toggle}
                                                    selectedIndex={selectedIndex}
                                                    index={index}
                                                />
                                            );
                                        })}
                                    {catalog.length > 0 && (
                                        <RenderFooter
                                            values={pagination?.total}
                                            clientId={user?.client?.id}
                                            valueSearch={valueSearch}
                                            handleOpen={onFooterClicked}
                                            pointOfSale={pointOfSaleId}
                                        />
                                    )}
                                </>
                            );
                        } else if (data && data?.length === 0) {
                            //@ts-ignore
                            return currentSearch && currentSearch.length >= 3 ? (
                                <RenderWithoutRecordsetV2 onClickButtonByCode={onClickButtonByCode} />
                            ) : (
                                <div className={style['container-loading']}>
                                    <SpinnerIcon spin className={style['loading-icon']} />
                                </div>
                            );
                        }
                    }
                }}
                menuStyle={{ padding: '16px 0px' }}
                onKeyDown={(evt) => {
                    if (evt.key === 'Enter') {
                        if (!valueSearch.trim()) {
                            return; // Si valueSearch está vacío o solo contiene espacios, no hacer nada.
                        }
                        handlerSendWithEnter(user, evt);
                        setFirstSearch(valueSearch);
                    }
                    if (
                        (evt.key === 'Enter' && valueSearch.trim()) ||
                        evt.key === 'ArrowUp' ||
                        evt.key === 'ArrowDown'
                    ) {
                        handleKeyDownInputPicker(
                            evt,
                            setSelectedIndex,
                            info,
                            router,
                            user,
                            pointOfSaleId,
                            valueSearch || productNameSelected,
                            'searchList',
                            setActiveSearches,
                        );
                    }
                }}
            />
            <div className={style['autocomplete-icon-search']}>
                <SearchIcon className={style['icon']} />
            </div>
            <UploaderButton
                onClickVisibleDragDrop={isResults ? false : onClickVisibleDragDrop}
                isOpen={isOpen}
                visibleClear={isResults ? true : visibleClear}
                onClickClose={handleCleanInput}
                setVisibleClear={isResults ? () => {} : setVisibleClear}
            />
        </div>
    );
};

export default AutoCompleteSearch;
