import { memo } from 'react';
import { t } from 'i18next';
// components
import { ExButton } from '@commons/EXComponentsLibrary';
// interfaces
import { ICancelButtonProps } from './CancelButton.interface';
// styles
import styles from './CancelButton.module.css';

const CancelButton = ({ hasError, multiplyErrors, handleCancelModal }: ICancelButtonProps) => {
    return (
        <ExButton
            type="button"
            status="initial"
            fullWidth
            size="md"
            loading={false}
            className={`${styles['button']} ${
                !hasError
                    ? styles['no-errors']
                    : multiplyErrors
                    ? styles['with-errors-multiply']
                    : styles['with-errors']
            }`}
            appearance={'link'}
            onClick={() => handleCancelModal()}
            disabled={false}
        >
            {t('credentialplaceholder_three')}
        </ExButton>
    );
};

export default memo(CancelButton);
