import { t } from "i18next";

export const getInfoButtons = (errors) => {
    return [
        {
            id: "1",
            label: t('home.tabs.available'),
        },
        {
            id: "2",
            label: t('home.tabs.connected'),
            errorsCount:  errors
        },
    ];
};
