import { memo } from 'react';
import { t } from 'i18next';
// components
import { AddButton } from './AddButton';
// styles
import styles from './AddOfflineMessage.module.css';

const AddOfflineMessage = () => {
    return (
        <div className={styles['container']}>
            <div>
                <p>{t('offlineDrugManufacturer.title.drugmanufacturerOffline')}</p>
                <p>{t('home.tabs.addDrugmanufacturer')}</p>
            </div>
            <AddButton />
        </div>
    );
};

export default memo(AddOfflineMessage);
