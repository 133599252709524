export const LockIcon = (): JSX.Element => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
                d="M6 22C5.45 22 4.97917 21.8042 4.5875 21.4125C4.19583 21.0208 4 20.55 4 20V10C4 9.45 4.19583 8.97917 4.5875 8.5875C4.97917 8.19583 5.45 8 6 8H7V6C7 4.61667 7.4875 3.4375 8.4625 2.4625C9.4375 1.4875 10.6167 1 12 1C13.3833 1 14.5625 1.4875 15.5375 2.4625C16.5125 3.4375 17 4.61667 17 6V8H18C18.55 8 19.0208 8.19583 19.4125 8.5875C19.8042 8.97917 20 9.45 20 10V20C20 20.55 19.8042 21.0208 19.4125 21.4125C19.0208 21.8042 18.55 22 18 22H6ZM6 20H18V10H6V20ZM12 17C12.55 17 13.0208 16.8042 13.4125 16.4125C13.8042 16.0208 14 15.55 14 15C14 14.45 13.8042 13.9792 13.4125 13.5875C13.0208 13.1958 12.55 13 12 13C11.45 13 10.9792 13.1958 10.5875 13.5875C10.1958 13.9792 10 14.45 10 15C10 15.55 10.1958 16.0208 10.5875 16.4125C10.9792 16.8042 11.45 17 12 17ZM9 8H15V6C15 5.16667 14.7083 4.45833 14.125 3.875C13.5417 3.29167 12.8333 3 12 3C11.1667 3 10.4583 3.29167 9.875 3.875C9.29167 4.45833 9 5.16667 9 6V8Z"
                fill="#ACAEC4"
            />
        </svg>
    );
};

export const LockIconSvg = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
            <path
                d="M12 44C10.9 44 9.95833 43.6083 9.175 42.825C8.39167 42.0417 8 41.1 8 40V20C8 18.9 8.39167 17.9583 9.175 17.175C9.95833 16.3917 10.9 16 12 16H14V12C14 9.23333 14.975 6.875 16.925 4.925C18.875 2.975 21.2333 2 24 2C26.7667 2 29.125 2.975 31.075 4.925C33.025 6.875 34 9.23333 34 12V16H36C37.1 16 38.0417 16.3917 38.825 17.175C39.6083 17.9583 40 18.9 40 20V40C40 41.1 39.6083 42.0417 38.825 42.825C38.0417 43.6083 37.1 44 36 44H12ZM12 40H36V20H12V40ZM24 34C25.1 34 26.0417 33.6083 26.825 32.825C27.6083 32.0417 28 31.1 28 30C28 28.9 27.6083 27.9583 26.825 27.175C26.0417 26.3917 25.1 26 24 26C22.9 26 21.9583 26.3917 21.175 27.175C20.3917 27.9583 20 28.9 20 30C20 31.1 20.3917 32.0417 21.175 32.825C21.9583 33.6083 22.9 34 24 34ZM18 16H30V12C30 10.3333 29.4167 8.91667 28.25 7.75C27.0833 6.58333 25.6667 6 24 6C22.3333 6 20.9167 6.58333 19.75 7.75C18.5833 8.91667 18 10.3333 18 12V16Z"
                fill="#0171E6"
            />
        </svg>
    );
};
