export const inputSearchRecordsetFormat = (recordset) => {
  return recordset.map(({ _source, _id }) => {
    return {
      label: _source.description,
      value: _id,
      _source:{
        ean:[ _source.ean[0]],
        description:_source.description
      },
      laboratory: _source.laboratory,
      drug: _source.drug,
    };
  });
}