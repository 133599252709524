import subDays from "date-fns/subDays";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import addDays from "date-fns/addDays";
import startOfMonth from "date-fns/startOfMonth";
import { format } from "date-fns";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { useTranslation } from 'react-i18next';
import { EX5057 } from 'config/flags';

type DateRange = [Date, Date];
type RangeType = {
    label: string;
    value: DateRange | ((value?: any) => DateRange);
    placement?: 'left' | 'bottom' | undefined;
    closeOverlay?: boolean;
    onClick?: (e: any) => void;
    className?: string;
};

export const predefinedRanges = (): RangeType[] => {
  const { t } = useTranslation();
    return [
        {
            label: !!EX5057 ? t('myOrders.lastSevenDays') : 'Últimos 7 días',
            closeOverlay: false,
            value: [subDays(new Date(), 6), new Date()],
            placement: 'left',
            className: 'shorcut__last-7-days-1',
        },
        {
            label: !!EX5057 ? t('myOrders.thisWeek') : 'Semana Actual',
            closeOverlay: false,
            value: [startOfWeek(new Date()), endOfWeek(new Date())],
            placement: 'left',
            className: 'shorcut__current-week-1',
        },
        {
            label: !!EX5057 ? t('myOrders.lastWeek') : 'Semana pasada',
            closeOverlay: false,
            value: (value: any) => {
                const [start = new Date()] = value || [];
                return [
                    addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
                    addDays(endOfWeek(start, { weekStartsOn: 0 }), -7),
                ];
            },
            placement: 'left',
            className: 'shorcut__past-week-1',
        },
        {
            label: !!EX5057 ? t('myOrders.ThisMonth') : 'Mes actual',
            closeOverlay: false,
            value: [startOfMonth(new Date()), new Date()],
            placement: 'left',
            className: 'shorcut__current-month-1',
        },
        {
            label: !!EX5057 ? t('myOrders.lastMonth') : 'Mes pasado',
            closeOverlay: false,
            value: [subDays(new Date(), 29), new Date()],
            placement: 'left',
            className: 'shorcut__past-month-1',
        },
    ];
};


export const lastSevenDays = () => {
  const currentDay = new Date();
  return subDays(currentDay, 6);
};

export const formatUserSelectionDate = (originalDate) => {
  const currentDay = new Date(originalDate);
  return subDays(currentDay, -1);
};

export const locale = () => {

  const { t } = useTranslation();

  return {

    sunday: "Dom",
    monday: "Lun",
    tuesday: "Mar",
    wednesday: "Mié",
    thursday: "Jue",
    friday: "Vie",
    saturday: "Sáb",
    ok: !!EX5057 ? t('myOrders.labelApply') : "Aplicar",
  }
}


export const parseDate = (originalDate): any => {
  const dateInUTC = zonedTimeToUtc(
    originalDate,
    "America/Argentina/Buenos_Aires"
  );
  const dateParsed = utcToZonedTime(
    dateInUTC,
    "America/Argentina/Buenos_Aires"
  );
  return format(dateParsed, "yyyy-MM-dd");
};

function noMoreThanAYear(selectedDate) {
  const actualDate = new Date();

  const moreThanAYear = new Date();
  moreThanAYear.setFullYear(actualDate.getFullYear() - 1);

  return selectedDate < moreThanAYear;
}

export const shouldDisableDate = (date) => {
  const today = new Date();
  const tomorrow =new Date(today)
  tomorrow.setDate(today.getDate() + 1);

  const firstDayOfNextMonth = new Date(today);
  firstDayOfNextMonth.setMonth(today.getMonth() + 1, 1);
  return noMoreThanAYear(date) || date >= firstDayOfNextMonth || date >= tomorrow;
};

export const blockCleanable = (dateTerms) => {
  const date1: any = new Date(dateTerms.from);
  const date2: any = new Date();

  const diff = date2 - date1;

  const diffDays: any = diff / (1000 * 60 * 60 * 24);

  return parseInt(diffDays) !== 6;
};

export function equalsArray(firstArray, secondArray) {
  for (let i = 0; i < firstArray.length; i++) {
    if (firstArray[i] !== secondArray[i]) {
      return false;
    }
  }
  return true;
}

export const handleWeekDay = (selectedDate, actualDate) => {
  const startOfSelectDate = selectedDate[0].toString().split(" ").slice(0, 4);
  const startOfActualDate = actualDate[0].toString().split(" ").slice(0, 4);

  const endOfSelectDate = selectedDate[1].toString().split(" ").slice(0, 4);
  const endOfActualDate = actualDate[1].toString().split(" ").slice(0, 4);

  if (
    equalsArray(startOfActualDate, startOfSelectDate) &&
    equalsArray(endOfSelectDate, endOfActualDate)
  ) {
    localStorage.setItem("selectedFilter", "true");
  } else {
    localStorage.setItem("selectedFilter", "false");
  }
};
