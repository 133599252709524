import * as gtag from 'utils/gtag';

export const handleFinishOrder = ({
    fill,
    orderId,
    getProductSelected,
    minimunPurchase,
    handlerModalMinimunPurchase,
    handleResetVisibility,
    getProductQuantity,
    allProductHasChecked,
    skeleton,
    unselectedProductNotificationVisibility,
    run,
    uncheckedProductsByDrugNamesMinOrMax,
    handlerModalMinMaxQuantity,
}): void => {
    // If there are drugstores with a minimum purchase that do not comply
    if (minimunPurchase.length > 0) {
        handlerModalMinimunPurchase();
    } else if (uncheckedProductsByDrugNamesMinOrMax()?.length > 0) {
        handlerModalMinMaxQuantity();
    } else {
        handleResetVisibility();
        if (getProductQuantity <= 0 || !allProductHasChecked || skeleton) {
            unselectedProductNotificationVisibility();
            return;
        }
        gtag.event({
            action: 'click',
            category: 'efficient-purchase__product-comparison',
            label: 'button__make-order',
            value: 0,
        });
        run(fill(orderId, getProductSelected));
    }
};
