import React from 'react';
// STYLES
import styles from '../../RecommendedCard.module.css';
import style from './RecommendedCardPrice.module.css';
// HOOKS
import useCurrencyFormatter from 'utils/hooks/useCurrencyFormatter/useCurrencyFormatter';

export const RecommendedCardPrice = ({ recommended }) => {
    const {formatCurrency} = useCurrencyFormatter();

    return (
        <div className={style['container']}>
            <span className={styles['unit_price']}>
                {recommended?.unit_price && recommended?.unit_price !== null
                    ? formatCurrency({value: recommended.unit_price}).fullValue
                    : ''}
            </span>
            <span className={styles['unit_price_dto']}>
                {recommended?.unit_price_dto && recommended?.unit_price_dto !== null
                    ? formatCurrency({value: recommended.unit_price_dto}).fullValue
                    : ''}
            </span>
        </div>
    );
};
