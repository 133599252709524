import { isNullOrUndefined } from 'utils/strings/isNullOrUndefined';

export const unitsByDrugManufacturer = (t, units) => {
    if (isNullOrUndefined(units)) {
        return '';
    }
    return `${units} ${
        units === 1
            ? t('efficientPurchasing.comparative.labelFooterUnit')
            : t('efficientPurchasing.comparative.labelFooterUnits')
    }`;
};
