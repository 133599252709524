// Functions for interacting with the drug manufacturers' information API
import { getDrugmanfacturersInformation, patchDrugManufacturersInformation } from 'api/backoffice/backoffice';

// Interface for the state used by the functions
import { IState } from '../Suppliers.interface';

/**
 * Helper function containing operations related to drug manufacturers' information management.
 * Returns functions for fetching and updating data, and handling input changes.
 */
export const auxiliary = () => {
    /**
     * Fetches drug manufacturer information from the API and updates the local state.
     * @param setState Function to update the local state.
     * @param clientId Client ID for which information is fetched.
     */
    const fetchInformationOfDrugmanufacturer = async (setState, clientId: string) => {
        try {
            setState((prevState) => ({ ...prevState, isLoading: true }));
            const response = await getDrugmanfacturersInformation(clientId);
            setState((prevState) => ({
                ...prevState,
                drugManufacturer: response?.data,
                country: response?.data?.country_alfa_2 || '',
                minimumPurchaseAmount: response?.data?.minimum_purchase_amount || null,
                form: response?.data || {},
                isLoading: false,
                rehydrateData:false,
            }));
        } catch (ex: any) {
            setState((prevState) => ({ ...prevState, isLoading: false }));
            console.log(ex.message);
        }
        return;
    };

    /**
     * Updates drug manufacturer information using the API.
     * @param setState Function to update the local state.
     * @param clientId Client ID for which information is updated.
     * @param state Updated state with modified fields to send.
     */
    const updateInformationOfDrugmanufacturer = async (setState, clientId: string, state: IState, toaster) => {
        const modifiedFields = { ...state.modifiedFields };

        if (modifiedFields.commercial_expiration_date) {
            const [day, month, year] = modifiedFields.commercial_expiration_date.split('/');
            const date = new Date(`${year}-${month}-${day}`);
            modifiedFields.commercial_expiration_date = date.toISOString().split('T')[0];
        }

        try {
            /* if (!formRef.current.check()) {
                return;
            } */
            setState((prevState) => ({ ...prevState, isLoadingEdit: true }));
            toaster.showToaster({
                message: { title: 'Guardaste los cambios' },
                duration: 5000,
                type: 'success',
            });
            await patchDrugManufacturersInformation(clientId, modifiedFields);
            setState((prevState) => ({ ...prevState, modifiedFields: {}, isLoadingEdit: false }));
        } catch (ex: any) {
            toaster.showToaster({
                message: { title: 'No se guardaron los cambios correctamente' },
                duration: 5000,
                type: 'error',
            });
            setState((prevState) => ({ ...prevState, modifiedFields: {}, isLoadingEdit: false }));
            console.error(ex.message);
        }

        return;
    };

    /**
     * Handles input change in the form, updating the local state.
     * @param value New value of the input.
     * @param key Key of the field being modified.
     * @param setState Function to update the local state.
     */
    const handleInputChange = (value: any, key: string, setState) => {
        setState((prevState) => ({
            ...prevState,
            form: { ...prevState.form, [key]: value },
            modifiedFields: { ...prevState.modifiedFields, [key]: value },
        }));
    };

    /**
     * Handles the selection of an address in the form, updating the local state.
     * @param value New address value.
     * @param setState Function to update the local state.
     */
    const handleSelectAddress = (value: string, setState) => {
        setState((prevState) => ({
            ...prevState,
            form: { ...prevState.form, warehouse_address: value },
            modifiedFields: { ...prevState.modifiedFields, warehouse_address: value },
        }));
    };

    /**
     * Checks if any field in the form has been modified.
     * @param state Current state of the form.
     * @param setState Function to update the local state.
     * @param setModified Function to set the modified state.
     */
    const hasModifiedAnyField = (state, setState, setModified) => {
        const hasModifiedFields = Object.values(state.modifiedFields).some((field) => field || field === '');
        setState((prevState) => ({ ...prevState, isFormModified: hasModifiedFields }));
        setModified(hasModifiedFields);
    };

    // Returns functions for external use
    return {
        fetchInformationOfDrugmanufacturer,
        updateInformationOfDrugmanufacturer,
        handleInputChange,
        handleSelectAddress,
        hasModifiedAnyField,
    };
};
