// owner css
import style from './PaymentFooter.module.css';

const PaymentFooter = () => {
    return (
        <div className={style['footer-container']}>
            <div className={style['footer-content']}>
                <p className={style['footer-text']}>Av. Paseo de la Reforma 333, Cuauhtémoc,CDMX.</p>
                <p className={style['footer-text']}>|</p>
                <p className={style['footer-text']}>Copyright © 2025 Extendeal MX SA de CV</p>
            </div>
        </div>
    );
};

export default PaymentFooter;
