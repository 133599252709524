import { postSendEmailVendors } from 'api/vendors/vendors';

export const axiliaryFunctionsVendors = () => {
    const handlerSubmitSendEmailVendors = async (drugManufacturerId, posId, setIsRequiredVendor,setIsLoading) => {
        try {
            setIsLoading(true)
            const response = await postSendEmailVendors(drugManufacturerId, posId);
            if( response?.status === 200) {

                localStorage.setItem('isRequiredVendor','true')
                setIsLoading(false)
                setIsRequiredVendor(true);
            }
            return response;
        } catch (ex) {
            setIsLoading(false)
            setIsRequiredVendor(false);
            console.error(ex.message);
        }
    };

    return {
        handlerSubmitSendEmailVendors,
    };
};
