// Import the main component
import { Viewer } from '@react-pdf-viewer/core';

// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';

import handleError from 'utils/pdfViewer/handleError';

const PrivacyPolicyMX = () => {
    return <Viewer fileUrl={'/pdf/privacyPolicy/mx/privacyPolicyMX.pdf'} renderError={handleError} />;
};

export default PrivacyPolicyMX;
