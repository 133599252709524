export const auxiliary = () => {

    const preventRedirect = ( link, router, show, setState) => {
        show({
            className: '',
            title: 'Descartar cliente',
            cancel: true,
            size: 'xs',
            body: 'Tenés cambios sin guardar ¿Querés descartarlos?',
            action: 'Si, descartar',
            onConfirm: () => {
                setState((prevState) => ({ ...prevState, rehydrateData: true }));
            },
            onCancel: () => {},
        });
    };

    return {
        preventRedirect
    }
}