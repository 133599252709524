import React from 'react';
// COMPONENTS
import { Placeholder } from 'rsuite';
// INTERFACES
import { RadioProps } from './Price.interface';
// UTILS
import { showCaretUp } from '../../../../utils';
// STYLES
import cellStyles from '../../../../CellStyles.module.css';
import { PriceValue } from '../../../../PriceValue';

const Price = ({
    drugManufacturerEnabled,
    skeleton,
    hasDrugManufacturerError,
    product,
    checked,
    isLowest,
    chipColor,
    userLocale,
    unitPrice,
}: RadioProps): JSX.Element => {
    return (
        <div
            className={`${cellStyles['price']} ${
                !drugManufacturerEnabled && !checked && !skeleton ? cellStyles['drugManufacturerDisabled'] : ''
            } ${ checked ? cellStyles['price-color-checked'] : ''}`}
        >
            {skeleton ? (
                <Placeholder.Paragraph rowHeight={16} rows={1} active className={cellStyles['placeholder-price']} />
            ) : (
                <>
                    {!hasDrugManufacturerError && (
                        <p
                            className={`${!product ? cellStyles['without-stock'] : ''} ${
                                showCaretUp({ checked, isLowest, product }) ? cellStyles['text-with-error'] : ''
                            }`}
                        >
                            {showCaretUp({ checked, isLowest, product }) && (
                                <img src="/assets/images/arrow_drop_up.svg" alt="arrow drop icon" />
                            )}
                            <PriceValue
                                product={product}
                                userLocale={userLocale}
                                chipColor={chipColor}
                                unitPrice={unitPrice}
                            />
                        </p>
                    )}
                    {hasDrugManufacturerError ? <p className={cellStyles['line']}>-</p> : ''}
                </>
            )}
        </div>
    );
};

export default Price;
