// components
import { ExButton } from '@commons/EXComponentsLibrary';
import { ExLoader } from '@commons/EXComponentsLibrary/ExLoader';
import { Icon } from './Icon';
// interfaces
import { IButtonFalProps } from './ButtonFal.interface';
// utils && hooks
import { COUNTRY } from 'utils/country';
// styles
import styles from './ButtonFal.module.css';

const ButtonFal = ({ disabled, gtagClass, loading, handleOnClick, text, country }: IButtonFalProps) => {
    return (
        <ExButton
            className={`${gtagClass} ${
                (country === COUNTRY.BR || country === COUNTRY.US) ? styles['button-fal-max'] : styles['button-fal']
            }`}
            disabled={disabled}
            size="md"
            type="button"
            appearance="outline"
            IconComponent={<Icon disabled={disabled} loading={loading} />}
            onClick={() => handleOnClick()}
        >
            {loading ? <ExLoader size="xs" /> : text}
        </ExButton>
    );
};

export default ButtonFal;
