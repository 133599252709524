import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  tolerance: {
    width: 244,
    cursor: "pointer",
    "& label.Mui-focused": {
      color: "#646464",
    },
    "& label": {
      color: "#646464",
    },
    "& .MuiInput-underline:after": {
      borderColor: "#ACACAC",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#ACACAC",
      },
      "&:hover fieldset": {
        borderColor: "#ACACAC",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#ACACAC",
        borderWidth: 0.5,
      },
      "& input:valid:focus + fieldset": {
        borderWidth: 0.5,
      },
    },
  },
  modalInfo: {
    "& .MuiList-padding": {
      paddintTop: 0,
      paddingRight: 8,
    },
  },
  InfoToleranceIcon: {
    color: "#0171E6",
    marginTop: 30,
    height: 24,
    width: 24,
    marginLeft: 22,
  },
  infoToleranceText: {
    margin: 0,
    padding: 0,
    color: "#333333",
    fontSize: 16,
    marginLeft: 16,
    fontFamily: "Source Sans Pro",
    fontWeight: 600,
    paddingTop: 16,
  },
  infoToleranceSubtext: {
    margin: 0,
    padding: 0,
    color: "#646464",
    fontSize: 14,
    fontFamily: "Source Sans Pro",
    marginLeft: 16,
    marginTop: 8,
    marginRight: 24,
  },
  rowButtonContainerTolerance: {
    display: "flex",
    marginTop: 16,
    width: "100%",
    justifyContent: "flex-end",
  },
  buttonTolerance: {
    color: "#646464",
    border: "1px solid #646464",
    padding: "8px 16px",
    marginLeft: 8,
    fontSize: 14,
  },
  openModalIcon: {
    alignItems: "center",
    height: 40,
    justifyContent: "center",
    display: "flex",
    marginLeft: 4,
    marginRight: 4,
  },
  containerTolerance: {
    display: "flex",
    alignItems: "center",
  },
});
