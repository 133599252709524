// COMPONENTS
import { RecommendedCellProduct } from './RecommendedCellProduct';
import { RecommendedCellPrice } from './RecommendedCellPrice';
import { RecommendedCellCdo } from './RecommendedCellCdo';
// UTILS
import { recommendedIdxByDrugManufacturerId } from '../utils';
import { CHIP_DISCOUNT_COLOR } from 'utils/enums/chipDiscount';
import { useRecommended } from './hooks';
// INTERFACES
import { DrugManufacturersId } from 'utils/interfaces';
// STYLES
import grid from '../Grid.module.css';
import styles from './Recommended.module.css';
import cellStyles from '../CellStyles.module.css';

const Recommended = () => {
    const {
        nextRecommendationClicked,
        fadeOutRecommended,
        SKELETON,
        RENDER_TABLE,
        WITHOUT_RECOMMENDATIONS,
        withoutSearchings,
        isLastRecommendation,
        recommendationsVisible,
        recommendationLimit,
        handleAddRecommendation,
        drugManufacturer,
        containerRef,
        CDO_IS_VISIBLE,
        recommendedRef,
        handleRecommendedScroll,
        showRecommendations,
        recommendations,
        currentIndexRecommendation,
        setShowRecommendations,
        isEnabledDrugManufacturer,
        CDO_DRUGMANUFACTURER,
        hasDrugManufacturerError,
        lastUnitPrice,
        unitPrice,
        user,
        nextRecommendation,
    } = useRecommended();

    return (
        <div
            className={`${grid['grid-system']} ${nextRecommendationClicked ? styles['shrink'] : ''} ${
                fadeOutRecommended ? styles['table-with-transition'] : ''
            } ${styles['row']} 
            ${
                !SKELETON &&
                !fadeOutRecommended &&
                RENDER_TABLE &&
                !nextRecommendationClicked &&
                !WITHOUT_RECOMMENDATIONS &&
                !withoutSearchings
                    ? !isLastRecommendation
                        ? styles['two-overlap']
                        : styles['one-overlap']
                    : ''
            }
            `}
        >
            <div style={{ display: 'grid', gridColumn: '1 / 2', height: 82 }}>
                <RecommendedCellProduct
                    recommendation={recommendationsVisible}
                    fadeOutRecommended={fadeOutRecommended}
                    recommendationLimit={recommendationLimit}
                    addRecommendation={handleAddRecommendation}
                    drugManufacturer={drugManufacturer}
                    skeleton={SKELETON}
                    containerRef={containerRef}
                />
            </div>
            <div
                className={`${
                    !recommendationLimit
                        ? CDO_IS_VISIBLE
                            ? styles['middle']
                            : styles['middle-without-cdo']
                        : styles['middle-limit-recommendations']
                } ${
                    recommendationLimit
                        ? styles['container-drugmanufacturers-limit']
                        : styles['container-drugmanufacturers']
                } ${grid['grid-column-drugManufacturers']}`}
                ref={recommendedRef}
                onScroll={handleRecommendedScroll}
            >
                {drugManufacturer.map(({ id: drugManufacturerId, enabled, name }, idx: number) => {
                    return (
                        <div key={idx + 1} className={`${cellStyles['cell-price-container']} ${styles['cell']}`}>
                            {showRecommendations &&
                                (drugManufacturerId === DrugManufacturersId.CentralDeOfertas ? (
                                    <RecommendedCellCdo
                                        product={recommendedIdxByDrugManufacturerId(
                                            recommendations,
                                            currentIndexRecommendation,
                                            DrugManufacturersId.CentralDeOfertas,
                                        )}
                                        productPVP={
                                            recommendedIdxByDrugManufacturerId(
                                                recommendations,
                                                currentIndexRecommendation,
                                                DrugManufacturersId.CentralDeOfertas,
                                            )?.pvpPercentage
                                        }
                                        recommendationLimit={recommendationLimit}
                                        setShowRecommendations={() => setShowRecommendations(false)}
                                        fadeOutRecommended={fadeOutRecommended}
                                        skeleton={SKELETON}
                                        chipColor={CHIP_DISCOUNT_COLOR.ORANGE}
                                        drugManufacturerEnabled={isEnabledDrugManufacturer(CDO_DRUGMANUFACTURER?.id)}
                                        drugManufacturerName={CDO_DRUGMANUFACTURER?.name}
                                        hasDrugManufacturerError={hasDrugManufacturerError(CDO_DRUGMANUFACTURER?.id)}
                                        drugManufacturerLength={drugManufacturer?.length / 2 === 0}
                                        drugManufacturerId={drugManufacturerId}
                                        user={user}
                                        cdo_is_visible={CDO_IS_VISIBLE}
                                        isLastCell={drugManufacturer?.length - 1 === idx}
                                        lastUnitPrice={lastUnitPrice(DrugManufacturersId.CentralDeOfertas)}
                                        unitPrice={unitPrice(DrugManufacturersId.CentralDeOfertas)}
                                        isRecommended={true}
                                    />
                                ) : (
                                    <RecommendedCellPrice
                                        isLastCell={drugManufacturer?.length - 1 === idx}
                                        product={recommendedIdxByDrugManufacturerId(
                                            recommendations,
                                            currentIndexRecommendation,
                                            drugManufacturerId,
                                        )}
                                        productPVP={
                                            recommendedIdxByDrugManufacturerId(
                                                recommendations,
                                                currentIndexRecommendation,
                                                drugManufacturerId,
                                            )?.pvpPercentage
                                        }
                                        fadeOutRecommended={fadeOutRecommended}
                                        recommendationLimit={recommendationLimit}
                                        skeleton={SKELETON}
                                        chipColor={CHIP_DISCOUNT_COLOR.ORANGE}
                                        cdo_is_visible={CDO_IS_VISIBLE}
                                        setShowRecommendations={() => setShowRecommendations(false)}
                                        drugManufacturerEnabled={isEnabledDrugManufacturer(drugManufacturerId)}
                                        drugManufacturerName={name}
                                        hasDrugManufacturerError={hasDrugManufacturerError(drugManufacturerId)}
                                        drugManufacturerId={drugManufacturerId}
                                        user={user}
                                        lastUnitPrice={lastUnitPrice(drugManufacturerId)}
                                        unitPrice={unitPrice(drugManufacturerId)}
                                        isRecommended={true}
                                    />
                                ))}
                        </div>
                    );
                })}
            </div>
            {/* ARROW RECOMMENDED */}
            {!WITHOUT_RECOMMENDATIONS &&
                !nextRecommendationClicked &&
                !fadeOutRecommended &&
                !SKELETON &&
                !withoutSearchings && (
                    <button className={`${styles['arrow']}`} onClick={nextRecommendation} disabled={SKELETON}>
                        <img
                            src="/assets/images/arrow_forward.svg"
                            width={16}
                            height={16}
                            color="#fff"
                            alt="arrow icon"
                        />
                    </button>
                )}
        </div>
    );
};

export default Recommended;
