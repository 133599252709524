// next
import { useRouter } from 'next/router';
// react
import { useEffect, useState, useRef } from 'react';
// components
import {
    LineDivider,
    UploaderButton,
    CloseButtonIcon,
    RenderFooter,
    RenderMenuItem,
    RenderWithoutRecordset,
    RenderLastSearch,
} from '.';
// interface
import { IInputSearch } from './InputSearch.interface';
// hooks
import { useUser } from 'utils/hooks';
import { useStatusContext } from 'context/control-state';
// utils
import { inputSearchRecordsetFormat } from './utils';
import { handleKeyDownInputPicker } from 'utils/dataKeyPress';
// rsuite
import { InputPicker } from 'rsuite';
import SpinnerIcon from '@rsuite/icons/legacy/Spinner';
// mui
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
// style
import style from './InputSearch.module.css';
import { RenderWithoutRecordsetV2 } from '../AutoCompleteSearch/RenderWithoutRecordsetV2';

const InputSearch = ({
    placeholder,
    recordset,
    onClickVisibleDragDrop,
    cleanable,
    caretAs,
    onSearch,
    onClickAddProduct,
    open,
    onClickClose,
    onOpen,
    onCleanInput,
    value,
    valueSearch,
    existsProductInCart,
    getProductByBarcode,
    pointOfSaleId,
    handleUpdateProduct,
    isLoading,
    pagination,
    handleOpen,
    isOpen,
    lastSearch,
    visibleClear,
    onKeyDown,
    visibility,
    toggle,
    isExpanded,
    productNameSelected,
    setProductNameSelected,
    className,
    container,
    setVisibleClear,
    setActiveSearches,
    onClickButtonByCode,
    activeShakeAnimation,
    onClose,
    isClickButtonByCode,
    setSearchValue
}: IInputSearch) => {
    const router = useRouter();
    const itemRef = useRef(null);
    const inputRef = useRef(null);

    // hooks
    const { user } = useUser({});
    const { isTourOpen } = useStatusContext();
    // cookies
    let firstSteps = false;
    if (typeof window !== 'undefined') {
        firstSteps = localStorage.getItem('firstLogin') === 'true';
    }
    // recordset
    const data = inputSearchRecordsetFormat(recordset);
    // state
    const [selectedIndex, setSelectedIndex] = useState(-1);

    // state resize width
    const [width, setWidth] = useState<number>(0);

    const handleZIndex = isTourOpen || firstSteps;

    const getSize = (): any => {
        return document.body.getElementsByClassName('rs-picker-input');
    };

    useEffect(() => {
        setWidth(getSize()[0]?.offsetWidth);
    }, [visibility, isExpanded]);

    if (typeof window !== 'undefined') {
        window.addEventListener('resize', () => {
            setWidth(getSize()[0]?.offsetWidth);
        });
    }

    const info = data && data.length > 0 ? data : lastSearch && lastSearch.length > 0 ? lastSearch : [];

    useEffect(() => {
        if (isClickButtonByCode) {
            setSearchValue('')
            onClickClose()
            const searchInput: any = document.querySelector('.rs-picker-search-input');
            if (searchInput && inputRef?.current) {
                inputRef?.current.close();
                //inputRef?.current.clear()
                searchInput.value = ''; // Limpia el valor directamente
                searchInput.focus();
            }
        }
    }, [isClickButtonByCode]);
    return (
        <div className={style['container-input']}>
            <div className={handleZIndex ? style['search-icon-tourOn'] : style['search-icon']} id="step-1">
                <SearchOutlinedIcon className={style['icon-searching-input']} />
            </div>
            <InputPicker
                ref={inputRef}
                container={container}
                onKeyDown={(evt) => {
                    handleKeyDownInputPicker(
                        evt,
                        setSelectedIndex,
                        info,
                        router,
                        user,
                        pointOfSaleId,
                        value || productNameSelected,
                        'searchHome',
                        setActiveSearches
                    );
                }}
                id="input-open-search-home"
                style={{
                    zIndex: isOpen ? 2000 : 0,
                    borderBottomLeftRadius: isOpen && (value !== '' || lastSearch) ? 0 : '8px',
                    borderBottomRightRadius: isOpen && (value !== '' || lastSearch) ? 0 : '8px',
                }}
                menuStyle={{
                    minWidth: width,
                }}
                className={`${style['input-search-index']} ${className} ${
                    activeShakeAnimation && style['shake-horizontal']
                }`}
                menuClassName={value === '' && !lastSearch?.length ? style['hide-menu-input'] : style['menu-input']}
                data={data}
                defaultValue={''}
                value={value}
                size="lg"
                placeholder={placeholder}
                //@ts-ignore
                caretAs={caretAs}
                cleanable={cleanable}
                onSearch={onSearch}
                onClose={onClose}
                onOpen={onOpen}
                onEntering={() => {
                    handleOpen(true);
                }}
                onExiting={() => {
                    handleOpen(false);
                }}
                open={open}
                onClean={onCleanInput}
                renderExtraFooter={() =>
                    !isLoading &&
                    data &&
                    data.length > 0 && (
                        <RenderFooter
                            values={pagination?.total}
                            clientId={user?.client?.id}
                            valueSearch={valueSearch}
                            handleOpen={handleOpen}
                            pointOfSale={pointOfSaleId}
                        />
                    )
                }
                renderMenu={(menu: any) => {
                    if (isLoading) {
                        return (
                            <div className={style['container-loading']}>
                                <SpinnerIcon spin className={style['loading-icon']} />
                            </div>
                        );
                    } else {
                        if (data && data.length > 0) {
                            return data?.slice(0, 5).map((item, index) => {
                                return (
                                    <RenderMenuItem
                                        label={item.label}
                                        item={item}
                                        onClickAddProduct={(evt) => onClickAddProduct(evt, item)}
                                        existsProductInCart={existsProductInCart}
                                        quantity={getProductByBarcode(item.value)?.quantity}
                                        cartId={getProductByBarcode(item.value)?.id}
                                        pointOfSaleId={pointOfSaleId}
                                        handleUpdateProduct={handleUpdateProduct}
                                        valueSearch={valueSearch}
                                        toggle={toggle}
                                        visibility={visibility}
                                        selectedIndex={selectedIndex}
                                        index={index}
                                        itemRef={itemRef}
                                    />
                                );
                            });
                        } else if (lastSearch?.length > 0 && !value) {
                            return lastSearch?.map((item, index) => {
                                return (
                                    <RenderLastSearch
                                        key={`input_search_${index}`}
                                        onKeyDown={onKeyDown}
                                        productName={item}
                                        handleOpen={() => handleOpen(false)}
                                        clientId={user?.client?.id}
                                        pointOfSale={pointOfSaleId}
                                        selectedIndex={selectedIndex}
                                        index={index}
                                        setProductNameSelected={setProductNameSelected}
                                    />
                                );
                            });
                        } else if (menu.props.children === 'No se encontraron resultados' && value) {
                            //@ts-ignore
                            return  (
                                <RenderWithoutRecordsetV2 onClickButtonByCode={onClickButtonByCode} />
                            )
                        }
                    }
                }}
            />
            <UploaderButton
                onClickVisibleDragDrop={onClickVisibleDragDrop}
                isOpen={isOpen}
                visibleClear={visibleClear}
                onClickClose={onClickClose}
                setVisibleClear={setVisibleClear}
            />
        </div>
    );
};

export default InputSearch;
