import React from 'react';
// component
import { RsuiteTooltip } from '@commons/components/RsuiteTooltip';
// rsuite
import { Dropdown } from 'rsuite';
// style
import style from './TimeSetting.module.css';

const TimeSetting = ({ hoursRecordset, setSelectedAvailableTime, selectedAvailableTime }) => {
    return (
        <RsuiteTooltip trigger="hover" text="Modificar hora" placement="bottom">
            <Dropdown
                className={style['container-dropdown-button']}
                noCaret
                title={<p className={style['hour-configuration']}>{selectedAvailableTime}</p>}
                onSelect={(eventKey: string, event) => setSelectedAvailableTime(eventKey)}
            >
                {hoursRecordset.map((time, idx) => {
                    return (
                        <Dropdown.Item active={time === selectedAvailableTime} eventKey={time} key={idx}>
                            {time}
                        </Dropdown.Item>
                    );
                })}
            </Dropdown>
        </RsuiteTooltip>
    );
};

export default TimeSetting;
