import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(({ palette }) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    textTransform: "uppercase",
    fontSize: "16px",
    color: palette.neutral[900],
    fontWeight: 600,
  },
}));

export default useStyles;
