// style
import { useTranslation } from 'react-i18next';
import style from './WithoutNotifications.module.css';
import { useUser } from 'utils/hooks';
import useChangeLanguage from 'utils/hooks/use-change-language';
import { EX5057 } from 'config/flags';

const WithoutNotifications = () => {
    const { t } = useTranslation();
    const { userLocaleCountryCode } = useUser({});
    if (EX5057) useChangeLanguage(userLocaleCountryCode);
    return (
        <div className={style['container-body-popover']}>
            <div className={style['container-image-popover']}>
                <img src="/assets/images/notif.svg" />
            </div>
            <p className={style['text-body']}>{EX5057 ? t('header.header_notifyOne') : 'No tenés notificaciones'}</p>
        </div>
    );
};

export default WithoutNotifications;
