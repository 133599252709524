import { EX3990 } from 'config/flags';

import { TypeConnection } from 'utils/enums/InitialConfig';

import style from './NewTypeConnectionsV3.module.css';
import { auxiliaryFunctions } from './utils';
import { useUser } from 'utils/hooks';
import { t } from 'i18next';
import { capitalizeFirstLetter } from 'utils/string';
import { credentialTypesInOrder, handleSwitch } from '../../NewCredentialCards.helpers';

const NewTypeConnectionsV3 = ({
    credentialTypes,
    handlerDrugFormConnection,
    drugManufacturerUrl = null,
    drugmanufacturerId,
}) => {
    const { user } = useUser({});
    const EX5990 = user?.EX5990;
    // credential types orders
    const credentialType = credentialTypesInOrder(credentialTypes);

    const handleButtonClassName = (type) => {
        switch (type) {
            case TypeConnection.WEB:
                return 'button__web-credentials';
            case TypeConnection.WEB_NEW:
                return 'button__new-web-credentials';
            case TypeConnection.API:
                return 'button__api-credentials';
            default:
                return '';
        }
    };

    const LABEL_WEB = t('efficientPurchasing.credentialsError.credentialTypeScrapper').toLowerCase();
    const LABEL_NEW_WEB = t('settings.editPointsOfSale.sett_newWebTextPointsOfSale').toLowerCase();

    const handleButtonNameType = (type) => {
        switch (type) {
            case TypeConnection.WEB:
                return EX5990 ? capitalizeFirstLetter(LABEL_WEB) : 'Web';
            case TypeConnection.WEB_NEW:
                return EX5990 ? capitalizeFirstLetter(LABEL_NEW_WEB) : 'Web Nueva';
            case TypeConnection.API:
                return 'Api';
            default:
                return '';
        }
    };

    const fx = auxiliaryFunctions();

    return (
        <>
            <div className={style['title-container']}>
                <span className={style['title-text']}>
                    {EX5990 ? t('credentials.titleTwoTypesConnection') : '¿Cómo querés conectarte?'}
                </span>
            </div>
            {credentialType?.map(({ name, id, username }, idx) => {
                const nameType = handleSwitch(name, credentialType);
                const newUrls = fx.setNewUrls(drugmanufacturerId, nameType, drugManufacturerUrl);
                return (
                    <div key={idx} className={style['button-container']}>
                        <button
                            onClick={() => handlerDrugFormConnection(nameType, id)}
                            className={`${handleButtonClassName(nameType)} ${style['button-connection']}`}
                            disabled={!!username}
                        >
                            <span className={style['button-text']}>{handleButtonNameType(nameType)}</span>
                            {EX3990 && nameType !== 'api' && <span className={style['button-url']}>{newUrls}</span>}
                        </button>
                    </div>
                );
            })}
        </>
    );
};

export default NewTypeConnectionsV3;
