import { EX5788 } from 'config/flags';

interface shouldDisplayPriceDiscountProps {
    skeleton: boolean;
    product: any;
    hasDrugManufacturerError: boolean;
}

/* #region should show price discount */
export const shouldDisplayPriceDiscount = ({
    skeleton,
    product,
    hasDrugManufacturerError,
}: shouldDisplayPriceDiscountProps): boolean => {
    if (
        !skeleton &&
        !!product &&
        !hasDrugManufacturerError &&
        (product?.quantityDiscountApplies ||
            (EX5788 && product?.agreement?.applies) ||
            (product?.bonusApplied))
    ) {
        return (
            product?.discounts?.length > 0 ||
            product?.discount?.quantityDiscountApplies ||
            product?.agreement?.applies ||
            product?.bonusApplied
        );
    } else {
        return false;
    }
};

/* #endregion */
