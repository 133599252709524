// style
import { ExButton } from '@commons/EXComponentsLibrary';
import style from './EmptyListV2.module.css';
import { useTranslation } from 'react-i18next';
import Image from 'next/image';

const EmptyListV2 = ({ onClickButtonByCode, currentSearch }) => {
    const { t } = useTranslation();
    return (
        <div className={style['container']}>
            <div className={style['container-images']}>
                <div className={style['image-vector']}>
                    <img src="/assets/images/codigo_barras.svg" className={style['vector-img']} />
                </div>
            </div>
            <div className={style['container-without-search']}>
                <div className={style['without-search-text']}>
                    <p className={style['title-without-searching']}>{t('search.one', { value: currentSearch })}</p>
                    <span className={style['subtitle-without-searching']}>{t('search.two')}</span>
                    <span className="textlink__search-code">
                        <div className={`'textlink__search-code' ${style['container-button']}`}>
                            <span className="textlink__search-code">
                                <ExButton
                                    IconComponent={
                                        <Image
                                            src={'/assets/images/outline.svg'}
                                            width={24}
                                            height={24}
                                            alt="icon search"
                                            draggable={false}
                                        />
                                    }
                                    appearance="link"
                                    onClick={(evt) => {
                                        evt.stopPropagation(), onClickButtonByCode();
                                    }}
                                    className="textlink__search-code"
                                >
                                    {t('search.three')}
                                </ExButton>
                            </span>
                        </div>
                    </span>
                </div>
            </div>
        </div>
    );
};

export default EmptyListV2;
