import React from 'react';
import Image from 'next/image';
import { t } from 'i18next';
// components
import { Form } from 'rsuite';
import { ExButton, FormInput } from '@commons/EXComponentsLibrary';
import ReCAPTCHA from 'react-google-recaptcha';
// interfaces
import { IFormRecoveryPasswordProps } from './FormRecoveryPassword.interface';
// styles
import styles from './FormRecoveryPassword.module.css';

const FormRecoveryPassword = ({
    formState,
    onSubmit,
    onChangeCaptcha,
    loading,
    updateFormState,
    success,
}: IFormRecoveryPasswordProps) => {
    return (
        <>
            <div className={styles['form-container']}>
                <div className={styles['box-description']}>
                    <p className={styles['title']}>
                        {' '}
                        <Image
                            src={'/assets/images/lock_blue.png'}
                            alt="lock icon"
                            width={24}
                            height={24}
                            draggable={false}
                        />
                        {!success ? t('login.login6') : t('login.login9')}
                    </p>
                    <p className={styles['subtitle']}>
                        {!success ? t('login.reset_password_text') : t('login.login29')}
                    </p>
                </div>
                {!success && (
                    <Form fluid className={styles['form']} formValue={formState} onSubmit={onSubmit}>
                        <div className={`${styles['input']}`}>
                            <FormInput
                                label={t('login.login10')}
                                placeholder={t('login.login10')}
                                isRequired={true}
                                type={'email'}
                                /* startIcon={<img src="/assets/images/mail.png" />} */
                                /* showStartIcon */
                                maxWidth
                                autoComplete={'on'}
                                showErrorIcon={false}
                                error={null}
                                name={'email'}
                                customStyleStartIcon={{ backgroundColor: '#FFF', marginRight: 0 }}
                                onChange={(event) => updateFormState(event, 'email')}
                            />
                        </div>
                        {onChangeCaptcha && (
                            <ReCAPTCHA sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE} onChange={onChangeCaptcha} />
                        )}
                        <ExButton
                            type="submit"
                            status="initial"
                            withArrow={true}
                            fullWidth
                            size="lg"
                            loading={loading}
                            className={'button__password-recovery-1'}
                        >
                            {t('login.login6')}
                        </ExButton>
                    </Form>
                )}
            </div>
        </>
    );
};

export default FormRecoveryPassword;
