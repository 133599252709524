// routes
import { itemsSettings } from '../routes/settings';
// scripts
import { handlerOpenSettings } from './handlers/setting';
// rsuite
import { Nav, Whisper, Tooltip, Popover } from 'rsuite';
// style
import style from './FooterSidebar.module.css';
import { useEffect, useRef, useState } from 'react';
import FooterSidebarHelp from './FooterSidebarHelp/FooterSidebarHelp';
import { useStatusContext } from 'context/control-state';
import { useRouter } from 'next/router';
import { useUser } from 'utils/hooks';
import { apiPharmaInstance } from 'utils/axios';

const FooterSidebar = () => {
    const { user } = useUser({});
    const router = useRouter();
    const { pathname } = router;
    const helpRef = useRef(null);
    const { continueStatus } = useStatusContext();

    const [open, setOpen] = useState(false);

    const loadHubspotChat = () => {
        // @ts-ignore
        window.HubSpotConversations?.widget.load();

        return () => {
            // @ts-ignore
            window.HubSpotConversations.widget.remove();
            // @ts-ignore
            window.hsConversationsOnReady = [];
        };
    };
    const fetchCompletedWalkthrough = async () => {
        try {
            return await apiPharmaInstance.post('api/free-trial/walkthrough/completed');
        } catch (error) {
            console.log(error.message);
        }
    };
    useEffect(() => {
        loadHubspotChat();
    }, []);
    useEffect(() => {
        if (
            continueStatus === 'finished' &&
            pathname === '/[clientId]/efficient-purchase/[posId]' &&
            !!user?.show_walkthrough_free_trial
        ) {
            setOpen(true); // Abre el popover cuando el stepper termina
        }
    }, [continueStatus, pathname]);
    const close = () => {
        setOpen(false), fetchCompletedWalkthrough();
    };

    return (
        <Nav>
            <div className={style['sidenav-footer']}>
                {itemsSettings.map(({ label, icon, href }) => {
                    return (
                        <div className={style['wrapper-items-settings']} key={`footer_siderbar_${label}`}>
                            {label === 'Chat' ? (
                                <Whisper
                                    ref={helpRef}
                                    speaker={
                                        <Popover className={style['menu-popover']}>
                                            <FooterSidebarHelp closePopover={close} />
                                        </Popover>
                                    }
                                    placement="rightEnd"
                                    trigger="none"
                                    open={open}
                                    onClose={close}
                                >
                                    <p style={{ color: '#0b2033', cursor: 'default' }}>f</p>
                                </Whisper>
                            ) : (
                                <Whisper
                                    speaker={
                                        <Tooltip className={style['tooltip-component']} placement="right">
                                            {label}
                                        </Tooltip>
                                    }
                                >
                                    {/*  <div className={style['container-settins-icons']}> */}

                                    {/* ESTO ES POR EL BOTON DE HASPOT Y EL ONBOARDING */}
                                    <span
                                        style={{ position: 'absolute', left: 96, top: 20 }}
                                        /* className={`${style['content-icon-settings']} ${
                      label === 'Chat' ? style['microphone-icon'] : ''
                    }`} */
                                        //onClick={() => handlerOpenSettings(label, href)}
                                        id={label === 'Chat' ? 'step4' : ''}
                                    >
                                        {icon}
                                    </span>
                                    {/* {label === 'Chat' && (
                    <div className={style['circle-badge']}></div>
                  )} */}
                                    {/* </div> */}
                                </Whisper>
                            )}
                        </div>
                    );
                })}
            </div>
        </Nav>
    );
};

export default FooterSidebar;
