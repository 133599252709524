// components
import { OrderCards } from 'commons/components';
// interface
import { IListDeliveries } from './ListDeliveries.interface';
// utils
import { addImageManufactured } from '../ListOrders.utils';
// helper
import { obtainInitialsLetter, obtainReferenceOfPointOfSale } from '../ListOrders.herlper';
import style from './ListDelivieries.module.css';
//gtag
import * as gtag from 'utils/gtag';

const ListDeliveries = ({ deliveries, selectorList, timerSkeleton, firstElement }: IListDeliveries) => {

    const handlerDeliveriesContainer = () => {
        gtag.event({
            action: 'click',
            category: 'my-orders',
            label: 'card__purchase-order-detail',
            value: 0,
        });
    };

    return (
        <>
            {!!deliveries &&
                deliveries.length > 0 &&
                deliveries?.map(({ number, total, drug_manufacturer, order, id, drug_manufacturer_id }, idx) => {
                    const drugList = addImageManufactured(drug_manufacturer, 'list');
                    const { first_name, last_name, pointsOfSale } = order?.user;

                    const { point_of_sale_id, point_of_sale_name } = order;

                    const initialName = obtainInitialsLetter(`${first_name} ${last_name}`);

                    const reference_name = obtainReferenceOfPointOfSale(pointsOfSale, point_of_sale_id);
                    return (
                        <div
                            key={idx}
                            className={style['wrapper-orders-cards']}
                            ref={idx === 0 ? firstElement : null}
                            onClick={() => handlerDeliveriesContainer()}
                        >
                            <OrderCards
                                key={idx}
                                drug_manufacturer_id={drug_manufacturer_id}
                                referencePointOfSale={reference_name}
                                dateTime={order?.created_at}
                                orderNumber={id}
                                drugmanufactur={point_of_sale_name}
                                value={total}
                                avatar={initialName}
                                client={`${first_name} ${last_name}`}
                                selectorList={selectorList}
                                imageOneDrugmanufacturer={drugList.image}
                                oneDrugmanufacturerName={drugList.name}
                                timerSkeleton={timerSkeleton}
                                initialLettersDrugmanufName={drugList.name}
                                orderNumberByDrugmanufact={number}
                            />
                        </div>
                    );
                })}
        </>
    );
};

export default ListDeliveries;
