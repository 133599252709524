// next
import { useRouter } from 'next/router';
import { t } from 'i18next';
// rsuite
import { Button, Message, Row, Col, ButtonToolbar, IconButton } from 'rsuite';
import { EX4569, EX5057 } from 'config/flags';
// mui
import CloseIcon from '@mui/icons-material/Close';

// style
import style from './WarningBanner.module.css';
import { useUser } from 'utils/hooks';

const WarningBanner = ({ closable, type, header, subtext, handleClick, srcIcon }) => {
    const { user } = useUser({});
    const EX6144 = user?.EX6144;
    // next
    const router = useRouter();
    return (
        <Message
            closable={closable}
            type={type}
            className={style['message']}
            style={{
                border: '1px solid  #FFEA9F',
                background: '#FFF9E6',
                // left:
                //     !!EX4569 && router.pathname === '/[clientId]/efficient-purchase/[posId]/product-comparison'
                //         ? '0px'
                //         : '72px',
                width:
                    !!EX4569 && router.pathname === '/[clientId]/efficient-purchase/[posId]/product-comparison'
                        ? '100vw'
                        : '96.5vw',
                // top: EX6144 ? 0 : 56
            }}
        >
            <Row>
                <div className={style['wrapper-container']}>
                    <Col xs={24} sm={12}>
                        <div className={style['flex-container-left']}>
                            <div className={style['icon-type']}>
                                <img src={srcIcon} />
                            </div>
                            <div className={style['wrapper-text']}>
                                <p className={style['header-styles']}>{header}</p>

                                <p className={style['subtext-styles']}>{subtext}</p>
                            </div>
                        </div>
                    </Col>
                    <Col xs={24} sm={12}>
                        <div className={style['flex-container-right']}>
                            <ButtonToolbar>
                                <Button
                                    appearance="link"
                                    className={style['buttons-coins']}
                                    onClick={() => handleClick()}
                                >
                                    <span>
                                        {EX5057
                                            ? t('efficientPurchasing.priorityAndTolerance.labelUnderstood')
                                            : 'Entendido'}
                                    </span>
                                </Button>
                            </ButtonToolbar>
                            <IconButton
                                className={style['container-icon']}
                                icon={<CloseIcon className={style['icon']} />}
                                onClick={() => handleClick()}
                            />
                        </div>
                    </Col>
                </div>
            </Row>
        </Message>
    );
};

export default WarningBanner;
