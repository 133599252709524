import { getPosByClient } from "api";

export const getPosForSubscriptions = async ( id ) => {
    try {
        const response = await getPosByClient(id);
        const formattedPos = response?.data?.map(({ id, name }) => ({
            label: name,
            value: id,
        }));
        return formattedPos || []
    } catch (error) {
        console.error('Error fetching POS for subscriptions:', error);
    }
};