import React, { memo } from 'react';
import { t } from 'i18next';
// components
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
// utils && hooks
import { usePasswordCondition } from './hooks';
// interfaces
import { IPasswordConditionsProps } from './PasswordConditions.interface';
// styles
import styles from './PasswordConditions.module.css';

const PasswordConditions = ({ runValidations, password, callbackValidation }: IPasswordConditionsProps) => {
    const { passwordCondition, iconPassword } = usePasswordCondition({ password, callbackValidation });

    return (
        <>
            <p className={styles['required-password']}>{t('login.login12')}</p>
            {passwordCondition.map((condition) => (
                <div className={styles['password-conditions']}>
                    {runValidations &&
                        (iconPassword(condition.control) ? (
                            <CheckIcon style={{ height: 16, width: 16, color: '#07AD4B' }} />
                        ) : (
                            <CloseIcon style={{ height: 16, width: 16, color: '#C62828' }} />
                        ))}

                    <p className={styles['text']}>{condition.name}</p>
                </div>
            ))}
        </>
    );
};

export default memo(PasswordConditions);
