// react
import { useEffect, useState } from 'react';
// next
import { useRouter } from 'next/router';
// components
import { CartBadge, NotificationAlert, Path, PharmacySelector, Profile, Settings } from '.';
// handlers
import { handlerFillSelector } from './handlers';
// hooks
import { useModal, useUser } from 'utils/hooks';
// context
import { NewUseCart, useAuth, usePointOfSale } from 'context';
// utils
import { definitionPaths, obtainInitialFirstLetters, obtainRoleOfUser, savePosIdLocalStorage } from './utils';
// traslate
import { useTranslation } from 'react-i18next';
// rsuite v5
import { Col, Navbar, Row, Header as RsuiteHeader } from 'rsuite';
// style
import style from './Header.module.css';
import { switchPlaceholder } from './helpers';
// components
import { useStatusContext } from 'context/control-state';
import { DrawerCart } from '../../index';
//cookie
import { EX3900, EX4467, EX4569, EX5791 } from 'config/flags';
import { isZettiFormat } from 'utils/pointOfSale';
import { disabledPaths } from './PharmacySelector/utils';

const Header = ({ orders, delivery }) => {
    // ff
    const EX3526 = process.env.NEXT_PUBLIC_EX3526 == 'true';
    // traslate
    const { t } = useTranslation();
    // hooks
    const { user } = useUser({});
    const EX4904 = user?.EX4904;

    const { pointOfSale, setPointOfSale } = usePointOfSale();
    const { logout } = useAuth();
    const modal = useModal();
    const { counterStep, setIsTourOpen } = useStatusContext();

    // next
    const router = useRouter();
    // state's
    const [selectedPointOfSale, setSelectedPointOfSale] = useState(null);
    const [pdvSelectedInLocalStorage, setPdvSelectedInLocalStorage] = useState(selectedPointOfSale);
    const [isSearchResultsPage, setIsSearchResultsPage] = useState(false);
    // profile
    const role = obtainRoleOfUser(user);
    const alias = `${user?.first_name} ${user?.last_name}`;
    const initialAlias = obtainInitialFirstLetters(alias);

    // selector
    const recordset = handlerFillSelector(user?.pointsOfSale);
    const { withPosName, withoutPosName } = switchPlaceholder(EX3526, t);

    // cart
    const { getCart, quantityCart, toggle, visibility } = NewUseCart();

    // pdv
    const pdvSelected = user?.pointsOfSale.find((elem) => elem.id === selectedPointOfSale);

    // validation is zetti
    const isZetti = isZettiFormat(pointOfSale);

    useEffect(() => {
        (async () => selectedPointOfSale && getCart(selectedPointOfSale, true))();
    }, [selectedPointOfSale]);

    // Paths
    const { path, goBack } = definitionPaths(router.pathname, user, pointOfSale?.id, orders, delivery, router.query);

    // US VALIDATION
    const US_ES_PATH_VALIDATION = EX5791
        ? ['Compra eficiente', 'Resultados', 'Efficient purchase', 'Results']
        : ['Compra eficiente', 'Resultados'];

    useEffect(() => {
        if (!!pdvSelected) {
            setPointOfSale(pdvSelected); //set pdv state in context
            (async () => await savePosIdLocalStorage(pdvSelected))();
        }
    }, [pdvSelected, selectedPointOfSale, router.pathname]);

    useEffect(() => {
        const pdvOfLocalStorage = localStorage.getItem('pdvSelected');

        (async () => JSON.parse(pdvOfLocalStorage)?.id && getCart(JSON.parse(pdvOfLocalStorage)?.id, true))();
        if (
            !!pdvOfLocalStorage &&
            !!user &&
            path === 'Compra eficiente' &&
            path !== 'Mis pedidos' &&
            router.pathname.split('/').at(-1) !== 'product-comparison'
        ) {
            const id = JSON.parse(pdvOfLocalStorage)?.id;
            setPdvSelectedInLocalStorage(id);
            router.push(`/${user?.client?.id}/efficient-purchase/${id}`);
        } else {
            if (
                path !== 'Resultados' &&
                path === 'Compra eficiente' &&
                !!user &&
                router.pathname.split('/').at(-1) !== 'product-comparison'
            ) {
                router.push(`/${user?.client?.id}/efficient-purchase/`);
            }
        }
    }, []);

    useEffect(() => {
        const pathValidation = EX5791
            ? ['Compra eficiente', 'Efficient purchase']?.includes(path)
            : path === 'Compra eficiente';
        if (!!selectedPointOfSale && pathValidation) {
            router.push(`/${user?.client?.id}/efficient-purchase/${selectedPointOfSale}`);
        }
    }, [selectedPointOfSale]);

    const handlerOnBoardingSelectPDV = () => {
        if (localStorage.getItem('checkOnboarding') === 'false') {
            setIsTourOpen(true);
        }
        localStorage.setItem('firstLogin', 'false');
    };

    const disabledPosSelector = disabledPaths.includes(router.pathname);

    const isEfficientPurchases =
        router.pathname === `/[clientId]/efficient-purchase/[posId]/product-comparison` &&
        goBack === `/${user?.client?.id}/efficient-purchase/${pointOfSale?.id}`;

    const isConfirmationEfficientPurchases =
        router.pathname === `/[clientId]/efficient-purchase/[posId]/confirmation` &&
        goBack === `/${user?.client?.id}/efficient-purchase/${pointOfSale?.id}/product-comparison`;

    const isDisabledSelector =
        router.pathname === '/[clientId]/efficient-purchase/[posId]/product-comparison' ||
        router.pathname === '/[clientId]/efficient-purchase/[posId]/order-sent' ||
        router.pathname === '/[clientId]/efficient-purchase/[posId]/confirmation';

    const handlerSelectPos = (evt) => {
        if (router.pathname === '/[clientId]/efficient-purchase/[posId]/search-results' && !!evt) {
            setSelectedPointOfSale(evt);
            handlerOnBoardingSelectPDV();
            router.push(`/${user?.client?.id}/efficient-purchase/${pointOfSale?.id}`);
        } else {
            setSelectedPointOfSale(evt);

            handlerOnBoardingSelectPDV();
        }
    };

    useEffect(() => {
        const isSearchResultsPath = router?.pathname === '/[clientId]/efficient-purchase/[posId]/search-results';
        if (isSearchResultsPath) {
            setIsSearchResultsPage(true);
        } else {
            setIsSearchResultsPage(false);
        }
    }, [router.pathname]);

    const steps = ['#step-4', '#step-5', '#step-7', 'body'];
    const controlStep = steps.find((element) => counterStep === element);

    return (
        <RsuiteHeader style={EX4467 ? (isSearchResultsPage ? { zIndex: 2600 } : { zIndex: 1200 }) : {}}>
            {/* El zIndex se coloca en 1200 para que se superponga al 'backdrop' al abrir el carrito en orders y config*/}
            {/* ON BOARDING BLUR  */}
            {/* {!controlStep && <div className={style['wrapper-cart-blur']}></div>} */}
            {/* END SECTION ON BOARDING BLUR  */}
            {/* ----------------------------------- */}
            <div className={style['container-navbar']}>
                <Navbar className={style['wrapper-navbar']}>
                    <div className={style['container-cols']}>
                        <Row
                            className={style['container-row']}
                            style={{
                                margin:
                                    (!!EX4904 &&
                                        router.pathname === '/[clientId]/efficient-purchase/[posId]/confirmation') ||
                                    (router.pathname === '/[clientId]/efficient-purchase/[posId]/product-comparison' &&
                                        !!EX4569)
                                        ? '0px'
                                        : router.pathname === '/[clientId]/efficient-purchase/[posId]/order-sent'
                                        ? '0px 40px'
                                        : '0px 48px',
                            }}
                        >
                            <Col xs={24} sm={12}>
                                <Path
                                    label={path}
                                    href={goBack}
                                    haveGoBack={!!goBack}
                                    isEfficientPurchases={isEfficientPurchases}
                                    isConfirmationEfficientPurchases={isConfirmationEfficientPurchases}
                                    clientId={user?.client?.id}
                                />
                            </Col>

                            <Col xs={24} sm={12} className={style['container-commands']}>
                                <PharmacySelector
                                    recordset={recordset}
                                    handlerSelectedValue={(evt) => handlerSelectPos(evt)}
                                    itemSelected={selectedPointOfSale}
                                    value={selectedPointOfSale ? selectedPointOfSale : router.query.posId}
                                    defaultValue={selectedPointOfSale ? selectedPointOfSale : router.query.posId}
                                    placeholder={!!selectedPointOfSale ? withPosName : withoutPosName}
                                    userValues={user}
                                    disabledPosSelector={disabledPosSelector}
                                    disabled={isDisabledSelector}
                                    isDisabledSelector={isDisabledSelector}
                                />
                                {EX3900 && <NotificationAlert />}
                                <Settings
                                    title={<Profile aliases={alias} roleType={role} initials={initialAlias} />}
                                    execModal={modal}
                                    execLogout={logout}
                                    isZetti={isZetti}
                                />
                                {router.pathname === '/[clientId]/efficient-purchase/[posId]/product-comparison' ||
                                    router.pathname === '/[clientId]/efficient-purchase/[posId]/confirmation' ||
                                    router.pathname === '/[clientId]/efficient-purchase/[posId]/order-sent' || (
                                        <CartBadge
                                            quantityCart={!!quantityCart() ? quantityCart() : ''}
                                            toggle={toggle}
                                            pointOfSaleId={pointOfSale?.id}
                                            isZetti={isZetti}
                                        />
                                    )}
                            </Col>
                        </Row>
                    </div>
                </Navbar>
                {!US_ES_PATH_VALIDATION.includes(path) && (
                    <div
                        className={`${visibility && style['drawer-fade-in']}`}
                        style={{
                            position: 'fixed',
                            right: 50,
                            top: 65,
                            transform: 'translateX(0)',
                            zIndex: 900,
                        }}
                    >
                        <DrawerCart />
                    </div>
                )}
            </div>
        </RsuiteHeader>
    );
};

export default Header;
