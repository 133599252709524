import React, { useEffect, useState } from 'react';
import { NewUseCart, useRecommendedProducts } from 'context';
import { handlerAddProductCart } from './handlers';
import { useRouter } from 'next/router';
import { MockData } from 'commons/modules/RecommendedProducts/Mock/Mock';
import { useDragAndDrop } from 'context/dragAndDropContext';
import RecommendedCard from './RecommendedCard';
import RecommendedEmpty from './RecommendedCardEmpty/RecommendedEmpty';
import { useStatusContext } from 'context/control-state';

const RecommendedProducts = () => {
    // Check if window is defined before accessing window.innerWidth
    const isWindowDefined = typeof window !== 'undefined';
    // state width
    const [width, setWidth] = useState(isWindowDefined ? window.innerWidth < 1200 : false);
    // context recommended products
    const { recommendedProducts, fetching } = useRecommendedProducts();
    // context drag and drop
    const { visibleDragDrop } = useDragAndDrop();
    // PDV
    const { posId } = useRouter()?.query;
    // context cart
    const {
        addProduct,
        existsProductInCart,
        getProductByBarcode,
        updateProduct,
        isExpanded,
        visibility,
        toggle,
    } = NewUseCart();

    // context onboarding
    const { counterStep } = useStatusContext();

    const pixels: any = 1500;

    useEffect(() => {
        const destopMediaQuery = window?.matchMedia(`(min-width: ${pixels}px)`);

        const handleResize = () => {
            setWidth(window?.innerWidth < pixels);
        };

        destopMediaQuery?.addEventListener('change', handleResize);

        // Limpia el evento al desmontar el componente
        return () => {
            destopMediaQuery?.removeEventListener('change', handleResize);
        };
    }, []); // El arreglo de dependencias está vacío para que solo se ejecute al montar/desmontar el componente

    const renderEmpty = () => <RecommendedEmpty />;

    const gridTemplateColumns = () => {
        // esta abierto el carrito o no?
        if (!visibility) {
            // se esta achicando la pantalla hasta ciertos px?
            if (width) {
                return '1fr 1fr 1fr';
            } else {
                return '1fr 1fr 1fr 1fr 1fr';
            }
        } else {
            // esta abierto el carrito pero no esta expandido y no se achico la pantalla?
            if (visibility && !isExpanded && !width) {
                return '1fr 1fr 1fr';
            } else if ((visibility && isExpanded) || width) {
                // esta abierto el carrito y expandido o se achico la pantalla
                return '1fr 1fr';
            } else {
                return '1fr 1fr 1fr 1fr 1fr';
            }
        }
    };

    return (
        <div
            id="step-3"
            style={{
                width: '100%',
                display: 'grid',
                gridTemplateColumns: gridTemplateColumns(),
                justifyContent: 'space-around',
                alignItems: 'center',
                position: 'relative',
                zIndex: counterStep === '#step-3' ? 2200 : 1,
                gap: 24,
                filter: visibleDragDrop ? 'opacity(0.5)' : 'none',
            }}
        >
            {fetching && renderEmpty()}
            {recommendedProducts.length > 0 &&
                !fetching &&
                recommendedProducts.map((recommended: any, idx) => {
                    const isFifthElem = (idx + 1) % 5 === 0;
                    return (
                        <RecommendedCard
                            key={recommended?.product?.ean}
                            recommended={recommended}
                            handlerAddProductCart={(item: any) => {
                                handlerAddProductCart(item, addProduct);
                                if (!visibility) toggle(posId);
                            }}
                            existsProductInCart={existsProductInCart(recommended?.product?.ean)}
                            quantity={getProductByBarcode(recommended?.product?.ean)?.quantity}
                            handleUpdateProduct={updateProduct}
                            cartId={getProductByBarcode(recommended?.product?.ean)?.id}
                            disabledButtons={visibleDragDrop}
                            isFifthElem={!visibility && isFifthElem}
                        />
                    );
                })}
        </div>
    );
};

export default RecommendedProducts;
