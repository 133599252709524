// UTILS
import { isNullOrUndefined } from 'utils/strings/isNullOrUndefined';
import useCurrencyFormatter from 'utils/hooks/useCurrencyFormatter/useCurrencyFormatter';

export const renderPrice = (value) => {
    const { formatCurrency } = useCurrencyFormatter();
    if (isNullOrUndefined(value)) {
        return '';
    } else {
        return formatCurrency({ value, digitsValidation: 7 })?.fullValue;
    }
};
