import { useEffect, useState } from 'react';
// contexts
import { useProgressBarPriceContext } from 'context';
// styles
import styles from '../Header.module.css';

const useHeader = () => {
    const { headerSteps, currentHeaderStep, quantityRequestedProducts, failed } = useProgressBarPriceContext();

    const [animationClass, setAnimationClass] = useState(styles['fade-in-right']);
    const [displayedStep, setDisplayedStep] = useState(1);

    useEffect(() => {
        if (currentHeaderStep !== displayedStep) {
            setAnimationClass(styles['fade-out-left']);

            const timeout = setTimeout(() => {
                setDisplayedStep(currentHeaderStep);
                setAnimationClass(styles['fade-in-right']);
            }, 200);

            return () => clearTimeout(timeout);
        }
    }, [currentHeaderStep, displayedStep]);

    return { animationClass, displayedStep, headerSteps, quantityRequestedProducts, failed };
};

export default useHeader;
