import { memo } from 'react';
// components
import { ActionsButtons } from './ActionsButtons';
import { Information } from './Information';
// utilts && hooks
import { handleRemoveItemAnimation, handleAnimationEnd, onClickRequestVendor } from './utils';
import { useItem } from './hooks';
// interfaces
import { IItemProps } from './Item.interfaces';
// styles
import styles from './Item.module.css';

const Item = ({ child, from, parentRef, idx }: IItemProps) => {
    const {
        removeItem,
        setIsDeleted,
        setIsVendorRequested,
        isVendorRequested,
        requestVendor,
        onClickItem,
        getClassNames,
        disabledEdit,
    } = useItem();

    return (
        <div
            className={getClassNames(child, from, styles)}
            onClick={(e: any) => onClickItem(e, from, child)}
            onAnimationEnd={(e) => handleAnimationEnd(e, removeItem, child.id, from === 2 ? 'connected' : 'suggested')}
        >
            <div className={`${styles['item-content']} ${disabledEdit(child) ? styles['item-content-large'] : ''}`}>
                {/* information section */}
                <Information
                    from={from}
                    drugmanufacturer={child}
                    parentRef={parentRef}
                    idx={idx}
                    disabledEdit={disabledEdit}
                />
                {/*  -------------------------- buttons rigth --------------------------  */}
                {(!disabledEdit(child) || from === 1) && (
                    <ActionsButtons
                        child={child}
                        from={from}
                        handleRemove={() => handleRemoveItemAnimation(setIsDeleted)}
                        onClickRequestVendor={() =>
                            onClickRequestVendor(requestVendor, setIsVendorRequested, child, setIsDeleted)
                        }
                        isVendorRequested={isVendorRequested}
                        disabledEdit={disabledEdit}
                    />
                )}
            </div>
        </div>
    );
};

export default memo(Item);
