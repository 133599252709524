import React from 'react';
import { Col, Row } from 'rsuite';
import style from './ListProducts.module.css';
import { useTranslation } from 'react-i18next';
import { useUser } from 'utils/hooks';
import useChangeLanguage from 'utils/hooks/use-change-language';
import { EX4396, EX5057 } from 'config/flags';
import { currencyFormatter } from '../../utils/currencyTotal';
import useStyles from './ListProducts.style';
import { capitalizeEveryFirstLetter } from 'utils/strings/capitalizeEveryFirstLetter';

function ListProducts({ products }) {
    //translation by Country
    const { t } = useTranslation();
    const { user } = useUser({});
    const userLocaleCountryCode = user?.client?.country_alfa_2;
    if (EX4396) useChangeLanguage(userLocaleCountryCode);

    const EX4904 = user?.EX4904;
    const classes = useStyles({ EX4904: EX4904 });

    return (
        <>
            {products.map((product) => (
                <>
                    <Row className={style['product-row']}>
                        <Col sm={12} md={8} lg={12}>
                            <p className={style['product-name']}>{capitalizeEveryFirstLetter(product?.description)}</p>
                            <p className={style['product-code']}>{product?.barcode}</p>
                        </Col>
                        <Col sm={24} md={8} lg={5} className={style['product-count']}>
                            {`${product?.quantity} ${
                                product?.quantity > 1
                                    ? !!EX5057
                                        ? t('myOrders.labelUnits')
                                        : 'unidades'
                                    : !!EX5057
                                    ? t('myOrders.labelUnit')
                                    : 'unidad'
                            }`}
                        </Col>

                        <Col sm={12} md={8} lg={7} className={style['product-count']}>
                            {!EX4396
                                ? currencyFormatter(product?.total)
                                : t('myOrders.orderDetails.currencySymbol', {
                                      valueMoney: currencyFormatter(product?.total),
                                  })}
                        </Col>
                    </Row>
                    { product?.free_quantity > 0 && (
                        <Row className={style['product-row']}>
                            <Col sm={12} md={8} lg={12}>
                                <p className={style['product-name']}>{product?.description}</p>
                                <p className={style['product-code']}>{product?.barcode}</p>
                            </Col>
                            <Col sm={24} md={8} lg={5} className={style['product-count']}>
                                {`${product?.free_quantity} ${
                                    product?.free_quantity > 1
                                        ? !!EX5057
                                            ? t('myOrders.labelUnits')
                                            : 'unidades'
                                        : !!EX5057
                                        ? t('myOrders.labelUnit')
                                        : 'unidad'
                                }`}
                            </Col>

                            <Col sm={12} md={8} lg={7} className={style['product-count']}>
                                {product?.free_quantity > 0 && <p className={classes.textFreeQuantity}>GRATIS</p>}
                            </Col>
                        </Row>
                    )}
                </>
            ))}
        </>
    );
}

export default ListProducts;
