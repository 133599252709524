import { useEffect, useState } from 'react';
// rsuite
import { Row, Col, Button, Loader, Placeholder } from 'rsuite';
import PlusIcon from '@rsuite/icons/Plus';
// mui v5
import WallpaperIcon from '@mui/icons-material/Wallpaper';
// style
import style from './RenderMenuItem.module.css';
import { RsuiteCounter } from 'commons/components/RsuiteCounter';
import { handleOperation, formatObject, handleChangeMomentary } from './handlers';
import { getHighlightedText } from 'utils/getHighlightedText';
import { EX4693 } from 'config/flags';
import { capitalizeEveryFirstLetter } from 'utils/strings/capitalizeEveryFirstLetter';

const RenderMenuItem = ({
    label,
    item,
    onClickAddProduct,
    existsProductInCart,
    quantity,
    cartId,
    pointOfSaleId,
    handleUpdateProduct,
    valueSearch,
    visibility,
    toggle,
    selectedIndex,
    index,
}) => {
    // STATE LOCAL INPUT
    const [value, setValue] = useState<number>(quantity);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [imageLoad, setImageLoad] = useState<boolean>(false);

    // LISTEN UPDATE QUANTITY & SETTER STATE
    useEffect(() => {
        if (isFetching || quantity !== value) setValue(quantity);
        setIsFetching(false);
    }, [quantity]);

    // SUBS
    const handleMinus = (v) => {
        setIsFetching(true);
        return handleOperation(formatObject(item, cartId), v, handleUpdateProduct);
    };

    // ADD
    const handlePlus = (v) => {
        setIsFetching(true);
        return handleOperation(formatObject(item, cartId), v, handleUpdateProduct);
    };

    // WRITING MOMENTARY
    const handleWriting = (v) => {
        return handleChangeMomentary(v, setValue);
    };

    // INPUT ONBLUR
    const handleInput = (v) => {
        if (quantity !== +v) {
            setIsFetching(true);
            return handleOperation(formatObject(item, cartId), +v, handleUpdateProduct);
        }
    };

    const info = `${item.label}. ${item.laboratory !== null ? item.laboratory : ''}`;

    return (
        <Row
            className={selectedIndex === index ? style['container-row-hover'] : style['container-row']}
            //onKeyDown={handleKeyDown}
            //@ts-ignore
            tabIndex="0"
        >
            <div className={style['wrapper-menu-item']}>
                <Col xs={20}>
                    <div className={style['container-image-text-menu']}>
                        {!!EX4693 ? (
                            <>
                                {!imageLoad && (
                                    <Placeholder.Graph
                                        active
                                        style={{
                                            width: 55,
                                            height: 55,
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    />
                                )}

                                <img
                                    src={`${process.env.NEXT_PUBLIC_CLOUDFRONT_IMAGES_EP}/${process.env.NEXT_PUBLIC_CLOUDFRONT_FOLDER_TWO}/${item.value}.png`}
                                    alt="imagen medicamento"
                                    onError={(e: any) => (e.target.src = '/assets/images/default.png')}
                                    onLoad={(e: any) => setImageLoad(true)}
                                    width={55}
                                    height={55}
                                    style={{
                                        display: !imageLoad ? 'none' : 'inline',
                                        objectFit: 'cover', // Agregando objectFit para conservar la calidad
                                    }}
                                    className={style['container-image']}
                                />
                            </>
                        ) : (
                            <WallpaperIcon className={style['icon-wallpaper']} />
                        )}
                        <div className={style['wrapper-text-menu-item']}>
                            {!!EX4693 ? (
                                <>
                                    <p>{getHighlightedText(capitalizeEveryFirstLetter(info), valueSearch)}</p>
                                    <span className={style['container-text']}>
                                        {item.value} {item.drug && <div className={style['verticalBar']} />}{' '}
                                        {!!item.drug && item.drug !== null ? item.drug : ''}
                                    </span>
                                </>
                            ) : (
                                <>
                                    <p>{getHighlightedText(label, valueSearch)}</p>
                                    <span>{item.value} </span>
                                </>
                            )}
                        </div>
                    </div>
                </Col>
                <Col xs={4}>
                    <div className={style['wrapper-button-menu-item']}>
                        {!!existsProductInCart(item.value) ? (
                            <RsuiteCounter
                                idx={item.value}
                                value={value}
                                handleWriting={handleWriting}
                                handleInput={handleInput}
                                handleMinus={handleMinus}
                                handlePlus={handlePlus}
                                hasError={false}
                                min={0}
                                isFetching={isFetching}
                                styles={style}
                                tabOrigin={'search-results-input'}
                            />
                        ) : (
                            <Button
                                className={`button__add-product-search-bar ${style['button-add-item']}`}
                                startIcon={!isFetching && <PlusIcon className="button__add-product-search-bar" />}
                                onClick={(evt) => {
                                    setIsFetching(true);
                                    onClickAddProduct(evt);
                                    if (!visibility) {
                                        toggle(pointOfSaleId);
                                    }
                                }}
                            >
                                {isFetching ? (
                                    <div className={`${style['wrapper-loader']}`}>
                                        <Loader />
                                    </div>
                                ) : (
                                    <span className={`button__add-product-search-bar ${style['text-button-item']}`}>
                                        Agregar
                                    </span>
                                )}
                            </Button>
                        )}
                    </div>
                </Col>
            </div>
        </Row>
    );
};

export default RenderMenuItem;
