import Link from 'next/link';
import { useRouter } from 'next/router';
// mui v5
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// rsuite
import { Breadcrumb } from 'rsuite';

// interface
import { IBreadcrumb } from './Breadcrumbs.interface';

// style
import style from './Breadcrumbs.module.css';

const Breadcrumbs = ({
  href,
  text,
  nOrder,
  flagIcon,
  goBack,
  savePrevUrl,
  urlPrev
}: IBreadcrumb) => {
  // next
  const router = useRouter();
  return (
    <div className={style['wrapper-container']}>
      {flagIcon && (
        <Link
          className={style['href-go-back']}
          href={goBack}
          onClick={() => savePrevUrl(router.asPath)}
        >
          <ArrowBackIcon className={style['icon']} />
        </Link>
      )}

      <Breadcrumb className={style['container-breadcrumb']}>
        <Breadcrumb.Item
          href={href}
          active
          className={style['text-breadcrumb']}
        >
          {text} {nOrder ? `# ${nOrder}` : ''}
        </Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );
};

export default Breadcrumbs;
