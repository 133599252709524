import { useEffect, useRef, useState } from 'react';
import { t } from 'i18next';
import { rem, rgba } from 'polished';
import Button from 'components/Button';
import { Text } from 'components/text';
import { useRouter } from 'next/router';
import { setPriority } from 'api/priority';
import Menu, { MenuProps } from 'components/Menu';
import { Grid, useTheme } from '@material-ui/core';
import { useStatusContext } from 'context/control-state';
import { useReloadProducts } from 'context/reload_products';
import { useSkeletonModal } from 'context/loading-skeleton';
import { useProductComparison } from 'context/product-comparison.context';
import * as gtag from 'utils/gtag';
import { Modal } from 'components';
import { ButtonGhost, ButtonOutline, ButtonSolid } from 'commons/components/Button';
import { EX3991, EX5057, EX5225, EX6381 } from 'config/flags';
import { DrugManufacturersId } from 'common/types';
import { OfferCenters } from 'utils/enums';
import { EDrugManufacturerTypeId } from 'utils/enums/drugmanufacturerTypeId/DrugManufacturerTypeId';
import InputPrioridad from './InputPrioridad';

type newDrugmanufacterArray = [
    {
        id: number;
        name: string;
        type_id: number;
        cdo_status_id: number;
        vendor_accepted: boolean;
    },
];

interface ModalPriorities extends Omit<MenuProps, 'children'> {
    handleClose(event: any): void;
    array: any;
    unassigned: any;
    deletePriority: any;
    setShow?: any;
    setMsg?: any;
    available?: any;
    count?: number;
    setCount?: any;
    setTolerance?: any;
    tolerance?: any;
    serviceId?: boolean;
    user?: any;
    mutateUser?: any;
    pointOfSale?: any;
    openModal?: boolean;
    newDrugmanufacturers?: newDrugmanufacterArray;
}

export default function ModalPriorities({
    user,
    mutateUser,
    pointOfSale,
    handleClose,
    array,
    unassigned,
    deletePriority,
    setShow,
    setMsg,
    available,
    tolerance,
    setTolerance,
    serviceId,
    openModal,
    newDrugmanufacturers,
}: ModalPriorities) {
    const { palette } = useTheme();
    const [scroll, setScroll] = useState(true);
    const [handlePriorities, setHandlePriorities] = useState([]);
    const [open, setOpen] = useState(false);
    const { setLoadingSkeleton } = useSkeletonModal();
    const [priorities, setPriorities] = useState([]);
    const [disabled, setDisabled] = useState(false);
    const userRole = user.role_id;
    // const { orderId } = useProductComparison();
    const [selectArray, setSelectArray] = useState([]);
    const [check, setCheck] = useState(false);
    const { status, setStatus, setControl, response, setResponse, setCount } = useStatusContext();
    const [positions, setPositions] = useState([]);
    const { setReload } = useReloadProducts();
    const EX3526 = process.env.NEXT_PUBLIC_EX3526 === 'true';
    const EX6017 = user?.EX6017;
    const FFEX6381 = !!EX6381;
    const [selectedItems, setSelectedItems] = useState([]);
    const [allSelectedItems, setAllSelectedItems] = useState([]);
    const { query } = useRouter();

    const [relativePosition, setRelativePosition] = useState([]);
    const scrollDivRef = useRef(null);

    const addRelativeElementPosition = (index, element) => {
        setRelativePosition((prevArray) => {
            const newArray = [...prevArray];
            newArray[index] = element;
            return newArray;
        });
    };

    const calculateRelativeElements = () => {
        const internalElements = scrollDivRef?.current?.querySelectorAll('.internal-element');
        const divTop = scrollDivRef?.current?.getBoundingClientRect().top;

        internalElements?.forEach((elemento, index) => {
            const elementoTop = elemento.getBoundingClientRect().top - divTop;
            addRelativeElementPosition(index, elementoTop);
        });
    };

    useEffect(() => {
        if (relativePosition.length === 0) {
            calculateRelativeElements();
        }
        scrollDivRef?.current?.addEventListener('scroll', calculateRelativeElements);

        return () => {
            scrollDivRef?.current?.removeEventListener('scroll', calculateRelativeElements);
        };
    }, [relativePosition, scrollDivRef?.current]);

    const handleCloseModal = () => {
        handleClose(false);
        setDisabled(false);
        setControl(false);
    };

    useEffect(() => {
        const drugmanufacturerControlCdo = [];
        newDrugmanufacturers?.map((item) => {
            if (item?.id !== DrugManufacturersId.CentralDeOfertas) {
                drugmanufacturerControlCdo.push(item);
            } else if (item?.cdo_status_id === OfferCenters.Enabled) {
                drugmanufacturerControlCdo.push(item);
            }
        });
        setSelectArray(drugmanufacturerControlCdo);
        if (!serviceId) {
            handleFilterPriorities();
        }
    }, [array, newDrugmanufacturers]);

    useEffect(() => {
        margeArrays(newDrugmanufacturers, priorities);
    }, [priorities, newDrugmanufacturers]);


    useEffect(() => {
        if(available !== undefined) {

            const filteredData = priorities.map((elem) => elem.drug_manufacturer_id)
            setAllSelectedItems(filteredData)
        }
      
    }, [available,priorities])

    function margeArrays(input1, input2) {
        let retVal = [];
        let pos = [];
        if (input2.length !== 0) {
            input1.forEach((element) => {
                retVal[element.id] = {
                    name: element.name,
                    id: element.id,
                    priority_number: undefined,
                };
            });

            input2.forEach((element) => {
                retVal[element.drug_manufacturer_id].priority_number = element.priority_number;
                pos[element.priority_number - 1] = {
                    drug_manufacturer_id: element.drug_manufacturer_id,
                    priority_number: element.priority_number,
                };
            });
        }
        setPositions(pos);
        setHandlePriorities(retVal);
    }
    const handleFilterPriorities = () => {
        let pos = [];

        const filteredArray = array?.filter((a) => {
            return newDrugmanufacturers?.some((d) => {
                return d.id === a.drug_manufacturer_id;
            });
        });

        filteredArray?.forEach((element) => {
            pos[element.priority_number] = element.drug_manufacturer_id;
        });

        setPositions(pos);
        setPriorities(filteredArray);
    };

    const handleValues = () => {
        gtag.event({
            action: 'click',
            category: 'efficient-purchase__product-comparison',
            label: 'modal-priority-config__save',
            value: 0,
        });
        setControl(true);
        setStatus(!status);
        const isValid = positions.every((item) => item?.drug_manufacturer_id === undefined);
        const hasCdo = newDrugmanufacturers?.find((item) => item?.id === DrugManufacturersId.CentralDeOfertas);

        if (positions.length === 0) {
            handleSend(isValid);
        } else {
            let valueArr = positions.map(function (item) {
                return item.drug_manufacturer_id;
            });
            let isDuplicate = valueArr.some(function (item, input_position) {
                return valueArr.indexOf(item) != input_position;
            });

            let isUndefined = positions.find(function (item) {
                return item?.drug_manufacturer_id === undefined;
            });

            let isEmpty = false;

            for (let i = 0; i < positions.length; i++) {
                if (positions[i] === undefined) {
                    isEmpty = true;
                }
            }
            if (
                isValid ||
                (!isDuplicate &&
                    !isEmpty &&
                    !isUndefined &&
                    (positions.length === newDrugmanufacturers?.length ||
                        (hasCdo && positions.length + 1 === newDrugmanufacturers?.length)))
            ) {
                handleSend(isValid);
            }
        }
    };
    const handleSend = async (isValid) => {
        setDisabled(true);
        let obj = {};
        if (tolerance) {
            if (positions.length !== 0 && !isValid) {
                obj = {
                    priorities: positions,
                    tolerance: parseFloat(tolerance.replace(',', '.')),
                };
            } else {
                obj = {
                    tolerance: parseFloat(tolerance.replace(',', '.')),
                };
            }
        } else {
            if (positions.length !== 0 && !isValid) {
                obj = {
                    priorities: positions,
                    tolerance: 0,
                };
            } else {
                obj = {
                    tolerance: 0,
                };
            }
        }

        setLoadingSkeleton(true);

        let res = await setPriority(query.orderId, obj);
        if (res) {
            setCount(1);
            let newUserData = await mutateUser();

            let findTolerance = newUserData.data.pointsOfSale.find((p) => pointOfSale.id === p.id);
            if (findTolerance.tolerance === null) {
                setTolerance('0,00');
            } else {
                setTolerance(findTolerance.tolerance.toString());
            }

            setReload(true);
            setMsg(
                EX3526
                    ? t('efficientPurchasing.priorityAndTolerance.efficientPurchasing_priorityAndToleranceSeventeen')
                    : 'La prioridad ha sido guardada.',
            );
            setResponse(!response);
            setShow(true);
        }
        handleCloseModal();
    };

    const handleDeletePriorities = (res) => {
        if (res === true) {
            setPriorities([]);
            setMsg(
                EX3526
                    ? t('efficientPurchasing.priorityAndTolerance.efficientPurchasing_priorityAndToleranceTwentyFour')
                    : 'Se ha eliminado la prioridad de precios iguales.',
            );
            setShow(true);
            handleClose(false);
            setReload(true);
        }
        setControl(false);
        setOpen(!open);
    };

    const handleMouseEnter = () => {
        setScroll(false);
    };

    const handleMouseLeave = () => {
        setScroll(true);
    };

    return (
        <>
            <Modal fullWidth Width="560px" Height="560px" Percentage={true} open={openModal}>
                <Grid container>
                    <Grid item container>
                        <Text fontSize="20px" style={{ padding: '24px' }} color={palette.neutral[900]} fontWeight={600}>
                            {EX5057
                                ? t('efficientPurchasing.priorityAndTolerance.labelTitleModalConfigPriority')
                                : 'Configurar prioridad'}
                        </Text>
                    </Grid>
                    <div
                        style={{
                            paddingLeft: '24px',
                            width: '470px',
                            marginBottom: '24px',
                        }}
                    >
                        {userRole !== 2 ? (
                            <Text fontSize="16px" color={palette.neutral[800]} fontWeight="normal">
                                {EX3526
                                    ? t(
                                          'efficientPurchasing.priorityAndTolerance.efficientPurchasing_priorityAndToleranceTen',
                                      )
                                    : 'Elige el orden de las droguerías para la selección a precios iguales. También puedes ingresar una tolerancia en la droguería prioritaria. '}
                            </Text>
                        ) : (
                            <Text fontSize="16px" color={palette.neutral[800]} fontWeight="normal">
                                {!EX3991
                                    ? 'Elige el orden de las droguerías para la selección a precios iguales.'
                                    : t(
                                          'efficientPurchasing.priorityAndTolerance.efficientPurchasing_priorityAndToleranceTwenty',
                                      )}
                            </Text>
                        )}
                    </div>
                    <div
                        ref={scrollDivRef}
                        style={{
                            paddingTop: 0,
                            paddingLeft: 24,
                            height: '330px',
                            overflowY: scroll ? 'scroll' : 'clip',
                            overflowX: 'hidden',
                        }}
                    >
                        <Grid item container xs={12} direction="column" alignItems="center">
                            {newDrugmanufacturers
                                ?.filter((a) => {
                                    if (FFEX6381) {
                                        if (available?.length !== 0) {
                                            return available?.some((u) => {
                                                return u.drug_manufacturer_id === a.id;
                                            });
                                        } else {
                                            if (
                                                a?.id === DrugManufacturersId.CentralDeOfertas &&
                                                a?.cdo_status_id === OfferCenters.Enabled
                                            ) {
                                                return a;
                                            } else if (
                                                (a?.id !== DrugManufacturersId.CentralDeOfertas &&
                                                    !!a?.vendor_accepted &&
                                                    a?.type_id === EDrugManufacturerTypeId.vendor) ||
                                                a?.type_id !== EDrugManufacturerTypeId.vendor
                                            ) {
                                                return a;
                                            }
                                        }
                                    } else {
                                        if (available?.length !== 0) {
                                            return available?.some((u) => {
                                                return u.drug_manufacturer_id === a.id;
                                            });
                                        } else {
                                            if (
                                                a?.id === DrugManufacturersId.CentralDeOfertas &&
                                                a?.cdo_status_id === OfferCenters.Enabled
                                            ) {
                                                return a;
                                            } else if (
                                                a?.id !== DrugManufacturersId.CentralDeOfertas &&
                                                a?.type_id !== EDrugManufacturerTypeId.vendor
                                            ) {
                                                return a;
                                            }
                                        }
                                    }
                                })
                                .map(({ id }, input_position) => {
                                    return (
                                        <div className="internal-element">
                                            <InputPrioridad
                                                relativePosition={relativePosition[input_position]}
                                                unassigned={false}
                                                id={id}
                                                input_position={input_position + 1}
                                                placeholder={
                                                    EX3526
                                                        ? t(
                                                              'efficientPurchasing.priorityAndTolerance.efficientPurchasing_priorityAndToleranceEleven',
                                                          )
                                                        : 'Selecciona una droguería'
                                                }
                                                priorities={priorities}
                                                positions={positions}
                                                tolerance={tolerance}
                                                setTolerance={setTolerance}
                                                handlePriorities={handlePriorities}
                                                selectArray={selectArray}
                                                userRole={userRole}
                                                check={check}
                                                setCheck={setCheck}
                                                setControl={setControl}
                                                handleMouseEnter={handleMouseEnter}
                                                handleMouseLeave={handleMouseLeave}
                                                setSelectedItems={setSelectedItems}
                                                selectedItems={selectedItems}
                                                setAllSelectedItems={setAllSelectedItems}
                                                allSelectedItems={allSelectedItems}
                                            />
                                        </div>
                                    );
                                })}

                            {unassigned?.map((item, index) => {
                                return (
                                    <div className="internal-element">
                                        <InputPrioridad
                                            relativePosition={relativePosition[available?.length + index]}
                                            unassigned={true}
                                            id={item.id}
                                            input_position={newDrugmanufacturers?.length}
                                            placeholder={
                                                EX3526
                                                    ? t(
                                                          'efficientPurchasing.priorityAndTolerance.efficientPurchasing_priorityAndToleranceEleven',
                                                      )
                                                    : 'Selecciona una droguería'
                                            }
                                            priorities={priorities}
                                            positions={positions}
                                            tolerance={tolerance}
                                            setTolerance={setTolerance}
                                            handlePriorities={handlePriorities}
                                            selectArray={selectArray}
                                            userRole={userRole}
                                            check={check}
                                            setCheck={setCheck}
                                            setControl={setControl}
                                            handleMouseEnter={handleMouseEnter}
                                            handleMouseLeave={handleMouseLeave}
                                            setSelectedItems={setSelectedItems}
                                            selectedItems={selectedItems}
                                            setAllSelectedItems={setAllSelectedItems}
                                            allSelectedItems={allSelectedItems}
                                        />
                                    </div>
                                );
                            })}
                        </Grid>
                    </div>

                    <Grid
                        item
                        container
                        xs={12}
                        justify="space-between"
                        alignItems="flex-end"
                        style={{ padding: '8px 8px', position: 'absolute', bottom: 0 }}
                    >
                        <>
                            <div>
                                <ButtonGhost
                                    text={
                                        EX5057
                                            ? t(
                                                  'efficientPurchasing.priorityAndTolerance.efficientPurchasing_priorityAndToleranceThirteen',
                                              )
                                            : 'Eliminar prioridad'
                                    }
                                    onClick={handleDeletePriorities}
                                    disabled={!available?.length}
                                    height={40}
                                    width={''}
                                />
                            </div>
                            <div>
                                <ButtonOutline
                                    text={EX5225 ? t('credentialplaceholder_three') : 'Cancelar'}
                                    height={40}
                                    width={''}
                                    onClick={handleCloseModal}
                                    margin="0px 8px 0px"
                                />
                                <ButtonSolid
                                    gtagClass="modal__priority-config-save"
                                    text={
                                        <span className={'modal__priority-config-save'}>
                                            {EX5057
                                                ? t(
                                                      'efficientPurchasing.priorityAndTolerance.efficientPurchasing_priorityAndToleranceFifteen',
                                                  )
                                                : 'Guardar'}
                                        </span>
                                    }
                                    onClick={handleValues}
                                    disabled={disabled}
                                    height={40}
                                    width={''}
                                />
                            </div>
                        </>
                    </Grid>
                </Grid>
            </Modal>

            <ModalConfirm
                deletePriority={deletePriority}
                unassigned={''}
                open={open}
                array={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
                transformOrigin={{ vertical: 'center', horizontal: 'center' }}
                role={undefined}
                style={{
                    backgroundColor: rgba(palette.neutral[900], 0.3),
                    padding: 0,
                }}
                handleClose={handleDeletePriorities}
            />
        </>
    );
}

function ModalConfirm({ handleClose, array, deletePriority, ...props }: ModalPriorities) {
    const { palette } = useTheme();
    const router = useRouter();
    const { orderId } = router.query;
    const PapertStyle = {
        width: 300,
        padding: `${rem('16px')} ${rem('8px')} 0px ${rem('20px')}`,
    };

    const handleDelete = async () => {
        gtag.event({
            action: 'click',
            category: 'efficient-purchase__product-comparison',
            label: 'modal-delete-priority-config__delete',
            value: 0,
        });
        await deletePriority(orderId);
        handleClose(true);
    };

    return (
        <Menu {...props} PaperProps={{ style: PapertStyle }}>
            <Grid container>
                <Grid item container xs={10}>
                    <Text fontSize="20px" color={palette.neutral[900]} fontWeight={600}>
                        {EX5057
                            ? t(
                                  'efficientPurchasing.priorityAndTolerance.efficientPurchasing_priorityAndToleranceThirteen',
                              )
                            : 'Eliminar prioridad'}
                    </Text>
                    <Text
                        fontSize="16px"
                        color={palette.neutral[800]}
                        fontWeight="normal"
                        style={{ marginTop: '20px' }}
                    >
                        {EX5057
                            ? t(
                                  'efficientPurchasing.priorityAndTolerance.efficientPurchasing_priorityAndToleranceTwentyThree',
                              )
                            : 'Se eliminará la prioridad de compra y tendrás que seleccionar los precios iguales manualmente.'}
                        <br />
                    </Text>
                </Grid>

                <Grid item container xs={12} justify="flex-end" alignItems="flex-end" style={{ marginTop: 25 }}>
                    <Button colorName={palette.Ra} variant={'outlined'} onClick={handleClose}>
                        {EX5225 ? t('credentialplaceholder_three') : 'Cancelar'}
                    </Button>

                    <Button
                        style={{ marginLeft: 8 }}
                        colorName={palette.Ra}
                        variant="contained"
                        onClick={handleDelete}
                        className="modal__priority-config-delete"
                    >
                        <span className={'modal__priority-config-delete'}>
                            {EX5225 ? t('modals.comparativeThree') : 'Eliminar'}
                        </span>
                    </Button>
                </Grid>
            </Grid>
        </Menu>
    );
}
