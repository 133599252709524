import { useState, useEffect, useRef } from 'react';
// style
import { AutoComplete, InputGroup } from 'rsuite';
import style from './AutoCompleteSearchHome.module.css';

// mui
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { RenderFooter, RenderLastSearch, RenderMenuItem, RenderWithoutRecordset, UploaderButton } from '../InputSearch';
import { inputSearchRecordsetFormat } from './utils';
// rsuite
import SpinnerIcon from '@rsuite/icons/legacy/Spinner';
import { NewUseCart, useNewSearchProduct } from 'context';
import { useUser } from 'utils/hooks';
import { RenderWithoutRecordsetV2 } from '../AutoCompleteSearch/RenderWithoutRecordsetV2';
import { t } from 'i18next';
import { handleKeyDownInputPicker } from 'utils/dataKeyPress';

const AutoCompleteSearchHome = ({
    openDropdown,
    onClickAddProduct,
    pointOfSaleId,
    handlerSendWithEnter,
    selectedIndex,
    setProductNameSelected,
    onClickButtonByCode,
    searchValue,
    activeSearches,
    productNameSelected,
    setOpenFocus,
    onSearch,
    setSelectedIndex,
    router,
    setActiveSearches,
    visibleClear,
    onClickClose,
    setVisibleClear,
    onClickVisibleDragDrop,
    activeShakeAnimation,
    isClickButtonByCode,
    from,
}) => {
    const inputRef = useRef(null);
    const innerRef = useRef(null);

    // context
    const {
        searchData,
        currentSearch,
        lastSearch,
        isLoading,
        pagination,
        handleOpen,
        updateLastSearch,
        isOpen,
    } = useNewSearchProduct();
    const {
        addProduct,
        existsProductInCart,
        getProductByBarcode,
        updateProduct,
        isExpanded,
        handleResetVisibility,
        toggle,
        visibility,
    } = NewUseCart();

    const { user } = useUser({});

    const data = inputSearchRecordsetFormat(searchData);
    const [width, setWidth] = useState(0);
    const [firstSearch, setFirstSearch] = useState('');

    const getSize = () => document.getElementById('input-open-search-home');

    useEffect(() => {
        const handleResize = () => {
            setWidth(getSize()?.offsetWidth);
        };

        handleResize(); // Inicializa el ancho al cargar el componente

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize); // Limpieza del evento
    }, []);

    useEffect(() => {
        const elements: any = document.getElementsByClassName(
            'rs-anim-fade rs-anim-in placement-bottom-start rs-picker-menu',
        );
        const pickerMenuElement = elements[0];

        if (pickerMenuElement) {
            pickerMenuElement.style.minWidth = `${width}px`;
            pickerMenuElement.style.left = '0';
        }
    }, [width, visibility, isExpanded]);

    const info = data && data.length > 0 ? data : lastSearch && lastSearch.length > 0 ? lastSearch : [];

    const onFooterClicked = (value) => {
        updateLastSearch(currentSearch);
        handleOpen(value);
    };

    const onAddProduct = (evt, item) => {
        updateLastSearch(currentSearch);
        onClickAddProduct(evt, item);
    };

    useEffect(() => {
        if (isClickButtonByCode) {
            const searchInput: any = document.querySelector('.rs-input');
            if (searchInput && inputRef.current) {
                inputRef.current.close();
                searchInput.focus();
                handleOpen(false);
            }
        }
    }, [isClickButtonByCode]);

    return (
        <div
            className={`${style['container-input']} ${openDropdown() ? style['border-custom'] : ''} ${
                activeShakeAnimation && style['shake-horizontal']
            }`}
            style={{ zIndex: isOpen ? 2000 : 5, position: 'sticky', top: 0 }}
            id="input-open-search-home"
            ref={innerRef}
        >
            <InputGroup className={style['input-group-container']}>
                <InputGroup.Addon className={style['input-addon-style']}>
                    <SearchOutlinedIcon className={style['icon-searching-input']} />
                </InputGroup.Addon>
                <AutoComplete
                    ref={inputRef}
                    autoComplete="off"
                    className={style['autocomplete']}
                    data={data}
                    placeholder={
                        !searchValue && lastSearch && !!activeSearches
                            ? productNameSelected
                            : t('efficientPurchasing.fileUpload.efficientPurchasing_fileUploadThirtyThree')
                    }
                    value={searchValue}
                    onChange={(e) => {
                        if (!firstSearch && currentSearch) {
                            setFirstSearch(currentSearch);
                        }
                        onSearch(e);
                    }}
                    open={openDropdown()}
                    onFocus={() => setOpenFocus(true)}
                    onBlur={() => setOpenFocus(false)}
                    onEntering={() => setOpenFocus(true)}
                    onExiting={() => handleOpen(false)}
                    onClose={() => handleOpen(false)}
                    menuClassName={style['menu-dropdown']}
                    menuStyle={{ padding: '16px 0px', width: `${width}px` }}
                    renderMenu={(menu) => {
                        if (isLoading && currentSearch.length) {
                            return (
                                <div className={style['container-loading']}>
                                    <SpinnerIcon spin className={style['loading-icon']} />
                                </div>
                            );
                        } else {
                            if (lastSearch.length > 0 && currentSearch === '') {
                                return lastSearch?.map((item, index) => {
                                    return (
                                        <RenderLastSearch
                                            key={`input_search_${index}`}
                                            productName={item}
                                            handleOpen={handleOpen}
                                            clientId={user?.client?.id}
                                            pointOfSale={pointOfSaleId}
                                            onKeyDown={(evt) => handlerSendWithEnter(user, evt)}
                                            selectedIndex={selectedIndex}
                                            index={index}
                                            setProductNameSelected={setProductNameSelected}
                                        />
                                    );
                                });
                            } else if (data && data.length > 0) {
                                return (
                                    <>
                                        {data?.slice(0, 5).map((item, index) => {
                                            return (
                                                <RenderMenuItem
                                                    label={item.label}
                                                    item={item}
                                                    onClickAddProduct={(evt) => onAddProduct(evt, item)}
                                                    existsProductInCart={existsProductInCart}
                                                    quantity={getProductByBarcode(item.value)?.quantity}
                                                    cartId={getProductByBarcode(item.value)?.id}
                                                    pointOfSaleId={pointOfSaleId}
                                                    handleUpdateProduct={updateProduct}
                                                    valueSearch={currentSearch}
                                                    visibility={visibility}
                                                    toggle={toggle}
                                                    selectedIndex={selectedIndex}
                                                    index={index}
                                                />
                                            );
                                        })}
                                        <RenderFooter
                                            values={pagination?.total}
                                            clientId={user?.client?.id}
                                            valueSearch={currentSearch}
                                            handleOpen={onFooterClicked}
                                            pointOfSale={pointOfSaleId}
                                        />
                                    </>
                                );
                            } else if (data.length === 0) {
                                return (
                                    <RenderWithoutRecordsetV2 onClickButtonByCode={onClickButtonByCode} />
                                )
                            }
                        }
                    }}
                    onKeyDown={(evt) => {
                        if (evt.key === 'Enter') {
                            handlerSendWithEnter(user, evt);
                            setFirstSearch(currentSearch);
                        }
                        if (evt.key === 'Enter' || evt.key === 'ArrowUp' || evt.key === 'ArrowDown') {
                            handleKeyDownInputPicker(
                                evt,
                                setSelectedIndex,
                                info,
                                router,
                                user,
                                pointOfSaleId,
                                currentSearch || productNameSelected,
                                'searchList',
                                setActiveSearches,
                            );
                        }
                    }}
                />
                <InputGroup.Addon className={style['input-addon-style']}>
                    <UploaderButton
                        onClickVisibleDragDrop={onClickVisibleDragDrop}
                        isOpen={isOpen}
                        visibleClear={visibleClear}
                        onClickClose={onClickClose}
                        setVisibleClear={setVisibleClear}
                    />
                </InputGroup.Addon>
            </InputGroup>
        </div>
    );
};

export default AutoCompleteSearchHome;
