interface IDelivery {
    id: number;
    drug_manufacturer: string;
    drug_manufacturer_link: string;
    drug_manufacturer_logo: string;
    credit_for_agreements: number;
    delivery_id: number;
    lines: number;
    order_file: string;
    order_pdf: string;
    products_quantity?: number;
    purchased_products: number;
    purchased_products_total_price: number;
    products: any;
    free_unit_products_quantity: number;
}

export enum DeliveryTypes {
    MANUAL = "Manual delivery",
    AUTOMATIC = "automatic"
}

export interface IDeliveriesCard {
    order_deliveries: IDelivery[];
    type: DeliveryTypes;
}
