import { useCallback, useEffect, useState } from 'react';
import { t } from 'i18next';
// components
import { useToast } from '@commons/EXComponentsLibrary';
// apis
import { changePassword } from 'api';
// utils && hooks
import { formatPayload } from '../utils';
interface FormField {
    name: string;
    translate: string;
    placeholder: string;
    value: string;
    error: boolean;
    showPassword: boolean;
    focus: boolean;
    complete: boolean;
}

export const useModalChangePassword = ({ modal }) => {
    const toaster = useToast();
    // ------------------ states ------------------
    const [loading, setLoading] = useState<boolean>(false);
    const [formState, setFormState] = useState<FormField[]>([
        {
            name: 'password',
            translate: t('home.changePassword2'),
            placeholder: t('home.changePassword2'),
            value: '',
            error: false,
            showPassword: false,
            focus: false,
            complete: false,
        },
        {
            name: 'newPassword',
            translate: t('home.changePassword3'),
            placeholder: t('home.changePassword3'),
            value: '',
            error: false,
            showPassword: false,
            focus: false,
            complete: false,
        },
        {
            name: 'repeatNewPassword',
            translate: t('home.changePassword4'),
            placeholder: t('home.changePassword4'),
            value: '',
            error: false,
            showPassword: false,
            focus: false,
            complete: false,
        },
    ]);

    useEffect(() => {
        if (!modal.visibility) {
            resetFormState();
        }
    }, [modal]);

    // ------------------ reset state ------------------
    const resetFormState = () => {
        setLoading(false);
        setFormState((prevState) =>
            prevState.map((field) => ({
                ...field,
                value: '',
                error: false,
                showPassword: false,
                focus: false,
                complete: false,
            })),
        );
    };

    // ------------------ setter value inputs ------------------
    const updateFormField = useCallback((key: string, fieldName: keyof FormField, value: any) => {
        setFormState((prevState) =>
            prevState.map((field) => {
                return field.name === key ? { ...field, [fieldName]: value } : field;
            }),
        );
    }, []);

    // ------------------ callback new password conditions ------------------
    const handleNewPasswordCallback = (v: boolean): void => {
        if (formState[1]?.focus) {
            if (!!v) {
                updateFormField('newPassword', 'error', false);
                updateFormField('newPassword', 'complete', true);
            } else {
                updateFormField('newPassword', 'error', true);
                updateFormField('newPassword', 'complete', false);
            }
        }
    };

    // ------------------ callback password conditions ------------------
    const handlePasswordCallback = (v: boolean): void => {
        if (!!v) {
            updateFormField('password', 'error', false);
            updateFormField('password', 'complete', true);
        } else {
            if (formState[0].value?.trim()?.length !== 0) {
                updateFormField('password', 'error', true);
                updateFormField('password', 'complete', false);
            }
        }
    };

    // ------------------ callback repeat new password conditions ------------------
    const handleRepeatPasswordCallback = (v: boolean): void => {
        if (!!v) {
            updateFormField('repeatNewPassword', 'error', false);
            updateFormField('repeatNewPassword', 'complete', true);
        } else {
            if (formState[2].value?.trim()?.length !== 0) {
                updateFormField('repeatNewPassword', 'error', true);
                updateFormField('repeatNewPassword', 'complete', false);
            }
        }
    };

    // ------------------ check form before submit ------------------
    const checkFormValid = (): boolean => {
        let value = true;
        formState?.map((item) => {
            if (item.value?.trim()?.length === 0) {
                value = false; // si tenemos algun error retornamos falso
                updateFormField(item.name, 'error', true);
            }
        });
        return value;
    };

    // ------------------ show toaster ------------------
    const showToaster = (text: string, duration: number, type: 'error' | 'info' | 'success' | 'warning'): void => {
        toaster.showToaster({
            message: { title: text },
            duration: duration,
            type: type,
        });
    };

    // ------------------ submit ------------------
    const onSubmit = async () => {
        const isValidForm = checkFormValid();
        if (isValidForm && !loading) {
            const payload = formatPayload(formState);
            setLoading(true);
            changePassword(payload)
                .then((response) => {
                    if (response?.status === 200) {
                        showToaster(t('home.changePassword11'), 5000, 'success');
                    }
                })
                .catch((e) => {
                    console.error(e);
                    if (e?.response?.data?.data?.validation?.current_password) {
                        showToaster(t('home.changePassword.home_changePasswordTen'), 5000, 'error');
                    }
                })
                .finally(() => {
                    setLoading(false);
                    modal.toggle();
                });
        }
    };

    return {
        formState,
        onSubmit,
        handleNewPasswordCallback,
        handlePasswordCallback,
        handleRepeatPasswordCallback,
        updateFormField,
        loading,
    };
};
