import { EX5057, EX5225, EX5232 } from 'config/flags';
import { t } from 'i18next';

export const definitionPaths = (currentPath, user, pos, orders, delivery, query) => {
    let path;
    let goBack;
    let urlMyOrders;

    if (typeof window !== 'undefined') {
        urlMyOrders = localStorage?.getItem('urlRedirect');
    }

    switch (currentPath) {
        case `/[clientId]/efficient-purchase/[posId]`: {
            path = EX5057 ? t('header.header_One') : 'Compra eficiente';
            break;
        }
        case '/[clientId]/efficient-purchase': {
            path = EX5057 ? t('header.header_One') : 'Compra eficiente';
            break;
        }
        case '/[clientId]/my-orders': {
            path = EX5057 ? t('header.header_Two') : 'Mis pedidos';
            goBack = `/${user?.client?.id}/my-orders/`
            break;
        }
        case `/[clientId]/efficient-purchase/[posId]/search-results`: {
            path = EX5232 ? t('header.header_Four') : 'Resultados';
            goBack = `/${user?.client?.id}/efficient-purchase/${pos}`;
            break;
        }
        case `/[clientId]/efficient-purchase/[posId]/product-comparison`: {
            path = EX5057 ? t('header.header_One') : 'Compra eficiente';
            goBack = `/${user?.client?.id}/efficient-purchase/${pos}`;
            break;
        }
        case `/[clientId]/efficient-purchase/[posId]/confirmation`: {
            path = EX5057
                ? t('efficientPurchasing.confirmPurchase.efficientPurchasing_confirmPurchaseOne')
                : 'Confirmación';
            goBack = `/${user?.client?.id}/efficient-purchase/${pos}/product-comparison`;
            break;
        }
        case '/[clientId]/my-orders/details/[order]': {
            path = EX5225 ? `${t('myOrders.labelMyOrderNumber')}${orders}` : `Pedido # ${orders}`;
            goBack = `/${user?.client?.id}/my-orders`;
            break;
        }
        case '/[clientId]/my-orders/deliveries/[delivery]/[order]': {
            path = EX5057
                ? `${t('myOrders.labelOrderNumber')}${orders}-${delivery}`
                : `Orden de compra # ${orders}-${delivery}`;
            goBack = urlMyOrders ? `/${user?.client?.id}/${urlMyOrders}` : `/${user?.client?.id}/my-orders`;
            break;
        }
        case '/[clientId]/settings/POS/[posId]/posDetails': {
            path = !!EX5057 ? t('settings.listPointsOfSale.sett_configTextPointsOfSale') : 'Configuración';
            goBack = `/${user?.client?.id}/settings/POS`;
            break;
        }
        case '/[clientId]/settings/POS/[posId]/addOfflineDrugstore/[[...offlineDrugstoreId]]': {
            path = `${t('offlineDrugManufacturer.header.addDrugmanufacturer')}`;
            const {posId} = query;
            goBack = `/${user?.client?.id}/settings/POS/${posId}/posDetails`;
            break;
        }
        case '/pos/[clientId]/[posId]/addOfflineDrugstore/[[...offlineDrugstoreId]]': {
            path = `${t('offlineDrugManufacturer.header.addDrugmanufacturer')}`;
            const {posId} = query;
            goBack = `/pos/${user?.client?.id}/${posId}`;
            break;
        }
        case '/[clientId]/settings/POS': {
            path = !!EX5057 ? t('settings.listPointsOfSale.sett_configTextPointsOfSale') : 'Configuración';
            goBack = `/${user?.client?.id}/efficient-purchase/${pos}`;
            break;
        }
        case '/[clientId]/settings/trade-agreements': {
            path = !!EX5057 ? t('settings.listPointsOfSale.sett_configTextPointsOfSale') : 'Configuración';
            goBack = `/${user?.client?.id}/efficient-purchase/${pos}`;
            break;
        }
        case '/[clientId]/settings/trade-agreements/editAgreement/[id]': {
            path = EX5057 ? t('header.header_Three') : 'Acuerdos comerciales';
            goBack = `/${user?.client?.id}/settings/trade-agreements`;
            break
        }
        case '/[clientId]/settings/trade-agreements/create': {
            path = EX5057 ? t('header.header_Three') : 'Acuerdos comerciales';
            goBack = `/${user?.client?.id}/settings/trade-agreements`;
            break;
        }
        case '/analytics' : {
            path = 'Reporte de compras'
            break;
        }
        default:
            null;
            break;
    }

    return {
        path,
        goBack,
    };
};
