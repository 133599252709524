import { useEffect, useState } from 'react';

export const usePasswordConditionText = ({
    textString,
    password,
    callbackValidation,
    isRepeatNewPassword,
    newPassword,
    repeatNewPassword,
}) => {
    const [text, setText] = useState<string>('');
    const DEPENDECIES = isRepeatNewPassword ? [password, newPassword, repeatNewPassword] : [password];

    const checkPassword = (password: string, callbackValidation: (v: boolean) => void) => {
        if (isRepeatNewPassword) {
            if (typeof newPassword === 'string' && typeof repeatNewPassword === 'string') {
                // las contraseñas deben ser iguales
                const arePasswordsEqual: boolean = newPassword.trim() === repeatNewPassword.trim() && repeatNewPassword.trim()?.length !== 0;
                callbackValidation && callbackValidation(arePasswordsEqual);
            } else {
                callbackValidation && callbackValidation(false);
            }
        } else {
            const isValidPassowrd: boolean = password?.length >= 8 && /[A-Z]/.test(password) && /\d/.test(password);
            callbackValidation && callbackValidation(isValidPassowrd);
        }
    };

    useEffect(() => {
        setText(textString);
    }, []);

    useEffect(() => {
        checkPassword(password, callbackValidation);
    }, DEPENDECIES);

    return {
        text,
    };
};
