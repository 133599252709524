// rsuite
import { Button, ButtonToolbar, Grid } from 'rsuite';
// style
import style from './Banner.module.css';
// interface
import { IBanner } from './Banner.interface';

const Banner = ({ image,styleImage, vector, title, subtitle, label, onClick, styleTitle, gtagClass }: IBanner) => {
  return (
    <Grid>
      <div className={style['wrapper-container-images']}>
        <div className={style['image-container']}>
          <div className={style['wrapper-images']}>
            <img src={image} className={styleImage}/>
            {!!vector && (
              <div className={style['image-vector']}>
                <img src={vector} />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={style['container-text']}>
        <p className={style['title-out-of-orders']} style={styleTitle}>{title}</p>
        <p className={style['subtitle-out-of-orders']}>{subtitle}</p>
        {!!label && (
          <ButtonToolbar>
            <Button appearance='primary' className={`${gtagClass} ${style['action-button']}`} onClick={onClick}>
              {label}
            </Button>
          </ButtonToolbar>
        )}
      </div>
    </Grid>
  );
};

export default Banner;
