import MinusIcon from '@rsuite/icons/Minus';
import PlusIcon from '@rsuite/icons/Plus';
import { useRouter } from 'next/router';
import { InputGroup, InputNumber, Loader } from 'rsuite';
import {
    handlerCounterAddition,
    handlerCounterSubtraction,
    handlerInputValidation,
} from './handlers/CounterOperations';
import { useUser } from 'utils/hooks';
import { COUNTRY } from 'utils/country';

const RsuiteCounter = ({
    idx,
    value,
    handleWriting,
    handleInput,
    handleMinus,
    handlePlus,
    hasError,
    min = 1,
    isFetching = false,
    styles = {},
    tabOrigin = '',
}) => {
    const { user, userLocaleCountryCode } = useUser({});
    const router = useRouter();
    const EX5937 = user?.EX5937 && userLocaleCountryCode !== COUNTRY.AR
    
    // CHECKEAR CON NUEVAS URL
    const renderG4tagHtml = () => {
        let value = '';
        //if (router.pathname.split('/')[3] === '[posId]' && router.pathname.split('/').length === 4) {
        if (tabOrigin === 'open-search' || tabOrigin === 'search-results-input') {
            value = 'search-bar';
        }
        if (tabOrigin === 'cart') {
            value = 'cart';
        }
        if (tabOrigin === 'search-results-list') {
            value = 'results';
        }
        if (tabOrigin === 'recommended-product') {
            value = 'recommended-product';
        }
        if (router.pathname.split('/').at(-1) === 'product-comparison') {
            value = 'product-comparison';
        }
        return value;
    };

    return (
        <InputGroup className={`${styles['wrapper-counter']}  ${value > 0 && styles['custom-input-number-complete']} ${hasError && styles['wrapper-counter-error']}`}>
            {!!isFetching ? (
                <div className={`${styles['wrapper-loader']}`}>
                    <Loader />
                </div>
            ) : (
                <>
                    <InputGroup.Button
                        className={`counter-box__subtract-${renderG4tagHtml()}`}
                        onClick={(e: any) => {
                            e.stopPropagation();
                            handlerCounterSubtraction(+value - 1, handleMinus, min);
                        }}
                    >
                        <span className={`counter-box__subtract-${renderG4tagHtml()}`}>
                            <MinusIcon
                                id={`counter-box__remove-${idx}-${renderG4tagHtml()}`}
                                className={`counter-box__remove-${renderG4tagHtml()}`}
                            />
                        </span>
                    </InputGroup.Button>
                    <InputNumber
                        className={styles['custom-input-number']}
                        value={value}
                        onClick={(e: any) => e.stopPropagation()}
                        onKeyPress={(e: any) => {
                            e.stopPropagation();
                            if (e.key === 'Enter') {
                                handlerInputValidation(e.target.value, handleInput);
                            }
                        }}
                        onChange={(v, e: any) => {
                            e.stopPropagation();
                            if (EX5937) {
                                // add validation max limit 9 digits
                                if (typeof +v === 'number' && +v <= 999999999) {
                                    handleWriting(v, handleInput);
                                } else {
                                    return;
                                }
                            } else {
                                // add validation max limit 999
                                if (typeof +v === 'number' && +v <= 999) {
                                    handleWriting(v, handleInput);
                                } else {
                                    return;
                                }
                            }
                        }}
                        onBlur={(e: any) => {
                            e.stopPropagation();
                            handlerInputValidation(e.target.value, handleInput);
                        }}
                    />
                    <InputGroup.Button
                        className={`counter-box__add-${renderG4tagHtml()}`}
                        onClick={(e: any) => {
                            e.stopPropagation();
                            if (EX5937) {
                                // add validation max limit 9 digits
                                if (typeof +value === 'number' && +value +1 <= 999999999) {
                                    handlerCounterAddition(+value + 1, handlePlus);
                                } else {
                                    return;
                                }
                            } else {
                                // add validation max limit 999
                                if (typeof +value === 'number' && +value + 1 <= 999) {
                                    handlerCounterAddition(+value + 1, handlePlus);
                                } else {
                                    return;
                                }
                            }
                        }}
                    >
                        <span className={`counter-box__add-${renderG4tagHtml()}`}>
                            <PlusIcon
                                id={`counter-box__add-${idx}-${renderG4tagHtml()}`}
                                className={`counter-box__add-${renderG4tagHtml()}`}
                            />
                        </span>
                    </InputGroup.Button>
                </>
            )}
        </InputGroup>
    );
};

export default RsuiteCounter;
