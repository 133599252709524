// react
import { memo } from 'react';
// configs
import { EX7066 } from 'config/flags';
// rsuite
import { Dropdown } from 'rsuite';
// style
import style from './CellMenuDropdown.module.css';
// translation
import { t } from 'i18next';
//hook
import { useUser } from 'utils/hooks';
// utils
import actionMenuContext from 'utils/strings/actionMenuContext';
import { useSelectAllDrugManufacturerContext } from 'context';

const CellMenuDropdown = ({
    isEnabled,
    selectAllState,
    id,
    event,
    setOpen,
    handleSelectAllById,
    handleOnAndSelectAllById,
    handleUnselectAll,
}) => {
    // hook
    const { userLocaleCountryCode } = useUser({});
    const { selectAllDrugManufacturerId } = useSelectAllDrugManufacturerContext();
    // logic
    const actionText = actionMenuContext(userLocaleCountryCode);

    const validation = EX7066 ? id !== selectAllDrugManufacturerId : id !== selectAllState;

    return (
        <Dropdown.Menu className={style['container-dropdown-menu']}>
            <Dropdown.Item
                eventKey={1}
                onClick={() => {
                    setOpen(false);
                    event.handleOnOffEvent();
                }}
                className={isEnabled ? 'button__off' : 'button__on'}
            >
                {isEnabled
                    ? t('efficientPurchasing.onOff.newEfficientPurchasing_onOffOne', {
                          action: actionText.off,
                      })
                    : t('efficientPurchasing.onOff.newEfficientPurchasing_onOffOne', {
                          action: actionText.on,
                      })}
            </Dropdown.Item>
            <Dropdown.Item
                eventKey={2}
                onClick={() => {
                    setOpen(false);
                    if (EX7066) {
                        if (id !== selectAllDrugManufacturerId) {
                            if (!isEnabled) {
                                handleOnAndSelectAllById(id);
                            } else {
                                handleSelectAllById(id);
                            }
                        } else {
                            handleUnselectAll();
                        }
                    } else {
                        if (id !== selectAllState) {
                            handleSelectAllById(id);
                        } else {
                            event.handlerUnselectAll();
                        }
                    }
                }}
                className={validation ? 'button__select-all' : 'button__unselect-all'}
            >
                {validation ? t('efficientPurchasing.onOff.selectAll') : t('efficientPurchasing.onOff.undoSelectAll')}
            </Dropdown.Item>
        </Dropdown.Menu>
    );
};

export default memo(CellMenuDropdown);
