// component
import { ExButton } from '@commons/EXComponentsLibrary';
// style
import style from './PanelAddDrugmanufacturer.module.css';
// rsuite
import { Panel } from 'rsuite';
import { t } from 'i18next';
import { useRouter } from 'next/router';

const PanelAddDrugmanufacturer = () => {
    const router = useRouter();
    const { query } = router;

    const handleRedirect = () => {
        router.push(`/${query?.clientId}/settings/POS/${query?.posId}/posDetails`);
    };
    return (
        <Panel bordered className={style['panel-container-connected']}>
            <p style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                <span
                    style={{
                        color: 'var(--Neutral-800, #40435B)',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: '20px',
                    }}
                >
                    {t('offlineDrugManufacturer.title.drugmanufacturerOffline')}
                </span>
                <span
                    style={{
                        color: 'var(--Neutral-800, #40435B)',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '18px',
                    }}
                >
                    {t('home.tabs.addDrugmanufacturer')}
                </span>
            </p>
            <div style={{ marginTop: 16, justifyContent: 'center' }}>
                <ExButton
                    fullWidth
                    onClick={() => {
                        handleRedirect();
                    }}
                    IconComponent={
                        <img
                            src={'/assets/images/add_circle.svg'}
                            width={'100%'}
                            height={24}
                            alt="add icon"
                        />
                    }
                >
                    {t('offlineDrugManufacturer.header.addDrugmanufacturer')}
                </ExButton>
            </div>
        </Panel>
    );
};

export default PanelAddDrugmanufacturer;
