import { useEffect, useState } from 'react';
// next
import { useRouter } from 'next/router';
// components
import { ModalConfirmation } from 'commons/components';
import { PaginationList } from '@commons/components/SearchResults/ListResults';
// context
import { useNewSearchProduct } from 'context/openSearch/newSearchDataContext';
import { NewUseCart } from 'context';
import { usePointOfSale } from 'context';
// handler
import { handlerAddProductCart } from './handlers';
import { handlerChangePage, handleLimitChange } from '@commons/components/SearchResults/ListResults/handlers';
// style
import style from './SearchResults.module.css';
import { useDebounce, useUser } from 'utils/hooks';
import { t } from 'i18next';
import { ListResultsV2 } from '@commons/components/SearchResults/ListResultsV2';

const SearchResults = () => {
    // next
    const router = useRouter();
    const { search } = router.query as any;
    const { user } = useUser({});
    // states
    const [searchValue, setSearchValue] = useState('');
    const searchValueDebounce = useDebounce(searchValue, 1000);
    const [openFocus, setOpenFocus] = useState<boolean>(false);
    const [productNameSelected, setProductNameSelected] = useState(null);
    const EX5095 = user?.EX5095;
    const EX5289 = user?.EX5289;

    // context
    const {
        handleResetSearch,
        setCurrentSearch,
        searchData,
        paginationByResults,
        pagination,
        fetchData,
        isOpen,
        resultsList,
        isLoadingByResults,
        isLoading,
        lastSearch,
        currentSearch,
        setLastSearch,
        handleOpen,
        aggregationsTypes,
        updateLastSearch,
        setAggregationsTypes,
        activeShakeAnimation,
        isClickButtonByCode,
        handleClickButtonByCode,
    } = useNewSearchProduct();

    // context cart
    const {
        addProduct,
        existsProductInCart,
        getProductByBarcode,
        updateProduct,
        openModalDelete,
        setOpenModalDelete,
        emptyCart,
        visibility,
        isExpanded,
        toggle,
    } = NewUseCart();

    // context pointOfSale
    const { pointOfSale } = usePointOfSale();
    // state
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [activeSearches, setActiveSearches] = useState(false);

    const handleFetching = (
        searchKeyword: string,
        page: number,
        per_page: number,
        loadResults: boolean,
        aggregations: any,
    ) => {
        return fetchData(searchKeyword, page, per_page, loadResults, aggregations);
    };

    useEffect(() => {
        // close dropdown when update list BY results
        const perPage = EX5289 ? 50 : 20;
        handleFetching(search, 1, perPage, true, aggregationsTypes);
    }, [aggregationsTypes]);

    // next page click
    const handleNextPage = (next_value: number) => {
        return handleFetching(search, next_value, paginationByResults.per_page, true, aggregationsTypes);
    };

    // previous page click
    const handlePreviousPage = (previous_value: number) => {
        return handleFetching(search, previous_value, paginationByResults.per_page, true, aggregationsTypes);
    };

    // setter items per page click
    const handleItemsPerPage = (per_page: number) => {
        return handleFetching(search, 1, per_page, true, aggregationsTypes);
    };

    return (
        <div className={style['container']}>
            <ModalConfirmation
                open={openModalDelete}
                handleClose={() => setOpenModalDelete(!openModalDelete)}
                handleConfirm={() => emptyCart(pointOfSale?.id)}
                backdrop={true}
                title={t('shoppingCart.shoppingCart_Seventeen')}
                body={t('shoppingCart.shoppingCart_Eighteen')}
            />
            {
                <ListResultsV2
                    recordset={resultsList}
                    pagination={paginationByResults}
                    onClickAddProductCart={(item) => handlerAddProductCart(item, addProduct, 'list')}
                    handleNextPage={handleNextPage}
                    handlePreviousPage={handlePreviousPage}
                    handleItemsPerPage={handleItemsPerPage}
                    isLoading={isLoadingByResults}
                    isOpen={isOpen}
                    existsProductInCart={existsProductInCart}
                    getProductByBarcode={getProductByBarcode}
                    updateProduct={updateProduct}
                    pointOfSale={pointOfSale}
                    setSelectedIndex={setSelectedIndex}
                    selectedIndex={selectedIndex}
                    setActiveSearches={setActiveSearches}
                    onClickButtonByCode={handleClickButtonByCode}
                    currentSearch={currentSearch}
                />
            }

            {EX5095 && paginationByResults?.total > 20 && (
                <PaginationList
                    total={paginationByResults?.total}
                    activePage={paginationByResults?.current_page}
                    limit={paginationByResults?.per_page}
                    onChangePage={(new_page) =>
                        new_page > paginationByResults?.current_page
                            ? handlerChangePage(new_page, handleNextPage)
                            : handlerChangePage(new_page, handlePreviousPage)
                    }
                    onChangeLimit={(limit) => handleLimitChange(limit, handleItemsPerPage)}
                    pages={paginationByResults?.total_pages}
                    isLoading={isLoading}
                />
            )}
        </div>
    );
};

export default SearchResults;
