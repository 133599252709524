import React from 'react';
// INTERFACES
import { CloseMessageProps } from './CloseMessage.interface';
// STYLES
import styles from '../RecommendedCellCdo.module.css';

const CloseMessage = ({ setShowRecommendations }: CloseMessageProps): JSX.Element => {
    return (
        <div className={styles['limit-recommendation-close']}>
            <p onClick={() => setShowRecommendations()}>
                Cerrar <img src="/assets/images/close_blue.png" width={16} height={16} />
            </p>
        </div>
    );
};

export default CloseMessage;
