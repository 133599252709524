// export const handlerWidthColumns = (cdoFF: boolean, isArg: boolean, drugManufacturer: any, cellWidth: string) => {
//     if (cdoFF && isArg && drugManufacturer?.length > 0) {
//         // SET ARG
//         const widthSecondColumn = drugManufacturer?.length >= 2 ? 'auto' : '25%';
//         const widthThirdColumn = drugManufacturer?.length >= 2 ? cellWidth : '25%';
//         // set width second and third columns by ARG
//         // SECOND COLUMN
//         document.documentElement.style.setProperty('--second-column-min-width', '146px');
//         document.documentElement.style.setProperty('--second-column-max-width', widthSecondColumn);
//         // THIRD COLUMN
//         document.documentElement.style.setProperty('--third-column-min-width', '146px');
//         document.documentElement.style.setProperty('--third-column-max-width', widthThirdColumn);
//         // CELLS IN SECOND COLUMN AND THIRD COLUMN
//         document.documentElement.style.setProperty('--cell-max-width', cellWidth);
//         // FIRST COLUMN
//         if (drugManufacturer?.length <= 2) {
//             document.documentElement.style.setProperty('--first-column-with-cdo-max-width', '50%');
//         } else if (drugManufacturer?.length <= 4) {
//             document.documentElement.style.setProperty('--first-column-with-cdo-max-width', '40%');
//         } else if (drugManufacturer?.length === 5) {
//             document.documentElement.style.setProperty('--first-column-with-cdo-max-width', '33.3%');
//         } else {
//             document.documentElement.style.setProperty('--first-column-with-cdo-max-width', '306px');
//         }
//     } else if (!cdoFF && !isArg && drugManufacturer?.length > 0) {
//         // SET !ARG
//         // FIRST COLUMN
//         if (drugManufacturer?.length <= 3) {
//             document.documentElement.style.setProperty('--first-column-max-width', '50%');
//         } else if (drugManufacturer?.length <= 4) {
//             document.documentElement.style.setProperty('--first-column-max-width', '40%');
//         } else {
//             document.documentElement.style.setProperty('--first-column-max-width', '306px');
//         }
//         // CELLS IN SECOND COLUMN
//         document.documentElement.style.setProperty('--cell-max-width', cellWidth);
//     }
// };

export const handlerWidthColumns = (cdoFF: boolean, isArg: boolean, drugManufacturer: any, cellWidth: string) => {
    document.documentElement.style.setProperty('--cell-max-width', cellWidth);
    if (drugManufacturer?.length <= 3) {
        document.documentElement.style.setProperty('--first-column-max-width', '50%');
    } else if (drugManufacturer?.length <= 4) {
        document.documentElement.style.setProperty('--first-column-max-width', '40%');
    } else if (drugManufacturer?.length <= 6) {
        document.documentElement.style.setProperty('--first-column-max-width', '35%');
    } else {
        document.documentElement.style.setProperty('--first-column-max-width', '306px');
    }
};
