// rsuite
import { DatePicker } from 'rsuite';
// date-fns
import { es } from 'date-fns/locale';
import format from 'date-fns/format';
// style
import style from './DaySetting.module.css';
// aux
import { auxiliaryDatePicker, auxiliaryFxs } from './utils';

const DaySetting = ({ value, setSelectedDate, availableDates }) => {
    // utils
    const fx = auxiliaryDatePicker();
    // logic
    const actions = auxiliaryFxs();

    return (
        <div className={style['tooltip-container']}>
            <div className={style['tooltip-trigger']}>
                <img src="/assets/images/schedule.svg" width={16} height={16} />
                <DatePicker
                    className={style['date-picker']}
                    appearance="subtle"
                    caretAs={null}
                    size="xs"
                    value={value}
                    placement='auto'
                    editable={false}
                    onChange={(date) => actions.handleSelectedDate(date, setSelectedDate)}
                    locale={fx.setLocale}
                    shouldDisableDate={(date) => fx.disabledDatePicker(date,availableDates)}
                    renderValue={(value, formatString) => format(value, "dd 'de' MMMM", { locale: es })}
                    isoWeek
                />
            </div>
            <div className={style['tooltip-content']}>Modificar día</div>
        </div>
    );
};

export default DaySetting;
