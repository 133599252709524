import React, { useEffect, useState } from 'react';
// COMPONENTS
import { RsuiteTooltip } from '@commons/components/RsuiteTooltip';
// UTILS
import { textInChip, textSuizaTucuman } from '../../utils';

const OnlyDiscount = ({ product, productPVP, is_suiza_tucuman, drugManufacturerId, t }): JSX.Element => {
    const [isTooltipDisabled, setIsTooltipDisabled] = useState<boolean>(true);
    const textId = `${product?.id}${product?.discount?.discountPercentage} %
    ${is_suiza_tucuman ? textSuizaTucuman(product) : textInChip(product, productPVP, drugManufacturerId, t)}`;

    useEffect(() => {
        const checkOverflow = () => {
            const element = document.getElementById(textId);
            if (element) {
                setIsTooltipDisabled(element.offsetWidth >= element.scrollWidth);
            }
        };

        // Timeout to ensure the DOM is fully updated
        const timeoutId = setTimeout(checkOverflow, 100);

        window.addEventListener('resize', checkOverflow);
        return () => {
            clearTimeout(timeoutId);
            window.removeEventListener('resize', checkOverflow);
        };
    }, [product, is_suiza_tucuman, drugManufacturerId]);

    return (
        <RsuiteTooltip
            placement="bottom"
            trigger="hover"
            text={`${!!productPVP ? product?.pvpPercentage : product?.discount?.discountPercentage} %
            ${is_suiza_tucuman ? textSuizaTucuman(product) : textInChip(product, productPVP, drugManufacturerId, t)}`}
            disabled={isTooltipDisabled}
            marginTop={4}
            fontSize={14}
        >
            <p id={textId}>
                {!!productPVP ? product?.pvpPercentage : product?.discount?.discountPercentage} % {''}
                {is_suiza_tucuman ? textSuizaTucuman(product) : textInChip(product, productPVP, drugManufacturerId, t)}
            </p>
        </RsuiteTooltip>
    );
};

export default OnlyDiscount;
