export const getUnitsSelectedByDrugManufacturerId = (id: string | number, units, EX5937) => {
    if (EX5937) {
        return newGetUnitsSelectedByDrugManufacturerId(id, units);
    }
    // Buscar el objeto con el drugManufacturerId correspondiente
    const drugManufacturer = units?.find((unit) => {
        if (EX5937) {
            return unit?.drugManufacturerId?.includes(id);
        } else {
            return unit?.drugManufacturerId === id;
        }
    });

    // Si no se encuentra el fabricante, retornar 0
    if (!drugManufacturer) {
        return 0;
    }

    // Sumar las cantidades de todos los productos del fabricante
    const totalQuantity = drugManufacturer?.products?.reduce(
        (accumulatedQuantity, product) => accumulatedQuantity + product?.quantity,
        0,
    );

    return totalQuantity;
};

const newGetUnitsSelectedByDrugManufacturerId = (id: string | number, units) => {
    if (!Array.isArray(units) || units.length === 0) {
        return 0;
    }

    const drugManufacturersUnits = units.filter((unit) => unit.drugManufacturerId === id);

    if (!drugManufacturersUnits || drugManufacturersUnits.length === 0) {
        return 0;
    }

    const totalQuantity = drugManufacturersUnits.reduce((accumulatedQuantity, unit) => {
        if (!Array.isArray(unit.products)) {
            return accumulatedQuantity;
        }

        const unitTotal = unit.products.reduce(
            (productAccumulatedQuantity, product) => productAccumulatedQuantity + (product?.quantity || 0),
            0,
        );

        return accumulatedQuantity + unitTotal;
    }, 0);

    return totalQuantity;
};
