// component
import { RsuiteTooltip } from '@commons/components/RsuiteTooltip';
// styles
import styles from './DefaultWithoutError.module.css';
import { StatusBadge } from '../../StatusBadge';
import { useUser } from 'utils/hooks';
import { ChipDelivery } from './ChipDelivery';

const DefaultWithoutError = ({ textTooltip, disabled, name, flag, isEnabled, delivery, offline }) => {
    const {user} = useUser({});
    const EX5242 = user?.EX5242; // FF offline drugstores
     
    return (
        <div className={styles['container-name']}>
            <RsuiteTooltip placement="top" text={textTooltip} trigger="hover" disabled={disabled}>
                <div className={flag === 'cdo' && styles['container-image-text-cdo']}>
                    {!isEnabled && flag === 'cdo' ? (
                        <img src="/assets/images/beenheredisabled.svg" />
                    ) : (
                        flag === 'cdo' && <img src="/assets/images/beenherewhite.svg" className={styles['icon-cdo']} />
                    )}
                    <div
                        className={`${styles['name']}
                            ${!isEnabled ? styles['name-disabled'] : flag === 'cdo' ? styles['name-cdo'] : ''}`}
                    >
                        {EX5242 && flag !== 'cdo' && <StatusBadge offline={offline} isEnabled={isEnabled}/>}
                        {name}
                    </div>
                    {!!delivery && delivery?.tag && <ChipDelivery delivery={delivery} isEnabled={isEnabled}/>}
                </div>
            </RsuiteTooltip>
        </div>
    );
};

export default DefaultWithoutError;
