// next
import { useRouter } from 'next/router';
// rsuite
import { ButtonToolbar, Panel, Button } from 'rsuite';
// style
import style from './BtnsClientsTypes.module.css';
// utils
import { auxiliarFunctions } from './utils';
import { ILabels } from './BtnsClientsTypes.interface';

const BtnsClientsTypes = () => {
    // next
    const router = useRouter();

    // fx
    const fx = auxiliarFunctions();

    const labels: ILabels[] = [
        {
            label: 'Cadena o farmacia',
            id: 1,
        },
        {
            label: 'Droguería',
            id: 2,
        },
    ];

    return (
        <div className={style['container']}>
            <Panel className={style['panel-info']} header={<p className={style['label']}>Tipo de cliente</p>}>
                <ButtonToolbar>
                    {labels?.map(({ label, id }: ILabels) => {
                        const routing = fx.linksRoutes(id);

                        return (
                            <Button
                                appearance="ghost"
                                block
                                key={id}
                                className={style['btn-type']}
                                onClick={() => router.push(routing)}
                            >
                                <span className={style['btn-label']}>{label}</span>
                            </Button>
                        );
                    })}
                </ButtonToolbar>
            </Panel>
        </div>
    );
};

export default BtnsClientsTypes;
