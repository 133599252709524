import { useState } from 'react';
// next
import { useRouter } from 'next/router';
// component
import { LineDivider } from '.';
// interface
import { ISettings } from './Settings.interface';
// rsuite
import { Dropdown } from 'rsuite';
// style
import style from './Settings.module.css';
// utils
import { generateDropdownItems } from './utils/items';
// helper
import { executeOnClick } from './helper';
// context
import { NewUseCart } from 'context';
import { useStatusContext } from 'context/control-state';
import { EX5783 } from 'config/flags';

const Settings = ({ title, execModal, execLogout, isZetti }: ISettings) => {
    // router
    const router = useRouter();
    //context
    const { counterStep } = useStatusContext();
    const { handleResetVisibility } = NewUseCart();

    // state
    const [isFullScreen, setIsFullScreen] = useState(false);

    return (
        <div className={style['container-setting']}>
            {counterStep === '#step-5' && <div className={style['container-blur']}></div>}
            {counterStep === '#step-4' && <div className={style['container-blur']}></div>}
            {router.pathname === '/[clientId]/efficient-purchase/[posId]/order-sent' ||
                router.pathname === '/[clientId]/efficient-purchase/[posId]/confirmation' && <LineDivider />}

            <Dropdown
                placement={EX5783 ? 'bottomStart' : 'bottomEnd'}
                title={title}
                className={style['dropdown-content']}
                style={{
                    margin:
                        router.pathname === '/[clientId]/efficient-purchase/[posId]/product-comparison' ||
                        router.pathname === '/[clientId]/efficient-purchase/[posId]/confirmation'
                            ? '0'
                            : '0px 24px',
                }}
                noCaret
                appearance="subtle"
                menuStyle={EX5783 ? {
                    marginLeft: -50,
                    right: 10,
                    minWidth: 200
                } : {
                    right: -58,
                }}
            >
                {generateDropdownItems(isFullScreen).map(({ label, icon, className, id, onClick }) => {
                    return (
                        <Dropdown.Item
                            key={`header_settings_${label}`}
                            eventKey={id}
                            className={className}
                            onClick={() => {
                                handleResetVisibility();
                                executeOnClick(
                                    id,
                                    label,
                                    onClick,
                                    router,
                                    execLogout,
                                    execModal,
                                    setIsFullScreen,
                                    isFullScreen,
                                );
                            }}
                        >
                            <div className={`${className} ${style['wrapper-dropdown-item']}`}>
                                <div className={style['wrapper-icon']}>{icon}</div>
                                <span className={className}>{label}</span>
                            </div>
                        </Dropdown.Item>
                    );
                })}
            </Dropdown>

            {(!isZetti && router.pathname === '/[clientId]/efficient-purchase/[posId]/product-comparison') ||
                router.pathname === '/[clientId]/efficient-purchase/[posId]/order-sent' ||
                router.pathname === '/[clientId]/efficient-purchase/[posId]/confirmation' || <LineDivider />}
        </div>
    );
};

export default Settings;
