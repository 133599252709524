import { COUNTRY } from 'utils/country';

/**
 * Formats the drug manufacturers' names into a string.
 *
 * @param drugManufacturersNames - An array of drug manufacturers' names.
 * @param country - The country code (optional). Defaults to 'AR'.
 * @returns The formatted string of drug manufacturers' names.
 * @throws {Error} If drugManufacturersNames is not an array or if any element is not a string.
 */
export const formatDrugManufacturers = (drugManufacturersNames: string[], country: string = 'AR'): string => {
    let connectorLabel = 'y';
    switch (country) {
        case COUNTRY.BR:
            connectorLabel = ' e ';
            break;
        case COUNTRY.US:
            connectorLabel = ' and ';
            break;
        default:
            connectorLabel = ' y ';
            break;
    }
    // Validate that drugManufacturersNames is an array
    if (!Array.isArray(drugManufacturersNames)) {
        throw new Error('drugManufacturersNames debe ser un array');
    }

    // Validate that all elements in drugManufacturersNames are strings
    if (!drugManufacturersNames.every((name) => typeof name === 'string')) {
        throw new Error('Todos los elementos de drugManufacturersNames deben ser strings');
    }

    let drugManufacturersString = '';
    const namesCopy = [...drugManufacturersNames];

    if (namesCopy.length > 1) {
        const lastWord = namesCopy.pop(); // Delete and return the last element of the array
        drugManufacturersString = namesCopy.join(', ') + (connectorLabel ? connectorLabel : ' y ') + lastWord;
    } else {
        drugManufacturersString = namesCopy.join(', '); // If there is only one element, return it as a string without connectorLabel
    }

    return drugManufacturersString;
};
