import { useEffect, useState } from 'react';

export const useAlertBanner = () => {
    const [hiddenAlert, setHiddenAlert] = useState<any>('');

    //initial alert banner
    useEffect(() => {
        const hiddenAlert = localStorage.getItem('alertBanner');

        if (hiddenAlert) {
            setHiddenAlert(hiddenAlert);
        }
    }, []);
    return {
        hiddenAlert,
    };
};
