import React from 'react';
import { t } from 'i18next';
// components
import { Layout } from '@commons/modules/Auth/Layout';
import { Form } from 'rsuite';
import { ExButton, FormInput } from '@commons/EXComponentsLibrary';
import { PasswordConditions } from '@commons/components';
import MobileWrapper from '@commons/modules/Auth/Login/FormAuth/Mobile/MobileWrapper';
import { HeaderMobile } from '@commons/modules/Auth/HeaderMobile';
import MessageOnlyDesktop from '@commons/modules/Auth/MessageOnlyDesktop/MessageOnlyDesktop';
// utils && hooks
import { useActivateUser, useMailTo, usePolicyOrTerms, useShowPassword } from '@commons/modules/Auth/hooks';
import { PolicyOrTerms } from '../PolicyOrTerms';
// styles
import styles from './ActivateForm.module.css';

const ActivateForm = () => {
    const {
        formState,
        updateFormState,
        onSubmit,
        formError,
        loading,
        handlePasswordCallback,
        runValidtionPassowrd,
    } = useActivateUser();
    const { showMailTo } = useMailTo();
    const { showPassword, handleShowPassword } = useShowPassword();
    const {
        openModalTerms,
        setOpenModalTerms,
        openModalPolicy,
        setOpenModalPolicy,
        checkIfHasPolicy,
        checkIfHasTerms,
        checkIfHasPolicyAndTerms,
        checkIfHasPolicyOrTerms,
        userLocaleCountryCode,
    } = usePolicyOrTerms();

    return (
        <Layout>
            <div className={styles['container-content']}>
                <div className={styles['content']}>
                    {/* title */}
                    <p className={styles['title-form']}>{t('login.login2')}</p>
                    {/* form */}
                    <Form fluid className={styles['form']} formValue={formState} onSubmit={onSubmit}>
                        <div>
                            <div className={`${styles['input']}`}>
                                <FormInput
                                    label={t('login.login10')}
                                    placeholder={t('login.login10')}
                                    isRequired={true}
                                    type={'text'}
                                    /* startIcon={<img src="/assets/images/mail.png" />} */
                                    /* showStartIcon */
                                    maxWidth
                                    autoComplete={'on'}
                                    showErrorIcon={false}
                                    error={null}
                                    name={'email'}
                                    customStyleStartIcon={{ backgroundColor: '#FFF', marginRight: 0 }}
                                    onChange={(event) => updateFormState(event, 'email')}
                                    disabled
                                />
                            </div>
                            <div className={`${styles['input']} ${styles['box-password']}`}>
                                <FormInput
                                    label={t('login.login11')}
                                    placeholder={t('login.login11')}
                                    isRequired={true}
                                    type={showPassword ? 'text' : 'password'}
                                    /* startIcon={<img src="/assets/images/lock.png" />} */
                                    /* showStartIcon */
                                    maxWidth
                                    autoComplete={'off'}
                                    showErrorIcon={false}
                                    error={null}
                                    name={'password'}
                                    customStyleStartIcon={{ backgroundColor: '#FFF', marginRight: 0 }}
                                    onChange={(event) => updateFormState(event, 'password')}
                                    applyBorderErrorStyle={formError}
                                    onEyeClick={() => handleShowPassword()}
                                />
                                <PasswordConditions
                                    runValidations={runValidtionPassowrd}
                                    password={formState?.password}
                                    callbackValidation={handlePasswordCallback}
                                />
                            </div>
                        </div>
                        <ExButton
                            type="submit"
                            status="initial"
                            className={'button__login-activate-1'}
                            withArrow={true}
                            fullWidth
                            size="lg"
                            loading={loading}
                        >
                            {t('login.login16')}
                        </ExButton>
                    </Form>
                    {userLocaleCountryCode?.CountryCode &&
                        checkIfHasPolicyOrTerms(userLocaleCountryCode?.CountryCode) && (
                            <PolicyOrTerms
                                checkIfHasPolicyAndTerms={checkIfHasPolicyAndTerms}
                                checkIfHasTerms={checkIfHasTerms}
                                checkIfHasPolicy={checkIfHasPolicy}
                                openModalTerms={openModalTerms}
                                setOpenModalTerms={setOpenModalTerms}
                                openModalPolicy={openModalPolicy}
                                setOpenModalPolicy={setOpenModalPolicy}
                                userLocaleCountryCode={userLocaleCountryCode}
                            />
                        )}
                </div>
                <div className={`link__login-contact-us ${styles['contact-us']}`} onClick={showMailTo}>
                    {t('login.login21')}
                </div>
            </div>
            {/* MOBILE */}
            <MobileWrapper>
                <HeaderMobile />
                <div className={styles['title-form']}>
                    <span className={styles['title-form']}>{t('login.login2')}</span>
                </div>
                <Form fluid className={styles['form']} formValue={formState} onSubmit={onSubmit}>
                    <div>
                        <div className={`${styles['input']}`}>
                            <FormInput
                                label={t('login.login10')}
                                placeholder={t('login.login10')}
                                isRequired={true}
                                type={'text'}
                                /* startIcon={<img src="/assets/images/mail.png" />} */
                                /* showStartIcon */
                                maxWidth
                                autoComplete={'on'}
                                showErrorIcon={false}
                                error={null}
                                name={'email'}
                                customStyleStartIcon={{ backgroundColor: '#FFF', marginRight: 0 }}
                                onChange={(event) => updateFormState(event, 'email')}
                                disabled
                            />
                        </div>
                        <div className={`${styles['input']} ${styles['box-password']}`}>
                            <FormInput
                                label={t('login.login11')}
                                placeholder={t('login.login11')}
                                isRequired={true}
                                type={showPassword ? 'text' : 'password'}
                                /* startIcon={<img src="/assets/images/lock.png" />} */
                                /* showStartIcon */
                                maxWidth
                                autoComplete={'off'}
                                showErrorIcon={false}
                                error={null}
                                name={'password'}
                                customStyleStartIcon={{ backgroundColor: '#FFF', marginRight: 0 }}
                                onChange={(event) => updateFormState(event, 'password')}
                                applyBorderErrorStyle={formError}
                                onEyeClick={() => handleShowPassword()}
                            />
                            <PasswordConditions
                                runValidations={runValidtionPassowrd}
                                password={formState?.password}
                                callbackValidation={handlePasswordCallback}
                            />
                        </div>
                    </div>
                    <ExButton
                        type="submit"
                        status="initial"
                        className={'button__login-activate-1'}
                        withArrow={true}
                        fullWidth
                        size="lg"
                        loading={loading}
                    >
                        {t('login.login16')}
                    </ExButton>
                </Form>
                <MessageOnlyDesktop />
            </MobileWrapper>
            {userLocaleCountryCode?.CountryCode && checkIfHasPolicyOrTerms(userLocaleCountryCode?.CountryCode) && (
                <PolicyOrTerms
                    checkIfHasPolicyAndTerms={checkIfHasPolicyAndTerms}
                    checkIfHasTerms={checkIfHasTerms}
                    checkIfHasPolicy={checkIfHasPolicy}
                    openModalTerms={openModalTerms}
                    setOpenModalTerms={setOpenModalTerms}
                    openModalPolicy={openModalPolicy}
                    setOpenModalPolicy={setOpenModalPolicy}
                    userLocaleCountryCode={userLocaleCountryCode}
                />
            )}
            <div className={`link__login-contact-us ${styles['contact-us-mobile']}`} onClick={showMailTo}>
                {t('login.login21')}
            </div>
        </Layout>
    );
};

export default ActivateForm;
