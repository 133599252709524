import { EX3771 } from "config/flags";
import * as gtag from "utils/gtag";

const handleClickLogoutGa = () => {
  gtag.event({
    action: "click",
    category: "efficient-purchase",
    label: "button__log-out",
    value: 0,
  });
};

export const handlerLogout = (logout, router) => {
  logout();
  localStorage.removeItem("redirectToLogin");
  localStorage.removeItem("pdvSelected");
  localStorage.removeItem('openSections');
  localStorage.removeItem('showPanel')
  EX3771 && localStorage.removeItem("recommended_products_viewed");
  handleClickLogoutGa();
  router.push("/login");
};
