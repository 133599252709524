import { Badge } from 'rsuite';
import style from './StepsCard.module.css';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useStatusContext } from 'context/control-state';
import { useTranslation } from 'react-i18next';
import { EX5057 } from 'config/flags';

const StepsCard = ({ continuous, index, step, backProps, closeProps, primaryProps, tooltipProps }) => {
    const { t } = useTranslation();
    const badge = [1, 2, 3, 4, 5, 6];

    //context
    const { counterStep } = useStatusContext();

    //use counterStep from context without hash "#" to use it in the class name
    const counterStepWithoutHash = counterStep?.slice(1);

    const getButtonClass = (buttonType) => {
        switch (buttonType) {
            case 'next':
                return index === 6
                    ? `button__step-6-end ${style['button-end']}`
                    : `button__${counterStepWithoutHash}-next ${style['button-foward']}`;
            case 'skip':
                return `link__${counterStepWithoutHash}-skip ${style['close-button']}`;
            case 'step-3':
                return `button__${counterStepWithoutHash}-skip ${style['close-button-step3']}`;
        }
    };

    // add class G4tag to icon splitting by space and getting the first class
    const arrowForwardIconClass = getButtonClass('next')?.split(' ')[0];

    return (
        <>
            {index === 3 && (
                <button {...closeProps} className={getButtonClass('step-3')}>
                    {!!EX5057 ? t('walkthrough.labelAvoidWalkthrough') : ' Omitir recorrido'}
                </button>
            )}
            <div
                className={
                    counterStep === '#step-6'
                        ? style['panel-container-pos']
                        : counterStep === '#step-4'
                        ? style['panel-container-step4']
                        : style['panel-container']
                }
            >
                <div>
                    <p className={style['title']}>{step.title}</p>
                    <p className={style['content']}>{step.content}</p>
                </div>
                <div className={style['line']}></div>

                <div className={style['footer']}>
                    <div className={style['footer-badge']}>
                        <span className={style['step']}>{index}/6</span>

                        {badge?.map((item) => (
                            <Badge
                                style={{
                                    background: item === index ? '#1A66FC' : '#E0E1E9',
                                    marginRight: 8,
                                }}
                            />
                        ))}
                    </div>

                    <div className={style['footer-actions']}>
                        {index > 1 && (
                            <button {...backProps} className={style['button-back']}>
                                {!!EX5057 ? t('walkthrough.labelStepBack') : 'Atrás'}
                            </button>
                        )}
                        {index === 6 ? (
                            <button {...closeProps} className={getButtonClass('next')}>
                                {!!EX5057 ? t('walkthrough.labelEndWalkthrough') : 'Finalizar'}{' '}
                                <ArrowForwardIcon className={`${arrowForwardIconClass} ${style['icon-arrow-end']}`} />
                            </button>
                        ) : (
                            <button {...primaryProps} className={getButtonClass('next')}>
                                {!!EX5057 ? t('walkthrough.labelNext') : 'Siguiente'}{' '}
                                <ArrowForwardIcon className={`${arrowForwardIconClass} ${style['icon-arrow']}`} />
                            </button>
                        )}
                    </div>
                </div>
            </div>
            {index !== 3 && (
                <button {...closeProps} className={getButtonClass('skip')}>
                    {!!EX5057 ? t('walkthrough.labelAvoidWalkthrough') : ' Omitir recorrido'}
                </button>
            )}
        </>
    );
};

export default StepsCard;
