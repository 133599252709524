// next
import { useRouter } from 'next/router';
// components
import { RenderFooter, RenderMenuItem, RenderWithoutRecordset } from '.';
// interface
import { IInputSearch } from './InputSearch.interface';
// context
import { useNewSearchProduct } from 'context/openSearch/newSearchDataContext';
// hooks
import { useUser } from 'utils/hooks';
// rsuite
import SpinnerIcon from '@rsuite/icons/legacy/Spinner';
import { AutoComplete } from 'rsuite';
// utils
import { inputSearchRecordsetFormat } from './utils';
// mui
import SearchIcon from '@mui/icons-material/Search';
// style
import { RenderLastSearch } from 'commons/components/OpenSearchBanner/InputSearch';
import { useEffect, useState } from 'react';
import { handleKeyDownInputPicker } from 'utils/dataKeyPress';
import { CloseButtonIcon } from './CloseButtonIcon';
import style from './InputSearch.module.css';

const InputSearch = ({
    placeholder,
    recordset,
    onSearch,
    onClickAddProduct,
    valueSearch,
    existsProductInCart,
    getProductByBarcode,
    pointOfSaleId,
    handleUpdateProduct,
    isLoading,
    pagination,
    lastSearch,
    handleCleanInput,
    openDropdown,
    setOpenFocus,
    currentSearch,
    visibility,
    isExpanded,
    toggle,
    handlerSendWithEnter,
    productNameSelected,
    setProductNameSelected,
    selectedIndex,
    setSelectedIndex,
    setActiveSearches,
}: IInputSearch) => {
    const router = useRouter();
    const [firstSearch, setFirstSearch] = useState('');
    // context
    const { handleOpen, isOpen, updateLastSearch } = useNewSearchProduct();
    // hooks
    const { user } = useUser({});
    // recordset
    const data = inputSearchRecordsetFormat(recordset);
    // state resize width
    const [width, setWidth] = useState<number>(0);

    const getSize = (): any => {
        return document.body.getElementsByClassName('rs-input');
    };

    useEffect(() => {
        setWidth(getSize()[0]?.offsetWidth);
    }, [visibility, isExpanded, openDropdown]);

    useEffect(() => {
        const element: any = document.body.getElementsByClassName('rs-picker-menu');
        if (element.length) {
            element[0].style.minWidth = `${width}px`;
        }
    }, [width, visibility, isExpanded]);

    if (typeof window !== 'undefined') {
        window.addEventListener('resize', () => {
            setWidth(getSize()[0]?.offsetWidth);
        });
    }

    const info = data && data.length > 0 ? data : lastSearch && lastSearch.length > 0 ? lastSearch : [];

    const onFooterClicked = (value: boolean) => {
        updateLastSearch(currentSearch)
        handleOpen(value)
    }

    const onAddProduct = (evt, item) => {
        updateLastSearch(currentSearch)
        onClickAddProduct(evt, item)
    }

    return (
        <div className={style['container-input']} style={{ zIndex: isOpen ? 2000 : 5, position: 'sticky', top: 0 }}>
            <AutoComplete
                id="input-open-search-results"
                autoComplete="off"
                className={style['autocomplete']}
                data={data}
                placeholder={placeholder}
                value={valueSearch}
                onChange={(e) => {
                    if (!firstSearch && valueSearch) {
                        setFirstSearch(valueSearch);
                    }
                    onSearch(e);
                }}
                open={openDropdown()}
                onFocus={() => {
                    setOpenFocus(true);
                }}
                onBlur={() => {
                    setOpenFocus(false);
                }}
                onEntering={() => {
                    setOpenFocus(true);
                }}
                onExiting={() => handleOpen(false)}
                renderMenu={(menu: any) => {
                    if (isLoading && valueSearch.length) {
                        return (
                            <div className={style['container-loading']}>
                                <SpinnerIcon spin className={style['loading-icon']} />
                            </div>
                        );
                    } else {
                        if (lastSearch && valueSearch === '') {
                            return lastSearch?.map((item, index) => {
                                return (
                                    <RenderLastSearch
                                        key={`input_search_${index}`}
                                        productName={item}
                                        handleOpen={handleOpen}
                                        clientId={user?.client?.id}
                                        pointOfSale={pointOfSaleId}
                                        onKeyDown={(evt) => {
                                            handlerSendWithEnter(user, evt);
                                        }}
                                        selectedIndex={selectedIndex}
                                        index={index}
                                        setProductNameSelected={setProductNameSelected}
                                    />
                                );
                            });
                        } else if (data && data.length > 0) {
                            return (
                                <>
                                    {data?.slice(0, 5).map((item, index) => {
                                        return (
                                            <RenderMenuItem
                                                label={item.label}
                                                item={item}
                                                onClickAddProduct={(evt) => onAddProduct(evt, item)}
                                                existsProductInCart={existsProductInCart}
                                                quantity={getProductByBarcode(item.value)?.quantity}
                                                cartId={getProductByBarcode(item.value)?.id}
                                                pointOfSaleId={pointOfSaleId}
                                                handleUpdateProduct={handleUpdateProduct}
                                                valueSearch={currentSearch}
                                                visibility={visibility}
                                                toggle={toggle}
                                                selectedIndex={selectedIndex}
                                                index={index}
                                            />
                                        );
                                    })}
                                    <RenderFooter
                                        values={pagination?.total}
                                        clientId={user?.client?.id}
                                        valueSearch={valueSearch}
                                        handleOpen={onFooterClicked}
                                        pointOfSale={pointOfSaleId}
                                    />
                                </>
                            );
                        } else if (data.length === 0) {
                            //@ts-ignore
                            return <RenderWithoutRecordset />;
                        }
                    }
                }}
                menuStyle={{ padding: '16px 0px' }}
                onKeyDown={(evt) => {
                    if (evt.key === 'Enter') {
                        handlerSendWithEnter(user, evt);
                        setFirstSearch(valueSearch);
                    }
                    handleKeyDownInputPicker(
                        evt,
                        setSelectedIndex,
                        info,
                        router,
                        user,
                        pointOfSaleId,
                        valueSearch || productNameSelected,
                        'searchList',
                        setActiveSearches,
                    );
                }}
            />
            <div className={style['autocomplete-icon-search']}>
                <SearchIcon className={style['icon']} />
            </div>
            {valueSearch.length > 0 && <CloseButtonIcon onClickClose={handleCleanInput} />}
        </div>
    );
};

export default InputSearch;
