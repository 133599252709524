import React, { memo } from 'react';
import Image from 'next/image';
import { t } from 'i18next';
// components
import { ExButton } from '@commons/EXComponentsLibrary';
import { Panel } from 'rsuite';
// utils && hooks
import { useMailTo } from '@commons/modules/Auth/hooks';
// interfaces
import { IExpiredTokenProps } from './ExpiredToken.interface';
// styles
import styles from './ExpiredToken.module.css';

const ExpiredToken = ({ loading, redirectToLogin }: IExpiredTokenProps): JSX.Element => {
    const { showMailTo } = useMailTo();
    const [firstPart, secondPart] = t('login.no_token_text')?.split(":");
    return (
        <div className={styles['container']}>
            <Panel bordered className={styles['panel']}>
                <Image
                    src={'/assets/images/IsologoVertical.svg'}
                    alt="extendeal logo"
                    width={161}
                    height={83.7}
                    draggable={false}
                />
                <div className={styles['box-description']}>
                    <p className={styles['title']}>
                        {' '}
                        <Image
                            src={'/assets/images/error.svg'}
                            alt="lock icon"
                            width={24}
                            height={24}
                            draggable={false}
                        />
                        {t('login.login31')}
                    </p>
                    <div className={styles['subtitle']}>
                        <p>{firstPart}:</p>
                        <p>{secondPart}</p>
                    </div>
                </div>
                <ExButton
                    type="submit"
                    status="initial"
                    withArrow={true}
                    fullWidth
                    size="lg"
                    loading={loading}
                    onClick={redirectToLogin}
                >
                    {t('login.login4')}
                </ExButton>
            </Panel>
            <div className={`link__back-to-login-1 ${styles['contact-us']}`} onClick={showMailTo}>
                {t('login.login21')}
            </div>
        </div>
    );
};

export default memo(ExpiredToken);
