// components
import { Panel } from 'rsuite';
import { Header } from './Header';
// interfaces
import { IFooter } from './IFooter.interface';
// styles
import styles from './Footer.module.css';
import { useUser } from 'utils/hooks';

const Footer = ({ res_link, fal_link }: IFooter) => {
    const { user } = useUser({});
    const EX5651 = user?.EX5651;
    return (
        <Panel
            className={`${EX5651 ? styles['container'] : ''}`}
            header={<Header fal_link={fal_link} res_link={res_link} />}
        />
    );
};
export default Footer;
