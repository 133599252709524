import { useEffect } from 'react';
// rsuite
import { InputGroup, Input } from 'rsuite';
// interface
import { ISelectInput } from './SelectInput.interface';
// style
import style from './SelectInput.module.css';
//gtag
import * as gtag from 'utils/gtag';
import Loop from './icons/icons';
import CloseIcon from '@mui/icons-material/Close';
const SelectInput = ({
    placeholder,
    setSearchTerms,
    currentSearch,
    setCurrentSearch,
    timerSkeleton,
    gtagClass,
}: ISelectInput) => {
  // handlers  
  const handlerCurrentSearch = () => setSearchTerms(currentSearch);

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            handlerCurrentSearch();
        }
    };

    const handleDeleteValue = () => {
      setCurrentSearch('')
      setSearchTerms('')
    }
    const handleInputFocus = () => {
        gtag.event({
            action: 'click',
            category: 'my-orders',
            label: 'input__search-orders',
            value: 0,
        });
    };

    useEffect(() => {
      if (currentSearch === '') {
          setSearchTerms('');
      }
  }, [currentSearch, setSearchTerms]);

    return (
        <div className={`${gtagClass} ${style['container-input']}`}>
            <InputGroup size="lg" className={`${gtagClass} ${style['input-group']}`} disabled={timerSkeleton}>
                <Input
                    disabled={timerSkeleton}
                    placeholder={placeholder}
                    className={`${gtagClass} ${style['input-search']}`}
                    onChange={(evt) => setCurrentSearch(evt)}
                    onKeyDown={handleKeyDown}
                    onFocus={handleInputFocus}
                    value={currentSearch}
                />
                <InputGroup.Button className={style['search-button']} onClick={() => {currentSearch ?  handleDeleteValue() : handlerCurrentSearch()} }>
                {currentSearch ? <CloseIcon style={{color:'#ACAEC4'}}/> : <Loop color={timerSkeleton ? "#ACAEC4" : "#0171e6"} />}
                </InputGroup.Button>
            </InputGroup>
        </div>
    );
};
export default SelectInput;
