import React from 'react';
// RSUITE
import { RsuiteCounter } from 'commons/components/RsuiteCounter';
import { Button, Loader } from 'rsuite';
import PlusIcon from '@rsuite/icons/Plus';
// STYLES
import styles from '../RecommendedProducts.module.css';
import { useStatusContext } from 'context/control-state';
import { EX5057 } from 'config/flags';
import { useTranslation } from 'react-i18next';

export const RecommendedCardFooter = ({
    existsProductInCart,
    isFetching,
    recommended,
    value,
    handleWriting,
    handleInput,
    handleMinus,
    handlePlus,
    disabledButtons,
    setIsFetching,
    handlerAddProductCart,
}) => {
    // translation
    const { t } = useTranslation();

    // add gtag to an span element which contains startIcon
    const btnAddRecommendedStartIcon = document.getElementsByClassName('rs-btn-start-icon');
    const { setDisplayModalOnBoarding, displayModalOnBoarding } = useStatusContext();

    if (btnAddRecommendedStartIcon.length > 0 && btnAddRecommendedStartIcon) {
        btnAddRecommendedStartIcon[0].setAttribute('class', 'button_add-recommended-product-1');
    }

    return (
        <div
            style={{
                width: '100%',
                height: 48,
                marginTop: 12,
            }}
            className={!existsProductInCart && 'button_add-recommended-product-1'}
        >
            {!!existsProductInCart ? (
                <RsuiteCounter
                    idx={recommended?.product?.ean}
                    value={value}
                    handleWriting={handleWriting}
                    handleInput={handleInput}
                    handleMinus={handleMinus}
                    handlePlus={handlePlus}
                    hasError={false}
                    min={0}
                    isFetching={isFetching}
                    styles={styles}
                    tabOrigin={'recommended-product'}
                />
            ) : (
                <Button
                    className={`button_add-recommended-product-1 ${styles['button-add-item']}`}
                    startIcon={!isFetching && <PlusIcon className="button_add-recommended-product-1" />}
                    disabled={disabledButtons}
                    onClick={(evt) => {
                        if (displayModalOnBoarding) {
                            setDisplayModalOnBoarding(false);
                        }
                        evt.stopPropagation();
                        setIsFetching(true);
                        handlerAddProductCart(recommended);
                    }}
                >
                    {isFetching ? (
                        <div className={`${styles['wrapper-loader']}`}>
                            <Loader />
                        </div>
                    ) : (
                        <span className={`button_add-recommended-product-1 ${styles['text-button-item']}`}>
                            {EX5057 ? t('shoppingCart.shoppingCart_Eleven') : 'Agregar'}
                        </span>
                    )}
                </Button>
            )}
        </div>
    );
};
