import React, { useEffect, useState, useRef } from 'react';
import { List, Panel, Row, Col, Button, Loader, Placeholder } from 'rsuite';
import PlusIcon from '@rsuite/icons/Plus';
// mui v5
import WallpaperIcon from '@mui/icons-material/Wallpaper';
// style
import style from './RenderMenuItemV2.module.css';
import { formatObject, handleChangeMomentary, handleOperation } from './handlers';
import { RsuiteCounter } from 'commons/components/RsuiteCounter';
import { NewUseCart } from 'context';
import { DetailProduct, FakeLoading } from 'commons/components/DetailProduct';
import { addEllipsis } from 'utils/strings/ellipsis.utils';
import { EX4693, EX4698 } from 'config/flags';
import { capitalizeEveryFirstLetter } from 'utils/strings/capitalizeEveryFirstLetter';
import ParagraphLoader from '@components/LoadingBody/ParagraphLoader/ParagraphLoader';
import { RenderFooter } from '../RenderFooter';
import { t } from 'i18next';

const RenderMenuItemV2 = ({
    onClickAddProductCart,
    existsProductInCart,
    pointOfSaleId,
    handleUpdateProduct,
    onKeyDown,
    selectedIndex,
    parentRef = null,
    recordsetNotCatalog,
    recordsetCatalog,
    getProductByBarcode,
    pagination,
    clientId,
    valueSearch,
    handleOpen,
    pointOfSale,
}) => {
    // STATE LOCAL INPUT
    const [value, setValue] = useState<number>(0);
    const [imageLoad, setImageLoad] = useState<boolean>(false);
    const [quantity, setQuantity] = useState<number>(0);
    const [quantities, setQuantities] = useState({});

    const containerRef = useRef(null);
    const popoverRef = useRef(null);

    const { toggle, visibility, isFetching, setIsFetching } = NewUseCart();

    // init
    const handleRecordsetChange = (recordset) => {
        const firstEan = recordset[0]?._source.ean[0]; // Obtener el primer ean del recordset
        const product = getProductByBarcode(firstEan);

        if (product) {
            setQuantity(product?.quantity || 0); // Actualizar la cantidad en el estado de `quantity`

            // Asegúrate de actualizar `quantities` correctamente con el nuevo producto
            setQuantities((prevQuantities) => ({
                ...prevQuantities,
                [firstEan]: product?.quantity || 0, // Usamos el `ean` como clave para cada producto
            }));
        }
    };

    useEffect(() => {
        if (recordsetNotCatalog) {
            handleRecordsetChange(recordsetNotCatalog);
        }
    }, [recordsetNotCatalog, getProductByBarcode]);

    useEffect(() => {
        if (recordsetCatalog) {
            handleRecordsetChange(recordsetCatalog);
        }
    }, [recordsetCatalog, getProductByBarcode]);
    // LISTEN UPDATE QUANTITY & SETTER STATE
    useEffect(() => {
        if (isFetching || quantity !== value) {
            setValue(quantity);
        }
        setTimeout(() => {
            setIsFetching(false);
        }, 1000);
    }, [quantity, value, isFetching]);

    useEffect(() => {
        if (recordsetNotCatalog) {
            const firstEan = recordsetNotCatalog[0]?._source.ean[0]; // Obtener el primer ean del recordset
            const product = getProductByBarcode(firstEan);
            setQuantity(product?.quantity || 0); // Actualizar quantity
        }
    }, [recordsetNotCatalog, getProductByBarcode]);

    useEffect(() => {
        if (imageLoad) setImageLoad(false);
    }, [recordsetNotCatalog[0]?._source.ean[0]]);

    const growUp = recordsetCatalog.length === 0 ? 5 : 2;

    return (
        <>
            {recordsetNotCatalog?.length > 0 && (
                <div className={style['container']}>
                    <p className={style['title-container']}>{t('search.four')}</p>
                    {recordsetNotCatalog?.slice(0, growUp)?.map((item, idx) => {
                        const cartId = getProductByBarcode(item._source.ean[0])?.id;
                        const quantity = getProductByBarcode(item._source.ean[0])?.quantity;
                        const resultsWithOutCatalog = item?._source?.description === null;

                        const witchImage = resultsWithOutCatalog
                            ? '/assets/images/frame_inspect.svg'
                            : `${process.env.NEXT_PUBLIC_CLOUDFRONT_IMAGES_EP}/${process.env.NEXT_PUBLIC_CLOUDFRONT_FOLDER_TWO}/${item?._source.ean[0]}.png`;

                        const onErrorWitchImage = resultsWithOutCatalog
                            ? '/assets/images/frame_inspect.svg'
                            : '/assets/images/default.png';

                        // WRITING MOMENTARY
                        const handleWriting = (v) => {
                            return handleChangeMomentary(v, setValue);
                        };

                        const handleMinus = (v, item) => {
                            setIsFetching((prevState) => ({ ...prevState, [item?._source?.ean[0]]: true }));
                            const updatedQuantities = { ...quantities };
                            updatedQuantities[item?._source?.ean[0]] = v; // Actualiza el valor de ese producto
                            setQuantities(updatedQuantities);
                            return handleOperation(formatObject(item?._source, cartId), v, handleUpdateProduct);
                        };

                        const handlePlus = (v, item) => {
                            setIsFetching((prevState) => ({ ...prevState, [item?._source?.ean[0]]: true }));
                            const updatedQuantities = { ...quantities };
                            updatedQuantities[item?._source?.ean[0]] = v; // Actualiza el valor de ese producto
                            setQuantities(updatedQuantities);
                            return handleOperation(formatObject(item?._source, cartId), v, handleUpdateProduct);
                        };

                        const handleInput = (v, item) => {
                            if (quantities[item?._source?.ean[0]] !== +v) {
                                setIsFetching((prevState) => ({ ...prevState, [item?._source?.ean[0]]: true }));
                                const updatedQuantities = { ...quantities };
                                updatedQuantities[item?._source?.ean[0]] = +v; // Actualiza el valor de ese producto
                                setQuantities(updatedQuantities);
                                return handleOperation(formatObject(item?._source, cartId), +v, handleUpdateProduct);
                            }
                        };

                        let openPopover;
                        switch (true) {
                            case item?._source?.drug:
                                openPopover = false;
                                break;
                            case !item?._source?.drug:
                                openPopover = true;
                                break;
                            default:
                                break;
                        }

                        return (
                            <div
                                className={
                                    selectedIndex === idx
                                        ? style['wrapper-menu-item-arrow']
                                        : style['wrapper-menu-item']
                                }
                                //@ts-ignore
                                tabIndex="-1"
                                onKeyDown={onKeyDown}
                                id="list-results"
                            >
                                <Col xs={18}>
                                    <div className={style['container-image-text-menu']}>
                                        {!!EX4693 ? (
                                            <>
                                                {/* {!imageLoad && (
                                                    <ParagraphLoader
                                                        graph="image"
                                                        className={style['paragraphLoaderImage']}
                                                        rows={0}
                                                    />
                                                )} */}
                                                
                                                <div className={style['container-image-img']}>
                                                    <img
                                                        src={witchImage}
                                                        alt="imagen medicamento"
                                                        onError={(e: any) => (e.target.src = onErrorWitchImage)}
                                                        onLoad={(e: any) => setImageLoad(true)}
                                                        width={onErrorWitchImage ? 18 : 55}
                                                        height={onErrorWitchImage ? 18 : 55}
                                                        style={{
                                                            display: !imageLoad ? 'none' : 'inline',
                                                            objectFit: 'cover', // Agregando objectFit para conservar la calidad
                                                        }}
                                                        className={
                                                            resultsWithOutCatalog
                                                                ? style['container-image-withoutDescription ']
                                                                : style['container-image']
                                                        }
                                                    />
                                                </div>
                                            </>
                                        ) : (
                                            <WallpaperIcon className={style['icon-wallpaper']} />
                                        )}
                                        {!!EX4698 && !!EX4693 ? (
                                            <div ref={containerRef} className={style['wrapper-detail-product']}>
                                                <DetailProduct
                                                    activePrinciple={item?._source.drug}
                                                    labBrand={item?._source.laboratory}
                                                    medicine={`${item?._source.description} ${item?._source.laboratory}`}
                                                    category={item?._source?.category}
                                                    fakeLoading={<FakeLoading imageLoad={imageLoad} />}
                                                    imageSrc={`${process.env.NEXT_PUBLIC_CLOUDFRONT_IMAGES_EP}/${process.env.NEXT_PUBLIC_CLOUDFRONT_FOLDER_ONE}/${item?._source.ean[0]}.png`}
                                                    onLoad={(e: any) => setImageLoad(true)}
                                                    innerRef={popoverRef}
                                                    onClick={() => popoverRef?.current?.close()}
                                                    imageLoad={imageLoad}
                                                    disabled={openPopover}
                                                    placement={idx + 1 <= 2 ? 'bottomStart' : 'topStart'}
                                                    container={parentRef}
                                                    onOpen={() => {}}
                                                    onClose={() => {}}
                                                    laboratory={capitalizeEveryFirstLetter(item?._source.laboratory)}
                                                    description={`${capitalizeEveryFirstLetter(
                                                        item?._source.description,
                                                    )} ${capitalizeEveryFirstLetter(item?._source.laboratory)}`}
                                                    productName={capitalizeEveryFirstLetter(item?._source.name)}
                                                    presentation={capitalizeEveryFirstLetter(
                                                        item?._source.presentation,
                                                    )}
                                                >
                                                    <div className={style['wrapper-text-menu-item']}>
                                                        <p
                                                            className={
                                                                item?._source.drug !== null
                                                                    ? style['descriptionHover']
                                                                    : style['description-out-catalog']
                                                            }
                                                        >
                                                            "{item?._source.ean[0]}"
                                                        </p>
                                                        <span className={style['container-text-out-catalog']}>
                                                            {resultsWithOutCatalog
                                                                ? 'Agregá el producto para buscarlo en tus droguerías conectadas.'
                                                                : item?._source.ean[0]}{' '}
                                                            {item?._source.drug && (
                                                                <div className={style['verticalBar']} />
                                                            )}{' '}
                                                            {(!!item?._source.drug && item?._source.drug === 'null') ||
                                                            ''
                                                                ? ''
                                                                : item?._source.drug}
                                                        </span>
                                                    </div>
                                                </DetailProduct>
                                            </div>
                                        ) : (
                                            <div className={style['wrapper-text-menu-item']}>
                                                <p>
                                                    {resultsWithOutCatalog
                                                        ? item?._source.ean[0]
                                                        : item?._source.description}
                                                </p>
                                                <span>{item?._source.ean[0]}</span>
                                            </div>
                                        )}
                                    </div>
                                </Col>
                                <Col xs={6}>
                                    <div className={style['wrapper-button-menu-item']}>
                                        {!!existsProductInCart(item?._source.ean[0]) ? (
                                            <RsuiteCounter
                                                idx={idx}
                                                value={quantities[item?._source?.ean[0]] || quantity} // Usar el estado del contador para cada producto
                                                handleWriting={handleWriting}
                                                handleInput={handleInput}
                                                handleMinus={(v) => handleMinus(v, item)} // Pasa el item para actualizar su contador
                                                handlePlus={(v) => handlePlus(v, item)} // Pasa el item para actualizar su contador
                                                hasError={false}
                                                min={0}
                                                isFetching={isFetching[item?._source?.ean[0]] || false}
                                                styles={style}
                                                tabOrigin={'search-results-list'}
                                            />
                                        ) : (
                                            <Button
                                                className={`button__add-product-results ${style['button-add-item-notCatalog']}`}
                                                startIcon={
                                                    !isFetching[item?._source?.ean[0]] && (
                                                        <PlusIcon className="button__add-product-results" />
                                                    )
                                                }
                                                onClick={(evt) => {
                                                    setIsFetching(true);
                                                    onClickAddProductCart(evt, item);
                                                    if (!visibility) {
                                                        toggle(pointOfSaleId);
                                                    }
                                                }}
                                                appearance="subtle"
                                            >
                                                {isFetching[item?._source?.ean[0]] ? (
                                                    <div className={`${style['wrapper-loader']}`}>
                                                        <Loader />
                                                    </div>
                                                ) : (
                                                    <span
                                                        className={`button__add-product-results button__add-product-search-bar-1 ${style['text-button-item']}`}
                                                    >
                                                        Agregar
                                                    </span>
                                                )}
                                            </Button>
                                        )}
                                    </div>
                                </Col>
                            </div>
                        );
                    })}
                    {recordsetCatalog?.length > 0 || (
                        <RenderFooter
                            values={pagination}
                            clientId={clientId}
                            valueSearch={valueSearch}
                            handleOpen={handleOpen}
                            pointOfSale={pointOfSaleId}
                        />
                    )}
                </div>
            )}

            {recordsetCatalog?.length > 0 && (
                <div className={style['container-catalog']}>
                    <p className={style['title-container']}>{t('search.five')}</p>
                    {recordsetCatalog?.slice(0, 2)?.map((item, idx) => {
                        const cartId = getProductByBarcode(item?._source?.ean[0])?.id;
                        const quantity = getProductByBarcode(item._source.ean[0])?.quantity;
                        const resultsWithOutCatalog = item?._source?.description === null;

                        const witchImage = resultsWithOutCatalog
                            ? '/assets/images/frame_inspect.svg'
                            : `${process.env.NEXT_PUBLIC_CLOUDFRONT_IMAGES_EP}/${process.env.NEXT_PUBLIC_CLOUDFRONT_FOLDER_TWO}/${item?._source.ean[0]}.png`;

                        const onErrorWitchImage = resultsWithOutCatalog
                            ? '/assets/images/frame_inspect.svg'
                            : '/assets/images/default.png';
                        const sourceAndLab =
                            item?._source?.laboratory !== null && item?._source?.laboratory !== 'null'
                                ? capitalizeEveryFirstLetter(
                                      resultsWithOutCatalog
                                          ? item?._source.ean[0]
                                          : `${item?._source.description}. ${item?._source.laboratory}`,
                                  )
                                : capitalizeEveryFirstLetter(
                                      `${resultsWithOutCatalog ? item?._source.ean[0] : item?._source.description}`,
                                  );

                        // WRITING MOMENTARY
                        const handleWriting = (v) => {
                            return handleChangeMomentary(v, setValue);
                        };

                        const handleMinus = (v, item) => {
                            setIsFetching((prevState) => ({ ...prevState, [item?._source?.ean[0]]: true }));
                            const updatedQuantities = { ...quantities };
                            updatedQuantities[item?._source?.ean[0]] = v; // Actualiza el valor de ese producto
                            setQuantities(updatedQuantities);
                            return handleOperation(formatObject(item?._source, cartId), v, handleUpdateProduct);
                        };

                        const handlePlus = (v, item) => {
                            setIsFetching((prevState) => ({ ...prevState, [item?._source?.ean[0]]: true }));
                            const updatedQuantities = { ...quantities };
                            updatedQuantities[item?._source?.ean[0]] = v; // Actualiza el valor de ese producto
                            setQuantities(updatedQuantities);
                            return handleOperation(formatObject(item?._source, cartId), v, handleUpdateProduct);
                        };

                        const handleInput = (v, item) => {
                            if (quantities[item?._source?.ean[0]] !== +v) {
                                setIsFetching((prevState) => ({ ...prevState, [item?._source?.ean[0]]: true }));
                                const updatedQuantities = { ...quantities };
                                updatedQuantities[item?._source?.ean[0]] = +v; // Actualiza el valor de ese producto
                                setQuantities(updatedQuantities);
                                return handleOperation(formatObject(item?._source, cartId), +v, handleUpdateProduct);
                            }
                        };
                        let openPopover;
                        switch (true) {
                            case item?._source?.drug:
                                openPopover = false;
                                break;
                            case !item?._source?.drug:
                                openPopover = true;
                                break;
                            default:
                                break;
                        }

                        return (
                            <div
                                className={
                                    selectedIndex === idx
                                        ? style['wrapper-menu-item-arrow']
                                        : style['wrapper-menu-item']
                                }
                                //@ts-ignore
                                tabIndex="-1"
                                onKeyDown={onKeyDown}
                                id="list-results"
                            >
                                <Col xs={18}>
                                    <div className={style['container-image-text-menu']}>
                                        {!!EX4693 ? (
                                            <>
                                                {!imageLoad && (
                                                    <ParagraphLoader
                                                        graph="image"
                                                        className={style['paragraphLoaderImage']}
                                                        rows={0}
                                                    />
                                                )}

                                                <img
                                                    src={witchImage}
                                                    alt="imagen medicamento"
                                                    onError={(e: any) => (e.target.src = onErrorWitchImage)}
                                                    onLoad={(e: any) => setImageLoad(true)}
                                                    width={onErrorWitchImage ? 48 : 55}
                                                    height={onErrorWitchImage ? 48 : 55}
                                                    style={{
                                                        display: !imageLoad ? 'none' : 'inline',
                                                        objectFit: 'cover', // Agregando objectFit para conservar la calidad
                                                    }}
                                                    className={
                                                        resultsWithOutCatalog
                                                            ? style['container-image-withoutDescription ']
                                                            : style['container-image']
                                                    }
                                                />
                                            </>
                                        ) : (
                                            <WallpaperIcon className={style['icon-wallpaper']} />
                                        )}
                                        {!!EX4698 && !!EX4693 ? (
                                            <div ref={containerRef} className={style['wrapper-detail-product']}>
                                                <DetailProduct
                                                    activePrinciple={item?._source.drug}
                                                    labBrand={item?._source.laboratory}
                                                    medicine={`${item?._source.description} ${item?._source.laboratory}`}
                                                    category={item?._source?.category}
                                                    fakeLoading={<FakeLoading imageLoad={imageLoad} />}
                                                    imageSrc={`${process.env.NEXT_PUBLIC_CLOUDFRONT_IMAGES_EP}/${process.env.NEXT_PUBLIC_CLOUDFRONT_FOLDER_ONE}/${item?._source.ean[0]}.png`}
                                                    onLoad={(e: any) => setImageLoad(true)}
                                                    innerRef={popoverRef}
                                                    onClick={() => popoverRef?.current?.close()}
                                                    imageLoad={imageLoad}
                                                    disabled={openPopover}
                                                    placement={idx + 1 <= 2 ? 'bottomStart' : 'topStart'}
                                                    container={parentRef}
                                                    onOpen={() => {}}
                                                    onClose={() => {}}
                                                    laboratory={capitalizeEveryFirstLetter(item?._source.laboratory)}
                                                    description={`${capitalizeEveryFirstLetter(
                                                        item?._source.description,
                                                    )} ${capitalizeEveryFirstLetter(item?._source.laboratory)}`}
                                                    productName={capitalizeEveryFirstLetter(item?._source.name)}
                                                    presentation={capitalizeEveryFirstLetter(
                                                        item?._source.presentation,
                                                    )}
                                                >
                                                    <div className={style['wrapper-text-menu-item']}>
                                                        <p
                                                            className={
                                                                item?._source.drug !== null
                                                                    ? style['descriptionHover']
                                                                    : style['description']
                                                            }
                                                        >
                                                            {sourceAndLab}
                                                        </p>
                                                        <span className={style['container-text']}>
                                                            {resultsWithOutCatalog
                                                                ? 'Agregá el producto para buscarlo en tus droguerías conectadas.'
                                                                : item?._source.ean[0]}{' '}
                                                            {item?._source.drug && (
                                                                <div className={style['verticalBar']} />
                                                            )}{' '}
                                                            {(!!item?._source.drug && item?._source.drug === 'null') ||
                                                            ''
                                                                ? ''
                                                                : item?._source.drug}
                                                        </span>
                                                    </div>
                                                </DetailProduct>
                                            </div>
                                        ) : (
                                            <div className={style['wrapper-text-menu-item']}>
                                                <p>
                                                    {resultsWithOutCatalog
                                                        ? item?._source.ean[0]
                                                        : item?._source.description}
                                                </p>
                                                <span>{item?._source.ean[0]}</span>
                                            </div>
                                        )}
                                    </div>
                                </Col>
                                <Col xs={6}>
                                    <div className={style['wrapper-button-menu-item']}>
                                        {!!existsProductInCart(item?._source.ean[0]) ? (
                                            <RsuiteCounter
                                                idx={idx}
                                                value={quantities[item?._source?.ean[0]] || quantity} // Usar el estado del contador para cada producto
                                                handleWriting={handleWriting}
                                                handleInput={handleInput}
                                                handleMinus={(v) => handleMinus(v, item)} // Pasa el item para actualizar su contador
                                                handlePlus={(v) => handlePlus(v, item)} // Pasa el item para actualizar su contador
                                                hasError={false}
                                                min={0}
                                                isFetching={isFetching[item?._source?.ean[0]] || false}
                                                styles={style}
                                                tabOrigin={'search-results-list'}
                                            />
                                        ) : (
                                            <Button
                                                className={`button__add-product-results ${style['button-add-item']}`}
                                                startIcon={
                                                    !isFetching[item?._source.ean[0]] && (
                                                        <PlusIcon className="button__add-product-results" />
                                                    )
                                                }
                                                onClick={(evt) => {
                                                    setIsFetching(true);
                                                    onClickAddProductCart(evt, item);
                                                    if (!visibility) {
                                                        toggle(pointOfSaleId);
                                                    }
                                                }}
                                            >
                                                {isFetching[item?._source.ean[0]] ? (
                                                    <div className={`${style['wrapper-loader']}`}>
                                                        <Loader />
                                                    </div>
                                                ) : (
                                                    <span
                                                        className={`button__add-product-results ${style['text-button-item']}`}
                                                    >
                                                        Agregar
                                                    </span>
                                                )}
                                            </Button>
                                        )}
                                    </div>
                                </Col>
                            </div>
                        );
                    })}
                </div>
            )}
        </>
    );
};

export default RenderMenuItemV2;
