export const obtainInitialFirstLetters = (name) => {
  // Split the name into words
  const words = name.split(' ');

  // Map through the words and get the first character of each word
  const initials = words.map((word) => word.charAt(0).toUpperCase());

  // Join the initials together and take only the first two characters
  return initials.slice(0, 2).join('');
};
