import useDragAndDropEvents from '@commons/components/OfflineDrugstores/hooks/useDragAndDropEvents';
import { useEffect, useState } from 'react';
import { Uploader, Toggle } from 'rsuite';
import DropzoneFormatStatusV2 from './DropzoneFormatStatusV2/DropzoneFormatStatusV2';
import styles from './FormatStatusUploaderV2.module.css';
import { Status, useUser } from 'utils/hooks';
import { useToggleSwitch } from 'context/toggleSwitch/ToggleSwitchContext';
import { useModalCredentialsContext } from 'context/ModalCredentials/ModalCredentials-context';

interface FormatStatusUploaderV2Props {
    statusApiCreateCredential: Status;
    setFormValue: React.Dispatch<React.SetStateAction<any>>;
}

const FormatStatusUploaderV2 = ({ statusApiCreateCredential, setFormValue }: FormatStatusUploaderV2Props) => {
    const { isToggleOn, toggleSwitch, isLoadingFile, setIsLoadingFile } = useToggleSwitch();
    const { getDrugInformationById, setIsMissingFileAndErrorFormat, isMissingFileAndErrorFormat } = useModalCredentialsContext();
    const { missing_format } = getDrugInformationById();
    const { hasAuthorization } = useUser({});
    const [fileName, setFileName] = useState<{ label: string; size: number } | null>(null);
    const [progress, setProgress] = useState<number>(50);
    const [isInvalidExtensionFile, setIsInvalidExtensionFile] = useState<boolean>(false);
    const [showUploadedFileName, setShowUploadedFileName] = useState<boolean>(false);
    const isComplete = progress === 100;

    const { handleDragEnter, handleDragLeave, handleDrop } = useDragAndDropEvents();

    const handleFileUpload = (file: File | { blobFile: File }) => {
        if(isMissingFileAndErrorFormat) {
            setIsMissingFileAndErrorFormat(false);
        }
        const extensionsAllowed = ['dds', 'dat', 'ped', 'asc'];
        const blobFile = (file as { blobFile: File }).blobFile || file;
        setShowUploadedFileName(false);
        const fileExtension = (blobFile as File)?.name.split('.').pop()?.toLowerCase();
        if ((blobFile as File)?.type !== 'text/plain' && !extensionsAllowed?.includes(fileExtension)) {
            setIsInvalidExtensionFile(true);
            return;
        } else {
            setIsInvalidExtensionFile(false);
            setFormValue((prevState: any) => ({
                ...prevState,
                file: blobFile,
            }));
            setFileName({ label: (blobFile as File).name, size: (blobFile as File).size });
            setProgress(75);
            setTimeout(() => {
                setProgress(100);
            }, 4000);
            setTimeout(() => {
                setProgress(50);
                setShowUploadedFileName(true);
            }, 6000);
        }
    };

    useEffect(() => {
        setIsLoadingFile(progress >= 75 && progress < 100);
    }, [progress]);

    const validation = missing_format && !hasAuthorization;

    return (
        <div className={styles['container']}>
            <div className={styles['container-label']}>
                <span className={styles['label-uploader']}>¿Compras con archivo en esta droguería?</span>
            </div>

            {validation && (
                <>
                    <span className={styles['sub-label-uploader']}>
                        Cargá el archivo que usas para comprar, con al menos 3 productos.
                    </span>
                    <Uploader
                        action=""
                        accept="text/*"
                        fileListVisible={false}
                        multiple={false}
                        onUpload={handleFileUpload}
                        draggable
                        onDragEnter={handleDragEnter}
                        onDragLeave={handleDragLeave}
                        onDrop={handleDrop}
                        disabled={statusApiCreateCredential === Status.Pending}
                        className={styles['uploader-format']}
                    >
                        <div>
                            <DropzoneFormatStatusV2
                                isDisabled={statusApiCreateCredential === Status.Pending}
                                progress={progress}
                                fileName={fileName}
                                isLoading={isLoadingFile}
                                isComplete={isComplete}
                                isInvalidExtensionFile={isInvalidExtensionFile}
                                showUploadedFileName={showUploadedFileName}
                                errorMissingFile={isMissingFileAndErrorFormat}
                            />
                        </div>
                    </Uploader>
                    {isMissingFileAndErrorFormat && (
                        <div className={styles['error-format-uploader-wrapper']}>
                            <img src={'/assets/images/error.svg'} width={16} height={16} alt='error icon' draggable={false} />
                            <span className={styles['error-format-uploader']}>Archivo requerido</span>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default FormatStatusUploaderV2;
