import React from 'react';
import { t } from 'i18next';
import { Button , ButtonToolbar } from 'rsuite'
import style from './CardTypesError.module.css'

const CardTypesError = ({ title, subtitle, openModalCredentials, buttonText = "" }) => {
    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'start',
                }}
            >
                <div style={{ paddingRight: 16 }}>
                    <img src="/assets/images/error.svg" />
                </div>

                <div>
                    <p className={style['title']} style={{ width: '100%' }}>
                        {title}
                    </p>
                    <span className={style['subtitle']}>
                        {subtitle}
                    </span>
                </div>
            </div>
            <div className={`button__update-credentials-update-2 ${style['container-buttons']}`}>
                <ButtonToolbar className={`button__update-credentials-update-2 ${style['button-toolbar-content']}`}>
                    <Button
                        className={`button__update-credentials-update-2 ${style['buttons-update']}`}
                        onClick={openModalCredentials}
                    >
                        <span className="button__update-credentials-update-2">
                            {
                                buttonText ? buttonText : t('efficientPurchasing.credentialsError.updateCredentials')
                            }
                        </span>
                    </Button>
                </ButtonToolbar>
            </div>
        </div>
    );
};

export default CardTypesError;
