import { apiPharmaInstance } from "utils/axios";

export const getPriority = async (orderId) => {
  try {
    const response = await apiPharmaInstance.get(
      `/api/orders/${orderId}/drug-manufacturer-priorities`
    );
    return response;
  } catch (e) {
    if (e.response.status === 404) {
      return null;
    } else {
      console.error("Hubo un error")
        }
  }
};

export const setPriority = async (orderId, data) => {
  try {
    await apiPharmaInstance.post(
      `/api/orders/${orderId}/drug-manufacturer-priorities`,
      data
    );
    return true;
  } catch (e) {
    console.error("Hubo un error")
    }
};

export const deletePriority = async (orderId) => {
  try {
    return await apiPharmaInstance.delete(
      `/api/orders/${orderId}/drug-manufacturer-priorities`
    );
  } catch (e) {
    if (e.response.status === 404) {
      console.error("Hubo un error")
        }
  }
};

// NEW ENDPOINTS BY PRIORITY
// service_id = 1 (Prioridades por dogueria)
// service_id = 2 (Distribucion)
// Request
// {
//     "service_id": 2
// }
export const switchPriority = async (pointOfSaleId: number, data: any) => {
  try {
    return await apiPharmaInstance.patch(
      `/api/points-of-sale/${pointOfSaleId}/priority-switch`,
      data
    );
  } catch (e) {
    console.error("Hubo un error")
  }
};