import Image from 'next/image';
import React from 'react';
import style from './SubscriptionFormHeader.module.css';
import { paymentMethodsIcons } from './images/images';
const SubscriptionFormHeader = () => {
    return (
        <div className={style['container-header']}>
            <Image
                src="/assets/images/Logo-extendeal-oscuro.svg"
                alt="Isologo"
                draggable={false}
                width={256}
                height={50}
            />
            <div>
                {paymentMethodsIcons.map((src, index) => (
                    <Image
                        key={index}
                        className={style['images-icon']}
                        src={src}
                        alt="logo"
                        draggable={false}
                        width={48}
                        height={48}
                    />
                ))}
            </div>
        </div>
    );
};

export default SubscriptionFormHeader;
