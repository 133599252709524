import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Menu, { MenuProps } from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import useStyles from "./Select.styles";

const StyledMenu = withStyles({
  paper: {
    color:'#646464',
    width: 96,
    boxShadow: "1px 4px 17px #00000029",
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&.MuiMenuItem-root": {
      height: "32px !important",
    },
    "&:hover": {
      background:'#EEEEEE'
    },
  },
}))(MenuItem);

export default function CustomizedMenus({first, setfirst}) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleValue =(value)=>{
    setfirst(value)
    handleClose()
  }
  const classes = useStyles();
  return (
    <div>
      <button
        onClick={handleClick}
        className={classes.menuSelect}
        id="menuSelect"
      >
        {first}
        {Boolean(anchorEl) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem>
          <ListItemText primary="20" onClick={() => handleValue("20")} />
        </StyledMenuItem>
        <StyledMenuItem>
          <ListItemText primary="50" onClick={() => handleValue("50")} />
        </StyledMenuItem>
        <StyledMenuItem>
          <ListItemText primary="100" onClick={() => handleValue("100")} />
        </StyledMenuItem>
      </StyledMenu>
    </div>
  );
}
