// React
import { useState, useEffect } from 'react';
// Rsuite
import { Table, Panel, IconButton } from 'rsuite';
// Style
import style from './ClientTableOpenpay.module.css';
// Component
import { RsuiteTooltip } from '@commons/components/RsuiteTooltip';
import { useToast } from '@commons/EXComponentsLibrary';
// Context
import { useClientDetails } from 'context/client-details.context';
// Dayjs
import dayjs from 'dayjs';
// Config flag
import { BASEURLCOPY } from 'config/flags';

const copyToClipboard = (text, toaster, title) => {
    navigator.clipboard.writeText(text).then(() => {
        toaster.showToaster({
            message: { title: title },
            duration: 2000,
            type: 'success',
        });
    });
};

const ClientTableOpenpay = () => {
    // Client
    const { client } = useClientDetails();
    // toaster
    const toaster = useToast();

    return (
        <Panel className={style['container-table']}>
            <Table
                autoHeight
                minHeight={400}
                data={client?.openpay_payment_requests}
                onRowClick={(rowData) => {
                    //console.log(rowData);
                }}
                rowClassName={style['table']}
                rowHeight={66}
            >
                <Table.Column flexGrow={1} align="center" fullText>
                    <Table.HeaderCell
                        style={{
                            background: '#D2F4F6',
                            borderRadius: '0px 4px 4px 0px',
                            color: 'var(--Neutral-800, #40435B)',
                            fontFeatureSettings: "'liga' off, 'clig' off",
                            fontFamily: '"Source Sans Pro"',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            lineHeight: '18px' /* 128.571% */,
                        }}
                    >
                        POS Id
                    </Table.HeaderCell>
                    <Table.Cell style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {(rowData) => {
                            return <p className={style['table-cell']}>{rowData.point_of_sale_ids}</p>;
                        }}
                    </Table.Cell>
                </Table.Column>

                <Table.Column flexGrow={1} fullText>
                    <Table.HeaderCell
                        style={{
                            background: '#D2F4F6',
                            borderRadius: '0px 4px 4px 0px',
                            color: 'var(--Neutral-800, #40435B)',
                            fontFeatureSettings: "'liga' off, 'clig' off",
                            fontFamily: '"Source Sans Pro"',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            lineHeight: '18px' /* 128.571% */,
                        }}
                    >
                        <span>Envío de solicitud</span>
                    </Table.HeaderCell>
                    <Table.Cell
                        className={style['cell-email']}
                        style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                    >
                        {(row) => {
                            return (
                                <>
                                    <p className={style['table-cell-email']}>{row.email}</p>
                                    <RsuiteTooltip text="Copiar email" placement={'bottom'} trigger="hover">
                                        <IconButton
                                            icon={<img src="/assets/images/contentCopy.svg" />}
                                            className={style['copy-icon-email']} // Se agrega la clase copy-icon
                                            onClick={() => {
                                                const title = 'Copiaste el email';
                                                copyToClipboard(row.email, toaster, title);
                                            }}
                                        />
                                    </RsuiteTooltip>
                                </>
                            );
                        }}
                    </Table.Cell>
                </Table.Column>

                <Table.Column flexGrow={1}>
                    <Table.HeaderCell
                        style={{
                            background: '#D2F4F6',
                            borderRadius: '0px 4px 4px 0px',
                            color: 'var(--Neutral-800, #40435B)',
                            fontFeatureSettings: "'liga' off, 'clig' off",
                            fontFamily: '"Source Sans Pro"',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            lineHeight: '18px' /* 128.571% */,
                        }}
                    >
                        Plan y precio
                    </Table.HeaderCell>
                    <Table.Cell style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        {(row) => {
                            return <p className={style['table-cell']}>{row.plan}</p>;
                        }}
                    </Table.Cell>
                </Table.Column>

                <Table.Column flexGrow={1}>
                    <Table.HeaderCell
                        style={{
                            background: '#D2F4F6',
                            borderRadius: '0px 4px 4px 0px',
                            color: 'var(--Neutral-800, #40435B)',
                            fontFeatureSettings: "'liga' off, 'clig' off",
                            fontFamily: '"Source Sans Pro"',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            lineHeight: '18px' /* 128.571% */,
                        }}
                    >
                        Fecha de envío
                    </Table.HeaderCell>
                    <Table.Cell style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        {(row) => {
                            return <p className={style['table-cell']}>{dayjs(row.created_at).format('DD/MM/YYYY')}</p>;
                        }}
                    </Table.Cell>
                </Table.Column>

                <Table.Column flexGrow={1}>
                    <Table.HeaderCell
                        style={{
                            background: '#D2F4F6',
                            borderRadius: '0px 4px 4px 0px',
                            color: 'var(--Neutral-800, #40435B)',
                            fontFeatureSettings: "'liga' off, 'clig' off",
                            fontFamily: '"Source Sans Pro"',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            lineHeight: '18px' /* 128.571% */,
                        }}
                    >
                        Vencimiento hash
                    </Table.HeaderCell>
                    <Table.Cell style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        {(row) => {
                            return (
                                <p className={style['table-cell']}>
                                    {dayjs(row?.hash_expires_at).format('DD/MM/YYYY')}
                                </p>
                            );
                        }}
                    </Table.Cell>
                </Table.Column>

                <Table.Column flexGrow={1}>
                    <Table.HeaderCell
                        style={{
                            background: '#D2F4F6',
                            borderRadius: '0px 4px 4px 0px',
                            color: 'var(--Neutral-800, #40435B)',
                            fontFeatureSettings: "'liga' off, 'clig' off",
                            fontFamily: '"Source Sans Pro"',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            lineHeight: '18px' /* 128.571% */,
                        }}
                    >
                        Fecha de suscripción
                    </Table.HeaderCell>
                    <Table.Cell style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        {(row) => {
                            return (
                                <p className={style['table-cell']}>{`${
                                    row.subscribed_at ? dayjs(row?.subscribed_at).format('DD/MM/YYYY') : '-'
                                }`}</p>
                            );
                        }}
                    </Table.Cell>
                </Table.Column>

                <Table.Column flexGrow={1} fixed="right">
                    <Table.HeaderCell
                        style={{
                            background: '#D2F4F6',
                            borderRadius: '0px 4px 4px 0px',
                            color: 'var(--Neutral-800, #40435B)',
                            fontFeatureSettings: "'liga' off, 'clig' off",
                            fontFamily: '"Source Sans Pro"',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            lineHeight: '18px',
                        }}
                    >
                        Status
                    </Table.HeaderCell>

                    <Table.Cell
                        style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', padding: '6px' }}
                        className={style['container-label-status']}
                    >
                        {(rowData) => {
                            let styles: string;
                            let icon = null;
                            let label: string;

                            switch (rowData.status.id) {
                                case 2:
                                    styles = style['label-pending-status'];
                                    icon = (
                                        <RsuiteTooltip text="Copiar link de pago" placement={'bottom'} trigger="hover">
                                            <IconButton
                                                icon={<img src="/assets/images/contentCopy.svg" />}
                                                className={style['copy-icon']} // Se agrega la clase copy-icon
                                                onClick={() => {
                                                    const text = `${BASEURLCOPY}hash=${rowData?.hash}`;
                                                    const title = 'Copiaste el link de pago';
                                                    copyToClipboard(text, toaster, title);
                                                }}
                                            />
                                        </RsuiteTooltip>
                                    );
                                    label = 'Pendiente';
                                    break;
                                case 3:
                                    styles = style['label-defeated-status'];
                                    label = 'Vencido';
                                    break;
                                default:
                                    styles = style['label-active-status'];
                                    label = 'Activo';
                                    break;
                            }

                            return (
                                <div className={style['container-label-status']}>
                                    <p className={styles}>{label}</p>
                                    {icon && icon} {/* Mostrar el ícono solo si se definió */}
                                </div>
                            );
                        }}
                    </Table.Cell>
                </Table.Column>
            </Table>
        </Panel>
    );
};

export default ClientTableOpenpay;
