import React from "react";
import { Grid } from "@material-ui/core";
import { Public, SyncAlt } from "@material-ui/icons";
import { BuyOperationType, DrugManufacturer } from "common/types";
import { Icon } from "components/icon";
import useStyles from "./OperationType.styles";

interface OperationTypeProps {
  buyOperationType: DrugManufacturer["buy_operation_type"];
}

function OperationType({ buyOperationType }: OperationTypeProps) {
  const classes = useStyles();
  const isValidOperationType = Object.values(BuyOperationType)?.includes(
    buyOperationType
  );

  const type = (operationType: DrugManufacturer["buy_operation_type"]) => {
    switch (operationType) {
      case BuyOperationType.DONT_SEND_ORDER:
      case BuyOperationType.SEND_ORDER:
        return {
          icon: SyncAlt,
          text: "Recibe los pedidos a través de Extendeal Pharma.",
        };
      case BuyOperationType.DONT_SEND_ORDER_BUT_RETURNS_OK: {
        return {
          icon: Public,
          text: "Recibe los pedidos a través de su página.",
        };
      }
    }
  };

  // const { text } = type(buyOperationType);

  if (!isValidOperationType) {
    return null;
  }

  return (
    <Grid container>
      <Grid item xs={1} container justify="center">
        {/* <Icon Component={icon} /> */}
      </Grid>
      <Grid item xs={11} container justify="flex-start">
        {/* <span className={classes.text}>{text}</span> */}
      </Grid>
    </Grid>
  );
}

export default OperationType;
