import { useEffect, useLayoutEffect, useState } from 'react';
import { usePointOfSale } from 'context';
import { isZettiFormat } from 'utils/pointOfSale';

const useHasHydrated = (beforePaint = true) => {
    const { pointOfSale } = usePointOfSale();
    const isZetti = isZettiFormat(pointOfSale);
    const [hasHydrated, setHasHydrated] = useState(false);

    const isServer = typeof window === 'undefined';
    const useEffectFn = !isZetti && beforePaint && !isServer ? useLayoutEffect : useEffect;

    useEffectFn(() => {
        if (!isZetti) {
            setHasHydrated(true);
        }
    }, []);

    return hasHydrated;
};

export default useHasHydrated;
