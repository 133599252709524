// rsuite
import { Col } from "rsuite";
// style
import { useStatusContext } from "context/control-state";
import style from "./LineDivider.module.css";

const LineDivider = () => {
  const { counterStep } = useStatusContext();
  const steps = ["#step-5", "#step-4"];
  const controlStep = steps.find((element) => counterStep === element);
  return (
    <div
      style={{alignSelf: "center" }}
      className={
        controlStep ? style["line-divider-blur"] : style["line-divider"]
      }
    >
      <hr />
    </div>
  );
};

export default LineDivider;
