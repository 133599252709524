// styles
import styles from './HeaderMobile.module.css';

const HeaderMobile = () => {
    return (
        <div className={styles['container']}>
            {/* image */}
            <img src={'/assets/images/vectores.svg'} alt="extendeal logo" draggable={false} width={30} height={27} />
        </div>
    );
};

export default HeaderMobile;
