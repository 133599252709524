import useSWR from 'swr';
import { NewOrderDelivery, ResponseType } from 'common/types';

export function useOrderDeliveries(orderId: string) {
    const { data, error, isValidating } = useSWR<ResponseType<NewOrderDelivery | any>>(`/api/orders/${orderId}/deliveries`, {
        revalidateOnFocus: false, // No revalidar al recuperar el foco
        shouldRetryOnError: false, // No reintentar en caso de error
    });

    return {
        orderDeliveries: data,
        isLoading: !error && !data,
        error,
        isValidating: isValidating,
    };
}
