import { apiPharmaInstance } from 'utils/axios';

// get
export const getDrugmanfacturersInformation = async (clientId) => {
    try {
        const response = await apiPharmaInstance.get(`/api/client-distributors/${clientId}`);
        return response.data;
    } catch (ex) {
        console.error(ex.message);
    }
};

// patch
export const patchDrugManufacturersInformation = async (clientId, payload) => {
    try {
        return await apiPharmaInstance.patch(`/api/client-distributors/${clientId}`, { ...payload });
    } catch (ex) {
        console.error(ex.message);
    }
};

// post
export const postDrugManufacturersInformation = async (payload) => {
    try {
        return await apiPharmaInstance.patch(`/api/client-distributors/`, { ...payload });
    } catch (ex) {
        console.error(ex.message);
    }
};


export const getPlansSubscriptions = async () => {
    try {
        const response = await apiPharmaInstance.get('/api/openpay/plans');
        return response?.data;
    } catch (ex) {
        console.error(ex.message)
    }
}

export const postPlansSubscriptions = async (planId, payload) => {
    try {
        return  await apiPharmaInstance.post(`/api/openpay/plans/${planId}/customer/create`,{...payload});
    } catch (ex) {
        console.error(ex.message)
    }
}