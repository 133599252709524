import React from 'react';
// COMPONENTS
import { Placeholder } from 'rsuite';
import { quantityProductsByDrugManufacturer } from '@commons/components/TableComparisonV2/utils';
// STYLES
import styles from '../../../../TableTotals.module.css';
import cellStyles from '../../../../../../CellStyles.module.css';
// INTERFACES
import { ProductQuantityProps } from './ProductQuantity.interface';

const ProductQuantity = ({
    skeleton,
    drugManufacturerEnabled,
    t,
    productQuantityByDrugManufacturer    
}: ProductQuantityProps): JSX.Element => (
    <div className={styles['quantity-products']}>
        <p className={`${!drugManufacturerEnabled && !skeleton && productQuantityByDrugManufacturer === 0 ? cellStyles['drugManufacturerDisabled'] : ''}`}>
            {skeleton ? (
                <Placeholder.Paragraph rowHeight={16} rows={1} active className={cellStyles['placeholder-price']} />
            ) : (
                quantityProductsByDrugManufacturer(t, productQuantityByDrugManufacturer)
            )}
        </p>
    </div>
);

export default ProductQuantity;
