// react
//import { useRef } from 'react';
// component
import { LineDivider } from '../../LineDivider';
import { NotificationPanel } from '../NotificationPanel';
// rsuite
import { IconButton, Badge } from 'rsuite';
// style
import style from './NotificationAlert.module.css';

const NotificationAlert = () => {
    return (
        <>
            <LineDivider />
            <div className={style['container-notification']}>
                <NotificationPanel>
                    <div>
                        <IconButton icon={<img src="/assets/images/notifications.svg" />} />
                        <div className={style['container-badge-notification']}>
                            <Badge className={style['badge-notification']} />
                        </div>
                    </div>
                </NotificationPanel>
            </div>
        </>
    );
};

export default NotificationAlert;
