import { t } from 'i18next';
import { useUser } from 'utils/hooks';
import useChangeLanguage from 'utils/hooks/use-change-language';

export const newNormalOnboarding = () => {
    const { userLocaleCountryCode } = useUser({});
    useChangeLanguage(userLocaleCountryCode);

    return [
        {
            title: t('walkthrough.welcomeEfficientPurchase'),
            content: t('shoppingCart.walkthrough.shoppingCart_walkthrough_Six'),
            placement: 'center',
            target: 'body',
            continuous: true,
            styles: {
                options: {
                    overlayColor: 'rgba(64, 67, 91, 0.90)',

                },
            },
            
        },
        {
            title: t('PLG.Step1.Title'),
            content: t('PLG.Step1.Label'),
            placement: 'auto',
            target: '#step1',
            continuous: true,
            styles: {
                options: {
                    overlayColor: 'transparent',
                    

                },
            },
        },
        {
            title: t('PLG.Step2.Title'),
            content: t('PLG.Step2.Label'),
            placement: 'bottom-start',
            target: '#step2',
            styles: {
                options: {
                    overlayColor: 'transparent',
                },
            },
        },
        {
            title:t('PLG.Step3.Title'),
            content: t('PLG.Step3.Label'),
            placement: 'left',
            target: '#step3',
            styles: {
                options: {
                    overlayColor: 'transparent',
                },
            },
        },

        /* {
            title:'¿Necesitas ayuda?',
            content: 'Si tenés dudas o querés más información, escribinos. Estamos acá para acompañarte en lo que necesites.',
            placement: 'auto',
            target: '#step4',
            styles: {
                options: {
                    overlayColor: 'transparent',
                },
            },
        }, */
        
    ];
};
