// COMPONENTS
import { ProductQuantity } from '../ProductQuantity';
import { QuantityUnits } from '../QuantityUnits';
// STYLES
import styles from '../../../../TableTotals.module.css';
// UTILS
import { COUNTRY } from 'utils/country';
// INTERFACES
import { validationMinimumPurchase } from '@commons/components/TableComparisonV2/TableFooter/utils';
import { MinimumPurchaseDetail } from '../MinimumPurchaseDetail';
import { Value } from '../Value';
import { CellExpandedProps } from './CellExpanded.interface';
import { CellButtonVendor } from '../../CellButtonVendor';
import { EDrugManufacturerTypeId } from 'utils/enums/drugmanufacturerTypeId/DrugManufacturerTypeId';

const CellExpanded = ({
    user,
    min_purchase,
    subTotalValueColumn,
    userLocaleCountryCode,
    skeleton,
    drugManufacturerEnabled,
    productQuantityByDrugManufacturer,
    quantityUnitsByDrugManufacturer,
    t,
    drugManufacturerTypeId = null,
    drugManufacturerId,
    vendor_accepted
}: CellExpandedProps) => {
    const isDisabled = !drugManufacturerEnabled && !skeleton;

    return (
        <>
            {drugManufacturerTypeId === EDrugManufacturerTypeId.vendor && !vendor_accepted && !!user?.EX6017 ? (
                <CellButtonVendor drugManufacturerId ={drugManufacturerId}/>
            ) : (
                <div className={styles['container-expanded']}>
                    {/* render value */}
                    <Value
                        isDisabled={isDisabled}
                        user={user}
                        min_purchase={min_purchase}
                        subTotalValueColumn={subTotalValueColumn}
                        t={t}
                        typeRenderFormat={userLocaleCountryCode === COUNTRY.ES}
                        skeleton={skeleton}
                        productQuantityByDrugManufacturer={productQuantityByDrugManufacturer}
                    />
                    {validationMinimumPurchase(user, min_purchase, subTotalValueColumn) ? (
                        <MinimumPurchaseDetail min_purchase={min_purchase} />
                    ) : (
                        <>
                            {/* products quantity */}
                            <ProductQuantity
                                skeleton={skeleton}
                                drugManufacturerEnabled={drugManufacturerEnabled}
                                t={t}
                                productQuantityByDrugManufacturer={productQuantityByDrugManufacturer}
                            />

                            {/*Generics quantity units */}
                            <QuantityUnits
                                skeleton={skeleton}
                                drugManufacturerEnabled={drugManufacturerEnabled}
                                t={t}
                                quantityUnitsByDrugManufacturer={quantityUnitsByDrugManufacturer}
                                productQuantityByDrugManufacturer={productQuantityByDrugManufacturer}
                            />
                        </>
                    )}
                </div>
            )}
        </>
    );
};

export default CellExpanded;
