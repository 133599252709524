import { t } from 'i18next';
import { cancel } from 'api/orders';
import { useModalWithoutStock } from 'context/ModalWithoutStock/modalWithoutStock';
import { useRouter } from 'next/router';
import { Button, Modal } from 'rsuite';
import { useUser } from 'utils/hooks';
// style
import { EX5057, EX5225 } from 'config/flags';
import { useSnackbar } from 'context';
import style from './ModalWithoutStock.module.css';

const ModalWithoutStock = () => {
    // context
    const { isModalOpen, closeModal } = useModalWithoutStock();
    // hook
    const { user } = useUser({});
    const { push, query } = useRouter();
    const snackbar = useSnackbar();
    // utils
    const orderId = query?.orderId ?? 1;
    const clientId = user?.client?.id;
    const posSelected = query?.posId ?? null;
    // build the href to redirect to
    const href = posSelected ? `/${clientId}/efficient-purchase/${posSelected}` : `/${clientId}/efficient-purchase`;

    const redirectTo = () => {
        cancel(orderId).then(() => {
            snackbar.show({
                message: EX5225 ? t('snackbar.labelDiscardOrder') : 'Se descartó la compra.',
            });
            push(href);
        });
    };

    const closeAlertModal = () => {
        redirectTo();
        closeModal();
    };

    return (
        <Modal open={isModalOpen} className={style['modal']}>
            <Modal.Header closeButton={false} className={style['modal-header']}>
                <p className={style['title-modal']}>
                    {EX5057 ? t('efficientPurchasing.comparative.outOfStockAvailable') : 'Sin stock disponible'}
                </p>
            </Modal.Header>
            <Modal.Body className={style['body-modal']}>
                <p className={style['subtitle-modal']}>
                    {EX5057
                        ? t('efficientPurchasing.comparative.outOfStockAllDrugmanufacturers')
                        : 'Lamentablemente, ninguna de las droguerías tiene stock disponible para los productos que estas buscando.'}
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button block className={style['action-button-modal']} onClick={() => closeAlertModal()}>
                    <span className={style['text-button-active']}>
                        {EX5057
                            ? t('efficientPurchasing.comparative.labelSearchNewProducts')
                            : 'Buscar otros productos'}
                    </span>
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalWithoutStock;
