import { Tooltip, Whisper } from "rsuite";
import { ITooltip } from "./Tooltip.interface";

const TooltipCard = ({ text, icon }:ITooltip) => {
  const tooltip = (
    <Tooltip arrow={false} style={{ padding: "8px 16px" }}>
      <span style={{ fontSize: 14, fontWeight: 400, lineHeight: 0 }}>
        {text}
      </span>
    </Tooltip>
  );

  return (
    <Whisper
      placement="bottomEnd"
      controlId="control-id-active"
      trigger="hover"
      speaker={tooltip}
    >
      {icon}
    </Whisper>
  );
};
export default TooltipCard;
