import { useCallback, useEffect, useState } from 'react';
import { t } from 'i18next';
// components
import { useToast } from '@commons/EXComponentsLibrary';
// apis
import { recoveryPasswordTokenVerification, resetPassword } from 'api';
import { useRouter } from 'next/router';
import useChangeLanguage from 'utils/hooks/use-change-language';
import { COUNTRY } from 'utils/country';

interface FormField {
    name: string;
    translate: string;
    placeholder: string;
    value: string;
    error?: boolean;
}

export const useNewPassword = () => {
    const toaster = useToast();
    const { push, query } = useRouter();
    // ------------------ states ------------------
    const [loading, setLoading] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const [status, setStatus] = useState(100);
    const [runValidations, setRunValidations] = useState<boolean>(false);
    const [userData, setUserData] = useState({
        token: null,
        email: null,
    });
    const [formState, setFormState] = useState<FormField[]>([
        {
            name: 'newPassword',
            translate: t('home.changePassword3'),
            placeholder: t('home.changePassword3'),
            value: '',
            error: false,
        },
        {
            name: 'repeatNewPassword',
            translate: t('home.changePassword4'),
            placeholder: t('home.changePassword4'),
            value: '',
        },
    ]);

    const userLocale = typeof window !== 'undefined' ? navigator?.language : null;

    switch (userLocale) {
        case 'es-AR':
            useChangeLanguage(COUNTRY.AR);
            break;
        case 'en-US':
            useChangeLanguage(COUNTRY.US);
            break;
        case 'pt-BR':
            useChangeLanguage(COUNTRY.BR);
            break;
        case 'es-MX':
            useChangeLanguage(COUNTRY.MX);
            break;
        case 'es-CO':
            useChangeLanguage(COUNTRY.CO);
            break;
        case 'es-ES':
            useChangeLanguage(COUNTRY.ES);
            break;
        default:
            useChangeLanguage('es');
            break;
    }

    useEffect(() => {
        if (query?.token) {
            handleFindUserByToken();
        }
    }, [query?.token]);

    // ------------------ setter value inputs ------------------
    const updateFormField = useCallback((key: string, fieldName: keyof FormField, value: any) => {
        setFormState((prevState) =>
            prevState.map((field) => {
                return field.name === key ? { ...field, [fieldName]: value } : field;
            }),
        );
    }, []);

    // ------------------ callback new password conditions ------------------
    const handleNewPasswordCallback = (v: boolean): void => {
        if (!!v) {
            updateFormField('newPassword', 'error', false);
        } else {
            if (formState[0]?.value?.trim()?.length > 0) {
                updateFormField('newPassword', 'error', true);
                setRunValidations(true);
            }
        }
    };

    // ------------------ check form before submit ------------------
    const checkFormValid = (): boolean => {
        return (
            formState[0]?.value?.trim() === formState[1]?.value?.trim() &&
            formState[0]?.value?.trim()?.length > 0 &&
            formState[1]?.value?.trim()?.length > 0
        );
    };

    // ------------------ show toaster ------------------
    const showToaster = (text: string, duration: number, type: 'error' | 'info' | 'success' | 'warning'): void => {
        toaster.showToaster({
            message: { title: text },
            duration: duration,
            type: type,
        });
    };

    const handleFindUserByToken = async () => {
        try {
            let resp = await recoveryPasswordTokenVerification(query?.token as string);
            if (resp.status === 200) {
                setStatus(200);
                setUserData(resp.data.data);
            }
        } catch (error) {
            if (error.response.status === 400) {
                setStatus(400);
            }
        }
    };

    // ------------------ submit ------------------
    const onSubmit = async () => {
        const isValidForm: boolean = checkFormValid();

        if (isValidForm && !loading) {
            userData['password'] = formState[0].value;
            userData['password_confirmation'] = formState[1].value;
            const payload = userData;
            setLoading(true);
            resetPassword(payload)
                .then((response) => {
                    setSuccess(true);
                })
                .catch((e) => {
                    console.error(e);
                    showToaster(t('home.changePassword.home_changePasswordTen'), 5000, 'error');
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const redirectToLogin = () => {
        return push('/login');
    };

    return {
        formState,
        onSubmit,
        handleNewPasswordCallback,
        updateFormField,
        loading,
        success,
        runValidations,
        redirectToLogin,
        status,
    };
};
