export const handleOperation = (product, value, setter) => {
    if (typeof (value) !== 'number') {
        return setter(product, 1);
    } else {
        return setter(product, value);
    }
};

export const handleChangeMomentary = (value, setter) => {
    return setter(value);
};