// style
import { Dropdown, Popover, Whisper, IconButton } from 'rsuite';
import style from './NotificationHeader.module.css';
// mui
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CheckIcon from '@mui/icons-material/Check';

const dropdownItem = [
    {
        icon: <CheckIcon className={style['icon-item-dropdown']} />,
        label: 'Marcar todo como leido',
    },
    {
        icon: '/assets/images/delete.svg',
        label: 'Eliminar todo',
    },
];

const NotificationHeader = () => {
    return (
        <div className={style['container-header-popover-notification']}>
            <p>Notificaciones</p>
            <Whisper
                trigger="click"
                placement="bottomStart"
                speaker={
                    <Popover arrow={false} className={style['container-popover-menu']}>
                        <Dropdown.Menu className={style['container-dropdown-menu']}>
                            {dropdownItem.map(({ icon, label },idx) => {
                                return (
                                    <div className={style['dropdown-item']} key={idx}>
                                        <Dropdown.Item eventKey={idx}>
                                            {typeof icon === 'object' ? (
                                                icon
                                            ) : (
                                                <img src={icon} className={style['icon-item-dropdown']} />
                                            )}{' '}
                                            {label}
                                        </Dropdown.Item>
                                    </div>
                                );
                            })}
                        </Dropdown.Menu>
                    </Popover>
                }
            >
                <IconButton className={style['container-button']} icon={<MoreVertIcon />} />
            </Whisper>
        </div>
    );
};

export default NotificationHeader;
