import { EX4201 } from 'config/flags';
import style from './Header.module.css';
//gtag
import * as gtag from 'utils/gtag';
import useChangeLanguage from 'utils/hooks/use-change-language';
import { useUser } from 'utils/hooks';
import { useTranslation } from 'react-i18next';
const Header = () => {
  const { t } = useTranslation();
  const { user } = useUser({});
  const userLocaleCountryCode = user?.client?.country_alfa_2;
  if (EX4201) useChangeLanguage(userLocaleCountryCode);
  const handlerDropHeader = () => {
    gtag.event({
      action: 'click',
      category: 'my-orders',
      label: 'expand__purchase-rate-1',
      value: 0,
    });
  };
  return (
    <span className={`expand__purchase-rate-1 ${style['title']}`} onClick={() => handlerDropHeader()}>
      <img
        src='/assets/images/monitoring.svg'
        alt=''
        style={{ height: 18, width: 18, marginRight: 8 }}
      />
      {!EX4201
        ? 'Porcentaje de compra por droguería'
        : t('myOrders.orderDetails.percentageBuyedByDrugstore')}
    </span>
  );
};
export default Header;
