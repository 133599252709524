import { useMemo, useRef } from 'react';
// configs
import { EX6778, FFRECOMMENDED } from 'config/flags';
// contexts
import {
    ModalDiscountsProvider,
    UpdateParentProductProvider,
    useContainerRefContext,
    useDrugManufacturersContext,
    useFetchingContext,
    useProductsContext,
    useScrollFooterContext,
    ModalProviderVolumeDiscount,
    useModalWithoutCredit,
} from 'context';
import { useProductComparison } from 'context/product-comparison.context';
import { useRecommendationsContext } from 'context/productComparison/recommendations-context';
// COMPONENTS
import { Recommended } from '../Recommended';
import { Row } from './Row';
import { Arrow } from './Arrow';
import ModalDiscounts from '@commons/modules/TableProductComparisonV2/ModalDiscounts';
import { ModalWithoutCredit } from '@commons/components/ModalWithoutCredit';
import { ModalVolumeDiscountV2 } from '@commons/components/ModalVolumeDiscountV2';
import { ModalWithoutStock } from '@commons/components/ModalWithoutStock';
import NewCredentialCardConnectionV4 from '@commons/modules/InitialConfig/PointOfSales/NewCredentialCard/NewCredentialCardConnectionV3/NewCredentialCardConnectionV4';
// HOOKS
import { useTableData, usePositionArrowLeft, usePositionArrowRight } from './hooks';
import { useCDOStatus } from '@commons/modules/TableProductComparisonV2/hooks';
import { useUser } from 'utils/hooks';

const TableProducts = () => {
    // user
    const { user } = useUser({});
    const EX6144 = user?.EX6144;
    const EX4903 = user?.EX4903;
    const EX5506 = user?.EX5506;

    // contexts
    const { containerRef } = useContainerRefContext();

    const { recommendations, getCreditById } = useProductComparison();

    const { productGroups, setProductInfo, handleRemoveProduct } = useProductsContext();

    const { loading } = useFetchingContext();

    const { drugManufacturer } = useDrugManufacturersContext();

    const { CDO_IS_VISIBLE } = useCDOStatus();

    const { showRecommendations } = useRecommendationsContext();

    const { showScrollArrowLeft, showScrollArrowRight, handleClickScroll } = useScrollFooterContext();

    const { openModal, setTypeModal } = useModalWithoutCredit();

    // states
    const tableRef = useRef(null);

    const { data } = useTableData();

    const DRUGMANUFACTURERS = useMemo(() => drugManufacturer, [drugManufacturer]);

    const WITHOUT_RECOMMENDATIONS = useMemo(() => {
        return !showRecommendations || recommendations?.length === 0;
    }, [showRecommendations, recommendations]);

    const RENDER_TABLE = useMemo(() => {
        return DRUGMANUFACTURERS?.length > 0 && productGroups?.length > 0;
    }, [DRUGMANUFACTURERS, productGroups]);

    const GRID_ROW_TABLE = EX6144 ? (WITHOUT_RECOMMENDATIONS ? 2 : 3) : WITHOUT_RECOMMENDATIONS ? 3 : 4;

    const { arrowLeft } = usePositionArrowLeft('[class^="CellProduct_cell__"]');

    const { arrowRight } = usePositionArrowRight(containerRef, CDO_IS_VISIBLE, tableRef, RENDER_TABLE);

    return (
        <>
            {EX6778 && <NewCredentialCardConnectionV4 mutateClientPos={() => {}} toggleCard={() => {}} />}
            <ModalWithoutStock />
            <ModalDiscountsProvider>
                <ModalProviderVolumeDiscount>
                    <ModalDiscounts />
                    {!!EX4903 && !EX5506 && <ModalWithoutCredit />}
                    <ModalVolumeDiscountV2 />
                    {/* ------------ recommended component ------------  */}
                    {!WITHOUT_RECOMMENDATIONS && FFRECOMMENDED && data?.length ? <Recommended /> : <></>}
                    {/* ----------------- table -----------------  */}
                    <div
                        style={{
                            gridRow: GRID_ROW_TABLE,
                            gridColumn: '1 / 4',
                            marginTop: WITHOUT_RECOMMENDATIONS ? 4 : '',
                        }}
                        ref={tableRef}
                    >
                        <Arrow
                            show={showScrollArrowLeft}
                            skeleton={loading}
                            parentRef={containerRef}
                            position={'left'}
                            positionValue={arrowLeft}
                            handlerClick={() => handleClickScroll('left')}
                        />
                        <UpdateParentProductProvider>
                            {data?.map((product, idx) => {
                                return (
                                    <Row
                                        key={product?.order_product_id}
                                        idx={idx + 1}
                                        item={product}
                                        removeEvent={handleRemoveProduct}
                                        getCreditById={getCreditById}
                                        setProductInfo={setProductInfo}
                                        openModalWithoutCredit={openModal}
                                        setTypeModalWithoutCredit={setTypeModal}
                                        loading={loading}
                                    />
                                );
                            })}
                        </UpdateParentProductProvider>
                        <Arrow
                            show={showScrollArrowRight}
                            skeleton={loading}
                            parentRef={containerRef}
                            position={'right'}
                            positionValue={arrowRight}
                            handlerClick={() => handleClickScroll('right')}
                        />
                    </div>
                </ModalProviderVolumeDiscount>
            </ModalDiscountsProvider>
        </>
    );
};

export default TableProducts;
