import { t } from 'i18next';
// contexts
import { useListBoxDrugManufacturersContext } from 'context';
// components
import { Buttons } from './Buttons';
import { SkeletonHeader } from './SkeletonHeader';
// hooks
import { useSubtitleProcessing } from './hooks';
// styles
import styles from './HeaderBox.module.css';

const HeaderBox = (): JSX.Element => {
    const { fetching } = useListBoxDrugManufacturersContext();
    const { firstPart, secondPart } = useSubtitleProcessing(t('home.widgetSubtitle'));

    return (
        <div className={styles['container']}>
            {fetching ? (
                <SkeletonHeader />
            ) : (
                <>
                    <div>
                        <div className={styles['title']}>{t('home.widgetTitle')}</div>
                    </div>
                    <Buttons />
                    <div className={styles['subtitle']}>
                        <p>
                            {firstPart}, <br /> {secondPart}.
                        </p>
                    </div>
                </>
            )}
        </div>
    );
};

export default HeaderBox;
