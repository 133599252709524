// interfaces
import { IImageUpload } from './UploadImage.interface';
// styles
import styles from './UploadImage.module.css';

const UploadImage = ({ src }: IImageUpload): JSX.Element => {
    return (
        <>
            {src ? (
                <div>
                    <img src={src} className={styles['image']} />
                </div>
            ) : (
                <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
                    <path
                        d="M15.1666 46.6666C11.6277 46.6666 8.60409 45.4417 6.09575 42.9916C3.58742 40.5416 2.33325 37.5472 2.33325 34.0083C2.33325 30.975 3.24714 28.2722 5.07492 25.9C6.9027 23.5278 9.29436 22.0111 12.2499 21.35C13.2221 17.7722 15.1666 14.875 18.0833 12.6583C20.9999 10.4416 24.3055 9.33331 27.9999 9.33331C32.5499 9.33331 36.4096 10.918 39.5791 14.0875C42.7485 17.2569 44.3333 21.1166 44.3333 25.6666C47.0166 25.9778 49.243 27.1347 51.0124 29.1375C52.7819 31.1403 53.6666 33.4833 53.6666 36.1666C53.6666 39.0833 52.6458 41.5625 50.6041 43.6041C48.5624 45.6458 46.0833 46.6666 43.1666 46.6666H30.3333V29.9833L32.4333 32.025C32.861 32.4528 33.3958 32.6666 34.0374 32.6666C34.6791 32.6666 35.2333 32.4333 35.6999 31.9666C36.1277 31.5389 36.3416 30.9944 36.3416 30.3333C36.3416 29.6722 36.1277 29.1278 35.6999 28.7L29.6333 22.6333C29.1666 22.1666 28.6221 21.9333 27.9999 21.9333C27.3777 21.9333 26.8333 22.1666 26.3666 22.6333L20.2999 28.7C19.8721 29.1278 19.6485 29.6625 19.6291 30.3041C19.6096 30.9458 19.8333 31.5 20.2999 31.9666C20.7277 32.3944 21.2624 32.618 21.9041 32.6375C22.5458 32.6569 23.0999 32.4528 23.5666 32.025L25.6666 29.9833V46.6666H15.1666Z"
                        fill="url(#paint0_linear_2533_2941)"
                    />
                    <defs>
                        <linearGradient
                            id="paint0_linear_2533_2941"
                            x1="27.9999"
                            y1="9.33331"
                            x2="27.9999"
                            y2="46.6666"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stop-color="#BADBFF">
                                <animate
                                    attributeName="stop-color"
                                    values="#BADBFF;#0171E6;#BADBFF"
                                    dur="2s"
                                    repeatCount="indefinite"
                                />
                            </stop>
                            <stop offset="1" stop-color="#0171E6">
                                <animate
                                    attributeName="stop-color"
                                    values="#0171E6;#BADBFF;#0171E6"
                                    dur="2s"
                                    repeatCount="indefinite"
                                />
                            </stop>
                        </linearGradient>
                    </defs>
                </svg>
            )}
        </>
    );
};

export default UploadImage;
