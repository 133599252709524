// apis
import { getPercentageOfToleranceOrderID } from 'api';
import { getPriority } from 'api/priority';

// Función que maneja las solicitudes y devuelve una promesa
export const fetchDataService = async (serviceId: number, orderId: string | undefined) => {
    try {
        if (serviceId === 1) {
            const resultGetPriority = await getPriority(orderId);
            return resultGetPriority?.data?.data || [];
        } else {
            const resultGetDistribution = await getPercentageOfToleranceOrderID(orderId);
            return resultGetDistribution?.data?.data || [];
        }
    } catch (error) {
        // Propaga el error para que sea manejado por el caller
        throw new Error('Error fetching data');
    }
};
