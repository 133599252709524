import React from 'react';
// TRANSLATION
import { t } from 'i18next';
// COMPONENTS
import { DiscountChip } from './DiscountChip';
import { CheckIcon } from './checkIcon';
import { TagVolumeDiscount } from './TagVolumeDiscount';
import { SplitChip } from './SplitChip';
import { TagVolumeDiscountV2 } from './TagVolumeDiscountV2';
import { SplitIcon } from './splitIcon';
// UTILS
import { CHIP_DISCOUNT_COLOR } from 'utils/enums/chipDiscount';
// CONFIGS
import { EX3762 } from 'config/flags';
// INTERFACES
import { DrugManufacturersId } from 'utils/interfaces';
import { ChipProps } from './Chip.interface';
// STYLES
import cellStyles from '../CellStyles.module.css';

const Chip = ({
    skeleton,
    chipColor,
    product,
    productPVP,
    drugManufacturerName,
    drugManufacturerId,
    bonus,
    quantityForBonus,
    isAppliedIndex,
    checked,
    user,
    isRecommended = false,
}: ChipProps): JSX.Element => {
    /* #region ff */
    const EX5937 = user?.EX5937;
    /* #endregion */

    /* #region its apply && its apply split */
    const ITS_APPLY = chipColor === CHIP_DISCOUNT_COLOR.GREEN;
    const ITS_APPLY_SPLIT = EX5937 && chipColor === CHIP_DISCOUNT_COLOR.STRONG_GREEN;
    /* #endregion */

    /* #region is suiza tucuman and has multiple */
    const IS_SUIZA_TUCUMAN_WITH_MULTIPLE =
        drugManufacturerId === DrugManufacturersId.SuizaTucuman && product?.discount?.multiple && EX3762;
    /* #endregion */

    /* #region chip volument txt */
    const volumeDiscountChip = () => {
        return TagVolumeDiscount(
            drugManufacturerName,
            quantityForBonus,
            bonus,
            isAppliedIndex,
            checked,
        );
    };
    const newVolumeDiscountChip = () => {
        return TagVolumeDiscountV2(
            drugManufacturerName,
            bonus,
            checked,
            product?.bonusApplied,
            product?.volumeDiscountApplied,
            chipColor === CHIP_DISCOUNT_COLOR.DISABLED,
        );
    };
    /* #endregion */

    /* #region chip discount txt */
    const discountChip = () => {
        return DiscountChip(
            product,
            productPVP,
            ITS_APPLY,
            IS_SUIZA_TUCUMAN_WITH_MULTIPLE,
            drugManufacturerId,
            drugManufacturerName,
            t,
            chipColor === CHIP_DISCOUNT_COLOR.DISABLED
        );
    };
    /* #endregion */

    /* #region render icon split or check apply discounts */
    const renderIcon = () => {
        if (ITS_APPLY) {
            return CheckIcon(16, 16);
        } else if (ITS_APPLY_SPLIT) {
            return SplitIcon(16, 16);
        }
        return null;
    };
    /* #endregion */
    /* #region render content */
    const renderChipContent = () => {
        if (EX5937) {
            if (ITS_APPLY_SPLIT) {
                return <SplitChip value={product?.quantity_suggested} />;
            } else if (bonus?.length > 0) {
                if (product?.quantityDiscountApplies) {
                    return discountChip();
                } else {
                    return newVolumeDiscountChip();
                }
            } else {
                return discountChip();
            }
        } else {
            if (bonus?.length > 0) {
                return volumeDiscountChip();
            } else {
                return discountChip();
            }
        }
    };

    /* #endregion */

    return (
        <div
            className={`${cellStyles['bottom']} ${cellStyles['chip']} ${cellStyles[`chip-background-${chipColor}`]}`}
            style={{
                justifyContent: ITS_APPLY || ITS_APPLY_SPLIT ? 'space-between' : 'flex-end',
            }}
        >
            {!skeleton && renderIcon()}
            {!skeleton && renderChipContent()}
        </div>
    );
};

export default Chip;
