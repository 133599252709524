export const renderProductCantText = (maxcant, mincant): string => {
    if (!!maxcant && !mincant) {
        return `Máx. ${maxcant}/u.`;
    } else if (!maxcant && !!mincant) {
        return `Min. ${mincant}/u.`;
    } else if (!!maxcant && !!mincant) {
        return `Min. ${mincant}/u. | Máx. ${maxcant}/u.`;
    } else {
        return;
    }
};
