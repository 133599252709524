// components
import { Card } from 'commons/components';

const OpenSearchBanner = ({containerRef}) => {
  return (
    <Card  containerRef = {containerRef}/>
  );
};

export default OpenSearchBanner;
