// interface
import { IPaginationList } from './PaginationList.interface';
// rsuite
import { Pagination, SelectPicker } from 'rsuite';
// mui v5
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
// style
import style from './PaginationList.module.css';
import { EX5057 } from 'config/flags';
import { useTranslation } from 'react-i18next';
import { useUser } from 'utils/hooks';

const PaginationList = ({
    total,
    activePage,
    onChangePage,
    onChangeLimit,
    limit,
    pages,
    isLoading,
}: IPaginationList) => {
    const { t } = useTranslation();
    const {user} = useUser({});
    const EX5289 = user?.EX5289;

    const limitOptions = [
        {
            value: 20,
            label: 20,
        },
        {
            value: 50,
            label: 50,
        },
        {
            value: 100,
            label: 100,
        },
    ];

    // validation is loading includes because disabled not working in next and previous
    return (
        <div className={style['container-pagination']}>
            {isLoading ? (
                <div className={style['wrapper-pagination']}>
                    <Pagination
                        className={style['pagination']}
                        size="sm"
                        prev={true}
                        next={true}
                        ellipsis={true}
                        total={total}
                        activePage={activePage}
                        boundaryLinks={true}
                        maxButtons={5}
                        limit={limit}
                        limitOptions={[20, 50, 100]}
                        layout={['pager']}
                        onChangeLimit={onChangeLimit}
                        onChangePage={onChangePage}
                        pages={pages}
                        disabled={isLoading}
                    />
                    <span className={style['text-seeing']}>
                        {!!EX5057 ? t('shoppingCart.labelIsShowing') : 'Mostrando'}
                    </span>
                    <SelectPicker //@ts-ignore
                        caretAs={() => <ExpandMoreOutlinedIcon />}
                        searchable={false}
                        data={limitOptions}
                        placement="topStart"
                        menuClassName="menu"
                        className={style['select-picker-container']}
                        cleanable={false}
                        defaultValue={EX5289 ? 50 : 20}
                        onChange={onChangeLimit}
                    />
                </div>
            ) : (
                <div className={style['wrapper-pagination']}>
                    <Pagination
                        className={style['pagination']}
                        size="sm"
                        prev={true}
                        next={true}
                        ellipsis={true}
                        total={total}
                        activePage={activePage}
                        boundaryLinks={true}
                        maxButtons={5}
                        limit={limit}
                        limitOptions={[20, 50, 100]}
                        layout={['pager']}
                        onChangeLimit={onChangeLimit}
                        onChangePage={onChangePage}
                        pages={pages}
                    />
                    <span className={style['text-seeing']}>  {!!EX5057 ? t('shoppingCart.labelIsShowing') : 'Mostrando'}</span>
                    <SelectPicker //@ts-ignore
                        caretAs={() => <ExpandMoreOutlinedIcon />}
                        searchable={false}
                        data={limitOptions}
                        placement="topStart"
                        menuClassName="menu"
                        className={style['select-picker-container']}
                        cleanable={false}
                        defaultValue={EX5289 ? 50 : 20}
                        onChange={onChangeLimit}
                    />
                </div>
            )}
        </div>
    );
};

export default PaginationList;
