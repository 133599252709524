export const switchPlaceholder = (flag, t ) => {
  const withoutPosName = flag
    ? t('home.withOutPharmacySelected.home_withoutPharmacySelectedSecond')
    : 'Seleccioná una farmacia';
  const withPosName = flag 
    ? t('home.selectedPOS.home_selecterPointofsale')
    : '¿En qué farmacia vas a realizar la compra?';

  return {
    withoutPosName,
    withPosName
  }
};
