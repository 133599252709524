const actionMenuContext = (lang) => {
    let on;
    let off;
    switch (lang) {
        case 'AR':
            (on = 'Prender'), (off = 'Apagar');
            break;
        case 'ES':
            (on = 'Encender'), (off = 'Apagar');
            break;
        case 'CO':
            (on = 'Encender'), (off = 'Apagar');
            break;
        case 'MX': {
            (on = 'Encender'), (off = 'Apagar');
            break;
        }
        case 'BR': {
            (on = 'Ligar'), (off = 'Desligar');
            break;
        }
        case 'US': {
            (on = 'Turn on'), (off = 'Turn off');
            break;
        }
        default:
            break;
    }

    return {
        on,
        off,
    };
};

export default actionMenuContext;
