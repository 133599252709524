// rsuite
import { SelectPicker } from 'rsuite';
// style
import style from './FieldSelectCountries.module.css'
// interface
import { IFieldSelectCountries } from './FieldSelectCountries.interface';

const FieldSelectCountries = ({ field, state, handlerChangeInput, countries }:IFieldSelectCountries) => {
    
    return (
        <SelectPicker
            data={countries?.data.map(({ alfa_2, name }) => ({
                label: name,
                value: alfa_2,
            }))}
            name={field.name}
            className={style['select-picker']}
            defaultValue={state.country}
            onChange={(evt) => handlerChangeInput(evt, field.name)}
            loading={state.isLoading}
        />
    );
};

export default FieldSelectCountries;
