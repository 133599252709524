import { makeStyles } from '@material-ui/core/styles';
import { rem } from 'polished';

const useStyles = makeStyles((theme) => ({
  itemRowStatusDisabledWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  itemRowStatusDisabledTitle: {
    color: '#f78c21',
  },
  itemRowStatusDisabledSubtitle: {
    fontSize: rem('12px'),
  },
  itemRowStatusAbsoluteWrapper: {
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textCenter: {
    textAlign: 'center',
    zIndex: 200,
  },
  buttonCancelar: {
    color: 'white',
    fontWeight: 600,
  },
  modalBody: {
    padding: '8px 8px 0',
    height: '160px',
  },
  labelBody: {
    padding: '8px 16px',
  },
  titleBody: {
    padding: '16px 24px 0',
  },
  popOverRoot: {
    pointerEvents: 'none',
    '&:hover': {
      pointerEvents: 'auto',
    },
  },
  usersList__tooltip: {
    borderRadius: 1,
    visibility: 'hidden',
  },
  usersList: {
    '&:hover td > button': {
      visibility: 'visible !important',
    },
  },
  paginationColorPDV: {
    '& .Mui-selected': {
      backgroundColor: '#DCEDFF !important',
      border: 'none',
      color: '#0171E6',
    },
    '& .MuiPaginationItem-page.Mui-selected': {
      backgroundColor: '#DCEDFF !important',
      color: '#0171E6',
    },
    '& .MuiPaginationItem-page.Mui-selected:hover': {
      backgroundColor: '#DCEDFF !important',
      color: '#0171E6',
    },

    '& .MuiPaginationItem-page.Mui-selected:focused': {
      backgroundColor: '#DCEDFF !important',
      color: '#0171E6',
    },
    '& .MuiPaginationItem-page:hover': {
      backgroundColor: '#EEEEEE !important',
      color: '#646464',
      borderColor: '#646464',
    },
    '& .MuiPaginationItem-page': {
      color: '#646464',
      borderColor: '#646464',
    },
    '& .MuiPaginationItem-page:focused': {
      color: '#D1D1D1',
      borderColor: '#646464',
    },
  },
}));
export default useStyles;
