import { ButtonGhost, ButtonSolid } from '@commons/components/Button';
import styles from './PreventAlert.module.css';
import { useModalCredentialsContext } from 'context/ModalCredentials/ModalCredentials-context';
import { t } from 'i18next';
import { Modal } from 'rsuite';
const PreventAlertV2 = () => {
    const {
        orderByCredentialType,
        drugConnection,
        showPreventAlert,
        togglePreventModal,
        clearOnClose,
    } = useModalCredentialsContext();

    const drugName = orderByCredentialType?.find((drugstore) => drugstore.id === drugConnection.id)?.name;

    return (
        <Modal open={showPreventAlert} className={styles['modal-prevent-alert']}>
            <div className={styles['container']}>
                <div className={styles['body-container']}>
                    <span className={styles['title-text']}>{t('settings.pointOfSales.preventAlertTitle')}</span>
                    <span className={styles['subtitle-text']}>
                        {t('settings.pointOfSales.preventAlertBody', { drugName: drugName })}
                    </span>
                </div>
                <div className={styles['actions-container']}>
                    <ButtonSolid
                        cancelType={true}
                        onClick={() => clearOnClose()}
                        height={40}
                        width={'100%'}
                        margin={'0px'}
                        text={t('settings.pointOfSales.preventAlertConfirmButton')}
                    />
                    <ButtonGhost
                        onClick={() => togglePreventModal()}
                        height={40}
                        width={'100%'}
                        margin={'0px 0px 0px 16px'}
                        text={t('settings.pointOfSales.buttonCancel')}
                    />
                </div>
            </div>
        </Modal>
    );
};
export default PreventAlertV2;
