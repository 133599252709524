import React from "react";
import useStyles from "../CredentialCardBody.styles";
import { handleIcons } from "./helper";
import { EX3986, EX4073 } from "config/flags";

const Tabs = ({
  credential_types,
  nameSelect,
  onChange,
  handleSwitch,
  setCredencialId,
  tabError,
}) => {
  const classes = useStyles();

  const handleDisable = (event, item) => {
    onChange(event, item);
    setCredencialId(item?.api_credential_id);
  };

  const credentialTypesInOrder = (): any[] => {
    const POS1 = 1;
    const POS2 = 2;
    const POS3 = 0;

    return orderCredentials(credential_types, POS1, POS2, POS3);
  };

  const orderCredentials = (
    arr: any[],
    pos1: number,
    pos2: number,
    pos3: number
  ): any[] => {
    // Order the elements in the specified positions and clean undefined
    return [arr[pos1], arr[pos2], arr[pos3]].filter(
      (item: any) => item != undefined
    );
  };

  return (
    <div className={classes.tabs}>
      {!!credentialTypesInOrder().length &&
        credentialTypesInOrder().map((item, index) => {
          const typeName = handleSwitch(item.name);
          if(typeName){
            return (
              <div
                key={index}
                className={
                  item.name === nameSelect ? classes.tab : classes.button
                }
                onClick={(event) => handleDisable(event, item)}
              >
                <span style={{ marginRight: 4 }}>{typeName}</span>
                {item?.username && !!EX3986 && handleIcons(tabError[item.name])}
              </div>
            );
          }
        })}
    </div>
  );
};

export default Tabs;
