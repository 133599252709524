// utils
import { withoutStockText } from './withoutStock';

export const renderWithoutStockText = (t): string => {
    return `${withoutStockText(t)}`;
};

export const renderValue = (product) => {
    // chipColor === CHIP_DISCOUNT_COLOR.GREEN = discount apply

    // ahora unit_price_ep es la key a renderizar en valor de celda

    return product?.unit_price_ep;
};
