import style from "./Header.module.css";

const Header = ({ haveLogo, title }) => {
  return (
    <div className={style["header"]}>
      {haveLogo && (
        <img src="/assets/images/IsologoVertical.svg" alt="IsologoVertical" />
      )}
      {title && <span style={{ marginTop: haveLogo ? 32 : 0 }}>{title}</span>}
    </div>
  );
};

export default Header;
