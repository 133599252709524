import { memo } from 'react';
// components
import { Placeholder } from 'rsuite';
// styles
import styles from './AddOfflineMessageSkeleton.module.css';

const AddOfflineMessageSkeleton = () => {
    return (
        <div className={styles['container']}>
            <div>
                <Placeholder.Paragraph active className={styles['placeholder']} />
            </div>
            <Placeholder.Paragraph graph="square" active className={styles['button']} rows={0} />
        </div>
    );
};

export default memo(AddOfflineMessageSkeleton);
