// components
import { ExLoader } from '@commons/EXComponentsLibrary/ExLoader';
// interfaces
import { ITitleProps } from './Title.interface';
// styles
import styles from './Title.module.css';

const Title = ({ loading, title, disabled }: ITitleProps) => {
    return (
        <>
            {loading ? (
                <div className={styles['loader']}>
                    <ExLoader size="xs" />
                </div>
            ) : (
                <div className={styles['placeholder']}>
                    <img
                        src={`/assets/images/download_${disabled ? 'disabled' : 'file_blue'}.svg`}
                        width={16}
                        height={16}
                        alt="icon download"
                        draggable={false}
                    />
                    {title}
                </div>
            )}
        </>
    );
};

export default Title;
