export const handlerLink = (href, router, user, pos) => {
  switch (href) {
    case '/[clientId]/efficient-purchase/[posId]':
      router.push(`/${user?.client?.id}/efficient-purchase/${pos?.id}`);
      break;
    case '/[clientId]/my-orders':
      router.push(`/${user?.client?.id}/my-orders`);
      break;
    case '/analytics':
      router.push(`/analytics`)
      break;
    default:
      router.push(href);
      break;
  }
};
