import { EX5970 } from 'config/flags';
// hooks
import { useMoreThanOne } from '../hooks';

interface backgroundColorCellProps {
    isSuggested: boolean;
    isLowest: boolean;
    product: any;
    applyToleranceAccordingToService: boolean;
    checked: boolean;
    withoutChecks: boolean;
    isProductGroupSplit: boolean;
    isLowestSuggested: boolean;
}

enum Colors {
    GREEN = '#DDF6E2',
    YELLOW = '#FFF3D6',
}

export const backgroundColorCell = ({
    isSuggested,
    isLowest,
    product,
    applyToleranceAccordingToService,
    checked,
    withoutChecks = false,
    isProductGroupSplit = false,
    isLowestSuggested,
}: backgroundColorCellProps): string => {
    // esta logica es para desactivar colores cuando solo participa 1 droguería
    const { EX5749, EX6157, ItsmorethanOne } = useMoreThanOne();
    // primero validar si es el precio seleccionado por el sistema (suggested)
    // SI es (2 caminos)
    // |=> ¿es el mas bajo y tiene stock y esta chequeado? => fondo verde
    // |=> ¿aplica tolerancia y es el mismo que el seleccionado por el sistema y esta chequeado? => fondo amarillo + tuerquita
    // |=> ¿si esta spliteado y esta chequeado y no es el mas bajo? => fondo amarillo

    // NO es => al ser diferente al seleccionado por el sistema (suggested) significa que es seleccion manual => fondo amarillo, sin icono de tolerancia y con caret up si corresponde. Valida si esta chequeado
    if (!product) {
        // NO HAY STOCK
        return '#F0F5FF';
    } else {
        if (!!isSuggested) {
            if (EX5749) {
                if (!!isProductGroupSplit && !!checked && !isLowest) {
                    return Colors.YELLOW;
                }
                if (EX6157) {
                    if (!!isLowest && !checked) {
                        return Colors.GREEN;
                    }
                    if (!!isLowest && !!product && !!checked) {
                        return Colors.GREEN;
                    } else if (applyToleranceAccordingToService && !!checked) {
                        return Colors.YELLOW;
                    } else if (!!checked && !isLowest && !ItsmorethanOne) {
                        return Colors.YELLOW;
                    }
                } else {
                    if (!!isLowest && !!product) {
                        return Colors.GREEN;
                    } else if (applyToleranceAccordingToService && !!checked) {
                        return Colors.YELLOW;
                    } else if (!!checked && !isLowest && !ItsmorethanOne) {
                        return Colors.YELLOW;
                    }
                }
            } else {
                if (!!isProductGroupSplit && !!checked && !isLowest) {
                    return Colors.YELLOW;
                }
                if (!!isLowest && !!product) {
                    return Colors.GREEN;
                } else if (applyToleranceAccordingToService && !!checked) {
                    return Colors.YELLOW;
                } else if (!!checked && !isLowest) {
                    return Colors.YELLOW;
                }
            }
        } else {
            if (EX6157) {
                if (!!withoutChecks && !!isLowest) {
                    return Colors.GREEN;
                }
                if (!checked && !!isLowest && !isLowestSuggested) {
                    return Colors.GREEN;
                }
                if (!!checked && !isLowest) {
                    return Colors.YELLOW;
                }
                if(!!checked) {
                    return Colors.GREEN;
                }
            } else {
                if (!!checked && !isLowest) {
                    return Colors.YELLOW;
                } else if (isLowest && !withoutChecks) {
                    return Colors.GREEN;
                } else if (isLowest && !!withoutChecks && EX5970) {
                    return Colors.GREEN;
                }
            }
        }
    }
};
