import React from 'react';
import style from './PanelConnection.module.css';
import { ExAvatarConnections, ExButton } from '@commons/EXComponentsLibrary';
import { IconButton, Panel } from 'rsuite';
import { RsuiteTooltip } from '@commons/components/RsuiteTooltip';
import { EPossiblesLegends } from './enum';
import PageNextIcon from '@rsuite/icons/PageNext';
const PanelConnection = ({ name, label, status, statusAvatar, btnLabel, btnAppearance, handleClick, initialName, where = false }) => {
    let classNameStyle;
    
    const isConnected = !!where && ( status !== EPossiblesLegends.ErrorDeCredenciales || status !== EPossiblesLegends.ErrorDeCredenciales);

    switch (status) {
        case EPossiblesLegends.ErrorDeCredenciales:{
            classNameStyle = style['error-label-text'];
            break;
        }
        case EPossiblesLegends.ErrorDeFormato: {
            classNameStyle = style['error-label-text'];
            break;
        }
        default:
            classNameStyle = style['connected-label-text'];
            break;
    }

    return (
        <Panel bordered className={style['panel-container-connected']}>
            <div className={style['container-connected']}>
                <ExAvatarConnections
                    initialsName={initialName}
                    //@ts-ignore
                    status={statusAvatar}
                    style={{ width: 32, height: 32, fontSize: 12 }}
                />
                <div style={{ width: isConnected ? 210 : 133 }}>
                    <RsuiteTooltip
                        placement="top"
                        trigger="hover"
                        text={name}
                        disabled={name.length < 18 || isConnected}
                    >
                        <p>{name}</p>
                    </RsuiteTooltip>{' '}
                    <RsuiteTooltip
                        placement="top"
                        trigger="hover"
                        text={label}
                        disabled={label?.length < 18 || isConnected}
                    >
                        <span className={!where ? style['label-speciality'] : classNameStyle}>{label}</span>
                    </RsuiteTooltip>
                </div>
                {isConnected ? (
                    <IconButton className={style['icon-button']} icon={<PageNextIcon />} onClick={handleClick} />
                ) : (
                    <ExButton className={style['button-actions']} appearance={btnAppearance} onClick={handleClick}>
                        {btnLabel}
                    </ExButton>
                )}
            </div>
        </Panel>
    );
};

export default PanelConnection;
