import React from 'react';
// COMPONENTS
import { Placeholder } from 'rsuite';
// STYLES
import styles from '../../../../TableTotals.module.css';
import cellStyles from '../../../../../../CellStyles.module.css';
// INTERFACES
import { PercentageProps } from './Percentage.interface';

const Percentage = ({ skeleton, drugManufacturerEnabled, percent,productQuantityByDrugManufacturer }: PercentageProps): JSX.Element => {
    return (
        <div className={styles['container-percentage-expanded']} style={{ border: skeleton ? 'none' : '' }}>
            <p className={`${!drugManufacturerEnabled && !skeleton && productQuantityByDrugManufacturer === 0 ? cellStyles['drugManufacturerDisabled'] : ''}`}>
                {skeleton ? (
                    <Placeholder.Paragraph
                        rowHeight={16}
                        rows={1}
                        active
                        className={cellStyles['placeholder-percent']}
                    />
                ) : (
                    percent
                )}
            </p>
        </div>
    );
};

export default Percentage;
