// contexts
import { useProgressBarPriceContext } from 'context';
// components
import { Footer } from './Footer';
import { Header } from './Header';
import { ProgressBar } from './ProgressBar';
// interfaces
import { IProgressProps } from './Progress.interface';
// styles
import styles from './Progress.module.css';

const Progress = ({ errors, confirmCancelation }: IProgressProps) => {
    const { currentStep, failed } = useProgressBarPriceContext();
    return (
        <div
            className={`${styles['container']} ${currentStep === 4 ? styles['completed'] : ''} ${
                failed ? styles['failed'] : ''
            }`}
            style={{
                height: errors > 0 && !failed ? '65vh' : '70vh',
                padding: errors > 0 || failed ? '92px 48px 48px 48px' : 48,
            }}
        >
            <Header />
            <ProgressBar />
            <Footer confirmCancelation={confirmCancelation} failed={failed} />
        </div>
    );
};

export default Progress;
