// component
import { FooterSidebar, BodySidebar, HeadSidebar } from '.';
// context
import { useStatusContext } from 'context/control-state';
// hook
import { useUser } from 'utils/hooks';
// rsuite
import { Sidebar as RsuiteSidebar, Sidenav } from 'rsuite';

// style
import style from './Sidebar.module.css';

const Sidebar = () => {
    //context
    const { counterStep } = useStatusContext();
    // hook
    const { user } = useUser({});  
    //steps onboarding
    const steps = ['#step3', 'body','#step4'];
    const controlStep = steps.find((element) => counterStep === element);

    return (
        <>
            {!controlStep && <div className={style['wrapper-sidenav-blur']}></div>}

            {counterStep === '#step-6' && <div className={style['wrapper-sidenav-focus']}></div>}

            <div className={style['container-sidebar']}>
                <RsuiteSidebar>
                    <Sidenav className={style['sidenav-content']}>
                        <div className={style['wrapper-sidenav']}>
                            <HeadSidebar clientId={user?.client?.id} />
                            <BodySidebar />
                        </div>

                        <FooterSidebar />
                    </Sidenav>
                </RsuiteSidebar>
            </div>
        </>
    );
};

export default Sidebar;
