import { PUSHER_LOGS } from "config/flags";
import Pusher, { Options } from "pusher-js";

const config: Options = {
  cluster: process.env.NEXT_PUBLIC_PUSHER_CLUSTER,
  forceTLS: Boolean(process.env.NEXT_PUBLIC_PUSHER_FORCE_TLS == "true"),
  activityTimeout: 120000,
};

const pusher = new Pusher(process.env.NEXT_PUBLIC_PUSHER_KEY, config);

Pusher.logToConsole = PUSHER_LOGS ? true : false;

export default pusher;
