export const disabledPaths = [
  '/[clientId]/my-orders',
  '/[clientId]/my-orders/details/[order]',
  '/[clientId]/my-orders/deliveries/[delivery]/[order]',
  //'[clientId]/efficient-purchase/search-results',
  //'/[clientId]/efficient-purchase/search-results',
  '/[clientId]/settings/POS',
  '/[clientId]/settings/trade-agreements/editAgreement/[id]',
  '/[clientId]/settings/trade-agreements',
  '/[clientId]/settings/POS/[posId]/posDetails',
  '/[clientId]/settings/trade-agreements/create',
  // ADD OFFLINE
  '/[clientId]/settings/POS/[posId]/addOfflineDrugstore',
  '/[clientId]/settings/POS/[posId]/addOfflineDrugstore/[[...offlineDrugstoreId]]',
  '/pos/[clientId]/[posId]/addOfflineDrugstore/[[...offlineDrugstoreId]]',
  //'/[clientId]/efficient-purchase/[posId]/product-comparison',
  // '/[clientId]/efficient-purchase/[posId]/order-sent',
  // '/[clientId]/efficient-purchase/[posId]/confirmation',
];
