// context
import { useListBoxDrugManufacturersContext } from 'context';
// components
import { Item } from './Item';
import { SkeletonItems } from './SkeletonItems';
// styles
import styles from './ListItems.module.css';

const ListItems = ({ fetching }): JSX.Element => {
    const { data, currentIndex } = useListBoxDrugManufacturersContext();
    return (
        <div className={styles['container']}>
            {fetching ? (
                <SkeletonItems />
            ) : (
                data?.slice(currentIndex, currentIndex + 3).map((item, idx) => {
                    return <Item key={idx + 1} item={item} />;
                })
            )}
        </div>
    );
};

export default ListItems;
