import React from 'react';
import style from './RenderWithoutRecordset.module.css';
import { useTranslation } from 'react-i18next';
const RenderWithoutRecordset = () => {
  const { t } = useTranslation()
  return (
    <div className={style['container']}>
      <div className={style['container-images']}>
        <div className={style['image-vector']}>
          <img src='/assets/images/Vector.svg' className={style['vector-img']} />
        </div>
        <div className={style['framer-icon']}>
          <img src='/assets/images/Frame.svg' className={style['framer-icon']} />
        </div>
      </div>
      <div className={style['container-without-search']}>
        <div className={style['without-search-text']}>
          <p className={style['title-without-searching']}>
          {t('shoppingCart.labelNoResults') || 'No hay resultados que coincidan con tu búsqueda.'}
          </p>
          <span className={style['subtitle-without-searching']}>
          {t('shoppingCart.labelTryAgain') || 'Intentalo de nuevo'}
          </span>
        </div>
      </div>
    </div>
  );
};

export default RenderWithoutRecordset;
