import { useRef, useState } from 'react';
import Link from 'next/link';
import { t } from 'i18next';
// configs
import { EX4085, EX5057, EX5232 } from 'config/flags';
// apis
import { downloadMissingFileResult, downloadOrderByExtension, downloadZippedOrder } from 'api';
//gtag
import * as gtag from 'utils/gtag';
// components
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Col, Row } from 'rsuite';
import { ButtonOutline } from 'commons/components/Button/ButtonOutline';
// utils && hooks
import useChangeLanguage from 'utils/hooks/use-change-language';
import { useRouter } from 'next/router';
import { useUser } from 'utils/hooks';
import { COUNTRY } from 'utils/country';
// interfaces
import { IHeader } from './IHeader.interface';
// styles
import style from './Header.module.css';
import { DropdownOrder, ButtonFal } from '@commons/components';

const Header = ({ res_link, fal_link }: IHeader) => {
    const { user } = useUser({});
    const router = useRouter();
    const { query } = router;
    const orderId = query.order;
    const userLocaleCountryCode = user?.client?.country_alfa_2;
    if (EX4085) useChangeLanguage(userLocaleCountryCode);
    const EX5651 = user?.EX5651;

    const EX5587 = user?.EX5587;
    const [loading, setLoading] = useState(false);

    const fullOrder = () => {
        gtag.event({
            action: 'click',
            category: 'my-orders',
            label: 'button__download-complete-order-1',
            value: 0,
        });
    };

    const failedOrder = () => {
        gtag.event({
            action: 'click',
            category: 'my-orders',
            label: 'button__download-missing-products-1',
            value: 0,
        });
    };

    const [downloadFal, setDownloadFal] = useState<boolean>(false);
    const [downloadOrder, setDownloadOrder] = useState<boolean>(false);

    const downloadFile = async (fn: any, type: string) => {
        await fn(orderId).then((response) => {
            //@ts-ignore
            const blob = new Blob([response?.data], { type: type });
            const fileNameFromHeaders = response.headers['content-disposition'].split('filename=')[1];
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileNameFromHeaders;
            link.click();
            link.remove();
            setDownloadFal(false);
            setDownloadOrder(false);
        });
    };

    const handleDownloadOrder = async (fn: any, type: string) => {
        setLoading(true);
        await downloadFile(fn, type);
        setLoading(false);
        failedOrder();
    };

    const handleDownloadMissing = async (fn: any, type: string) => {
        setDownloadFal(true);
        await downloadFile(fn, type);
    };

    const dropdownOrderRef = useRef(null);

    const downloadOrderComplete = (type: 'res' | 'xlsx' | 'csv' | 'txt') => {
        downloadOrderByExtension(+orderId, type)
            .then((response) => {
                //@ts-ignore
                const blob = new Blob([response?.data], { type: response?.data?.type });

                const fileNameFromHeaders = response.headers['content-disposition'].split('filename=')[1];

                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = fileNameFromHeaders;
                link.click();
                link.remove();
            })
            .catch((e) => console.error(e))
            .finally(() => setDownloadOrder(false));
    };

    const onSelect = (eventKey: string, event: any): void => {
        setDownloadOrder(true);
        downloadOrderComplete(event?.target?.id);
    };

    const DOWNLOAD_FORMAT =
        userLocaleCountryCode === COUNTRY.AR
            ? [
                  {
                      type: 'res',
                      text: t('efficientPurchasing.sendingPurchase.efficientPurchasing_sendingPurchaseSeventeen', {
                          fileFormat: 'RES',
                      }),
                  },
                  {
                      type: 'xlsx',
                      text: t('efficientPurchasing.sendingPurchase.efficientPurchasing_sendingPurchaseSeventeen', {
                          fileFormat: 'XLSX',
                      }),
                  },
                  {
                      type: 'csv',
                      text: t('efficientPurchasing.sendingPurchase.efficientPurchasing_sendingPurchaseSeventeen', {
                          fileFormat: 'CSV',
                      }),
                  },
              ]
            : [
                  {
                      type: 'txt',
                      text: t('efficientPurchasing.sendingPurchase.efficientPurchasing_sendingPurchaseSeventeen', {
                          fileFormat: 'TXT',
                      }),
                  },
                  {
                      type: 'xlsx',
                      text: t('efficientPurchasing.sendingPurchase.efficientPurchasing_sendingPurchaseSeventeen', {
                          fileFormat: 'XLSX',
                      }),
                  },
                  {
                      type: 'csv',
                      text: t('efficientPurchasing.sendingPurchase.efficientPurchasing_sendingPurchaseSeventeen', {
                          fileFormat: 'CSV',
                      }),
                  },
              ];

    return (
        <Col xs={24} style={{ paddingBottom: 20 }}>
            <Row className={style['row-container']}>
                <Col xs={24} sm={24} md={EX5651 && user?.client?.country_alfa_2 !== COUNTRY.AR ? 12 : 14}>
                    <span className={style['footer-header']}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            style={{ marginRight: 8 }}
                        >
                            <path
                                d="M9 12.75C9.2125 12.75 9.39063 12.6781 9.53438 12.5344C9.67813 12.3906 9.75 12.2125 9.75 12V9C9.75 8.7875 9.67813 8.60938 9.53438 8.46563C9.39063 8.32188 9.2125 8.25 9 8.25C8.7875 8.25 8.60938 8.32188 8.46563 8.46563C8.32188 8.60938 8.25 8.7875 8.25 9V12C8.25 12.2125 8.32188 12.3906 8.46563 12.5344C8.60938 12.6781 8.7875 12.75 9 12.75ZM9 6.75C9.2125 6.75 9.39063 6.67813 9.53438 6.53438C9.67813 6.39062 9.75 6.2125 9.75 6C9.75 5.7875 9.67813 5.60938 9.53438 5.46563C9.39063 5.32188 9.2125 5.25 9 5.25C8.7875 5.25 8.60938 5.32188 8.46563 5.46563C8.32188 5.60938 8.25 5.7875 8.25 6C8.25 6.2125 8.32188 6.39062 8.46563 6.53438C8.60938 6.67813 8.7875 6.75 9 6.75ZM9 16.5C7.9625 16.5 6.9875 16.3031 6.075 15.9094C5.1625 15.5156 4.36875 14.9813 3.69375 14.3063C3.01875 13.6313 2.48438 12.8375 2.09063 11.925C1.69688 11.0125 1.5 10.0375 1.5 9C1.5 7.9625 1.69688 6.9875 2.09063 6.075C2.48438 5.1625 3.01875 4.36875 3.69375 3.69375C4.36875 3.01875 5.1625 2.48438 6.075 2.09063C6.9875 1.69688 7.9625 1.5 9 1.5C10.0375 1.5 11.0125 1.69688 11.925 2.09063C12.8375 2.48438 13.6313 3.01875 14.3063 3.69375C14.9813 4.36875 15.5156 5.1625 15.9094 6.075C16.3031 6.9875 16.5 7.9625 16.5 9C16.5 10.0375 16.3031 11.0125 15.9094 11.925C15.5156 12.8375 14.9813 13.6313 14.3063 14.3063C13.6313 14.9813 12.8375 15.5156 11.925 15.9094C11.0125 16.3031 10.0375 16.5 9 16.5ZM9 15C10.675 15 12.0938 14.4188 13.2563 13.2563C14.4188 12.0938 15 10.675 15 9C15 7.325 14.4188 5.90625 13.2563 4.74375C12.0938 3.58125 10.675 3 9 3C7.325 3 5.90625 3.58125 4.74375 4.74375C3.58125 5.90625 3 7.325 3 9C3 10.675 3.58125 12.0938 4.74375 13.2563C5.90625 14.4188 7.325 15 9 15Z"
                                fill="#0171E6"
                            />
                        </svg>{' '}
                        {!EX4085
                            ? 'Registrá el pedido en tu sistema de punto de venta:'
                            : t('myOrders.orderDetails.registerOrderOnPOS')}
                    </span>
                </Col>
                <Col
                    xs={24}
                    sm={24}
                    md={EX5651 && user?.client?.country_alfa_2 !== COUNTRY.AR ? 12 : 10}
                    style={{ display: 'flex', justifyContent: 'flex-end', gap: EX5651 ? 12 : '' }}
                >
                    {EX5651 && (
                        <DropdownOrder
                            dropdownOrderRef={dropdownOrderRef}
                            title={
                                !downloadOrder
                                    ? t('efficientPurchasing.sendingPurchase.efficientPurchasing_sendingPurchaseFive')
                                    : ''
                            }
                            data={DOWNLOAD_FORMAT}
                            disabled={!res_link}
                            onSelect={onSelect}
                            loading={downloadOrder}
                            gtagClass={'button__download-complete-order-1'}
                        />
                    )}
                    {EX5587 && !EX5651 ? (
                        <ButtonOutline
                            text={
                                !loading
                                    ? t('efficientPurchasing.sendingPurchase.efficientPurchasing_sendingPurchaseFive')
                                    : ''
                            }
                            onClick={() => handleDownloadOrder(downloadZippedOrder, 'application/zip')}
                            height={32}
                            width={152}
                            disabled={loading}
                            loading={loading}
                            icon={
                                !loading && (
                                    <FileDownloadOutlinedIcon style={{ height: 20, width: 20, color: '#0171E6' }} />
                                )
                            }
                            gtagClass="button__download-complete-order-1"
                        />
                    ) : (
                        !EX5651 && (
                            <Link href={res_link ? res_link : ''} download>
                                <ButtonOutline
                                    text={
                                        EX5057
                                            ? t(
                                                  'efficientPurchasing.sendingPurchase.efficientPurchasing_sendingPurchaseFive',
                                              )
                                            : 'Pedido completo'
                                    }
                                    onClick={() => fullOrder()}
                                    height={32}
                                    width={152}
                                    disabled={res_link ? false : true}
                                    icon={
                                        <FileDownloadOutlinedIcon style={{ height: 20, width: 20, color: '#0171E6' }} />
                                    }
                                    gtagClass="button__download-complete-order-1"
                                />
                            </Link>
                        )
                    )}
                    {EX5651 ? (
                        <ButtonFal
                            loading={downloadFal}
                            disabled={!fal_link}
                            text={t('myOrders.faults')}
                            handleOnClick={() => handleDownloadMissing(downloadMissingFileResult, 'application/text')}
                            country={user?.client?.country_alfa_2}
                            gtagClass={'button__download-missing-products-2'}
                        />
                    ) : (
                        <Link
                            href={''}
                            download
                            className={!fal_link ? style['disabled'] : ''}
                            onClick={() => handleDownloadMissing(downloadMissingFileResult, 'application/text')}
                            style={{ marginLeft: 16 }}
                        >
                            <ButtonOutline
                                text={EX5232 ? t('myOrders.faults') : 'Faltas'}
                                height={32}
                                onClick={() => {}}
                                width={82}
                                disabled={fal_link ? false : true}
                                icon={
                                    <FileDownloadOutlinedIcon
                                        style={{
                                            height: 20,
                                            width: 20,
                                            color: !fal_link ? '#ACAEC4' : '#0171E6',
                                        }}
                                    />
                                }
                                gtagClass="button__download-missing-products-2"
                            />
                        </Link>
                    )}
                </Col>
            </Row>
        </Col>
    );
};

export default Header;
