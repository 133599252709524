import { IconButton, Tooltip, useTheme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { DeleteOutline } from '@material-ui/icons';
import {
    createApiCredential,
    deleteAllApiCredential,
    deleteApiCredential,
    deletePercentageOfTolerance,
    getCatalogs,
    saveAddress,
    saveCatalogForDrugManufacturer,
    savePaymentMethod,
    updateApiCredential,
    updateCatalogForDrugManufacturer,
    uploadFormat,
} from 'api';
import { ApiCredential, DrugManufacturer } from 'common/types';
import { Button, Modal } from 'components';
import { EX3986, EX4600, EX4882, EX4893, EX5225 } from 'config/flags';
import { useAlert, useSnackbar } from 'context';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
    haveCredential,
    haveCredentialCatalog,
    haveCredentialClientIdentifier,
    haveEnabledCdOConnection,
} from 'utils/drug_manufacturer';
import { Status, useAsync, useModal, useUser } from 'utils/hooks';
import useChangeLanguage from 'utils/hooks/use-change-language';
import { DrugManufacturersId } from 'utils/interfaces';
import Regex from 'utils/regex';
import { mutateFromCache } from 'utils/swr';
import useStyles from './CredentialCard.styles';
import CredentialCardBody from './CredentialCardBody';
import CredentialCardFooter from './CredentialCardFooter';
import CredentialCardHeader from './CredentialCardHeader';
import { ModalConfirmUser } from './ModalConfirmUser';
import { capitalizeFirstLetter } from 'utils/string';
interface CredentialCardProps {
    id: number;
    name: string;
    format_ticket?: any;
    credential: ApiCredential;
    apiCredentialQuantity: number;
    buyOperationType: DrugManufacturer['buy_operation_type'];
    credential_types?: any;
    deposits?: any;
    client_types?: any;
    mutateDrugManufacturers?: any;
    credentials?: any;
    apiCredentials?: any;
    pointOfSaleValueId?: any;
    havePriorities: boolean;
    format_status?: any;
    haveLimitCredential?: boolean;
    mutateClientPos?: any;
    isCheckedPriority: any;
    drugmanufacturersData?: any;
}

interface DeleteCredentialProps {
    onClick(): void;
    hasAuthorization?: any;
    drugmanufacturerId?: any;
}
const useStylesTooltip = makeStyles(() =>
    createStyles({
        tooltipPlacementBottom: {
            position: 'relative',
            top: -10,
            width: 81,
            height: 24,
            fontSize: 14,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: '#04273F',
        },
    }),
);

function DeleteCredential({ onClick, hasAuthorization, drugmanufacturerId }: DeleteCredentialProps) {
    // translation by Country
    const { t } = useTranslation();
    const { user, userLocaleCountryCode } = useUser({});
    if (EX5225) useChangeLanguage(userLocaleCountryCode);
    const classesToltip = useStylesTooltip();
    return (
        <Tooltip
            placement="bottom"
            title={EX5225 ? t('modals.comparativeThree') : 'Eliminar'}
            classes={{ tooltip: classesToltip.tooltipPlacementBottom }}
        >
            <IconButton
                aria-label="delete"
                onClick={onClick}
                style={{
                    display:
                        !hasAuthorization &&
                        (drugmanufacturerId === DrugManufacturersId.AlmacenDeDrogas ||
                            (EX4600 && drugmanufacturerId === DrugManufacturersId.FarmaciasABC))
                            ? 'none'
                            : null,
                }}
            >
                <DeleteOutline />
            </IconButton>
        </Tooltip>
    );
}

function Motion({ children, show }) {
    if (!show) {
        return null;
    }
    return (
        <motion.div
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
                open: {
                    opacity: 1,
                    height: 'auto',
                    transition: {
                        opacity: { duration: 0.35, ease: 'easeOut' },
                        height: { duration: 0.25, ease: 'easeOut' },
                    },
                },
                collapsed: {
                    opacity: 0,
                    height: 0,
                    transition: {
                        opacity: { duration: 0.1, ease: 'easeIn' },
                        height: { duration: 0.25, ease: 'easeIn' },
                    },
                },
            }}
        >
            {children}
        </motion.div>
    );
}

const CredentialCard = React.memo(
    ({
        id,
        name,
        format_status,
        mutateClientPos,
        havePriorities,
        credential_types,
        deposits,
        client_types,
        buyOperationType,
        pointOfSaleValueId,
        haveLimitCredential,
        apiCredentialQuantity,
        mutateDrugManufacturers,
        isCheckedPriority,
        drugmanufacturersData,
    }: CredentialCardProps) => {
        const modal = useModal();
        const modalUser = useModal();
        const classes = useStyles();
        const { mutateUser, hasAuthorization } = useUser({});
        const [loading, setLoading] = React.useState(false);
        const [expanded, setExpanded] = React.useState(false);
        const [selectItems, setSelectItems] = React.useState({
            username: '',
            api_credential_id: 0,
            password: '',
        });

        const { user } = useUser({});

        const EX5305 = user?.EX5305;

        const { run: runApiCredentialDeleted, status: statusApiCredentialDeleted } = useAsync({});
        const { run: runAllApiCredentialDeleted, status: statusAllApiCredentialDeleted } = useAsync({});
        const {
            run: runUpdateApiCredential,
            status: statusApiUpdateCredential,
            data: dataUpdateCredential,
            error: errorUpdate,
        } = useAsync({});
        const {
            run: runCreateApiCredential,
            status: statusApiCreateCredential,
            data: dataCreateCredential,
            error: errorCreate,
        } = useAsync({});
        const { run: runSaveAddress, status: statusSaveAddress, error: errorSaveAddress } = useAsync({});
        const { run: runSavePaymentMethod, status: statusSavePaymentMethod, error: errorSavePaymentMethod } = useAsync(
            {},
        );
        const {
            run: runSaveCatalogForDrugManufacturer,
            status: statusSaveCatalogForDrugManufacturer,
            error: errorSaveCatalogForDrugManufacturer,
        } = useAsync({});
        const {
            run: runUpdateCatalogForDrugManufacturer,
            status: statusUpdateCatalogForDrugManufacturer,
            error: errorUpdateCatalogForDrugManufacturer,
        } = useAsync({});
        const { run: runUploadFormat } = useAsync({});

        const snackbar = useSnackbar();
        const { show } = useAlert();
        const {
            handleSubmit,
            register,
            errors,
            setError,
            getValues,
            setValue,
            reset,
            formState: { isDirty },
            clearErrors,
            trigger,
        } = useForm({
            mode: 'onChange',
        });

        const enabled = haveCredential(credential_types);
        const enabledCatalog = haveCredentialCatalog(credential_types);
        const enabledFarmaciasABC =
            id === DrugManufacturersId.FarmaciasABC && haveCredentialClientIdentifier(credential_types);
        const enabledConnectionCdO =
            id === DrugManufacturersId.CentralDeOfertas && haveEnabledCdOConnection(drugmanufacturersData);
        const error = Boolean(errors.username || errors.password);
        const [errorMsg, setErrorMsg] = React.useState(null);
        const [saveSuccess, setSaveSuccess] = React.useState(false);
        const EX3529 = process.env.NEXT_PUBLIC_EX3529 === 'true';
        const EX3649 = process.env.NEXT_PUBLIC_EX3649 === 'true';
        const { t } = useTranslation();
        const [addresses, setAddresses] = React.useState([]);

        const drugManufacturersNeedClientIdentifier = {
            [DrugManufacturersId.SuizoArgentina]: [
                {
                    credentialType: 'API Suizo',
                    required: true,
                    forceErrors: true,
                    name: 'client_identifier',
                    value: 1,
                    regex: {
                        value: Regex.number,
                        messageError: 'El dato ingresado debe ser numerico',
                    },
                    placeholder: 'Empresa',
                },
                {
                    credentialType: 'WEB Suizo',
                    required: true,
                    forceErrors: true,
                    name: 'client_identifier',
                    regex: {
                        value: Regex.number,
                        messageError: 'El dato ingresado debe ser numerico',
                    },
                    placeholder: 'N° de cuenta',
                },
            ],
            [DrugManufacturersId.MonroeAmericana]: {
                credentialType: 'credentialType',
                required: true,
                forceErrors: true,
                name: 'client_identifier',
                regex: {
                    value: Regex.number,
                    messageError: 'El dato ingresado debe ser numerico',
                },
                placeholder: 'Codigo de cliente',
            },
            [DrugManufacturersId.CofarmenMendoza]: {
                credentialType: 'credentialType',
                required: true,
                forceErrors: true,
                name: 'client_identifier',
                regex: {
                    value: Regex.number,
                    messageError: 'El dato ingresado debe ser numerico',
                },
                placeholder: 'Número de cliente',
            },
            [DrugManufacturersId.CofarmenSanJuan]: {
                credentialType: 'credentialType',
                required: true,
                forceErrors: true,
                name: 'client_identifier',
                regex: {
                    value: Regex.number,
                    messageError: 'El dato ingresado debe ser numerico',
                },
                placeholder: 'Número de cliente',
            },
            [DrugManufacturersId.DrogueriasDelSud]: [
                {
                    credentialType: 'API',
                    required: false,
                    forceErrors: false,
                    placeholder: 'Función',
                    name: 'function',
                },
                {
                    credentialType: 'WEB',
                    required: true,
                    forceErrors: true,
                    placeholder: 'Número de Cliente',
                    name: 'client_identifier',
                    regex: {
                        value: Regex.number,
                        messageError: 'El dato ingresado debe ser numerico',
                    },
                },
            ],
            [DrugManufacturersId.DrogueriaSur]: [
                {
                    fieldType: 'autoComplete',
                    credentialType: 'WEB',
                    required: true,
                    forceErrors: true,
                    placeholder: 'Dirección de entrega',
                    stepLabel: 'Selecciona una dirección de entrega',
                    name: 'address',
                    regex: {
                        value: Regex.username,
                        messageError: 'Campo obligatorio',
                    },
                },
            ],
            [DrugManufacturersId.Cofaloza]: [
                {
                    fieldType: 'autoComplete',
                    credentialType: 'WEB',
                    required: true,
                    forceErrors: true,
                    placeholder: 'Dirección de entrega',
                    stepLabel: 'Selecciona una dirección de entrega',
                    name: 'address',
                    regex: {
                        value: Regex.number,
                        messageError: 'Campo obligatorio',
                    },
                },
            ],
            [DrugManufacturersId.MedicinasRosario]: [
                {
                    fieldType: 'autoComplete',
                    credentialType: 'WEB',
                    required: true,
                    forceErrors: true,
                    placeholder: 'Dirección de entrega',
                    stepLabel: 'Selecciona una dirección de entrega',
                    name: 'address',
                    regex: {
                        value: Regex.number,
                        messageError: 'Campo obligatorio',
                    },
                },
            ],
            [DrugManufacturersId.Nadro]: {
                credentialType: 'credentialType',
                required: false,
                forceErrors: true,
                name: 'client_identifier',
                type: 'text',
                placeholder: 'Número de sucursal',
            },
            [DrugManufacturersId.Farmamigo]: [
                {
                    fieldType: 'autoComplete',
                    credentialType: 'WEB',
                    required: true,
                    forceErrors: true,
                    placeholder: 'Dirección de entrega',
                    stepLabel: 'Selecciona una dirección de entrega',
                    name: 'address',
                    regex: {
                        value: Regex.number,
                        messageError: 'Campo obligatorio',
                    },
                },
            ],
            [DrugManufacturersId.PacificPharmaGroup]: [
                {
                    fieldType: 'autoComplete',
                    credentialType: 'WEB',
                    required: true,
                    forceErrors: true,
                    placeholder: 'Método de pago',
                    stepLabel: 'Selecciona el método de pago',
                    name: 'payment_methods',
                    regex: {
                        value: Regex.number,
                        messageError: 'Campo obligatorio',
                    },
                },
            ],
            [DrugManufacturersId.AlmacenDeDrogas]: [
                {
                    credentialType: 'credentialType',
                    required: true,
                    forceErrors: true,
                    placeholder: 'Número de Cliente',
                    name: 'client_identifier',
                    regex: {
                        value: Regex.numberLengthToThirteen,
                        messageError: 'El dato ingresado debe ser numérico y hasta 13 dígitos',
                    },
                },
            ],
            [DrugManufacturersId.Fanasa]: [
                {
                    credentialType: 'credentialType',
                    required: false,
                    forceErrors: true,
                    name: 'client_identifier',
                    type: 'text',
                    placeholder: 'ID de Farmacia',
                },
            ],
            [DrugManufacturersId.FarmaciasABC]: [
                {
                    credentialType: 'credentialType',
                    required: true,
                    forceErrors: true,
                    placeholder: 'Número de Cliente',
                    name: 'client_identifier',
                    regex: {
                        value: Regex.number,
                        messageError: 'El dato ingresado debe ser numerico',
                    },
                },
            ],
            [DrugManufacturersId.Dimed]: [
                {
                    credentialType: 'credentialType',
                    required: false,
                    forceErrors: true,
                    placeholder: 'Número do Cliente',
                    name: 'client_identifier',
                    regex: {
                        value: Regex.number,
                        messageError: 'O dado inserido deve ser numérico',
                    },
                },
            ],
            [DrugManufacturersId.FarmaceuticosTenorioSAdeCV]: [
                {
                    fieldType: 'autoComplete',
                    credentialType: 'WEB',
                    required: true,
                    forceErrors: true,
                    placeholder: 'Método de pago',
                    stepLabel: 'Selecciona el método de pago',
                    name: 'payment_methods',
                    regex: {
                        value: Regex.number,
                        messageError: 'Campo obligatorio',
                    },
                },
            ],
            [DrugManufacturersId.FarmaMX]: [
                {
                    fieldType: 'autoComplete',
                    credentialType: 'WEB',
                    required: true,
                    placeholder: 'Depósito',
                    stepLabel: 'Selecciona el depósito',
                    name: 'client_identifier',
                },
            ],
        };
        const [idError, setIdError] = React.useState(0);
        const [nameSelect, setNameSelect] = React.useState('scraper');
        const [type, setType] = React.useState(2);
        const [username, setUsername] = React.useState(null);
        const [disable, setDisable] = React.useState(false);
        const [credencialId, setCredencialId] = React.useState();
        const [stepAddress, setStepAddress] = React.useState(1);
        const [addressSelected, setAddressSelected] = React.useState(null);
        const credentialTypeSelectedId = nameSelect === 'api' ? 0 : 1;
        const drugManufacturersWithAddress = [10, 18, 24, 34, 32];
        const itUsesAddress = drugManufacturersWithAddress.some((drugId) => drugId === id) && nameSelect === 'scraper';
        const findAddress = credential_types[credentialTypeSelectedId]?.dispatch_addresses?.find(
            (address) => address?.selected === true,
        );
        const itHasAddressesSelected = credential_types[credentialTypeSelectedId]?.dispatch_addresses
            ? findAddress?.dispatch_address?.description
            : '';
        const getAllAddresses = credential_types[credentialTypeSelectedId]?.dispatch_addresses?.filter(
            (address) => address?.selected === false,
        );

        const [errorPasswordUsername, setErrorPasswordUsername] = React.useState(false);
        const [paymentMethod, setPaymentMethod] = useState([]);
        const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
        const [apiCredentialSelected, setApiCredentialSelected] = useState('');
        const [catalogs, setCatalogs] = useState([]);
        const [catalogSelected, setCatalogSelected] = useState([]);
        const [itHasCatalogSelected, setItHasCatalogSelected] = useState(false);
        const [idCatalogLoaded, setIdCatalogLoaded] = useState(null);
        const [nameCatalogLoaded, setNameCatalogLoaded] = useState('');
        const [idCatalogToSend, setIdCatalogToSend] = useState(null);

        const drugManufacturersWithDeposit = [
            DrugManufacturersId.MedicinasRosario,
            DrugManufacturersId.Vicma,
            DrugManufacturersId.FarmaMX,
        ];
        const itUsesDeposits = drugManufacturersWithDeposit.some((drugId) => drugId === id) && nameSelect === 'scraper';
        const itHasDepositSelected = itUsesDeposits && credential_types[credentialTypeSelectedId]?.client_identifier;

        const parserReceivedDepositFarmaMX = (value) => {
            if (!!value) {
                const splittedValue = value?.split('&&');
                if (splittedValue?.length < 2) {
                    console.error('formatted value is not correct, expected KEY&&VALUE');
                } else if (!!splittedValue) {
                    return capitalizeFirstLetter(splittedValue[1]);
                }
            }
        };

        const [depositSelected, setDepositSelected] = useState(
            !!itHasDepositSelected && EX5305 && id === DrugManufacturersId?.FarmaMX
                ? parserReceivedDepositFarmaMX(itHasDepositSelected)
                : itHasDepositSelected,
        );

        const drugManufacturersWithClientTypes = [DrugManufacturersId.Coopidrogas];
        const itUsesClientTypes =
            drugManufacturersWithClientTypes.some((drugId) => drugId === id) && nameSelect === 'scraper';
        const itHasClientTypesSelected =
            itUsesClientTypes && credential_types[credentialTypeSelectedId]?.client_identifier;
        const [clientTypeSelected, setClientTypeSelected] = useState(itHasClientTypesSelected);

        const alertTitle = EX5225 ? t('pointOfSale.modalDiscardChangesTitle') : 'Descartar cambios';

        const alertText = EX3529
            ? t('settings.editPointsOfSale.sett_discardUnsavedCredentialsTextPointsOfSale')
            : 'Se descartarán las credenciales que no fueron guardadas.';

        const alertConfirm = EX5225 ? t('pointOfSale.modalDiscardChangesConfirmDiscard') : 'Si, descartar';

        function handleFileInputChange(event) {
            const selectedFile = event.target.files[0];
            const formData = new FormData();
            formData.append('file', selectedFile);
            runUploadFormat(uploadFormat(formData, credencialId));
            snackbar.show({
                message: 'Incorporación de formato solicitada.',
            });
        }

        const onChange = (event, item) => {
            event.preventDefault();
            setSaveSuccess(false);
            if (isDirty && (getValues('username') !== '' || getValues('password') !== '')) {
                show({
                    className: '',
                    cancel: true,
                    size: 'xs',
                    title: alertTitle,
                    body: alertText,
                    onConfirm: () => {
                        reset({
                            username: item.username,
                            password: item.password,
                            client_identifier: item.client_identifier,
                        });
                        setUsername(item.username);
                        setType(item.id);
                        setSelectItems(item);
                        setNameSelect(item.name);
                    },
                    onCancel: () => {},
                    action: alertConfirm,
                });
            } else {
                reset({
                    username: item.username,
                    password: item.password,
                    client_identifier: item.client_identifier,
                });
                setSelectItems(item);
                setType(item.id);
                setNameSelect(item.name);
                setUsername(item.username);
                setSaveSuccess(false);
            }
        };

        const handleDataUserCredential = () => {
            const credential_type = credential_types?.find((c) => c.name === nameSelect);
            const { username, client_identifier, password } = credential_type ?? {};
            setSelectItems(credential_type);
            setUsername(username);

            setValue('username', username);
            setValue('password', password);
            setValue(
                'client_identifier',
                EX5305 && id === DrugManufacturersId?.FarmaMX
                    ? parserReceivedDepositFarmaMX(itHasDepositSelected)
                    : itHasDepositSelected,
            );

            // reset({
            //     username: username,
            //     password: password,
            //     client_identifier:
            //         id === DrugManufacturersId.FarmaMX
            //             ? parserReceivedDepositFarmaMX(client_identifier)
            //             : client_identifier,
            // });
        };

        const onConfirm = () => {
            setNameSelect('scraper');
            setTimeout(() => {
                setSaveSuccess(false);
            }, 500);
            setExpanded(false);
            setStepAddress(1);
        };

        const handleCancel = () => {
            if (isDirty && (getValues('username') !== '' || getValues('password') !== '')) {
                show({
                    className: '',
                    cancel: true,
                    size: 'xs',
                    title: alertTitle,
                    body: alertText,
                    onConfirm,
                    action: alertConfirm,
                    onCancel: () => {},
                    async: undefined,
                });
            } else {
                setErrorMsg(null);
                onConfirm();
            }
        };

        const onSubmit = async (data, confirmed = false) => {
            const sendConfirmed = !!EX4882 && confirmed;
            if (type === 3) {
                delete data['function'];
            } else if (id === 1) {
                delete data['client_identifier'];
            }
            const values = getValues();
            if (!data) {
                data = values;
            }
            setErrorMsg(null);
            const isFarmaterPaymentMethod =
                id === DrugManufacturersId.FarmaceuticosTenorioSAdeCV && selectedPaymentMethod;
            try {
                if (EX5305 && id === DrugManufacturersId.FarmaMX) {
                    if (!!apiCredentialSelected) {
                        setLoading(true);
                        runUpdateApiCredential(
                            updateApiCredential(apiCredentialSelected, {
                                ...data,
                                drug_manufacturer_id: id,
                                type_id: type,
                                confirmed: true,
                                username: selectItems?.username,
                                password: selectItems?.password,
                                client_identifier: stepAddress === 1 ? '' : depositSelected,
                            }),
                        );
                        return;
                    } else {
                        setLoading(true);
                        runCreateApiCredential(
                            createApiCredential({
                                ...data,
                                point_of_sale_id: parseInt(pointOfSaleValueId),
                                drug_manufacturer_id: id,
                                type_id: type,
                                confirmed: !!EX4882 && sendConfirmed,
                            }),
                        );
                        return;
                    }
                }
                if (stepAddress === 2 && !!addressSelected) {
                    runSaveAddress(saveAddress(selectItems?.api_credential_id, addressSelected));
                    setStepAddress(1);
                    return;
                }
                if (stepAddress === 2 && id === 32 && !!selectedPaymentMethod) {
                    runSavePaymentMethod(savePaymentMethod(apiCredentialSelected, selectedPaymentMethod));
                    setStepAddress(1);
                    return;
                }
                setLoading(true);
                setDisable(true);
                if (selectItems?.username || enabledFarmaciasABC) {
                    runUpdateApiCredential(
                        updateApiCredential(selectItems?.api_credential_id, {
                            ...data,
                            drug_manufacturer_id: id,
                            type_id: type,
                            confirmed: !!EX4882 && username === data?.username ? true : sendConfirmed,
                            ...(isFarmaterPaymentMethod && { payment_method_id: selectedPaymentMethod.id }),
                        }),
                    );
                } else {
                    runCreateApiCredential(
                        createApiCredential({
                            ...data,
                            point_of_sale_id: parseInt(pointOfSaleValueId),
                            drug_manufacturer_id: id,
                            type_id: type,
                            confirmed: !!EX4882 && sendConfirmed,
                            ...(isFarmaterPaymentMethod && { payment_method_id: selectedPaymentMethod.id }),
                        }),
                    );
                }
                setUsername(data.username);
            } catch (error) {
                setSaveSuccess(false);
                setLoading(false);

                let fields = null;
                if (data?.function !== undefined) {
                    fields = Boolean(drugManufacturersNeedClientIdentifier[id])
                        ? ['username', 'password', 'function']
                        : ['username', 'password'];
                } else {
                    fields = Boolean(drugManufacturersNeedClientIdentifier[id])
                        ? ['username', 'password', 'client_identifier']
                        : ['username', 'password'];
                }

                fields.forEach((key) => setError(key, error));
            }
        };
        const onSubmitCatalog = async (data) => {
            try {
                if (idCatalogLoaded && nameCatalogLoaded && id === DrugManufacturersId.AlmacenDeDrogas) {
                    runUpdateCatalogForDrugManufacturer(
                        updateCatalogForDrugManufacturer(
                            selectItems.api_credential_id,
                            parseInt(pointOfSaleValueId),
                            data.client_identifier,
                            DrugManufacturersId.AlmacenDeDrogas,
                            idCatalogToSend,
                        ),
                    );
                    return;
                }
                if (!idCatalogLoaded && !nameCatalogLoaded && id === DrugManufacturersId.AlmacenDeDrogas) {
                    runSaveCatalogForDrugManufacturer(
                        saveCatalogForDrugManufacturer(
                            parseInt(pointOfSaleValueId),
                            data.client_identifier,
                            DrugManufacturersId.AlmacenDeDrogas,
                            idCatalogToSend,
                        ),
                    );
                }
            } catch (error) {
                setSaveSuccess(false);
                setLoading(false);
                let fields = null;
                if (data?.catalog_id !== undefined) {
                    fields = Boolean(drugManufacturersNeedClientIdentifier[id]) && ['client_identifier'];
                }
                fields.forEach((key) => setError(key, error));
            }
        };
        const handlePostDelete = () => {
            reset({
                username: '',
                password: '',
                client_identifier: '',
            });
            setUsername(null);
            setSaveSuccess(false);
            setSelectItems(null);
            setStepAddress(1);
            setDepositSelected(null);
            setClientTypeSelected(null);

            runApiCredentialDeleted(deleteApiCredential(selectItems?.api_credential_id));
        };

        const handlePostDeleteAllCredentials = () => {
            reset({
                username: '',
                password: '',
                client_identifier: '',
            });
            setUsername(null);
            setSaveSuccess(false);
            setSelectItems(null);
            if (!!isCheckedPriority?.priority_id && !!isCheckedPriority?.distribution_id) {
                deletePercentageOfTolerance(parseInt(pointOfSaleValueId));
                runAllApiCredentialDeleted(deleteAllApiCredential(id, parseInt(pointOfSaleValueId)));
            } else if (!isCheckedPriority?.priority_id && !isCheckedPriority?.distribution_id) {
                deletePercentageOfTolerance(parseInt(pointOfSaleValueId));
                runAllApiCredentialDeleted(deleteAllApiCredential(id, parseInt(pointOfSaleValueId)));
            } else if (!!isCheckedPriority?.priority_id) {
                deletePercentageOfTolerance(parseInt(pointOfSaleValueId));
                runAllApiCredentialDeleted(deleteAllApiCredential(id, parseInt(pointOfSaleValueId)));
            } else if (!!isCheckedPriority?.distribution_id) {
                deletePercentageOfTolerance(parseInt(pointOfSaleValueId));
                runAllApiCredentialDeleted(deleteAllApiCredential(id, parseInt(pointOfSaleValueId)));
            }
        };

        const handleDelete = () => {
            //Valido si tiene mas de una credential configurada para saber que texto mostrar
            const hasMoreThanOneCredential = credential_types.every((credential: any) => !!credential.username);

            let oneCredential = '';
            if (!hasMoreThanOneCredential && apiCredentialQuantity === 1) {
                oneCredential = EX5225
                    ? t('pointOfSale.modalDeleteCredentialBody2')
                    : ' y ya no podrás utilizar la compra eficiente';
            }

            let textBody = '';

            if (!!isCheckedPriority?.priority_id && !hasMoreThanOneCredential && !isCheckedPriority?.distribution_id) {
                textBody = EX5225
                    ? t('pointOfSale.modalDeleteCredentialBody4')
                    : 'y la selección automática por prioridad';
            } else if (
                !!isCheckedPriority?.distribution_id &&
                !hasMoreThanOneCredential &&
                !isCheckedPriority?.priority_id
            ) {
                textBody = EX5225
                    ? t('pointOfSale.modalDeleteCredentialBody5')
                    : 'y la selección automática por porcentaje';
            } else if (
                !!isCheckedPriority?.priority_id &&
                !!isCheckedPriority?.distribution_id &&
                !hasMoreThanOneCredential
            ) {
                textBody = EX5225
                    ? t('pointOfSale.modalDeleteCredentialBody3')
                    : 'y la selección automática por prioridad y por porcentaje ';
            }

            let text = EX5225
                ? `${t('pointOfSale.modalDeleteCredentialBody6')} ${textBody}${oneCredential}`
                : `Se eliminará la credencial ${textBody}${oneCredential}.`;

            show({
                className: '',
                cancel: true,
                size: 'xs',
                title: EX5225 ? t('pointOfSale.modalDeleteCredentialTitle2') : 'Eliminar credencial',
                body: `${text}`,
                onConfirm: () => handlePostDelete(),
                onCancel: () => {},
                async: undefined,
                action: EX5225 ? t('pointOfSale.modalDeleteCredentialConfirmDelete2') : 'Eliminar',
            });
        };

        const handleDeleteAllCredentials = () => {
            let textBody = '';
            if (!!isCheckedPriority?.priority_id && !!isCheckedPriority?.distribution_id) {
                textBody = EX5225
                    ? t('pointOfSale.modalDeleteCredentialBody3')
                    : 'y la selección automática por prioridad y por porcentaje';
            } else if (!!isCheckedPriority?.priority_id) {
                textBody = EX5225
                    ? t('pointOfSale.modalDeleteCredentialBody4')
                    : 'y la selección automática por prioridad';
            } else if (!!isCheckedPriority?.distribution_id) {
                textBody = EX5225
                    ? t('pointOfSale.modalDeleteCredentialBody5')
                    : 'y la selección automática por porcentaje';
            }

            show({
                className: '',
                title: EX5225 ? t('pointOfSale.modalDeleteCredentialTitle1') : 'Eliminar credenciales',
                cancel: true,
                size: 'xs',
                body: EX5225
                    ? `${t('pointOfSale.modalDeleteCredentialBody1')} ${name} ${textBody} ${
                          apiCredentialQuantity === 2 ? t('pointOfSale.modalDeleteCredentialBody2') : '.'
                      }`
                    : `Se eliminarán todas las credenciales de ${name} ${textBody} ${
                          apiCredentialQuantity === 2 ? ' y ya no podrás utilizar la compra eficiente.' : '.'
                      }`,
                onConfirm: () => handlePostDeleteAllCredentials(),
                onCancel: () => {},
                async: undefined,
                action: EX5225 ? t('pointOfSale.modalDeleteCredentialConfirmDelete1') : 'Si, eliminar ',
            });
        };

        const handleDeleteAllCredentialsWithPriorities = () => {
            let textBody = '';
            if (!!isCheckedPriority?.priority_id && !!isCheckedPriority?.distribution_id) {
                textBody = EX5225
                    ? t('pointOfSale.modalDeleteCredentialBody3')
                    : 'y la selección automática por prioridad y por porcentaje';
            } else if (!!isCheckedPriority?.priority_id) {
                textBody = EX5225
                    ? t('pointOfSale.modalDeleteCredentialBody4')
                    : 'y la selección automática por prioridad';
            } else if (!!isCheckedPriority?.distribution_id) {
                textBody = EX5225
                    ? t('pointOfSale.modalDeleteCredentialBody5')
                    : 'y la selección automática por porcentaje';
            }

            show({
                className: '',
                title: EX5225 ? t('pointOfSale.modalDeleteCredentialTitle1') : 'Eliminar credenciales',
                cancel: true,
                size: 'xs',
                body: EX5225
                    ? `${t('pointOfSale.modalDeleteCredentialBody1')} ${name} ${textBody} ${
                          apiCredentialQuantity === 2 ? t('pointOfSale.modalDeleteCredentialBody2') : '.'
                      }`
                    : `Se eliminarán todas las credenciales de ${name} ${textBody}${
                          apiCredentialQuantity === 2 ? ' y ya no podrás utilizar la compra eficiente.' : '.'
                      }`,
                onConfirm: () => handlePostDeleteAllCredentials(),
                onCancel: () => {},
                action: EX5225 ? t('pointOfSale.modalDeleteCredentialConfirmDelete1') : 'Si, eliminar ',
            });
        };

        function handleSubmitFormat() {
            switch (format_status?.name) {
                case 'VALIDATION_PENDING':
                    return true;
                case 'UNKNOWN':
                    return false;
                case 'VALIDATED':
                    return true;
                default:
                    return true;
            }
        }

        function formatDateRequest(created_at) {
            const dateObj = new Date(created_at);
            const day = dateObj.getDate();
            const month = dateObj.toLocaleString('es', { month: 'long' });
            const Month = month.charAt(0).toUpperCase() + month.slice(1);
            return day + ' ' + Month.slice(0, 3);
        }

        function handleFormatRequest() {
            if (nameSelect === 'scraper') {
                if (credential_types[1]?.format_ticket) {
                    return formatDateRequest(credential_types[1]?.format_ticket?.created_at);
                }
            }
            if (nameSelect === 'scraper_np') {
                if (credential_types[2]?.format_ticket) {
                    return formatDateRequest(credential_types[2]?.format_ticket?.created_at);
                }
            }
        }

        function handleLimitCredential() {
            if (haveLimitCredential && !enabled) {
                modal.toggle();
            } else {
                setExpanded(true);
            }
        }

        const setAddressesIfHave = () => {
            const apiCredentialSelected = credential_types[credentialTypeSelectedId]?.api_credential_id;
            const addressMapping = credential_types[credentialTypeSelectedId]?.dispatch_addresses?.map((address) => {
                return {
                    id: address?.dispatch_address?.id,
                    label: address?.dispatch_address?.description,
                    api_credential_id: apiCredentialSelected,
                };
            });
            const paymentMethods = credential_types[credentialTypeSelectedId]?.payment_methods?.map((payment) => {
                return {
                    id: payment?.id,
                    label: payment?.name,
                    api_credential_id: apiCredentialSelected,
                };
            });
            setApiCredentialSelected(apiCredentialSelected);
            setPaymentMethod(paymentMethods);
            setAddresses(addressMapping);
        };

        const setInitialPaymentMethod = () => {
            const drugManufacturerData = drugmanufacturersData?.filter(
                (drugId) => drugId.id === DrugManufacturersId.FarmaceuticosTenorioSAdeCV,
            );
            const credentialTypes = drugManufacturerData[0]?.credential_types[1];
            const paymentMethods = credentialTypes?.payment_methods;
            const currentPaymentMethodId = credentialTypes?.payment_method_id;
            const originalPaymentMethod = paymentMethods?.find(
                (paymentMethod) => paymentMethod.id === currentPaymentMethodId,
            );
            if (originalPaymentMethod) {
                setSelectedPaymentMethod({ id: originalPaymentMethod.id, label: originalPaymentMethod.name });
            }
        };

        const getAddressInUse = (getAllAddresses, addressSelected) => {
            return getAllAddresses?.some((address) => {
                const iterateInUse = address?.in_use?.filter((addressInUse) => {
                    return addressInUse?.dispatch_address_id === addressSelected?.id;
                });
                return iterateInUse && iterateInUse.length > 0;
            });
        };

        const getAddressInUsePOSName = (getAllAddresses, addressSelected) => {
            let getPOSName = '';
            getAllAddresses?.filter((address) => {
                const iterateInUse = address?.in_use?.filter((addressInUse) => {
                    return addressInUse?.dispatch_address_id === addressSelected?.id;
                });
                if (iterateInUse && iterateInUse.length > 0) {
                    getPOSName = address?.in_use[0]?.point_of_sale_name;
                }
            });
            return getPOSName;
        };

        React.useEffect(() => {
            switch (statusApiCredentialDeleted) {
                case Status.Resolved:
                    setErrorPasswordUsername(false);
                    setIdError(0);
                    snackbar.show({
                        message: EX5225 ? t('pointOfSale.deletedCredentialCard') : 'Credencial eliminada.',
                    });
                    mutateFromCache('/api/api-credential');
                    reset({
                        username: '',
                        password: '',
                        client_identifier: '',
                    });
                    setUsername(null);
                    if (mutateClientPos) {
                        mutateClientPos();
                    }
                    mutateUser();
                    mutateDrugManufacturers();
                    break;
            }
        }, [statusApiCredentialDeleted]);

        React.useEffect(() => {
            switch (statusAllApiCredentialDeleted) {
                case Status.Resolved:
                    snackbar.show({
                        message: EX5225 ? t('pointOfSale.snackbarCredentialDeleted') : 'Credenciales eliminadas.',
                    });
                    mutateFromCache('/api/api-credential');
                    if (mutateClientPos) {
                        mutateClientPos();
                    }
                    reset({
                        username: '',
                        password: '',
                        client_identifier: '',
                    });
                    setIdError(0);
                    setErrorPasswordUsername(false);
                    setUsername(null);
                    mutateUser();
                    mutateDrugManufacturers();
                    break;
            }
        }, [statusAllApiCredentialDeleted]);
        function controlCredential(texto) {
            // Expresión regular para buscar la frase
            let regularExpression = /La credencial ingresada ya ha sido utilizada.*otros puntos de ventas/g;

            // Usar el método test() para verificar si la frase está presente en el texto
            return regularExpression.test(texto);
        }

        const [depositsFarmaMX, setDepositsFarmaMX] = React.useState([]);

        React.useEffect(() => {
            switch (statusApiUpdateCredential) {
                case Status.Resolved:
                    setErrorPasswordUsername(false);
                    setIdError(0);
                    mutateFromCache('/api/api-credential');
                    mutateDrugManufacturers();
                    clearErrors('username');
                    clearErrors('password');
                    setLoading(false);
                    setDisable(false);
                    if (EX4893) {
                        if (EX5305 && id === DrugManufacturersId.FarmaMX && stepAddress === 1) {
                            setDepositsFarmaMX(dataUpdateCredential?.data?.data.available_branches);
                            setStepAddress(2);
                            return;
                        }
                        if (
                            itUsesAddress &&
                            id === DrugManufacturersId.Cofaloza &&
                            dataUpdateCredential?.data?.data?.dispatch_addresses?.length > 0
                        ) {
                            setStepAddress(2);
                            return;
                        }
                        if (itUsesAddress && id !== DrugManufacturersId.Cofaloza) {
                            setStepAddress(2);
                            return;
                        }
                    }
                    if (!EX4893 && itUsesAddress) {
                        setStepAddress(2);
                        return;
                    }
                    setSaveSuccess(true);
                    setTimeout(() => {
                        onConfirm();
                    }, 500);
                    break;
                case Status.Rejected:
                    if (!!EX4882 && controlCredential(errorCreate?.response?.data?.data?.validation?.username[0])) {
                        modalUser.toggle();
                        setErrorMsg(errorUpdate?.response?.data?.data?.validation?.username[0]);
                        setLoading(false);
                        setSaveSuccess(false);
                        setDisable(false);
                    } else {
                        setError('username', null);
                        setError('password', null);
                        setErrorMsg(errorUpdate?.response?.data?.data?.validation?.username[0]);
                        setLoading(false);
                        setSaveSuccess(false);
                        setDisable(false);
                    }

                    break;
            }
        }, [statusApiUpdateCredential]);

        React.useEffect(() => {
            switch (statusApiCreateCredential) {
                case Status.Resolved:
                    setIdError(0);
                    setErrorPasswordUsername(false);
                    setSaveSuccess(true);
                    mutateFromCache('/api/api-credential');
                    mutateDrugManufacturers();
                    setLoading(false);
                    setDisable(false);
                    if (EX4893) {
                        if (EX5305 && id === DrugManufacturersId.FarmaMX) {
                            setDepositsFarmaMX(dataCreateCredential?.data?.data.available_branches);
                            setStepAddress(2);
                            return;
                        }
                        if (
                            itUsesAddress &&
                            id === DrugManufacturersId.Cofaloza &&
                            dataCreateCredential?.data?.data?.dispatch_addresses?.length > 0
                        ) {
                            setStepAddress(2);
                            return;
                        }
                        if (itUsesAddress && id !== DrugManufacturersId.Cofaloza) {
                            setStepAddress(2);
                            return;
                        }
                    }
                    if (!EX4893 && itUsesAddress) {
                        setStepAddress(2);
                        return;
                    }
                    if (mutateClientPos) {
                        mutateClientPos();
                    }
                    setTimeout(() => {
                        onConfirm();
                    }, 500);
                    break;
                case Status.Rejected:
                    if (!!EX4882 && controlCredential(errorCreate?.response?.data?.data?.validation?.username[0])) {
                        modalUser.toggle();
                        setErrorMsg(errorCreate?.response?.data?.data?.validation?.username[0]);
                        setLoading(false);
                        setSaveSuccess(false);
                        setDisable(false);
                    } else {
                        setError('username', null);
                        setError('password', null);
                        setErrorMsg(errorCreate?.response?.data?.data?.validation?.username[0]);
                        setLoading(false);
                        setSaveSuccess(false);
                        setDisable(false);
                    }
                    break;
            }
        }, [statusApiCreateCredential]);

        React.useEffect(() => {
            switch (statusSaveAddress) {
                case Status.Resolved:
                    setIdError(0);
                    setErrorPasswordUsername(false);
                    mutateFromCache('/api/api-credential');
                    mutateDrugManufacturers();
                    clearErrors('username');
                    clearErrors('password');
                    setLoading(false);
                    setSaveSuccess(true);
                    setExpanded(false);
                    setDisable(false);
                    setStepAddress(1);
                    break;
                case Status.Rejected:
                    setError('username', null);
                    setError('password', null);
                    setLoading(false);
                    setSaveSuccess(false);
                    setErrorMsg(errorUpdate?.response?.data?.data?.validation?.username[0]);

                    setDisable(false);
                    break;
            }
        }, [statusSaveAddress]);

        React.useEffect(() => {
            switch (statusSavePaymentMethod) {
                case Status.Resolved:
                    setIdError(0);
                    setErrorPasswordUsername(false);
                    mutateFromCache('/api/api-credential');
                    mutateDrugManufacturers();
                    clearErrors('username');
                    clearErrors('password');
                    setLoading(false);
                    setSaveSuccess(true);
                    setExpanded(false);
                    setDisable(false);
                    setStepAddress(1);
                    break;
                case Status.Rejected:
                    setError('username', null);
                    setError('password', null);
                    setLoading(false);
                    setSaveSuccess(false);
                    setErrorMsg(errorUpdate?.response?.data?.data?.validation?.username[0]);

                    setDisable(false);
                    break;
            }
        }, [statusSavePaymentMethod]);

        React.useEffect(() => {
            handleDataUserCredential();
        }, [expanded, credential_types]);

        React.useEffect(() => {
            let credentialId = credential_types.find((credential) => credential.name === nameSelect);
            setCredencialId(credentialId?.api_credential_id);
        }, [credential_types]);

        React.useEffect(() => {
            setAddressesIfHave();
            setInitialPaymentMethod();
        }, [stepAddress, credential_types]);

        React.useEffect(() => {
            switch (statusSaveCatalogForDrugManufacturer) {
                case Status.Resolved:
                    setIdError(0);
                    setErrorPasswordUsername(false);
                    setSaveSuccess(true);
                    clearErrors('autoComplete');
                    clearErrors('client_identifier');
                    mutateFromCache('/api/api-credential');
                    mutateDrugManufacturers();
                    setLoading(false);
                    setDisable(false);
                    if (mutateClientPos) {
                        mutateClientPos();
                    }
                    break;
                case Status.Rejected:
                    setError('autoComplete', null);
                    setError('client_identifier', null);
                    setLoading(false);
                    setSaveSuccess(false);
                    setErrorMsg(errorSaveCatalogForDrugManufacturer?.response?.data?.data?.validation);
                    setDisable(false);
                    break;
            }
        }, [statusSaveCatalogForDrugManufacturer]);
        React.useEffect(() => {
            switch (statusUpdateCatalogForDrugManufacturer) {
                case Status.Resolved:
                    setIdError(0);
                    setErrorPasswordUsername(false);
                    setSaveSuccess(true);
                    clearErrors('autoComplete');
                    clearErrors('client_identifier');
                    mutateFromCache('/api/api-credential');
                    mutateDrugManufacturers();
                    setLoading(false);
                    setDisable(false);
                    if (mutateClientPos) {
                        mutateClientPos();
                    }
                    break;
                case Status.Rejected:
                    setError('autoComplete', null);
                    setError('client_identifier', null);
                    setLoading(false);
                    setSaveSuccess(false);
                    setErrorMsg(errorUpdateCatalogForDrugManufacturer?.response?.data?.data?.validation);
                    setDisable(false);
                    break;
            }
        }, [statusUpdateCatalogForDrugManufacturer]);

        const [failedPasswordUsername, setFailedPasswordUsername] = useState(false);

        React.useEffect(() => {
            credential_types?.map((credential) => {
                if (credential?.errors?.length > 0 && !!EX3986) {
                    setFailedPasswordUsername(true);
                }
            });
        }, [credential_types]);
        const [failedClientIdentifierCatalog, setFailedClientIdentifierCatalog] = useState(false);
        React.useEffect(() => {
            credential_types?.map((credential) => {
                if (credential?.errors?.length > 0 && !!EX3986) {
                    setFailedPasswordUsername(true);
                    setFailedClientIdentifierCatalog(true);
                }
            });
        }, [credential_types]);
        const fetchGetCatalogs = async () => {
            const response = await getCatalogs();
            setCatalogs(response.data.data);
            findCatalogLoaded();
        };
        useEffect(() => {
            if (hasAuthorization && id === DrugManufacturersId.AlmacenDeDrogas) fetchGetCatalogs();
        }, [hasAuthorization]);
        const findCatalogLoaded = () => {
            if (id === DrugManufacturersId.AlmacenDeDrogas) {
                const manufacturer = drugmanufacturersData?.find(
                    (manufacturer) => manufacturer.id === DrugManufacturersId.AlmacenDeDrogas,
                );
                if (manufacturer) {
                    const catalogId = manufacturer.credential_types.find(
                        (credentialType) => credentialType.catalog_id != null,
                    )?.catalog_id;
                    setIdCatalogLoaded(catalogId);
                    const catalogName = catalogs.find((catalog) => catalog.id === catalogId)?.name;
                    setNameCatalogLoaded(catalogName);
                    setItHasCatalogSelected(true);
                } else {
                    setIdCatalogLoaded(null);
                    setNameCatalogLoaded(null);
                    setItHasCatalogSelected(false);
                    setIdCatalogToSend(null);
                }
            }
        };
        //handler to detect if catalog is loaded and use patch instead of post in endpoint
        useEffect(() => {
            findCatalogLoaded();
        }, [drugmanufacturersData, catalogs]);

        //handler to send catalog_id to endpoint
        useEffect(() => {
            if (catalogSelected) {
                const catalogId = catalogs.find((catalog) => catalog.name === catalogSelected)?.id;
                setIdCatalogToSend(catalogId);
            } else {
                setIdCatalogToSend(null);
            }
        }, [catalogSelected]);

        return (
            <form className={classes.root} autoComplete="off">
                <OnConfirmLimitModal modal={modal} />
                {!!EX4882 && (
                    <ModalConfirmUser
                        modal={modalUser}
                        onSubmit={onSubmit}
                        credentials={errorMsg}
                        credential_username={getValues('username')}
                    />
                )}
                <input autoComplete="false" name="hidden" type="text" className="hidden" style={{ display: 'none' }} />

                <CredentialCardHeader
                    title={name}
                    enabled={enabled}
                    erp_status={format_status?.name}
                    failedPasswordUsername={failedPasswordUsername}
                    onClick={expanded ? undefined : () => handleLimitCredential()}
                    deleteComponent={
                        (enabled || enabledCatalog || enabledFarmaciasABC) &&
                        id !== DrugManufacturersId.CentralDeOfertas ? (
                            <DeleteCredential
                                onClick={
                                    havePriorities
                                        ? handleDeleteAllCredentialsWithPriorities
                                        : handleDeleteAllCredentials
                                }
                                hasAuthorization={hasAuthorization}
                                drugmanufacturerId={id}
                            />
                        ) : null
                    }
                    drugmanufacturerId={id}
                    failedClientIdentifierCatalog={failedClientIdentifierCatalog}
                    enabledCatalog={enabledCatalog}
                    enabledFarmaciasABC={enabledFarmaciasABC}
                    enabledConnectionCdO={enabledConnectionCdO}
                />
                <AnimatePresence>
                    <Motion show={expanded}>
                        <CredentialCardBody
                            errors={errors}
                            setValue={setValue}
                            onChange={onChange}
                            loading={loading}
                            idError={idError}
                            setIdError={setIdError}
                            saveSuccess={saveSuccess}
                            error={error}
                            register={register}
                            getValues={getValues}
                            nameSelect={nameSelect}
                            handleFormatRequest={handleFormatRequest}
                            setCredencialId={setCredencialId}
                            buyOperationType={buyOperationType}
                            credential_types={credential_types}
                            stepAddress={stepAddress}
                            setAddressSelected={setAddressSelected}
                            addresses={addresses}
                            itHasAddressesSelected={itHasAddressesSelected}
                            clientIdentifier={drugManufacturersNeedClientIdentifier[id]}
                            pointOfSaleName={getAddressInUsePOSName(getAllAddresses, addressSelected)}
                            addressInUse={getAddressInUse(getAllAddresses, addressSelected)}
                            errorPasswordUsername={errorPasswordUsername}
                            setErrorPasswordUsername={setErrorPasswordUsername}
                            paymentMethod={paymentMethod}
                            selectedPaymentMethod={selectedPaymentMethod}
                            setSelectedPaymentMethod={setSelectedPaymentMethod}
                            drugmanufacturerId={id}
                            catalogs={catalogs}
                            catalogSelected={catalogSelected}
                            setCatalogSelected={setCatalogSelected}
                            itHasCatalogSelected={itHasCatalogSelected}
                            idCatalogLoaded={idCatalogLoaded}
                            nameCatalogLoaded={nameCatalogLoaded}
                            deposits={deposits}
                            itUsesDeposits={itUsesDeposits}
                            depositSelected={depositSelected}
                            setDepositSelected={setDepositSelected}
                            clientTypes={client_types}
                            itUsesClientTypes={itUsesClientTypes}
                            clientTypeSelected={clientTypeSelected}
                            setClientTypeSelected={setClientTypeSelected}
                            trigger={trigger}
                            depositsFarmaMX={depositsFarmaMX}
                        />

                        <CredentialCardFooter
                            disable={disable}
                            onSubmit={
                                id !== DrugManufacturersId.AlmacenDeDrogas
                                    ? handleSubmit((e) => onSubmit(e, false))
                                    : handleSubmit(onSubmitCatalog)
                            }
                            title={name}
                            nameSelect={nameSelect}
                            format_status={format_status}
                            handleSubmitFormat={handleSubmitFormat}
                            handleFileInputChange={handleFileInputChange}
                            clientIdentifier={drugManufacturersNeedClientIdentifier[id]}
                            stepAddress={stepAddress}
                            deleteComponent={
                                username ? (
                                    <DeleteCredential onClick={handleDelete} />
                                ) : itHasCatalogSelected && idCatalogLoaded && nameCatalogLoaded ? (
                                    <DeleteCredential onClick={handleDelete} />
                                ) : null
                            }
                            onCancel={handleCancel}
                            enabledCatalog={enabledCatalog}
                            drugmanufacturerId={id}
                            {...{ loading, errorMsg }}
                        />
                    </Motion>
                </AnimatePresence>
            </form>
        );
    },
);

export default CredentialCard;

function OnConfirmLimitModal({ modal }) {
    const { palette } = useTheme();

    const handleSubmit = () => {
        modal.toggle();
    };

    return (
        <Modal fullWidth maxWidth="sm" open={modal.visibility} onClose={() => {}}>
            <Modal.Title>
                <span style={{ fontSize: '20px' }}>Límite de droguerías</span>
            </Modal.Title>

            <>
                <Modal.Content style={{ paddingBottom: 8, paddingTop: 0 }}>
                    Llegaste al límite de cinco droguerías conectadas.
                </Modal.Content>
                <Modal.Content style={{ paddingBottom: 12, paddingTop: 0 }}>
                    Desconectá una droguería para poder configurar una nueva.
                </Modal.Content>
                <Modal.Actions style={{ paddingTop: 0 }}>
                    <Button
                        colorName={palette.Ra}
                        variant="contained"
                        style={{ fontWeight: 600 }}
                        onClick={handleSubmit}
                        type="submit"
                    >
                        ENTENDIDO
                    </Button>
                </Modal.Actions>
            </>
        </Modal>
    );
}
