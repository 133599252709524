import { Role } from 'common/types';
import { EX5057, EX5225 } from 'config/flags';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'rsuite';
import { useUser } from 'utils/hooks';
import { TooltipCard } from '../Tooltip';
import { ICardRow } from './CardRow.interface';
import style from './CardRow.module.css';
import useCurrencyFormatter from 'utils/hooks/useCurrencyFormatter/useCurrencyFormatter';

const CardRow = ({ name, value, haveTooltip, icon, text, total_products, products_quantity }: ICardRow) => {
    const { t } = useTranslation();
    const { user } = useUser({});
    const firstRow = name === 'Total';
    const totalProducts =
        total_products > 1
            ? `${total_products} ${EX5225 ? t('myOrders.labelProducts') : 'productos'}`
            : `${total_products} ${EX5225 ? t('myOrders.labelProduct') : 'producto'}`;
    const productsQuantity =
        products_quantity > 1
            ? `${products_quantity}  ${!!EX5057 ? t('myOrders.labelUnits') : 'unidades'} `
            : `${products_quantity} ${!!EX5057 ? t('myOrders.labelUnit') : 'unidad'}`;

    return user?.role_id !== Role.Buyer || name !== 'Crédito por acuerdos' ? (
        <Col xs={24} style={{ padding: '16px 0px 0px 0px' }}>
            <Row>
                <Col xs={24} sm={24} md={12}>
                    <span className={style['first-col']}>
                        <div className={firstRow && style['first-row-title']}>{name}</div>
                        {haveTooltip && <TooltipCard icon={icon} text={text} />}
                    </span>
                    {firstRow && (
                        <div>
                            <span className={style['total_products']}>{totalProducts}</span>

                            {<span className={style['unit_products']}> | {productsQuantity}</span>}
                        </div>
                    )}
                </Col>
                <Col xs={24} sm={24} md={12}>
                    <span
                        className={style['second-col']}
                        style={{
                            color:
                                name === 'Ahorro estimado' || name === 'Crédito por acuerdos' ? '#057634' : '#40435B',
                        }}
                    >
                        {value}
                    </span>
                </Col>
            </Row>
        </Col>
    ) : (
        <></>
    );
};

export default CardRow;
