// rsuite
import { MaskedInput } from 'rsuite';
// style
import style from './FieldWithMasked.module.css'
// interface
import { IFieldWithMasked } from './FieldWithMasked.interface';
// dayjs
import dayjs from 'dayjs';
import 'dayjs/locale/es'; // importar el idioma español
dayjs.locale('es');


const FieldWithMasked = ({ field, state, handlerChangeInput } : IFieldWithMasked) => {
    const options = {
        name: 'Date',
        mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
    };
    return (
        <MaskedInput
            className={style['masked-input']}
            mask={options.mask}
            showMask={dayjs(state.form[field.name]).format('DD/MM/YYYY') ? false : true}
            onChange={(evt) => handlerChangeInput(evt, field.name)}
            defaultValue={dayjs(state.form[field.name]).format('DD/MM/YYYY')}
            name={field.name}
            keepCharPositions
            guide={true}
            placeholderChar='_'
            placeholder='DD/MM/YYYY'
        />
    );
};

export default FieldWithMasked;
