export const SplitIcon = (width = 16, height = 16): JSX.Element => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            fill="none"
            style={{ minWidth: width, minHeight: height }}
        >
            <path
                d="M2.66663 8.66699V7.33366H7.73329L11.0666 4.00033H9.33329V2.66699H13.3333V6.66699H12V4.93366L8.26663 8.66699H2.66663ZM9.33329 13.3337V12.0003H11.0666L8.93329 9.90033L9.89996 8.93366L12 11.067V9.33366H13.3333V13.3337H9.33329Z"
                fill="white"
            />
        </svg>
    );
};
