import { EX6780 } from 'config/flags';
import { isEmptyObjectAtrribute } from 'utils/object';

export const productHasCheckedQuantity = (products, enabledOnOff, isEnabledDrugManufacturer, EX5937) => {
    if (enabledOnOff) {
        return products?.filter(({ products, areAllItemChecked, drugManufacturerIdSelected, quantity, isFatherGeneric }) => {
            const withoutStock = isEmptyObjectAtrribute(products);
            if (Array.isArray(drugManufacturerIdSelected)) {
                const isEnabled = EX5937
                    ? drugManufacturerIdSelected.some((d) => isEnabledDrugManufacturer(d))
                    : isEnabledDrugManufacturer(drugManufacturerIdSelected);
                
                const hasMinMaxProducts = Object.values(products).some(
                    (product: any) => product?.mincant > 0 || product?.maxcant > 0
                );

                if (isFatherGeneric && quantity === 0) return false;

                return !hasMinMaxProducts && areAllItemChecked && !withoutStock && !isEnabled;
            }
        });
    }

    return products?.filter(({ products, areAllItemChecked, quantity, isFatherGeneric }) => {
        const withoutStock = isEmptyObjectAtrribute(products);
        const hasMinMaxChecked = Object.values(products).some(
            (product: any) => product?.mincant > 0 || product?.maxcant > 0
        );

        if (EX6780 ? (isFatherGeneric ? quantity === 0 : false) : isFatherGeneric && quantity === 0) {
            return false;
        }

        return !hasMinMaxChecked && areAllItemChecked && !withoutStock;
    });
};
