import imageMap from './image.json';
export const addImageManufactured = (drug_manufacturer, tag) => {
  const baseUrl = '/assets/images/';

  switch (tag) {
    case 'orders': {
      return drug_manufacturer?.map(({ name, id }) => {
        let nameWithoutBar = name;
        if (name.includes('/')) {
          nameWithoutBar = name.split('/')[0].trim();
        }

        const imageName = imageMap[nameWithoutBar.toLowerCase()];
        const image = imageName ? `${baseUrl}${imageName}` : null;

        return {
          id,
          name,
          image,
        };
      });
    }
    case 'list': {
      if (!drug_manufacturer) {
        return {
          id: '',
          name: '',
          image: '',
        };
      }

      const { name, id } = drug_manufacturer;
   

      let nameWithoutBar = name;
      if (name.includes('/')) {
        nameWithoutBar = name.split('/')[0].trim();
      }

      const imageName = imageMap[nameWithoutBar.toLowerCase()];
      const image = imageName ? `${baseUrl}${imageName}` : null;
      return {
        id,
        name,
        image,
      };
    }

    default:
      null;
      break;
  }
};
