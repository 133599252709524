import { memo } from 'react';
import { t } from 'i18next';
// rsuite
import { Avatar, AvatarGroup, Whisper, Popover } from 'rsuite';
// mui
import { RsuiteTooltip } from '../RsuiteTooltip';
//utils
import stringAvatar from 'utils/avatar-name';
//style
import style from './AvatarsGroups.module.css';
import { IAvatarGroups } from 'utils/interfaces/AvatarGroups';
import { useUser } from 'utils/hooks';
import { COUNTRY } from 'utils/country';
import { EX5057 } from 'config/flags';

const AvatarGroups = ({ max, avatars, tolerance, unassigned, service }: IAvatarGroups) => {
    const { userLocaleCountryCode } = useUser({});

    const getAvatarStyle = (unassigned, service, tolerance, style, index = undefined) => {
        if (unassigned) {
            return service ? style['avatars-unassigned_d'] : style['avatars-unassigned'];
        }
        if (tolerance && index === 0) {
            return service
                ? style['avatars-tolerance_d']
                : avatars[index]
                ? style['avatars-tolerance_firstIdx']
                : style['avatars-tolerance'];
        }

        if (tolerance) {
            return service ? style['avatars-tolerance_d'] : style['avatars-tolerance'];
        }

        return service ? style['avatars_d'] : style['avatars'];
    };

    const getAvatarStyleDropdown = (unassigned, service, tolerance, style, index = undefined) => {
        if (unassigned) {
            return service ? style['avatars-unassigned-n-droguerias_d'] : style['avatars-unassigned-n-droguerias'];
        }

        if (tolerance) {
            return service ? style['avatars-tolerance_d'] : style['avatars-tolerance'];
        }

        return service ? style['avatars_d'] : style['avatars'];
    };

    const isOnlyTolerance = () => {
        return tolerance === true && service === false;
    };

    return (
        <AvatarGroup stack>
            {avatars
                ?.filter((drugManufacturer, i) => i < max)
                .map((drugManufacturer, i) => (
                    <RsuiteTooltip placement="bottom" trigger="hover" text={drugManufacturer} key={drugManufacturer}>
                        <Avatar
                            size="md"
                            className={
                                !isOnlyTolerance()
                                    ? getAvatarStyle(unassigned, service, tolerance, style)
                                    : getAvatarStyle(unassigned, service, tolerance, style, i)
                            }
                            circle
                            key={drugManufacturer}
                            alt={drugManufacturer}
                        >
                            {stringAvatar(drugManufacturer, service, 0, drugManufacturer?.offline)}
                        </Avatar>
                    </RsuiteTooltip>
                ))}
            {avatars?.length > max && (
                <Whisper
                    placement="bottom"
                    speaker={
                        <Popover>
                            {avatars
                                .filter((drugManufacturer, i) => i >= max)
                                .map((drugManufacturer, index) => {
                                    return (
                                        <>
                                            <div className={style['container-n-avatars']}>
                                                <Avatar
                                                    circle
                                                    className={getAvatarStyleDropdown(
                                                        unassigned,
                                                        service,
                                                        tolerance,
                                                        style,
                                                    )}
                                                    size="md"
                                                >
                                                    {stringAvatar(
                                                        drugManufacturer,
                                                        service,
                                                        0,
                                                        drugManufacturer?.offline,
                                                    )}
                                                </Avatar>
                                                <p className={style['text-avatar-n-droguerias']}>{drugManufacturer}</p>
                                            </div>
                                            <hr className={style['line-divider']} />
                                        </>
                                    );
                                })}
                        </Popover>
                    }
                    //open={true}
                >
                    <Avatar
                        circle
                        size="md"
                        className={service ? style['avatar-component-all_d'] : style['avatar-component-all']}
                    >
                        {t('efficientPurchasing.avatares.quantity', {
                            avatarQuantity: `+ ${avatars.slice(max).length}`,
                        }) +
                            `${
                                avatars.slice(max).length > 1
                                    ? EX5057 && userLocaleCountryCode === COUNTRY.AR
                                        ? 's'
                                        : 'es'
                                    : ''
                            }`}
                    </Avatar>
                </Whisper>
            )}
        </AvatarGroup>
    );
};

export default memo(AvatarGroups);
