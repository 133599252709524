// next
import { useRouter } from 'next/router';
import { t } from 'i18next';
// routes
import { itemsLinks } from '../routes/links';
// handler
import { handlerLink } from './handlers/handler.routing';
// rsuite
import { Button, Nav, Sidenav, Tooltip, Whisper } from 'rsuite';
// hooks
import { useUser } from 'utils/hooks';
// context
import { NewUseCart, useAlert, useCreateOrder, usePointOfSale, useSnackbar } from 'context';
// style
import { EX4378, EX5057, EX5225 } from 'config/flags';
import { useDragAndDrop } from 'context/dragAndDropContext';
import { useNewSearchProduct } from 'context/openSearch/newSearchDataContext';
import { useEffect, useState } from 'react';
import style from './BodySidebar.module.css';
import { isZettiFormat } from 'utils/pointOfSale';
import { sendMarkTooltip } from 'api/analytics/analytics';

const BodySidebar = () => {
    // router
    const router = useRouter();
    // hooks & context
    const { user, mutateUser } = useUser({});
    const { pointOfSale } = usePointOfSale();
    const [links, setLinks] = useState([]);
    // reset visibility on switch routes while progress bar is active
    const { handleResetVisibility } = NewUseCart();
    const { handleOpen } = useNewSearchProduct();
    const { setVisibleDragDrop } = useDragAndDrop();
    const { showLoadingScreen, handleSwitchViewsCancelOrder } = useCreateOrder();
    const snackbar = useSnackbar();
    const { show } = useAlert();

    const [showOnboardingModal, setShowOnboardingModal] = useState(false);

    useEffect(() => {
        setLinks(itemsLinks(t));
    }, [itemsLinks, user]);

    // handler for the link click if the flag will use the previous logic code
    const handleButtonClick = (href) => {
        !EX4378
            ? (handleResetVisibility(), handleOpen(false), handlerLink(href, router, user, pointOfSale))
            : showLoadingScreen
            ? show({
                  className: '',
                  title: EX5225
                      ? t('efficientPurchasing.fileUpload.efficientPurchasing_fileUploadTwentyEight')
                      : 'Descartar',
                  cancel: true,
                  size: 'xs',
                  body: EX5225
                      ? t('efficientPurchasing.fileUpload.efficientPurchasing_fileUploadTwentyNine')
                      : 'Se descartará la obtención de datos.',
                  action: EX5057 ? t('efficientPurchasing.comparative.labelConfirmDiscard') : 'Si, descartar',
                  onConfirm: () => {
                      handlerLink(href, router, user, pointOfSale);
                      if (!isZettiFormat(pointOfSale)) {
                          setVisibleDragDrop(false);
                      }

                      handleOpen(false);
                      handleResetVisibility();
                      setTimeout(() => {
                          handleSwitchViewsCancelOrder();
                          snackbar.show({
                              message: EX5225 ? t('snackbar.labelDiscardOrder') : 'Se descartó la compra.',
                          });
                      }, 100);
                  },
                  onCancel: () => {},
              })
            : (handleResetVisibility(), handleOpen(false), handlerLink(href, router, user, pointOfSale));
    };

    const LOCAL_STORAGE_KEY = 'onboardingCompleted';

    // Verificar si se debe mostrar el OnBoarding
    const checkOnboardingStatus = () => {
        if (user?.tooltip_read === 0) {
            setShowOnboardingModal(true);
            localStorage.setItem(LOCAL_STORAGE_KEY, 'false'); // Forzar no completado
        } else {
            const onboardingCompleted = localStorage.getItem(LOCAL_STORAGE_KEY) === 'true';
            setShowOnboardingModal(!onboardingCompleted && user?.tooltip_read === 0);
        }
    };

    // Manejar clic en "Entendido"
    const handlePopoverClick = async () => {
        try {
            await sendMarkTooltip(user?.id); // Marcar como leído en el backend
            localStorage.setItem(LOCAL_STORAGE_KEY, 'true'); // Marcar como completado en localStorage
            setShowOnboardingModal(false); // Ocultar modal
            mutateUser();
        } catch (error) {
            console.error('Error al manejar el popover:', error);
        }
    };

    // Evaluar el estado del OnBoarding al cargar o cambiar rutas
    useEffect(() => {
        checkOnboardingStatus();
    }, [user]);

    useEffect(() => {
        const handleRouteChange = () => {
            checkOnboardingStatus();
        };

        router.events.on('routeChangeComplete', handleRouteChange);
        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, [router]);

    return (
        <Sidenav.Body style={{ position: 'relative' }}>
            {user &&
                links.map(({ label, icon, hover, href }) => {
                    const isCurrentPath = router.pathname === href;

                    return (
                        <Nav
                            key={`bodysidebar_${label}`}
                            className={label === 'Reporte de compras' ? 'icon__dashboard' : ''}
                        >
                            <div className={style['wrapper-items-links']}>
                                <div className={isCurrentPath ? style['border-line'] : ''}>
                                    <Whisper
                                        trigger={'hover'}
                                        speaker={
                                            <Tooltip className={style['tooltip-component']} placement="right">
                                                {label}
                                            </Tooltip>
                                        }
                                    >
                                        <Button
                                            style={{
                                                height: 56,
                                                width: '100%',
                                                backgroundColor: 'transparent',
                                                color: '#fff',
                                            }}
                                            active={isCurrentPath}
                                            onClick={() => handleButtonClick(href)}
                                        >
                                            <span className={label === 'Reporte de compras' ? 'icon__dashboard' : ''}>
                                                {isCurrentPath ? hover : icon}
                                            </span>
                                        </Button>
                                    </Whisper>
                                    {/* {label === 'Reporte de compras' &&
                                        (!user?.chip_is_expired || user?.chip_is_expired === null) && (
                                            <Badge
                                                className={style['analytics-tags']}
                                                content={t('analytics.onboarding.badge')}
                                            />
                                        )} */}
                                </div>
                            </div>
                        </Nav>
                    );
                })}
            {/* {showOnboardingModal && (
                <OnBoarding handlePopoverClick={handlePopoverClick} hidden={user?.tooltip_read === 1} />
            )} */}
        </Sidenav.Body>
    );
};

export default BodySidebar;
