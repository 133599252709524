import { useEffect } from 'react';
import { t } from 'i18next';
// style
import { EX3918 } from 'config/flags';
import { useUser } from 'utils/hooks';
import style from './SuccessLabelCredentialCards.module.css';

const SuccessLabelCredentialCards = ({ handlerUpdateEfficientPurchase }) => {
    const { user } = useUser({});
    const EX5990 = user?.EX5990;

    useEffect(() => {
        if (true) {
            setTimeout(() => {
                handlerUpdateEfficientPurchase();
            }, 1500);
        }
    }, []);

    return (
        <div className={style['container-success']}>
            <div className={style['container-image']}>
                <img src="/assets/images/success.svg" />
            </div>
            <div>
                <p className={style['label-text-congrats']}>
                    {EX5990
                        ? t('efficientPurchasing.credentialsError.labelCongratsUpdateCredentials')
                        : '¡Felicitaciones!'}
                </p>
                <p className={style['subtext-update']}>
                    {!EX3918
                        ? 'Actualizaste todas las credenciales de las droguerías.'
                        : t('efficientPurchasing.credentialsError.efficientPurchasing_credentialsErrorThree')}
                </p>
            </div>
        </div>
    );
};

export default SuccessLabelCredentialCards;
