import { OfferCenters } from 'utils/enums';
import { DrugManufacturersId } from 'utils/interfaces';

export const isLowest = (lowest_without_cdo, lowest, drugManufacturerId, cdoStatusByPOS): boolean => {
    if (cdoStatusByPOS !== OfferCenters.Enabled) {
        if (lowest_without_cdo?.includes(drugManufacturerId)) {
            return true;
        } else {
            return false;
        }
    } else {
        if (lowest?.includes(drugManufacturerId)) {
            return true;
        } else {
            return false;
        }
    }
};

export const isLowestCdo = (lowest): boolean => {
    if (lowest?.includes(DrugManufacturersId.CentralDeOfertas)) {
        return true;
    } else {
        return false;
    }
};
