// react
import { memo, useEffect, useMemo, useState } from 'react';
// styles
import styles from './CellDrugManufacturerCdo.module.css';
// components
import { DefaultWithoutError } from '../TableHeaderErrors';
import { TableHeadDropdownMenu } from '../TableHeadDropdownMenu';
// utils
import { addEllipsis } from 'utils/strings/ellipsis.utils';
// contexts
import { useCredentialErrors } from 'context/CredentialsErrors/CredentialsErrors';
import { checkErrors } from '../utils';
import { useProductComparison } from 'context/product-comparison.context';
import { DrugManufacturersId } from 'utils/interfaces';
import { useCDOStatus } from '@commons/modules/TableProductComparisonV2/hooks';
import { useDrugManufacturersContext, useFetchingContext } from 'context';
import { useRouter } from 'next/router';
import { cellEvents } from 'utils/cellEvents';

const CellDrugManufacturerCdo = ({ item, parentHeaderRef, fetchProductGroup }) => {
    // const { loading } = useProductComparison();
    const { loading } = useFetchingContext();

    const { drugManufacturer, isEnabledDrugManufacturer } = useDrugManufacturersContext();

    const { CDO_IS_PENDING_BY_POS, CDO_IS_DEFAULT_BY_POS } = useCDOStatus();

    const CDO_DRUGMANUFACTURER = useMemo(() => {
        return drugManufacturer?.find((d: any) => d?.id === DrugManufacturersId.CentralDeOfertas);
    }, [drugManufacturer]);

    const isEnabledCdo = useMemo(() => {
        return !!CDO_DRUGMANUFACTURER ? isEnabledDrugManufacturer(CDO_DRUGMANUFACTURER.id) : false;
    }, [CDO_DRUGMANUFACTURER]);

    // logic
    const truncateText: any = addEllipsis(CDO_DRUGMANUFACTURER.name);

    const [selectAll, setSelectAll] = useState(false);

    const { query } = useRouter();

    const { extractedDataError, isFetchingUpdate } = useCredentialErrors();
    // states
    const [errorsTypes, setErrorsTypes] = useState({
        unknown: false,
    });

    const event = cellEvents(
        'rest',
        item,
        selectAll,
        setSelectAll,
        isEnabledCdo && !errorsTypes?.unknown,
        item.id,
        query,
        fetchProductGroup,
    );

    useEffect(() => {
        const errors = checkErrors(extractedDataError, CDO_DRUGMANUFACTURER.id);

        setErrorsTypes({
            unknown: errors.hasUnknownError,
        });
    }, [extractedDataError, CDO_DRUGMANUFACTURER.id, isFetchingUpdate]);

    return (
        <div
            className={styles['cell']}
            style={{ backgroundColor: !isEnabledCdo || errorsTypes?.unknown ? '#F5F6FA' : '#0056b0' }}
        >
            <DefaultWithoutError
                name={CDO_DRUGMANUFACTURER?.name}
                textTooltip={CDO_DRUGMANUFACTURER?.name}
                disabled={!truncateText.ellipsis}
                flag="cdo"
                isEnabled={isEnabledCdo && !errorsTypes?.unknown}
                delivery={item?.delivery}
                offline={item?.offline}
            />
            <TableHeadDropdownMenu
                isEnabled={isEnabledCdo && !errorsTypes?.unknown}
                item={CDO_DRUGMANUFACTURER}
                flag="cdo"
                isActive={CDO_IS_PENDING_BY_POS}
                isDefault={CDO_IS_DEFAULT_BY_POS}
                parentHeaderRef={parentHeaderRef}
                loading={loading}
                event={event}
                selectAll={selectAll}
                setSelectAll={setSelectAll}
                fetchProductGroup={fetchProductGroup}
            />
        </div>
    );
};

export default memo(CellDrugManufacturerCdo);
