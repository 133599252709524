// react
import { useEffect, useState } from 'react';
import { t } from 'i18next';
// rsuite
import UnvisibleIcon from '@rsuite/icons/Unvisible';
import VisibleIcon from '@rsuite/icons/Visible';
import { Button, ButtonToolbar, Col, Divider, Form, IconButton, Panel, Row, SelectPicker } from 'rsuite';
// mui
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// style
import style from './PanelCredentialCardDrawer.module.css';
// helpers
import { EX3918, EX4770, EX5057 } from 'config/flags';
import { handleSwitch } from '@commons/modules/InitialConfig/PointOfSales/NewCredentialCard/NewCredentialCards.helpers';
import {
    commonsAttributes,
    commonsAttributesWithClientIdentifier,
    commonsAttributesWithClientFunction,
} from '../utils/formAttributes';

const PanelCredentialCardDrawer = ({
    hasMoreOfTwoCredentials,
    drugManufacturerName,
    credentialTypes,
    isExpanded,
    setIsExpanded,
    defaultExpanded,
    eventKey,
    handlerSubmitForm,
    handlerForm,
    isSuccessCredential,
    form,
    setForm,
    selector,
    setSelector,
    hasErrors,
    setHasErrors,
    handlerKeyPress,
    isLoading,
    haveId,
    setPersistForm,
    persistForm,
    extractedDataError,
    extractedCredentialErrors,
    //formulationAttributes,
    step,
    addresses,
    handlerOnChangeAddress,
    hasAddressesSelected,
    useAddress,
    useClientIdentifier,
    url,
}) => {
    // state
    const [showPassword, setShowPassword] = useState(false);

    const changeSelectorType = (name, val) => {
        setSelector({ type: name, value: val });
        const selectedDrug = extractedCredentialErrors.find(
            (drug) => drug.id === haveId && drug.name === drugManufacturerName && !!isExpanded,
        );

        if (!!selectedDrug) {
            const credentials = selectedDrug?.credential_types || [];
            const allCredentials = credentials?.find((item) => item.id === selector.value);

            setPersistForm({
                username: allCredentials?.username || '',
                password: allCredentials?.password || '',
                apiCredentialId: allCredentials?.api_credential_id,
                id: selectedDrug?.id,
                nameType: allCredentials?.name,
                client_identifier: allCredentials?.client_identifier || '',
                function: allCredentials?.function || null,
            });
        }
    };
    const err = credentialTypes?.filter((elem) => elem.errors.length > 0);
    const nameType = handleSwitch(err[0]?.name, credentialTypes);
    useEffect(() => {
        setSelector({ type: nameType, value: err[0]?.id });
    }, [isExpanded]);

    useEffect(() => {
        if (!!isExpanded) {
            changeSelectorType(selector.type, selector.value);
            setHasErrors({
                id: eventKey,
                bool: true,
            });
        }
    }, [selector.value, eventKey, isExpanded]);

    useEffect(() => {
        if (!!defaultExpanded || !isExpanded) {
            setIsExpanded(true);
        }
    }, []);
    const addressesData = addresses?.map(({ label, id }) => {
        return {
            label: label,
            value: id,
        };
    });

    const elemsWithCredentials = credentialTypes.filter((item) => item.api_credential_id !== null);

    const credentialsQuantity = elemsWithCredentials.length;

    const [data] = elemsWithCredentials;

    const nameTypes = handleSwitch(data.name, credentialTypes);

    const drugManufacturerUrl = new URL(url);
    const formatedUrl = drugManufacturerUrl?.hostname?.toLowerCase() || '';
    const WEBACTUAL = 'www.delsud.com.ar';
    const inputsForm =
        formatedUrl === WEBACTUAL
            ? commonsAttributesWithClientFunction
            : useClientIdentifier
            ? commonsAttributesWithClientIdentifier
            : commonsAttributes;
    return (
        <div className={style['container-panel']}>
            <Panel
                className={
                    !!isSuccessCredential.bool && isSuccessCredential.credId.includes(eventKey)
                        ? style['panel-wrapper-success']
                        : style['panel-wrapper']
                }
                collapsible
                expanded={isExpanded}
                defaultExpanded={defaultExpanded}
                eventKey={eventKey}
                header={
                    <div className={style['container-header']}>
                        <div className={style['title-content']}>
                            {!!isSuccessCredential.bool && isSuccessCredential.credId.includes(eventKey) && (
                                <img src="/assets/images/check_circle.svg" />
                            )}
                            <p className={style['title-head-panel']}>{drugManufacturerName}</p>
                        </div>

                        <IconButton
                            className={
                                !!isSuccessCredential.bool && isSuccessCredential.credId.includes(eventKey)
                                    ? style['icon-button-content-success']
                                    : style['icon-button-content']
                            }
                            disabled={
                                !!isSuccessCredential.bool && isSuccessCredential.credId.includes(eventKey)
                                    ? true
                                    : false
                            }
                            icon={
                                !isExpanded ? (
                                    <ExpandMoreIcon
                                        style={{
                                            color:
                                                !!isSuccessCredential.bool &&
                                                isSuccessCredential.credId.includes(eventKey)
                                                    ? '#ACAEC4'
                                                    : '#40435B',
                                        }}
                                    />
                                ) : (
                                    <ExpandLessIcon
                                        style={{
                                            color:
                                                !!isSuccessCredential.bool &&
                                                isSuccessCredential.credId.includes(eventKey)
                                                    ? '#ACAEC4'
                                                    : '#40435B',
                                        }}
                                    />
                                )
                            }
                            onClick={() => setIsExpanded(!isExpanded)}
                        />
                    </div>
                }
            >
                <div className={style['container-card-body']}>
                    <p className={style['title-body-card']}>
                        {!EX3918
                            ? 'Para poder comprar, conectá al menos una credencial.'
                            : t('efficientPurchasing.credentialsError.efficientPurchasing_credentialsErrorFour')}
                    </p>
                    {credentialsQuantity === 1 && !!EX4770 ? (
                        <>
                            <p className={style['withOneCredential']}>
                                <span className={style['type-withOneCredential']}>{nameTypes}</span>
                                <span className={style['email-withOneCredential']}>|</span>
                                <img src="/assets/images/lockFill.svg" alt="icon-lock" />
                                <span className={style['email-withOneCredential']}>{formatedUrl}</span>
                            </p>
                            <hr className={style['liner-withOneCredential']} />
                        </>
                    ) : (
                        <div className={style['container-button-actions']}>
                            <Row className={style['row-buttons-action']}>
                                {credentialTypes.map(({ name, id }) => {
                                    const nameType = handleSwitch(name, credentialTypes);
                                    return (
                                        nameType !== 'api' && (
                                            <Col xs={24} sm={12}>
                                                <div key={id} className={style['wrapper-button']}>
                                                    <Button
                                                        active={id === selector.value}
                                                        className={style['button-action']}
                                                        onClick={() => changeSelectorType(nameType, id)}
                                                        style={{
                                                            borderRadius: id === selector.value ? '8px' : '',
                                                            border:
                                                                id === selector.value
                                                                    ? '0.5px solid var(--primary-600, #0171e6)'
                                                                    : '',

                                                            background: '#fff',
                                                        }}
                                                    >
                                                        {nameType}
                                                    </Button>
                                                </div>
                                            </Col>
                                        )
                                    );
                                })}
                            </Row>
                        </div>
                    )}

                    <div className={style['container-form']}>
                        <Form
                            className={style['form-content']}
                            onChange={handlerForm}
                            fluid
                            onKeyPress={handlerKeyPress}
                        >
                            {step === 2 && useAddress ? (
                                <>
                                    <div>
                                        <SelectPicker
                                            searchable={false}
                                            data={addressesData}
                                            style={{ width: '100%' }}
                                            placeholder="Selecciona una dirección de envío"
                                            onChange={(evt) => handlerOnChangeAddress(evt)}
                                        />
                                    </div>
                                </>
                            ) : (
                                inputsForm?.map(({ value, label }, idx) => {
                                    let type =
                                        value === 'username' || value === 'client_identifier'
                                            ? 'text'
                                            : showPassword
                                            ? 'text'
                                            : 'password';

                                    return (
                                        <>
                                            <Form.Group controlId={value} key={idx}>
                                                <Form.ControlLabel>{label}</Form.ControlLabel>
                                                <div className={style['container-form-control']}>
                                                    <Form.Control
                                                        name={value}
                                                        className={style['form-control']}
                                                        size="lg"
                                                        type={type}
                                                        style={{
                                                            border:
                                                                hasErrors.id === eventKey &&
                                                                !!hasErrors.bool &&
                                                                persistForm?.username?.length !== 0 &&
                                                                persistForm?.password?.length !== 0
                                                                    ? '1px solid var(--Semantic-error-600, #E22E2E)'
                                                                    : '',
                                                        }}
                                                        value={persistForm[value]}
                                                        autoComplete="off"
                                                        onChange={(evt) =>
                                                            setPersistForm({
                                                                ...persistForm,
                                                                [value]: evt,
                                                            })
                                                        }
                                                    />
                                                    {value === 'password' && (
                                                        <div className={style['icon-eye']}>
                                                            <IconButton
                                                                className={style['icon-button-eye']}
                                                                icon={
                                                                    showPassword ? <VisibleIcon /> : <UnvisibleIcon />
                                                                }
                                                                onClick={() => setShowPassword(!showPassword)}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </Form.Group>

                                            {value === 'client_identifier' &&
                                                step === 1 &&
                                                hasErrors.id === eventKey &&
                                                !!hasErrors.bool &&
                                                persistForm?.username?.length !== 0 &&
                                                persistForm?.password?.length !== 0 && (
                                                    <div className={style['error-container']}>
                                                        <img src="/assets/images/error_FILL.svg" />
                                                        <p>
                                                            {EX5057
                                                                ? t(
                                                                      'efficientPurchasing.credentialsError.panelErrorUserOrPassword',
                                                                  )
                                                                : 'Usuario y/o contraseña incorrectos'}
                                                        </p>
                                                    </div>
                                                )}
                                        </>
                                    );
                                })
                            )}
                        </Form>
                    </div>

                    {!!hasMoreOfTwoCredentials && (
                        <>
                            <Divider />

                            <div className={style['container-footer']}>
                                <ButtonToolbar>
                                    <Button
                                        className={style['button-omit-action']}
                                        onClick={() => setIsExpanded(!isExpanded)}
                                    >
                                        Omitir
                                    </Button>
                                    <Button
                                        className={`button__save-credential-3 ${style['button-action-save']}`}
                                        onClick={() => handlerSubmitForm()}
                                        type="submit"
                                        loading={isLoading}
                                    >
                                        <span className="button__save-credential-3">
                                            {step === 1 && !useAddress
                                                ? EX5057
                                                    ? t(
                                                          'efficientPurchasing.priorityAndTolerance.efficientPurchasing_priorityAndToleranceFifteen',
                                                      )
                                                    : 'Guardar'
                                                : t(
                                                      'efficientPurchasing.priorityAndTolerance.efficientPurchasing_priorityAndToleranceNext',
                                                  ) || 'Continuar'}
                                        </span>
                                    </Button>
                                </ButtonToolbar>
                            </div>
                        </>
                    )}
                </div>
            </Panel>
        </div>
    );
};

export default PanelCredentialCardDrawer;
