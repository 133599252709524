import React from 'react';
import style from './FirstLogin.module.css';
import { useTranslation } from 'react-i18next';
import { useUser } from 'utils/hooks';
import useChangeLanguage from 'utils/hooks/use-change-language';

function FirstLogin({ index, step }) {
    const { t } = useTranslation();
    const { user } = useUser({});
    const userLocaleCountryCode = user?.client?.country_alfa_2;
    useChangeLanguage(userLocaleCountryCode);

    return (
        <div className={`dropdown__select-pos-1 ${style['container']}`}>
            <img src={'/assets/images/storefront.svg'} alt="IsologoHorizontal" className={style['icon']} />
            <p className={`dropdown__select-pos-1 ${style['title']}`}>{t('shoppingCart.walkthrough.shoppingCart_walkthrough_Twelve')}</p>
        </div>
    );
}

export default FirstLogin;
