import { useEffect, useState } from 'react';
// import { useRouter } from 'next/router';
// contexts
import { usePrioritiesPercentagesCeContext } from 'context/PrioritiesPercentagesCe/PrioritiesPercentagesCe-context';
// import { useProductComparison } from 'context/product-comparison.context';
// apis
// import { getPercentageOfToleranceOrderID } from 'api';
// types
import { ServiceId } from 'common/types';
import { EX6676 } from 'config/flags';
import { useDrugManufacturersContext, useFetchingContext } from 'context';

export const DistributionConfig = () => {
    //router
    // const router = useRouter();
    //context
    // const { orderId } = router.query;
    // const { 
    //     // drugManufacturer, 
    //     loading } = useProductComparison();
    const { loading } = useFetchingContext();
    const { drugManufacturer } = useDrugManufacturersContext();
    //hooks
    const [percentage, setPercentages] = useState([]);
    const [distribution, setDistribution] = useState([]);
    const [unassigned, setUnassigned] = useState(false);
    // new context by prioritys and percentages
    const { data, serviceId } = usePrioritiesPercentagesCeContext();
    const [changeAvatares, setChangeAvatares] = useState<boolean>(false);
    const DEPENDENCIES = EX6676 ? [data, loading, serviceId, drugManufacturer] : [data, loading];

    // new effect
    useEffect(() => {
        if (EX6676) {
            if (drugManufacturer?.length && !loading && serviceId === ServiceId.ByPercentage) {
                handleGetDataDistribution();
            }
        } else {
            if (serviceId === ServiceId.ByPercentage) {
                handleGetDataDistribution();
            }
        }
    }, DEPENDENCIES);

    const handleGetDataDistribution = async () => {
        if (Object?.keys(data)?.length === 0) {
            setDistribution([]);
        } else {
            const responseDistribution = data?.percentages_distributed;
            const withOutDistribution = data?.without_distribution;
            setDistribution(responseDistribution);
            handleWithOutDistribution(withOutDistribution, responseDistribution);
        }
    };

    const handleWithOutDistribution = (withOutDistribution, responseDistribution) => {
        if (withOutDistribution?.length > 1) {
            setUnassigned(true);
        } else if (withOutDistribution?.length > 0) {
            const moreThanOneCeroPercentage = responseDistribution?.map((item) => item?.percentage === 0);
            if (moreThanOneCeroPercentage) {
                setUnassigned(true);
            }
        } else {
            setUnassigned(false);
        }
        withOutDistribution?.map((item) =>
            responseDistribution?.push({ percentage: 0, drug_manufacturer_id: item?.id, name: item?.name }),
        );
        if (EX6676) {
            setChangeAvatares(true);
        } else {
            handleAvataresDistribution();
        }
    };

    useEffect(() => {
        if (EX6676 && changeAvatares) {
            handleAvataresDistribution();
        }
    }, [changeAvatares]);

    const handleAvataresDistribution = () => {
        let auxList = [];

        distribution.forEach(function (item) {
            const found = drugManufacturer?.find((element) => element.id === item.drug_manufacturer_id);
            if (found) {
                auxList.push(`${found?.name} ${item.percentage ? item.percentage : 0}%`);
            }
        });
        if (EX6676) {
            setChangeAvatares(false);
        }
        setPercentages(auxList);
    };

    return { percentage, unassigned_d: unassigned };
};
