import { useEffect, useRef, useState } from 'react';
import { t } from 'i18next';
// next
import { useRouter } from 'next/router';
// component
import { RenderMenuItemSelector } from '.';
// interface
import { IPharmacySelector } from './PharmacySelector.interface';
// context
import { useStatusContext } from 'context/control-state';
// rsuite
import { SelectPicker } from 'rsuite';
// style
import style from './PharmacySelector.module.css';
// mui v5
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import { useUser } from 'utils/hooks';

const PharmacySelector = ({
    recordset,
    handlerSelectedValue,
    value,
    defaultValue,
    placeholder,
    userValues,
    itemSelected,
    disabledPosSelector,
    disabled,
    isDisabledSelector,
}: IPharmacySelector) => {
    const { user } = useUser({});
    // next
    const router = useRouter();
    //context
    const { counterStep, setHideSuggestionPDV } = useStatusContext();
    const [haveReference, setHaveReference] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const ref = useRef(null);
    const selectRef = useRef(null);

    useEffect(() => {
        const handleOutSideClick = (event) => {
            if (!ref.current?.contains(event.target)) {
                if (
                    !!event.target.classList.contains('rs-picker-search-bar-input') ||
                    !!event.target.classList.contains('rs-picker-search-bar-search-icon')
                ) {
                    setIsOpen(true);
                } else if (
                    !!event.target.closest(`[class^='RenderMenuItemSelector_container']`) ||
                    !!event.target.closest(`[class*='RenderMenuItemSelector_disabled-item']`) ||
                    !!event.target.closest(`[class*='RenderMenuItemSelector_button-content']`) ||
                    !!event.target.closest(`[class^='RenderMenuItemSelector_title-render-item']`) ||
                    !!event.target.closest(`[class^='RenderMenuItemSelector_text-address']`) ||
                    !!event.target.closest(`[class^='RenderMenuItemSelector_container-button']`) ||
                    !!event.target.closest(`[class*='RenderMenuItemSelector_']`)
                ) {
                    setTimeout(() => {
                        setIsOpen(false);
                    }, 500);
                } else {
                    setIsOpen(false);
                }
            }
        };

        window.addEventListener('mousedown', handleOutSideClick);

        return () => {
            window.removeEventListener('mousedown', handleOutSideClick);
        };
    }, [selectRef]);

    const handlerOnBoarding = () => {
        setHideSuggestionPDV(true);
        setTimeout(() => {
            setIsOpen(true);
        }, 100);
    };

    const disabledItem = recordset?.filter((elem) => elem.availableDrugManufacturers <= 2)?.map((elem) => elem.value);

    const sortedItems = recordset?.sort(
        (a: any, b: any) => +(a.availableDrugManufacturers <= b.availableDrugManufacturers),
    );

    const isEqual = disabledItem?.includes(itemSelected) || disabledItem?.includes(+router?.query?.posId);

    const handleReferenceName = (item) => {
        if (item?.reference_name) {
            setHaveReference(true);
        } else {
            setHaveReference(false);
        }
        if (!item)
            return (
                <div className="dropdown__select-pos-1" style={{ minHeight: 20, maxHeight: 32 }}>
                    <p style={{ color: '#40435b', fontSize: 14 }} className="dropdown__select-pos-1">
                        {t('shoppingCart.walkthrough.shoppingCart_walkthrough_Eleven')}
                    </p>
                </div>
            );
        return (
            <div style={{ minHeight: 20, maxHeight: 32 }}>
                {item?.reference_name && <p className={style['reference']}>{item?.name}</p>}
                <p
                    className={`${item?.reference_name ? style['name_pdv'] : style['name_pdv_noReference']} ${
                        isDisabledSelector ? style['disabled-name'] : ''
                    }`}
                >
                    {item?.reference_name ? item?.reference_name : item?.name}
                </p>
            </div>
        );
    };
    const seeSelectedValue = (e) => {
        const value = sortedItems.find((item) => item.id === e);
        const sortedItemsFilter = sortedItems.filter((item) => item.id !== e);
        sortedItemsFilter.unshift(value);
        //setFilterItems(sortedItemsFilter)
        handlerSelectedValue(e);
    };

    // Get the div element by its class
    const getDivLabelSelectPicker = typeof window !== 'undefined' ? document?.querySelector('.rs-picker-toggle') : null;

    // Retrieve the value of the aria-owns attribute as it is the only way to identify it
    const ariaOwnsValue = getDivLabelSelectPicker?.getAttribute('aria-owns');

    // Validate identifier and if has reference
    if (ariaOwnsValue === 'step-5-listbox' && haveReference) {
        // Add class 'wrapper-pos-reference'
        getDivLabelSelectPicker?.setAttribute('id', 'wrapper-pos-reference');
    } else {
        // Remove class 'wrapper-pos-reference'
        getDivLabelSelectPicker?.removeAttribute('id');
    }

    const showCartBadge = (): boolean => {
        if (
            router.pathname === '/[clientId]/efficient-purchase/[posId]/product-comparison' ||
            router.pathname === '/[clientId]/efficient-purchase/[posId]/confirmation' ||
            router.pathname === '/[clientId]/efficient-purchase/[posId]/order-sent'
        ) {
            return false;
        } else {
            return true;
        }
    };

    // hide on boarding when the user click on the selector PDV
    useEffect(() => {
        if (router.pathname === '/[clientId]/efficient-purchase' && !isOpen) {
            setHideSuggestionPDV(false);
        }
    }, [isOpen, router]);

    return (
        <div className={style['container-selecter-picker']}>
            {!disabledPosSelector && (
                <>
                    <div className={style['wrapper-select-picker']} id={'step-7'} onClick={handlerOnBoarding}>
                        {counterStep === '#step-4' && <div className={style['container-blur']}></div>}
                        <div
                            className={
                                haveReference
                                    ? `dropdown__select-pos-1 ${style['reference_svg']}`
                                    : 'dropdown__select-pos-1'
                            }
                        >
                            <SelectPicker
                                size="lg"
                                ref={selectRef}
                                data={sortedItems ? sortedItems : []}
                                // disabled={disabled}
                                value={value ? parseInt(value) : 0}
                                searchBy={(keyword, label, item) => {
                                    return (
                                        item?.name?.toLowerCase().includes(keyword.toLowerCase()) ||
                                        item?.reference_name?.toLowerCase().includes(keyword.toLowerCase())
                                    );
                                }}
                                defaultValue={defaultValue ? parseInt(defaultValue) : 0}
                                onChange={(e) => {
                                    seeSelectedValue(e);
                                }}
                                style={{
                                    opacity: isDisabledSelector ? 1 : 'none',
                                }}
                                placeholder={placeholder}
                                className={`dropdown__select-pos-1 ${
                                    isOpen ? style['select-picker-border'] : style['select-picker']
                                } ${isDisabledSelector ? style['disabled-picker'] : ''}`}
                                searchable={true}
                                open={isDisabledSelector ? false : isOpen}
                                cleanable={false}
                                caretAs={() => (
                                    <ExpandMoreOutlinedIcon
                                        id={haveReference ? 'wrapper-icon-pos-reference' : 'none'}
                                        className={
                                            haveReference
                                                ? `dropdown__select-pos-1 ${style['wrapper-icon-pos-reference']} `
                                                : 'dropdown__select-pos-1'
                                        }
                                        style={{
                                            color: isDisabledSelector ? '#84C0FE' : '#fff',
                                        }}
                                    />
                                )}
                                //disabledItemValues={disabledItem}
                                renderMenuItem={(label, item) => {
                                    if (item) {
                                        return (
                                            <RenderMenuItemSelector
                                                selectValue={value}
                                                sortedItems={sortedItems}
                                                values={item}
                                                userValues={userValues}
                                            />
                                        );
                                    }
                                }}
                                renderValue={(value, item) => {
                                    if (item) {
                                        return handleReferenceName(item);
                                    }
                                }}
                                menuClassName={!disabledItem ? style['disabled'] : style['menu']}
                                placement={'bottomEnd'}
                            />
                        </div>
                        <div className={style['wrapper-icon-select']}>
                            <StorefrontOutlinedIcon
                                className={
                                    !showCartBadge() || !!isEqual
                                        ? style['store-icon-front-disabled']
                                        : style['store-icon-front']
                                }
                            />
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default PharmacySelector;
