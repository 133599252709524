import React from 'react';
// components
import { Placeholder } from 'rsuite';
// styles
import styles from '../HeaderBox.module.css';

const SkeletonHeader = () => {
    return (
        <>
            <div>
                <Placeholder.Paragraph className={styles['placeholder-title']} style={{ height: 16 }} rows={1} />
            </div>
            <div className={styles['subtitle']}>
                <Placeholder.Paragraph className={styles['placeholder-subtitle-one']} style={{ height: 20 }} rows={1} />
                <Placeholder.Paragraph className={styles['placeholder-subtitle-two']} style={{ height: 20 }} rows={1} />
            </div>
        </>
    );
};

export default SkeletonHeader;
