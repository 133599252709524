import React from 'react';
import styles from './DrawerHeader.module.css';
import { Button, Divider, IconButton } from 'rsuite';
import CloseIcon from '@rsuite/icons/Close';
import Image from 'next/image';
import { RsuiteTooltip } from '../../../index';
import ArowBackIcon from '@rsuite/icons/ArowBack';
import { EX5057 } from 'config/flags';
import { t } from 'i18next';

const DrawerHeader = ({
    toggle,
    expand,
    isExpanded,
    quantityCart,
    setOpenModalDelete,
    fetching,
    allowExpand,
    handlePushRouter,
    pointOfSaleName,
}) => {
    return (
        <>
            <div
                className={styles['container']}
                style={{
                    border: !quantityCart && 'none',
                }}
            >
                <div className={styles['wrapper-title']}>
                    <div className={styles['wrapper-left']}>
                        {/* {allowExpand && !EX6303 &&(
                            <RsuiteTooltip
                                trigger={'hover'}
                                placement={'left'}
                                text={
                                    isExpanded
                                        ? !!EX5057
                                            ? t('shoppingCart.labelResize')
                                            : 'Contraer'
                                        : !!EX5057
                                        ? t('shoppingCart.labelExpand')
                                        : 'Expandir'
                                }
                                disabled={quantityCart === 0}
                            >
                                <Button
                                    className={
                                        isExpanded
                                            ? `icon__reduce-shopping-cart ${styles['button-expanded']}`
                                            : `icon__expand-shopping-cart ${styles['button-expanded']}`
                                    }
                                    onClick={expand}
                                    disabled={quantityCart === 0}
                                >
                                    <span
                                        className={
                                            isExpanded ? 'icon__reduce-shopping-cart' : 'icon__expand-shopping-cart'
                                        }
                                    >
                                        <Image
                                            alt="icono para expandir"
                                            src={
                                                quantityCart === 0
                                                    ? '/assets/images/expand_content_grey.svg'
                                                    : isExpanded
                                                    ? '/assets/images/contraction.svg'
                                                    : '/assets/images/expand_content.svg'
                                            }
                                            width={isExpanded ? 16 : 24}
                                            height={isExpanded ? 14 : 24}
                                        />
                                    </span>
                                </Button>
                            </RsuiteTooltip>
                        )} */}
                        <p className={styles['cart']}>
                            {!!EX5057 ? t('shoppingCart.shoppingCart_Seven') : 'Carrito'}{' '}
                            {!!quantityCart && !fetching && (
                                <span className={styles['quantity']}>{`(${quantityCart})`}</span>
                            )}
                        </p>
                    </div>
                    <div className={`link__empty-cart ${styles['wrapper-empty-text']}`}>
                        {!!quantityCart && !fetching && (
                            <p className={`link__empty-cart ${styles['empty-text']}`} onClick={setOpenModalDelete}>
                                {!!EX5057 ? t('shoppingCart.shoppingCart_Seventeen') : 'Vaciar carrito'}
                            </p>
                        )}
                    </div>
                </div>
                <div>
                    {allowExpand ? (
                        <IconButton
                            onClick={toggle}
                            appearance="link"
                            icon={<CloseIcon color="rgba(64, 67, 91, 1)" />}
                            style={{
                                paddingTop: 0,
                            }}
                            className="icon__close-cart"
                        />
                    ) : (
                        <IconButton onClick={handlePushRouter} appearance="link" className={styles['go-to-back']}>
                            <div className={styles['wrapper-button-content']}>
                                <ArowBackIcon
                                    style={{
                                        background: 'none',
                                        minWidth: 16,
                                        fontWeight: 600,
                                    }}
                                    color="rgba(1, 113, 230, 1)"
                                />
                                <p className={styles['drugManufacturer-name']}>{pointOfSaleName}</p>
                            </div>
                        </IconButton>
                    )}
                </div>
            </div>
            {!!quantityCart && !fetching && <Divider style={{ margin: '0 16px' }} />}
        </>
    );
};

export default DrawerHeader;
