import { useEffect, useRef, useState } from 'react';
// next
import { useRouter } from 'next/router';
// component
import { RenderMenuItemSelector } from '.';
// interface
import { IPharmacySelector } from './PharmacySelector.interface';
// context
import { useStatusContext } from 'context/control-state';
// rsuite
import { SelectPicker } from 'rsuite';
// style
import style from './PharmacySelector.module.css';
// mui v5
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import { useTranslation } from 'react-i18next';
import { useUser } from 'utils/hooks';
import useChangeLanguage from 'utils/hooks/use-change-language';
import { EX5057 } from 'config/flags';

const PharmacySelector = ({
    recordset,
    handlerSelectedValue,
    value,
    defaultValue,
    placeholder,
    userValues,
    itemSelected,
    disabledPosSelector,
    disabled,
    isDisabledSelector,
}: IPharmacySelector) => {
    const { t } = useTranslation();
    const { user } = useUser({});
    const userLocaleCountryCode = user?.client?.country_alfa_2;
    useChangeLanguage(userLocaleCountryCode);
    // next
    const router = useRouter();
    //context
    const { counterStep, setIsTourOpen, isTourOpen, setDisplayModalOnBoarding } = useStatusContext();
    const [haveReference, setHaveReference] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const ref = useRef(null);
    const selectRef = useRef(null);

    useEffect(() => {
        const handleOutSideClick = (event) => {
            if (!ref.current?.contains(event.target)) {
                if (
                    !!event.target.classList.contains('rs-picker-search-bar-input') ||
                    !!event.target.classList.contains('rs-picker-search-bar-search-icon')
                ) {
                    setIsOpen(true);
                } else if (
                    !!event.target.closest('.RenderMenuItemSelector_container__AuqWc') ||
                    !!event.target.closest('.RenderMenuItemSelector_disabled-item__LlPDc') ||
                    !!event.target.closest('.RenderMenuItemSelector_button-content__Yhv8M')
                ) {
                    setTimeout(() => {
                        setIsOpen(false);
                    }, 500);
                } else {
                    setIsOpen(false);
                }
            }
        };

        window.addEventListener('mousedown', handleOutSideClick);

        return () => {
            window.removeEventListener('mousedown', handleOutSideClick);
        };
    }, [ref]);

    const handlerOnBoarding = () => {
        setIsOpen(true);
        setIsTourOpen(false);
        if (counterStep === '#step-7' || (counterStep === 'body' && isTourOpen)) {
            setDisplayModalOnBoarding(false);
        }
    };

    const disabledItem = recordset?.filter((elem) => elem.availableDrugManufacturers <= 2)?.map((elem) => elem.value);

    const sortedItems = recordset?.sort(
        (a: any, b: any) => +(a.availableDrugManufacturers <= b.availableDrugManufacturers),
    );

    const isEqual = disabledItem?.includes(itemSelected) || disabledItem?.includes(+router?.query?.posId);

    const handleReferenceName = (item) => {
        if (item?.reference_name) {
            setHaveReference(true);
        } else {
            setHaveReference(false);
        }
        if (!item)
            return (
                <div className="dropdown__select-pos-1" style={{ minHeight: 20, maxHeight: 32 }}>
                    <p style={{ color: '#40435b', fontSize: 14 }} className="dropdown__select-pos-1">
                        {t('shoppingCart.walkthrough.shoppingCart_walkthrough_Eleven')}
                    </p>
                </div>
            );
        return (
            <div style={{ minHeight: 20, maxHeight: 32 }}>
                {item?.reference_name && <p className={style['reference']}>{item?.name}</p>}
                <p
                    className={`${item?.reference_name ? style['name_pdv'] : style['name_pdv_noReference']} ${
                        isDisabledSelector ? style['disabled-name'] : ''
                    }`}
                >
                    {item?.reference_name ? item?.reference_name : item?.name}
                </p>
            </div>
        );
    };
    const seeSelectedValue = (e) => {
        const value = sortedItems.find((item) => item.id === e);
        const sortedItemsFilter = sortedItems.filter((item) => item.id !== e);
        sortedItemsFilter.unshift(value);
        //setFilterItems(sortedItemsFilter)
        handlerSelectedValue(e);
    };

    // Get the div element by its class
    const getDivLabelSelectPicker = typeof window !== 'undefined' ? document?.querySelector('.rs-picker-toggle') : null;

    // Retrieve the value of the aria-owns attribute as it is the only way to identify it
    const ariaOwnsValue = getDivLabelSelectPicker?.getAttribute('aria-owns');

    // Validate identifier and if has reference
    if (ariaOwnsValue === 'step-5-listbox' && haveReference) {
        // Add class 'wrapper-pos-reference'
        getDivLabelSelectPicker?.setAttribute('id', 'wrapper-pos-reference');
    } else {
        // Remove class 'wrapper-pos-reference'
        getDivLabelSelectPicker?.removeAttribute('id');
    }


    return (
        <div className={style['container-selecter-picker']}>
            {!disabledPosSelector && (
                <>
                    <div className={style['wrapper-select-picker']} id={"step-7"} onClick={handlerOnBoarding}>
                        {counterStep === '#step-4' && <div className={style['container-blur']}></div>}
                        <div
                            className={
                                haveReference
                                    ? `dropdown__select-pos-1 ${style['reference_svg']}`
                                    : 'dropdown__select-pos-1'
                            }
                        >
                            <SelectPicker
                                size="lg"
                                ref={selectRef}
                                data={sortedItems ? sortedItems : [] }
                                disabled={disabled}
                                value={value ? parseInt(value) : 0}
                                searchBy={(keyword, label, item) => {
                                    return (
                                        item?.name?.toLowerCase().includes(keyword.toLowerCase()) ||
                                        item?.reference_name?.toLowerCase().includes(keyword.toLowerCase())
                                    );
                                }}
                                defaultValue={defaultValue ? parseInt(defaultValue) : 0 }
                                onChange={(e) => {
                                    seeSelectedValue(e);
                                }}
                                style={{
                                    background: isDisabledSelector ? '#ACAEC4' : 'none',
                                    border: isDisabledSelector ? '1px solid #E0E1E9' : 'none',
                                    borderRadius: isDisabledSelector ? '8px' : 'none',
                                    opacity: isDisabledSelector ? 1 : 'none',
                                }}
                                placeholder={placeholder}
                                className={`dropdown__select-pos-1 ${
                                    isOpen ? style['select-picker-border'] : style['select-picker']
                                } ${isDisabledSelector ? style['disabled-picker'] : ''}`}
                                searchable={true}
                                open={isDisabledSelector ? false : isOpen}
                                cleanable={false}
                                caretAs={() => (
                                    <ExpandMoreOutlinedIcon
                                        id={haveReference ? 'wrapper-icon-pos-reference' : 'none'}
                                        className={
                                            haveReference
                                                ? `dropdown__select-pos-1 ${style['wrapper-icon-pos-reference']} `
                                                : 'dropdown__select-pos-1'
                                        }
                                        style={{
                                            color: isDisabledSelector ? '#ACAEC4' : '#0171e6',
                                        }}
                                    />
                                )}
                                //disabledItemValues={disabledItem}
                                renderMenuItem={(label, item) => {
                                    if(item){
                                       return <RenderMenuItemSelector
                                            selectValue={value}
                                            sortedItems={sortedItems}
                                            values={item}
                                            userValues={userValues}
                                        />
                                    }
                                    
                                }}
                                renderValue={(value, item) => {
                                    if(item){
                                        return handleReferenceName(item)
                                    }
                                }}
                                menuClassName={!disabledItem ? style['disabled'] : style['menu']}
                                placement="bottom"
                            />
                        </div>
                        <div className={style['wrapper-icon-select']}>
                            <StorefrontOutlinedIcon
                                className={!!isEqual ? style['store-icon-front-disabled'] : style['store-icon-front']}
                            />
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default PharmacySelector;
