import React, { useState } from 'react';
//css
import style from './InputFormLogin.module.css';
//rsuite
import { Button, Loader } from 'rsuite';
//mui
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
//google
import ReCAPTCHA from 'react-google-recaptcha';
import Link from 'next/link';
//icons
import InputForm from './InputForm/InputForm';
import { ControlPassword } from './ControlPassword';
import { useRouter } from 'next/router';
import { normalizeRouteSegment } from 'utils/normalizeRoutes';

const InputFormLogin = ({
    formValues,
    onSubmit,
    updateValues,
    values,
    errorUser,
    onChangeCaptcha,
    activeUser,
    disableInputMail,
}) => {
    const [click, setIsClicked] = useState(false);
    const [validLength, setValidLength] = useState(false);
    const [hasUppercase, setHasUppercase] = useState(false);
    const [hasNumber, setHasNumber] = useState(false);
    const [disableButton, setDisableButton] = useState(false);

    const handleKeyPress = async (event) => {
        if (event.key === 'Enter') {
            setIsClicked(true);
            await onSubmit();
            setIsClicked(false);
        }
    };

    const handleSubmit = async () => {
        setIsClicked(true);
        await onSubmit();
        setIsClicked(false);
    };

    //helper to handle dynamic classNames
    const router = useRouter();

    const getClassByRoute = (type) => {
        const pathParts = router?.pathname?.split('/')?.filter((p) => p);
        const config = {
            button: {
                login: 'button__log-in',
                newPassword: 'button__change-password-1',
                activateUser: 'button__login-activate-1',
                recovery: 'button__password-recovery-1',
            },
            link: {
                login: 'link__forgot-password-2',
                newPassword: 'link__change-password-cancel',
                activateUser: 'link__forgot-password-1',
                recovery: 'link__back-to-login-1',
            },
        };

        let key = normalizeRouteSegment(pathParts[0]); // Get the key filtered by route path
        if (['new-password', 'recovery'].includes(pathParts[1])) {
            key = normalizeRouteSegment(pathParts[1]);
        }
        return config[type]?.[key];
    };

    return (
        <div onKeyPress={handleKeyPress} tabIndex={0}>
            {errorUser && <p className={style['error-span']}>Nombre de usuario y/o contraseña incorrectos</p>}

            {formValues?.inputValues?.map((inputValue) => (
                <InputForm
                    inputValue={inputValue}
                    updateValues={updateValues}
                    values={values}
                    activeUser={activeUser}
                    disableInputMail={disableInputMail}
                    validLength={validLength}
                    setValidLength={setValidLength}
                    hasUppercase={hasUppercase}
                    setHasUppercase={setHasUppercase}
                    hasNumber={hasNumber}
                    setHasNumber={setHasNumber}
                />
            ))}
            {activeUser && (
                <ControlPassword
                    hasUppercase={hasUppercase}
                    validLength={validLength}
                    hasNumber={hasNumber}
                    arrayObj={formValues?.inputValues}
                    values={values}
                    setDisableButton={setDisableButton}
                />
            )}

            {onChangeCaptcha && (
                <ReCAPTCHA
                    style={{ margin: '24px 0px' }}
                    sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE}
                    onChange={onChangeCaptcha}
                />
            )}
            <Button
                disabled={disableButton}
                appearance="primary"
                type="submit"
                className={`${getClassByRoute('button')} ${style['button']}`}
                onClick={handleSubmit}
            >
                <span className={`${getClassByRoute('button')} ${style['buttonText']}`}>
                    {formValues?.buttonAction?.name}
                    {click ? (
                        <Loader
                            style={{
                                marginLeft: 5,
                            }}
                        />
                    ) : (
                        <ArrowForwardIcon className={`${getClassByRoute('button')} ${style['icon-navigation']}`} />
                    )}
                </span>
            </Button>

            <Link href={formValues?.label?.navigate} className={getClassByRoute('link')}>
                <p className={`${getClassByRoute('link')} ${style['navigation-span']}`}>{formValues?.label?.name}</p>
            </Link>
        </div>
    );
};

export default InputFormLogin;
