// react
import { useEffect, useRef, useState } from 'react';
// next
import { useRouter } from 'next/router';
import { t } from 'i18next';
// components
import { CartBadge, LineDivider, NotificationAlert, Path, PharmacySelector, Profile, Settings } from '.';
// handlers
import { handlerFillSelector } from './handlers';
// hooks
import { useDebounce, useModal, useUser } from 'utils/hooks';
// context
import { NewUseCart, useAuth, useNewSearchProduct, usePointOfSale } from 'context';
// utils
import { definitionPaths, obtainInitialFirstLetters, obtainRoleOfUser, savePosIdLocalStorage } from './utils';
// rsuite v5
import { Col, Navbar, Row, Header as RsuiteHeader } from 'rsuite';
// style
import style from './Header.module.css';
import { switchPlaceholder } from './helpers';
// components
import { useStatusContext } from 'context/control-state';
import { DrawerCart, ModalChangePassowrdV2 } from '../../index';
//cookie
import { EX3900, EX4467, EX5791, EX6243, EX7579 } from 'config/flags';
import { useDragAndDrop } from 'context/dragAndDropContext';
import { isGestorERPFormat, isZettiFormat } from 'utils/pointOfSale';
import { AutoCompleteSearch } from './AutoCompleteSearch';
import { disabledPaths } from './PharmacySelector/utils';

const Header = ({ orders, delivery, handlerClearFilters = null }) => {
    // ff
    const EX3526 = process.env.NEXT_PUBLIC_EX3526 == 'true';
    // hooks
    const { user } = useUser({});
    const EX4904 = user?.EX4904;
    const EX6236 = user?.EX6236;

    const { pointOfSale, setPointOfSale } = usePointOfSale();
    const { logout } = useAuth();
    const modal = useModal();
    const { counterStep, setIsTourOpen, setHideSuggestionPDV } = useStatusContext();

    // next
    const router = useRouter();
    // state's
    const [selectedPointOfSale, setSelectedPointOfSale] = useState(null);
    const [pdvSelectedInLocalStorage, setPdvSelectedInLocalStorage] = useState(selectedPointOfSale);
    const [openFocus, setOpenFocus] = useState<boolean>(false);
    const [selectedIndex, setSelectedIndex] = useState(-1);

    // profile
    const role = obtainRoleOfUser(user);
    const alias = `${user?.first_name} ${user?.last_name}`;
    const initialAlias = obtainInitialFirstLetters(alias);

    // selector
    const recordset = handlerFillSelector(user?.pointsOfSale);
    const { withPosName, withoutPosName } = switchPlaceholder(EX3526, t);

    // cart
    const { getCart, quantityCart, toggle, visibility } = NewUseCart();

    // pdv
    const pdvSelected = user?.pointsOfSale.find((elem) => elem.id === selectedPointOfSale);

    // validation is zetti
    const isZetti = isZettiFormat(pointOfSale);
    const isGestorERP = isGestorERPFormat(pointOfSale);

    const doNotCallCartOpen: string[] = [
        '/[clientId]/efficient-purchase/[posId]/confirmation',
        '/[clientId]/efficient-purchase/[posId]/product-comparison',
    ];

    useEffect(() => {
        (async () =>
            selectedPointOfSale &&
            !doNotCallCartOpen?.includes(router?.pathname) &&
            getCart(selectedPointOfSale, true))();
    }, [selectedPointOfSale]);

    // Paths
    const { path, goBack } = definitionPaths(router.pathname, user, pointOfSale?.id, orders, delivery, router.query);

    // US VALIDATION
    const US_ES_PATH_VALIDATION = EX5791
        ? ['Compra eficiente', 'Resultados', 'Efficient purchase', 'Results']
        : ['Compra eficiente', 'Resultados'];

    useEffect(() => {
        if (!!pdvSelected) {
            setPointOfSale(pdvSelected); //set pdv state in context
            (async () => await savePosIdLocalStorage(pdvSelected))();
        }
    }, [pdvSelected, selectedPointOfSale, router.pathname]);

    useEffect(() => {
        if(!EX7579) {
            const pdvOfLocalStorage = localStorage.getItem('pdvSelected');
    
            (async () =>
                JSON.parse(pdvOfLocalStorage)?.id &&
                !doNotCallCartOpen?.includes(router?.pathname) &&
                getCart(JSON.parse(pdvOfLocalStorage)?.id, true))();
            if (
                !!pdvOfLocalStorage &&
                !!user &&
                path === 'Compra eficiente' &&
                path !== 'Mis pedidos' &&
                router.pathname.split('/').at(-1) !== 'product-comparison'
            ) {
                const id = JSON.parse(pdvOfLocalStorage)?.id;
                setPdvSelectedInLocalStorage(id);
                router.push(`/${user?.client?.id}/efficient-purchase/${id}`);
            } else {
                if (
                    path !== 'Resultados' &&
                    path === 'Compra eficiente' &&
                    !!user &&
                    router.pathname.split('/').at(-1) !== 'product-comparison'
                ) {
                    router.push(`/${user?.client?.id}/efficient-purchase/`);
                }
            }
        }
    }, []);

    useEffect(() => {
        if (EX7579) {
            if (!!pointOfSale) {
                (async () => !doNotCallCartOpen?.includes(router?.pathname) && getCart(pointOfSale?.id, true))();
                if (
                    !router?.query?.posId &&
                    path === 'Compra eficiente' &&
                    path !== 'Mis pedidos' &&
                    router.pathname.split('/').at(-1) !== 'product-comparison'
                ) {
                    router.push(`/${pointOfSale?.client_id}/efficient-purchase/${pointOfSale?.id}`);
                }
            }
        }
    }, [pointOfSale]);

    useEffect(() => {
        const pathValidation = EX5791
            ? ['Compra eficiente', 'Efficient purchase']?.includes(path)
            : path === 'Compra eficiente';
        if (!!selectedPointOfSale && pathValidation) {
            router.push(`/${user?.client?.id}/efficient-purchase/${selectedPointOfSale}`);
        }
    }, [selectedPointOfSale]);

    const handlerOnBoardingSelectPDV = () => {
        localStorage.setItem('firstLogin', 'false');
        setHideSuggestionPDV(true);
    };

    const disabledPosSelector = disabledPaths.includes(router.pathname);

    const isEfficientPurchases =
        router.pathname === `/[clientId]/efficient-purchase/[posId]/product-comparison` &&
        goBack === `/${user?.client?.id}/efficient-purchase/${pointOfSale?.id}`;

    const isConfirmationEfficientPurchases =
        router.pathname === `/[clientId]/efficient-purchase/[posId]/confirmation` &&
        goBack === `/${user?.client?.id}/efficient-purchase/${pointOfSale?.id}/product-comparison`;

    const isDisabledSelector =
        router.pathname === '/[clientId]/efficient-purchase/[posId]/product-comparison' ||
        router.pathname === '/[clientId]/efficient-purchase/[posId]/order-sent' ||
        router.pathname === '/[clientId]/efficient-purchase/[posId]/confirmation';

    const handlerSelectPos = (evt) => {
        if (router.pathname === '/[clientId]/efficient-purchase/[posId]/search-results' && !!evt) {
            setSelectedPointOfSale(evt);
            handlerOnBoardingSelectPDV();
            router.push(`/${user?.client?.id}/efficient-purchase/${pointOfSale?.id}`);
        } else {
            setSelectedPointOfSale(evt);

            handlerOnBoardingSelectPDV();
        }
    };

    const steps = ['#step2', 'body', '#step4'];
    const controlStep = steps.find((element) => counterStep === element);
    const [searchValue, setSearchValue] = useState('');
    const [visibleClear, setVisibleClear] = useState(false);
    const searchValueDebounce = useDebounce(searchValue, 500);
    const [click, setClick] = useState<boolean>(false);
    const [productNameSelected, setProductNameSelected] = useState(null);
    const [activeSearches, setActiveSearches] = useState(false);

    const [isInputPickerOpen, seIsInputPickerOpen] = useState(true);

    // context
    const {
        searchData,
        currentSearch,
        lastSearch,
        setLastSearch,
        setCurrentSearch,
        isLoading,
        pagination,
        handleOpen,
        isOpen,
        handleResetSearch,
        setAggregationsTypes,
        aggregationsTypes,
        activeShakeAnimation,
        isClickButtonByCode,
        handleClickButtonByCode,
    } = useNewSearchProduct();

    const {
        addProduct,
        existsProductInCart,
        getProductByBarcode,
        updateProduct,
        isExpanded,
        handleResetVisibility,
        setVisibility,
        setProductsList,
    } = NewUseCart();

    const { openDragAndDropIconClose } = useDragAndDrop();

    const containerRef = useRef(null);

    const isHome =
        router?.pathname === '/[clientId]/efficient-purchase' ||
        router?.pathname === '/[clientId]/efficient-purchase/[posId]';

    const isResults = router?.pathname === '/[clientId]/efficient-purchase/[posId]/search-results';

    const isAnalytics = router?.pathname === '/analytics';

    const clearSearchValue = () => {
        setClick(true);
    };

    const handlerSendWithEnter = (value, evt) => {
        if (evt?.key === 'Enter') {
            handleOpen(false);
            if (isResults) setOpenFocus(false);
            if (value.length > 0) {
                router.push(
                    `/${user?.client?.id}/efficient-purchase/${pointOfSale?.id}/search-results?search=${value}`,
                );
            }
        }
    };

    const updateLastSearch = (debouncedValue) => {
        // update last search localStorage
        if (lastSearch?.length === 0 || lastSearch?.indexOf(debouncedValue) === -1) {
            lastSearch?.unshift(debouncedValue);
            const slicedLastSearch = lastSearch.slice(0, 6);
            setLastSearch(slicedLastSearch);
            localStorage.setItem('lastSearch', JSON.stringify(slicedLastSearch));
        }
    };

    useEffect(() => {
        if (searchValueDebounce.trim() !== '' && searchValueDebounce.length >= 3) {
            setCurrentSearch(searchValueDebounce);
            updateLastSearch(searchValueDebounce);
            return;
        }
    }, [searchValueDebounce]);

    useEffect(() => {
        // reset state when close dropdown
        if (!isOpen && !click) {
            if (isHome) {
                handleResetSearch();
                setSearchValue('');
                setVisibleClear(false);
            }
        }
    }, [isOpen, click]);

    const showInputSearch = (): boolean => {
        if ((isHome || isResults) && !isZetti && !isGestorERP) {
            return true;
        } else {
            return false;
        }
    };

    const showPathAndGoBack = (): boolean => {
        if (isHome) {
            return false;
        } else {
            return true;
        }
    };

    const showCartBadge = (): boolean => {
        if (
            router.pathname === '/[clientId]/efficient-purchase/[posId]/product-comparison' ||
            router.pathname === '/[clientId]/efficient-purchase/[posId]/confirmation' ||
            router.pathname === '/[clientId]/efficient-purchase/[posId]/order-sent'
        ) {
            return false;
        } else {
            return true;
        }
    };

    // ------------------------------- RESULTS LOGIC ----------------------------
    // clean input
    const handleCleanInput = () => {
        if (isResults || isHome) {
            const element: any = document?.getElementById('input-open-search-results');
            handleResetSearch();
            setSearchValue('');
            setOpenFocus(true);
            element.focus();
        }
    };

    // open dropdown
    const openDropdown = (): boolean => {
        const shouldOpenDropdown = () => {
            if (searchValue.length > 0 && !!isOpen) {
                return true;
            } else if (searchValue.length < 0 && !!isOpen) {
                return true;
            } else if (!!openFocus) {
                handleOpen(true);
                return true;
            } else {
                handleOpen(false);
                return false;
            }
        };

        if (isResults || isHome) {
            return shouldOpenDropdown();
        } else if (isResults) {
            return shouldOpenDropdown();
        }
        return false;
    };

    const handleClickOutside = (event) => {
        if (!isHome) return; // Solo ejecuta la lógica si estás en la página principal

        const menuElement = document.querySelector('.rs-picker-menu');
        const inputElement = document.getElementById('input-open-search-results');

        // Verifica si el clic ocurrió dentro del menú o dentro del input
        if (
            (menuElement && menuElement.contains(event.target)) ||
            (inputElement && inputElement.contains(event.target))
        ) {
            return; // No cierra el menú si el clic ocurrió dentro del menú o del input
        }

        handleOpen(false); // Cierra el menú
    };

    useEffect(() => {
        if (isHome) {
            document.addEventListener('click', handleClickOutside);
            return () => {
                document.removeEventListener('click', handleClickOutside);
            };
        }
    }, [isHome, isOpen]);

    useEffect(() => {
        if (isResults) setSearchValue(router.query?.search as string);
    }, [router.query?.search]);

    const handlerAnimateSearchInput = () => {
        handleResetSearch();
        setSearchValue('');
        setProductNameSelected(null);
        setVisibleClear(false);
        handleClickButtonByCode();
    };

    return (
        <RsuiteHeader style={EX4467 ? { zIndex: 1200 } : {}}>
            {/* El zIndex se coloca en 1200 para que se superponga al 'backdrop' al abrir el carrito en orders y config*/}
            {/* ON BOARDING BLUR  */}
            {controlStep === '#step2' && <div className={style['wrapper-cart-blur-step2']}></div>}
            {!controlStep && <div className={style['wrapper-cart-blur']}></div>}
            {/* END SECTION ON BOARDING BLUR  */}
            {/* ----------------------------------- */}
            <div className={style['container-navbar']}>
                <Navbar className={style['wrapper-navbar']}>
                    <div className={style['container-cols']}>
                        <Row
                            className={style['container-row']}
                            style={{
                                padding: `0px ${!showCartBadge() ? '16px' : '49px'}`,
                                margin: 0,
                                justifyContent: 'flex-end',
                            }}
                        >
                            {showPathAndGoBack() && (
                                <Col xs={!isHome && !isResults ? 16 : 4}>
                                    <Path
                                        label={path}
                                        href={goBack}
                                        haveGoBack={!!goBack}
                                        isEfficientPurchases={isEfficientPurchases}
                                        isConfirmationEfficientPurchases={isConfirmationEfficientPurchases}
                                        clientId={user?.client?.id}
                                        handlerClearFilters={handlerClearFilters}
                                    />
                                </Col>
                            )}
                            {showInputSearch() && (
                                <Col xs={isHome ? 16 : 14} style={{ padding: EX6236 ? 0 : '0px 5px' }}>
                                    {isHome ? (
                                        <div id="step2">
                                            <AutoCompleteSearch
                                                placeholder={
                                                    !searchValue && lastSearch && !!activeSearches
                                                        ? productNameSelected
                                                        : t(
                                                              'efficientPurchasing.fileUpload.efficientPurchasing_fileUploadThirtyThree',
                                                          )
                                                }
                                                recordset={searchData}
                                                onSearch={(value) => {
                                                    // Validación que restablece el estado de la búsqueda
                                                    if (value === '') {
                                                        handleResetSearch();
                                                        setVisibleClear(false);
                                                        handleCleanInput();
                                                    } else {
                                                        // Si hay agregaciones, las reseteamos
                                                        if (Object.keys(aggregationsTypes).length !== 0) {
                                                            setAggregationsTypes({});
                                                        }
                                                        setSearchValue(value);
                                                        setVisibleClear(true);
                                                    }
                                                }}
                                                onClickAddProduct={(e, item) => {
                                                    e.stopPropagation();
                                                    addProduct(item);
                                                }}
                                                valueSearch={searchValue}
                                                existsProductInCart={existsProductInCart}
                                                getProductByBarcode={getProductByBarcode}
                                                pointOfSaleId={pointOfSale?.id}
                                                handleUpdateProduct={updateProduct}
                                                isLoading={isLoading}
                                                pagination={pagination}
                                                lastSearch={lastSearch}
                                                handleCleanInput={handleCleanInput}
                                                openDropdown={openDropdown}
                                                setOpenFocus={setOpenFocus}
                                                currentSearch={currentSearch}
                                                visibility={visibility}
                                                isExpanded={isExpanded}
                                                toggle={toggle}
                                                handlerSendWithEnter={(user, evt) => handlerSendWithEnter(user, evt)}
                                                productNameSelected={productNameSelected}
                                                setProductNameSelected={setProductNameSelected}
                                                selectedIndex={selectedIndex}
                                                setSelectedIndex={setSelectedIndex}
                                                setActiveSearches={setActiveSearches}
                                                onClickButtonByCode={handlerAnimateSearchInput}
                                                activeShakeAnimation={activeShakeAnimation}
                                                //onClose={() => !!isOpen && isHome && handleOpen(false)} // Incluimos el evento de cierre
                                                isClickButtonByCode={isClickButtonByCode}
                                                setVisibleClear={setVisibleClear}
                                                onClickVisibleDragDrop={() => {
                                                    // Incluimos lógica con EX6243 para manejar drag and drop
                                                    if (EX6243) {
                                                        handleResetVisibility();
                                                    }
                                                    openDragAndDropIconClose();
                                                }}
                                                visibleClear={visibleClear}
                                            />
                                        </div>
                                    ) : (
                                        <AutoCompleteSearch
                                            placeholder={
                                                !searchValue && lastSearch && !!activeSearches
                                                    ? productNameSelected
                                                    : 'Buscar'
                                            }
                                            recordset={searchData}
                                            onSearch={(value) => {
                                                // validation that resets search status
                                                if (value === '') {
                                                    handleResetSearch();
                                                    handleCleanInput();
                                                    setSearchValue('');
                                                } else {
                                                    if (Object.keys(aggregationsTypes).length !== 0) {
                                                        setAggregationsTypes({});
                                                    }
                                                    setSearchValue(value);
                                                }
                                            }}
                                            onClickAddProduct={(e, item) => {
                                                e.stopPropagation();
                                                addProduct(item);
                                            }}
                                            valueSearch={searchValue}
                                            existsProductInCart={existsProductInCart}
                                            getProductByBarcode={getProductByBarcode}
                                            pointOfSaleId={pointOfSale?.id}
                                            handleUpdateProduct={updateProduct}
                                            isLoading={isLoading}
                                            pagination={pagination}
                                            lastSearch={lastSearch}
                                            handleCleanInput={handleCleanInput}
                                            openDropdown={openDropdown}
                                            setOpenFocus={setOpenFocus}
                                            currentSearch={currentSearch}
                                            visibility={visibility}
                                            isExpanded={isExpanded}
                                            toggle={toggle}
                                            handlerSendWithEnter={(user, evt) => handlerSendWithEnter(user, evt)}
                                            productNameSelected={productNameSelected}
                                            setProductNameSelected={setProductNameSelected}
                                            selectedIndex={selectedIndex}
                                            setSelectedIndex={setSelectedIndex}
                                            setActiveSearches={setActiveSearches}
                                            onClickButtonByCode={handlerAnimateSearchInput}
                                            activeShakeAnimation={activeShakeAnimation}
                                            isClickButtonByCode={isClickButtonByCode}
                                        />
                                    )}
                                </Col>
                            )}
                            <Col xs={8} md={8} lg={8} className={style['container-commands']} style={{ padding: 0 }}>
                                {!isAnalytics && (
                                    <Col style={{ marginLeft: isHome ? 160 : 0 }}>
                                        <PharmacySelector
                                            recordset={recordset}
                                            handlerSelectedValue={(evt) => handlerSelectPos(evt)}
                                            itemSelected={selectedPointOfSale}
                                            value={selectedPointOfSale ? selectedPointOfSale : router.query.posId}
                                            defaultValue={
                                                selectedPointOfSale ? selectedPointOfSale : router.query.posId
                                            }
                                            placeholder={!!selectedPointOfSale ? withPosName : withoutPosName}
                                            userValues={user}
                                            disabledPosSelector={disabledPosSelector}
                                            disabled={isDisabledSelector}
                                            isDisabledSelector={isDisabledSelector}
                                        />
                                    </Col>
                                )}
                                {EX3900 && (
                                    <>
                                        <Col style={{ display: 'flex', justifyContent: 'center' }}>
                                            <LineDivider />
                                        </Col>
                                        <Col xs={1} className={style['col-header']}>
                                            <NotificationAlert />
                                        </Col>
                                    </>
                                )}
                                <Col>
                                    <LineDivider />
                                </Col>
                                <Col xs={1} className={style['col-header']}>
                                    <Settings
                                        title={<Profile initials={initialAlias} />}
                                        execModal={modal}
                                        execLogout={logout}
                                        isZetti={isZetti || isGestorERP}
                                    />
                                </Col>
                                {showCartBadge() && (
                                    <Col xs={1}>
                                        <CartBadge
                                            quantityCart={!!quantityCart() ? quantityCart() : ''}
                                            toggle={toggle}
                                            pointOfSaleId={pointOfSale?.id}
                                            isZetti={isZetti || isGestorERP}
                                        />
                                    </Col>
                                )}
                            </Col>
                        </Row>
                    </div>
                </Navbar>
                {!US_ES_PATH_VALIDATION.includes(path) && (
                    <div
                        className={`${visibility && style['drawer-fade-in']}`}
                        style={{
                            position: 'fixed',
                            right: 50,
                            top: 65,
                            transform: 'translateX(0)',
                            zIndex: 900,
                        }}
                    >
                        <DrawerCart />
                    </div>
                )}
            </div>
            <ModalChangePassowrdV2 modal={modal} />
        </RsuiteHeader>
    );
};

export default Header;
