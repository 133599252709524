import React, { useEffect, useState } from 'react'
import { RsuiteCounter } from '../../../../index'
import { handleChangeMomentary, handleOperation } from './handler'
import styles from './DrawerCounter.module.css'

const DrawerCounter = ({ idx, product, quantity, handleUpdateProduct, pointOfSale }) => {
    // STATE LOCAL INPUT
    const [value, setValue] = useState<number>(quantity)
    const [isFetching, setIsFetching] = useState<boolean>(false)

    // LISTEN UPDATE QUANTITY & SETTER STATE PD: listen product.id because missing reference
    useEffect(() => {
        if (isFetching || quantity !== value) setValue(quantity)
        setIsFetching(false)
    }, [quantity, product.id])

    // SUBS
    const handleMinus = (v) => {
        setIsFetching(true)
        return handleOperation(product, v, handleUpdateProduct)
    }

    // ADD
    const handlePlus = (v) => {
        setIsFetching(true)
        return handleOperation(product, v, handleUpdateProduct)
    }

    // WRITING MOMENTARY
    const handleWriting = (v) => {
        return handleChangeMomentary(v, setValue)
    }

    // INPUT ONBLUR
    const handleInput = (v) => {
        if (quantity !== +v) {
            setIsFetching(true)
            return handleOperation(product, +v, handleUpdateProduct)
        }
    }

    return (
        <RsuiteCounter
            idx={idx}
            value={value}
            handleWriting={handleWriting}
            handleInput={handleInput}
            handleMinus={handleMinus}
            handlePlus={handlePlus}
            hasError={false}
            isFetching={isFetching}
            styles={styles}
            min={0}
            tabOrigin={'cart'}
        />
    )
}

export default DrawerCounter
