import { useRouter } from 'next/router';
// material ui
import { CloseButtonIcon } from './CloseButtonIcon';
// components
import { Button } from 'rsuite';
// styles
import style from './UploaderButton.module.css';

const UploaderButton = ({ onClickVisibleDragDrop, isOpen, visibleClear, onClickClose, setVisibleClear }) => {
    const { pathname } = useRouter();
    const gtagClassByPath = pathname === '/[clientId]/efficient-purchase/[posId]' ? '1' : '2';
    return (
        <div
            className={`button__upload-file-1 ${
                style['container-file-system-upload']
            }`}
            style={{ zIndex: isOpen ? 2100 : 0 }}
        >
            {!visibleClear ? (
                <Button
                    size="sm"
                    onClick={onClickVisibleDragDrop}
                    className={`button__upload-file-${gtagClassByPath} ${style['button-icon-text']}`}
                    startIcon={
                        <img
                            src="/assets/images/cloud.svg"
                            className={`button__upload-file-${gtagClassByPath} ${style['upload-icon']}`}
                        />
                    }
                />
            ) : (
                <CloseButtonIcon onClickClose={onClickClose} setVisibleClear={setVisibleClear} />
            )}
        </div>
    );
};

export default UploaderButton;
