import React from 'react';
// CONTEXTS
import { useModalCdO } from 'context/modalCdO/modalCdO';
// COMPONENTS
import { Button } from 'rsuite';
import PlusIcon from '@rsuite/icons/Plus';
// STYLES
import cdoStyles from '../CellCdo.module.css';
// INTERFACES
import { RenderRequestButtonProps } from './RenderRequestButton.interface';

const RenderRequestButton = ({ skeleton }: RenderRequestButtonProps): JSX.Element => {
    /* #region contexts */
    const { openModal } = useModalCdO();
    /* #endregion */

    return (
        <Button
            className={`button__request-offer-center-2 ${cdoStyles['button-request-cdo']}`}
            onClick={() => openModal()}
            disabled={skeleton}
            style={{
                background: skeleton ? '#E0E1E9' : '',
                borderColor: skeleton ? '#E0E1E9' : '',
            }}
        >
            <PlusIcon color={skeleton ? '#ACAEC4' : '#0171E6'} className="button__request-offer-center-2" />
            <p className="button__request-offer-center-2" style={{ color: skeleton ? '#ACAEC4' : '' }}>
                Solicitar
            </p>
        </Button>
    );
};

export default RenderRequestButton;
