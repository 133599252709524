import React, { useEffect, useState } from 'react';
// handlers
import { handleChangeMomentary, handleOperation } from './handlers';
import { formatObject } from './handlers/formatObject';
// components
import { RecommendedCardHeader } from './RecommendedCardHeader';
import { RecommendedCardFooter } from './RecommendedCardFooter';
import { RecommendedCardBody } from './RecommendedCardBody';
// style cards
import style from './RecommendedCard.module.css';
import { EX4698, EX4796 } from 'config/flags';
import { useStatusContext } from 'context/control-state';

const RecommendedCardV2 = ({
    recommended,
    handlerAddProductCart,
    handleUpdateProduct,
    existsProductInCart,
    cartId,
    quantity,
    disabledButtons,
    isFifthElem,
}) => {
    // STATE LOCAL INPUT
    const [value, setValue] = useState<number>(quantity);
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [imageLoad, setImageLoad] = useState<boolean>(false);
    const { setDisplayModalOnBoarding, displayModalOnBoarding } = useStatusContext();

    // LISTEN UPDATE QUANTITY & SETTER STATE
    useEffect(() => {
        if (isFetching || quantity !== value) setValue(quantity);
        setIsFetching(false);
    }, [quantity]);

    // SUBS
    const handleMinus = (v) => {
        setIsFetching(true);
        return handleOperation(formatObject(recommended, cartId), v, handleUpdateProduct);
    };

    // ADD
    const handlePlus = (v) => {
        if (displayModalOnBoarding) {
            setDisplayModalOnBoarding(false);
        }
        setIsFetching(true);
        return handleOperation(formatObject(recommended, cartId), v, handleUpdateProduct);
    };

    // WRITING MOMENTARY
    const handleWriting = (v) => {
        return handleChangeMomentary(v, setValue);
    };

    // INPUT ONBLUR
    const handleInput = (v) => {
        if (quantity !== +v) {
            setIsFetching(true);
            return handleOperation(formatObject(recommended, cartId), +v, handleUpdateProduct);
        }
    };

    return (
        <div className={style['container']} style={EX4796 ? { maxHeight: EX4698 ? 377 : 357 } : {}}>
            <div className={style['box']} style={{ gap: 4 }}>
                <RecommendedCardHeader recommended={recommended} />
                <RecommendedCardBody
                    imageLoad={imageLoad}
                    setImageLoad={setImageLoad}
                    recommended={recommended}
                    isFifthElem={isFifthElem}
                />
                <RecommendedCardFooter
                    existsProductInCart={existsProductInCart}
                    isFetching={isFetching}
                    recommended={recommended}
                    value={value}
                    handleWriting={handleWriting}
                    handleInput={handleInput}
                    handleMinus={handleMinus}
                    handlePlus={handlePlus}
                    disabledButtons={disabledButtons}
                    setIsFetching={setIsFetching}
                    handlerAddProductCart={handlerAddProductCart}
                />
            </div>
        </div>
    );
};

export default RecommendedCardV2;
