import { useMemo, useState } from 'react';

export const useStatesFilters = () => {
    const [isFilteringByOutofStock, setIsFilteringByOutofStock] = useState(false);
    const [isFilteringByGenerics, setIsFilteringByGenerics] = useState(false);

    const filters = useMemo(
        () => ({
            isFilteringByOutofStock,
            setIsFilteringByOutofStock,
            isFilteringByGenerics,
            setIsFilteringByGenerics,
        }),
        [isFilteringByOutofStock, isFilteringByGenerics],
    );

    return filters;
};
