import { useRouter } from 'next/router';
import { typeAlerts } from 'utils/enums/typeAlert/typeAlert';
import { useUser } from 'utils/hooks';

export const useAlerts = () => {
    const { user } = useUser({});
    const { pathname } = useRouter();
    const EX4990 = user?.EX4990;

    const alerts: any = Object.values(user?.client?.alerts ?? {});
    const [item] = alerts;
    const hasAlert =
        pathname === '/[clientId]/efficient-purchase/[posId]/product-comparison' &&
        item?.notification_location.includes(typeAlerts.Comparative);

    const showAlert = EX4990 && !!hasAlert;
    const itemNotificationType = !!item && item?.notification_type;

    return { hasAlert, item, showAlert, itemNotificationType };
};
