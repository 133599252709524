// React
import { useState, useEffect } from 'react';
// Next
import { useRouter } from 'next/router';
// Component
import { ExButton } from '@commons/EXComponentsLibrary';
import { ClientTableOpenpay, ClientFormOpenpay } from '.';
// style
import style from './OpenpayFormDetails.module.css';
// Api
import { postPlansSubscriptions } from 'api/backoffice/backoffice';
// Hook
import { useUser } from 'utils/hooks';
// Context
import { useClientDetails } from 'context/client-details.context';
// Service
import { getPlansForSubscriptions, getPosForSubscriptions } from './Service';

const OpenpayFormDetails = () => {
    // Context and router setup
    const { query } = useRouter();
    // context
    const { client, mutateClient } = useClientDetails();
    const { user } = useUser({});
    // flag
    const EX7454 = user?.EX7454;
    // State management
    const [formData, setFormData] = useState({ plan: '', email: '', pos: [] });
    const [plans, setPlans] = useState([]);
    const [posByClients, setPosByClients] = useState([]);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [emailError, setEmailError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selectedPos, setSelectedPos] = useState([]);
    const [errors, setErrors] = useState<any>({});
    const [isFetchingAgain, setIsFetchingAgain] = useState(false);
    const [fetching, setFetching] = useState({
        plans: false,
        pos: false,
    });

    const stateManagers = {
        formData,
        setFormData,
        plans,
        setPlans,
        posByClients,
        setPosByClients,
        isButtonDisabled,
        setIsButtonDisabled,
        emailError,
        setEmailError,
        isLoading,
        setIsLoading,
        isSubmitting,
        setIsSubmitting,
        selectedPos,
        setSelectedPos,
        errors,
        setErrors,
        isFetchingAgain,
        setIsFetchingAgain,
        fetching,
    };

    const setFetchingState = (key, value) => {
        setFetching((prev) => ({ ...prev, [key]: value }));
    };

    // Validate Input
    const validateForm = () => {
        let newErrors: any = {};
        if (!formData.plan) newErrors.plan = 'Campo obligatorio';
        if (!formData.email) {
            newErrors.email = 'Campo obligatorio';
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
            newErrors.email = 'Correo electrónico inválido';
        }
        if (EX7454 && formData.pos.length === 0) newErrors.pos = 'Campo obligatorio';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // Submits form data to the API
    const handleSendFormData = async () => {
        if (!validateForm()) return;

        const payload = {
            client_id: query.clientId,
            contact_email: formData.email,
            ...(EX7454 && { point_of_sale_ids: formData.pos }), // Se agrega solo si EX7454 es true
        };

        setIsLoading(true);
        setIsSubmitting(true);

        try {
            await postPlansSubscriptions(formData.plan, payload);

            // Reset de formulario y actualización de estados
            setFormData({ plan: '', email: '', pos: [] });
            setSelectedPos([]);
            mutateClient();
            setIsFetchingAgain(true);
        } catch (error) {
            console.error('Error enviando los datos del formulario:', error?.message || error);
        } finally {
            setIsLoading(false);
        }
    };

    // Fetches subscription plans and pos from the API and formats them for the SelectPicker

    const fetchData = async (key, fetchFunction, setState, params = null) => {
        try {
            setFetchingState(key, true);
            const response = await fetchFunction(params);
            setState(response);
        } catch (ex) {
            console.error(`Error fetching ${key}:`, ex?.message || ex);
        } finally {
            setFetchingState(key, false);
        }
    };

    const getPlansSubscription = () => fetchData('plans', getPlansForSubscriptions, setPlans);
    const getPosSubscription = () => fetchData('pos', getPosForSubscriptions, setPosByClients, client?.id);

    // Fetches subscription plans and pos on component mount
    useEffect(() => {
        (async () => await getPlansSubscription())();
        (async () => await getPosSubscription())();
    }, []);

    useEffect(() => {
        if (isFetchingAgain) {
            (async () => await getPosSubscription())();
        }
        return () => {
            setIsFetchingAgain(false);
        };
    }, [isFetchingAgain]);

    return (
        <>
            <div className={style['container-form']}>
                {EX7454 && (
                    <>
                        <ClientFormOpenpay handleSendFormData={handleSendFormData} stateManagers={stateManagers} />
                    </>
                )}
            </div>
            {EX7454 && (
                <div className={style['container-button-send']}>
                    <ExButton
                        loading={isLoading}
                        disabled={posByClients.length === 0 || isLoading}
                        className={style['button']}
                        onClick={handleSendFormData}
                        style={{ width: EX7454 ? '10%' : '30%' }}
                    >
                        Enviar
                    </ExButton>
                </div>
            )}
            {EX7454 && <ClientTableOpenpay />}
        </>
    );
};

export default OpenpayFormDetails;
