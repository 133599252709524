import { getRoleById } from 'utils/string';

export const obtainRoleOfUser = (user) => {
    const userLocaleCountryCode = user?.client?.country_alfa_2;
    const type = getRoleById(user?.role_id);

    let role;

    if (userLocaleCountryCode === 'US') {
        return type;
    } else {
        switch (type) {
            case 'owner': {
                role = 'dueño';
                break;
            }
            case 'manager': {
                role = 'gerente';
                break;
            }
            case 'buyer': {
                role = 'comprador';
                break;
            }
            case 'admin': {
                role = 'admin';
                break;
            }
            case 'client': {
                role = 'cliente';
                break;
            }

            default:
                break;
        }
    }

    return role;
};
