import { apiPharmaInstance } from 'utils/axios';

// Función para enviar el pago
export const postSendPayment = async (payload) => {
    try {
        const response = await apiPharmaInstance.post('/api/openpay/subscribe', { ...payload });
        return response; // Asegúrate de devolver la respuesta
    } catch (err) {
        return err.response.data || err; // Devuelve un objeto con el error
    }
};

export const healthcheckHash = async (payload) => {
    try {
        const response = await apiPharmaInstance.post('/api/openpay/check', {...payload} );
        return response?.data;
    } catch (err) {
        return err.response.data || err;
    }
};
