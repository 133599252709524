import { InputPicker } from 'rsuite';
import { ExLoader } from '../ExLoader';
import styles from './FormInputPicker.module.css';

type TypeData = {
    value: string;
    label: string;
};

type FormInputPickerProps = {
    label: string;
    isRequired?: boolean;
    showOptionalLabel?: boolean;
    name: string;
    data: Array<TypeData>;
    value: string;
    onChange: (value: string) => void;
    onClick?: () => void;
    placeholder: string;
    showErrorIcon?: boolean;
    error: React.ReactNode | string | null;
    size: 'lg' | 'md' | 'sm';
    labelKey?: string;
    valueKey?: string;
    disabled?: boolean;
    clearInput?: boolean;
    showLoaderIconCaret?: boolean;
};

const FormInputPicker = ({
    label,
    isRequired,
    showOptionalLabel,
    name,
    data,
    value,
    onChange,
    onClick,
    placeholder,
    error,
    size,
    showErrorIcon,
    labelKey = 'label',
    valueKey = 'value',
    disabled,
    clearInput = false,
    showLoaderIconCaret = false,
}: FormInputPickerProps) => {
    return (
        <div className={styles['input-picker-container']}>
            <div>
                <label className={styles['input-picker-label']}>{label}</label>
                {isRequired && <span className={styles['input-picker-required']}>*</span>}
                {!isRequired && showOptionalLabel && <span className={styles['form-input-optional']}>(opcional)</span>}
            </div>
            <InputPicker
                id={`form-input-picker-${name}`}
                name={name}
                data={data}
                value={value}
                onChange={onChange}
                // placeholder={data?.length === 0 ? 'Cargando...' : <span id="placeholder">{placeholder}</span>}
                placeholder={<span id="placeholder">{placeholder}</span>}
                // disabled={disabled || data?.length === 0}
                disabled={disabled}
                size={size}
                className={error ? styles['input-picker-error'] : styles['input-picker']}
                labelKey={labelKey}
                valueKey={valueKey}
                cleanable={clearInput}
                onClick={onClick}
                caretAs={
                    showLoaderIconCaret
                        ? () => (
                              <div className={styles['form-loader-caret-container']}>
                                  <ExLoader size="sm" />
                              </div>
                          )
                        : null
                }
                renderMenu={(menu) => {
                    if (showLoaderIconCaret) {
                        return null;
                        // return (
                        //     <div className={styles['form-loader-container-skeleton']}>
                        //         <div className={styles['form-loader-option-skeleton']} />
                        //         <div className={styles['form-loader-option-skeleton']} />
                        //     </div>
                        // );
                    } else {
                        return menu;
                    }
                }}
            />
            <div className={styles['message-container']}>
                <span className={styles['form-error-message']}>
                    {showErrorIcon && <img src="/assets/images/error.svg" alt="icon error" width={16} height={16} />}
                    {error}
                </span>
            </div>
        </div>
    );
};

export default FormInputPicker;
