import { Button } from 'rsuite';
import style from './UploaderButton.module.css';
// mui v5
import { useTranslation } from 'react-i18next';
import { useUser } from 'utils/hooks';
import useChangeLanguage from 'utils/hooks/use-change-language';

const UploaderButton = ({ onClickVisibleDragDrop, isOpen }) => {
    const { t } = useTranslation();
    const { user } = useUser({});
    const userLocaleCountryCode = user?.client?.country_alfa_2;
    useChangeLanguage(userLocaleCountryCode);
    return (
        <div
            className={`button__upload-file-1 ${style['container-file-system-upload']}`}
            id="step-2"
            style={{ zIndex: isOpen ? 2100 : 0 }}
        >
            <Button
                size="sm"
                onClick={onClickVisibleDragDrop}
                className={`button__upload-file-1 ${style['button-icon-text']}`}
                startIcon={
                    <img src="/assets/images/cloud.svg" className={`button__upload-file-1 ${style['upload-icon']}`} />
                }
            >
                <p className={`button__upload-file-1 ${style['upload-text']}`}>
                    {t('shoppingCart.shoppingCart_Four')}
                </p>
            </Button>
        </div>
    );
};

export default UploaderButton;
