import { EX5057 } from 'config/flags';
import { t } from 'i18next';
// style
import style from './WithoutNotifications.module.css';

const WithoutNotifications = () => {
    return (
        <div className={style['container-body-popover']}>
            <div className={style['container-image-popover']}>
                <img src="/assets/images/notif.svg" />
            </div>
            <p className={style['text-body']}>{EX5057 ? t('header.header_notifyOne') : 'No tenés notificaciones'}</p>
        </div>
    );
};

export default WithoutNotifications;
