import { FC, ReactNode } from 'react';
// contexts
import { useDrugManufacturersContext, useExpandedFooterContext, useRecommendationsContext } from 'context';
import { useProductComparison } from 'context/product-comparison.context';
// styles
import styles from './Layout.module.css';

interface WrapperTableProps {
    children: ReactNode;
    isShowSomeAlert: boolean;
}

const WrapperTable: FC<WrapperTableProps> = ({ children, isShowSomeAlert }) => {
    const { expandedFooter } = useExpandedFooterContext();
    const { drugManufacturer } = useDrugManufacturersContext();
    const { recommendations } = useProductComparison();
    const { showRecommendations } = useRecommendationsContext();

    return (
        <div
            style={{
                display: drugManufacturer?.length === 0 ? 'none' : '',
                padding: '0px 19px',
                gridRow: isShowSomeAlert ? 4 : 3,
            }}
            className={`${
                !expandedFooter
                    ? styles[
                          !showRecommendations || recommendations?.length === 0
                              ? 'layout-table-without-recommendations-v2'
                              : 'layout-table-with-recommendations-v2'
                      ]
                    : styles[
                          !showRecommendations || recommendations?.length === 0
                              ? 'layout-table-without-recommendations-expanded-footer-v2'
                              : 'layout-table-with-recommendations-expanded-footer-v2'
                      ]
            }`}
        >
            {children}
        </div>
    );
};

export default WrapperTable;
