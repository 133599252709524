// style
import style from './LineDivider.module.css';

const LineDivider = () => {
  return (
    <div className={style['line-divider']}>
      <hr />
    </div>
  );
};

export default LineDivider;
