import { useEffect, useRef, useState } from 'react';
import { t } from 'i18next';
// configs
import { EX6561 } from 'config/flags';
// components
import { alertIcon } from '@commons/modules/EfficientPurchase/OrderSentNew/List/Item/icons';
// utils && hooks
import { EErrorCode } from 'utils/enums/comparativeAlerts/comparativeAlerts';

const useTooltipError = ({ typeError }) => {
    const alertIconRef = useRef(null);
    const [textError, setTextError] = useState<string | null>('insufficientCredit.label.viewErrorReason');
    const [textTootip, setTextTooltip] = useState<JSX.Element | null>(null);
    const [color, setColor] = useState<string | null>('#E22E2E');
    const [icon, setIcon] = useState<JSX.Element | null>(alertIcon);

    /* #region listener to setter text, icon and colors */
    useEffect(() => {
        if (EX6561) {
            if (!!typeError) {
                if (typeError?.cause?.id === EErrorCode.CREDIT_LIMIT_REACHED) {
                    setTextTooltip(
                        <p>
                            {t('efficientPurchasing.errorSuccessOrder.errorReason')}
                            <br />
                            {t('insufficientCredit.tooltip.drugstoreErrorInsufficientCredit')}
                        </p>,
                    );
                } else {
                    setTextTooltip(
                        <p>
                            {t('efficientPurchasing.errorSuccessOrder.errorReason')}
                            <br />
                            {typeError?.user_message}
                        </p>,
                    );
                }
            }
        } else {
            if (typeError !== null && typeError !== undefined) {
                switch (typeError) {
                    case EErrorCode.CREDIT_LIMIT_REACHED:
                        setTextError('insufficientCredit.label.viewErrorReason');
                        setTextTooltip(
                            <p>
                                {t('efficientPurchasing.errorSuccessOrder.errorReason')}
                                <br />
                                {t('insufficientCredit.tooltip.drugstoreErrorInsufficientCredit')}
                            </p>,
                        );
                        setColor('#E22E2E');
                        setIcon(alertIcon);
                        break;
                    default:
                        setTextError(null);
                        setTextTooltip(null);
                        setColor(null);
                        setIcon(null);
                        break;
                }
            }
        }
    }, [typeError]);
    /* #endregion */

    /* #region listener hidden tooltip when scrolling */
    useEffect(() => {
        const checkScroll = () => {
            if (alertIconRef?.current) {
                alertIconRef.current.close();
            }
        };

        const contentElement = document?.getElementById('content-id');
        if (contentElement) {
            contentElement?.addEventListener('scroll', checkScroll);
            return () => {
                contentElement?.removeEventListener('scroll', checkScroll);
            };
        }
    }, [alertIconRef]);
    /* #endregion */

    return {
        alertIconRef,
        textError,
        textTootip,
        color,
        icon,
    };
};

export default useTooltipError;
