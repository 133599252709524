import { useUser } from 'utils/hooks';

export const useMoreThanOne = () => {
    const { user } = useUser({});

    const EX5749 = user?.EX5749;
    const EX6157 = process.env.NEXT_PUBLIC_EX6157 === 'true';
    const drugManufacturerStatusListLS = JSON.parse(localStorage.getItem('drugManufacturerStatusList') || '[]')
    const drugManufacturerStatus = drugManufacturerStatusListLS;
    const readyItems = EX5749 ? drugManufacturerStatus?.filter((item) => item.responseStatus === 'ready') : [];
    const ItsmorethanOne = EX5749 && readyItems?.length === 1;

    return {
        EX5749,
        EX6157,
        ItsmorethanOne,
    };
};
