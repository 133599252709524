import React from 'react';
// COMPONENTS
import { ImageBarcode } from './ImageBarcode';
import { ProductDescription } from './ProductDescription';
import { AddButton } from './AddButton';
import { LimitMessage } from './LimitMessage';
// STYLES
import Animations from '../Animations.module.css';
import styles from './RecommendedCellProduct.module.css';

const RecommendedCellProduct = ({
    recommendation,
    fadeOutRecommended,
    recommendationLimit,
    addRecommendation,
    drugManufacturer,
    skeleton,
    containerRef,
}): JSX.Element => {
    return (
        <div
            className={`${fadeOutRecommended ? Animations['fade-out'] : ''} ${
                recommendationLimit && styles['without-border']
            } ${styles['cell']}`}
            style={{
                borderRight: recommendationLimit ? '' : '0.5px solid #FFCDAD',
            }}
        >
            {!recommendationLimit ? (
                <>
                    <ImageBarcode skeleton={skeleton} recommendation={recommendation} />
                    <ProductDescription
                        skeleton={skeleton}
                        recommendation={recommendation}
                        containerRef={containerRef}
                    />
                    <AddButton
                        skeleton={skeleton}
                        addRecommendation={addRecommendation}
                        recommendation={recommendation}
                        drugManufacturer={drugManufacturer}
                    />
                </>
            ) : (
                <LimitMessage />
            )}
        </div>
    );
};

export default RecommendedCellProduct;
