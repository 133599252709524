// component
import { RsuiteTooltip } from '@commons/components';
// rsuite
import HelpOutlineIcon from '@rsuite/icons/HelpOutline';
// style
import style from './DeliveryNumber.module.css';
// interface
import { IDeliveryNumber } from './DeliveryNumber.interface';

const DeliveryNumber = ({
    text,
    label,
    hash,
    containerClassName,
    labelClassName,
    deliveryNumberClassName,
    location
}: IDeliveryNumber) => {
    return (
        <>
            <div className={containerClassName} /* className={style['delivery-number-container']} */>
                <RsuiteTooltip
                    text={text}
                    width="200px"
                    minWidth="0px"
                    marginTop={10}
                    placement="bottom"
                    trigger="hover"
                >
                    <HelpOutlineIcon />
                </RsuiteTooltip>
                <p className={labelClassName}>{label}</p> {!!location && <span>{hash}</span>}
            </div>
            {
                !location && (

                    <div className={deliveryNumberClassName}>
                        <p >{hash}</p>
                    </div>
                )
            }
        </>
    );
};
//
export default DeliveryNumber;
