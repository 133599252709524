import React from 'react';
import useCurrencyFormatter from 'utils/hooks/useCurrencyFormatter/useCurrencyFormatter';

type Props = {
    value: number | string;
    fontSize?: number;
    decimalScaleDownFactor?: number;
}

const FormattedCurrency = ({value, fontSize = 20, decimalScaleDownFactor = 0.75} : Props) => {
    const {formatCurrency} = useCurrencyFormatter();

    const currencyParts = formatCurrency({value}).currencyParts
    const decimalIndex = currencyParts.findIndex(part => part.type === 'decimal');
    const pastDecimalSize = fontSize * decimalScaleDownFactor;

    return <span>
        {currencyParts.map(((part, index) => {
            const isPastDecimal = decimalIndex !== -1 && index > decimalIndex;
            
            return <span style={{fontSize: isPastDecimal ? pastDecimalSize : fontSize}} key={index}>{part.value}</span>
        }))}
    </span>

}

export default FormattedCurrency;