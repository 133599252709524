import { useMemo } from 'react';
// CONTEXTS
import { useProductComparison } from 'context/product-comparison.context';
// COMPONENTS
import { TableSaving } from './TableSaving';
import { TableTotals } from './TableTotals';
import { ModalMinimunAmount } from '@commons/components/Modals';
import { ModalMinMaxQuantity } from '@commons/components/Modals/ModalMinMaxQuantity';
// UTILS
import { StatusCdo } from 'utils/interfaces';
import { useUser } from 'utils/hooks';
// HOOKS
import { useCDOStatus, useParseIdToNameDrugManufacturer } from '@commons/modules/TableProductComparisonV2/hooks';
import { useRecommendationsContext } from 'context/productComparison/recommendations-context';
import {
    UnitsProvider,
    useDrugManufacturersContext,
    useFetchingContext,
    useMinimunPurchaseContext,
    useModalMinimunQuantityContext,
} from 'context';

const TableFooter = () => {
    const {
        //@ts-ignore
        unselectedProductNotificationVisibility,
        orderId,
        recommendations,
    } = useProductComparison();

    const { showModalMinMaxQuantity, handlerModalMinMaxQuantity } = useModalMinimunQuantityContext();

    const { loading } = useFetchingContext();

    const { drugManufacturer, isEnabledDrugManufacturer } = useDrugManufacturersContext();

    const {
        handlerModalMinimunPurchase,
        minimunPurchase,
        addMinimunPurchase,
        removeMinimunPurchase,
    } = useMinimunPurchaseContext();

    const { parseIdToNameDrugManufacturer: uncheckedProductsByDrugNamesMinOrMax } = useParseIdToNameDrugManufacturer();

    const { user, userLocaleCountryCode } = useUser({});

    const { CDO_IS_VISIBLE, CDO_STATUS_BY_POS } = useCDOStatus();

    const { showRecommendations } = useRecommendationsContext();

    const WITHOUT_RECOMMENDATIONS = useMemo(() => {
        return !showRecommendations || recommendations?.length === 0;
    }, [showRecommendations, recommendations]);

    return (
        <>
            <ModalMinMaxQuantity open={showModalMinMaxQuantity} onClose={() => handlerModalMinMaxQuantity()} />
            <ModalMinimunAmount />
            <UnitsProvider>
                <TableTotals
                    drugManufacturer={drugManufacturer}
                    cdo_is_visible={CDO_IS_VISIBLE}
                    skeleton={loading}
                    withoutRecommendations={WITHOUT_RECOMMENDATIONS}
                    userLocaleCountryCode={userLocaleCountryCode}
                    cdoStatusByPOS={CDO_STATUS_BY_POS}
                    cdo_is_enabled={CDO_STATUS_BY_POS === StatusCdo.ENABLED}
                    isEnabledDrugManufacturer={isEnabledDrugManufacturer}
                    user={user}
                    addMinimunPurchase={addMinimunPurchase}
                    removeMinimunPurchase={removeMinimunPurchase}
                />
                <TableSaving
                    skeleton={loading}
                    orderId={orderId}
                    minimunPurchase={minimunPurchase}
                    handlerModalMinimunPurchase={handlerModalMinimunPurchase}
                    unselectedProductNotificationVisibility={unselectedProductNotificationVisibility}
                    withoutRecommendations={WITHOUT_RECOMMENDATIONS}
                    uncheckedProductsByDrugNamesMinOrMax={uncheckedProductsByDrugNamesMinOrMax}
                    handlerModalMinMaxQuantity={handlerModalMinMaxQuantity}
                />
            </UnitsProvider>
        </>
    );
};

export default TableFooter;
