import React, { memo } from 'react';
import { t } from 'i18next';
// components
import { PasswordConditionsText } from '@commons/components/PasswordConditionsText';
import { PasswordConditionsWithItems } from '@commons/components/PasswordConditionsWithItems';
import { ExButton, FormInput } from '@commons/EXComponentsLibrary';
import { Form, Modal } from 'rsuite';
// utils && hooks
import { useModalChangePassword } from './hooks';
import { LockIcon, LockIconSvg } from './utils/Icons';
// styles
import styles from './ModalChangePasswordV2.module.css';

const ModalChangePassowrd = ({ modal }) => {
    const {
        formState,
        updateFormField,
        onSubmit,
        handleNewPasswordCallback,
        handlePasswordCallback,
        handleRepeatPasswordCallback,
        loading,
    } = useModalChangePassword({ modal });

    return (
        <Modal
            backdrop={true}
            keyboard={false}
            open={modal.visibility}
            onClose={() => (!!modal.visibility ? modal.toggle() : () => {})}
            className={styles['container']}
        >
            <div className={styles['content']}>
                {/* header */}
                <div className={styles['header']}>
                    <LockIconSvg />
                    <div className={styles['title']}>{t('home.changePassword1')}</div>
                </div>
                {/* form */}
                <Form fluid className={styles['form']} formValue={formState} onSubmit={onSubmit}>
                    <div>
                        {formState?.map((item: any, idx: number) => {
                            return (
                                <div key={idx + 1} className={`${styles['input']} ${styles['box-password']}`}>
                                    <FormInput
                                        label={item.translate}
                                        placeholder={`${item.placeholder}`}
                                        isRequired={true}
                                        type={item.showPassword ? 'text' : 'password'}
                                        startIcon={<LockIcon />}
                                        showStartIcon
                                        maxWidth
                                        autoComplete={'off'}
                                        showErrorIcon={false}
                                        error={null}
                                        name={item?.name}
                                        customStyleStartIcon={{ backgroundColor: '#FFF', marginRight: 0 }}
                                        onChange={(event) => updateFormField(item.name, 'value', event)}
                                        applyBorderErrorStyle={item.error}
                                        onEyeClick={() =>
                                            updateFormField(item.name, 'showPassword', !item.showPassword)
                                        }
                                        onFocus={() => {
                                            if (item.name === 'newPassword') {
                                                updateFormField(item.name, 'focus', true);
                                                if (!item.complete) {
                                                    updateFormField(item.name, 'error', true);
                                                }
                                            }
                                        }}
                                    />
                                    {/* password error input*/}
                                    {item?.name === 'password' && (
                                        <PasswordConditionsText
                                            textString={t('home.changePassword8')}
                                            showError={item.error}
                                            password={item.value}
                                            callbackValidation={handlePasswordCallback}
                                            isRepeatNewPassword={false}
                                        />
                                    )}
                                    {/* newPassword conditions only new password input*/}
                                    {item?.name === 'newPassword' && (
                                        <PasswordConditionsWithItems
                                            password={item.value}
                                            callbackValidation={handleNewPasswordCallback}
                                            show={item.error}
                                        />
                                    )}
                                    {/* password error input*/}
                                    {item?.name === 'repeatNewPassword' && (
                                        <PasswordConditionsText
                                            textString={t('home.changePassword9')}
                                            showError={item.error}
                                            password={item.value}
                                            callbackValidation={handleRepeatPasswordCallback}
                                            isRepeatNewPassword={true}
                                            newPassword={formState[1]?.value}
                                            repeatNewPassword={item.value}
                                        />
                                    )}
                                </div>
                            );
                        })}
                    </div>
                    <ExButton type="submit" status="initial" fullWidth size="lg" loading={loading}>
                        {t('home.changePassword6')}
                    </ExButton>
                </Form>
            </div>
            {/* footer buttons */}
            <ExButton
                type="button"
                status="initial"
                fullWidth
                size="sm"
                loading={false}
                className={`${styles['cancel-button']}`}
                appearance={'link'}
                onClick={() => modal.toggle()}
            >
                {t('home.changePassword5')}
            </ExButton>
        </Modal>
    );
};

export default memo(ModalChangePassowrd);
