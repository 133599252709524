// component
import { OrderCards } from 'commons/components';

export const EmptyOrderCard = () => {
  const emptyOrderCards = Array.from({ length: 10 }, (_, idx) => {
    return (
      <OrderCards
        key={idx}
        dateTime={''}
        orderNumber={''}
        drugmanufactur={''}
        value={''}
        avatar={''}
        noMedicinals={''}
        referencePointOfSale={null}
        client={''}
        selectorList={''}
        drugmanufacturers={[]}
        imageOneDrugmanufacturer={''}
        oneDrugmanufacturerName={''}
        timerSkeleton={true}
        initialLettersDrugmanufName=''
        orderNumberByDrugmanufact=''
      />
    );
  });
  return <>{emptyOrderCards}</>
};

export default EmptyOrderCard;
