export const getProductSelectedByDrugManufacturerId = (
    id: string | number,
    products: any[],
    EX5937: boolean,
): any[] => {
    return (
        products?.filter(({ drugManufacturerIdSelected }) => {
            if (EX5937) {
                if (Array.isArray(drugManufacturerIdSelected)) {
                    return drugManufacturerIdSelected?.includes(id);
                }
                return drugManufacturerIdSelected === id;
            } else {
                return drugManufacturerIdSelected === id;
            }
        }) || []
    );
};
