import Link from 'next/link';
import { t } from 'i18next';
// components
import { Form } from 'rsuite';
import { ExButton, FormInput } from '@commons/EXComponentsLibrary';
import { PasswordConditionsWithMatching } from '@commons/components/PasswordConditionsWIthMatching';
// interfaces
import { IFormRecoveryPasswordProps } from './FormRecoveryPassword.interface';
// utils & hooks
import { useShowPassword } from '../../hooks';
// styles
import styles from './FormRecoveryPassword.module.css';

const FormRecoveryPassword = ({
    formState,
    onSubmit,
    loading,
    updateFormState,
    success,
    handleNewPasswordCallback,
    runValidations,
}: IFormRecoveryPasswordProps) => {
    return (
        <div className={styles['form-container']}>
            <div className={styles['box-description']}>
                {success && (
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                        <path
                            d="M11.1 14.4914L8.95 12.3414C8.76667 12.1581 8.53333 12.0664 8.25 12.0664C7.96667 12.0664 7.73333 12.1581 7.55 12.3414C7.36667 12.5247 7.275 12.7581 7.275 13.0414C7.275 13.3247 7.36667 13.5581 7.55 13.7414L10.4 16.5914C10.6 16.7914 10.8333 16.8914 11.1 16.8914C11.3667 16.8914 11.6 16.7914 11.8 16.5914L17.45 10.9414C17.6333 10.7581 17.725 10.5247 17.725 10.2414C17.725 9.95807 17.6333 9.72474 17.45 9.54141C17.2667 9.35807 17.0333 9.26641 16.75 9.26641C16.4667 9.26641 16.2333 9.35807 16.05 9.54141L11.1 14.4914ZM12.5 22.6914C11.1167 22.6914 9.81667 22.4289 8.6 21.9039C7.38333 21.3789 6.325 20.6664 5.425 19.7664C4.525 18.8664 3.8125 17.8081 3.2875 16.5914C2.7625 15.3747 2.5 14.0747 2.5 12.6914C2.5 11.3081 2.7625 10.0081 3.2875 8.79141C3.8125 7.57474 4.525 6.51641 5.425 5.61641C6.325 4.71641 7.38333 4.00391 8.6 3.47891C9.81667 2.95391 11.1167 2.69141 12.5 2.69141C13.8833 2.69141 15.1833 2.95391 16.4 3.47891C17.6167 4.00391 18.675 4.71641 19.575 5.61641C20.475 6.51641 21.1875 7.57474 21.7125 8.79141C22.2375 10.0081 22.5 11.3081 22.5 12.6914C22.5 14.0747 22.2375 15.3747 21.7125 16.5914C21.1875 17.8081 20.475 18.8664 19.575 19.7664C18.675 20.6664 17.6167 21.3789 16.4 21.9039C15.1833 22.4289 13.8833 22.6914 12.5 22.6914ZM12.5 20.6914C14.7333 20.6914 16.625 19.9164 18.175 18.3664C19.725 16.8164 20.5 14.9247 20.5 12.6914C20.5 10.4581 19.725 8.56641 18.175 7.01641C16.625 5.46641 14.7333 4.69141 12.5 4.69141C10.2667 4.69141 8.375 5.46641 6.825 7.01641C5.275 8.56641 4.5 10.4581 4.5 12.6914C4.5 14.9247 5.275 16.8164 6.825 18.3664C8.375 19.9164 10.2667 20.6914 12.5 20.6914Z"
                            fill="#07AD4B"
                        />
                    </svg>
                )}
                <p className={styles['title']}>{!success ? t('login.login7') : t('login.login42')}</p>
            </div>
            {!success && (
                <Form fluid className={styles['form']} formValue={formState} onSubmit={onSubmit}>
                    <div>
                        {formState?.map((item) => {
                            const { showPassword, handleShowPassword } = useShowPassword();
                            return (
                                <div className={`${styles['input']} ${styles['box-password']}`}>
                                    <FormInput
                                        label={item?.translate}
                                        placeholder={item?.placeholder}
                                        isRequired={true}
                                        type={showPassword ? 'text' : 'password'}
                                        /* startIcon={<img src="/assets/images/mail.png" />} */
                                        /* showStartIcon */
                                        maxWidth
                                        autoComplete={'on'}
                                        showErrorIcon={false}
                                        error={
                                            item?.name === 'repeatNewPassword'
                                                ? formState[0]?.value?.trim() !== formState[1]?.value?.trim() &&
                                                  formState[1]?.value?.trim()?.length
                                                : item?.error
                                        }
                                        name={item?.name}
                                        customStyleStartIcon={{ backgroundColor: '#FFF', marginRight: 0 }}
                                        onChange={(event) => updateFormState(item?.name, 'value', event)}
                                        onEyeClick={() => handleShowPassword()}
                                    />
                                    {item?.name === 'newPassword' && (
                                        <PasswordConditionsWithMatching
                                            runValidations={runValidations}
                                            password={item?.value}
                                            repeatPassword={formState[1]?.value}
                                            callbackValidation={handleNewPasswordCallback}
                                        />
                                    )}
                                </div>
                            );
                        })}
                    </div>
                    <ExButton
                        type="submit"
                        status="initial"
                        withArrow={true}
                        fullWidth
                        size="lg"
                        loading={loading}
                        className={'button__password-recovery-1'}
                    >
                        {t('login.login40')}
                    </ExButton>
                    {!success && (
                        <Link href={'/login'} className={styles['redirect-login']}>
                            <p>{t('login.login41')}</p>
                        </Link>
                    )}
                </Form>
            )}
        </div>
    );
};

export default FormRecoveryPassword;
