import React, { memo } from 'react';
import { t } from 'i18next';
// COMPONENTS
import { CellExpanded } from './CellExpanded';
import { Percentage } from './Percentage';
// UTILS
import { percent } from '@commons/components/TableComparisonV2/TableFooter/utils';
// STYLES
import styles from '../../../TableTotals.module.css';
import { CellContracted } from './CellContracted';
import { EDrugManufacturerTypeId } from 'utils/enums/drugmanufacturerTypeId/DrugManufacturerTypeId';
import { auxiliary } from './utils/auxiliary.utils';

const CellPrice = ({
    expandedFooter,
    hasDrugManufacturerError,
    skeleton,
    userLocaleCountryCode,
    drugManufacturerEnabled,
    min_purchase,
    user,
    drugManufacturerId,
    productQuantityByDrugManufacturer,
    quantityUnitsByDrugManufacturer,
    subTotalValueColumn,
    subtotal,
    drugManufacturerTypeId,
    vendor_accepted,
    enabled = false
}) => {
    // aux
    const auxFx = auxiliary();

    const classNameStyle = auxFx.handleChangeClassNameStyles(styles, drugManufacturerTypeId, expandedFooter);


    return (
        <div
            className={
                user?.EX6017
                    ? classNameStyle
                    : `${expandedFooter ? styles['cell-price-expanded'] : styles['cell-price']}`
            }
        >
            <p className={`${expandedFooter ? '' : styles['total']}`}>
                {expandedFooter && !hasDrugManufacturerError ? (
                    <CellExpanded
                        user={user}
                        min_purchase={min_purchase}
                        subTotalValueColumn={subTotalValueColumn}
                        userLocaleCountryCode={userLocaleCountryCode}
                        skeleton={skeleton}
                        drugManufacturerEnabled={enabled}
                        productQuantityByDrugManufacturer={productQuantityByDrugManufacturer(drugManufacturerId)}
                        quantityUnitsByDrugManufacturer={quantityUnitsByDrugManufacturer(drugManufacturerId)}
                        t={t}
                        drugManufacturerTypeId={drugManufacturerTypeId}
                        drugManufacturerId = { drugManufacturerId }
                        vendor_accepted={vendor_accepted}
                    />
                ) : (
                    !hasDrugManufacturerError && (
                        <CellContracted
                            user={user}
                            min_purchase={min_purchase}
                            subTotalValueColumn={subTotalValueColumn}
                            skeleton={skeleton}
                            userLocaleCountryCode={userLocaleCountryCode}
                            drugManufacturerEnabled={enabled}
                            t={t}
                            drugManufacturerTypeId={drugManufacturerTypeId}
                            drugManufacturerId = { drugManufacturerId }
                            vendor_accepted={vendor_accepted}
                            productQuantityByDrugManufacturer={productQuantityByDrugManufacturer(drugManufacturerId)}
                        />
                    )
                )}
            </p>
            {expandedFooter &&
                !hasDrugManufacturerError &&
                drugManufacturerTypeId !== EDrugManufacturerTypeId.vendor && (
                    <Percentage
                        skeleton={skeleton}
                        drugManufacturerEnabled={enabled}
                        percent={percent(t, subTotalValueColumn, subtotal)}
                        productQuantityByDrugManufacturer={productQuantityByDrugManufacturer(drugManufacturerId)}
                    />
                )}
        </div>
    );
};

export default memo(CellPrice);
