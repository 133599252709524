import { EX5057 } from 'config/flags'
import style from './PresentationCard.module.css'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { useTranslation } from 'react-i18next';

function PresentationCard({ continuous, index, step, backProps, closeProps, primaryProps, tooltipProps }) {
    const { t } = useTranslation();
    return (
        <>
            <div {...tooltipProps} className={style['panel-container']}>
                <div className={style['element-a']}>
                    <img
                        src={'/assets/images/IsologoHorizontal.svg'}
                        alt="IsologoHorizontal"
                        className={style['icon']}
                    />
                </div>

                {step.title && <p className={style['title']}>{step.title}</p>}
                <p className={style['content']}>{step.content}</p>

                {continuous && (
                    <button {...primaryProps} className={`button__welcome-start ${style['button-start']}`} id="next">
                        {!!EX5057 ? t('walkthrough.labelBegin') : 'Comenzar'} <ArrowForwardIcon className={`button__welcome-start ${style['arrow']}`} />
                    </button>
                )}
            </div>
            <button {...closeProps} className={`link__welcome-not-now ${style['close-button']}`}>
                {!!EX5057 ? t('walkthrough.labelNotNow') : 'Ahora no'}
            </button>
        </>
    )
}

export default PresentationCard
