import { useRouter } from 'next/router';
// configs
import { EX4503, EX4562 } from 'config/flags';
// contexts
import {
    useDrugManufacturersContext,
    usePointOfSale,
    useSelectAllStateContext,
    useUpdateParentProductContext,
} from 'context';
import { useCredentialErrors } from 'context/CredentialsErrors/CredentialsErrors';
import { useModalCdO } from 'context/modalCdO/modalCdO';
// apis
import { updateProductGroup } from 'api';
import { useState, useEffect, useCallback, useRef } from 'react';
// utils && hooks
import { DrugManufacturersId, StatusCdo } from 'utils/interfaces';
import { OfferCenters } from 'utils/enums';
import { ServiceId } from 'common/types';
import { isEmptyObjectAtrribute } from 'utils/object';
import { isZettiFormat } from 'utils/pointOfSale';
import { enabledButtonMakeOrder } from '@commons/components/TableComparisonV2/utils/changeStateButtonMakeOrder';
import { useUser } from 'utils/hooks';

export const useTableRowGeneric = ({
    item,
    drugManufacturer,
    orderProductNonGenericId,
    getCreditById,
    setProductInfo,
    openModalWithoutCredit,
    setTypeModalWithoutCredit,
    setGenericsIdsAdd,
}) => {
    const { setUpdateParentProduct } = useUpdateParentProductContext();
    const { selectAllState } = useSelectAllStateContext();
    const { enabledOnOff, drugManufacturer: drugManufacturerContext } = useDrugManufacturersContext();

    const { pointOfSale: pos } = usePointOfSale();
    const isZetti = isZettiFormat(pos);

    const { user } = useUser({});
    const EX4903 = user?.EX4903;
    const EX4904 = user?.EX4904;
    const EX4927 = user?.EX4927;
    const EX4800 = user?.EX4800 && !isZetti; // ff generics
    const EX5223 = user?.EX5223; //ff pvp render in discount
    const EX5506 = user?.EX5506; //FF epic insufficientCredit V2
    const EX5937 = user?.EX5937; //FF split stock

    const router = useRouter();

    const [productGroup, setProductGroup] = useState<any>(() => item as any);

    const [isManualSelected, setIsManualSelected] = useState(false);

    const [isAppliedIndex, setIsAppliedIndex] = useState([]);

    const IdIncludeInSuggested = (id: number): boolean => {
        if (EX5937 && Array.isArray(productGroup?.suggested)) {
            return productGroup?.suggested?.includes(id);
        } else {
            return productGroup?.suggested === id;
        }
    };

    const handleCheckboxOnChange = async (quantity) => {
        let res = null;
        try {
            const itemCheckbox = checkbox.find((item) => item.isChecked === true);
            const priceItemSelected = productGroup?.products[itemCheckbox?.id]?.quantityDiscountApplies
                ? productGroup?.products[itemCheckbox?.id]?.discount?.price * quantity
                : productGroup?.products[itemCheckbox?.id]?.price * quantity;

            const product = {
                barcode: productGroup?.barcode,
                amountSelected: priceItemSelected,
            };

            const validation = EX5506 || getCreditById(itemCheckbox?.id, product);

            if (validation || !EX4903) {
                let { data } = await updateProductGroup(router.query.orderId, {
                    barcode: productGroup.barcode,
                    quantity,
                });
                setProductGroup(data?.data);
                res = true;

                // sent order_product_id from parent and order_product_id from generic with their quantities.
                setUpdateParentProduct((prevState) => {
                    const exists = prevState?.some(
                        (item) => item.orderProductIdGeneric === productGroup?.order_product_id,
                    );
                    if (exists) {
                        // Si existe, mapear y actualizar la cantidad
                        return prevState.map((item) =>
                            item.orderProductIdGeneric === productGroup?.order_product_id
                                ? { ...item, quantity }
                                : item,
                        );
                    } else {
                        // Si no existe, agregar el nuevo objeto
                        return [
                            ...prevState,
                            {
                                orderProductIdParent: orderProductNonGenericId,
                                orderProductIdGeneric: productGroup?.order_product_id,
                                quantity: quantity,
                            },
                        ];
                    }
                });
            } else {
                const name = drugManufacturerContext.find(
                    (drugManufacturer) => drugManufacturer?.id === itemCheckbox?.id,
                )?.name;

                setTypeModalWithoutCredit('');
                openModalWithoutCredit(name);
                res = false;
            }
        } catch (error) {
            const errorMessage = error?.response?.data?.data?.validation?.quantity[0];
            if (errorMessage.match(/ZERO_QUANTITY/)) {
                let itemZero = item as any;
                itemZero.quantity = quantity;
                Object.keys(itemZero.products).map((drugManufacturerId) => {
                    if (itemZero.products[drugManufacturerId]?.total) {
                        itemZero.products[drugManufacturerId].total = 0;
                    }
                });
                setProductGroup(itemZero as any);
            }
        } finally {
            enabledButtonMakeOrder();
        }
        return res;
    };

    const hasDrugManufacturerError = (id: number) => {
        if (EX4904) {
            return extractedDataErrorIds?.includes(id);
        } else {
            return false;
        }
    };

    const checkBonus = (bonusArray, productQuantity) => {
        let leftovers = productQuantity;
        let appliedBonuses = [];

        bonusArray?.forEach((bonus) => {
            if (leftovers >= bonus?.paid) {
                let freeUnits = Math.floor(leftovers / bonus.paid) * bonus.free;
                leftovers %= bonus.paid;

                if (freeUnits > 0) {
                    appliedBonuses.push({
                        paid: bonus.paid,
                        free: bonus.free,
                        gift: freeUnits,
                        apply: true,
                    });
                }
            }
        });

        // Añadimos esta verificación por si no se aplica ningún bonus
        if (appliedBonuses.length === 0) {
            appliedBonuses.push({
                gift: 0,
                apply: false,
            });
        }

        return appliedBonuses;
    };

    const bonus = useCallback(
        (id: number) => {
            const product = productItemByDrugmanufacturer(id) || {};
            if ('bonus' in product && product?.bonus) {
                // Normalizar el array para consolidar elementos duplicados
                const normalizedArray = [];
                (product?.bonus as Array<any>).forEach((item) => {
                    if (!normalizedArray.some((element) => element.paid === item.paid && element.free === item.free)) {
                        normalizedArray.push(item);
                    }
                });
                // Ordenar el array por 'paid' en orden descendente
                normalizedArray.sort((a, b) => b.paid - a.paid);

                return normalizedArray;
            }
            return [];
        },
        [productGroup],
    );

    const { openModalCdo } = useModalCdO();

    const currentStatusCdO = drugManufacturer?.find((drug) => drug?.id === DrugManufacturersId.CentralDeOfertas)
        ?.cdo_status_id;

    const { extractedDataError } = useCredentialErrors();
    const extractedDataErrorIds = extractedDataError?.map((item: any) => item.id);

    useEffect(() => {
        if (!!productGroup) {
            const bonusIsApplied = checkBonus(bonus(productGroup.drugManufacturerIdSelected), productGroup.quantity);
            if (bonusIsApplied) {
                setIsAppliedIndex(bonusIsApplied);
            }
        }
    }, []);

    const orderFill = useCallback(() => productGroup?.selectedProduct, [productGroup]);

    const productItemByDrugmanufacturer = useCallback(
        (drugManufacturerId, isGeneric = false, cellGenerics = {}): any => {
            if (!productGroup?.products) {
                return {} as any;
            } else if (isGeneric) {
                return cellGenerics;
            }
            return productGroup?.products[drugManufacturerId];
        },
        [productGroup],
    );

    const unitPrice = useCallback(
        (id, isGenerics = false, cellGenerics = {}) => {
            const { quantityDiscountApplies, discount, price } =
                productItemByDrugmanufacturer(id, isGenerics, cellGenerics) || {};

            if (isGenerics && !!cellGenerics?.quantityDiscountApplies) {
                cellGenerics?.discount?.price;
            } else {
                cellGenerics?.price;
            }

            if (quantityDiscountApplies) {
                return discount?.price;
            } else {
                return price;
            }
        },
        [productGroup],
    );

    const pointOfSale = useCallback(() => {
        return user?.pointsOfSale?.find((item) => item?.id === +router?.query?.posId);
    }, [user]);

    const lastUnitPrice = (id, isGenerics = false, cellGenerics = null) => {
        if (EX5223) {
            if (productGroup?.pvp && !isGenerics) {
                return productGroup?.pvp;
            } else {
                return productItemByDrugmanufacturer(id, isGenerics, cellGenerics)?.price;
            }
        } else {
            return productItemByDrugmanufacturer(id, isGenerics)?.price;
        }
    };

    const totalPrice = (id) => productItemByDrugmanufacturer(id)?.total;

    const isBestPrice = useCallback((id) => !!productGroup?.['lowest_without_cdo']?.find((item) => item === id), [
        productGroup,
    ]);

    const productGroupHasTolerance = useCallback(() => Boolean(productGroup?.bestByTolerance), [productGroup]);

    const bestPriceQuantity = useCallback(() => productGroup?.['lowest_without_cdo']?.length || 0, [productGroup]);

    const productsIds = useCallback(
        () =>
            Object.entries(productGroup?.products || {})
                ?.map(([key, value]) => {
                    if (!value) return null;
                    return parseInt(key);
                })
                ?.filter((i) => i),
        [productGroup],
    );

    const productSelectedCondition = useCallback(
        (id) => {
            if (EX5937) {
                return (
                    orderFill()?.drug_manufacturer_id === id ||
                    (!Boolean(orderFill()?.drug_manufacturer_id) && IdIncludeInSuggested(id))
                );
            } else {
                return orderFill()?.drug_manufacturer_id === id
                    ? true
                    : !Boolean(orderFill()?.drug_manufacturer_id) && productGroup?.suggested === id;
            }
        },
        [productGroup],
    );

    const [checkbox, setCheckbox] = useState<{ id: number; isChecked: boolean }[]>(() =>
        drugManufacturer?.map(({ id }) => ({
            id,
            isChecked: productSelectedCondition(id),
        })),
    );

    const [helper, setHelper] = useState(0);

    const updateCheckbox = (drugManufacturerId) => {
        const product = {
            barcode: productGroup?.barcode,
            amountSelected: productGroup?.products[drugManufacturerId]?.total,
        };
        const res = EX5506 || getCreditById(parseInt(drugManufacturerId), product);

        if (res || !EX4903) {
            if (
                +drugManufacturerId === DrugManufacturersId.CentralDeOfertas &&
                drugManufacturer.at(-1)?.cdo_status_id !== StatusCdo.ENABLED
            ) {
                return;
            } else {
                setCheckbox(
                    checkbox?.map((item) => {
                        return {
                            id: item.id,
                            isChecked: String(item.id) === String(drugManufacturerId),
                        };
                    }),
                );
                if (EX4800 && productGroup?.quantity === 0) {
                    handleCheckboxOnChange(1);
                }
            }
        } else if (helper !== 0) {
            const name = drugManufacturerContext.find((drugManufacturer) => drugManufacturer?.id === drugManufacturerId)
                ?.name;
            setTypeModalWithoutCredit('');
            openModalWithoutCredit(name);
        }
        setHelper(1);
    };

    const updateQuantityRequired = (id: number, quantity: number) => {
        const minCantRequired = productCant(id)?.mincant;
        const maxCantRequired = productCant(id)?.maxcant;
        if (minCantRequired && maxCantRequired) {
            if (quantity < minCantRequired) {
                return minCantRequired;
            } else if (quantity > maxCantRequired) {
                return maxCantRequired;
            } else {
                return quantity;
            }
        } else if (minCantRequired && !maxCantRequired) {
            if (quantity < minCantRequired) {
                return minCantRequired;
            } else {
                return quantity;
            }
        } else if (maxCantRequired && !minCantRequired) {
            if (quantity > maxCantRequired) {
                return maxCantRequired;
            } else {
                return quantity;
            }
        }
    };

    const handleOnCheck = async (drugManufacturerId: number) => {
        if (productGroup?.quantity === 0) {
            setGenericsIdsAdd((prevState) => [...prevState, productGroup?.order_product_id]);
        }
        if (
            !!isTheLastColumn(+drugManufacturerId) &&
            !!EX4562 &&
            user?.EX4562 &&
            drugManufacturer.at(-1)?.cdo_status_id === StatusCdo.DISABLED
        ) {
            openModalCdo();
        }
        setIsManualSelected(true);
        updateCheckbox(drugManufacturerId);
        const productWithMinMaxRequired = productCant(drugManufacturerId);
        if (productWithMinMaxRequired?.mincant || productWithMinMaxRequired?.maxcant) {
            const validatedQuantity = updateQuantityRequired(drugManufacturerId, productGroup?.quantity);
            if (validatedQuantity !== productGroup?.quantity) {
                await handleCheckboxOnChange(validatedQuantity);
            }
        }
    };

    const isChecked = useCallback(
        (drugManufacturerId) => checkbox?.find(({ id }) => id === drugManufacturerId)?.isChecked,
        [checkbox],
    );

    const drugManufacturerIdSelected = useCallback(() => checkbox?.find(({ isChecked }) => isChecked)?.id, [checkbox]);

    const uncheckAllProducts = useCallback(() => checkbox?.forEach((check) => (check.isChecked = false)), [checkbox]);

    const areAllItemChecked = useCallback(() => {
        const itemsChecked = checkbox?.filter(({ isChecked }) => isChecked);
        const key = productGroup?.lowest_without_cdo;

        if (enabledOnOff) {
            const hasAnyChecked = itemsChecked?.length === 0 && enabledOnOff;

            if (hasAnyChecked) {
                return false;
            }

            if (hasAnyChecked && key?.length > 1) {
                return true;
            }

            if (hasAnyChecked) {
                return false;
            }

            return itemsChecked?.length === 0;
        } else {
            if (itemsChecked?.length === 0) {
                return false;
            }

            if (itemsChecked?.length === 0 && key?.length > 1) {
                return true;
            } else if (itemsChecked?.length === 0 && key?.length === 0 && currentStatusCdO !== StatusCdo.ENABLED) {
                if (!!productCant(drugManufacturerIdSelected())) {
                    return true;
                }
                return false;
            }

            return itemsChecked?.length === 0;
        }
    }, [checkbox, enabledOnOff]);

    const productCant = useCallback(
        (id) => {
            if (productGroup?.products) {
                const product: any = productGroup?.products[id];
                return {
                    maxcant: product?.maxcant,
                    mincant: product?.mincant,
                };
            }
        },
        [productGroup],
    );

    const isTheLastColumn = (drugManufacturerId) =>
        EX4503 && drugManufacturer?.length ? drugManufacturer.at(-1)?.id === drugManufacturerId : false;

    const amountSelected = useCallback(() => {
        const id = drugManufacturerIdSelected();
        if (!id) {
            return 0;
        }
        return totalPrice(id) || 0;
    }, [drugManufacturerIdSelected(), productGroup]);

    const lowest = useCallback(
        (id) => {
            // if id is equal CDO_ID
            if (id === DrugManufacturersId.CentralDeOfertas) {
                // if the point of sale has cdo enabled
                if (productGroup && productGroup?.lowest?.includes(id)) {
                    // BACKGROUNDCOLOR GREEN
                    return true;
                }
            } else {
                // if the point of sale does not have cdo enabled
                if (pointOfSale()?.cdo_status_id !== OfferCenters.Enabled) {
                    if (productGroup && productGroup?.lowest_without_cdo?.includes(id)) {
                        // BACKGROUNDCOLOR GREEN
                        return true;
                    }
                } else {
                    // if the point of sale has cdo enabled
                    if (productGroup && productGroup?.lowest?.includes(id)) {
                        // BACKGROUNDCOLOR GREEN
                        return true;
                    }
                }
            }
        },
        [productGroup],
    );

    const isSuggested = useCallback(
        (id) => {
            return productGroup && IdIncludeInSuggested(id);
        },
        [productGroup],
    );

    const getDepositName = useCallback(() => {
        return productGroup?.products[drugManufacturerIdSelected()]?.deposit;
    }, [checkbox]);

    const setGroupControl = useCallback(() => {
        if (
            !productGroup ||
            (productGroup?.amountSelected === amountSelected() &&
                productGroup?.drugManufacturerIdSelected === drugManufacturerIdSelected())
        ) {
            return null;
        }
        // console.log('---------------EJECUTO SET GROUP CONTROL GENERICO---------------', productGroup);
        setProductGroup((prevProps) => {
            const productData = {
                ...prevProps,
                drugManufacturerIdSelected: drugManufacturerIdSelected(),
                areAllItemChecked: areAllItemChecked(),
                amountSelected: amountSelected(),
                isFatherGeneric: true,
                isManualSelected: isManualSelected,
            };

            const bonusIsApplied = checkBonus(bonus(productData.drugManufacturerIdSelected), productData.quantity);

            if (bonusIsApplied) {
                setIsAppliedIndex(bonusIsApplied);
            }
            setProductInfo(productData);
            return productData;
        });
    }, [productGroup, drugManufacturerIdSelected(), amountSelected()]);

    useEffect(() => {
        if (EX4927) {
            if (item !== 0) {
                //item is zero when first load before productsGroups complete 200
                setGroupControl();
            }
        } else {
            setGroupControl();
        }
    }, [productGroup, amountSelected(), drugManufacturerIdSelected()]);

    useEffect(() => {
        if (selectAllState && productGroup) {
            if (productGroup.quantity !== 0) {
                const currentProductSelected =
                    productGroup?.products[selectAllState] !== null ? selectAllState : productGroup.lowest[0];
                updateCheckbox(currentProductSelected);
            }
        }
    }, [selectAllState, productGroup]);

    const paymentDue = (drugManufacturerId: number) => {
        return (
            productGroup &&
            productGroup?.products[drugManufacturerId] &&
            productGroup?.products[drugManufacturerId]?.payment_due
        );
    };

    const isBestByToleranceInPriorityService = useCallback((id) => productGroup?.bestByTolerance === id, [
        productGroup,
    ]);

    const productGroupHasServiceByPriority = useCallback(
        () => Boolean(productGroup?.service?.id === ServiceId.ByPriority),
        [productGroup],
    );

    const applyToleranceInDistribution = useCallback(
        (id) => {
            if (productGroupHasServiceByPercentage() && productGroup.d_tolerance > 0) {
                // HAS TOLERANCE BY PERCENTAGE BACKGROUND COLOR YELLOW ONLY DRUGMANUFACTURER
                return productGroup.in_distribution.includes(id);
            }
        },
        [productGroup],
    );

    const productGroupHasServiceByPercentage = useCallback(
        () => Boolean(productGroup?.service?.id === ServiceId.ByPercentage),
        [productGroup],
    );

    const withStockAllDrugManufacturers = useCallback(
        (): boolean => isEmptyObjectAtrribute(productGroup?.products || []),
        [productGroup],
    );

    const getBestDrugManufacturerId = useCallback(
        () => drugManufacturer?.map(({ id }) => (productSelectedCondition(id) ? id : undefined)).find((i) => i),
        [productGroup],
    );

    const getBestByToleranceDrugManufacturerId = useCallback(
        () => drugManufacturer?.map(({ id }) => (productSelectedCondition(id) ? id : undefined)).find((i) => i),
        [productGroup],
    );

    useEffect(() => {
        if (!getBestDrugManufacturerId()) {
            return;
        }
        if (productGroupHasTolerance()) {
            updateCheckbox(getBestByToleranceDrugManufacturerId());
            return;
        }
        if (isManualSelected) {
            return;
        }
        if (bestPriceQuantity() !== 0) {
            updateCheckbox(getBestDrugManufacturerId());
            return;
        }
    }, [getBestDrugManufacturerId(), getBestByToleranceDrugManufacturerId()]);

    const gridRef = useRef(null);

    return {
        handleCheckboxOnChange,
        productGroup,
        setGroupControl,
        hasDrugManufacturerError,
        gridRef,
        withStockAllDrugManufacturers,
        setIsManualSelected,
        bonus,
        setIsAppliedIndex,
        isAppliedIndex,
        currentStatusCdO,
        productItemByDrugmanufacturer,
        orderFill,
        unitPrice,
        isBestPrice,
        pointOfSale,
        lastUnitPrice,
        totalPrice,
        productsIds,
        applyToleranceInDistribution,
        productGroupHasServiceByPriority,
        isBestByToleranceInPriorityService,
        productSelectedCondition,
        checkbox,
        updateCheckbox,
        isChecked,
        handleOnCheck,
        drugManufacturerIdSelected,
        areAllItemChecked,
        amountSelected,
        lowest,
        isSuggested,
        getDepositName,
        paymentDue,
        updateQuantityRequired,
        uncheckAllProducts,
    };
};
