import { InstitutionalHeader, InstitutionalLayout, PaymentError, PaymentFooter } from '@commons/components';
import Image from 'next/image';
import React from 'react';

const PaymentErrorSide = () => {
    return (
        <InstitutionalLayout>
            <InstitutionalHeader>
                <Image
                    src={'/assets/images/institutional_logo.svg'}
                    alt="institutional logo"
                    width={216.82}
                    height={28.89}
                    priority={true}
                />
            </InstitutionalHeader>
            <PaymentError />
            <PaymentFooter />
        </InstitutionalLayout>
    );
};

export default PaymentErrorSide;
