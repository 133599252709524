/**
 * search idx on the products groups and return products for the drugmanufacturer
 * @param idx
 * @param drugManufacturerId
 * @returns
 */
export const productIdxByDrugManufacturerId = (productGroups: any, idx: number, drugManufacturerId: number) => {
    return productGroups[idx]?.products[drugManufacturerId];
};

export const recommendedIdxByDrugManufacturerId = (recommendations: any, idx: number, drugManufacturerId: number) => {
    return recommendations[idx]?.products[drugManufacturerId];
};
