import { useRef } from 'react';
// contexts
import { useProgressBarPriceContext } from 'context';

const useContent = ({ getFailedConnectionDrugManufacturers }) => {
    const { currentStep, failed } = useProgressBarPriceContext();
    const errorRefContainer: any = useRef(null);
    const errors: any[] = getFailedConnectionDrugManufacturers();
    return { currentStep, failed, errorRefContainer, errors };
};

export default useContent;
