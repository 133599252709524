export const webIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
            d="M3.33333 14C2.96667 14 2.65278 13.8694 2.39167 13.6083C2.13056 13.3472 2 13.0333 2 12.6667V3.33333C2 2.96667 2.13056 2.65278 2.39167 2.39167C2.65278 2.13056 2.96667 2 3.33333 2H7.33333C7.52222 2 7.68056 2.06389 7.80833 2.19167C7.93611 2.31944 8 2.47778 8 2.66667C8 2.85556 7.93611 3.01389 7.80833 3.14167C7.68056 3.26944 7.52222 3.33333 7.33333 3.33333H3.33333V12.6667H12.6667V8.66667C12.6667 8.47778 12.7306 8.31944 12.8583 8.19167C12.9861 8.06389 13.1444 8 13.3333 8C13.5222 8 13.6806 8.06389 13.8083 8.19167C13.9361 8.31944 14 8.47778 14 8.66667V12.6667C14 13.0333 13.8694 13.3472 13.6083 13.6083C13.3472 13.8694 13.0333 14 12.6667 14H3.33333ZM12.6667 4.26667L6.93333 10C6.81111 10.1222 6.65556 10.1833 6.46667 10.1833C6.27778 10.1833 6.12222 10.1222 6 10C5.87778 9.87778 5.81667 9.72222 5.81667 9.53333C5.81667 9.34444 5.87778 9.18889 6 9.06667L11.7333 3.33333H10C9.81111 3.33333 9.65278 3.26944 9.525 3.14167C9.39722 3.01389 9.33333 2.85556 9.33333 2.66667C9.33333 2.47778 9.39722 2.31944 9.525 2.19167C9.65278 2.06389 9.81111 2 10 2H14V6C14 6.18889 13.9361 6.34722 13.8083 6.475C13.6806 6.60278 13.5222 6.66667 13.3333 6.66667C13.1444 6.66667 12.9861 6.60278 12.8583 6.475C12.7306 6.34722 12.6667 6.18889 12.6667 6V4.26667Z"
            fill="#646464"
        />
    </svg>
);

export const alertIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
        <path
            d="M8.50065 11.3334C8.68954 11.3334 8.84787 11.2695 8.97565 11.1417C9.10343 11.0139 9.16732 10.8556 9.16732 10.6667C9.16732 10.4778 9.10343 10.3195 8.97565 10.1917C8.84787 10.0639 8.68954 10 8.50065 10C8.31176 10 8.15343 10.0639 8.02565 10.1917C7.89787 10.3195 7.83398 10.4778 7.83398 10.6667C7.83398 10.8556 7.89787 11.0139 8.02565 11.1417C8.15343 11.2695 8.31176 11.3334 8.50065 11.3334ZM8.50065 8.66671C8.68954 8.66671 8.84787 8.60282 8.97565 8.47504C9.10343 8.34726 9.16732 8.18893 9.16732 8.00004V5.33337C9.16732 5.14449 9.10343 4.98615 8.97565 4.85837C8.84787 4.7306 8.68954 4.66671 8.50065 4.66671C8.31176 4.66671 8.15343 4.7306 8.02565 4.85837C7.89787 4.98615 7.83398 5.14449 7.83398 5.33337V8.00004C7.83398 8.18893 7.89787 8.34726 8.02565 8.47504C8.15343 8.60282 8.31176 8.66671 8.50065 8.66671ZM8.50065 14.6667C7.57843 14.6667 6.71176 14.4917 5.90065 14.1417C5.08954 13.7917 4.38398 13.3167 3.78398 12.7167C3.18398 12.1167 2.70898 11.4112 2.35898 10.6C2.00898 9.78893 1.83398 8.92226 1.83398 8.00004C1.83398 7.07782 2.00898 6.21115 2.35898 5.40004C2.70898 4.58893 3.18398 3.88337 3.78398 3.28337C4.38398 2.68337 5.08954 2.20837 5.90065 1.85837C6.71176 1.50837 7.57843 1.33337 8.50065 1.33337C9.42287 1.33337 10.2895 1.50837 11.1007 1.85837C11.9118 2.20837 12.6173 2.68337 13.2173 3.28337C13.8173 3.88337 14.2923 4.58893 14.6423 5.40004C14.9923 6.21115 15.1673 7.07782 15.1673 8.00004C15.1673 8.92226 14.9923 9.78893 14.6423 10.6C14.2923 11.4112 13.8173 12.1167 13.2173 12.7167C12.6173 13.3167 11.9118 13.7917 11.1007 14.1417C10.2895 14.4917 9.42287 14.6667 8.50065 14.6667ZM8.50065 13.3334C9.98954 13.3334 11.2507 12.8167 12.284 11.7834C13.3173 10.75 13.834 9.48893 13.834 8.00004C13.834 6.51115 13.3173 5.25004 12.284 4.21671C11.2507 3.18337 9.98954 2.66671 8.50065 2.66671C7.01176 2.66671 5.75065 3.18337 4.71732 4.21671C3.68398 5.25004 3.16732 6.51115 3.16732 8.00004C3.16732 9.48893 3.68398 10.75 4.71732 11.7834C5.75065 12.8167 7.01176 13.3334 8.50065 13.3334Z"
            fill="#E22E2E"
        />
    </svg>
);
export const downloadIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path
            d="M10 12.9792C9.88893 12.9792 9.78476 12.9618 9.68754 12.9271C9.59032 12.8924 9.50004 12.8333 9.41671 12.75L6.41671 9.75001C6.25004 9.58334 6.17018 9.3889 6.17712 9.16668C6.18407 8.94445 6.26393 8.75001 6.41671 8.58334C6.58337 8.41668 6.78129 8.32987 7.01046 8.32293C7.23962 8.31598 7.43754 8.39584 7.60421 8.56251L9.16671 10.125V4.16668C9.16671 3.93057 9.24657 3.73265 9.40629 3.57293C9.56601 3.4132 9.76393 3.33334 10 3.33334C10.2362 3.33334 10.4341 3.4132 10.5938 3.57293C10.7535 3.73265 10.8334 3.93057 10.8334 4.16668V10.125L12.3959 8.56251C12.5625 8.39584 12.7605 8.31598 12.9896 8.32293C13.2188 8.32987 13.4167 8.41668 13.5834 8.58334C13.7362 8.75001 13.816 8.94445 13.823 9.16668C13.8299 9.3889 13.75 9.58334 13.5834 9.75001L10.5834 12.75C10.5 12.8333 10.4098 12.8924 10.3125 12.9271C10.2153 12.9618 10.1112 12.9792 10 12.9792ZM5.00004 16.6667C4.54171 16.6667 4.14935 16.5035 3.82296 16.1771C3.49657 15.8507 3.33337 15.4583 3.33337 15V13.3333C3.33337 13.0972 3.41324 12.8993 3.57296 12.7396C3.73268 12.5799 3.9306 12.5 4.16671 12.5C4.40282 12.5 4.60074 12.5799 4.76046 12.7396C4.92018 12.8993 5.00004 13.0972 5.00004 13.3333V15H15V13.3333C15 13.0972 15.0799 12.8993 15.2396 12.7396C15.3993 12.5799 15.5973 12.5 15.8334 12.5C16.0695 12.5 16.2674 12.5799 16.4271 12.7396C16.5868 12.8993 16.6667 13.0972 16.6667 13.3333V15C16.6667 15.4583 16.5035 15.8507 16.1771 16.1771C15.8507 16.5035 15.4584 16.6667 15 16.6667H5.00004Z"
            fill="#646464"
        />
    </svg>
);