export const obtainInitialsLetter = (name) => {
  const words = name.split(' ');

  const initials = words.map((word) => word.charAt(0).toUpperCase());

  return initials.join('');
};

export const obtainNameOfPointOfSale = (
  pointOfSale: any,
  id: number,
  
) => {
  return  pointOfSale.find((elem: any) => elem.id === id)?.name;
};

export const obtainReferenceOfPointOfSale = (
  pointOfSale: any,
  id: number,
  
) => {
  return  pointOfSale.find((elem: any) => elem.id === id)?.reference_name;
};


export const newOrdersArray = (orders) => {
  return orders?.map((order) => {
    const result = order.deliveries?.reduce(
      (acc, delivery) => {
        const totalOrders = acc.totalOrders + delivery.total;

        const drugManufacturers = [
          ...acc.drugManufacturers,
          delivery.drug_manufacturer,
        ];

        return {
          totalOrders,
          drugManufacturers,
        };
      },
      {
        totalOrders: 0,
        drugManufacturers: [],
      }
    );

    return {
      ...order,
      deliveries: order.deliveries.map((delivery) => ({
        ...delivery,
      })),
      drug_manufacturer_orders: result.drugManufacturers,
      totalOrders: result.totalOrders,
    };
  });
}