// style
import style from './ToolbarCE.module.css';

import { ToolbarCESearch, ToolbarCEDropdown, ToolbarCEAvatars, ToolbarCESetting } from '@commons/components';

const ToolbarCE = ({ setOpenDropdown, openDropdown }) => {
    return (
        <div
            className={style['container-toolbar']}
            style={{
                marginTop: 0,
                marginBottom: 0,
                marginLeft: 0,
            }}
        >
            <ToolbarCESearch />
            <ToolbarCEDropdown setOpenDropdown={setOpenDropdown} openDropdown={openDropdown} />
            <ToolbarCESetting />
            <ToolbarCEAvatars />
        </div>
    );
};

export default ToolbarCE;
