import { lazy, Suspense, useEffect, useRef, useState } from 'react';
import { t } from 'i18next';
// configs
import { EX6689, EX7074, EX7160, EX7196 } from 'config/flags';
// CONTEXTS
import { useProductComparison } from 'context/product-comparison.context';
import { useContainerRefContext, usePointOfSale } from 'context';
//COMPONENTS
import { Button, IconButton, Placeholder, Tooltip, Whisper } from 'rsuite';
import SplitIcon from '@rsuite/icons/Split';
import { CounterBox } from './CounterBox';
import { CellProductDetail } from './CellProductDetail';
import { TrashIcon } from './TrashIcon';
// Carga el componente BarcodeImg de forma dinámica
export const LazyBarcodeImg = lazy(() => import('./BarcodeImg').then((module) => ({ default: module.BarcodeImg })));
// INTERFACES
import { CellProductProps } from './CellProduct.interface';
// UTILS
import { handlerRemoveProduct } from './utils';
import { isZettiFormat } from 'utils/pointOfSale';
//STYLES
import styles from './CellProduct.module.css';

const CellProduct = ({
    idx,
    description,
    laboratory,
    drug,
    category,
    barcode,
    skeleton,
    quantity,
    handleCheckboxOnChange,
    withoutChecks,
    removeEvent,
    showChildren,
    setShowChildren,
    generics,
    parentRef,
    isRecommended,
    orderProductId,
    isGenerics,
    user,
    updateQuantityRequired,
    drugmanufacturerIdSelected,
    uncheckAllProducts,
    childrenGenerics,
    handleSplitClick,
    is_split,
    allow_split,
    maxQuantitySplit,
    initial_quantity,
    productMaxMin,
    setIsManualSelected,
    parentGenericProduct,
    tag,
    genericsIdsAdd,
    setGenericsIdsAdd,
}: CellProductProps): JSX.Element => {
    const [isUnchecked] = useState<boolean>(withoutChecks);
    const [addedItems, setAddedItems] = useState<Array<{ id: number; added: boolean; initialQuantity: boolean }>>([]);
    const [isZero, setIsZero] = useState<boolean>(false);
    const [hasGenericsSelected, setHasGenericsSelected] = useState<boolean>(false);
    // validation is zetti
    const { pointOfSale: pos } = usePointOfSale();
    const isZetti = isZettiFormat(pos);
    // context
    const { setManuallySelectedProductIds } = useProductComparison();
    // ff
    const EX5937 = user?.EX5937;
    const EX4800 = user?.EX4800 && !isZetti;
    const FFMAXMIN = process.env.NEXT_PUBLIC_FFMAXMIN === 'true';
    const [seeSplitButton, setSeeSplitButton] = useState<boolean>(false);
    const firstText = t('efficientPurchase.cellProduct.tooltipsplit_text_one');
    const secondText = t('efficientPurchase.cellProduct.tooltipsplit_text_two');
    const thirdText = (quantityValue) =>
        t('efficientPurchase.cellProduct.tooltipsplit_text_three', { initial_quantity: quantityValue });
    const [showTemporaryText, setShowTemporaryText] = useState<string>(!allow_split ? firstText : '');
    const tooltipRef = useRef(null);
    const { containerRef } = useContainerRefContext();

    const handleAddClick = (id: number) => {
        if (EX7160) {
            setGenericsIdsAdd((prevState) => [...prevState, id]);
        }
        setAddedItems((prevState) => {
            const itemIndex = prevState.findIndex((item) => item.id === id);
            if (itemIndex !== -1) {
                // Actualizar el objeto existente
                const newState = [...prevState];
                newState[itemIndex] = { id, added: true, initialQuantity: false };
                handleCheckboxOnChange(quantity === 0 ? 1 : quantity);

                return newState;
            }
            handleCheckboxOnChange(1);
            return [...prevState, { id, added: true, initialQuantity: true }];
        });
    };

    const setMessage = (messageNumber, value = undefined) => {
        if (messageNumber === 0) {
            setShowTemporaryText('');
        }
        if (messageNumber === 1) {
            setShowTemporaryText(firstText);
        }
        if (messageNumber === 2) {
            setShowTemporaryText(secondText);
        }
        if (messageNumber === 3) {
            setShowTemporaryText(thirdText(value));
        }
    };

    const showTooltip = () => {
        if (tooltipRef.current) {
            tooltipRef.current.open();
        }
    };

    const disablePlusButton = () => {
        if (maxQuantitySplit && maxQuantitySplit !== 0) {
            return quantity >= maxQuantitySplit;
        } else {
            if (FFMAXMIN) {
                if (EX6689) {
                    if (!!productMaxMin?.maxcant) {
                        const maxAllowedQuantity = productMaxMin?.maxcant ?? 0;
                        return quantity >= maxAllowedQuantity;
                    } else {
                        return false;
                    }
                } else {
                    if (!!productMaxMin?.maxcant || !!productMaxMin?.mincant) {
                        const maxAllowedQuantity = productMaxMin?.maxcant ?? 0;
                        return quantity >= maxAllowedQuantity;
                    } else {
                        return false;
                    }
                }
            } else {
                return false;
            }
        }
    };

    const disableMinusButton = () => {
        if (generics) {
            const hasNoChildrenGenerics = EX7160 ? genericsIdsAdd?.length === 0 : childrenGenerics?.length === 0;
            return hasNoChildrenGenerics ? quantity === 1 : false;
        } else {
            return quantity === 1;
        }
    };

    useEffect(() => {
        if (EX5937) {
            if (is_split) {
                setSeeSplitButton(is_split);
            }
            
            if(EX7074) {
                if(!is_split && (quantity === initial_quantity)) {
                    setSeeSplitButton(is_split);
                }
            }

            if (allow_split === false) {
                setMessage(2);
                showTooltip();
                setTimeout(() => {
                    setMessage(1);
                }, 8000);
            } else {
                setMessage(0);
            }
        }
    }, [allow_split, is_split, quantity]);

    useEffect(() => {
        if (quantity === 0) {
            setIsManualSelected(false);
            uncheckAllProducts();
        }

        if (EX5937) {
            if (allow_split && maxQuantitySplit !== 0 && quantity <= maxQuantitySplit) {
                setMessage(0);
            }
            if (allow_split && maxQuantitySplit !== 0 && quantity > maxQuantitySplit) {
                setMessage(3, initial_quantity);
            }
        }
    }, [quantity]);

    useEffect(() => {
        if (isUnchecked !== withoutChecks) {
            setManuallySelectedProductIds((prevState) => prevState.concat([+orderProductId]));
        }
    }, [isUnchecked, withoutChecks]);

    useEffect(() => {
        if (addedItems?.length > 0) {
            setHasGenericsSelected(true);
        } else {
            setHasGenericsSelected(false);
        }
    }, [addedItems]);

    const splitButtonClass = allow_split
        ? `${styles['split-button-activated']} button__split-on`
        : `${styles['split-button-default']} button__split-off`;

    /* #region listener hidden tooltip when scrolling */
    useEffect(() => {
        const checkScroll = () => {
            if (tooltipRef?.current) {
                tooltipRef.current.close();
            }
        };

        const contentElement = containerRef?.current;

        if (!contentElement) {
            return;
        }

        contentElement.addEventListener('scroll', checkScroll);

        return () => {
            if (contentElement) {
                contentElement.removeEventListener('scroll', checkScroll);
            }
        };
    }, [tooltipRef, containerRef]);

    const validation = EX7160
        ? !isGenerics && !genericsIdsAdd?.length
        : isGenerics || (isZero && EX4800) || childrenGenerics?.length > 0;

    return (
        <div
            className={`${!!isGenerics ? styles['cell-generics'] : styles['cell']} ${
                withoutChecks && quantity !== 0 ? styles['cell-error'] : ''
            } ${!!tag && EX7196 ? styles['bioequivalent'] : ''}`}
            style={{
                gridTemplateColumns: `minmax(185px, 1fr) 110px`,
            }}
        >
            {/* DESCRIPTION PRODUCT */}
            <div className={styles['product']} style={{ gap: 4 }}>
                {!skeleton && validation ? (
                    <TrashIcon
                        handleClick={() =>
                            handlerRemoveProduct({
                                index: +idx - 1,
                                barcode,
                                removeEvent,
                                orderProductId: +orderProductId,
                            })
                        }
                    />
                ) : null}
                <div className={styles['product-image']}>
                    <Suspense
                        fallback={
                            <Placeholder.Graph
                                active
                                style={{
                                    width: 50,
                                    height: 50,
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            />
                        }
                    >
                        <LazyBarcodeImg barcode={barcode} />
                    </Suspense>
                </div>
                <CellProductDetail
                    skeleton={skeleton}
                    idx={idx}
                    barcode={barcode}
                    category={category}
                    laboratory={laboratory}
                    description={description}
                    drug={drug}
                    generics={generics}
                    showChildren={showChildren}
                    setShowChildren={setShowChildren}
                    parentRef={parentRef}
                    isRecommended={isRecommended}
                    isGenerics={isGenerics}
                    isZero={isZero}
                    user={user}
                    tag={tag}
                />
            </div>
            {/* COUNTER BOX PRODUCTS */}
            <div className={styles['button-group']}>
                {isGenerics ? (
                    quantity > 0 ? (
                        <div className={styles['counter']}>
                            <CounterBox
                                quantity={quantity}
                                handleCheckboxOnChange={handleCheckboxOnChange}
                                skeleton={skeleton}
                                error={false}
                                disabled={false}
                                setIsZero={setIsZero}
                                user={user}
                                updateQuantityRequired={updateQuantityRequired}
                                drugmanufacturerIdSelected={drugmanufacturerIdSelected}
                                uncheckProducts={uncheckAllProducts}
                                addedItems={addedItems}
                                disabledMinus={false}
                                parentGenericProduct={parentGenericProduct}
                                isGenerics={isGenerics}
                                orderProductId={+orderProductId}
                                setGenericsIdsAdd={setGenericsIdsAdd}
                            />
                        </div>
                    ) : (
                        <div className={styles['container-button']}>
                            <Button
                                className={styles['button']}
                                appearance="primary"
                                onClick={() => handleAddClick(+orderProductId)}
                            >
                                {t('efficientPurchase.cellProduct.add_button')}
                            </Button>
                        </div>
                    )
                ) : (
                    <div className={styles['counter']}>
                        <CounterBox
                            quantity={quantity}
                            handleCheckboxOnChange={handleCheckboxOnChange}
                            skeleton={skeleton}
                            error={false}
                            disabled={false}
                            setIsZero={setIsZero}
                            user={user}
                            updateQuantityRequired={updateQuantityRequired}
                            drugmanufacturerIdSelected={drugmanufacturerIdSelected}
                            uncheckProducts={uncheckAllProducts}
                            generics={generics}
                            disabledMinus={disableMinusButton()}
                            disabledPlus={disablePlusButton()}
                            addedItems={addedItems}
                            childrenGenerics={childrenGenerics}
                            maxQuantitySplit={maxQuantitySplit}
                            setMessage={setMessage}
                            showTooltip={showTooltip}
                            productMaxMin={productMaxMin}
                            parentGenericProduct={parentGenericProduct}
                            genericsIdsAdd={genericsIdsAdd}
                        />
                    </div>
                )}
                {EX5937 && seeSplitButton && (
                    <Whisper
                        ref={tooltipRef}
                        placement="bottomStart"
                        disabled={showTemporaryText === ''}
                        speaker={
                            <Tooltip className={styles['tooltipSplit']} placement="bottomStart">
                                {showTemporaryText}
                            </Tooltip>
                        }
                        trigger="hover"
                    >
                        <IconButton
                            startIcon={<SplitIcon />}
                            className={`${styles['split-button']} ${splitButtonClass}`}
                            appearance="ghost"
                            onClick={handleSplitClick}
                        >
                            {t('efficientPurchase.cellProduct.split_button')}
                        </IconButton>
                    </Whisper>
                )}
            </div>
        </div>
    );
};

export default CellProduct;
