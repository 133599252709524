// next
import { useRouter } from 'next/router';
// react
import { useEffect, useRef, useState } from 'react';
// components
import { EmptyList, PaginationList } from '.';
import { RenderMenuItem } from './RenderMenuItem';
// interface
import { IListResults } from './ListResultsV2.interface';
// rsuite
import { List, Panel, Placeholder, Row } from 'rsuite';
// style
import style from './ListResultsV2.module.css';
// handler
import { handleKeyDownInputPicker } from 'utils/dataKeyPress';
import { useUser } from 'utils/hooks';
import { handleLimitChange, handlerChangePage } from './handlers';
import LoadingBody from '@components/LoadingBody/LoadingBody';
import { RenderMenuItemV2 } from './RenderMenuItemV2';
import { EmptyListV2 } from '../ListResults/EmptyListV2';

const ListResultsV2 = ({
    recordset,
    pagination,
    onClickAddProductCart,
    handleNextPage,
    handlePreviousPage,
    handleItemsPerPage,
    isLoading,
    isOpen,
    existsProductInCart,
    getProductByBarcode,
    updateProduct,
    pointOfSale,
    setSelectedIndex,
    selectedIndex,
    setActiveSearches,
    onClickButtonByCode,
    currentSearch
}: IListResults) => {
    const router = useRouter();
    const { user } = useUser({});
    const EX5095 = user?.EX5095;
    const listElementRef = useRef();
    const [catalog, setCatalog] = useState([]);
    const [notCatalog, setNotCatalog] = useState([]);
    useEffect(() => {
        if (!isOpen) {
            const element: any = document?.getElementById('list-results');

            element?.focus();
            element?.click();
        }
    }, [isOpen]);

    useEffect(() => {
        const catalogItems = [];
        const notCatalogItems = [];

        recordset.forEach((item: any) => {
            if (item._source.description) {
                catalogItems.push(item);
            } else {
                notCatalogItems.push(item);
            }
        });

        setCatalog(catalogItems);
        setNotCatalog(notCatalogItems);
    }, [recordset]);

    return (
        <div className={`${(style['container-panel'], isOpen ? style['backdrop'] : '')}`}>
            <Panel bodyFill shaded className={style['panel-header']}>
                {recordset.length === 0 && !isLoading ? (
                    <EmptyListV2 onClickButtonByCode={onClickButtonByCode} currentSearch={currentSearch}/>
                ) : (
                    <div ref={listElementRef} style={{ position: 'relative' }}>
                        <List style={{ borderRadius: 8 }}>
                            <List.Item className={style['list-items']}>
                                {!user?.EX5178 && (
                                    <div className={style['container-quantity-item']}>
                                        {isLoading ? (
                                            <div className={style['wrapper-paragraph']}>
                                                <Placeholder.Paragraph rows={1} rowHeight={15} active />
                                            </div>
                                        ) : (
                                            <p className={style['quantity-items']}>
                                                {pagination?.total} resultados encontrados
                                            </p>
                                        )}
                                    </div>
                                )}
                                <Row className={style['wrapper-row']}>
                                    {isLoading ? (
                                        <LoadingBody isSearchResults rows={10} />
                                    ) : (
                                        <>
                                            {isLoading ? (
                                                <LoadingBody />
                                            ) : (
                                                (
                                                    <RenderMenuItemV2
                                                        onClickAddProductCart={onClickAddProductCart}
                                                        existsProductInCart={existsProductInCart}
                                                        pointOfSaleId={pointOfSale?.id}
                                                        handleUpdateProduct={updateProduct}
                                                        onKeyDown={(evt) =>
                                                            handleKeyDownInputPicker(
                                                                evt,
                                                                setSelectedIndex,
                                                                recordset,
                                                                router,
                                                                '',
                                                                pointOfSale?.id,
                                                                recordset,
                                                                'listResults',
                                                                setActiveSearches,
                                                            )
                                                        }
                                                        selectedIndex={selectedIndex}
                                                        parentRef={listElementRef}
                                                        recordsetNotCatalog={notCatalog}
                                                        recordsetCatalog={catalog}
                                                        getProductByBarcode={getProductByBarcode}
                                                    />
                                                )
                                            )}
                                            
                                        </>
                                    )}
                                </Row>
                                {!EX5095 && <hr className={style['liner-divider']} />}
                                {!EX5095 && (
                                    <PaginationList
                                        total={pagination?.total}
                                        activePage={pagination?.current_page}
                                        limit={pagination?.per_page}
                                        onChangePage={(new_page) =>
                                            new_page > pagination?.current_page
                                                ? handlerChangePage(new_page, handleNextPage)
                                                : handlerChangePage(new_page, handlePreviousPage)
                                        }
                                        onChangeLimit={(limit) => handleLimitChange(limit, handleItemsPerPage)}
                                        pages={pagination?.total_pages}
                                        isLoading={isLoading}
                                    />
                                )}
                            </List.Item>
                        </List>
                    </div>
                )}
            </Panel>
        </div>
    );
};

export default ListResultsV2;
