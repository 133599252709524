import { useState, useEffect } from 'react';

const usePositionArrowLeft = (selector: string) => {
    const [arrowLeft, setArrowLeft] = useState(0);
    const element: any = document?.querySelector(selector);

    const updateWidth = () => {
        if (element) {
            setArrowLeft(element?.offsetWidth);
        }
    };

    useEffect(() => {
        // Actualiza el ancho al montar el componente
        updateWidth();

        // Actualiza el ancho cuando se redimensiona la ventana
        window?.addEventListener('resize', updateWidth);

        // Cleanup event listener on component unmount
        return () => {
            window?.removeEventListener('resize', updateWidth);
        };
    }, [selector, element]);

    return { arrowLeft };
};

export default usePositionArrowLeft;
