import React from 'react';
import { Drawer, IconButton } from 'rsuite';
import style from './ModalPrivacyPolicy.module.css';
import { useUser } from 'utils/hooks';
import { COUNTRY } from 'utils/country';
import PrivacyPolicyMX from './locales/mx/PrivacyPolicyMX';
import PrivacyPolicyCO from './locales/co/PrivacyPolicyCO';
import { EX5036 } from 'config/flags';
import { useTranslation } from 'react-i18next';

const ModalPrivacyPolicy = ({ openModal, setOpenModal, setLinkActive, expandedFooter }) => {
    const { t } = useTranslation();
    const handleClose = () => {
        setLinkActive({ value: '', state: false });
        setOpenModal();
    };

    const { userLocaleCountryCode } = useUser({});

    return (
        <Drawer
            className={expandedFooter ? style['wrapper-content'] : style['wrapper-content-large']}
            size={'full'}
            placement={'bottom'}
            open={openModal}
            onClose={() => handleClose()}
            style={{
                zIndex: 3500,
            }}
        >
            <div className={style['wrapper-header']}>
                <p className={style['header']}>{t('footer.footer_Six')}</p>
                <IconButton
                    onClick={() => handleClose()}
                    appearance="link"
                    icon={<img color="rgba(64, 67, 91, 1)" src={'/assets/images/close.png'} />}
                />
            </div>
            {userLocaleCountryCode === COUNTRY.MX && <PrivacyPolicyMX />}
            {userLocaleCountryCode === COUNTRY.CO && <PrivacyPolicyCO />}
        </Drawer>
    );
};

export default ModalPrivacyPolicy;
