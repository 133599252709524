import { useEffect, useState } from 'react';
// uitls && hooks
import { isShowAlert } from '../utils';
import { isShowWarning } from '../utils/isShowAlert';

// useAlertBanner.ts (Hook personalizado)
export const useAlertBanner = () => {
    const [showAlert, setShowAlert] = useState<boolean>(isShowAlert());
    const [showWarning, setShowWarning] = useState<boolean>(isShowWarning());

    useEffect(() => {
        const handleStorageChange = (event: CustomEvent) => {
            if (!!event.detail) {
                setShowAlert(isShowAlert());
                setShowWarning(isShowWarning());
            }
        };

        // Escucha cambios en el almacenamiento local
        window.addEventListener('alertBannerChange', handleStorageChange);

        // Cleanup al desmontar el componente
        return () => {
            window.removeEventListener('alertBannerChange', handleStorageChange);
        };
    }, []);

    return showAlert || showWarning;
};
