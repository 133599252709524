import { memo } from 'react';
import { t } from 'i18next';
// context
import { useProductComparison } from 'context/product-comparison.context';
// rsuite
import { Button } from 'rsuite';
// style
import style from './WithoutSearching.module.css';

const WithoutSearching = () => {
    // context
    const { handleClearFilters } = useProductComparison();
    return (
        <div className={style['container']} style={{ gridRow: '1 / 4' }}>
            <div className={style['container-image']}>
                <img src="/assets/images/Search_error.svg" />
            </div>
            <div className={style['container-text']}>
                <p className={style['text-title']}>
                    {t('efficientPurchasing.comparative.labelErrorShowFilteredProducts') ||
                        ' No hay resultados que coincidan con tus filtros'}
                </p>
                <p className={style['text-label']}> {t('shoppingCart.labelTryAgain') || 'Intentalo de nuevo'}</p>
                <Button appearance="link" className={style['button-link']} onClick={handleClearFilters}>
                    {t('efficientPurchasing.comparative.labelClearFilters') || 'Limpiar filtros'}
                </Button>
            </div>
        </div>
    );
};

export default memo(WithoutSearching);
