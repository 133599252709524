import { FC } from 'react';
import { t } from 'i18next';
import { EX3762, EX5057 } from 'config/flags';
import { useModalDiscountsContext } from 'context';
import PerfectScrollBar from 'react-perfect-scrollbar';
import { Modal } from 'components';
import ModalMultiple from './ModalMultiple';
import { Grid, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from './ModalDiscounts.styles';
export interface Discount {
    max_quantity: number;
    min_quantity: number;
    multiple?: number;
    price: number;
    discountPercentage: number;
}

const ModalDiscounts: FC = () => {
    // modal discounts context
    const { openModalDiscounts, setOpenModalDiscounts } = useModalDiscountsContext();

    const classes = useStyles();
    const renderText = (discount: Discount) => {
        if (discount.max_quantity !== null && discount.min_quantity !== null) {
            // MAX AND MIN
            return (
                <Typography>
                    Seleccionando{' '}
                    <span>
                        {EX5057 ? t('efficientPurchasing.comparative.discountLabelFrom') : 'desde'}{' '}
                        {discount.min_quantity} u.{' '}
                        {EX5057 ? t('efficientPurchasing.comparative.discountLabelTo') : 'hasta'}{' '}
                        {discount.max_quantity} u.
                    </span>
                </Typography>
            );
        } else if (discount.max_quantity !== null && discount.min_quantity === null) {
            // ONLY MAX
            return (
                <Typography>
                    Seleccionando{' '}
                    <span>
                        {EX5057 ? t('efficientPurchasing.comparative.discountLabelTo') : 'hasta'}{' '}
                        {discount.max_quantity}{' '}
                        {EX5057 ? t('efficientPurchasing.comparative.labelFooterUnits') : 'unidades'}
                    </span>
                </Typography>
            );
        } else {
            // ONLY MIN
            return (
                <Typography>
                    Seleccionando{' '}
                    <span>
                        {EX5057 ? t('efficientPurchasing.comparative.discountLabelFrom') : 'desde'}{' '}
                        {discount.min_quantity}{' '}
                        {EX5057 ? t('efficientPurchasing.comparative.labelFooterUnits') : 'unidades'}
                    </span>
                </Typography>
            );
        }
    };

    const sortDiscounts = () =>
        openModalDiscounts.discounts.sort((a, b) => a.discountPercentage - b.discountPercentage);

    return 'Suiza de Tucumán' === openModalDiscounts.drugManufacturerName && EX3762 ? (
        <ModalMultiple
            open={openModalDiscounts.state}
            setOpen={() =>
                setOpenModalDiscounts({
                    state: false,
                    discounts: [],
                    drugManufacturerName: '',
                })
            }
            discounts={openModalDiscounts.discounts}
            drugManufacturerName={openModalDiscounts.drugManufacturerName}
        />
    ) : (
        <Modal
            className={classes.containerModalDiscounts}
            Width={
                openModalDiscounts.discounts.some((discount: Discount) => discount.max_quantity !== null)
                    ? '376px'
                    : '340px'
            }
            open={openModalDiscounts.state}
            onClose={() =>
                setOpenModalDiscounts({
                    state: false,
                    discounts: [],
                    drugManufacturerName: '',
                })
            }
        >
            {open && (
                <Grid container direction="column" alignItems="center" justify="center" className={classes.content}>
                    <Grid item className={classes.firstSection}>
                        <Grid item className={classes.sectionIcons}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="45"
                                height="45"
                                viewBox="0 0 45 45"
                                fill="none"
                                className={classes.infoIcon}
                            >
                                <path
                                    d="M22.5 3.75C12.1436 3.75 3.75 12.1436 3.75 22.5C3.75 32.8564 12.1436 41.25 22.5 41.25C32.8564 41.25 41.25 32.8564 41.25 22.5C41.25 12.1436 32.8564 3.75 22.5 3.75ZM22.5 37.7344C14.0991 37.7344 7.26562 30.9001 7.26562 22.5C7.26562 14.0999 14.0991 7.26562 22.5 7.26562C30.9009 7.26562 37.7344 14.0999 37.7344 22.5C37.7344 30.9001 30.9009 37.7344 22.5 37.7344ZM25.4297 28.3594H24.2578V21.9141C24.2578 20.9473 23.4741 20.1562 22.5 20.1562H20.1562C19.1895 20.1562 18.3984 20.9473 18.3984 21.9141C18.3984 22.8809 19.1895 23.6719 20.1562 23.6719H20.7422V28.3594H19.5703C18.6035 28.3594 17.8125 29.1504 17.8125 30.1172C17.8125 31.084 18.6035 31.875 19.5703 31.875H25.4297C26.4001 31.875 27.1875 31.0876 27.1875 30.1172C27.1875 29.1467 26.4038 28.3594 25.4297 28.3594ZM22.5 17.8125C23.7942 17.8125 24.8438 16.7629 24.8438 15.4688C24.8438 14.1746 23.7942 13.125 22.5 13.125C21.2058 13.125 20.1562 14.1724 20.1562 15.4688C20.1562 16.7651 21.2036 17.8125 22.5 17.8125Z"
                                    fill="#1A66FC"
                                />
                            </svg>
                            <CloseIcon
                                className={classes.closeIcon}
                                onClick={() =>
                                    setOpenModalDiscounts({
                                        state: false,
                                        discounts: [],
                                        drugManufacturerName: '',
                                    })
                                }
                            />
                        </Grid>
                        <Grid item>
                            <Typography className={classes.titleModal}>
                                Descuentos {openModalDiscounts.drugManufacturerName}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography className={classes.subtitleModal}>
                                Al seleccionar la cantidad, automaticamente aplicaremos los descuentos que correspondan
                                a estas promociones:
                            </Typography>
                        </Grid>
                    </Grid>
                    <PerfectScrollBar
                        style={{
                            overflowY: 'auto',
                            maxHeight: 308,
                            width: '100%',
                        }}
                    >
                        <Grid item className={classes.secondSection}>
                            {sortDiscounts().map((discount: Discount, idx) => {
                                return (
                                    <Grid item key={idx + 1}>
                                        <div>
                                            <Typography>
                                                {discount.discountPercentage}% <span>OFF</span>
                                            </Typography>
                                        </div>
                                        {renderText(discount)}
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </PerfectScrollBar>
                </Grid>
            )}
        </Modal>
    );
};

export default ModalDiscounts;
