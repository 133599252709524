import React from 'react';
// COMPONENTS
import { RsuiteTooltip } from '@commons/components/RsuiteTooltip';
// INTERFACES
import { AmountIconErrorProps } from './AmountIconError.interface';

const AmountIconError = ({ t, value }: AmountIconErrorProps): JSX.Element => {
    return (
        <RsuiteTooltip
            placement="top"
            text={
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span>{t('minimumAmount.min2')}</span>
                    <span>{value}</span>
                </div>
            }
            trigger="hover"
            marginTop={-10}
            padding={'4px 12px'}
        >
            <img src="/assets/images/error_FILL.svg" alt="error amount icon" width={16} height={16} />
        </RsuiteTooltip>
    );
};

export default AmountIconError;
