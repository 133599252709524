import { memo } from 'react';
// utils && hooks
import { useCircle } from './hooks';
// interfaces
import { ICircleProps } from './Circle.interface';
// styles
import styles from './Circle.module.css';

const Circle = ({ icon, confirmIcon, errorIcon, isLastItem, inProgress, isComplete }: ICircleProps) => {
    const {
        animation,
        fadeIconCheck,
        scaleUpCenterRef,
        handleRedirectTable,
        currentStep,
        failed,
        activateFinalAnimation,
        expandingCircle,
        showExpandingCircle,
        heartbeat,
    } = useCircle({ inProgress, isLastItem, isComplete });

    const getClassNames = () => {
        let classNames = styles['container'];
        const applyBackgroundBlue = !isLastItem && (isComplete || inProgress); //background circle
        const changeIconCheck = isLastItem && currentStep === 3; //change icon check completed
        const fadeOutIcon = currentStep === 4 && !isLastItem; //fade out apply in icons
        const moveRight = (failed || activateFinalAnimation) && isLastItem; //move to right
        const changeColorIcon = failed && isLastItem; //icon red by failed

        if (applyBackgroundBlue) {
            classNames += ` ${styles['container-complete']}`;
        } else if (changeIconCheck) {
            classNames += ` ${styles['last-complete']}`;
        }

        if (fadeOutIcon) {
            classNames += ` ${styles['fade-out-right']}`;
        } else if (moveRight) {
            classNames += ` ${styles['fade-in-right']}`;
        }

        if (heartbeat) {
            classNames += ` ${styles['heartbeat']}`;
        }

        if (changeColorIcon) {
            classNames += ` ${styles['failed-icon']}`;
        }

        if (fadeIconCheck) {
            classNames += ` ${styles['fade-out']}`;
        }

        return classNames;
    };

    return (
        <>
            <div ref={scaleUpCenterRef} className={getClassNames()} onAnimationEnd={showExpandingCircle}>
                {/* render icons */}
                {isLastItem
                    ? [3, 4].includes(currentStep)
                        ? failed
                            ? errorIcon('#fff')
                            : confirmIcon(currentStep === 3 ? '#0171E6' : '#fff')
                        : icon('#0171E6')
                    : icon(isComplete || inProgress ? '#fff' : '#0171E6')}
            </div>
            {/* line between circles */}
            <div
                className={`${!isLastItem ? `${styles['line']} ${animation ? styles['inProgress'] : ''} ` : ''} ${
                    isComplete ? styles['isComplete'] : ''
                } ${currentStep === 4 ? styles['fade-out-right'] : ''}`}
            ></div>
            {/* background green when finished animation */}
            {expandingCircle && (
                <div
                    className={styles['expanding-circle']}
                    onAnimationEnd={handleRedirectTable}
                    style={{
                        top: scaleUpCenterRef?.current?.getBoundingClientRect()?.top,
                        left: scaleUpCenterRef?.current?.getBoundingClientRect()?.left,
                    }}
                ></div>
            )}
        </>
    );
};

export default memo(Circle);
