import { DeliveryInfo } from "@commons/components/TableComparisonV2/TableHeader/TableHeaderErrors/DefaultWithoutError/ChipDelivery/ChipDelivery.interface";

export const auxiliarFunctions = () => {
    /**
     * Formats a date string into 'YYYY-MM-DD' format.
     * @param {string} dateStr - The date string to format.
     * @returns {string} The formatted date string.
     */
    const formatDate = (dateStr) => {
        const date = new Date(dateStr);
        return date.toISOString().split('T')[0];
    };

    /**
     * Finds an item in the data array that matches the specified date and selected available time.
     * @param {Object[]} data - An array of data objects containing 'date' and 'value' properties.
     * @param {string} dateStr - The date string to match.
     * @param {string} selectedAvailableTime - The available time value to match.
     * @returns {Object|undefined} The matching item, or undefined if no match is found.
     */
    const itemSelected = (data, dateStr, selectedAvailableTime) => {
        return data.find((item) => item.date === dateStr && item.value === selectedAvailableTime);
    };

        /**
     * Given a date, it returns a string detailing whether if it's today, tomorrow, or the appropiate
     * following day, by name.
     * @param {Date} date - The date to evaluate.
     * @returns {DeliveryInfo} The resulting value.
     */
    const getChipDateInfo = (date: Date): DeliveryInfo => {
        const today = new Date();
        const inputDate = new Date(date);
    
        // Ajustar la fecha de hoy a la medianoche para comparar solo fechas
        today.setHours(0, 0, 0, 0);
        inputDate.setHours(0, 0, 0, 0);
    
        const oneDay = 24 * 60 * 60 * 1000; // Milisegundos en un día
        const dayDifference = (inputDate.getTime() - today.getTime()) / oneDay;
    
        if (dayDifference === 0) {
            return {
                tag: 'Llega hoy',
                alert: true,
                mark_color: 'red',
                hover: ''
            };
        } else if (dayDifference === 1) {
            return {
                tag: 'Llega mañana',
                alert: false,
                mark_color: 'blue',
                hover: '',
            };
        } else {
            const daysOfWeek = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];
            const dayName = daysOfWeek[inputDate.getDay()];
            return {
                tag: `Llega el ${dayName}`,
                alert: false,
                mark_color: 'blue',
                hover: ''
            };
        }
    }

    return {
        formatDate,
        itemSelected,
        getChipDateInfo
    };
};
