import { EDrugManufacturerTypeId } from 'utils/enums/drugmanufacturerTypeId/DrugManufacturerTypeId';

export const auxiliary = () => {
    const handleChangeClassNameStyles = (styles, drugManufacturerTypeId, expandedFooter) => {
        let classNameStyle;

        switch (drugManufacturerTypeId) {
            case EDrugManufacturerTypeId.vendor:
                classNameStyle = expandedFooter
                    ? styles['cell-price-expanded-with-vendor']
                    : styles['cell-price-with-vendor'];
                break;
            default:
                classNameStyle = expandedFooter ? styles['cell-price-expanded'] : styles['cell-price'];
                break;
        }
        return classNameStyle;
    };

    return {
        handleChangeClassNameStyles,
    };
};
