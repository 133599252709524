// components
import { ActivateForm } from './ActivateForm';
import { ExpiredToken } from './ExpiredToken';
import ExpiredTokenMobile from './ExpiredTokenMobile/ExpiredTokenMobile';
import MobileWrapper from '../../Login/FormAuth/Mobile/MobileWrapper';
import LayoutMobile from '../../Layout/LayoutMobile/LayoutMobile';
// utils && hooks
import { useActivateUser } from '../../hooks';

const Content = () => {
    const { loading, status, redirectToLogin } = useActivateUser();
    if (status === 100) return null;
    return (
        <>
            {status === 404 ? (
                <>
                    <ExpiredToken loading={loading} redirectToLogin={redirectToLogin} />
                    <LayoutMobile>
                        <MobileWrapper>
                            <ExpiredTokenMobile loading={loading} redirectToLogin={redirectToLogin} />
                        </MobileWrapper>
                    </LayoutMobile>
                </>
            ) : (
                <ActivateForm />
            )}
        </>
    );
};

export default Content;
