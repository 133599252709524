// react
import { useState } from 'react';
// configs
import { EX4569, EX5057, EX6778 } from 'config/flags';
// next
import { useRouter } from 'next/router';
// rsuite
import { Button, Message, Row, Col, ButtonToolbar, IconButton } from 'rsuite';
// mui
import CloseIcon from '@mui/icons-material/Close';
// interface
import { IAlertBanner } from './AlertBanner.interface';
// utils && hooks
import { useUser } from 'utils/hooks';
import { EErrorCode } from 'utils/enums/comparativeAlerts/comparativeAlerts';
// style
import style from './AlertBanner.module.css';
import { t } from 'i18next';

const AlertBanner = ({ closable, type, header, subtext, srcIcon, onClickOpenDrawer, flag }: IAlertBanner) => {
    // next
    const router = useRouter();
    // state
    const [isHiddenAlert, setIsHiddenAlert] = useState(false);

    const { user } = useUser({});

    const EX6144 = user?.EX6144;

    const hiddenAlert = () => {
        setIsHiddenAlert(true);
        localStorage.setItem('alertBanner', 'false');
    };

    const dontHaveButton = [EErrorCode.MISSING_FORMAT, EErrorCode.CREDIT_LIMIT_REACHED];

    return (
        <Message
            closable={closable}
            type={type}
            className={!isHiddenAlert ? style['message'] : style['message-hidden']}
            style={{
                border: flag === 4 ? '1px solid  #FFEA9F' : '1px solid  #f8cbcb',
                background: flag === 4 ? '#FFF9E6' : '#ffebeb',
                width:
                    !!EX4569 && router.pathname === '/[clientId]/efficient-purchase/[posId]/product-comparison'
                        ? '100vw'
                        : '96.5vw',
            }}
        >
            <Row>
                <div className={style['wrapper-container']}>
                    <Col xs={24} sm={12}>
                        <div className={style['flex-container-left']}>
                            <div className={style['icon-type']}>
                                <img src={srcIcon} />
                            </div>
                            <div className={style['wrapper-text']}>
                                <p className={style['header-styles']}>{header}</p>

                                <p className={style['subtext-styles']}>{subtext}</p>
                            </div>
                        </div>
                    </Col>
                    <Col xs={24} sm={12}>
                        <div className={style['flex-container-right']}>
                            <ButtonToolbar>
                                <Button
                                    appearance="link"
                                    className={
                                        (flag === 2
                                            ? `button__update-credentials-skip-1 ${style['buttons']}`
                                            : style['buttons-coins'],
                                        flag === 4
                                            ? 'button__insufficient-credit-ok-1'
                                            : 'button__update-credentials-skip-1')
                                    }
                                    onClick={() => hiddenAlert()}
                                >
                                    {EX6778 ? null : dontHaveButton.includes(flag) ? (
                                        <span className="button__insufficient-credit-ok-1">
                                            {EX5057
                                                ? t('efficientPurchasing.priorityAndTolerance.labelUnderstood')
                                                : 'Entendido'}
                                        </span>
                                    ) : (
                                        <span className="button__update-credentials-skip-1">Omitir</span>
                                    )}
                                </Button>
                                {EX6778
                                    ? null
                                    : dontHaveButton.includes(flag) && (
                                          <Button
                                              className={`button__update-credentials-update-1 ${style['buttons-update']}`}
                                              onClick={onClickOpenDrawer}
                                          >
                                              <span className="button__update-credentials-update-1">
                                                  {EX5057
                                                      ? t('efficientPurchasing.credentialsError.updateCredentials')
                                                      : 'Actualizar credenciales'}
                                              </span>
                                          </Button>
                                      )}
                            </ButtonToolbar>
                            <IconButton
                                className={style['container-icon']}
                                style={{
                                    bottom: EX6778 ? 10 : 15,
                                }}
                                icon={<CloseIcon className={style['icon']} />}
                                onClick={() => {
                                    setIsHiddenAlert(true), localStorage.setItem('alertBanner', 'false');
                                    window.dispatchEvent(new CustomEvent('alertBannerChange', { detail: true }));
                                }}
                            />
                        </div>
                    </Col>
                </div>
            </Row>
        </Message>
    );
};

export default AlertBanner;
