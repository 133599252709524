// next
import { useRouter } from 'next/router';
// react
import { useEffect, useState, useRef } from 'react';
// components
import { RenderFormFields, RenderFormButton } from '.';
import { useToast } from '@commons/EXComponentsLibrary';
// interface
import { IFormData, IState } from './Suppliers.interface';
// aux
import { auxiliary } from './utils';
// rsuite
import { Panel, Row, Form, Loader } from 'rsuite';
// owner css
import style from './Suppliers.module.css';
//json forms
import data from 'utils/backoffice/informationForm.json';
// dayjs
import dayjs from 'dayjs';
import 'dayjs/locale/es'; // importar el idioma español
dayjs.locale('es');
// utils
import { useClientCountry } from 'utils/hooks/use-clients';
import useCurrencyFormatter from 'utils/hooks/useCurrencyFormatter/useCurrencyFormatter';
import validationSchema from './schema';
// schema

const Suppliers = ({ setModified }) => {
    const formRef = useRef(null);
    // next
    const router = useRouter();
    const { clientId } = router.query as { clientId: string };

    // hook
    const { countries } = useClientCountry();
    const toaster = useToast();

    // state
    const [state, setState] = useState<IState>({
        drugManufacturer: null,
        country: null,
        minimumPurchaseAmount: null,
        form: {},
        modifiedFields: {},
        isLoading: false,
        isFormModified: false,
        isLoadingEdit: false,
        errorsForms: {},
        rehydrateData: false,
    });
    // aux
    const fx = auxiliary();
    const handlerChangeInput = (evt: any, key: string) => fx.handleInputChange(evt, key, setState);
    const handlerSelectAddress = (evt: any) => fx.handleSelectAddress(evt, setState);
    // init
    useEffect(() => {
        if (clientId) {
            (async () => await fx.fetchInformationOfDrugmanufacturer(setState, clientId))();
        }
        
    }, [clientId, state.rehydrateData]);

    useEffect(() => {
        fx.hasModifiedAnyField(state, setState, setModified);
    }, [state.modifiedFields]);

    return (
        <>
            {state.isLoading ? (
                <Loader center size="md" content="Cargando..." vertical className={style['loader-spin']} />
            ) : (
                Object.keys(data as IFormData).map((key) => {
                    return (
                        <div key={key} className={style['container']}>
                            <Panel
                                className={style['container-panel']}
                                bordered
                                header={
                                    <div className={style['container-header']}>
                                        <p>{(data as IFormData)[key].title}</p>
                                        {(data as IFormData)[key].active && (
                                            <span>
                                                {(data as IFormData)[key].active}{' '}
                                                <span className={style['active-date']}>
                                                    {dayjs(state.drugManufacturer?.created_at).format('D  MMM  YYYY')}
                                                </span>
                                            </span>
                                        )}
                                    </div>
                                }
                            >
                                <Form fluid model={validationSchema} ref={formRef}>
                                    <Row gutter={32}>
                                        {RenderFormFields(
                                            (data as IFormData)[key].fields,
                                            key,
                                            handlerChangeInput,
                                            state,
                                            countries,
                                            handlerSelectAddress,
                                        )}
                                    </Row>
                                </Form>
                            </Panel>
                        </div>
                    );
                })
            )}
            {!state.isLoading && (
                <RenderFormButton
                    state={state}
                    setState={setState}
                    clientId={clientId}
                    fx={fx}
                    toaster={toaster}
                />
            )}
        </>
    );
};
export default Suppliers;
