import { memo } from 'react';
// mocks
import { Mock } from './Mock';
// components
import { Item } from './Item';
import { Header } from './Header';
// styles
import styles from './DropdownSkeleton.module.css';

const DropdownSkeleton = () => {
    return (
        <div className={styles['accordion']}>
            {[{ content: Mock?.suggested }, { content: Mock?.connected }].map((item: any, index) => {
                if (item?.content?.length > 0) {
                    return (
                        <div key={index + 1} className={styles['wrapper']}>
                            <Header item={item} />
                            <div className={`${styles['accordion-content']} ${styles['show']}`}>
                                {index + 1 === 1
                                    ? item.content
                                          .slice(0, 3)
                                          .map((child, index) => <Item key={index + 1} from={index + 1} />)
                                    : item.content.map((child, index) => <Item key={index + 1} from={index + 1} />)}
                            </div>
                        </div>
                    );
                }
            })}
        </div>
    );
};

export default memo(DropdownSkeleton);
