import { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
// configs
import { EX6697 } from 'config/flags';
// contexts
import { usePointOfSale } from 'context/point-of-sale-context';
// utils
import { fetchDataService } from '../utils';
import { useUser } from 'utils/hooks';
import { useDrugManufacturersContext } from 'context/productComparison/drugManufacturers-context';

const defaultValues = {
    data: {},
    error: null,
    isLoading: false,
};

// Hook separado para manejar la lógica del contexto con un manejo de promesas mejorado
export const usePrioritiesPercentagesCeLogic = () => {
    const { drugManufacturer } = useDrugManufacturersContext();
    const { pointOfSale } = usePointOfSale();
    const { query } = useRouter();
    const { user } = useUser({});
    // states
    const [data, setData] = useState<any>(defaultValues.data);
    const [error, setError] = useState<string | null>(defaultValues.error);
    const [isLoading, setIsLoading] = useState<boolean>(defaultValues.isLoading);

    const serviceId: number = EX6697
        ? drugManufacturer[0]?.service_id
        : user?.pointsOfSale?.find((p) => pointOfSale?.id === p.id)?.service_id;

    const fetchData = useCallback(async () => {
        if (serviceId) {
            setIsLoading(true);
            setError(null);
            try {
                const fetchedData = await fetchDataService(+serviceId, query?.orderId as string);
                setData(fetchedData);
            } catch (err) {
                setError(err.message);
            } finally {
                setIsLoading(false);
            }
        }
    }, [serviceId, query?.orderId]);

    useEffect(() => {
        if (EX6697) {
            fetchData();
        } else {
            if (!!user) {
                fetchData();
            }
        }
    }, [fetchData, serviceId]);

    return { data, error, isLoading, refetch: fetchData, serviceId };
};
