import Image from 'next/image';
import React from 'react';
import style from './PaymentError.module.css';
const PaymentError = () => {
    return (
        <div className={style['container']}>
            <Image src="/assets/images/astro.svg" width={500} height={500} alt="" className={style['image']} />
            <p className={style['text-advice']}>
                Lamentamos las molestias. El enlace que intentaste acceder ya no está disponible.
                <br />
                Por favor, verifica tu correo para obtener un nuevo enlace o contáctanos para más información.
            </p>
        </div>
    );
};

export default PaymentError;
