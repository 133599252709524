import { EX5225 } from 'config/flags';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useTranslation } from 'react-i18next';
import { useUser } from 'utils/hooks';
import useChangeLanguage from 'utils/hooks/use-change-language';
import { ICharts } from './Charts.interface';
import style from './Charts.module.css';

const HighchartsData = ({ optionsChart }: ICharts) => {
    // translation by Country
    const { t } = useTranslation();
    const { userLocaleCountryCode } = useUser({});
    if (EX5225) useChangeLanguage(userLocaleCountryCode);

    return (
        <div className={style['container']}>
            <div className={style['container-chart']}>
                <span className={style['span-complete-order']}>
                    {EX5225
                        ? t('efficientPurchasing.sendingPurchase.efficientPurchasing_sendingPurchaseFive')
                        : 'Pedido completo'}
                </span>
                <span className={style['span-complete-order-bolder']}>100%</span>
            </div>

            <HighchartsReact
                containerProps={{
                    style: {
                        margin: 0,
                        padding: 0,
                        height: '118px',
                    },
                }}
                highcharts={Highcharts}
                options={optionsChart}
            />
        </div>
    );
};

export default HighchartsData;
