import { FC, ReactNode } from 'react';
// styles
import styles from './InstitutionalHeader.module.css';

interface InstitutionalHeaderProps {
    children: ReactNode;
}

const InstitutionalHeader: FC<InstitutionalHeaderProps> = ({ children }) => {
    return (
        <div className={styles.container}>
            <div className={styles.content}>{children}</div>
        </div>
    );
};

export default InstitutionalHeader;
