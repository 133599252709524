import React from 'react';
import style from './EmptyAvailable.module.css';
import { t } from 'i18next';

const EmptyAvailable = () => {
    return (
        <div className={style['container-available-panel']}>
            <img src={'/assets/images/congrats.svg'} />

            <p className={style['container-available-text']}>
                <span className={style['congrats-text']}>{t('home.tabs.congratulations')}</span>
                <span>{t('home.tabs.connectedAll')}</span>
            </p>
        </div>
    );
};

export default EmptyAvailable;
