// rsuite
import { Whisper, Popover } from 'rsuite';
// style
import style from './NotificationPanel.module.css';
import { WithoutNotifications } from '../WithoutNotifications';
import { NotificationHeader } from '../NotificationHeader';

const NotificationPanel = ({ children }) => {
    return (
        <Whisper
            speaker={
                <Popover className={style['container-popover-notification']} arrow={false}>
                  <NotificationHeader />
                    <WithoutNotifications />
                </Popover>
            }
            trigger="click"
            enterable
            placement="bottomEnd"
        >
            {children}
        </Whisper>
    );
};

export default NotificationPanel;
