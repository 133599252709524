// interface
import { IProfile } from './Profile.interface';

// rsuite
import { Avatar } from 'rsuite';
// style
import style from './Profile.module.css';

const Profile = ({ initials }: IProfile) => {
    return (
        <div className={style['container-avatar']}>
            <Avatar size="sm" circle className={style['avatar-index']}>
                <span>{initials}</span>
            </Avatar>
        </div>
    );
};

export default Profile;
