import { useEffect, useState } from 'react';
//css
import style from './InputForm.module.css';
//rsuite
import { Input, InputGroup } from 'rsuite';
//mui
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { EX5057 } from 'config/flags';
import useChangeLanguage from 'utils/hooks/use-change-language';
import { COUNTRY } from 'utils/country';
import { useTranslation } from 'react-i18next';

const InputForm = ({
    inputValue,
    updateValues,
    values,
    activeUser,
    disableInputMail,
    validLength,
    setValidLength,
    hasUppercase,
    setHasUppercase,
    hasNumber,
    setHasNumber,
}) => {
    //translation by Country
    const { t } = useTranslation();
    const [visible, setVisible] = useState(false);
    const inputPassErrors =
        inputValue?.name !== 'email' &&
        activeUser &&
        (!validLength || !hasUppercase || !hasNumber) &&
        values[inputValue?.name] !== '';

    const handleChange = () => {
        setVisible(!visible);
    };
    const type = inputValue?.name === 'email' ? 'text' : visible ? 'text' : 'password';
    const handleInputValue = (inputname, event) => {
        if (
            inputname === 'password' && inputValue?.placeholder !== EX5057
                ? t('login.login34')
                : 'Nueva contraseña' && inputValue?.placeholder !== EX5057
                ? t('login.login35')
                : 'Repetí la nueva contraseña'
        ) {
            updateValues(inputValue?.name, event);
            checkPassword(event);
        } else if (inputValue?.placeholder === EX5057 ? t('login.login34') : 'Nueva contraseña') {
            checkPassword(event);
            updateValues('NewPassword', event);
        } else if (inputValue?.placeholder === EX5057 ? t('login.login35') : 'Repetí la nueva contraseña') {
            updateValues('RepetedPassword', event);
        } else {
            updateValues(inputValue?.name, event);
        }
    };

    const checkPassword = (password) => {
        setValidLength(password.length >= 8);

        setHasUppercase(/[A-Z]/.test(password));

        setHasNumber(/\d/.test(password));
    };

    const handleInputName = () => {
        if (inputValue?.name === 'RepetedPassword' || inputValue?.name === 'NewPassword') {
            return 'password';
        } else {
            return inputValue?.name;
        }
    };

    const userLocale = typeof window !== 'undefined' ? navigator?.language : null;
    switch (userLocale) {
        case 'es-AR':
            useChangeLanguage(COUNTRY.AR);
            break;
        case 'en-US':
            useChangeLanguage(COUNTRY.US);
            break;
        case 'pt-BR':
            useChangeLanguage(COUNTRY.BR);
            break;
        case 'es-MX':
            useChangeLanguage(COUNTRY.MX);
            break;
        case 'es-CO':
            useChangeLanguage(COUNTRY.CO);
            break;
        case 'es-ES':
            useChangeLanguage(COUNTRY.ES);
            break;
        default:
            useChangeLanguage('es');
            break;
    }

    return (
        <InputGroup inside className={inputPassErrors ? style['input-container-error'] : style['input-container']}>
            <Input
                readOnly={inputValue?.name === 'email' && disableInputMail}
                name={handleInputName()}
                value={values[inputValue?.name]}
                type={type}
                onChange={(e) => handleInputValue(inputValue?.name, e)}
                className={inputPassErrors ? style['input-login-error'] : style['input-login']}
                placeholder={inputValue?.placeholder}
                autoComplete='on'
            />
            {handleInputName() === 'password' && (
                <InputGroup.Addon onClick={handleChange}>
                    {visible ? (
                        <VisibilityIcon className={style['icon-eye']} />
                    ) : (
                        <VisibilityOffIcon className={style['icon-eye']} />
                    )}
                </InputGroup.Addon>
            )}
        </InputGroup>
    );
};

export default InputForm;
