import React from 'react';
import Link from 'next/link';
import { t } from 'i18next';
// components
import { Header } from './Header';
import { Panel } from 'rsuite';
import { FormRecoveryPassword } from './FormRecoveryPassword';
import ArrowLeftLineIcon from '@rsuite/icons/ArrowLeftLine';
import { ExButton } from '@commons/EXComponentsLibrary';
// utils && hooks
import { useMailTo, useRecovery } from '../hooks';
// styles
import styles from './RecoveryPassword.module.css';
import MobileWrapper from '../Login/FormAuth/Mobile/MobileWrapper';
import { HeaderMobile } from '../HeaderMobile';
import LayoutMobile from '../Layout/LayoutMobile/LayoutMobile';
import Image from 'next/image';

const RecoveryPassword = () => {
    const { formState, updateFormState, onSubmit, onChangeCaptcha, loading, success, redirectToLogin } = useRecovery();
    const { showMailTo } = useMailTo();

    return (
        <>
            <div className={styles['container']}>
                <Panel bordered className={`${styles['panel']} ${success ? styles['custom-width'] : ''}`}>
                    <Header />
                    <FormRecoveryPassword
                        formState={formState}
                        onSubmit={onSubmit}
                        onChangeCaptcha={onChangeCaptcha}
                        loading={loading}
                        updateFormState={updateFormState}
                        success={success}
                    />
                    {success && (
                        <ExButton
                            type="submit"
                            status="initial"
                            withArrow={true}
                            fullWidth
                            size="lg"
                            loading={loading}
                            onClick={redirectToLogin}
                        >
                            {t('login.login4')}
                        </ExButton>
                    )}
                    {!success && (
                        <Link href={'/login'} className={styles['redirect-login']}>
                            <ArrowLeftLineIcon />
                            <p>{t('login.login27')}</p>
                        </Link>
                    )}
                </Panel>
                <div className={`link__back-to-login-1 ${styles['contact-us']}`} onClick={showMailTo}>
                    {t('login.login21')}
                </div>
            </div>
            {/* MOBILE */}
            <LayoutMobile>
                <MobileWrapper>
                    <HeaderMobile />
                    {/* title */}
                    <div className={styles['title-form']}>
                        <div>
                            <span className={styles['icon']}>
                                {success ? (
                                    <Image
                                        src={'/assets/images/error.svg'}
                                        alt="lock icon"
                                        width={24}
                                        height={24}
                                        draggable={false}
                                    />
                                ) : (
                                    <Image
                                        src={'/assets/images/lock_blue.png'}
                                        alt="lock icon"
                                        width={24}
                                        height={24}
                                        draggable={false}
                                    />
                                )}
                            </span>
                            <span>{!success ? t('login.login6') : t('login.login9')}</span>
                        </div>
                    </div>
                    <p className={styles['subtitle']}>
                        {!success ? t('login.reset_password_text') : t('login.login29')}
                    </p>

                    <FormRecoveryPassword
                        formState={formState}
                        onSubmit={onSubmit}
                        onChangeCaptcha={onChangeCaptcha}
                        loading={loading}
                        updateFormState={updateFormState}
                        success={success}
                    />
                    {success && (
                        <ExButton
                            type="submit"
                            status="initial"
                            withArrow={true}
                            fullWidth
                            size="lg"
                            loading={loading}
                            onClick={redirectToLogin}
                        >
                            {t('login.login4')}
                        </ExButton>
                    )}
                    {!success && (
                        <Link href={'/login'} className={styles['redirect-login']}>
                            <ArrowLeftLineIcon />
                            <p>{t('login.login27')}</p>
                        </Link>
                    )}
                </MobileWrapper>
                <div className={`link__back-to-login-1 ${styles['contact-us']}`} onClick={showMailTo}>
                    {t('login.login21')}
                </div>
            </LayoutMobile>
        </>
    );
};

export default RecoveryPassword;
