// icons
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import HeadsetMicOutlinedIcon from '@mui/icons-material/HeadsetMicOutlined';



export const itemsSettings = [
  {
    label: 'Chat',
    icon: '',
  },
  {
    label: 'Ayuda',
    icon: '',
    href: '',
  },
];
