import React from 'react';
import { Grid, useTheme } from '@material-ui/core';
import Button from 'components/Button';
import * as gtag from 'utils/gtag';
import useStyles from './CredentialCardFooter.styles';
import { CustomCircularProgress } from 'components';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { DrugManufacturersId, Role } from 'common/types';
import { useUser } from 'utils/hooks';
import { drugManufacturerGtagEvent } from 'utils/gtagEventDM';
import { ButtonSolid } from 'commons/components/Button';
import { EX4600, EX5057 } from 'config/flags';
import { useTranslation } from 'react-i18next';
import useChangeLanguage from 'utils/hooks/use-change-language';

function CredentialCardFooter({
    title,
    onCancel,
    loading,
    disable,
    onSubmit,
    nameSelect,
    format_status,
    deleteComponent,
    handleSubmitFormat,
    handleFileInputChange,
    clientIdentifier,
    stepAddress,
    enabledCatalog,
    drugmanufacturerId,
}) {
    // translation by Country
    const { t } = useTranslation();
    const { user, userLocaleCountryCode } = useUser({});
    if (EX5057) useChangeLanguage(userLocaleCountryCode);

    const classes = useStyles();
    const { palette } = useTheme();
    const EX3649 = process.env.NEXT_PUBLIC_EX3649 === 'true';

    const handleClickSaveCredentials = (e) => {
        e.preventDefault();

        gtag.event({
            action: 'click',
            category: 'settings-pos',
            label: `button__save-credentials-d${title
                .split(' ')
                .find((item) => item)
                .toLowerCase()}`,
            value: 0,
        });
        e.stopPropagation();
        onSubmit();
    };

    const renderHtmlG4Tag = () => {
        const nameEvent = drugManufacturerGtagEvent.find(
            (drug_manufacturer) => drug_manufacturer.drug_manufacturer_name.toLowerCase() === title.toLowerCase(),
        )?.name_event;
        return `button__save-credentials-d${nameEvent?.toLowerCase()}`;
    };

    const showNextButtonAddressSelection = () => {
        return (
            clientIdentifier &&
            clientIdentifier[0]?.fieldType === 'autoComplete' &&
            stepAddress === 1 &&
            nameSelect === 'scraper' &&
            drugmanufacturerId !== DrugManufacturersId.FarmaceuticosTenorioSAdeCV
        );
    };

    const handleValidationSpecialDrugmanufacturer = () => {
        if (EX4600) {
            return (
                nameSelect !== 'api' &&
                user.role_id === Role.Admin &&
                drugmanufacturerId !== DrugManufacturersId.FarmaciasABC
            );
        } else {
            return nameSelect !== 'api' && user.role_id === Role.Admin;
        }
    };

    return (
        <div className={classes.root}>
            <Grid container>
                <Grid xs={2} style={{ height: '36.5px' }}>
                    {deleteComponent}
                </Grid>
                <Grid xs={5}>
                    {handleValidationSpecialDrugmanufacturer() ? (
                        <div className={classes.container_submitFormat}>
                            <div className={classes.row_submitFormat}>
                                {loading ? (
                                    <div className={classes.row_flex}>
                                        <div style={{ width: 4, height: 4, marginRight: 10 }}>
                                            <CustomCircularProgress style={{ marginBottom: 20 }} />
                                        </div>
                                        <p className={classes.text_correctFormat}>Comprobando formato...</p>
                                    </div>
                                ) : format_status?.name === 'VALIDATED' ? (
                                    <div className={classes.row_flex}>
                                        <CheckCircleIcon style={{ color: '#07AD4B', height: 16, width: 16 }} />
                                        <p className={classes.text_correctFormat}>Formato validado</p>
                                    </div>
                                ) : (
                                    !enabledCatalog && (
                                        <>
                                            <div
                                                style={{
                                                    display:
                                                        drugmanufacturerId === DrugManufacturersId.AlmacenDeDrogas ||
                                                        (EX4600 &&
                                                            drugmanufacturerId === DrugManufacturersId.FarmaciasABC)
                                                            ? 'none'
                                                            : 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between',
                                                }}
                                            >
                                                <div className={classes.row_flex}>
                                                    <img
                                                        src={
                                                            handleSubmitFormat()
                                                                ? '/assets/images/upload_disable.svg'
                                                                : '/assets/images/upload.svg'
                                                        }
                                                        style={{ width: 20, height: 20 }}
                                                    />

                                                    {/* @ts-ignore */}
                                                    <label
                                                        htmlFor="upload-photo"
                                                        className={
                                                            handleSubmitFormat() ? classes.labelDisabled : classes.label
                                                        }
                                                    >
                                                        Subir formato web
                                                    </label>
                                                </div>
                                            </div>

                                            <input
                                                type="file"
                                                name="photo"
                                                disabled={handleSubmitFormat()}
                                                className={classes.uploadPhoto}
                                                onChange={handleFileInputChange}
                                                id="upload-photo"
                                            />
                                        </>
                                    )
                                )}
                            </div>
                        </div>
                    ) : (
                        ''
                    )}
                </Grid>
                {/* @ts-ignore */}
                <Grid container item xs={5} alignItems="flex-end" justifyContent="flex-end">
                    <Button colorName={palette.neutral} onClick={onCancel} variant="text" style={{ color: '#6B6F93' }}>
                        {t('credentialplaceholder_three') || 'Cancelar'}
                    </Button>
                    <div style={{ marginLeft: 8 }}>
                        {showNextButtonAddressSelection() && EX3649 ? (
                            <ButtonSolid
                                type="button"
                                disabled={disable}
                                onClick={(event) => {
                                    event.preventDefault();
                                    handleClickSaveCredentials(event);
                                }}
                                text={
                                    t(
                                        'efficientPurchasing.priorityAndTolerance.efficientPurchasing_priorityAndToleranceNext',
                                    ) || 'Siguiente'
                                }
                                height={40}
                                width={''}
                            />
                        ) : (
                            <>
                                <ButtonSolid
                                    disabled={disable}
                                    type="submit"
                                    height={40}
                                    width={''}
                                    onClick={handleClickSaveCredentials}
                                    text={
                                        <span className={renderHtmlG4Tag()}>
                                            {EX5057
                                                ? t(
                                                      'efficientPurchasing.priorityAndTolerance.efficientPurchasing_priorityAndToleranceFifteen',
                                                  )
                                                : 'Guardar'}
                                        </span>
                                    }
                                />
                            </>
                        )}
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

export default CredentialCardFooter;
