import { Grid } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Autocomplete } from '@mui/material';
import InfoMessage from 'commons/components/Orders/InfoMessage/InfoMessage';
import { CustomCircularProgress } from 'components';
import TextField from 'components/TextField';
import { EX3986, EX3992, EX4600, EX5021, EX5057, EXCEPTIONSHOWTABSSTAGING } from 'config/flags';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUser } from 'utils/hooks';
import useChangeLanguage from 'utils/hooks/use-change-language';
import useStyles from './CredentialCardBody.styles';
import OperationType from './OperationType';
import Tabs from './Tabs/Tabs';
import { DrugManufacturersId } from 'utils/interfaces';
import usePaymentMethods from 'utils/hooks/usePaymentMethods';

function CredentialCardBody({
    error,
    errors,
    loading,
    register,
    onChange,
    setValue,
    getValues,
    nameSelect,
    saveSuccess,
    idError,
    setIdError,
    setCredencialId,
    clientIdentifier,
    buyOperationType,
    credential_types,
    handleFormatRequest,
    stepAddress,
    setAddressSelected,
    itHasAddressesSelected,
    addresses,
    addressInUse,
    pointOfSaleName,
    errorPasswordUsername,
    setErrorPasswordUsername,
    paymentMethod,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    drugmanufacturerId,
    catalogs,
    catalogSelected,
    setCatalogSelected,
    itHasCatalogSelected,
    idCatalogLoaded,
    nameCatalogLoaded,
    deposits,
    itUsesDeposits,
    depositSelected,
    setDepositSelected,
    clientTypes,
    itUsesClientTypes,
    clientTypeSelected,
    setClientTypeSelected,
    trigger,
    depositsFarmaMX,
}) {
    // translate by country
    const { t } = useTranslation();
    const { user, userLocaleCountryCode } = useUser({});
    if (EX3992) useChangeLanguage(userLocaleCountryCode);
    const classes = useStyles();
    const [credentialType, setCredentialType] = React.useState<'user' | 'token'>('user');
    const [tabError, setTabError] = React.useState({});
    const [showPassword, setShowPassword] = React.useState(false);
    const [showInputClientNumber, setShowInputClientNumber] = React.useState(false);
    const EX3529 = process.env.NEXT_PUBLIC_EX3529 === 'true';
    const EX3649 = process.env.NEXT_PUBLIC_EX3649 === 'true';

    const oldFormVicma = DrugManufacturersId.Vicma === drugmanufacturerId;
    const oldFormCoopidrogas = DrugManufacturersId.Coopidrogas === drugmanufacturerId;

    const EX5305 = user?.EX5305;

    interface OptionType {
        label: string;
    }

    interface NewValueType {
        label: string;
        value: string;
    }

    const { getPaymentMethods, results } = usePaymentMethods();
    const [currentPaymentMethods, setCurrentPaymentMethods] = React.useState<{ id: number; label: string }[]>([]);

    const showAddressInput = () => {
        return clientIdentifier && clientIdentifier[0]?.fieldType === 'autoComplete' && nameSelect === 'scraper';
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleShowInputClientNumber = () => {
        if (credential_types.length > 2 && nameSelect === 'scraper_np') {
            setShowInputClientNumber(true);
        } else {
            setShowInputClientNumber(false);
        }
    };

    const handleSwitch = (name) => {
        const newWeb = EX3529 ? t('settings.editPointsOfSale.sett_newWebTextPointsOfSale') : 'web nueva';
        const currentWeb = EX3529 ? t('settings.editPointsOfSale.sett_currentWebTextPointsOfSale') : 'web actual';

        switch (name) {
            case 'scraper':
                if (credential_types.length > 2) {
                    return currentWeb;
                } else {
                    return 'web';
                }
            case 'scraper_np':
                return newWeb;
            default:
                if (drugmanufacturerId === DrugManufacturersId.DrogueriasDelSud) {
                    return '';
                } else {
                    return 'api';
                }
        }
    };

    React.useEffect(() => {
        handleShowInputClientNumber();
    }, [clientIdentifier]);

    React.useEffect(() => {
        setErrorPasswordUsername(false);
        credential_types.map((credential) => {
            if (credential?.errors?.length > 0 && credential?.name === nameSelect && !!EX3986) {
                setErrorPasswordUsername(true);
                setIdError(credential?.errors[0].id);
            }
        });
    }, [nameSelect]);

    React.useEffect(() => {
        if (!!EX3986) {
            credential_types.map((credential) => {
                const bool = credential?.errors?.length > 0;
                const key = credential.name;
                setTabError((prevObject) => {
                    // Use the spread operator to create a shallow copy of the previous object
                    return { ...prevObject, [key]: bool };
                });
            });
        }
    }, [nameSelect]);

    const handleErrorText = () => {
        switch (idError) {
            case 2:
                return EX5057
                    ? t('efficientPurchasing.credentialsError.panelErrorUserOrPassword')
                    : 'Usuario o contraseña incorrectos.';
            case 3:
                return 'Usuario y contraseña inválido para comprar.';
            default:
                return EX5057
                    ? t('efficientPurchasing.credentialsError.panelErrorUserOrPassword')
                    : 'Usuario o contraseña incorrectos.';
        }
    };

    // update state and field value in form by change selection
    const handleChangeAutocomplete = (event, newValue, name) => {
        // update local state with new value selected in autocomplete
        if (EX5305 && drugmanufacturerId === DrugManufacturersId.FarmaMX) {
            setDepositSelected(`${newValue?.value}&&${newValue?.label}`);
            setValue('client_identifier', `${newValue?.value}&&${newValue?.label}`);
        } else {
            itUsesDeposits && setDepositSelected(newValue);
            itUsesClientTypes && setClientTypeSelected(newValue);
            // update form field value
            setValue(name, newValue);
        }
    };

    React.useEffect(() => {
        if (drugmanufacturerId === DrugManufacturersId.FarmaceuticosTenorioSAdeCV) {
            getPaymentMethods(drugmanufacturerId);
        }
    }, []);

    React.useEffect(() => {
        if (results?.paymentMethods) {
            setCurrentPaymentMethods(results.paymentMethods);
        }
    }, [results]);

    React.useEffect(() => {
        if (drugmanufacturerId === DrugManufacturersId.FarmaceuticosTenorioSAdeCV) {
            register('autoComplete', {
                required: clientIdentifier[0].regex?.messageError,
            });
        }
    }, [drugmanufacturerId, register, clientIdentifier]);

    return (
        <>
            <OperationType buyOperationType={buyOperationType} />
            <div
                style={{
                    margin: 'auto',
                    width: 400,
                    marginTop: 16,
                    marginBottom: 25,
                }}
            >
                {(drugmanufacturerId === DrugManufacturersId.DrogueriasDelSud ||
                    (EXCEPTIONSHOWTABSSTAGING &&
                        (drugmanufacturerId === DrugManufacturersId.SuizoArgentina ||
                            drugmanufacturerId === DrugManufacturersId.Disval ||
                            drugmanufacturerId === DrugManufacturersId.Asoprofarma ||
                            drugmanufacturerId === DrugManufacturersId.MonroeAmericana))) && (
                    <Tabs
                        credential_types={credential_types}
                        nameSelect={nameSelect}
                        onChange={onChange}
                        setCredencialId={setCredencialId}
                        handleSwitch={handleSwitch}
                        tabError={tabError}
                    />
                )}
                {clientIdentifier?.length && drugmanufacturerId === DrugManufacturersId.AlmacenDeDrogas && (
                    <>
                        {clientIdentifier[0]?.fieldType !== 'autoComplete' && (
                            <TextField
                                type="text"
                                name={
                                    clientIdentifier[0]?.name === '' ? 'client_identifier' : clientIdentifier[0]?.name
                                }
                                placeholder={clientIdentifier[0]?.placeholder}
                                label={clientIdentifier[0]?.placeholder}
                                inputRef={register({
                                    required: clientIdentifier[0]?.required,
                                    pattern: {
                                        value: clientIdentifier[0]?.regex?.value,
                                        message: clientIdentifier[0]?.regex?.messageError,
                                    },
                                })}
                                helperText={
                                    clientIdentifier[0]?.forceErrors && errors?.client_identifier
                                        ? errors?.client_identifier?.message
                                        : ''
                                }
                                style={{
                                    marginTop: 30,
                                }}
                                error={Boolean(clientIdentifier[0]?.forceErrors && errors.client_identifier)}
                            />
                        )}
                        <div style={{ marginTop: 30, marginBottom: 10 }}>
                            <Autocomplete
                                disablePortal
                                id="catalog-select-label"
                                options={catalogs.map((option) => option.name)}
                                className={classes.autoComplete}
                                value={catalogSelected?.length === 0 ? nameCatalogLoaded : catalogSelected}
                                noOptionsText={'Catálogo'}
                                onChange={(event, newValue) => {
                                    setCatalogSelected(newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        name="autoComplete"
                                        className={classes.autoComplete}
                                        error={Boolean(errors.autoComplete)}
                                        {...params}
                                        label="Catálogo"
                                    />
                                )}
                            />
                        </div>
                    </>
                )}

                {EX4600 && clientIdentifier?.length && drugmanufacturerId === DrugManufacturersId.FarmaciasABC && (
                    <>
                        {clientIdentifier[0]?.fieldType !== 'autoComplete' && (
                            <TextField
                                type="text"
                                name={
                                    clientIdentifier[0]?.name === '' ? 'client_identifier' : clientIdentifier[0]?.name
                                }
                                placeholder={clientIdentifier[0]?.placeholder}
                                label={clientIdentifier[0]?.placeholder}
                                inputRef={register({
                                    required: clientIdentifier[0]?.required,
                                    pattern: {
                                        value: clientIdentifier[0]?.regex?.value,
                                        message: clientIdentifier[0]?.regex?.messageError,
                                    },
                                })}
                                helperText={
                                    clientIdentifier[0]?.forceErrors && errors?.client_identifier
                                        ? errors?.client_identifier?.message
                                        : ''
                                }
                                error={Boolean(clientIdentifier[0]?.forceErrors && errors.client_identifier)}
                            />
                        )}
                    </>
                )}

                {itUsesClientTypes && drugmanufacturerId === DrugManufacturersId.Coopidrogas && (
                    <>
                        <Autocomplete
                            id={'client-types-select-label'}
                            disablePortal
                            noOptionsText="No hay tipos de clientes disponibles"
                            className={classes.autoComplete}
                            value={clientTypeSelected}
                            options={clientTypes}
                            onChange={(event, newValue) => {
                                handleChangeAutocomplete(event, newValue, 'client_identifier');
                            }}
                            ListboxProps={{
                                style: {
                                    maxHeight: 150,
                                    overflow: 'auto',
                                },
                            }}
                            style={{ marginTop: 30, display: stepAddress === 2 ? 'none' : '' }}
                            renderInput={(params) => (
                                <TextField
                                    name={'client_identifier'}
                                    fullWidth
                                    label={'Tipo de cliente'}
                                    placeholder={'Selecciona un tipo de cliente de la lista'}
                                    error={Boolean(errors.client_identifier)}
                                    inputRef={register({ required: true })}
                                    {...params}
                                />
                            )}
                        />
                        <TextField
                            type="text"
                            name="username"
                            placeholder={t('credentialplaceholder_one') || 'Usuario'}
                            label={t('credentialplaceholder_one') || 'Usuario'}
                            inputProps={{
                                'aria-label': 'username',
                            }}
                            inputRef={register({ required: true })}
                            style={{
                                marginTop: 30,
                                display: stepAddress === 2 ? 'none' : '',
                            }}
                            error={Boolean(errors.username) || errorPasswordUsername}
                        />
                        <TextField
                            type={showPassword ? 'text' : 'password'}
                            name="password"
                            placeholder={t('credentialplaceholder_two') || 'password'}
                            label={t('credentialplaceholder_two') || 'password'}
                            defaultValue={getValues('client_identifier')}
                            inputRef={register({ required: true })}
                            style={{
                                marginTop: 30,
                                display: stepAddress === 2 ? 'none' : '',
                                visibility: credentialType === 'token' ? 'hidden' : undefined,
                            }}
                            error={Boolean(errors.password) || errorPasswordUsername}
                            endAdornment={
                                <InputAdornment position="end" style={{ display: stepAddress === 2 ? 'none' : '' }}>
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        disableRipple={true}
                                        disableFocusRipple={true}
                                        classes={{
                                            root: classes.disable_hover,
                                        }}
                                    >
                                        {showPassword ? (
                                            <img src="/assets/images/visibility_off.svg" width={22} height={22} />
                                        ) : (
                                            <img src="/assets/images/visibility.svg" width={22} height={22} />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </>
                )}
                {itUsesDeposits && drugmanufacturerId === DrugManufacturersId.Vicma && (
                    <>
                        <Autocomplete
                            id={'deposit-select-label'}
                            disablePortal
                            noOptionsText="No hay depósitos disponibles"
                            className={classes.autoComplete}
                            value={depositSelected}
                            options={deposits}
                            onChange={(event, newValue) => {
                                handleChangeAutocomplete(event, newValue, 'client_identifier');
                            }}
                            ListboxProps={{
                                style: {
                                    maxHeight: 150,
                                    overflow: 'auto',
                                },
                            }}
                            style={{ marginTop: 30, display: stepAddress === 2 ? 'none' : '' }}
                            renderInput={(params) => (
                                <TextField
                                    name={'client_identifier'}
                                    fullWidth
                                    label={'Depósito'}
                                    placeholder={'Selecciona un depósito de la lista'}
                                    error={Boolean(errors.client_identifier)}
                                    inputRef={register({ required: true })}
                                    {...params}
                                />
                            )}
                        />
                        <TextField
                            type="text"
                            name="username"
                            placeholder={t('credentialplaceholder_one') || 'Usuario'}
                            label={t('credentialplaceholder_one') || 'Usuario'}
                            inputProps={{
                                'aria-label': 'username',
                            }}
                            inputRef={register({ required: true })}
                            style={{
                                marginTop: 30,
                                display: stepAddress === 2 ? 'none' : '',
                            }}
                            error={Boolean(errors.username) || errorPasswordUsername}
                        />
                        <TextField
                            type={showPassword ? 'text' : 'password'}
                            name="password"
                            placeholder={t('credentialplaceholder_two') || 'password'}
                            label={t('credentialplaceholder_two') || 'password'}
                            defaultValue={getValues('client_identifier')}
                            inputRef={register({ required: true })}
                            style={{
                                marginTop: 30,
                                display: stepAddress === 2 ? 'none' : '',
                                visibility: credentialType === 'token' ? 'hidden' : undefined,
                            }}
                            error={Boolean(errors.password) || errorPasswordUsername}
                            endAdornment={
                                <InputAdornment position="end" style={{ display: stepAddress === 2 ? 'none' : '' }}>
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        disableRipple={true}
                                        disableFocusRipple={true}
                                        classes={{
                                            root: classes.disable_hover,
                                        }}
                                    >
                                        {showPassword ? (
                                            <img src="/assets/images/visibility_off.svg" width={22} height={22} />
                                        ) : (
                                            <img src="/assets/images/visibility.svg" width={22} height={22} />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </>
                )}
                {clientIdentifier?.length &&
                    nameSelect === 'scraper' &&
                    clientIdentifier[1]?.credentialType === 'WEB Suizo' && (
                        <>
                            {credentialType === 'user' ? (
                                <TextField
                                    type="text"
                                    name="username"
                                    placeholder={t('credentialplaceholder_one') || 'Usuario'}
                                    label={t('credentialplaceholder_one') || 'Usuario'}
                                    inputRef={register({ required: true })}
                                    style={{ marginTop: 30 }}
                                    error={Boolean(errors.username) || errorPasswordUsername}
                                />
                            ) : (
                                <TextField
                                    type="text"
                                    name="token"
                                    placeholder="Token"
                                    label="Token"
                                    inputProps={{ 'aria-label': 'token' }}
                                    style={{ marginTop: 30 }}
                                    inputRef={register()}
                                />
                            )}
                            <TextField
                                type={showPassword ? 'text' : 'password'}
                                name="password"
                                placeholder={t('credentialplaceholder_two') || 'Contraseña'}
                                label={t('credentialplaceholder_two') || 'Contraseña'}
                                defaultValue={getValues('client_identifier')}
                                inputRef={register({ required: true })}
                                style={{
                                    marginTop: 30,
                                    visibility: credentialType === 'token' ? 'hidden' : undefined,
                                }}
                                error={Boolean(errors.password) || errorPasswordUsername}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            disableRipple={true}
                                            disableFocusRipple={true}
                                            classes={{
                                                root: classes.disable_hover,
                                            }}
                                        >
                                            {showPassword ? (
                                                <img src="/assets/images/visibility_off.svg" width={22} height={22} />
                                            ) : (
                                                <img src="/assets/images/visibility.svg" width={22} height={22} />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            <TextField
                                type="text"
                                className={classes.inputNumber}
                                name={
                                    clientIdentifier[1]?.name === '' ? 'client_identifier' : clientIdentifier[1]?.name
                                }
                                placeholder={clientIdentifier[1]?.placeholder}
                                label={clientIdentifier[1]?.placeholder}
                                onChange={(event) => {
                                    const currentValue = event.target.value;
                                    const formatedValue = currentValue.replace(/[^0-9]/g, '');

                                    setValue('client_identifier', formatedValue);
                                }}
                                inputRef={register({
                                    required: clientIdentifier[1]?.required,
                                    pattern: {
                                        value: clientIdentifier[1]?.regex?.value,
                                        message: clientIdentifier[1]?.regex?.messageError,
                                    },
                                })}
                                helperText={
                                    clientIdentifier[1]?.forceErrors && errors?.client_identifier
                                        ? errors?.client_identifier?.message
                                        : ''
                                }
                                style={{
                                    marginTop: 30,
                                }}
                                error={Boolean(clientIdentifier[1]?.forceErrors && errors.client_identifier)}
                            />
                        </>
                    )}
                {clientIdentifier?.credentialType !== 'credentialType' &&
                    nameSelect === 'api' &&
                    !clientIdentifier?.length && (
                        <>
                            {credentialType === 'user' ? (
                                <TextField
                                    type="text"
                                    name="username"
                                    placeholder={t('credentialplaceholder_one') || 'Usuario'}
                                    label={t('credentialplaceholder_one') || 'Usuario'}
                                    inputProps={{
                                        'aria-label': 'username',
                                    }}
                                    inputRef={register({ required: true })}
                                    style={{ marginTop: 30 }}
                                    error={Boolean(errors.username) || errorPasswordUsername}
                                />
                            ) : (
                                <TextField
                                    type="text"
                                    name="token"
                                    placeholder="Token"
                                    label="Token"
                                    inputProps={{ 'aria-label': 'token' }}
                                    style={{ marginTop: 30 }}
                                    inputRef={register()}
                                />
                            )}
                            <TextField
                                type={showPassword ? 'text' : 'password'}
                                name="password"
                                placeholder={t('credentialplaceholder_two') || 'Contraseña'}
                                label={t('credentialplaceholder_two') || 'Contraseña'}
                                defaultValue={getValues('client_identifier')}
                                inputRef={register({ required: true })}
                                style={{
                                    marginTop: 30,
                                    visibility: credentialType === 'token' ? 'hidden' : undefined,
                                }}
                                error={Boolean(errors.password) || errorPasswordUsername}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            disableRipple={true}
                                            disableFocusRipple={true}
                                            classes={{
                                                root: classes.disable_hover,
                                            }}
                                        >
                                            {showPassword ? (
                                                <img src="/assets/images/visibility_off.svg" width={22} height={22} />
                                            ) : (
                                                <img src="/assets/images/visibility.svg" width={22} height={22} />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </>
                    )}
                {clientIdentifier?.credentialType !== 'credentialType' &&
                    nameSelect === 'scraper' &&
                    !clientIdentifier?.length &&
                    !oldFormVicma &&
                    !oldFormCoopidrogas && (
                        <>
                            {credentialType === 'user' ? (
                                <TextField
                                    type="text"
                                    name="username"
                                    placeholder={t('credentialplaceholder_one') || 'Usuario'}
                                    label={t('credentialplaceholder_one') || 'Usuario'}
                                    inputProps={{
                                        'aria-label': 'username',
                                    }}
                                    inputRef={register({ required: true })}
                                    style={{ marginTop: 30 }}
                                    error={Boolean(errors.username) || errorPasswordUsername}
                                />
                            ) : (
                                <TextField
                                    type="text"
                                    name="token"
                                    placeholder="Token"
                                    label="Token"
                                    inputProps={{ 'aria-label': 'token' }}
                                    style={{ marginTop: 30 }}
                                    inputRef={register()}
                                />
                            )}
                            <TextField
                                type={showPassword ? 'text' : 'password'}
                                name="password"
                                placeholder={t('credentialplaceholder_two') || 'Contraseña'}
                                label={t('credentialplaceholder_two') || 'Contraseña'}
                                defaultValue={getValues('client_identifier')}
                                inputRef={register({ required: true })}
                                style={{
                                    marginTop: 30,
                                    visibility: credentialType === 'token' ? 'hidden' : undefined,
                                }}
                                error={Boolean(errors.password) || errorPasswordUsername}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            disableRipple={true}
                                            disableFocusRipple={true}
                                            classes={{
                                                root: classes.disable_hover,
                                            }}
                                        >
                                            {showPassword ? (
                                                <img src="/assets/images/visibility_off.svg" width={22} height={22} />
                                            ) : (
                                                <img src="/assets/images/visibility.svg" width={22} height={22} />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </>
                    )}
                {clientIdentifier?.credentialType === 'credentialType' && nameSelect === 'api' && (
                    <>
                        {credentialType === 'user' ? (
                            <TextField
                                type="text"
                                name="username"
                                placeholder={t('credentialplaceholder_one') || 'Usuario'}
                                label={t('credentialplaceholder_one') || 'Usuario'}
                                inputProps={{
                                    'aria-label': 'username',
                                }}
                                inputRef={register({ required: true })}
                                style={{ marginTop: 30 }}
                                error={Boolean(errors.username) || errorPasswordUsername}
                            />
                        ) : (
                            <TextField
                                type="text"
                                name="token"
                                placeholder="Token"
                                label="Token"
                                inputProps={{ 'aria-label': 'token' }}
                                style={{ marginTop: 30 }}
                                inputRef={register()}
                            />
                        )}
                        <TextField
                            type={showPassword ? 'text' : 'password'}
                            name="password"
                            placeholder={t('credentialplaceholder_two') || 'Contraseña'}
                            label={t('credentialplaceholder_two') || 'Contraseña'}
                            defaultValue={getValues('client_identifier')}
                            inputRef={register({ required: true })}
                            style={{
                                marginTop: 30,
                                visibility: credentialType === 'token' ? 'hidden' : undefined,
                            }}
                            error={Boolean(errors.password) || errorPasswordUsername}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        disableRipple={true}
                                        disableFocusRipple={true}
                                        classes={{
                                            root: classes.disable_hover,
                                        }}
                                    >
                                        {showPassword ? (
                                            <img src="/assets/images/visibility_off.svg" width={22} height={22} />
                                        ) : (
                                            <img src="/assets/images/visibility.svg" width={22} height={22} />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />

                        <TextField
                            type="text"
                            name={clientIdentifier?.name === '' ? 'client_identifier' : clientIdentifier?.name}
                            placeholder={clientIdentifier?.placeholder}
                            label={clientIdentifier?.placeholder}
                            onChange={(event) => {
                                const currentValue = event.target.value;
                                const formatedValue = currentValue.replace(/[^0-9]/g, '');

                                setValue('client_identifier', formatedValue);
                            }}
                            inputRef={register({
                                required: clientIdentifier?.required,
                                pattern: {
                                    value: clientIdentifier?.regex?.value,
                                    message: clientIdentifier?.regex?.messageError,
                                },
                            })}
                            helperText={
                                clientIdentifier?.forceErrors && errors?.client_identifier
                                    ? errors?.client_identifier?.message
                                    : ''
                            }
                            style={{
                                marginTop: 30,
                            }}
                            error={Boolean(clientIdentifier?.forceErrors && errors.client_identifier)}
                        />
                    </>
                )}
                {clientIdentifier?.credentialType === 'credentialType' && nameSelect === 'scraper' && (
                    <>
                        {credentialType === 'user' ? (
                            <TextField
                                type="text"
                                name="username"
                                placeholder={t('credentialplaceholder_one') || 'Usuario'}
                                label={t('credentialplaceholder_one') || 'Usuario'}
                                inputProps={{
                                    'aria-label': 'username',
                                }}
                                inputRef={register({ required: true })}
                                style={{ marginTop: 30 }}
                                error={Boolean(errors.username) || errorPasswordUsername}
                            />
                        ) : (
                            <TextField
                                type="text"
                                name="token"
                                placeholder="Token"
                                label="Token"
                                inputProps={{ 'aria-label': 'token' }}
                                style={{ marginTop: 30 }}
                                inputRef={register()}
                            />
                        )}
                        <TextField
                            type={showPassword ? 'text' : 'password'}
                            name="password"
                            placeholder={t('credentialplaceholder_two') || 'Contraseña'}
                            label={t('credentialplaceholder_two') || 'Contraseña'}
                            defaultValue={getValues('client_identifier')}
                            inputRef={register({ required: true })}
                            style={{
                                marginTop: 30,
                                visibility: credentialType === 'token' ? 'hidden' : undefined,
                            }}
                            error={Boolean(errors.password) || errorPasswordUsername}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        disableRipple={true}
                                        disableFocusRipple={true}
                                        classes={{
                                            root: classes.disable_hover,
                                        }}
                                    >
                                        {showPassword ? (
                                            <img src="/assets/images/visibility_off.svg" width={22} height={22} />
                                        ) : (
                                            <img src="/assets/images/visibility.svg" width={22} height={22} />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        <TextField
                            type="text"
                            name={clientIdentifier?.name === '' ? 'client_identifier' : clientIdentifier?.name}
                            placeholder={clientIdentifier?.placeholder}
                            label={clientIdentifier?.placeholder}
                            onChange={(event) => {
                                if (clientIdentifier?.type !== 'text') {
                                    const currentValue = event.target.value;
                                    const formatedValue = currentValue.replace(/[^0-9]/g, '');

                                    setValue('client_identifier', formatedValue);
                                } else {
                                    setValue('client_identifier', event.target.value);
                                }
                            }}
                            inputRef={register({
                                required: clientIdentifier?.required,
                                pattern: {
                                    value: clientIdentifier?.regex?.value,
                                    message: clientIdentifier?.regex?.messageError,
                                },
                            })}
                            helperText={
                                clientIdentifier?.forceErrors && errors?.client_identifier
                                    ? errors?.client_identifier?.message
                                    : ''
                            }
                            style={{
                                marginTop: 30,
                            }}
                            error={Boolean(clientIdentifier?.forceErrors && errors.client_identifier)}
                        />
                    </>
                )}
                {clientIdentifier?.length &&
                    !showInputClientNumber &&
                    nameSelect === 'api' &&
                    clientIdentifier[1]?.credentialType === 'WEB Suizo' && (
                        <>
                            {credentialType === 'user' ? (
                                <TextField
                                    type="text"
                                    name="username"
                                    placeholder={t('credentialplaceholder_one') || 'Usuario'}
                                    label={t('credentialplaceholder_one') || 'Usuario'}
                                    inputProps={{
                                        'aria-label': 'username',
                                    }}
                                    inputRef={register({ required: true })}
                                    style={{ marginTop: 30 }}
                                    error={Boolean(errors.username) || errorPasswordUsername}
                                />
                            ) : (
                                <TextField
                                    type="text"
                                    name="token"
                                    placeholder="Token"
                                    label="Token"
                                    inputProps={{ 'aria-label': 'token' }}
                                    style={{ marginTop: 30 }}
                                    inputRef={register()}
                                />
                            )}
                            <TextField
                                type={showPassword ? 'text' : 'password'}
                                name="password"
                                placeholder={t('credentialplaceholder_two') || 'Contraseña'}
                                label={t('credentialplaceholder_two') || 'Contraseña'}
                                defaultValue={getValues('client_identifier')}
                                inputRef={register({ required: true })}
                                style={{
                                    marginTop: 30,
                                    visibility: credentialType === 'token' ? 'hidden' : undefined,
                                }}
                                error={Boolean(errors.password) || errorPasswordUsername}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            disableRipple={true}
                                            disableFocusRipple={true}
                                            classes={{
                                                root: classes.disable_hover,
                                            }}
                                        >
                                            {showPassword ? (
                                                <img src="/assets/images/visibility_off.svg" width={22} height={22} />
                                            ) : (
                                                <img src="/assets/images/visibility.svg" width={22} height={22} />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />

                            <TextField
                                type="text"
                                name={
                                    clientIdentifier[0]?.name === '' ? 'client_identifier' : clientIdentifier[0]?.name
                                }
                                placeholder={clientIdentifier[0]?.placeholder}
                                label={clientIdentifier[0]?.placeholder}
                                disabled={true}
                                inputRef={register({
                                    required: clientIdentifier[0]?.required,
                                    pattern: {
                                        value: clientIdentifier[0]?.regex?.value,
                                        message: clientIdentifier[0]?.regex?.messageError,
                                    },
                                })}
                                helperText={
                                    clientIdentifier[0]?.forceErrors && errors?.client_identifier
                                        ? errors?.client_identifier?.message
                                        : ''
                                }
                                style={{
                                    marginTop: 30,
                                }}
                                error={Boolean(clientIdentifier[0]?.forceErrors && errors.client_identifier)}
                            />
                        </>
                    )}
                {clientIdentifier?.length &&
                    !showInputClientNumber &&
                    nameSelect === 'api' &&
                    clientIdentifier[1]?.credentialType !== 'WEB Suizo' && (
                        <>
                            {credentialType === 'user' ? (
                                <TextField
                                    type="text"
                                    name="username"
                                    placeholder={t('credentialplaceholder_one') || 'Usuario'}
                                    label={t('credentialplaceholder_one') || 'Usuario'}
                                    inputProps={{
                                        'aria-label': 'username',
                                    }}
                                    inputRef={register({ required: true })}
                                    style={{ marginTop: 30 }}
                                    error={Boolean(errors.username) || errorPasswordUsername}
                                />
                            ) : (
                                <TextField
                                    type="text"
                                    name="token"
                                    placeholder="Token"
                                    label="Token"
                                    inputProps={{ 'aria-label': 'token' }}
                                    style={{ marginTop: 30 }}
                                    inputRef={register()}
                                />
                            )}
                            <TextField
                                type={showPassword ? 'text' : 'password'}
                                name="password"
                                placeholder={t('credentialplaceholder_two') || 'Contraseña'}
                                label={t('credentialplaceholder_two') || 'Contraseña'}
                                defaultValue={getValues('client_identifier')}
                                inputRef={register({ required: true })}
                                style={{
                                    marginTop: 30,
                                    visibility: credentialType === 'token' ? 'hidden' : undefined,
                                }}
                                error={Boolean(errors.password) || errorPasswordUsername}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            disableRipple={true}
                                            disableFocusRipple={true}
                                            classes={{
                                                root: classes.disable_hover,
                                            }}
                                        >
                                            {showPassword ? (
                                                <img src="/assets/images/visibility_off.svg" width={22} height={22} />
                                            ) : (
                                                <img src="/assets/images/visibility.svg" width={22} height={22} />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />

                            {clientIdentifier[0]?.fieldType !== 'autoComplete' && (
                                <TextField
                                    type="text"
                                    name={
                                        clientIdentifier[0]?.name === ''
                                            ? 'client_identifier'
                                            : clientIdentifier[0]?.name
                                    }
                                    placeholder={clientIdentifier[0]?.placeholder}
                                    label={clientIdentifier[0]?.placeholder}
                                    inputRef={register({
                                        required: clientIdentifier[0]?.required,
                                        pattern: {
                                            value: clientIdentifier[0]?.regex?.value,
                                            message: clientIdentifier[0]?.regex?.messageError,
                                        },
                                    })}
                                    helperText={
                                        clientIdentifier[0]?.forceErrors && errors?.client_identifier
                                            ? errors?.client_identifier?.message
                                            : ''
                                    }
                                    style={{
                                        marginTop: 30,
                                    }}
                                    error={Boolean(clientIdentifier[0]?.forceErrors && errors.client_identifier)}
                                />
                            )}
                        </>
                    )}

                {drugmanufacturerId === DrugManufacturersId.FarmaMX && (
                    <>
                        {stepAddress === 2 && (
                            <Autocomplete
                                id="deposits-farmamx"
                                options={depositsFarmaMX}
                                getOptionLabel={(option: OptionType) => option?.label}
                                onChange={(event, newValue: NewValueType) => {
                                    handleChangeAutocomplete(event, newValue, 'client_identifier');
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Depósito"
                                        placeholder="Selecciona un depósito de la lista"
                                        inputRef={register({ required: true })}
                                    />
                                )}
                            />
                        )}

                        {stepAddress === 1 && (
                            <>
                                <TextField
                                    type="text"
                                    name="username"
                                    placeholder={t('credentialplaceholder_one') || 'Usuario'}
                                    label={t('credentialplaceholder_one') || 'Usuario'}
                                    inputProps={{
                                        'aria-label': 'username',
                                    }}
                                    inputRef={register({ required: true })}
                                    style={{
                                        marginTop: 30,
                                        display: stepAddress === 2 ? 'none' : '',
                                    }}
                                    error={Boolean(errors.username) || errorPasswordUsername}
                                />

                                <TextField
                                    type={showPassword ? 'text' : 'password'}
                                    name="password"
                                    placeholder={t('credentialplaceholder_two') || 'Contraseña'}
                                    label={t('credentialplaceholder_two') || 'Contraseña'}
                                    defaultValue={getValues('client_identifier')}
                                    inputRef={register({ required: true })}
                                    style={{
                                        marginTop: 30,
                                        display: stepAddress === 2 ? 'none' : '',
                                        visibility: credentialType === 'token' ? 'hidden' : undefined,
                                    }}
                                    error={Boolean(errors.password) || errorPasswordUsername}
                                    endAdornment={
                                        <InputAdornment
                                            position="end"
                                            style={{ display: stepAddress === 2 ? 'none' : '' }}
                                        >
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                disableRipple={true}
                                                disableFocusRipple={true}
                                                classes={{
                                                    root: classes.disable_hover,
                                                }}
                                            >
                                                {showPassword ? (
                                                    <img
                                                        src="/assets/images/visibility_off.svg"
                                                        width={22}
                                                        height={22}
                                                    />
                                                ) : (
                                                    <img src="/assets/images/visibility.svg" width={22} height={22} />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                {depositSelected?.length > 0 && (
                                    <TextField
                                        type="text"
                                        name="client_identifier"
                                        placeholder={'Depósito'}
                                        label={'Depósito'}
                                        defaultValue={depositSelected}
                                        inputProps={{
                                            'aria-label': 'depósito',
                                        }}
                                        inputRef={register({ required: true })}
                                        style={{
                                            marginTop: 30,
                                        }}
                                        error={Boolean(errors.client_identifier)}
                                        disabled={true}
                                    />
                                )}
                            </>
                        )}
                    </>
                )}
                {drugmanufacturerId !== DrugManufacturersId.FarmaMX &&
                    drugmanufacturerId !== DrugManufacturersId.AlmacenDeDrogas &&
                    drugmanufacturerId !== DrugManufacturersId.FarmaciasABC &&
                    clientIdentifier?.length &&
                    !showInputClientNumber &&
                    clientIdentifier[1]?.credentialType !== 'WEB Suizo' &&
                    nameSelect === 'scraper' && (
                        <>
                            {itUsesDeposits && (
                                <Autocomplete
                                    id={'deposit-select-label'}
                                    disablePortal
                                    noOptionsText="No hay depósitos disponibles"
                                    className={classes.autoComplete}
                                    value={depositSelected}
                                    options={deposits}
                                    onChange={(event, newValue) => {
                                        handleChangeAutocomplete(event, newValue, 'client_identifier');
                                    }}
                                    ListboxProps={{
                                        style: {
                                            maxHeight: 150,
                                            overflow: 'auto',
                                        },
                                    }}
                                    style={{ marginTop: 30, display: stepAddress === 2 ? 'none' : '' }}
                                    renderInput={(params) => (
                                        <TextField
                                            name={'client_identifier'}
                                            fullWidth
                                            label={'Depósito'}
                                            placeholder={'Selecciona un depósito de la lista'}
                                            error={Boolean(errors.client_identifier)}
                                            inputRef={register({ required: true })}
                                            {...params}
                                        />
                                    )}
                                />
                            )}
                            {credentialType === 'user' ? (
                                <TextField
                                    type="text"
                                    name="username"
                                    placeholder={t('credentialplaceholder_one') || 'Usuario'}
                                    label={t('credentialplaceholder_one') || 'Usuario'}
                                    inputProps={{
                                        'aria-label': 'username',
                                    }}
                                    inputRef={register({ required: true })}
                                    style={{
                                        marginTop: 30,
                                        display: stepAddress === 2 ? 'none' : '',
                                    }}
                                    error={Boolean(errors.username) || errorPasswordUsername}
                                />
                            ) : (
                                <TextField
                                    type="text"
                                    name="token"
                                    placeholder="Token"
                                    label="Token"
                                    inputProps={{ 'aria-label': 'token' }}
                                    style={{ marginTop: 30 }}
                                    inputRef={register()}
                                />
                            )}
                            <TextField
                                type={showPassword ? 'text' : 'password'}
                                name="password"
                                placeholder={t('credentialplaceholder_two') || 'Contraseña'}
                                label={t('credentialplaceholder_two') || 'Contraseña'}
                                defaultValue={getValues('client_identifier')}
                                inputRef={register({ required: true })}
                                style={{
                                    marginTop: 30,
                                    display: stepAddress === 2 ? 'none' : '',
                                    visibility: credentialType === 'token' ? 'hidden' : undefined,
                                }}
                                error={Boolean(errors.password) || errorPasswordUsername}
                                endAdornment={
                                    <InputAdornment position="end" style={{ display: stepAddress === 2 ? 'none' : '' }}>
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            disableRipple={true}
                                            disableFocusRipple={true}
                                            classes={{
                                                root: classes.disable_hover,
                                            }}
                                        >
                                            {showPassword ? (
                                                <img src="/assets/images/visibility_off.svg" width={22} height={22} />
                                            ) : (
                                                <img src="/assets/images/visibility.svg" width={22} height={22} />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            {clientIdentifier[0]?.fieldType !== 'autoComplete' && (
                                <TextField
                                    type="text"
                                    name={
                                        clientIdentifier[0]?.name === ''
                                            ? 'client_identifier'
                                            : clientIdentifier[0]?.name
                                    }
                                    placeholder={clientIdentifier[0]?.placeholder}
                                    label={clientIdentifier[0]?.placeholder}
                                    inputRef={register({
                                        required: clientIdentifier[0]?.required,
                                        pattern: {
                                            value: clientIdentifier[0]?.regex?.value,
                                            message: clientIdentifier[0]?.regex?.messageError,
                                        },
                                    })}
                                    helperText={
                                        clientIdentifier[0]?.forceErrors && errors?.client_identifier
                                            ? errors?.client_identifier?.message
                                            : ''
                                    }
                                    style={{
                                        marginTop: 30,
                                    }}
                                    error={Boolean(clientIdentifier[0]?.forceErrors && errors.client_identifier)}
                                />
                            )}
                            {drugmanufacturerId === DrugManufacturersId.FarmaceuticosTenorioSAdeCV && (
                                <div style={{ marginTop: 30, marginBottom: 10 }}>
                                    <Autocomplete
                                        disablePortal
                                        id="address-select-label"
                                        options={currentPaymentMethods}
                                        className={classes.autoComplete}
                                        value={selectedPaymentMethod || ''}
                                        noOptionsText={clientIdentifier[0]?.stepLabel}
                                        onChange={(event, newValue) => {
                                            setSelectedPaymentMethod(newValue);
                                            setValue('autoComplete', newValue || '', { shouldValidate: true });
                                            trigger('autoComplete');
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={clientIdentifier[0]?.placeholder}
                                                error={Boolean(errors.autoComplete)}
                                                helperText={errors.autoComplete?.message}
                                            />
                                        )}
                                    />
                                </div>
                            )}
                        </>
                    )}
                {clientIdentifier?.length && showInputClientNumber && nameSelect === 'scraper_np' && (
                    <>
                        {credentialType === 'user' ? (
                            <TextField
                                type="text"
                                name="username"
                                placeholder={t('credentialplaceholder_one') || 'Usuario'}
                                label={t('credentialplaceholder_one') || 'Usuario'}
                                inputProps={{
                                    'aria-label': 'username',
                                }}
                                inputRef={register({ required: true })}
                                style={{ marginTop: 30 }}
                                error={Boolean(errors.username) || errorPasswordUsername}
                            />
                        ) : (
                            <TextField
                                type="text"
                                name="token"
                                placeholder="Token"
                                label="Token"
                                inputProps={{ 'aria-label': 'token' }}
                                style={{ marginTop: 30 }}
                                inputRef={register()}
                            />
                        )}
                        <TextField
                            type={showPassword ? 'text' : 'password'}
                            name="password"
                            placeholder={t('credentialplaceholder_two') || 'Contraseña'}
                            label={t('credentialplaceholder_two') || 'Contraseña'}
                            defaultValue={getValues('client_identifier')}
                            inputRef={register({ required: true })}
                            style={{
                                marginTop: 30,
                                visibility: credentialType === 'token' ? 'hidden' : undefined,
                            }}
                            error={Boolean(errors.password) || errorPasswordUsername}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        disableRipple={true}
                                        disableFocusRipple={true}
                                        classes={{
                                            root: classes.disable_hover,
                                        }}
                                    >
                                        {showPassword ? (
                                            <img src="/assets/images/visibility_off.svg" width={22} height={22} />
                                        ) : (
                                            <img src="/assets/images/visibility.svg" width={22} height={22} />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        <TextField
                            type="text"
                            className={classes.inputNumber}
                            name={clientIdentifier[1]?.name === '' ? 'client_identifier' : clientIdentifier[1]?.name}
                            placeholder={'Número de Cliente'}
                            label={'Número de Cliente'}
                            onChange={(event) => {
                                const currentValue = event.target.value;
                                const formatedValue = currentValue.replace(/[^0-9]/g, '');

                                setValue('client_identifier', formatedValue);
                            }}
                            inputRef={register({
                                required: clientIdentifier[1]?.required,
                                pattern: {
                                    value: clientIdentifier[1]?.regex?.value,
                                    message: clientIdentifier[1]?.regex?.messageError,
                                },
                            })}
                            helperText={
                                clientIdentifier[1]?.forceErrors && errors?.client_identifier
                                    ? errors?.client_identifier?.message
                                    : ''
                            }
                            style={{
                                marginTop: 30,
                            }}
                            error={Boolean(clientIdentifier[1]?.forceErrors && errors.client_identifier)}
                        />
                    </>
                )}
                {showAddressInput() && stepAddress === 1 && itHasAddressesSelected && EX3649 && (
                    <div style={{ marginTop: 30 }}>
                        <Autocomplete
                            disablePortal
                            id="address-select-label"
                            options={clientIdentifier[0].name !== 'payment_methods' ? addresses : paymentMethod}
                            className={classes.autoComplete}
                            value={itHasAddressesSelected}
                            defaultValue={itHasAddressesSelected}
                            inputValue={itHasAddressesSelected}
                            noOptionsText={clientIdentifier[0]?.stepLabel}
                            disabled={true}
                            onChange={(event, newValue) => {
                                if (clientIdentifier[0].name !== 'payment_methods') {
                                    setAddressSelected(newValue);
                                } else {
                                    setSelectedPaymentMethod(newValue);
                                }
                            }}
                            renderInput={(params) => (
                                <TextField
                                    name="autoComplete"
                                    className={classes.autoComplete}
                                    error={Boolean(errors.autoComplete)}
                                    inputRef={register({
                                        required: clientIdentifier?.required,
                                        pattern: {
                                            value: clientIdentifier?.regex?.value,
                                            message: clientIdentifier?.regex?.messageError,
                                        },
                                    })}
                                    {...params}
                                    label={clientIdentifier[0]?.placeholder}
                                />
                            )}
                        />
                    </div>
                )}
                {showAddressInput() && stepAddress === 2 && EX3649 && (
                    <div style={{ marginTop: 30, marginBottom: 10 }}>
                        <Autocomplete
                            disablePortal
                            id="address-select-label"
                            options={clientIdentifier[0].name !== 'payment_methods' ? addresses : paymentMethod}
                            className={classes.autoComplete}
                            noOptionsText={clientIdentifier[0]?.stepLabel}
                            onChange={(event, newValue) => {
                                if (clientIdentifier[0].name !== 'payment_methods') {
                                    setAddressSelected(newValue);
                                } else {
                                    setSelectedPaymentMethod(newValue);
                                }
                            }}
                            renderInput={(params) => (
                                <TextField
                                    name="autoComplete"
                                    className={classes.autoComplete}
                                    error={Boolean(errors.autoComplete)}
                                    inputRef={register({
                                        required: clientIdentifier?.required,
                                        pattern: {
                                            value: clientIdentifier?.regex?.value,
                                            message: clientIdentifier?.regex?.messageError,
                                        },
                                    })}
                                    {...params}
                                    label={clientIdentifier[0]?.placeholder}
                                />
                            )}
                        />
                    </div>
                )}

                {addressInUse && stepAddress === 2 && EX3649 && (
                    <InfoMessage
                        children={
                            <>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <span>
                                        El punto de venta <b>{pointOfSaleName}</b> tiene la misma dirección de entrega
                                    </span>
                                    {!EX3992
                                        ? '¿De todas formas querés recibir tus pedidos en esta dirección?'
                                        : t('settings.editPointsOfSale.sett_questionReceiveOrderByAddress')}
                                </div>
                            </>
                        }
                        backgroundColor={'#FFF9E6'}
                        IconColor={'#FFC700'}
                    />
                )}
                {saveSuccess && !loading && stepAddress === 1 && (
                    <div style={{ marginTop: 5 }}>
                        <span className={classes.success}>
                            {EX3529
                                ? t('settings.editPointsOfSale.sett_saveSuccessfullTextPointsOfSale')
                                : 'Usuario y contraseña guardado con éxito.'}
                        </span>
                    </div>
                )}
                {(error || errorPasswordUsername) && !loading && (
                    <div style={{ marginTop: 5 }}>
                        <span className={classes.error}>{handleErrorText()}</span>
                    </div>
                )}
                {loading ? (
                    // @ts-ignore
                    <Grid
                        style={{ marginTop: 30 }}
                        container
                        item
                        // @ts-ignore
                        xs={15}
                        justify="center"
                        alignItems="center"
                    >
                        <CustomCircularProgress />
                    </Grid>
                ) : handleFormatRequest() ? (
                    <p className={classes.formatRequest}>Formato solicitado: {handleFormatRequest()}</p>
                ) : (
                    ''
                )}
            </div>
        </>
    );
}

export default CredentialCardBody;
