// component
import { RsuiteTooltip } from '@commons/components/RsuiteTooltip';
// styles
import styles from './UnknownError.module.css';
import { StatusBadge } from '../../StatusBadge';
import { EX5872 } from 'config/flags';

const UnknownError = ({ truncateText }) => {
  return (
    <div className={styles['container-name']}>
            <RsuiteTooltip
                placement="top"
                text={truncateText.texts}
                trigger="hover"
                disabled={!truncateText.hasEllipsis}
            >
                <div className={styles['name']}>
                  { EX5872 && 
                    <StatusBadge offline={false} isEnabled={false} unknown={true} disabledTooltip={true}  />
                  } 
                  <span>{truncateText.texts} </span>
                </div>
            </RsuiteTooltip>
        </div>
  )
}

export default UnknownError;