import { Col, Row } from "rsuite";
import ParagraphLoader from "../ParagraphLoader/ParagraphLoader";
import style from "./SearchResults.module.css";

const SearchResultsLoader = () => {
    return (<Col xs={22}>
        <Row style={{padding: '3px 16px'}}>
            <Col xs={2}>
                <ParagraphLoader graph="image" className={style["paragraphLoaderImage"]} rows={0}  />
            </Col>
            <Col xs={22}>
                <ParagraphLoader rows={1} rowHeight={15} active />
                <ParagraphLoader rows={1} rowHeight={15} active />
            </Col>
        </Row>
    </Col>)
}

export default SearchResultsLoader;