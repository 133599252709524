import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  subtitle: {
    fontSize: 16,
    color: "#666666",
    marginBottom: 0,
    marginTop: 0,
  },
  toleranceInputContainer: {
    width: 512,
    height: 64,
    display: "flex",
    marginBottom: 24,
    marginTop: 24,
    backgroundColor: "#FFF3D6",
    borderRadius: 4,
  },
  toleranceText: {
    color: "#333333",
    fontSize: 16,
    textTransform: "uppercase",
    fontWeight: 600,
  },
  toleranceTextContainer: {
    width: 216,
    display: "flex",
    alignItems: "center",
    paddingLeft: 16,
  },
  buttonBackgroung: {
    background: theme.palette.Ra[400],
    color: "white",
    paddingLeft: "16px",
    paddingRight: "16px",
    "&:hover": {
      background: "#80E1E4",
    },
  },
  button: {
    "&.MuiButton-root": {
      color: theme.palette.Ra[400],
      border: `1px solid  ${theme.palette.Ra[400]}`,
      paddingLeft: "16px",
      paddingRight: "16px",
      "&:hover": {
        background: theme.palette.Ra[100],
        color: theme.palette.Ra[400],
        border: `1px solid  ${theme.palette.Ra[400]}`,
      },
    },
  },
}));
