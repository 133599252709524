import { t } from 'i18next';
import { ButtonToolbar, Col, Row } from 'rsuite';
import { useUser } from 'utils/hooks';
import { Role } from 'common/types';
import * as gtag from 'utils/gtag';
import { useTheme } from '@material-ui/core';
import Menu from 'components/Menu';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { rgba } from 'polished';
import style from './AlertModal.module.css';
import { ButtonGhost, ButtonOutline, ButtonSolid } from 'commons/components/Button';
import { EX3992, EX5057 } from 'config/flags';
import { useDrugManufacturersContext, useProductsContext } from 'context';
import { productHasCheckedQuantity } from 'utils/productComparison/productHasCheckedQuantity';

const AlertModal = ({
    distribution,
    handleClose,
    setOpen,
    open,
    control,
    openModal,
    setOpenModalConfigDistribution,
    openModalConfigDistribution,
    setOpenModalConfigPriorities,
    openModalConfigPriorities,
    ...props
}) => {
    // translate by country
    const { user } = useUser({});
    const EX4927 = user?.EX4927;
    const EX5937 = user?.EX5937;
    const { productGroups, products } = useProductsContext();
    const { enabledOnOff, isEnabledDrugManufacturer } = useDrugManufacturersContext();

    const { palette } = useTheme();

    const unselectedProductQuantity = productHasCheckedQuantity(products, enabledOnOff, isEnabledDrugManufacturer, EX5937).length;
    const MAX_PRODUCT_VIEW = 5;

    const productIndexText = productHasCheckedQuantity(products, enabledOnOff, isEnabledDrugManufacturer, EX5937)
        ?.map(({ barcode }) => {
            const index = productGroups.findIndex((item) => item?.barcode === barcode);
            if (index > -1) {
                return index + 1;
            }
        })
        ?.sort((a, b) => a - b)
        ?.slice(0, MAX_PRODUCT_VIEW)
        ?.join(', ')
        ?.concat(unselectedProductQuantity > MAX_PRODUCT_VIEW ? ' y otros' : '')
        ?.concat('.');

    const disableConfig = user.role_id !== Role.Buyer;
    const EX3526 = process.env.NEXT_PUBLIC_EX3526 === 'true';
    const TextDistributionConfig = !EX3992
        ? `Hay productos sin selección porque tienen precios iguales. Para que el sistema haga una selección automática, define un porcentaje a cada droguería.`
        : t('efficientPurchasing.priorityAndTolerance.efficientPurchasing_priorityAndToleranceTwo');
    const TextPrioritiesConfig = EX3526
        ? t('efficientPurchasing.priorityAndTolerance.efficientPurchasing_priorityAndToleranceTwo')
        : `Hay productos sin selección porque tienen precios iguales. Selecciona manualmente una droguería en cada producto o configura la selección automática.`;
    const TextConfigBuyer = EX3526
        ? t('efficientPurchasing.porcentualDistribution.efficientPurchasing_porcentualDistributionFifteen')
        : `Hay productos sin selección porque tienen precios iguales. Selecciona manualmente una droguería para cada producto o solicita a un usuario con rol de gerente que configure la selección automática de productos.`;

    const handleCloseModalConfig = () => {
        gtag.event({
            action: 'click',
            category: 'efficient-purchase__product-comparison',
            label: 'tooltip-equal-prices__supplier-priority-config',
            value: 0,
        });
        if (distribution) {
            setOpenModalConfigDistribution(!openModalConfigDistribution);
        } else {
            setOpenModalConfigPriorities(!openModalConfigPriorities);
        }

        handleClose();
    };
    const handleText = () => {
        if (!disableConfig && distribution) {
            return TextConfigBuyer;
        } else if (distribution) {
            return TextDistributionConfig;
        } else {
            return TextPrioritiesConfig;
        }
    };
    return EX4927 ? (
        <Menu
            {...props}
            open={openModal}
            style={{
                backgroundColor: rgba(palette.neutral[900], 0.3),
                padding: 0,
                top: 8,
            }}
        >
            <div className={style['container-without-selection']}>
                <div className={style['without-selection-content-container']}>
                    <div className={style['content-header']}>
                        <div className={style['content-header-left']}>
                            <img src='/assets/images/error.svg' alt='Error' />
                            <span className={style['content-header-title']}>
                                {EX5057
                                    ? t(
                                          'efficientPurchasing.priorityAndTolerance.efficientPurchasing_priorityAndToleranceOne',
                                      )
                                    : 'Hay productos sin selección'}
                            </span>
                        </div>
                        <div>
                            <img src='/assets/images/close.svg' alt='Close' onClick={handleClose}/>
                        </div>
                    </div>

                    {control && (
                        <div className={style['content-error-msg']}>
                            {distribution
                                ? EX3526
                                    ? t(
                                          'efficientPurchasing.priorityAndTolerance.efficientPurchasing_priorityAndToleranceTwentyFive',
                                      )
                                    : EX3526
                                    ? t(
                                          'efficientPurchasing.porcentualDistribution.efficientPurchasing_porcentualDistributionSixteen',
                                      )
                                    : `Hay nuevas droguerías. Asígnale un porcentaje para activar la selección automática.`
                                : `Hay nuevas droguerías. Asígnale un orden de prioridad para
                  activar la selección automática.`}
                        </div>
                    )}

                    <div className={style['without-selection-content']}>
                        <p className={style['without-selection-content-text']}>{handleText()}</p>

                        <p className={style['without-selection-content-text']}>
                            {EX5057 ? t('prioritiesAndTolerance.labelProductsWithoutSelection') : 'Productos N°'}:
                            <span className={style['numbers']}> {productIndexText}</span>
                        </p>
                    </div>
                </div>

                <ButtonToolbar className={style['content-footer']}>
                    <ButtonGhost
                        text={EX5057 ? t('efficientPurchasing.priorityAndTolerance.labelUnderstood') : 'Entendido'}
                        onClick={handleClose}
                        height={40}
                        width={''}
                    />
                    <ButtonSolid
                        gtagClass={
                            distribution
                                ? 'tooltip__supplier-percent-distribution-config'
                                : 'tooltip__supplier-priority-config'
                        }
                        text={
                            <span
                                className={
                                    distribution
                                        ? 'tooltip__supplier-percent-distribution-config'
                                        : 'tooltip__supplier-priority-config'
                                }
                            >
                                {distribution
                                    ? EX5057
                                        ? t(
                                              'efficientPurchasing.porcentualDistribution.efficientPurchasing_porcentualDistributionFive',
                                          )
                                        : 'Configurar Porcentajes'
                                    : EX5057
                                    ? t(
                                          'efficientPurchasing.priorityAndTolerance.efficientPurchasing_priorityAndToleranceFive',
                                      )
                                    : 'Configurar Prioridad'}
                            </span>
                        }
                        className={style['button']}
                        display={!disableConfig && distribution ? 'none' : ''}
                        onClick={handleCloseModalConfig}
                        height={40}
                        width={''}
                    />
                </ButtonToolbar>
            </div>
        </Menu>
    ) : (
        <Menu
            {...props}
            open={openModal}
            style={{
                backgroundColor: rgba(palette.neutral[900], 0.3),
                padding: 0,
                top: 8,
            }}
        >
            <div className={style['container-modal-alert']}>
                <Row>
                    <Col xs={24} className={style['col']}>
                        <InfoOutlinedIcon
                            style={{
                                width: 24,
                                height: 24,
                                color: '#E22E2E',
                                marginRight: 16,
                            }}
                        />
                        <div>
                            <p className={style['title']}>
                                {EX5057
                                    ? t(
                                          'efficientPurchasing.priorityAndTolerance.efficientPurchasing_priorityAndToleranceOne',
                                      )
                                    : 'Hay productos sin selección'}
                            </p>
                        </div>
                    </Col>

                    {control && (
                        <Col xs={22} className={style['col-content-msg']} xsPush={2}>
                            {distribution
                                ? EX3526
                                    ? t(
                                          'efficientPurchasing.priorityAndTolerance.efficientPurchasing_priorityAndToleranceTwentyFive',
                                      )
                                    : EX3526
                                    ? t(
                                          'efficientPurchasing.porcentualDistribution.efficientPurchasing_porcentualDistributionSixteen',
                                      )
                                    : `Hay nuevas droguerías. Asígnale un porcentaje para activar la selección automática.`
                                : `Hay nuevas droguerías. Asígnale un orden de prioridad para
                  activar la selección automática.`}
                        </Col>
                    )}

                    <Col xs={22} className={style['col-content']} xsPush={2}>
                        <p className={style['content']}>{handleText()}</p>

                        <p className={style['counter']}>
                            {EX5057 ? t('prioritiesAndTolerance.labelProductsWithoutSelection') : 'Productos N°'}:
                            <span className={style['numbers']}> {productIndexText}</span>
                        </p>
                    </Col>
                </Row>

                <ButtonToolbar className={style['footer']}>
                    <ButtonGhost
                        text={EX5057 ? t('efficientPurchasing.priorityAndTolerance.labelUnderstood') : 'Entendido'}
                        onClick={handleClose}
                        height={40}
                        width={''}
                    />
                    <ButtonOutline
                        gtagClass={
                            distribution
                                ? 'tooltip__supplier-percent-distribution-config'
                                : 'tooltip__supplier-priority-config'
                        }
                        text={
                            <span
                                className={
                                    distribution
                                        ? 'tooltip__supplier-percent-distribution-config'
                                        : 'tooltip__supplier-priority-config'
                                }
                            >
                                {distribution
                                    ? EX5057
                                        ? t(
                                              'efficientPurchasing.porcentualDistribution.efficientPurchasing_porcentualDistributionFive',
                                          )
                                        : 'Configurar Porcentajes'
                                    : EX5057
                                    ? t(
                                          'efficientPurchasing.priorityAndTolerance.efficientPurchasing_priorityAndToleranceFive',
                                      )
                                    : 'Configurar Prioridad'}
                            </span>
                        }
                        className={style['button']}
                        display={!disableConfig && distribution ? 'none' : ''}
                        onClick={handleCloseModalConfig}
                        height={40}
                        width={''}
                    />
                </ButtonToolbar>
            </div>
        </Menu>
    );
};

export default AlertModal;
