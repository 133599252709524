// interfaces
import { IIconProps } from './Icon.interface';

const Icon = ({ disabled, loading }: IIconProps) => {
    return (
        <img
            src={`/assets/images/download_${disabled ? 'disabled' : 'file_blue'}.svg`}
            width={16}
            height={16}
            alt="icon download"
            style={{
                color: disabled ? '#ACAEC4' : '#0171E6',
                display: loading ? 'none' : 'block',
            }}
            draggable={false}
        />
    );
};

export default Icon;
