import { t } from 'i18next';
import { Grid, TextField } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import HelpIcon from '@material-ui/icons/Help';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { ServiceId } from 'common/types';
import { ButtonOutline, ButtonSolid } from 'commons/components/Button';
import Menu from 'components/Menu';
import { usePointOfSale } from 'context';
import { useStatusContext } from 'context/control-state';
import React from 'react';
import styled from 'styled-components';
import * as gtag from 'utils/gtag';
import { useUser } from 'utils/hooks';
import { useStyles } from './Tolerance.styles';
import { EX5057 } from 'config/flags';

function ModalConfirm({ open, setOpen, anchorElement, count, setCount, userRole, user }) {
    const { pointOfSale } = usePointOfSale();
    const serviceId: any = user?.pointsOfSale.find((p: any) => pointOfSale?.id === p.id);
    const EX3529 = process.env.NEXT_PUBLIC_EX3529 === 'true';
    const EX3526 = process.env.NEXT_PUBLIC_EX3526 === 'true';

    const PapertStyle = {
        width: 400,
        padding: 0,
        marginTop: count === 1 ? -24 : 4,
    };

    const classes = useStyles();
    const handleReedMore = () => {
        gtag.event({
            action: 'click',
            category: 'efficient-purchase__product-comparison',
            label: 'button__read-more',
            value: 0,
        });
        setCount(2);
        window.open(
            `${
                serviceId?.service_id === ServiceId.ByPercentage
                    ? 'https://ayuda.extendeal.com/qué-es-la-tolerancia-de-precios-por-porcentaje'
                    : 'https://ayuda.extendeal.com/qué-es-y-cómo-configuro-la-tolerancia-de-precios'
            }`,
            '_blank',
        );
    };

    const handleOpen = () => {
        gtag.event({
            action: 'click',
            category: 'efficient-purchase__product-comparison',
            label: 'button__understood',
            value: 0,
        });
        setCount(2);
        setOpen(!open);
    };

    const text = EX3529
        ? t('efficientPurchasing.priorityAndTolerance.efficientPurchasing_priorityAndToleranceTwentyEight')
        : 'El sistema seleccionará la droguería prioritaria cuando la diferencia con el precio más bajo no supere el porcentaje de tolerancia.';

    return (
        <>
            <Menu
                open={open}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                anchorEl={anchorElement}
                className={classes.modalInfo}
                getContentAnchorEl={null}
                style={{ left: -200, padding: 0 }}
                PaperProps={{ style: PapertStyle }}
            >
                <Grid>
                    <div style={{ display: 'flex' }}>
                        <div>
                            <InfoOutlinedIcon className={classes.InfoToleranceIcon} />
                        </div>
                        <div>
                            <p className={classes.infoToleranceText}>
                                {EX5057
                                    ? t(
                                          'efficientPurchasing.priorityAndTolerance.efficientPurchasing_priorityAndToleranceSeven',
                                      )
                                    : 'Prioridad por tolerancia'}
                            </p>
                            <p className={classes.infoToleranceSubtext}>
                                {userRole !== 2 ? (
                                    <>{text}</>
                                ) : (
                                    <>
                                        {EX3526
                                            ? t('efficientPurchasing.priorityAndTolerance.efficientPurchasing_priorityAndToleranceNineteen')
                                            : 'Cuando un usuario con rol de gerente configure la tolerancia, los precios se identificarán con un fondo amarillo. '}
                                    </>
                                )}
                            </p>
                        </div>
                        <div></div>
                    </div>
                    <div className={classes.rowButtonContainerTolerance}>
                        <ButtonOutline
                            gtagClass="button__read-more-tolerance"
                            text={
                                <span className="button__read-more-tolerance">
                                    {EX5057 ? t('efficientPurchasing.priorityAndTolerance.labelReadMore') : 'Leer más'}
                                </span>
                            }
                            onClick={handleReedMore}
                            height={40}
                            width={''}
                            margin="0px 8px 0px"
                        />
                        <ButtonSolid
                            gtagClass="button__understood-tolerance"
                            text={
                                <span className="button__understood-tolerance">
                                    {EX5057
                                        ? t('efficientPurchasing.priorityAndTolerance.labelUnderstood')
                                        : 'Entendido'}
                                </span>
                            }
                            height={40}
                            width={''}
                            onClick={handleOpen}
                        />
                    </div>
                </Grid>
            </Menu>
        </>
    );
}

const ToleranceTextField = styled(({ inputProps, ...props }) => {
    return (
        <TextField
            {...props}
            InputLabelProps={{ shrink: true }}
            InputProps={{
                ...inputProps,
            }}
        />
    );
})``;

const Tolerance = ({ setTolerance, tolerance, buttonRefState, setButtonRefState, buttonRef }) => {
    const classes = useStyles();
    const { user } = useUser({});
    const userRole = user.role_id;
    const [open, setOpen] = React.useState(false);
    const { count, setCount } = useStatusContext();
    let regexOnlyNumbersFromCeroToOneHundred = /(^100([.,][0]{0,2})?$)|(^[1-9]{0,1}\d{0,1}$)|(^([1-9]{0,1}\d{1}[,.]\d{0,2})$)/;

    const onChange = (e) => {
        if (regexOnlyNumbersFromCeroToOneHundred.test(e.target.value)) {
            let val = e.target.value.replace('.', ',');
            setTolerance(val);
        } else {
            e.target.value = tolerance;
        }
    };

    const onBlur = () => {
        if (tolerance) {
            let val = tolerance.replace(/[,]$/, '');
            setTolerance(val);
        }
    };

    const onClick = () => {
        gtag.event({
            action: 'click',
            category: 'efficient-purchase__product-comparison',
            label: 'input__tolerance',
            value: 0,
        });
    };

    const handleOpenModal = () => {
        setButtonRefState(buttonRef.current);
        setOpen(!open);
    };
    return (
        <div className={classes.containerTolerance}>
            <ToleranceTextField
                id="outlined-basic"
                size="small"
                fullWidth
                className={`input__tolerance ${classes.tolerance}`}
                style={{ background: 'white' }}
                value={tolerance}
                placeholder="0,00"
                variant="outlined"
                onChange={onChange}
                onBlur={onBlur}
                onClick={onClick}
                type="text"
                inputProps={{
                    classes: { input: 'input__tolerance' },
                    endAdornment: <img src="/assets/images/percent.svg" width={20} height={20} />,
                }}
            />
            <div className={classes.openModalIcon}>
                <IconButton aria-label="info-modal" onClick={handleOpenModal}>
                    <HelpIcon
                        className="icon__help-tolerance"
                        style={{
                            color: '#A16205',
                            height: 23,
                            cursor: 'pointer',
                            width: 23,
                        }}
                    />
                </IconButton>
            </div>
            <ModalConfirm
                open={open}
                setOpen={setOpen}
                anchorElement={buttonRefState}
                count={count}
                userRole={userRole}
                setCount={setCount}
                user={user}
            />
        </div>
    );
};

export default Tolerance;
