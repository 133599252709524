import { t } from 'i18next';
import { cancel } from 'api/orders';
import { useRouter } from 'next/router';
import { Button, Modal } from 'rsuite';
import { useUser } from 'utils/hooks';
// style
import { EX5225 } from 'config/flags';
import { useSnackbar } from 'context';
import style from './ModalWithoutCredit.module.css';
import { useModalWithoutCredit } from 'context/ModalWithoutCredit/ModalWithoutCredit';

const ModalWithoutCredit = () => {
    const { user } = useUser({});
    const EX4903 = user?.EX4903;
    // context
    const { isModalOpen, closeModal, drugManufacturerName, typeModal } = useModalWithoutCredit();
    // hook
    const { push, query } = useRouter();
    const snackbar = useSnackbar();
    // utils
    const orderId = query?.orderId ?? 1;
    const clientId = user?.client?.id;
    const posSelected = query?.posId ?? null;
    // build the href to redirect to
    const href = posSelected ? `/${clientId}/efficient-purchase/${posSelected}` : `/${clientId}/efficient-purchase`;

    const redirectTo = () => {
        cancel(orderId).then(() => {
            snackbar.show({
                message: EX5225 ? t('snackbar.labelDiscardOrder') : 'Se descartó la compra.',
            });
            push(href);
        });
    };

    const closeAlertModal = () => {
        closeModal();
    };
    const closeAlertRedirect = () => {
        redirectTo();
        closeModal();
    };

    return (
        <Modal open={isModalOpen} className={style['modal']}>
            <Modal.Header closeButton={false} className={style['modal-header']}>
                <p className={style['title-modal']}>
                    {typeModal === 'WithOutCredit'
                        ? EX4903
                            ? t('efficientPurchasing.insufficientCredit.withoutCreditInAllDrugmanufacturers')
                            : 'Sin crédito en droguerías'
                        : EX4903
                        ? t('efficientPurchasing.confirmPurchase.efficientPurchasing_confirmPurchaseThirteen')
                        : 'Crédito en droguería insuficiente'}
                </p>
            </Modal.Header>
            <Modal.Body className={style['body-modal']}>
                <p className={style['subtitle-modal']}>
                    {typeModal === 'WithOutCredit'
                        ? EX4903
                            ? t('efficientPurchasing.insufficientCredit.anyActiveDrugmanufacturerEnoughCredit')
                            : 'Lamentablemente, ninguna de las droguerías activas cuenta con crédito suficiente para realizar este pedido.'
                        : EX4903
                        ? t('efficientPurchasing.insufficientCredit.labelModalNotEnoughCredit', {
                              value: drugManufacturerName,
                          })
                        : ` Lamentablemente no cuentas con crédito suficiente en ${drugManufacturerName} para hacer esta
                    modificación. De todas formas, tu selección es la más eficiente tomando esta limitante en
                    consideración.`}
                </p>
            </Modal.Body>
            <Modal.Footer>
                {typeModal === 'WithOutCredit' ? (
                    <Button
                        block
                        className={`button__insufficient-credit-new-search ${style['action-button-modal']}`}
                        onClick={() => closeAlertRedirect()}
                    >
                        <span className={style['text-button-active']}>
                            {EX4903
                                ? t('efficientPurchasing.insufficientCredit.searchNewProducts')
                                : 'Buscar otros productos'}
                        </span>
                    </Button>
                ) : (
                    <Button
                        block
                        className={`button__insufficient-credit-ok-3 ${style['action-button-modal']}`}
                        onClick={() => closeAlertModal()}
                    >
                        <span className={style['text-button-active']}>
                            {EX4903
                                ? t(
                                      'efficientPurchasing.priorityAndTolerance.efficientPurchasing_priorityAndToleranceFour',
                                  )
                                : 'Entendido'}
                        </span>
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default ModalWithoutCredit;
