import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  totalRowContainer: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    marginLeft: 24,
    marginRight: 24,
  },
  totalContainer: {
    width: 512,
    height: 40,
    backgroundColor: "#EEEEEE",
    borderRadius: 4,
    marginTop: 4,
    alignItems: "center",
    display: "flex",
  },
  inputTotal: {
    width: 464,
    padding: "9px 16px",
    color: "#333333",
    fontSize: 16,
    fontFamily: "Source Sans Pro",
    fontWeight: 600,
  },
  spanError: {
    marginLeft: 162,
    color: "#FF3030",
    fontSize: 12,
    fontWeight: 400,
  },
  totalSumContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingRight: 10,
  },
  inputSumTotal: {
    color: "#333333",
    fontSize: 16,
    fontWeight: 600,
    marginRight: 13.33,
  },
  containerButtonAction: {
    height: 76,
    display: "flex",
    paddingTop: 32,
    width: 560,
    paddingLeft: 24,
    paddingRight: 24,
    marginBottom:24,
    justifyContent: "space-between",
  },
});
