import { useEffect, useState } from 'react';
// contexts
import { useProgressBarPriceContext } from 'context';
// styles
import styles from '../Footer.module.css';

const useFooter = () => {
    const { failed, currentStep, progress, timeLeft } = useProgressBarPriceContext();

    const [animationClass, setAnimationClass] = useState(styles['fade-in-right']);
    const [displayedStep, setDisplayedStep] = useState(0);

    useEffect(() => {
        if (failed) {
            setAnimationClass(styles['fade-out-left']);

            const timeout = setTimeout(() => {
                setDisplayedStep(1);
                setAnimationClass(styles['fade-in-right']);
            }, 200);

            return () => clearTimeout(timeout);
        }
    }, [failed]);

    return { animationClass, displayedStep, currentStep, progress, timeLeft };
};

export default useFooter;
