import { format, parseISO } from 'date-fns';

export const auxiliaryFx = () => {
    /**
     * Pre-determines the date and hour based on available dates.
     * @param {Object[]} data - An array of data containing 'date' and 'value' properties.
     * @returns {string[]} An array of unique date strings.
     */
    const availableDates = (data: any[]): string[] => [...new Set(data.map((item) => item.date))];

    /**
     * Pre-determines the date and hour based on available dates.
     * @param {string[]} availableDates - An array of available date strings.
     * @param {Function} setSelectedDate - A function to set the selected date state.
     * @param {Function} setAvailableTimes - A function to set the available times state.
     * @param {Function} setSelectedAvailableTime - A function to set the selected available time state.
     * @param {Object[]} data - An array of data containing 'date' and 'value' properties.
     */
    const predeterminateDateHour = (
        availableDates: string[],
        setSelectedDate: (v: Date | null) => void,
        setAvailableTimes: (v: string[]) => void,
        setSelectedAvailableTime: (v: string) => void,
        data: any[],
    ) => {
        const defaultDate = availableDates?.length > 0 ? parseISO(availableDates[0]) : null;
        setSelectedDate(defaultDate);
        if (defaultDate) {
            const formattedDate = format(defaultDate, 'yyyy-MM-dd');
            const times = data.filter((item) => item.date === formattedDate).map((item) => item.value);
            setAvailableTimes(times);
            if (times.length > 0) {
                setSelectedAvailableTime(times[0]);
            }
        }
    };

    /**
     * Processes the selected date and returns available times.
     * @param {Date} selectedDate - A Date object representing the selected date.
     * @param {Object[]} data - An array of data containing 'date' and 'value' properties.
     * @returns {Object} An object containing available times.
     */
    const processDateAndTimes = (selectedDate: Date | null, data: any[]) => {
        if (selectedDate) {
            // Format the selected date
            const formattedDate = format(selectedDate, 'yyyy-MM-dd');

            // Filter the data and map the values
            const times = data.filter((item) => item.date === formattedDate).map((item) => item.value);

            // Return available times and the selected time
            return {
                availableTimes: times,
            };
        } else {
            // Return empty values if selectedDate is falsy
            return {
                availableTimes: [],
            };
        }
    };

    return {
        availableDates,
        processDateAndTimes,
        predeterminateDateHour,
    };
};
