import Link from 'next/link';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { t } from 'i18next';
// STYLES
import styles from './AddButton.module.css';

const AddButton = () => {
    const { query } = useRouter();
    const href = `/${query.clientId}/settings/POS/${query?.posId}/addOfflineDrugstore`;

    return (
        <Link className={`${styles['container']}`} type='link' href={`${href}`} onClick={() => localStorage.setItem('redirectTo', 'home')}>
            <div>
                <Image src={'/assets/images/add_circle.svg'} width={24} height={24} alt="add icon" />
                <p>{t('offlineDrugManufacturer.button.addDrugManufacturer')}</p>
            </div>
        </Link>
    );
};

export default AddButton;
