import { StatusCdo } from 'utils/interfaces';

interface shouldDisplayRadioProps {
    cdo_status_id: number | null;
    hasDrugManufacturerError: boolean;
}

export const shouldDisplayRadio = ({ cdo_status_id, hasDrugManufacturerError }: shouldDisplayRadioProps): boolean => {
    return cdo_status_id !== StatusCdo.PENDING;
};
