import { useState } from "react";

const useExpandCollapse = () => {
  const [expandedState, setExpandedState] = useState({});

  const expandCard = (id: string) => {
    setExpandedState((prevState) => ({
      ...prevState,
      [id]: true,
    }));
  };

  const collapseCard = (id: string) => {
    setExpandedState((prevState) => ({
      ...prevState,
      [id]: false,
    }));
  };

  const toggleCard = (id: string) => {
    setExpandedState((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };
  
  return {
    isExpanded: (id: string) => !!expandedState[id],
    expandCard,
    collapseCard,
    toggleCard,
  };
};

export default useExpandCollapse 
