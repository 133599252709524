// components
import { memo } from 'react';
import { Content } from './Content';
import { Footer } from './Footer';
// interfaces
import { ProgressBarPriceProps } from './ProgressBarPrice.interface';
// utils && hooks
import { useProgressBarPrice } from './hooks';

const ProgressBarPrice = ({
    files,
    setInvalidProducts,
    setInvalidLines,
    setRequestedProductsQuantity,
    setParentOrderId,
    setErrorsFileUrl,
    setDisableDashedBorder,
    continueToEfficientPurchase,
    parentOrderId,
    onDiscard,
    quantityAvailableDrugManufacturers,
    dataCreated,
    efficientPurchaseFromCart,
    setHideBreadcrum,
}: ProgressBarPriceProps) => {
    // logic hook
    const { handleCancelModal, confirmCancelation, getFailedConnectionDrugManufacturers } = useProgressBarPrice({
        dataCreated,
        quantityAvailableDrugManufacturers,
        setHideBreadcrum,
        onDiscard,
        efficientPurchaseFromCart,
        files,
        continueToEfficientPurchase,
        parentOrderId,
        setInvalidProducts,
        setInvalidLines,
        setRequestedProductsQuantity,
        setParentOrderId,
        setErrorsFileUrl,
        setDisableDashedBorder,
    });

    return (
        <>
            <Content
                handleCancelModal={handleCancelModal}
                getFailedConnectionDrugManufacturers={getFailedConnectionDrugManufacturers}
                confirmCancelation={confirmCancelation}
            />
            <Footer />
        </>
    );
};

export default memo(ProgressBarPrice);
