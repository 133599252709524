import { useEffect, useState } from 'react';
import { rgba } from 'polished';
import { useTheme } from '@material-ui/core';
import { useProductComparison } from 'context/product-comparison.context';
import * as gtag from 'utils/gtag';
import { useUser } from 'utils/hooks';
import { useDrugManufacturersContext, usePointOfSale } from 'context';
import { EX6381, EX6695 } from 'config/flags';
import { ModalPriorities } from '../modal';
import { EDrugManufacturerTypeId } from 'utils/enums/drugmanufacturerTypeId/DrugManufacturerTypeId';

type newDrugmanufacterArray = [
    {
        id: number;
        name: string;
        type_id: number;
        cdo_status_id: number;
        vendor_accepted: boolean;
    },
];

function ConfigPriorities({
    serviceId,
    deletePriority,
    array,
    unassigned,
    open,
    setOpen,
    setShow,
    setMsg,
    available,
    count,
    setCount,
    priorityRecorset,
}) {
    const { user, mutateUser } = useUser({});
    const { pointOfSale } = usePointOfSale();
    // const { drugManufacturer } = useProductComparison();
    const { drugManufacturer } = useDrugManufacturersContext();
    const [tolerance, setTolerance] = useState('0,00');
    const EX6017 = user?.EX6017;
    const FFEX6381 = !!EX6381;
    const [newDrugmanufacturers, setNewDrugmanufacturers] = useState<newDrugmanufacterArray>();

    useEffect(() => {
        const filters = FFEX6381
            ? drugManufacturer?.filter(
                  (item) =>
                      (!!item?.vendor_accepted && item?.type_id === EDrugManufacturerTypeId.vendor) ||
                      item?.type_id !== EDrugManufacturerTypeId.vendor,
              )
            : drugManufacturer;
        const filteredByvendors = filters;
        if (EX6017) {
            setNewDrugmanufacturers(filteredByvendors);
        } else {
            setNewDrugmanufacturers(drugManufacturer);
        }
    }, [drugManufacturer]);

    useEffect(() => {
        handleTolerance();
    }, [array, drugManufacturer, serviceId]);

    const handleTolerance = () => {
        const { tolerance, priority_id: recordsetPriorityId } = priorityRecorset;
        const findTolerance = user?.pointsOfSale?.find((p) => pointOfSale?.id === p?.id);
        
        const getToleranceValue = () => {
            if (serviceId) return findTolerance?.d_tolerance;
            return !!EX6695 && !!priorityRecorset && recordsetPriorityId !== findTolerance?.priority_id
                ? tolerance
                : findTolerance?.tolerance;
        };

        handleValueTolerance(getToleranceValue());
    };

    const handleValueTolerance = (valueTolerance) => {
        if (valueTolerance === null) {
            setTolerance('0,00');
        } else {
            setTolerance(valueTolerance?.toString()?.replace('.', ','));
        }
    };

    const { palette } = useTheme();
    const handleClose = (value) => {
        gtag.event({
            action: 'click',
            category: 'efficient-purchase__product-comparison',
            label: 'chip__supplier-priority-config',
            value: 0,
        });
        setOpen(value);
    };

    return (
        <ModalPriorities
            user={user}
            open={open}
            mutateUser={mutateUser}
            pointOfSale={pointOfSale}
            serviceId={serviceId}
            tolerance={tolerance}
            setTolerance={setTolerance}
            setMsg={setMsg}
            setShow={setShow}
            deletePriority={deletePriority}
            openModal={open}
            array={array}
            available={available}
            unassigned={unassigned}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
            transformOrigin={{ vertical: 'center', horizontal: 'center' }}
            role={undefined}
            style={{
                backgroundColor: rgba(palette.neutral[900], 0.3),
                padding: 0,
            }}
            handleClose={handleClose}
            count={count}
            setCount={setCount}
            newDrugmanufacturers={newDrugmanufacturers}
        />
    );
}

export default ConfigPriorities;
