import { DrugManufacturersId } from 'common/types';
import Regex from 'utils/regex';
export const commonsAttributes = [
    {
        value: 'username',
        label: 'Usuario',
    },
    {
        value: 'password',
        label: 'Contraseña',
    },
];

export const commonsAttributesWithClientIdentifier = [
    {
        value: 'username',
        label: 'Usuario',
    },
    {
        value: 'password',
        label: 'Contraseña',
    },
    {
        value: 'client_identifier',
        label: 'Número de Cliente',
    },
];
export const commonsAttributesWithClientFunction = [
    {
        value: 'username',
        label: 'Usuario',
    },
    {
        value: 'password',
        label: 'Contraseña',
    },
    {
        value: 'funtion',
        label: 'Función',
    },
];

export const drugManufacturersNeedClientIdentifier = {
    [DrugManufacturersId.SuizoArgentina]: [
        {
            credentialType: 'API Suizo',
            required: true,
            forceErrors: true,
            name: 'client_identifier',
            value: 1,
            regex: {
                value: Regex.number,
                messageError: 'El dato ingresado debe ser numerico',
            },
            placeholder: 'Empresa',
        },
        {
            credentialType: 'WEB Suizo',
            required: true,
            forceErrors: true,
            name: 'client_identifier',
            regex: {
                value: Regex.number,
                messageError: 'El dato ingresado debe ser numerico',
            },
            placeholder: 'N° de Cuenta',
        },
    ],
    [DrugManufacturersId.MonroeAmericana]: {
        credentialType: 'credentialType',
        required: true,
        forceErrors: true,
        name: 'client_identifier',
        regex: {
            value: Regex.number,
            messageError: 'El dato ingresado debe ser numerico',
        },
        placeholder: 'Codigo de cliente',
    },
    [DrugManufacturersId.CofarmenMendoza]: {
        credentialType: 'credentialType',
        required: true,
        forceErrors: true,
        name: 'client_identifier',
        regex: {
            value: Regex.number,
            messageError: 'El dato ingresado debe ser numerico',
        },
        placeholder: 'Número de cliente',
    },
    [DrugManufacturersId.CofarmenSanJuan]: {
        credentialType: 'credentialType',
        required: true,
        forceErrors: true,
        name: 'client_identifier',
        regex: {
            value: Regex.number,
            messageError: 'El dato ingresado debe ser numerico',
        },
        placeholder: 'Número de cliente',
    },
    [DrugManufacturersId.DrogueriasDelSud]: [
        {
            credentialType: 'API',
            required: false,
            forceErrors: false,
            placeholder: 'Función',
            name: 'function',
        },
        {
            credentialType: 'WEB',
            required: true,
            forceErrors: true,
            placeholder: 'Número de Cliente',
            name: 'client_identifier',
            regex: {
                value: Regex.number,
                messageError: 'El dato ingresado debe ser numerico',
            },
        },
    ],
    [DrugManufacturersId.DrogueriaSur]: [
        {
            fieldType: 'autoComplete',
            credentialType: 'WEB',
            required: true,
            forceErrors: true,
            placeholder: 'Dirección de entrega',
            name: 'address',
            regex: {
                value: Regex.username,
                messageError: 'Campo obligatorio',
            },
        },
    ],
    [DrugManufacturersId.Cofaloza]: [
        {
            fieldType: 'autoComplete',
            credentialType: 'WEB',
            required: true,
            forceErrors: true,
            placeholder: 'Dirección de entrega',
            name: 'address',
            regex: {
                value: Regex.number,
                messageError: 'Campo obligatorio',
            },
        },
    ],
    [DrugManufacturersId.MedicinasRosario]: [
        {
            fieldType: 'autoComplete',
            credentialType: 'WEB',
            required: true,
            forceErrors: true,
            placeholder: 'Dirección de entrega',
            name: 'address',
            regex: {
                value: Regex.number,
                messageError: 'Campo obligatorio',
            },
        },
    ],
    [DrugManufacturersId.Nadro]: {
        credentialType: 'credentialType',
        required: false,
        forceErrors: true,
        name: 'client_identifier',
        type: 'text',
        placeholder: 'Número de sucursal',
    },
    [DrugManufacturersId.Farmamigo]: [
        {
            fieldType: 'autoComplete',
            credentialType: 'WEB',
            required: true,
            forceErrors: true,
            placeholder: 'Dirección de entrega',
            name: 'address',
            regex: {
                value: Regex.number,
                messageError: 'Campo obligatorio',
            },
        },
    ],
    [DrugManufacturersId.PacificPharmaGroup]: [
        {
            fieldType: 'autoComplete',
            credentialType: 'WEB',
            required: true,
            forceErrors: true,
            placeholder: 'Método de pago',
            stepLabel: 'Selecciona el método de pago',
            name: 'payMethod',
            regex: {
                value: Regex.number,
                messageError: 'Campo obligatorio',
            },
        },
    ],
};
