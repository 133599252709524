import { useEffect, useState } from 'react';
import { t } from 'i18next';
// components
import ModalWithPriceWithOutSelection from './ModalWithPriceWithOutSelection';
// contexts
import { useProductComparison } from 'context/product-comparison.context';
import { useDrugManufacturersContext, useProductsContext, useSnackbar } from 'context';
import { usePrioritiesPercentagesCeContext } from 'context/PrioritiesPercentagesCe/PrioritiesPercentagesCe-context';
// utils && hooks
import { productHasCheckedQuantity } from 'utils/productComparison/productHasCheckedQuantity';
// types
import { ServiceId } from 'common/types';
import { isThereAnyGenericProductWithZero } from 'utils/productComparison/isThereAnyGenericProductWithZero';

export const ModalWithoutSelectionContainer = ({ user }) => {
    const EX3526 = process.env.NEXT_PUBLIC_EX3526 === 'true';
    // CONTEXT
    const { showModalPriceWithOutSelection, setShowModalPriceWithOutSelection } = useProductComparison();
    const { products } = useProductsContext();
    const { enabledOnOff, isEnabledDrugManufacturer } = useDrugManufacturersContext();
    const EX5937 = user?.EX5937;
    // HOOKS
    const snackbar = useSnackbar();
    // STATES
    const [allProductAreSelected, setAllProductAreSelected] = useState(false);
    const [newDrugmanufacturer, setNewDrugmanufacturer] = useState([]);
    const [controlData, setControl] = useState(false);
    const [array, setArray] = useState([]);
    const [show, setShow] = useState(false);
    const validateNoProductsSelected = allProductAreSelected && (array.length === 0 || controlData);
    // new context by prioritys and percentages
    const { data, serviceId } = usePrioritiesPercentagesCeContext();
    const serviceIsPrioritiesOrDistribution = serviceId === ServiceId.ByPercentage;

    // new effect
    useEffect(() => {
        if (Object.keys(data)?.length !== 0) {
            if (serviceId === ServiceId.ByPriority) {
                handleGetDataPriorities();
            } else if (serviceId === ServiceId.ByPercentage) {
                handleGetDataDistribution();
            }
        }
    }, [data]);

    const handleGetDataDistribution = async () => {
        const responseDistribution = data?.percentages_distributed;
        setArray(responseDistribution);

        let haveOneCeroPercentage = handleDistributionCeroCounter(responseDistribution);

        const responseWithOutDistribution = data?.without_distribution;
        const updatedValues = [...newDrugmanufacturer];

        if (responseWithOutDistribution?.length !== 0 && haveOneCeroPercentage) {
            setControl(true);
            responseWithOutDistribution.map((item) => {
                updatedValues[item.id] = {
                    text: EX3526
                        ? t('modals.configModal_newDrugstores')
                        : 'Asígnale un porcentaje a las droguerías nuevas.',
                };
            });
            setNewDrugmanufacturer(updatedValues);
        } else if (responseWithOutDistribution?.length !== 0 && !haveOneCeroPercentage) {
            setControl(false);
            setArray(responseDistribution);
        } else {
            setControl(false);
        }
    };

    const handleDistributionCeroCounter = (percentages_distributed) => {
        let response = percentages_distributed.find((drugmanufacturer) => drugmanufacturer.percentage === 0);
        return response ? true : false;
    };

    const handleGetDataPriorities = async () => {
        const responsePriorities = data?.assigned;
        const responseUnassigned = data?.unassigned;

        setArray(responsePriorities);

        if (responseUnassigned?.length !== 0) {
            setControl(true);
        } else {
            setControl(false);
        }
    };

    useEffect(() => {
        if (show) {
            snackbar.show({
                message: EX3526
                    ? t('efficientPurchasing.priorityAndTolerance.efficientPurchasing_priorityAndToleranceSeventeen')
                    : 'La prioridad ha sido guardada.',
            });
            setShow(false);
        }
    }, [show]);

    useEffect(() => {
        if (isThereAnyGenericProductWithZero(products)) {
            setAllProductAreSelected(false);
        } else {
            setAllProductAreSelected(
                !(productHasCheckedQuantity(products, enabledOnOff, isEnabledDrugManufacturer, EX5937).length === 0),
            );
        }
    }, [products]);

    const hadleOpen = (value) => {
        if (!serviceIsPrioritiesOrDistribution) {
            setShowModalPriceWithOutSelection(value);
        }
    };

    return (
        <ModalWithPriceWithOutSelection
            validateNoProductsSelected={validateNoProductsSelected}
            open={showModalPriceWithOutSelection}
            setOpen={hadleOpen}
            control={controlData}
            distribution={serviceIsPrioritiesOrDistribution}
            user={user}
        />
    );
};
