// hidden cart validation
export const hiddenCart = (path) => {
    const routes = [
        '/[clientId]/my-orders',
        '/[clientId]/my-orders/details/[order]',
        '/[clientId]/my-orders/deliveries/[delivery]/[order]',
        '/[clientId]/efficient-purchase/[posId]/confirmation',
        '/[clientId]/settings/POS',
        '/[clientId]/settings/trade-agreements',
        '/[clientId]/settings/trade-agreements/create',
        '/[clientId]/settings/POS/[posId]/posDetails',
        '/[clientId]/settings/trade-agreements/editAgreement/[id]',
        '/analytics'
    ];

    if (routes.includes(path)) {
        return true;
    } else {
        return false;
    }
};
