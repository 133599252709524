
// style
import style from './FieldWithIcon.module.css';
// rsuite
import { InputGroup, Form, InputNumber } from 'rsuite';
// interface
import { IFieldWithIcon } from './FieldWithIcon.interface';

const FieldWithIcon = ({ field, state, handlerChangeInput }:IFieldWithIcon) => {
    return (
        <InputGroup className={style['container']}>
            <InputGroup.Addon className={style['price-icon']}>
                <img src="/assets/images/price.svg" />
            </InputGroup.Addon>
            <Form.Control
                name={field.name}
                defaultValue={state.minimumPurchaseAmount}
                onChange={(evt) => handlerChangeInput(evt, field.name)}
                errorMessage={state.errorsForms[field.name]}
                placeholder={field.placeholder }
                type='number'
                shouldResetWithUnmount
                //accepter={InputNumber}
                className={style['form-control-style']}
                min={0}
            />
        </InputGroup>
    );
};

export default FieldWithIcon;
