// rsuite
import { IconButton } from 'rsuite';
//mui
import CloseIcon from '@mui/icons-material/Close';
// style
import style from './CloseButtonIcon.module.css';

const CloseButtonIcon = ({ onClickClose }) => {
  return (
    <div className={`icon__clean-search-1 ${style['autocomplete-close-icon-button']}`}>
      <IconButton
        className={`icon__clean-search-1 ${style['autocomplete-icon-button-index']}`}
        size='xs'
        icon={<CloseIcon />}
        onClick={(evt: any) => {
          evt.stopPropagation(), 
          onClickClose();
        }}
      />
    </div>
  );
};

export default CloseButtonIcon;
