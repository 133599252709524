import React from 'react';
// CONFIGS
import { EX5138 } from 'config/flags';
// UTILS
import useCurrencyFormatter from 'utils/hooks/useCurrencyFormatter/useCurrencyFormatter';
// STYLES
import styles from '../../TableTotals.module.css';

const FormattedNumber = ({ typeRenderFormat, subtotal }): JSX.Element => {
    /* #region formatter currency */
    const { formatCurrency } = useCurrencyFormatter();
    const { taxLabel: tax, detailedValues } = formatCurrency({
        value: subtotal,
        digitsValidation: 7,
    });
    const { mainInteger: integer, decimalSeparator, currencySymbol: symbol, cents: decimal } = detailedValues;
    /* #endregion */

    if (typeRenderFormat) {
        return (
            <>
                <span className={styles['numberInt']}>{integer}</span>
                <span className={styles['numberDec']}>
                    {decimalSeparator}
                    {decimal}
                </span>
                <span className={styles['numberInt']}>{symbol}</span>
                <span className={styles['tax']}>{tax}</span>
            </>
        );
    } else {
        return (
            <>
                <span className={styles['numberInt']}>{symbol}</span>
                <span className={styles['numberInt']}>{integer}</span>
                <span className={styles['numberDec']}>
                    {decimalSeparator}
                    {decimal}
                </span>
                <span className={styles['tax']}>{EX5138 ? tax : '+ IVA'}</span>
            </>
        );
    }
};

export default FormattedNumber;
