import React from 'react';
// UTILS
import { renderPrice } from '../../../../utils';
// STYLES
import cellStyles from '../../../../CellStyles.module.css';

const PriceDiscount = ({ lastUnitPrice }): JSX.Element => {
    return (
        <div className={cellStyles['price-discount']}>
            <p>{renderPrice(lastUnitPrice)}</p>
        </div>
    );
};

export default PriceDiscount;
