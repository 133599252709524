import { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
// contexts
import { useAuth } from 'context';
// utils
import { useUser } from 'utils/hooks';
import * as gtag from 'utils/gtag';

export const useLogin = () => {
    // ---------------------- hooks ----------------
    const router = useRouter();

    const { mutateUser } = useUser({
        redirectTo: '/',
        redirectIfFound: true,
    });

    // ---------------------- context auth ----------------
    const { login, loading } = useAuth();

    // ---------------------- states ----------------
    const [formError, setFormError] = useState(false);

    const [formState, setFormState] = useState({
        email: '',
        password: '',
    });

    const updateFormState = useCallback((value, key) => {
        setFormState((prevState) => ({
            ...prevState,
            [key]: value,
        }));
    }, []);

    const handleClick = (label: string) => {
        gtag.event({
            action: 'click',
            category: 'login',
            label: label,
            value: 0,
        });
    };

    const onSubmit = async () => {
        try {
            if(!loading) {
                const response = await login(formState.email, formState.password);
                setFormError(false);
                handleClick('button__log-in');
                mutateUser(response);
            }
        } catch (error) {
            setFormError(true);
        }
    };

    // ---------------------- hubspot ----------------
    const removeHubspotChat = () => {
        // @ts-ignore
        window.HubSpotConversations?.widget.remove();
        // @ts-ignore
        window.hsConversationsOnReady = [];
    };

    useEffect(() => {
        router.prefetch('/');
        removeHubspotChat();
    }, []);

    // ---------------------- general ----------------
    useEffect(() => {
        localStorage.removeItem('pdvSelected');
        // remove redirectToLogin
        localStorage.removeItem('redirectToLogin');
    }, []);

    return {
        loading,
        formError,
        onSubmit,
        updateFormState,
        formState,
    };
};
