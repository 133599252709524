// icons
import DescriptionIcon from '@mui/icons-material/Description';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { EX5057 } from 'config/flags';

export const itemsLinks = (t) => [
    {
        label: EX5057 ? t('sidebar.sidebar_One') : 'Compra eficiente',
        icon: <LocalMallOutlinedIcon className="icon__shopping-cart" />,
        hover: <LocalMallIcon className="icon__shopping-cart" />,
        href: '/[clientId]/efficient-purchase/[posId]',
        href1: '/[clientId]/efficient-purchase',
    },
    {
        label: EX5057 ? t('sidebar.sidebar_Two') : 'Mis pedidos',
        icon: <DescriptionOutlinedIcon className="icon__my-orders" />,
        hover: <DescriptionIcon className="icon__my-orders" />,
        href: '/[clientId]/my-orders',
        href1: '',
    },
    {
    label: 'Reporte de compras',
    icon: <DashboardOutlinedIcon />,
    hover: <DashboardIcon />,
    href: '/analytics',
  },
];
