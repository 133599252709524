// rsuite v5
import { ButtonToolbar, Button, ButtonGroup } from "rsuite";
// helpers
import { btnAtributtes } from "./TabSelector.helper";

// style
import style from "./TabSelector.module.css";
// interface
import { ITabSelector } from "./TabSelector.interface";
//gtag
import * as gtag from "utils/gtag";

const TabSelector = ({
  handlerSelectOrders,
  selectorList,
  setSelectorList,
}: ITabSelector) => {
  const selectorOrders = (value, tag, setSelectorList) => {
    if (tag === "myOrders") {
      gtag.event({
        action: "click",
        category: "my-orders",
        label: "button__my-orders",
        value: 0,
      });
    } else {
      gtag.event({
        action: "click",
        category: "my-orders",
        label: "button__purchase-orders",
        value: 0,
      });
    }
    handlerSelectOrders(value, tag, setSelectorList);
  };

  const handleSelectTab = (value, tag, setSelectorList) => {
    selectorOrders(value, tag, setSelectorList);
    localStorage.setItem("tabSelected", value);
  };

  return (
    <div className={style["container"]}>
      <ButtonToolbar>
        <ButtonGroup>
          {btnAtributtes().map(({ label, value, tag, gtagClass }, index) => {
            return (
              <Button
                className={`${gtagClass} ${style["button-action"]}`}
                key={value}
                active={value === selectorList.value}
                style={{
                  backgroundColor:
                    value === selectorList.value ? "#fff" : "transparent",
                  color: value === selectorList.value ? "#0171E6" : "#40435B",
                  borderRadius:
                    index === 0 ? "8px 0px 0px 8px" : "0px 8px 8px 0px",
                }}
                appearance="subtle"
                onClick={() => handleSelectTab(value, tag, setSelectorList)}
              >
                {label}
              </Button>
            );
          })}
        </ButtonGroup>
      </ButtonToolbar>
    </div>
  );
};

export default TabSelector;
