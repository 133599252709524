import { LoadError } from '@react-pdf-viewer/core';

const handleError = (error: LoadError) => {
    let message = '';
    switch (error.name) {
        case 'InvalidPDFException':
            message = 'El documento es inválido o está dañado.';
            break;
        case 'MissingPDFException':
            message = 'No encontramos un documento para mostrar en este país.';
            break;
        case 'UnexpectedResponseException':
            message = 'Error inesperado al recibir el documento.';
            break;
        default:
            message = 'No se pudo cargar el documento.';
            break;
    }

    return (
        <div
            style={{
                alignItems: 'center',
                display: 'flex',
                height: '100%',
                justifyContent: 'center',
            }}
        >
            <div
                style={{
                    backgroundColor: '#D6D8DB',
                    borderRadius: '5px',
                    color: '#43455d',
                    paddingTop: '8px',
                    paddingBottom: '8px',
                    paddingLeft: '12px',
                    paddingRight: '12px',
                }}
            >
                {message}
            </div>
        </div>
    );
};

export default handleError;
