import { useEffect, useCallback } from 'react';
import { t } from 'i18next';
// context
import { useProductComparison } from 'context/product-comparison.context';
// rsuite
import { Input, InputGroup } from 'rsuite';
// style
import style from './ToolbarCESearch.module.css';
// mui
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { EX5057 } from 'config/flags';
import { useUser } from 'utils/hooks';

const ToolbarCESearch = () => {
    // context
    const { setSearchTerms, searchTerms } = useProductComparison();
    const { showModalPriceWithOutSelection } = useProductComparison();
    const { user } = useUser({});
    const EX4927 = user?.EX4927;

    const handleBlurModal = useCallback(() => {
        return showModalPriceWithOutSelection;
    }, [showModalPriceWithOutSelection]);

    // // init
    // useEffect(() => {
    //     // Debounce the search term input
    //     const timeoutId = setTimeout(() => {
    //         filtersByTerms();
    //     }, 500);

    //     // Cleanup the timeout
    //     return () => clearTimeout(timeoutId);
    // }, [searchTerms]);

    return (
        <>
            {/* Este blur se elimina con rediseño v3. Quitar al remover la FF */}
            {!EX4927 && handleBlurModal() && <div className={style['blur-tooltip-container']}></div>}
            <InputGroup inside className={style['input-group-component']}>
                <InputGroup.Button className={style['icon-button-action']}>
                    <SearchOutlinedIcon className={style['search-icon']} />
                </InputGroup.Button>
                <Input
                    size="lg"
                    className={style['input-component']}
                    placeholder={EX5057 ? t('efficientPurchasing.comparative.searchPlaceholder') : 'Buscar'}
                    onChange={(evt) => setSearchTerms(evt)}
                    value={searchTerms}
                />
            </InputGroup>
        </>
    );
};

export default ToolbarCESearch;
