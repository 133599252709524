import React from 'react';
// STYLES
import styles from '../CellProduct.module.css';
import { TrashIconProps } from './TrashIcon.interface';

const TrashIcon = ({ handleClick }: TrashIconProps): JSX.Element => {
    return (
        <div className={`${styles['trash-icon']}`}>
            <img src="/assets/images/delete_trash.svg" alt="delete icon" onClick={handleClick} />
        </div>
    );
};

export default TrashIcon;
