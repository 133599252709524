import { COUNTER_ZERO, EX7160 } from "config/flags";

export const handleMinus = ({
    value,
    setValue,
    handleCheckboxOnChange,
    updateQuantityRequired,
    drugmanufacturerIdSelected,
    addedItems,
    EX4800,
    uncheckProducts,
    childrenGenerics,
    orderProductId,
    setGenericsIdsAdd = (v) => {}
}): void => {
    if (EX4800) {
        if (childrenGenerics?.length > 0) {
            if (value - 1 <= 0) {
                uncheckProducts();
                setValue(0);
                handleCheckboxOnChange(0);
            } else {
                setValue(value - 1);
                handleCheckboxOnChange(value - 1);
            }
        } else {
            if (value > 1) {
                setValue(value - 1);
                handleCheckboxOnChange(value - 1);
            }
        }
    } else {
        if (value > 0) {
            if(EX7160) {
                if(value - 1 === 0) {
                    setGenericsIdsAdd(prevState => prevState?.filter(id => +id !== orderProductId));
                }
            }
            setValue(value - 1);
            handleCheckboxOnChange(value - 1);
        }
        // este caso no debería ser así ya que los productos no pueden tener cantidad 0
        else {
            setValue(0);
            handleCheckboxOnChange(0);
        }
    }
};

export const handlePlus = ({
    value,
    setValue,
    handleCheckboxOnChange,
    updateQuantityRequired,
    drugmanufacturerIdSelected,
}): void => {
    setValue(value + 1);
    handleCheckboxOnChange(value + 1);
};
export const handleValueChange = ({
    event,
    setValue,
    handleCheckboxOnChange,
    value,
    updateQuantityRequired,
    drugmanufacturerIdSelected,
    EX5937,
    parentGenericProduct,
    isGenerics = false,
    genericsIdsAdd = []
}): void => {
    const newValue = parseInt(event.target.textContent === '' ? event.target.value : event.target.textContent, 10) || 0;
    if(EX5937){
        if (newValue >= 1) {
            // if (!!validatedValue) {
            //     setValue(validatedValue);
            //     handleCheckboxOnChange(validatedValue);
            // } else {
            setValue(newValue);
            handleCheckboxOnChange(newValue);
            // }
        } else if (newValue < 1) {
            if(isGenerics) {
                setValue(newValue);
                handleCheckboxOnChange(newValue);
            }else {
                if(EX7160) {
                    if(genericsIdsAdd?.length > 0) {
                        setValue(newValue);
                        handleCheckboxOnChange(newValue);
                    }else {
                        setValue(1);
                        handleCheckboxOnChange(1);
                    }
                }else {
                    setValue(1);
                    handleCheckboxOnChange(1);
                }
            }
        } else {
            event.target.textContent = value.toString(); // Revertir el valor si es negativo
        }
    }
    else {
        if(COUNTER_ZERO) {
            if (newValue >= 1 && newValue <= 999) {
                setValue(newValue);
                handleCheckboxOnChange(newValue);
            } else if (parentGenericProduct && newValue === 0) {
                setValue(newValue);
                handleCheckboxOnChange(newValue);
            }else if (newValue < 1) {
                if(isGenerics) {
                    setValue(newValue);
                    handleCheckboxOnChange(newValue);
                }else {
                    setValue(1);
                    handleCheckboxOnChange(1);
                }
            } else if (newValue > 999) {
                setValue(999);
                handleCheckboxOnChange(999);
            } else {
                event.target.textContent = value.toString(); // Revertir el valor si es negativo
            }
        }else {
            if (newValue >= 1 && newValue <= 999) {
                // if (!!validatedValue) {
                //     setValue(validatedValue);
                //     handleCheckboxOnChange(validatedValue);
                // } else {
                setValue(newValue);
                handleCheckboxOnChange(newValue);
                // }
            } else if (newValue < 1) {
                setValue(1);
                handleCheckboxOnChange(1);
            } else if (newValue > 999) {
                setValue(999);
                handleCheckboxOnChange(999);
            } else {
                event.target.textContent = value.toString(); // Revertir el valor si es negativo
            }
        }
    }
};
