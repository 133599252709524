import React from 'react';
// STYLES
import styles from '../../../../TableTotals.module.css';
import cellStyles from '../../../../../../CellStyles.module.css';
// INTERFACES
import { NumberPartProps } from './NumberPart.interface';

const NumberPart = ({ content, disabled, isDecimal,dontHavePriceSelection }: NumberPartProps): JSX.Element => (
    <span
        className={`${isDecimal ? styles['numberDec'] : styles['numberInt']} ${
            disabled && dontHavePriceSelection ? cellStyles['drugManufacturerDisabled'] : ''
        }`}
    >
        {content}
    </span>
);

export default NumberPart;
