// component
import MenuSelect from '../../backoffice/clients/clientSummary/tabPanel/clientUser/Select/MenuSelect';
// utils
import { handlerChangeItemsRow, handlerChangePage, newHandlerChangePage } from './utils/pagination';
import { scrollToTop } from './utils/button';
// rsuite
import { Row, Col } from 'rsuite';
// mui
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { Pagination } from '@material-ui/lab';

// style
import useStyles from '../../backoffice/clients/clientSummary/tabPanel/clientUser/clientUsert.style';
import useStylesButton from '../../../../components/CustomList/Row/RowContainer.styles';
import style from './ListOrdersFooter.module.css';

// interface
import { IListOrdersFooter } from './ListOrdersFooter.interface';
import { EX4666 } from 'config/flags';
import { PaginationList } from '@commons/components/SearchResults/ListResults';

const ListOrdersFooter = ({
    pagination,
    pages,
    setPages,
    itemRow,
    setItemRow,
    firstElement,
    orders,
    deliveries,
    timerSkeleton
}: IListOrdersFooter) => {
    // style
    const classes = useStyles();
    const classesButton = useStylesButton();

    return (
        <Row>
            <div className={style['wrapper-row']}>
                <Col xs={24} sm={24}>
                    <div className={style['col-wrapper']}>
                        <div className={style['wrapper-paginator']}>
                            {EX4666 ? (
                                <PaginationList
                                    total={pagination?.total}
                                    activePage={pagination?.current_page}
                                    limit={pagination?.per_page}
                                    onChangePage={(new_page) => {
                                        // Set page
                                        newHandlerChangePage(new_page, setPages);
                                    }}
                                    onChangeLimit={(limit) => {
                                        // Set page 1 and the number of rows to do the fetch
                                        handlerChangeItemsRow(limit, setPages, setItemRow);
                                    }}
                                    pages={pagination?.total_pages}
                                    isLoading={timerSkeleton}
                                />
                            ) : (
                                <>
                                    <Pagination
                                        className={classes.paginationColorPDV}
                                        count={pagination?.total_pages}
                                        page={pages}
                                        onChange={(evt, value) => handlerChangePage(evt, value, setPages)}
                                        variant="outlined"
                                        shape="rounded"
                                    />
                                    <MenuSelect first={itemRow} setfirst={setItemRow} />
                                    {orders?.length >= 4 && deliveries?.length >= 4 && (
                                        <div className={style['wrapper-button']}>
                                            <div
                                                className={classesButton.containerButtonScrollTop}
                                                onClick={() => scrollToTop(firstElement)}
                                            >
                                                <span className={classesButton.buttonScrollTop}>
                                                    <ExpandLessIcon className={classesButton.icon_scrollTop} />
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </Col>
            </div>
        </Row>
    );
};

export default ListOrdersFooter;
