import { useCallback, useEffect, useState } from 'react';
import { useAuth, useSnackbar } from 'context';
import { useRouter } from 'next/router';
// apis
import { ClientSideCreatePassword, FindUserByToken } from 'api';
// utils && hooks
import { COUNTRY } from 'utils/country';
import { useUser } from 'utils/hooks';
import useChangeLanguage from 'utils/hooks/use-change-language';

export const useActivateUser = () => {
    const snackbar = useSnackbar();
    const { query, push } = useRouter();

    const { login, loading } = useAuth();
    const { mutateUser } = useUser({
        redirectTo: '/',
        redirectIfFound: true,
    });
    const [id, setId] = useState();

    const [username, setUsername] = useState('');

    const [status, setStatus] = useState(100);

    const [formError, setFormError] = useState<boolean>(false);

    const [runValidtionPassowrd, setRunValidationsPassword] = useState<boolean>(false);

    const [formState, setFormState] = useState({
        email: '',
        password: '',
    });

    const updatePasswordError = (value: string, key: string): void => {
        if (key === 'password') {
            setRunValidationsPassword(true);
            if (value?.trim()?.length === 0) {
                setRunValidationsPassword(false);
                setFormError(false);
            }
        }
    };

    const updateFormState = useCallback((value: string, key: string) => {
        updatePasswordError(value, key);
        setFormState((prevState) => ({
            ...prevState,
            [key]: value,
        }));
    }, []);

    const handlePasswordCallback = (v: boolean): void => {
        if (formState?.password?.trim()?.length > 0) {
            setFormError(!v);
        }
    };

    const onSubmit = async () => {
        if (!formError && formState.email?.trim()?.length > 0 && formState.password?.trim()?.length > 0) {
            if(!loading) {
                const response = await ClientSideCreatePassword(
                    username,
                    formState.email,
                    formState.password,
                    query?.token,
                    id,
                );
                if (response?.status === 200) {
                    setFormError(false);
                    const responseLogin = await login(formState.email, formState.password);
                    if (responseLogin) {
                        localStorage.setItem('firstLogin', 'true');
                        mutateUser(responseLogin);
                        localStorage.removeItem('locale');
                    }
                } else {
                    setFormError(true);
                    snackbar.show({
                        error: true,
                        message: response?.data?.data?.validation?.password[0],
                    });
                }
            }
        } else {
            setRunValidationsPassword(true);
            setFormError(true);
        }
    };

    const handleFindUserByToken = async () => {
        let res = await FindUserByToken(query?.token);
        if (res === 404) {
            setStatus(res);
        } else if (res?.email) {
            setStatus(res);
            setId(res?.id);
            updateFormState(res?.email, 'email');
            setUsername(res?.username);
            localStorage.setItem(
                'locale',
                JSON.stringify({
                    CountryCode: res?.client?.country_alfa_2,
                }),
            );
        }
    };

    const redirectToLogin = () => {
        return push('/login');
    };

    useEffect(() => {
        if (query?.token !== null && status === 100) {
            handleFindUserByToken();
        }
    }, [query?.token]);

    return {
        formState,
        updateFormState,
        onSubmit,
        formError,
        loading,
        status,
        redirectToLogin,
        handlePasswordCallback,
        runValidtionPassowrd,
    };
};
