import { useEffect, useState } from 'react';
import { useProductComparison } from 'context/product-comparison.context';
import { AlertModal } from './AlertModal';

function ModalWithPriceWithOutSelection({ control, open, setOpen, distribution, validateNoProductsSelected, user }) {
    // CONTEXT
    const {
        modalPriceWithOutSelection,
        setOpenModalConfigDistribution,
        openModalConfigDistribution,
        setOpenModalConfigPriorities,
        openModalConfigPriorities,
        setShowModalPriceWithOutSelection,
        openModal,
        setOpenModal
    } = useProductComparison();

    useEffect(() => {
        if (validateNoProductsSelected) {
            setOpenModal(true);
            setShowModalPriceWithOutSelection(true);
        }
    }, [validateNoProductsSelected]);

    return (
        <AlertModal
            distribution={distribution}
            open={open}
            setOpen={setOpen}
            setOpenModalConfigDistribution={setOpenModalConfigDistribution}
            openModalConfigDistribution={openModalConfigDistribution}
            setOpenModalConfigPriorities={setOpenModalConfigPriorities}
            openModalConfigPriorities={openModalConfigPriorities}
            anchorEl={modalPriceWithOutSelection?.current}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            control={control}
            handleClose={() => {
                setShowModalPriceWithOutSelection(false);
                setOpenModal(false);
            }}
            openModal={openModal}
        />
    );
}

export default ModalWithPriceWithOutSelection;
