// interface
import { Skeletons } from 'commons/components/Orders/Skeletons';
import { IOrdersByDrugmanufacturer } from './OrdersByDrugmanufacturer.interface';
// style
import style from './OrdersByDrugmanufacturer.module.css';
import { Avatar } from 'rsuite';
import stringAvatar from 'utils/avatar-name';

const OrdersByDrugmanufacturer = ({
  image,
  name,
  timerSkeleton,
  drug_manufacturer_id
}: IOrdersByDrugmanufacturer) => {
  return (
    <div className={style['wrapper-by-orders']}>
      <div>
        {!!timerSkeleton ? (
          <Skeletons
            animation='wave'
            variant='circular'
            width={24}
            height={24}
          />
        ) : (
          <Avatar className={style['name_avatarDrug']}>
            <span className={style['text_nameAvatarDrug']}>
              {stringAvatar(name, false, drug_manufacturer_id)}
            </span>
          </Avatar>
        )}
      </div>

      {!!timerSkeleton ? (
        <Skeletons animation='wave' />
      ) : (
        <p className={style['text-by-orders']}>{name}</p>
      )}
    </div>
  );
};

export default OrdersByDrugmanufacturer;
