export enum LABELS {
    first_name = 'Nombre',
    last_name = 'Apellido',
    email = 'Correo electrónico',
    country_alfa_2 = 'Selecciona tu país',
    phone_number = 'Teléfono',
    pharmacy_name = 'Nombre de la farmacia',
    pharmacy_address = 'Dirección de la farmacia',
    monthly_purchase_volume = 'Volumen de compra mensual',
    pos_count = 'Cantidad de sucursales',
    drug_manufacturer_count = 'Cantidad de distribuidores'
}

export enum MESSAGE {
    PROCESSING_ERROR = 'Hubo un error al procesar tu solicitud. Por favor, intenta nuevamente.',
}

export enum TEXT {
    FIELD_REQUIRED = 'Campo obligatorio',
}
