import { useEffect, useState } from 'react';
// configs
import { EX6809 } from 'config/flags';
// contexts
import { useDrugManufacturersContext, useFetchingContext, useFooterContext } from 'context';
// utils && hooks
import useCurrencyFormatter from 'utils/hooks/useCurrencyFormatter/useCurrencyFormatter';
import { useCalculate } from '../../../hooks';
import { useUser } from 'utils/hooks';
import { getEstimatedSavings } from '../../../utils/getEstimatedSavings';

export const useCanSaving = () => {
    const { footerValues, youAreSavings } = useFooterContext();
    const { loading } = useFetchingContext();
    const { products } = useCalculate();
    const { drugManufacturer } = useDrugManufacturersContext();
    const { user, userLocaleCountryCode } = useUser({});
    const EX4562 = user?.EX4562;
    const [init, setInit] = useState<boolean>(true);
    const [displayValue, setDisplayValue] = useState<string>('0');
    const [displayValueNumber, setDisplayValueNumber] = useState<string>('0');

    const { formatCurrency } = useCurrencyFormatter();
    const { fullValue: footerValuesCanSaving } = formatCurrency({
        value: footerValues?.can_still_save || 0,
        digitsValidation: 7,
    });

    const { fullValue: estimatedSavingsCanSaving } = formatCurrency({
        value: getEstimatedSavings('youCanSave', products, drugManufacturer, userLocaleCountryCode, EX4562) || 0,
        digitsValidation: 7,
    });
    // // Inicializa el worker solo una vez
    // useEffect(() => {
    //     if (EX6809) {
    //         if (!workerRef.current) {
    //             workerRef.current = new Worker(new URL('/public/worker-table.js', import.meta.url), { type: 'module' });
    //         }

    //         // Escucha la respuesta del worker
    //         workerRef.current.onmessage = (event) => {
    //             const { type, result } = event.data;
    //             if (type === EVENT_TYPES.GET_ESTIMATED_SAVINGS_BY_TYPE) {
    //                 const { fullValue: estimatedSavingsCanSaving } = formatCurrency({
    //                     value: result,
    //                     digitsValidation: 7,
    //                 });
    //                 setDisplayValueNumber(result);
    //                 setDisplayValue(estimatedSavingsCanSaving);
    //             }
    //         };

    //         return () => {
    //             workerRef.current.terminate();
    //         };
    //     }
    // }, []);

    const DEPENDENCIES = EX6809
        ? [products, init]
        : [getEstimatedSavings('youCanSave', products, drugManufacturer, userLocaleCountryCode, EX4562)];

    useEffect(() => {
        if (EX6809) {
            if (loading) {
                setInit(true);
            }
        }
    }, [loading]);

    useEffect(() => {
        if (init) {
            setDisplayValue(footerValuesCanSaving);
            setTimeout(() => {
                setInit(false);
            }, 1500);
        } else {
            if (EX6809) {
                if (!init) {
                }
            } else {
                setDisplayValue(estimatedSavingsCanSaving);
            }
        }
    }, DEPENDENCIES);

    return {
        displayValue,
        displayValueNumber: displayValueNumber,
        footerValuesCanSaving: footerValues?.are_saving,
        estimatedSavingsCanSaving: getEstimatedSavings(
            'youCanSave',
            products,
            drugManufacturer,
            userLocaleCountryCode,
            EX4562,
        ),
        youAreSavings,
    };
};
