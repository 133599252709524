import React from 'react';
import { drugManufacturersNeedClientIdentifier } from '../utils/formAttributes';
import style from '../PanelCredentialCardDrawer/PanelCredentialCardDrawer.module.css';
import { Form, SelectPicker } from 'rsuite';

const SpecificFormsCardDrawer = ({
  id,
  selector,
  hasErrors,
  persistForm,
  setPersistForm,
  value,
  step,
  data,
  handlerOnChangeAddress,
  hasAddressesSelected,
}) => {
  const specialDrugs = drugManufacturersNeedClientIdentifier[id];

  let component;

  switch (true) {
    case Array.isArray(specialDrugs) && specialDrugs !== null:
      component = specialDrugs.filter(
        (elem) =>
          elem.credentialType.toLowerCase() === selector.type ||
          (elem.credentialType.toLowerCase() === 'api' &&
            selector.type === 'web actual') ||
          (elem.credentialType.toLowerCase() === 'web' &&
            selector.type === 'web nueva')
      );
      return component?.map(({ name, placeholder }, idx) => {
        return (
          <>
            {name !== 'address' && name !== 'payMethod' && step === 1 ? (
              <>
                <Form.ControlLabel>{placeholder}</Form.ControlLabel>
                <div className={style['container-form-control']}>
                  <Form.Control
                    name={name}
                    className={style['form-control']}
                    size='lg'
                    type='name'
                    style={{
                      border:
                        hasErrors.id === id &&
                        !!hasErrors.bool &&
                        persistForm?.username?.length !== 0 &&
                        persistForm?.password?.length !== 0
                          ? '1px solid var(--Semantic-error-600, #E22E2E)'
                          : '',
                    }}
                    value={persistForm[value]}
                    autoComplete='off'
                    onChange={(evt) =>
                      setPersistForm({
                        ...persistForm,
                        [value]: evt,
                      })
                    }
                  />
                </div>
              </>
            ) : (
              
                step === 2 && name === 'address' && (

                  <div style={{ marginTop: 30 }}>
                  <Form.Group controlId={id}>
                    <Form.ControlLabel>
                      {placeholder}
                    </Form.ControlLabel>
                    <SelectPicker
                      data={data}
                      onChange={handlerOnChangeAddress}
                      style={{
                        borderRadius: 8,
                        width: '100%',
                      }}
                      placeholder='Selecciona una dirección de entrega'
                      searchable={false}
                    />
                  </Form.Group>
                </div>
                )
              
            )}
          </>
        );
      });

    case typeof specialDrugs === 'object' &&
      specialDrugs !== null &&
      !specialDrugs?.fieldType:
      return (
        <>
          <Form.ControlLabel>{specialDrugs.placeholder}</Form.ControlLabel>
          <div className={style['container-form-control']}>
            <Form.Control
              name={specialDrugs.name}
              className={style['form-control']}
              size='lg'
              type='name'
              style={{
                border:
                  hasErrors.id === id &&
                  !!hasErrors.bool &&
                  persistForm?.username?.length !== 0 &&
                  persistForm?.password?.length !== 0
                    ? '1px solid var(--Semantic-error-600, #E22E2E)'
                    : '',
              }}
              value={persistForm[value]}
              autoComplete='off'
              onChange={(evt) =>
                setPersistForm({
                  ...persistForm,
                  [value]: evt,
                })
              }
            />
          </div>
        </>
      );

    case typeof specialDrugs === 'object' &&
      specialDrugs.fieldType === 'autoComplete':
      // Special fields for step 1 and step 2
      return (
        <>
          {step === 1 &&
          hasAddressesSelected !== undefined &&
          hasAddressesSelected !== '' &&
          selector.type === 'web' ? (
            <>
              <Form.ControlLabel>{specialDrugs.placeholder}</Form.ControlLabel>
              <div className={style['container-form-control']}>
                <Form.Control
                  name={specialDrugs.name}
                  className={style['form-control']}
                  size='lg'
                  type='name'
                  style={{
                    border:
                      hasErrors.id === id &&
                      !!hasErrors.bool &&
                      persistForm?.username?.length !== 0 &&
                      persistForm?.password?.length !== 0
                        ? '1px solid var(--Semantic-error-600, #E22E2E)'
                        : '',
                  }}
                  value={persistForm[value]}
                  autoComplete='off'
                  onChange={(evt) =>
                    setPersistForm({
                      ...persistForm,
                      [value]: evt,
                    })
                  }
                />
              </div>
            </>
          ) : null}

          {step === 2 && selector.type === 'web' && (
            <div style={{ marginTop: 30 }}>
              <Form.Group controlId={id}>
                <Form.ControlLabel>
                  {specialDrugs.placeholder}
                </Form.ControlLabel>
                <SelectPicker
                  data={data}
                  onChange={handlerOnChangeAddress}
                  style={{
                    borderRadius: 8,
                    width: '100%',
                  }}
                  placeholder='Selecciona una dirección de entrega'
                  searchable={false}
                />
              </Form.Group>
            </div>
          )}

          {/* Additional logic for step 2 */}
          {/* {stepAddress === 2 && selectedAddressInOtherPos && (
            <InfoMessage
              children={
                <>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span>
                      El punto de venta <b>{pointOfSaleAddressName}</b> tiene la misma dirección de entrega
                    </span>
                    <span>
                      ¿De todas formas querés recibir tus pedidos en esta dirección?
                    </span>
                  </div>
                </>
              }
              backgroundColor={'#FFF9E6'}
              IconColor={'#FFC700'}
            />
          )} */}
        </>
      );

    default:
      return null;
  }
};

export default SpecificFormsCardDrawer;
