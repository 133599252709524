// context
import { useListBoxDrugManufacturersContext } from 'context';
// components
import { DescriptionItem } from './DescriptionItem';
import { ButtonItem } from './ButtonItem';
// interfaces
import { IItemProps } from './Item.interface';
// styles
import styles from './Item.module.css';

const Item = ({ item }: IItemProps): JSX.Element => {
    const { animations } = useListBoxDrugManufacturersContext();
    return (
        <div className={`${styles['item']} ${animations[item?.id] ? styles['slide-out-blurred-right'] : ''}`}>
            <DescriptionItem item={item} />
            <ButtonItem item={item} />
        </div>
    );
};

export default Item;
