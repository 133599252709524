import { t } from 'i18next';
// next
import { useRouter } from 'next/router';
// rsuite
import { Button, ButtonToolbar } from 'rsuite';
// style
import style from './RenderMenuItemSelector.module.css';
import { connectDrugManufacturer } from './handler';
import { useState } from 'react';
import { EX4201 } from 'config/flags';
import { useStatusContext } from 'context/control-state';

const RenderMenuItemSelector = ({ values, userValues, sortedItems, selectValue }) => {
    const [focus, setFocus] = useState(true);
    const { counterStep } = useStatusContext();
    const pdvSelected = localStorage.getItem('pdvSelected');
    // next
    const router = useRouter();

    let validateDisabled = values.availableDrugManufacturers >= 2;

    const handleReferenceName = () => {
        if (values?.reference_name) {
            return (
                <>
                    <span
                        className={
                            !validateDisabled && focus
                                ? style['reference-name-text-disabled']
                                : style['reference-name-text']
                        }
                    >
                        {values?.name}
                    </span>
                    <p
                        className={
                            !validateDisabled && focus
                                ? style['title-render-item-disabled']
                                : style['title-render-item']
                        }
                    >
                        {values?.reference_name}
                    </p>
                </>
            );
        } else {
            return (
                <p
                    className={
                        !validateDisabled && focus ? style['title-render-item-disabled'] : style['title-render-item']
                    }
                >
                    {values?.name}
                </p>
            );
        }
    };

    const handleRedirectPointOfSale = (evt) => {
        if (pdvSelected) {
            evt.stopPropagation(), connectDrugManufacturer(router, userValues?.id, values.id);
        }
    };

    return (
        <div
            style={{
                backgroundColor: values.id === parseInt(selectValue) ? '#DCEDFF' : '',
            }}
            onMouseEnter={() => setFocus(false)}
            onMouseLeave={() => setFocus(true)}
            className={` ${validateDisabled ? style['container'] : style['disabled-item']} ${
                sortedItems.at(0)?.label === values?.label && style['border-top']
            }
      ${sortedItems.at(-1)?.label === values?.label && style['border-bottom']} `}
        >
            {handleReferenceName()}
            <span className={!validateDisabled && focus ? style['text-address-disabled'] : style['text-address']}>
                {values?.address}
            </span>

            {validateDisabled || (
                <div className={`button__connect-drugstore-1 ${style['container-button']}`}>
                    <ButtonToolbar>
                        <Button
                            className={`button__connect-drugstore-1 ${style['button-content']}`}
                            onClick={(evt) => {
                                handleRedirectPointOfSale(evt);
                            }}
                        >
                            {!EX4201 ? 'Conectar droguería' : t('layout.pointsOfSale.connectDrugstore')}
                        </Button>
                    </ButtonToolbar>
                </div>
            )}
        </div>
    );
};

export default RenderMenuItemSelector;
