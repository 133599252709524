//react
import { useRef } from 'react';
// component
import { RsuiteTooltip } from '@commons/components/RsuiteTooltip';
import { RsuitePopover } from '@commons/components/RsuitePopover';
import { CardTypesError } from '../CardTypesError';
// styles
import styles from './CreditError.module.css';
// translation
import { t } from 'i18next';

const CreditError = ({ truncateText, haveErrorCredit, title, subtitle, placement }) => {
    // ref
    const triggerRef = useRef(null);
    const closeTootip = () => triggerRef?.current?.close();

    return (
        <div className={styles['container-name']}>
            <div className={styles['container-text-image']}>
                <img src="/assets/images/error_FILL.svg" className={styles['image']} />
                <RsuiteTooltip
                    placement="top"
                    text={truncateText.texts}
                    trigger="hover"
                    disabled={!truncateText.hasEllipsis}
                >
                    <div className={styles['name']}>{truncateText.texts}</div>
                </RsuiteTooltip>
            </div>
            <RsuitePopover
                //open={false} // testing put in true
                ref={triggerRef}
                placement={placement}
                trigger="hover"
                popoverClassName={styles['popover-container']}
                childOne={<CardTypesError subtitle={subtitle} title={title} openModalCredentials={closeTootip()} />}
                disabled={!!haveErrorCredit ? false : true}
            >
                <p className={styles['error-text']}>{t('efficientPurchasing.comparative.showError')}</p>
            </RsuitePopover>
        </div>
    );
};

export default CreditError;
