import React from 'react';
// STYLES
import styles from '../../TableTotals.module.css';
// INTERFACES
import { ExpandIconProps } from './ExpandIcon.interface';

const ExpandIcon = ({ expandedFooter, setExpandedFooter, skeleton }: ExpandIconProps): JSX.Element => (
    <div
        className={styles['arrow']}
        style={{
            background: skeleton ? '#EBEBE4' : '',
        }}
        onClick={() => setExpandedFooter(!expandedFooter)}
    >
        <img src={`/assets/images/expand_${expandedFooter ? 'less' : 'more'}_white.svg`} alt="row icon" />
    </div>
);

export default ExpandIcon;
