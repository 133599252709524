import { memo } from 'react';

export const Icon = memo(() => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
            <path
                d="M4.50008 15.1667C4.13341 15.1667 3.81953 15.0362 3.55841 14.7751C3.2973 14.514 3.16675 14.2001 3.16675 13.8334V7.16675C3.16675 6.80008 3.2973 6.48619 3.55841 6.22508C3.81953 5.96397 4.13341 5.83342 4.50008 5.83342H5.16675V4.50008C5.16675 3.57786 5.49175 2.79175 6.14175 2.14175C6.79175 1.49175 7.57786 1.16675 8.50008 1.16675C9.4223 1.16675 10.2084 1.49175 10.8584 2.14175C11.5084 2.79175 11.8334 3.57786 11.8334 4.50008V5.83342H12.5001C12.8667 5.83342 13.1806 5.96397 13.4417 6.22508C13.7029 6.48619 13.8334 6.80008 13.8334 7.16675V13.8334C13.8334 14.2001 13.7029 14.514 13.4417 14.7751C13.1806 15.0362 12.8667 15.1667 12.5001 15.1667H4.50008ZM4.50008 13.8334H12.5001V7.16675H4.50008V13.8334ZM8.50008 11.8334C8.86675 11.8334 9.18064 11.7029 9.44175 11.4417C9.70286 11.1806 9.83341 10.8667 9.83341 10.5001C9.83341 10.1334 9.70286 9.81953 9.44175 9.55842C9.18064 9.2973 8.86675 9.16675 8.50008 9.16675C8.13341 9.16675 7.81953 9.2973 7.55841 9.55842C7.2973 9.81953 7.16675 10.1334 7.16675 10.5001C7.16675 10.8667 7.2973 11.1806 7.55841 11.4417C7.81953 11.7029 8.13341 11.8334 8.50008 11.8334ZM6.50008 5.83342H10.5001V4.50008C10.5001 3.94453 10.3056 3.4723 9.91675 3.08341C9.52786 2.69453 9.05564 2.50008 8.50008 2.50008C7.94453 2.50008 7.4723 2.69453 7.08341 3.08341C6.69453 3.4723 6.50008 3.94453 6.50008 4.50008V5.83342Z"
                fill="#6B6F93"
            />
        </svg>
    );
});
