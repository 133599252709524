enum COLORS {
    BLUE = '#0171E6',
    YELLOW = '#FFC700',
    RED = '#E22E2E',
    DEFAULT = '#0171E6',
}

export const getBorderColor = (isFetching, blockerFormat, blockButton, errors) => {
    if (isFetching) {
        return COLORS.BLUE;
    } else if (blockButton ) {
        return COLORS.YELLOW;
    }  else if (blockerFormat ) {
        return COLORS.RED;
    }else if (errors) {
        return COLORS.RED;
    } else {
        return COLORS.DEFAULT;
    }
};
