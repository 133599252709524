import React, { useEffect, useState } from 'react';
//style
import style from './ControlPassword.module.css';
//icons
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { EX5057 } from 'config/flags';
import { useTranslation } from 'react-i18next';

function ControlPassword({ hasUppercase, validLength, hasNumber, arrayObj, values, setDisableButton }) {
    //translation by Country
    const { t } = useTranslation();
    const [haveValue, setHaveValue] = useState(false);
    const [passwordMatch, setPasswordMatch] = useState(false);
    const [controlEmail, setControlEmail] = useState(true);
    const passwordCondition = [
        { name: EX5057 ? t('login.login13') : 'Mínimo 8 caracteres', control: validLength },
        { name: EX5057 ? t('login.login14') : 'Al menos 1 mayúscula', control: hasUppercase },
        { name: EX5057 ? t('login.login15') : 'Al menos 1 número', control: hasNumber },
    ];

    const iconPassword = (control) => {
        if (control) {
            return <CheckIcon style={{ height: 16, width: 16, color: '#07AD4B', marginRight: 8 }} />;
        } else {
            return <CloseIcon style={{ height: 16, width: 16, color: '#C62828', marginRight: 8 }} />;
        }
    };

    useEffect(() => {
        arrayObj?.map((obj) => {
            if (values[obj.name] !== '' && obj.name !== 'email') {
                setHaveValue(true);
            }
            if (obj.name === 'email') {
                setControlEmail(false);
            }
        });

        setPasswordMatch(
            values['NewPassword'] === values['RepetedPassword'] &&
                values['NewPassword'] !== '' &&
                values['RepetedPassword'] !== '',
        );
    }, [values]);

    useEffect(() => {
        if (hasUppercase && validLength && hasNumber && passwordMatch && controlEmail) {
            setDisableButton(false);
        } else if (hasUppercase && validLength && hasNumber && !controlEmail) {
            setDisableButton(false);
        } else {
            setDisableButton(true);
        }
    }, [hasUppercase, validLength, hasNumber, passwordMatch]);

    return (
        <div style={{ marginBottom: 20 }}>
            <p className={style['characters-pass']}>{EX5057 ? t('login.login12') : 'Requisitos para tu contraseña'}</p>
            {passwordCondition.map((condition) => (
                <div className={style['password-conditions']}>
                    {haveValue && iconPassword(condition.control)}

                    <p
                        className={
                            haveValue && !condition.control ? style['span-password-error'] : style['span-password']
                        }
                    >
                        {condition.name}
                    </p>
                </div>
            ))}
            {controlEmail && (
                <div className={style['password-conditions']}>
                    {haveValue && iconPassword(passwordMatch)}

                    <p className={haveValue && !passwordMatch ? style['span-password-error'] : style['span-password']}>
                        {EX5057 ? t('login.login47') : 'Las contraseñas deben coincidir'}
                    </p>
                </div>
            )}
        </div>
    );
}

export default ControlPassword;
