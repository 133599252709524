import { useEffect, useState } from 'react';
// import { useRouter } from 'next/router';
// contexts
import { usePrioritiesPercentagesCeContext } from 'context/PrioritiesPercentagesCe/PrioritiesPercentagesCe-context';
// import { useProductComparison } from 'context/product-comparison.context';
// types
import { ServiceId } from 'common/types';
import { EX6694 } from 'config/flags';
import { useDrugManufacturersContext, useFetchingContext } from 'context';

const PrioritiesConfig = () => {
    //router
    // const router = useRouter();
    //context
    // const { orderId } = router.query;
    // const { 
    //     // drugManufacturer, 
    //     loading } = useProductComparison();
    const { loading } = useFetchingContext();

    const { drugManufacturer } = useDrugManufacturersContext();
    //hooks
    const [priorities, setPriorities] = useState([]);
    const [avatarPriorities, setAvatarPriorities] = useState([]);
    const [unassigned, setUnassigned] = useState(false);
    // new context by prioritys and percentages
    const { data, serviceId } = usePrioritiesPercentagesCeContext();
    const [changeAvatares, setChangeAvatares] = useState<boolean>(false);
    const DEPENDENCIES = EX6694 ? [data, loading, serviceId, drugManufacturer] : [data, loading];

    // new effect
    useEffect(() => {
        if (EX6694) {
            if (drugManufacturer?.length && !loading && serviceId === ServiceId.ByPriority) {
                handleGetDataPriorities();
            }
        } else {
            if (serviceId === ServiceId.ByPercentage) {
                handleGetDataPriorities();
            }
        }
    }, DEPENDENCIES);

    const handleGetDataPriorities = async () => {
        // new logic
        if (Object.keys(data)?.length === 0) {
            setAvatarPriorities([]);
        } else {
            const responsePriorities = data?.assigned;
            const responseUnassigned = data?.unassigned;

            setPriorities(responsePriorities);
            if (EX6694) {
                setChangeAvatares(true);
            } else {
                const avatares = handleAvataresPriorities();
                if (responseUnassigned?.length > 0) {
                    setUnassigned(true);
                    responseUnassigned?.map((item) => avatares?.push(item?.name));
                } else {
                    setUnassigned(false);
                }
                setAvatarPriorities(avatares);
            }
        }
    };

    useEffect(() => {
        if (EX6694 && changeAvatares) {
            const avatares = handleAvataresPriorities();
            if (data?.unassigned?.length > 0) {
                setUnassigned(true);
                data?.unassigned?.map((item) => avatares?.push(item?.name));
            } else {
                setUnassigned(false);
            }
            setAvatarPriorities(avatares);
        }
    }, [changeAvatares]);

    const handleAvataresPriorities = () => {
        let auxList = [];
        priorities.sort((a, b) => (a.priority_number > b.priority_number ? 1 : -1));

        priorities.forEach(function (item) {
            const found = drugManufacturer?.find((element) => element.id === item.drug_manufacturer_id);
            if (found) {
                auxList.push(found?.name);
            }
        });
        if (EX6694) {
            setChangeAvatares(false);
        }
        return auxList;
    };

    return { priorities: avatarPriorities, unassigned };
};

export default PrioritiesConfig;
