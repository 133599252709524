export const handleRemoveItemAnimation = (setter): void => {
    setter(true);
};

export const handleAnimationEnd = (
    e: any,
    fn: (drugManufacturerId: number, type: string) => void,
    drugManufacturerId: number,
    type: string,
): void => {
    if (/slide-out-blurred-right/.test(e?.animationName)) {
        fn(drugManufacturerId, type);
    }
};

export const onClickRequestVendor = async (fn, setterDisabled, drugManufacturer, setterDeleted) => {
    setterDisabled(true);
    const res: any = await fn(drugManufacturer?.name, drugManufacturer?.id);
    if (res?.status === 200) {
        setterDeleted(true);
    }
};
