// utils
import { useUser } from 'utils/hooks';

export const useFF = () => {
    const { user } = useUser({});
    // FF's
    const EX4562 = !!user?.EX4562; //cdo
    const EX3523 = process.env.NEXT_PUBLIC_EX3523 === 'true';
    const EX4903 = !!user?.EX4903;
    const EX5506 = !!user?.EX5506; //FF epic insufficientCredit V2
    const EX6144 = !!user?.EX6144;

    return {
        EX4562,
        EX3523,
        EX4903,
        EX5506,
        EX6144,
    };
};
