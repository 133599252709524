import { EX5057 } from 'config/flags';

export const executeOnClick = (id, label, onClick, router, execLogout, execModal, setIsFullScreen, isFullScreen) => {
    if (EX5057) {
        switch (id) {
            case 1:
                return onClick(router, null);
            case 2:
                return onClick(execModal, null);
            case 4:
                return onClick(execLogout, router);
            case 3:
                onClick(setIsFullScreen, isFullScreen);
                return;
            default:
                break;
        }
    } else {
        switch (label) {
            case 'Configuración':
                return onClick(router, null);
            case 'Cambiar Contraseña':
                return onClick(execModal, null);
            case 'Salir':
                return onClick(execLogout, router);
            case 'Pantalla completa' || 'Salir pantalla completa':
                onClick(setIsFullScreen, isFullScreen);
                return;
            default:
                break;
        }
    }
};
