import React from 'react';
import { Whisper, Popover } from 'rsuite';
import { IRsuitePopover } from './RsuitePopover.interface';

const RsuitePopover = ({
    childOne,
    children,
    trigger,
    placement,
    ref,
    popoverClassName,
    open,
    disabled,
    parentRef,
    style,
    className,
    onClick,
    showArrow
}: IRsuitePopover) => {

    return (
        <Whisper
            ref={ref}
            trigger={trigger}
            placement={placement}
            speaker={
                <Popover className={popoverClassName} style={style} arrow={showArrow}>
                    {childOne}
                </Popover>
            }
            enterable
            open={open}
            disabled={disabled}
            container={parentRef?.current || document.body}
            className={className}
            onClick={onClick}
        >
            {children}
        </Whisper>
    );
};

export default RsuitePopover;
