import { useEffect, useState } from 'react';
// contexts
import { NewUseCart, useCreateOrder, useNewSearchProduct, usePointOfSale } from 'context';
import { useStatusContext } from 'context/control-state';
import { useDragAndDrop } from 'context/dragAndDropContext';
// utils
import { EX6229, EX6243 } from 'config/flags';
import { useUser } from 'utils/hooks';
import { isGestorERPFormat, isZettiFormat } from 'utils/pointOfSale';

const useDragDropUploader = () => {
    // Custom hooks
    const {
        handleUpload,
        blockerFormat,
        errors,
        dataWithError,
        isFetching,
        formatFile,
        handlerUploadWithErrors,
        setErrors,
        isLoadingFileWithErrors,
        setBlockerFormat,
        setDataWithError,
    } = useCreateOrder();

    const { quantityCart, visibility, isExpanded, handleResetVisibility } = NewUseCart();

    const { isOpen } = useNewSearchProduct();

    const { handleDragLeave, setVisibleDragDrop, isDragging, visibleDragDrop } = useDragAndDrop();

    const { setDisplayModalOnBoarding, displayModalOnBoarding } = useStatusContext();

    const { pointOfSale } = usePointOfSale();

    const { userLocaleCountryCode, user } = useUser({});

    // State
    const [files, setFiles] = useState<any>([]);
    const isZetti: boolean = isZettiFormat(pointOfSale);
    const isGestorERP: boolean = isGestorERPFormat(pointOfSale);

    const handleCloseIcon = (): void => {
        if (!isZetti && !isGestorERP) {
            setVisibleDragDrop(false);
        }
        setErrors(false);
        setBlockerFormat(false);
        if (EX6229) {
            setDataWithError([]);
        }
    };

    const resetErrorsWhenHaveFormatError = () => {
        setDataWithError([]);
        setErrors(false);
    };

    const handleKeyDown = (event): void => {
        const key = event?.key || event?.keyCode;
        // 27 is the keyCode for the Escape key
        if (key === 'Escape' || key === 27) {
            if (!isZetti && !isGestorERP) {
                setVisibleDragDrop(false);
            }
            setErrors(false);
            setBlockerFormat(false);
            setDataWithError([]);
        }
    };

    useEffect(() => {
        const handleDragLeaveWindow = (event) => {
            // check if the dragleave event actually exited the window
            if (
                event.clientX <= 0 ||
                event.clientY <= 0 ||
                event.clientX >= window.innerWidth ||
                event.clientY >= window.innerHeight
            ) {
                if (EX6243) {
                    handleResetVisibility();
                }
                handleDragLeave(event);
            }
        };

        // Add event listener for keydown and dragleave
        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('dragleave', handleDragLeaveWindow);

        // Remove event listeners when unmounting the component
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('dragleave', handleDragLeaveWindow);
        };
    }, [isZetti, isGestorERP, user]);

    return {
        handleUpload,
        blockerFormat,
        errors,
        dataWithError,
        isFetching,
        formatFile,
        handlerUploadWithErrors,
        setErrors,
        isLoadingFileWithErrors,
        handleDragLeave,
        setVisibleDragDrop,
        visibleDragDrop,
        isDragging,
        setDisplayModalOnBoarding,
        displayModalOnBoarding,
        pointOfSale,
        userLocaleCountryCode,
        files,
        setFiles,
        isZetti,
        handleCloseIcon,
        setDataWithError,
        setBlockerFormat,
        resetErrorsWhenHaveFormatError,
        isOpen,
        quantityCart,
        visibility,
        isExpanded,
        isGestorERP,
    };
};

export default useDragDropUploader;
