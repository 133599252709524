// components
import { Errors } from './Errors';
import { Progress } from './Progress';
import { CancelButton } from './CancelButton';
// utils && hooks
import { useContent } from './hooks';
// interfaces
import { IContentProps } from './Content.interface';
// styles
import styles from './Content.module.css';

const Content = ({ handleCancelModal, getFailedConnectionDrugManufacturers, confirmCancelation }: IContentProps) => {
    const { errorRefContainer, errors, failed, currentStep } = useContent({ getFailedConnectionDrugManufacturers });

    return (
        <div className={`${styles['wrapper']} ${styles['fade-in']}`}>
            <Progress errors={errors?.length > 0} confirmCancelation={confirmCancelation} />
            {errors?.length > 0 && currentStep !== 4 && (
                <Errors errors={errors} errorRefContainer={errorRefContainer} />
            )}
            {!failed && currentStep !== 4 && (
                <CancelButton
                    hasError={errors?.length > 0}
                    multiplyErrors={errorRefContainer?.current?.offsetHeight > 24}
                    handleCancelModal={handleCancelModal}
                />
            )}
        </div>
    );
};

export default Content;
