import React from 'react';
// COMPONENTS
import { MultipleDiscount } from './MultipleDiscount';
import { OnlyDiscount } from './OnlyDiscount';

const DiscountChip = (
    product,
    productPVP,
    ITS_APPLY,
    IS_SUIZA_TUCUMAN,
    drugManufacturerId,
    drugManufacturerName,
    t,
    isDisabled = false
): JSX.Element => {
    /* #region render */
    const render = () => {
        // has discounts
        if (product?.discounts?.length > 1) {
            return (
                <MultipleDiscount
                    its_apply={ITS_APPLY}
                    is_suiza_tucuman={IS_SUIZA_TUCUMAN}
                    product={product}
                    drugManufacturerId={drugManufacturerId}
                    t={t}
                    drugManufacturerName={drugManufacturerName}
                    isDisabled={isDisabled}
                />
            );
        } else {
            // only discount
            return (
                <OnlyDiscount
                    is_suiza_tucuman={IS_SUIZA_TUCUMAN}
                    t={t}
                    product={product}
                    productPVP={productPVP}
                    drugManufacturerId={drugManufacturerId}
                />
            );
        }
    };
    /* #endregion */

    return render();
};

export default DiscountChip;
