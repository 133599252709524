// components
import { Dropdown } from 'rsuite';
// interfaces
import { IContentProps } from './Content.interface';
// styles
import styles from './Content.module.css';

const Content = ({ data }: IContentProps) => {
    return (
        <>
            {data?.map((item: any, idx: number) => {
                return (
                    <Dropdown.Item
                        key={idx + 1}
                        id={item?.type}
                        icon={
                            <img
                                src={'/assets/images/download_file.svg'}
                                width={20}
                                height={20}
                                alt="icon download"
                                draggable={false}
                                className={styles['item-icon']}
                            />
                        }
                    >
                        {item?.text}
                    </Dropdown.Item>
                );
            })}
        </>
    );
};

export default Content;
