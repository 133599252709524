import { Col } from "rsuite";
import { DeliveriesCard } from "../DeliveriesCard";
import { CredentialCard } from "./Card";
import { IDetailsCard } from "./DetailsCard.interface";
import SkeletonDetailsCard from "./SkeletonDetailsCard";
import { DeliveryTypes } from "../DeliveriesCard/DeliveriesCard.interface";

function DetailsCard({ details }: IDetailsCard) {
  return (
    <Col xs={24}>
      <CredentialCard details={details} deliveryDetail={false} />
      {details?.order_deliveries?.manual_delivery.length > 0 && (
        <DeliveriesCard
          order_deliveries={details?.order_deliveries?.manual_delivery}
          type={DeliveryTypes.MANUAL}
        />
      )}
      {details?.order_deliveries?.automatic_delivery.length > 0 && (
        <DeliveriesCard
          order_deliveries={details?.order_deliveries?.automatic_delivery}
          type={DeliveryTypes.AUTOMATIC}
        />
      )}

      {!details && <SkeletonDetailsCard />}
    </Col>
  );
}

export default DetailsCard;
