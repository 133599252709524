import { useState, useEffect, useRef } from 'react';

const useTruncate = (containerRef, paragraphRef) => {
    const [isTruncated, setIsTruncated] = useState(false);

    useEffect(() => {
        const paragraph = paragraphRef?.current;

        const handleResize = () => {
            setIsTruncated(paragraph?.offsetHeight < paragraph?.scrollHeight);
        };

        const resizeObserver = new ResizeObserver(handleResize);

        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
            handleResize();
        }

        return () => {
            if (containerRef.current) {
                resizeObserver.unobserve(containerRef.current);
            }
        };
    }, [containerRef, paragraphRef?.current]);

    return { isTruncated };
};

export default useTruncate;
