import { makeStyles } from '@material-ui/core/styles';
import { important, rgba } from 'polished';

const useStyle = makeStyles((theme) => ({
    tableRow: {
        height: 32,
        paddingTop: 8,
        paddingBottom: 8,
        border: 0,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#EEEEEE !important',
        },
        '&.MuiTableRow-root.Mui-selected': {
            backgroundColor: `#E3E3E3 !important`,
            height: 32,
        },
        '&.MuiTableRow-root.Mui-selected:hover': {
            backgroundColor: '#EEEEEE !important',
        },
    },
    modalInfo: {
        '& .MuiList-padding': {
            paddintTop: 0,
            paddingRight: 8,
        },
    },
    tableCell: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItem: 'center',
        border: 0,
        padding: 0,
        borderBottom: 'none',
        '&.MuiTableCell-root': {
            borderBottom: 0,
            padding: '12px 16px',
            /* paddingLeft: 16,
      paddingRight:16, */
        },
    },
    selectionSpan: {
        color: '#0171E6',

        /* Body/Body 1/Regular */
        fontFamily: 'Source Sans Pro',
        fontSize: 16,
        fontStyle: 'italic',
        fontWeight: 400,
    },
    tolerance: {
        cursor: 'pointer',
        '& label.Mui-focused': {
            color: '#646464',
        },
        '& label': {
            color: '#646464',
        },
        '& .MuiInput-underline:after': {
            borderColor: '#ACACAC',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#ACACAC',
            },
            '&:hover fieldset': {
                borderColor: '#ACACAC',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#ACACAC',
                borderWidth: 0.5,
            },
            '& input:valid:focus + fieldset': {
                borderWidth: 0.5,
            },
        },
    },
    container: {
        width: '512px',
        marginBottom: 16,
    },
    rowChipContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    rowContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    rowContainerAvatares: {
        display: 'flex',
        alignItems: 'center',
        width: '56%',
    },
    rowContainerAvataresResponsive: {
        display: 'flex',
        alignItems: 'center',
        width: '37%',
    },
    rowInput: {
        display: 'flex',
        justifyContent: 'space-between',
        height: 40,
        borderRadius: 4,
        paddingLeft: 15,
        width: 312,
        alignItems: 'center',
        color: theme.palette.neutral[900],
        background: '#EEEEEE',
    },
    rowInput_firstIndex: {
        display: 'flex',
        justifyContent: 'space-between',
        height: 64,
        borderRadius: 4,
        paddingLeft: 15,
        width: '100%',
        alignItems: 'center',
        color: theme.palette.neutral[900],
        background: '#FFF3D6',
    },
    alert: {
        border: '1px solid #FF3030',
        width: 272,
        height: 40,
        borderRadius: 5,
        paddingLeft: 10,
        fontSize: 15,
        cursor: 'pointer',
        background: 'white',
        display: 'flex',
        paddingRight: 10,
        paddingTop: 9,
        justifyContent: 'center',
        paddingBottom: 9,
        color: '#646464',
    },
    normal: {
        border: '1px solid #b6b6b6',
        width: 272,
        background: 'white',
        height: 40,
        borderRadius: 5,
        paddingLeft: 10,
        fontSize: 15,
        outline: 'red',
        cursor: 'pointer ',
        display: 'flex',
        paddingRight: 10,
        paddingTop: 9,
        justifyContent: 'center',
        paddingBottom: 9,
        color: '#333333',
    },
    input_placeholder: {
        '&::placeholder': {
            color: '#646464',
            opacity: 1,
        },
    },
    alert_buyer: {
        border: '1px solid #FF3030',
        width: 420,
        height: 40,
        borderRadius: 5,
        paddingLeft: 10,
        fontSize: 15,
        cursor: 'pointer',
        background: 'white',
        display: 'flex',
        paddingRight: 10,
        paddingTop: 9,
        justifyContent: 'center',
        paddingBottom: 9,
    },
    normal_buyer: {
        border: '1px solid #b6b6b6',
        width: 420,
        background: 'white',
        height: 40,
        borderRadius: 5,
        paddingLeft: 10,
        fontSize: 15,
        outline: 'red',
        cursor: 'pointer ',
        display: 'flex',
        paddingRight: 10,
        paddingTop: 9,
        justifyContent: 'center',
        paddingBottom: 9,
    },
    input: {
        width: '70%',
        height: 40,
        borderRadius: 5,
        border: '1px solid #b6b6b6',
        paddingLeft: 10,
        fontSize: 15,
    },
    message: {
        margin: 0,
        padding: 0,
        color: '#FF3030',
        fontFamily: 'Source Sans Pro',
        fontSize: '12px',
        letterSpacing: 0.06,
        width: '100%',
        height: '10px',
        display: 'flex',
        paddingLeft: '0px',
        bottom: '0px',
        position: 'relative',
        marginLeft: 40,
    },
    hide: {
        color: 'transparent',
        margin: 0,
        height: '10px',
        padding: 0,
        fontSize: '11px',
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        '&.MuiButton-root': {
            color: theme.palette.Ra[400],
            border: `1px solid  ${theme.palette.Ra[400]}`,
            paddingLeft: '16px',
            paddingRight: '16px',
            '&:hover': {
                background: theme.palette.Ra[100],
                color: theme.palette.Ra[400],
                border: `1px solid  ${theme.palette.Ra[400]}`,
            },
        },
    },
    buttonBackgroung: {
        '&.MuiButtonBase-root': {
            background: theme.palette.Ra[400],
            color: 'white',
            paddingLeft: '16px',
            paddingRight: '16px',
            '&:hover': {
                background: theme.palette.Ra[500],
            },
        },
    },
    buttonGray: {
        '&.MuiButton-root': {
            backgroundColor: 'white',
            color: theme.palette.neutral[800],
            paddingLeft: '16px',
            paddingRight: '16px',
            '&:hover': {
                background: 'white',
            },
        },
    },
    styleTooltip: {
        position: 'absolute',
        margin: 0,
        padding: 0,
        top: 43,
        background: '#04273F',
        color: 'white',
        borderRadius: 4,
        width: 355,
        height: 24,
        fontSize: 14,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    overlay: {
        boxShadow: 'rgba(51, 51, 51, 0.3) 0px 0px 0px 5000px',
        height: 64,
        position: 'absolute',
        zIndex: 9999999,
        border: 'none',
        borderRadius: 4,
        marginBottom: 10,
    },
    scroll_table: {
        marginTop: 4,
        maxHeight: '180px',
        background: 'white',
        width: '272px',
        boxShadow: '1px 2px 17px #00000029',
        zIndex: 10000,
        borderRadius: '4px',
        paddingTop: 8,
        paddingBottom: 8,
    },
    container_inputPosition: {
        fontWeight: 600,
        fontSize: '16px',
        fontFamily: 'Source Sans Pro',
        color: '#333333',
    },
    container_inputText: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        marginLeft: 16,
    },
    container_helpIcon: {
        alignItems: 'center',
        height: 64,
        justifyContent: 'center',
        display: 'flex',
        marginLeft: 4,
        marginRight: 4,
    },
    icon_InfoOutline: {
        color: '#0171E6',
        marginTop: 30,
        height: 24,
        width: 24,
        marginLeft: 22,
    },
    tolerance_alertText: {
        margin: 0,
        padding: 0,
        color: '#333333',
        fontSize: 16,
        marginLeft: 16,
        fontFamily: 'Source Sans Pro',
        fontWeight: 600,
        paddingTop: 16,
    },
    toleranceText: {
        margin: 0,
        padding: 0,
        color: '#646464',
        fontSize: 14,
        fontFamily: 'Source Sans Pro',
        marginLeft: 16,
        marginTop: 8,
        marginRight: 24,
    },
    tolerance_AlertButtonContainer: {
        display: 'flex',
        marginTop: 16,
        width: '100%',
        justifyContent: 'flex-end',
    },
    tolerance_buttonConfirm: {
        '&.MuiButton-root': {
            color: '#646464',
            border: '1px solid #646464',
            padding: '8px 16px',
            marginLeft: 8,
            fontSize: 14,
        },
    },
}));
export default useStyle;