import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import { Panel, Form, Row, Col } from 'rsuite';
import style from './PaymentForm.module.css';
import { ExButton } from '@commons/EXComponentsLibrary';
import { subscriptionFormValidationSchema } from './PaymentForm.schema';
import { SubscriptionForm, SubscriptionDetail, SubscriptionFormHeader } from '.';
import { postSendPayment } from 'api/payment/payment';

const PaymentForm = ({ setIsSubmitting, customerObject, hash, setShowModal }) => {
    // states
    const initialFormValue = {
        credit_card_holder_name: '',
        credit_card_number: '',
        expiration_date: '',
        credit_card_cvc: '',
    };
    const [formValue, setFormValue] = useState<any>(initialFormValue);
    const [formError, setFormError] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [isValidDate, setIsValidDate] = useState(true);
    const [isAmex, setIsAmex] = useState(false);

    // Manejar cambios en los inputs
    const handleChange = (value, name) => {
        setFormValue((prev) => ({ ...prev, [name]: value }));
    };

    // Manejar envío del formulario
    const handleSubmit = async (isValid) => {
        if (isValid) {
            setIsLoading(true);
            // Split the expiration date into month and year
            const [month, year] = formValue.expiration_date.split('/');

            const payload = {
                client_id: customerObject?.client_id,
                credit_card_holder_name: formValue.credit_card_holder_name,
                credit_card_number: isAmex
                    ? formValue.credit_card_number.replace(/\s+/g, '').slice(0, -1) // Elimina el último número si es Amex
                    : formValue.credit_card_number.replace(/\s+/g, ''),
                credit_card_expiration_month: month,
                credit_card_expiration_year: year,
                credit_card_cvc: formValue?.credit_card_cvc,
                hash: hash,
            };

            try {
                const response = await postSendPayment(payload);

                if (response?.status === 200) {
                    setFormValue(initialFormValue);
                    setIsSubmitting(true);
                } else {
                    setShowModal({ state: true, message: response?.error?.message });
                }
            } catch (error) {
                console.error('Error en la solicitud de pago:', error);
            }

            setIsLoading(false);
            return;
        }
    };
    useEffect(() => {
        // Verifica si el formulario está completo y si la fecha de expiración es válida
        const isFormValid =
            formValue.credit_card_holder_name &&
            formValue.credit_card_number &&
            formValue.expiration_date &&
            formValue.credit_card_cvc;

        // Deshabilitar el botón si hay un campo vacío o la fecha de expiración no es válida
        if (!isValidDate && formValue.expiration_date) {
            setIsButtonDisabled(true);
        } else {
            setIsButtonDisabled(!isFormValid);
        }
    }, [formValue, isValidDate]);

    return (
        <>
            <div className={style['container-payment-form']}>
                <Panel className={style['panel-style']} bodyFill bordered header={<SubscriptionFormHeader />}>
                    {/* Información del cliente */}
                    {/*  <SubscriptionClientForm onChangeClient={handleChange} error={emailError} /> */}

                    <div className={style['container-form-contact']}>
                        <Row gutter={10}>
                            <Col xs={12}>
                                <SubscriptionDetail customerObject={customerObject} />
                            </Col>
                            <Col xs={12}>
                                <p className={style['form-contact']}>Datos de la tarjeta</p>
                                <Form
                                    fluid
                                    model={subscriptionFormValidationSchema}
                                    onCheck={(error) => setFormError(error)}
                                >
                                    <SubscriptionForm
                                        formError={formError}
                                        onChangeMaskedInput={handleChange}
                                        onChangeSingleInput={handleChange}
                                        defaultValue={formValue}
                                        setIsValidDate={setIsValidDate}
                                        isValidDate={isValidDate}
                                        isAmex={isAmex}
                                        setIsAmex={setIsAmex}
                                    />
                                    <div className={style['payment-action-button']}>
                                        <p className={style['payment-advice-privacity']}>
                                            Al pagar acepto que estoy de acuerdo con los{' '}
                                            <a href="/pdf/termsAndConditions/mx/termsMXv4.pdf" target="_blank">
                                                Términos y condiciones
                                            </a>{' '}
                                            y{' '}
                                            <a href="/pdf/privacyPolicy/mx/privacyPolicyMX.pdf" target="_blank">
                                                Políticas de privacidad
                                            </a>{' '}
                                            de Extendeal MX SA de CV
                                        </p>
                                        <div className={style['container-payment-button']}>
                                            <ExButton
                                                className={style['payment-button']}
                                                onClick={() => handleSubmit(Object.keys(formError).length === 0)}
                                                disabled={isLoading || isButtonDisabled}
                                                loading={isLoading}
                                            >
                                                Pagar
                                            </ExButton>
                                        </div>
                                    </div>
                                </Form>
                            </Col>
                        </Row>
                        <div className={style['payment-footer']}>
                            <span>Powered by:</span>
                            <Image
                                src="/assets/images/open-color.bb6186f.svg"
                                alt="logo openpay"
                                width={120}
                                height={80}
                            />
                        </div>
                    </div>
                </Panel>
            </div>
        </>
    );
};

export default PaymentForm;
