import { StatusCdo } from 'utils/interfaces';

interface shouldDisplayBestPriceBadgeProps {
    skeleton: boolean;
    isLowest: boolean;
    cdo_status_id: number | null;
}

export const shouldDisplayBestPriceBadge = ({
    skeleton,
    isLowest,
    cdo_status_id,
}: shouldDisplayBestPriceBadgeProps): boolean => {
    return !skeleton && !!isLowest && cdo_status_id === StatusCdo.DISABLED;
};
