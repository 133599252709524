import { memo } from 'react';
import { useRouter } from 'next/router';
import { t } from 'i18next';
// contexts
import { useDrugManufacturerWidgetContext } from 'context';
// components
import { ExButton } from '@commons/EXComponentsLibrary';
// utils && hooks
import { EDrugManufacturerTypeId } from 'utils/enums/drugmanufacturerTypeId/DrugManufacturerTypeId';
import { typesOfErrors } from 'utils/drugManufacturers/errors';
// interfaces
import { IConnectedButtonsProps } from './ConnectedButtons.interface';
// styles
import styles from './ConnectedButtons.module.css';

const ConnectedButtons = ({ child, from, disabledEdit }: IConnectedButtonsProps) => {
    const { push, query } = useRouter();
    const { triggerModal } = useDrugManufacturerWidgetContext();
    const missingFormat = typesOfErrors(child)?.missing_format;
    const isErrorCredential = typesOfErrors(child)?.has_wrong_username_password;
    const isOffline = child?.type?.id === EDrugManufacturerTypeId.offline;
    const gtagClassName =
        isErrorCredential && !isOffline ? 'button__update-credentials-home' : isOffline ? 'button__update-file-2' : '';

    return (
        <>
            {(typesOfErrors(child)?.hasSomeError || isOffline) && (
                <ExButton
                    className={gtagClassName}
                    size="md"
                    appearance={'outline'}
                    fullWidth
                    onClick={() => {
                        if (isOffline) {
                            localStorage.setItem('redirectTo', 'home');
                            push(`/${query.clientId}/settings/POS/${query?.posId}/addOfflineDrugstore/${child?.id}`);
                        } else {
                            triggerModal(
                                child,
                                child.id,
                                child.credential_types,
                                child.type.id,
                                missingFormat,
                                isErrorCredential,
                            );
                        }
                    }}
                >
                    {isOffline
                        ? t('newWidgetHome.buttonUpdate')
                        : missingFormat
                        ? t('newCredentialsRefactor_Button_Two')
                        : t('newWidgetHome.buttonUpdate')}
                </ExButton>
            )}

            {!typesOfErrors(child)?.hasSomeError && !isOffline && !disabledEdit(child) && (
                <img
                    src={'/assets/images/expand_Icon_black.svg'}
                    alt="close icon"
                    width={16}
                    height={16}
                    draggable={false}
                    className={styles['arrow-icon']}
                    onClick={() => {
                        if (from === 2) {
                            !typesOfErrors(child)?.hasSomeError;
                            triggerModal(
                                child,
                                child.id,
                                child.credential_types,
                                child.type.id,
                                missingFormat,
                                isErrorCredential,
                            );
                        }
                    }}
                />
            )}
        </>
    );
};

export default memo(ConnectedButtons);
