// next
import { useRouter } from 'next/router';
// components
import { FiltersResultsBody, FiltersResultsHeader } from '@commons/components/FiltersResults';
// context
import { useNewSearchProduct } from 'context';
// style
import style from './FiltersResultsList.module.css';

const FiltersResultsList = () => {
    // next
    const router = useRouter();
    // context
    const { paginationByResults, searchAggregations } = useNewSearchProduct();

    return (
        <div className={style['container-result-list-filters']}>
            <FiltersResultsHeader resultsCount={paginationByResults?.total} searchTerm={router.query.search} />
            <FiltersResultsBody recordset={searchAggregations} resultsCount={paginationByResults?.total} />
        </div>
    );
};

export default FiltersResultsList;
