import format from 'date-fns/format';

export const auxiliaryDatePicker = () => {
    
    const disabledDatePicker = (date,availableDates) => {
        const formattedDate = format(date, 'yyyy-MM-dd');
        return !availableDates.includes(formattedDate);
    };

    const setLocale = {
        ok:'Aplicar'
    }

    return {
        disabledDatePicker,
        setLocale
    };
};
