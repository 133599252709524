import { memo } from 'react';
// utils && hooks
import { useHeader } from './hooks';
// styles
import styles from './Header.module.css';

const Header = () => {
    const { headerSteps, animationClass, quantityRequestedProducts, displayedStep, failed } = useHeader();
    return (
        <div className={styles['carousel-container']}>
            <div className={`${styles['container']} ${animationClass}`}>
                <p className={styles['title']}>
                    {failed && headerSteps[displayedStep - 1]?.ErrorHeaderTitle
                        ? headerSteps[displayedStep - 1]?.ErrorHeaderTitle('')
                        : headerSteps[displayedStep - 1]?.headerTitle(
                              displayedStep === 1 ? quantityRequestedProducts : '',
                          )}
                    {failed && headerSteps[displayedStep - 1]?.ErrorHeaderSubTitle && (
                        <p className={styles['subtitle-error']}>
                            {headerSteps[displayedStep - 1]?.ErrorHeaderSubTitle('')}
                        </p>
                    )}
                </p>
                {!failed && headerSteps[displayedStep - 1]?.headerSubTitle && (
                    <p className={styles['subtitle']}>{headerSteps[displayedStep - 1]?.headerSubTitle('')}</p>
                )}
            </div>
        </div>
    );
};

export default memo(Header);
