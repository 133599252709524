import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ palette }) => ({
  root: {
    input: {
      color: palette.neutral["800"],
      "::placeholder": {
        color: palette.neutral["600"],
        opacity: 1,
      },
    },
    "& MuiFormLabel-root": {
      color: palette.neutral["600"],
      "&.Mui-focused": {
        color: palette.Mi["400"],
      },
      "&.Mui-error": {
        color: palette.Ma["400"],
      },
    },
    "& .MuiFormHelperText-root": {
      fontSize: "10px",
      color: palette.neutral["600"],
      "&.Mui-error": {
        color: palette.Ma["400"],
      },
    },
    "& .MuiOutlinedInput-root": {
      "&.MuiOutlinedInput-notchedOutline": {
        borderColor: palette.neutral["600"],
      },
      "&.Mui-focused": {
        "&.MuiOutlinedInput-notchedOutline": {
          borderColor: palette.Mi["400"],
        },
      },
      "&.Mui-error": {
        "&.MuiOutlinedInput-notchedOutline": {
          borderColor: palette.Ma["400"],
        },
      },
      "&:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error)": {
        "& .MuiOutlinedInput-notchedOutline": {
          border: `2px ${palette.neutral["400"]} solid`,
        },
      },
    },
  },
  dirty: {
    "& .MuiInputBase-root::before": {
      content: "",
      position: "absolute",
      backgroundColor: palette.G[600],
      width: "6px",
      height: "6px",
      borderRadius: "6px",
      left: -"10px",
    },
  },
}));

export default useStyles;
