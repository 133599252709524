export const Mock = {
    suggested: [
        {
            id: 222,
            name: 'D."Furey"',
            url: 'https://www.furey.com.ar',
            country_alfa_2: 'AR',
            credential_types: [
                {
                    id: 2,
                    name: 'scraper',
                    username: null,
                    password: null,
                    client_identifier: null,
                    function: null,
                    api_credential_id: null,
                    format_ticket: null,
                    dispatch_addresses: [],
                    has_dispatch_addresses: false,
                    payment_methods: [],
                    errors: [],
                    catalog_id: null,
                    price_request_enabled: null,
                    payment_method_id: null,
                },
            ],
            format_status: {
                id: 1,
                name: 'VALIDATED',
            },
            deposits: [],
            client_types: [],
            is_offline: false,
            type: {
                id: 2,
                type: 'online',
            },
            speciality: null,
        },
        {
            id: 220,
            name: 'D."Globalmed"',
            url: 'https://www.globalmed.com.ar/',
            country_alfa_2: 'AR',
            credential_types: [
                {
                    id: 2,
                    name: 'scraper',
                    username: null,
                    password: null,
                    client_identifier: null,
                    function: null,
                    api_credential_id: null,
                    format_ticket: null,
                    dispatch_addresses: [],
                    has_dispatch_addresses: false,
                    payment_methods: [],
                    errors: [],
                    catalog_id: null,
                    price_request_enabled: null,
                    payment_method_id: null,
                },
            ],
            format_status: {
                id: 1,
                name: 'VALIDATED',
            },
            deposits: [],
            client_types: [],
            is_offline: false,
            type: {
                id: 2,
                type: 'online',
            },
            speciality: 'Genéricos & hospitalario',
        },
        {
            id: 6,
            name: 'D."Kellerhoff / Casa Central"',
            url: 'https://www.kellerhoff.com.ar',
            country_alfa_2: 'AR',
            credential_types: [
                {
                    id: 2,
                    name: 'scraper',
                    username: null,
                    password: null,
                    client_identifier: null,
                    function: null,
                    api_credential_id: null,
                    format_ticket: null,
                    dispatch_addresses: [],
                    has_dispatch_addresses: false,
                    payment_methods: [],
                    errors: [],
                    catalog_id: null,
                    price_request_enabled: null,
                    payment_method_id: null,
                },
            ],
            format_status: {
                id: 1,
                name: 'VALIDATED',
            },
            deposits: [],
            client_types: [],
            is_offline: false,
            type: {
                id: 2,
                type: 'online',
            },
            speciality: 'Catálogo completo',
        },
        {
            id: 17,
            name: 'D."Kellerhoff / San Nicolás"',
            url: 'https://www.kellerhoff.com.ar',
            country_alfa_2: 'AR',
            credential_types: [
                {
                    id: 2,
                    name: 'scraper',
                    username: null,
                    password: null,
                    client_identifier: null,
                    function: null,
                    api_credential_id: null,
                    format_ticket: null,
                    dispatch_addresses: [],
                    has_dispatch_addresses: false,
                    payment_methods: [],
                    errors: [],
                    catalog_id: null,
                    price_request_enabled: null,
                    payment_method_id: null,
                },
            ],
            format_status: {
                id: 1,
                name: 'VALIDATED',
            },
            deposits: [],
            client_types: [],
            is_offline: false,
            type: {
                id: 2,
                type: 'online',
            },
            speciality: 'Catálogo completo',
        },
        {
            id: 5,
            name: 'D."Monroe Americana"',
            url: 'https://www.monroeamericana.com.ar',
            country_alfa_2: 'AR',
            credential_types: [
                {
                    id: 2,
                    name: 'scraper',
                    username: null,
                    password: null,
                    client_identifier: null,
                    function: null,
                    api_credential_id: null,
                    format_ticket: null,
                    dispatch_addresses: [],
                    has_dispatch_addresses: false,
                    payment_methods: [],
                    errors: [],
                    catalog_id: null,
                    price_request_enabled: null,
                    payment_method_id: null,
                },
            ],
            format_status: {
                id: 1,
                name: 'VALIDATED',
            },
            deposits: [],
            client_types: [],
            is_offline: false,
            type: {
                id: 2,
                type: 'online',
            },
            speciality: 'Catálogo completo',
        },
        {
            id: 29,
            name: 'D."Suiza de Tucumán"',
            url: 'https://www.dsuiza.com.ar/',
            country_alfa_2: 'AR',
            credential_types: [
                {
                    id: 2,
                    name: 'scraper',
                    username: null,
                    password: null,
                    client_identifier: null,
                    function: null,
                    api_credential_id: null,
                    format_ticket: null,
                    dispatch_addresses: [],
                    has_dispatch_addresses: false,
                    payment_methods: [],
                    errors: [],
                    catalog_id: null,
                    price_request_enabled: null,
                    payment_method_id: null,
                },
            ],
            format_status: {
                id: 1,
                name: 'VALIDATED',
            },
            deposits: [],
            client_types: [],
            is_offline: false,
            type: {
                id: 2,
                type: 'online',
            },
            speciality: 'Catálogo completo',
        },
        {
            id: 2,
            name: 'D."Suizo Argentina"',
            url: 'https://suizoargentina.com',
            country_alfa_2: 'AR',
            credential_types: [
                {
                    id: 2,
                    name: 'scraper',
                    username: null,
                    password: null,
                    client_identifier: null,
                    function: null,
                    api_credential_id: null,
                    format_ticket: null,
                    dispatch_addresses: [],
                    has_dispatch_addresses: false,
                    payment_methods: [],
                    errors: [],
                    catalog_id: null,
                    price_request_enabled: null,
                    payment_method_id: null,
                },
            ],
            format_status: {
                id: 1,
                name: 'VALIDATED',
            },
            deposits: [],
            client_types: [],
            is_offline: false,
            type: {
                id: 2,
                type: 'online',
            },
            speciality: 'Catálogo completo',
        },
        {
            id: 217,
            name: 'D."Sumed"',
            url: 'https://www.drogueriasumed.com/',
            country_alfa_2: 'AR',
            credential_types: [
                {
                    id: 2,
                    name: 'scraper',
                    username: null,
                    password: null,
                    client_identifier: null,
                    function: null,
                    api_credential_id: null,
                    format_ticket: null,
                    dispatch_addresses: [],
                    has_dispatch_addresses: false,
                    payment_methods: [],
                    errors: [],
                    catalog_id: null,
                    price_request_enabled: null,
                    payment_method_id: null,
                },
            ],
            format_status: {
                id: 1,
                name: 'VALIDATED',
            },
            deposits: [],
            client_types: [],
            is_offline: false,
            type: {
                id: 2,
                type: 'online',
            },
            speciality: 'De oferta',
        },
        {
            id: 10,
            name: 'D."Sur"',
            url: 'https://www.drogueriasur.com.ar',
            country_alfa_2: 'AR',
            credential_types: [
                {
                    id: 2,
                    name: 'scraper',
                    username: null,
                    password: null,
                    client_identifier: null,
                    function: null,
                    api_credential_id: null,
                    format_ticket: null,
                    dispatch_addresses: [],
                    has_dispatch_addresses: true,
                    payment_methods: [],
                    errors: [],
                    catalog_id: null,
                    price_request_enabled: null,
                    payment_method_id: null,
                },
            ],
            format_status: {
                id: 2,
                name: 'UNKNOWN',
            },
            deposits: [],
            client_types: [],
            is_offline: false,
            type: {
                id: 2,
                type: 'online',
            },
            speciality: 'Catálogo completo',
        },
    ],
    connected: [
        {
            id: 8,
            name: 'D."20 De Junio"',
            url: 'https://www.drogueria20dejunio.com.ar',
            country_alfa_2: 'AR',
            credential_types: [
                {
                    id: 2,
                    name: 'scraper',
                    username: '4339',
                    password: '4339',
                    client_identifier: null,
                    function: null,
                    api_credential_id: 6765,
                    format_ticket: null,
                    dispatch_addresses: [],
                    has_dispatch_addresses: false,
                    payment_methods: [],
                    errors: [
                        {
                            id: 2,
                            name: 'wrong_username_password',
                        },
                    ],
                    catalog_id: null,
                    price_request_enabled: true,
                    payment_method_id: null,
                },
            ],
            format_status: {
                id: 1,
                name: 'VALIDATED',
            },
            deposits: [],
            client_types: [],
            is_offline: false,
            type: {
                id: 2,
                type: 'online',
            },
            speciality: 'Primera línea y genéricos',
        },
        {
            id: 7,
            name: 'D."Acofar"',
            url: 'https://www.acofar.com',
            country_alfa_2: 'AR',
            credential_types: [
                {
                    id: 2,
                    name: 'scraper',
                    username: null,
                    password: null,
                    client_identifier: null,
                    function: null,
                    api_credential_id: null,
                    format_ticket: null,
                    dispatch_addresses: [],
                    has_dispatch_addresses: false,
                    payment_methods: [],
                    errors: [],
                    catalog_id: null,
                    price_request_enabled: null,
                    payment_method_id: null,
                },
            ],
            format_status: {
                id: 1,
                name: 'VALIDATED',
            },
            deposits: [],
            client_types: [],
            is_offline: false,
            type: {
                id: 2,
                type: 'online',
            },
            speciality: 'Catálogo completo',
        },
        {
            id: 4,
            name: 'D."Asoprofarma"',
            url: 'https://www.asoprofarma.com',
            country_alfa_2: 'AR',
            credential_types: [
                {
                    id: 2,
                    name: 'scraper',
                    username: null,
                    password: null,
                    client_identifier: null,
                    function: null,
                    api_credential_id: null,
                    format_ticket: null,
                    dispatch_addresses: [],
                    has_dispatch_addresses: false,
                    payment_methods: [],
                    errors: [],
                    catalog_id: null,
                    price_request_enabled: null,
                    payment_method_id: null,
                },
            ],
            format_status: {
                id: 1,
                name: 'VALIDATED',
            },
            deposits: [],
            client_types: [],
            is_offline: false,
            type: {
                id: 2,
                type: 'online',
            },
            speciality: 'Catálogo completo',
        },
        {
            id: 18,
            name: 'D."Cofaloza"',
            url: 'http://brz1.cofaloza.com.ar:4040',
            country_alfa_2: 'AR',
            credential_types: [
                {
                    id: 2,
                    name: 'scraper',
                    username: null,
                    password: null,
                    client_identifier: null,
                    function: null,
                    api_credential_id: null,
                    format_ticket: null,
                    dispatch_addresses: [],
                    has_dispatch_addresses: false,
                    payment_methods: [],
                    errors: [],
                    catalog_id: null,
                    price_request_enabled: null,
                    payment_method_id: null,
                },
                {
                    id: 3,
                    name: 'scraper_np',
                    username: null,
                    password: null,
                    client_identifier: null,
                    function: null,
                    api_credential_id: null,
                    format_ticket: null,
                    dispatch_addresses: [],
                    has_dispatch_addresses: true,
                    payment_methods: [],
                    errors: [],
                    catalog_id: null,
                    price_request_enabled: null,
                    payment_method_id: null,
                },
            ],
            format_status: {
                id: 1,
                name: 'VALIDATED',
            },
            deposits: [],
            client_types: [],
            is_offline: false,
            type: {
                id: 2,
                type: 'online',
            },
            speciality: 'Catálogo completo',
        },
        {
            id: 21,
            name: 'D."Cofaral / Tucuman"',
            url: 'https://cofaral.com.ar/',
            country_alfa_2: 'AR',
            credential_types: [
                {
                    id: 2,
                    name: 'scraper',
                    username: null,
                    password: null,
                    client_identifier: null,
                    function: null,
                    api_credential_id: null,
                    format_ticket: null,
                    dispatch_addresses: [],
                    has_dispatch_addresses: false,
                    payment_methods: [],
                    errors: [],
                    catalog_id: null,
                    price_request_enabled: null,
                    payment_method_id: null,
                },
            ],
            format_status: {
                id: 1,
                name: 'VALIDATED',
            },
            deposits: [],
            client_types: [],
            is_offline: false,
            type: {
                id: 2,
                type: 'online',
            },
            speciality: 'Catálogo completo',
        },
        {
            id: 9,
            name: 'D."Cofarsur"',
            url: 'https://www.cofarsur.net',
            country_alfa_2: 'AR',
            credential_types: [
                {
                    id: 2,
                    name: 'scraper',
                    username: null,
                    password: null,
                    client_identifier: null,
                    function: null,
                    api_credential_id: null,
                    format_ticket: null,
                    dispatch_addresses: [],
                    has_dispatch_addresses: false,
                    payment_methods: [],
                    errors: [],
                    catalog_id: null,
                    price_request_enabled: null,
                    payment_method_id: null,
                },
            ],
            format_status: {
                id: 1,
                name: 'VALIDATED',
            },
            deposits: [],
            client_types: [],
            is_offline: false,
            type: {
                id: 2,
                type: 'online',
            },
            speciality: 'Primera línea y genéricos',
        },
        {
            id: 1,
            name: 'D."Del Sud"',
            url: 'https://www.delsud.com.ar',
            country_alfa_2: 'AR',
            credential_types: [
                {
                    id: 2,
                    name: 'scraper',
                    username: null,
                    password: null,
                    client_identifier: null,
                    function: null,
                    api_credential_id: null,
                    format_ticket: null,
                    dispatch_addresses: [],
                    has_dispatch_addresses: false,
                    payment_methods: [],
                    errors: [],
                    catalog_id: null,
                    price_request_enabled: null,
                    payment_method_id: null,
                },
                {
                    id: 3,
                    name: 'scraper_np',
                    username: null,
                    password: null,
                    client_identifier: null,
                    function: null,
                    api_credential_id: null,
                    format_ticket: null,
                    dispatch_addresses: [],
                    has_dispatch_addresses: false,
                    payment_methods: [],
                    errors: [],
                    catalog_id: null,
                    price_request_enabled: null,
                    payment_method_id: null,
                },
            ],
            format_status: {
                id: 1,
                name: 'VALIDATED',
            },
            deposits: [],
            client_types: [],
            is_offline: false,
            type: {
                id: 2,
                type: 'online',
            },
            speciality: 'Catálogo completo',
        },
        {
            id: 3,
            name: 'D."Disval"',
            url: 'http://www.disval.com.ar',
            country_alfa_2: 'AR',
            credential_types: [
                {
                    id: 2,
                    name: 'scraper',
                    username: 'alejandra.mejias+owner2340@extendeal.com',
                    password: 'Pharma1234',
                    client_identifier: null,
                    function: null,
                    api_credential_id: 7182,
                    format_ticket: null,
                    dispatch_addresses: [],
                    has_dispatch_addresses: false,
                    payment_methods: [],
                    errors: [],
                    catalog_id: null,
                    price_request_enabled: true,
                    payment_method_id: null,
                },
            ],
            format_status: {
                id: 1,
                name: 'VALIDATED',
            },
            deposits: [],
            client_types: [],
            is_offline: false,
            type: {
                id: 2,
                type: 'online',
            },
            speciality: 'Catálogo completo',
        },
        {
            id: 218,
            name: 'D."Farmasun"',
            url: 'http://app.drogueriafarmasun.com.ar/login',
            country_alfa_2: 'AR',
            credential_types: [
                {
                    id: 2,
                    name: 'scraper',
                    username: null,
                    password: null,
                    client_identifier: null,
                    function: null,
                    api_credential_id: null,
                    format_ticket: null,
                    dispatch_addresses: [],
                    has_dispatch_addresses: false,
                    payment_methods: [],
                    errors: [],
                    catalog_id: null,
                    price_request_enabled: null,
                    payment_method_id: null,
                },
            ],
            format_status: {
                id: 1,
                name: 'VALIDATED',
            },
            deposits: [],
            client_types: [],
            is_offline: false,
            type: {
                id: 2,
                type: 'online',
            },
            speciality: 'Genéricos & hospitalario',
        },
    ],
};
