// components
import { IconButton } from 'rsuite';
// context
import { useListBoxDrugManufacturersContext } from 'context';
// styles
import styles from './Buttons.module.css';

const Buttons = (): JSX.Element => {
    const { handleNext, handlePrevious } = useListBoxDrugManufacturersContext();
    const BUTTONS = ['prev', 'next'];
    return (
        <div className={styles['box-buttons']}>
            {BUTTONS.map((button: string, idx: number) => {
                return (
                    <IconButton
                        className={styles['button']}
                        circle
                        icon={
                            <img
                                className={styles[`icon-${button}`]}
                                src="/assets/images/expand_less.svg"
                                draggable={false}
                            />
                        }
                        appearance="default"
                        onClick={() => (idx === 0 ? handlePrevious() : handleNext())}
                    />
                );
            })}
        </div>
    );
};

export default Buttons;
