import {
    createContext,
    Dispatch,
    ReactNode,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
// utils && hooks
import { DrugManufacturer, ProductInterface } from 'common/types';
import { useStatesFilters } from 'utils/hooks/use-states-filters';
import { useUser } from 'utils/hooks';
import { EDrugManufacturerTypeId } from 'utils/enums/drugmanufacturerTypeId/DrugManufacturerTypeId';
import { useDrugManufacturersContext } from './productComparison/drugManufacturers-context';
import { useProductsContext } from './productComparison/products-context';
import { getProductSelectedByDrugManufacturerId } from 'utils/productComparison/getProductSelectedByDrugManufacturerId';

interface ProductComparisonParams {
    children: ReactNode;
    orderId: any;
}

export type ItemCheckbox = {
    label: string;
    id: number;
    name: 'Sin stock' | 'Con descuento' | 'Con genéricos';
    isChecked: boolean;
};

export interface EstimatedSavingsProps {
    youCanSave: number;
    youAreSaving: number;
}

export type InsufficientCreditWarning = {
    id: number;
    isWarningOpen: boolean;
};

export interface ProductComparisonProps extends ProductComparisonParams {
    retryLoading: boolean;
    setRetryLoading(value: boolean): void;
    getSubtotalById(id: DrugManufacturer['id']): number;
    setUndoTimeout(option: any);
    selectAllProductByDMId(drug_manufacturer_id: number): void;
    disabledButtonMakeOrderByDelete: boolean;
    recommendations: any;
    setRecommendations: (recommendations: any) => void;
    recommendationsVisible: any;
    setRecommendationsVisible: (recommendations: any) => void;
    setIsFetchRehydrate: (val: boolean) => void;
    isFetchRehydrate: boolean;
    withoutSearchings?: any;
    setSearchTerms?: any;
    searchTerms?: any;
    setIsCheck?: any;
    isCheck?: any;
    handleClearFilters?: any;
    modalPriceWithOutSelection?: any;
    setOpenModalConfigDistribution?: (value: boolean) => void;
    openModalConfigDistribution?: boolean;
    setOpenModalConfigPriorities?: (value: boolean) => void;
    openModalConfigPriorities?: boolean;
    setShowModalPriceWithOutSelection?: (value: boolean) => void;
    showModalPriceWithOutSelection?: boolean;
    getCreditById?: any;
    manuallySelectedProductIds?: number[];
    setManuallySelectedProductIds?: Dispatch<SetStateAction<number[]>>;
    recommendationLimit: boolean;
    setRecommendationLimit: (value: boolean) => void;
    filters: ItemCheckbox[];
    setFilters: Dispatch<SetStateAction<ItemCheckbox[]>>;
    addNewRecommendation: (item: ProductInterface) => void;
    setOpenModal: (v: boolean) => void;
    openModal: boolean;
    isFilteringByOutofStock: boolean;
    setDisabledButtonMakeOrderByDelete: (v: boolean) => void;
    originalProductGroups: any;
    setOriginalProductGroups: (v: any) => void;
    setWithoutSearchings: (v: boolean) => void;
    getSubtotalByIdByKellerhoff ?: any
}

const ProductComparisonContext = createContext<Omit<ProductComparisonProps, 'children'>>(null);

export function ProductComparisonProvider({ children, ...props }: ProductComparisonParams) {
    const { isFilteringByOutofStock, setIsFilteringByOutofStock } = useStatesFilters();
    const { drugManufacturer } = useDrugManufacturersContext();
    const [retryLoading, setRetryLoading] = useState<boolean>(false);
    const [undoTimeout, setUndoTimeout] = useState(false);
    const [disabledButtonMakeOrderByDelete, setDisabledButtonMakeOrderByDelete] = useState<boolean>(false);
    const [manuallySelectedProductIds, setManuallySelectedProductIds] = useState<number[]>([]);
    const [searchTerms, setSearchTerms] = useState('');
    const [isCheck, setIsCheck] = useState({});
    const [filters, setFilters] = useState<ItemCheckbox[]>([]);
    const [originalProductGroups, setOriginalProductGroups] = useState<ProductInterface[]>([]);
    const [withoutSearchings, setWithoutSearchings] = useState(false);
    const [recommendationLimit, setRecommendationLimit] = useState<boolean>(false);
    const [openModal, setOpenModal] = useState(false);

    const { user } = useUser({});
    const EX5937 = user?.EX5937; // ff split purchase

    const [openModalConfigDistribution, setOpenModalConfigDistribution] = useState(false);
    const [openModalConfigPriorities, setOpenModalConfigPriorities] = useState(false);
    const [showModalPriceWithOutSelection, setShowModalPriceWithOutSelection] = useState(false);

    const [recommendations, setRecommendations] = useState<any[]>([]);
    const [recommendationsVisible, setRecommendationsVisible] = useState<any>([]);
    const [isFetchRehydrate, setIsFetchRehydrate] = useState(false);
    const modalPriceWithOutSelection = useRef();

    const { productGroups, products, setProductInfo, setProductGroups, setProducts } = useProductsContext();

    const addNewRecommendation = (item: ProductInterface) => {
        // const newArray = [item, ...productGroups];
        // setProductGroups(newArray);
        // setProductInfo(item);
    };

    const getSubtotalById = useCallback(
        (id: DrugManufacturer['id']): number => {
            if (EX5937) {
                // este cambio es implementado ya que el amountSelected es el monto selecciónado para un producto,
                // pero esta función necesita retornar el total para esa droguería, amountSelected antes era valido,
                // porque no existia la posibilidad de tener +1 celda seleccionada por producto.
                const subtotalId = getProductSelectedByDrugManufacturerId(id, products, EX5937);
                const cellById = subtotalId?.map((item) => item?.products[id]?.total);
                const subtotal = cellById?.reduce((count, value) => (count += value), 0);
                return subtotal;
            } else {
                const subtotalId = getProductSelectedByDrugManufacturerId(id, products, EX5937)?.reduce(
                    (count, item) => (count += item?.amountSelected),
                    0,
                );

                return subtotalId;
            }
        },
        [products],
    );

    const getSubtotalByIdByKellerhoff = useCallback(
        (id: DrugManufacturer['id']): number => {
            const kellerhoffIds = [6, 13, 14, 15, 16, 17, 22, 23]; // IDs de Kellerhoff
            const isKellerhoff = kellerhoffIds.includes(id);
    
            if (isKellerhoff) {
                // Si es Kellerhoff, sumamos todos los subtotales de los Kellerhoff
                return kellerhoffIds.reduce((total, kellerhoffId) => {
                    const subtotal = getProductSelectedByDrugManufacturerId(kellerhoffId, products,EX5937)?.reduce(
                        (count, item) => count + (item?.amountSelected || 0),
                        0
                    );
                    return total + (subtotal || 0);
                }, 0);
            } else {
                return getSubtotalById(id)  
            }
        },
        [products]
    );

    const getCreditById = useCallback(
        (id: DrugManufacturer['id'], selectedItem) => {
            const limitCreditAvailable = drugManufacturer.find((drug) => {
                return drug.id === id;
            })?.credit_available;
            const totalItemSelected = getProductSelectedByDrugManufacturerId(id, products, EX5937).find(
                (item) => selectedItem?.barcode === item?.barcode,
            );
            let subtotal = getProductSelectedByDrugManufacturerId(id, products, EX5937)?.reduce((count, item) => {
                if (selectedItem?.barcode === item?.barcode) {
                    count += selectedItem?.amountSelected;
                } else {
                    count += item?.amountSelected;
                }
                return count;
            }, 0);

            if (totalItemSelected === undefined) {
                subtotal += selectedItem?.amountSelected;
            }

            return limitCreditAvailable >= subtotal || !limitCreditAvailable;
        },
        [products, drugManufacturer],
    );

    const selectAllProductByDMId = useCallback(
        (drug_manufacturer_id: number) => {
            const productsSelected = products
                ?.map((product, index) => {
                    if (product?.quantity !== 0) {
                        const drugLowest = drugManufacturer?.find((d) => +d?.id === product?.lowest[0]);
                        const isVendorActive = !!drugLowest?.vendor_accepted;
                        const drugVendor = drugLowest?.type_id === EDrugManufacturerTypeId.vendor;
                        if (drugVendor && product?.products[drug_manufacturer_id] === null) {
                            // Si la droguería a checkear es vendor + esta activa + tiene stock
                            if (isVendorActive) {
                                const currentProductSelected =
                                    product.products[drug_manufacturer_id] !== null
                                        ? drug_manufacturer_id
                                        : product.lowest[0];
                                return {
                                    ...product,
                                    drugManufacturerIdSelected: [currentProductSelected],
                                };
                            } else {
                                // Si la droguería a checkear es vendor y no esta activa, mantenemos selección
                                return { ...product };
                            }
                        } else {
                            const currentProductSelected =
                                product.products[drug_manufacturer_id] !== null
                                    ? drug_manufacturer_id
                                    : product.lowest[0];
                            return {
                                ...product,
                                drugManufacturerIdSelected: [currentProductSelected],
                            };
                        }
                    }
                })
                .filter((x) => x !== undefined);
            setProducts([...productsSelected]);
        },
        [products, productGroups],
    );

    const handleClearFilters = () => {
        const updatedFilters = filters.map((filter) => {
            return {
                ...filter,
                isChecked: false,
            };
        });
        setFilters(updatedFilters);
        if (searchTerms.length > 0) {
            setSearchTerms('');
        }
    };

    useEffect(() => {
        if (originalProductGroups.length === 0 && productGroups.length > 0) {
            setOriginalProductGroups(productGroups);
        }
    }, [productGroups]);

    useEffect(() => {
        if (filters.length === 0) {
            return;
        }

        const isDiscountChecked = filters.find((filter) => filter.name === 'Con descuento')?.isChecked;
        const isOutOfStockChecked = filters.find((filter) => filter.name === 'Sin stock')?.isChecked;
        const isGenericChecked = filters.find((filter) => filter.name === 'Con genéricos')?.isChecked;

        if (isDiscountChecked || isOutOfStockChecked || isGenericChecked) {
            const filteredProducts = products.filter((product) => {
                let matches = true;

                if (isDiscountChecked) {
                    matches =
                        matches &&
                        Object.values(product.products).some((product) => {
                            return (
                                (product && product.agreement) ||
                                (product?.discounts && product?.discounts?.length > 0) ||
                                (product &&
                                    product.agreement &&
                                    product?.discounts &&
                                    product?.discounts?.length > 0 &&
                                    product?.discount &&
                                    //@ts-ignore
                                    product?.discount?.length > 0)
                            );
                        });
                }
                if (isOutOfStockChecked) {
                    matches = matches && !product.is_product_in_stock;
                }
                if (isGenericChecked) {
                    matches = matches && product?.generics?.length > 0;
                }
                return matches;
            });

            setIsFilteringByOutofStock(isOutOfStockChecked);
            setProductGroups(filteredProducts);
            const checkedCount = filters.filter((filter) => filter.isChecked)?.length;
            setWithoutSearchings(checkedCount > 0 && filteredProducts.length === 0);
        } else {
            // if you applied any filters and then you remove them, the original product groups are restored
            setIsFilteringByOutofStock(false);
            setWithoutSearchings(false);
            // if add a new recommended product update the product groups and the original product groups state
            if (productGroups?.length > originalProductGroups?.length) {
                setOriginalProductGroups(productGroups);
            } else {
                setProductGroups(originalProductGroups);
            }
        }
    }, [filters]);
    // ---------------

    useEffect(() => {
        if (undoTimeout) {
            setUndoTimeout(false);
            setDisabledButtonMakeOrderByDelete(false);
            // clearTimeout(deleteItemTimeout);
        }
    }, [undoTimeout]);

    return (
        <ProductComparisonContext.Provider
            value={{
                getCreditById,
                getSubtotalById,
                retryLoading,
                setRetryLoading,
                setDisabledButtonMakeOrderByDelete,
                setUndoTimeout,
                selectAllProductByDMId,
                disabledButtonMakeOrderByDelete,
                addNewRecommendation,
                recommendations,
                setRecommendations,
                recommendationsVisible,
                setRecommendationsVisible,
                setIsFetchRehydrate,
                isFetchRehydrate,
                setWithoutSearchings,
                withoutSearchings,
                setSearchTerms,
                searchTerms,
                setIsCheck,
                isCheck,
                handleClearFilters,
                modalPriceWithOutSelection,
                openModalConfigDistribution,
                setOpenModalConfigDistribution,
                openModalConfigPriorities,
                setOpenModalConfigPriorities,
                showModalPriceWithOutSelection,
                setShowModalPriceWithOutSelection,
                manuallySelectedProductIds,
                setManuallySelectedProductIds,
                recommendationLimit,
                setRecommendationLimit,
                filters,
                setFilters,
                setOpenModal,
                openModal,
                isFilteringByOutofStock,
                originalProductGroups,
                setOriginalProductGroups,
                getSubtotalByIdByKellerhoff,
                ...props,
            }}
        >
            {children}
        </ProductComparisonContext.Provider>
    );
}

export function useProductComparison() {
    const context = useContext(ProductComparisonContext);
    if (!context) {
        throw new Error('useProductComparison must be used within a ProducComparisonProvider');
    }

    return context;
}
