import React from 'react';
import Image from 'next/image';
// INTERFACES
import { ArrowProps } from './Arrow.interface';
// STYLES
import styles from './Arrow.module.css';

const Arrow = ({ show, skeleton, parentRef, position, positionValue, handlerClick }: ArrowProps): JSX.Element => {
    return (
        <button
            style={{
                display: show ? 'flex' : 'none',
                position: 'fixed',
                visibility: !skeleton ? 'visible' : 'hidden',
                top: parentRef?.current?.clientHeight / 2.23,
                [position]: positionValue,
                zIndex: 1,
            }}
            className={`${position === 'left' ? styles['arrow-left'] : styles['arrow-right']}`}
            onClick={handlerClick}
        >
            <Image src={'/assets/images/arrow_forward.svg'} alt="arrow-left" width={16} height={16} />
        </button>
    );
};

export default Arrow;
