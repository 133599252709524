export const handleIcons = (error) => {
  if (error) {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='17'
        height='16'
        viewBox='0 0 17 16'
        fill='none'
      >
        <path
          d='M8.49967 11.3333C8.68856 11.3333 8.8469 11.2694 8.97467 11.1416C9.10245 11.0139 9.16634 10.8555 9.16634 10.6666C9.16634 10.4778 9.10245 10.3194 8.97467 10.1916C8.8469 10.0639 8.68856 9.99998 8.49967 9.99998C8.31079 9.99998 8.15245 10.0639 8.02467 10.1916C7.8969 10.3194 7.83301 10.4778 7.83301 10.6666C7.83301 10.8555 7.8969 11.0139 8.02467 11.1416C8.15245 11.2694 8.31079 11.3333 8.49967 11.3333ZM8.49967 8.66665C8.68856 8.66665 8.8469 8.60276 8.97467 8.47498C9.10245 8.3472 9.16634 8.18887 9.16634 7.99998V5.33331C9.16634 5.14442 9.10245 4.98609 8.97467 4.85831C8.8469 4.73054 8.68856 4.66665 8.49967 4.66665C8.31079 4.66665 8.15245 4.73054 8.02467 4.85831C7.8969 4.98609 7.83301 5.14442 7.83301 5.33331V7.99998C7.83301 8.18887 7.8969 8.3472 8.02467 8.47498C8.15245 8.60276 8.31079 8.66665 8.49967 8.66665ZM8.49967 14.6666C7.57745 14.6666 6.71079 14.4916 5.89967 14.1416C5.08856 13.7916 4.38301 13.3166 3.78301 12.7166C3.18301 12.1166 2.70801 11.4111 2.35801 10.6C2.00801 9.78887 1.83301 8.9222 1.83301 7.99998C1.83301 7.07776 2.00801 6.21109 2.35801 5.39998C2.70801 4.58887 3.18301 3.88331 3.78301 3.28331C4.38301 2.68331 5.08856 2.20831 5.89967 1.85831C6.71079 1.50831 7.57745 1.33331 8.49967 1.33331C9.4219 1.33331 10.2886 1.50831 11.0997 1.85831C11.9108 2.20831 12.6163 2.68331 13.2163 3.28331C13.8163 3.88331 14.2913 4.58887 14.6413 5.39998C14.9913 6.21109 15.1663 7.07776 15.1663 7.99998C15.1663 8.9222 14.9913 9.78887 14.6413 10.6C14.2913 11.4111 13.8163 12.1166 13.2163 12.7166C12.6163 13.3166 11.9108 13.7916 11.0997 14.1416C10.2886 14.4916 9.4219 14.6666 8.49967 14.6666Z'
          fill='#E22E2E'
        />
      </svg>
    );
  } else {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='17'
        height='16'
        viewBox='0 0 17 16'
        fill='none'
      >
        <path
          d='M7.56634 9.19998L6.13301 7.76665C6.01079 7.64442 5.85523 7.58331 5.66634 7.58331C5.47745 7.58331 5.3219 7.64442 5.19967 7.76665C5.07745 7.88887 5.01634 8.04442 5.01634 8.23331C5.01634 8.4222 5.07745 8.57776 5.19967 8.69998L7.09967 10.6C7.23301 10.7333 7.38856 10.8 7.56634 10.8C7.74412 10.8 7.89967 10.7333 8.03301 10.6L11.7997 6.83331C11.9219 6.71109 11.983 6.55554 11.983 6.36665C11.983 6.17776 11.9219 6.0222 11.7997 5.89998C11.6775 5.77776 11.5219 5.71665 11.333 5.71665C11.1441 5.71665 10.9886 5.77776 10.8663 5.89998L7.56634 9.19998ZM8.49967 14.6666C7.57745 14.6666 6.71079 14.4916 5.89967 14.1416C5.08856 13.7916 4.38301 13.3166 3.78301 12.7166C3.18301 12.1166 2.70801 11.4111 2.35801 10.6C2.00801 9.78887 1.83301 8.9222 1.83301 7.99998C1.83301 7.07776 2.00801 6.21109 2.35801 5.39998C2.70801 4.58887 3.18301 3.88331 3.78301 3.28331C4.38301 2.68331 5.08856 2.20831 5.89967 1.85831C6.71079 1.50831 7.57745 1.33331 8.49967 1.33331C9.4219 1.33331 10.2886 1.50831 11.0997 1.85831C11.9108 2.20831 12.6163 2.68331 13.2163 3.28331C13.8163 3.88331 14.2913 4.58887 14.6413 5.39998C14.9913 6.21109 15.1663 7.07776 15.1663 7.99998C15.1663 8.9222 14.9913 9.78887 14.6413 10.6C14.2913 11.4111 13.8163 12.1166 13.2163 12.7166C12.6163 13.3166 11.9108 13.7916 11.0997 14.1416C10.2886 14.4916 9.4219 14.6666 8.49967 14.6666ZM8.49967 13.3333C9.98856 13.3333 11.2497 12.8166 12.283 11.7833C13.3163 10.75 13.833 9.48887 13.833 7.99998C13.833 6.51109 13.3163 5.24998 12.283 4.21665C11.2497 3.18331 9.98856 2.66665 8.49967 2.66665C7.01079 2.66665 5.74967 3.18331 4.71634 4.21665C3.68301 5.24998 3.16634 6.51109 3.16634 7.99998C3.16634 9.48887 3.68301 10.75 4.71634 11.7833C5.74967 12.8166 7.01079 13.3333 8.49967 13.3333Z'
          fill='#07AD4B'
        />
      </svg>
    );
  }
};

export const handleIconsCloseCard = (error) => {
  if (error) {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
      >
        <path
          d='M12 17C12.2833 17 12.5208 16.9042 12.7125 16.7125C12.9042 16.5208 13 16.2833 13 16C13 15.7167 12.9042 15.4792 12.7125 15.2875C12.5208 15.0958 12.2833 15 12 15C11.7167 15 11.4792 15.0958 11.2875 15.2875C11.0958 15.4792 11 15.7167 11 16C11 16.2833 11.0958 16.5208 11.2875 16.7125C11.4792 16.9042 11.7167 17 12 17ZM12 13C12.2833 13 12.5208 12.9042 12.7125 12.7125C12.9042 12.5208 13 12.2833 13 12V8C13 7.71667 12.9042 7.47917 12.7125 7.2875C12.5208 7.09583 12.2833 7 12 7C11.7167 7 11.4792 7.09583 11.2875 7.2875C11.0958 7.47917 11 7.71667 11 8V12C11 12.2833 11.0958 12.5208 11.2875 12.7125C11.4792 12.9042 11.7167 13 12 13ZM12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22Z'
          fill='#E22E2E'
        />
      </svg>
    );
  } else {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
      >
        <path
          d='M10.6 13.8L8.45 11.65C8.26667 11.4667 8.03333 11.375 7.75 11.375C7.46667 11.375 7.23333 11.4667 7.05 11.65C6.86667 11.8333 6.775 12.0667 6.775 12.35C6.775 12.6333 6.86667 12.8667 7.05 13.05L9.9 15.9C10.1 16.1 10.3333 16.2 10.6 16.2C10.8667 16.2 11.1 16.1 11.3 15.9L16.95 10.25C17.1333 10.0667 17.225 9.83333 17.225 9.55C17.225 9.26667 17.1333 9.03333 16.95 8.85C16.7667 8.66667 16.5333 8.575 16.25 8.575C15.9667 8.575 15.7333 8.66667 15.55 8.85L10.6 13.8ZM12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22Z'
          fill='#07AD4B'
        />
      </svg>
    );
  }
};
