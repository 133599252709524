import { useTranslation } from 'react-i18next';
// handler
import { handlerDownloadErrors } from './handler';
// helpers
import { traslateToSpanishPossibleErrors } from './helpers';
// rsuite
import { ButtonToolbar, Loader } from 'rsuite';
// components
import { ExButton } from '@commons/EXComponentsLibrary';
// utils
import { useUser } from 'utils/hooks';
import useChangeLanguage from 'utils/hooks/use-change-language';
// style
import style from './DragDropWithErrors.module.css';
// mui v5
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { localPossibleError, maxProducts } from 'utils/constants';
import { EX6230 } from 'config/flags';

const DragDropWithErrors = ({ errors, onHandlerClick, isLoadingFileWithErrors }) => {
    // translation by Country
    const { t } = useTranslation();
    const { userLocaleCountryCode, user } = useUser({});
    useChangeLanguage(userLocaleCountryCode);
    const EX6141 = user?.EX6141;
    const ffEX6230 = EX6230;
    // helpers
    const spanishErrorText = traslateToSpanishPossibleErrors(errors);
    const validateLimitProducts = errors?.errors?.some((error) => error === 'EXCEEDED_PRODUCTS');

    const blockButton = errors?.invalid_lines === errors?.requested_products;
    const exceededLines = errors?.exceeded_lines ?? 0;
    const invalidLines = errors?.invalid_lines + exceededLines; 


    const messageError = () => {
        const errorsQuantity = errors?.errors?.length;

        if (errorsQuantity > 1) {
            return validateLimitProducts 
                ? t('efficientPurchasing.fileUpload.efficientPurchasing_fileUploadNineteen', { productsExceeded: maxProducts })
                : t('efficientPurchasing.fileUpload.efficientPurchasing_fileUploadNine');
        }
    
        if (errorsQuantity === 1 && validateLimitProducts) {
            return t('efficientPurchasing.fileUpload.efficientPurchasing_fileUploadEightteen', { productsExceeded: maxProducts });
        }
        else
        {
            return t('efficientPurchasing.fileUpload.efficientPurchasing_fileUploadNine');
        }
    }

    return (
        <div className={style['container']}>
            <ErrorOutlineOutlinedIcon
                className={style['warning-icon']}
                style={{ color: blockButton ? '#e22e2e' : '#FFC700' }}
            />
            {errors === localPossibleError && ffEX6230 ? (
                <p className={style['title-warning']}>
                    {t('uploader.formatInvalid')}
                    {EX6141 && validateLimitProducts && (
                        <span>
                            {' '}
                            {t('efficientPurchasing.fileUpload.efficientPurchasing_fileUploadEightteen', {
                                productsExceeded: maxProducts,
                            })}
                        </span>
                    )}
                </p>
            ) : (
            <p className={style['title-warning']}>
                {EX6141 ? messageError() : t('efficientPurchasing.fileUpload.efficientPurchasing_fileUploadNine')}
            </p>
            )}
            {errors === localPossibleError && ffEX6230 ? (
                <div>

                    <span className={style['text-messages']}>{t('efficientPurchasing.fileUpload.efficientPurchasing_fileUploadTwentyOne')}</span>
                    <span className={style['subtitle-text-message']}> .txt, .csv, excel</span>
                </div>
            ) : (
                <div className={style['container-list-errors']}>
                    <p className={style['subtitle']}>
                        {t('efficientPurchasing.fileUpload.efficientPurchasing_fileUploadTen', {
                            invalidLines: invalidLines,
                            requestedProductsQuantity: errors?.requested_products,
                        })}
                    </p>
                    <div className={style['list-errors']}>
                        <ul>
                            {spanishErrorText?.map((item) => {
                                return <li>{item}</li>;
                            })}
                        </ul>
                    </div>
                    {errors && errors?.errors_file_url && (
                        <div
                            className={`link__download-errors-1 ${style['download-container']}`}
                            onClick={(evt) => {
                                evt.stopPropagation(), handlerDownloadErrors(errors?.errors_file_url);
                            }}
                        >
                            <FileDownloadOutlinedIcon className="link__download-errors-1" />
                            <p className="link__download-errors-1">
                                {t('efficientPurchasing.fileUpload.efficientPurchasing_fileUploadFifteen')}
                            </p>
                        </div>
                    )}
                    {EX6141 && validateLimitProducts && (
                        <div
                            className={`link__download-errors-1 ${style['download-container']}`}
                            onClick={(evt) => {
                                evt.stopPropagation(), handlerDownloadErrors(errors?.exceeded_file_url);
                            }}
                        >
                            <FileDownloadOutlinedIcon className="link__download-errors-1" />
                            <p className="link__download-errors-1">
                                {t('efficientPurchasing.fileUpload.efficientPurchasing_fileUploadTwenty')}
                            </p>
                        </div>
                    )}
                </div>
            )}

            <div className={style['container-buttons']}>
                <ButtonToolbar className={style['buttons-toolbar']}>
                    <ExButton
                        className={
                            !!blockButton
                                ? `button__upload-new-file-1 ${style['button-action']}`
                                : `button__move-forward-1 ${style['button-action']}`
                        }
                        appearance="alternative"
                        size="lg"
                        onClick={(evt) => {
                            blockButton ? () => {} : onHandlerClick(evt);
                        }}
                        disabled={!!isLoadingFileWithErrors}
                    >
                        {!!blockButton ? (
                            <span className="button__upload-new-file-1">
                                {t('efficientPurchasing.fileUpload.efficientPurchasing_fileUploadSixteen')}
                            </span>
                        ) : (
                            <span className="button__move-forward-1">
                                {!!isLoadingFileWithErrors ? <Loader /> : t('uploadFile.labelFinishOrder')}
                            </span>
                        )}
                    </ExButton>
                    {blockButton || (
                        <ExButton
                            className={`button__upload-new-file-2 ${style['button-action-secondary']}`}
                            appearance="alternative"
                            size="lg"
                        >
                            <span className="button__upload-new-file-2">
                                {t('efficientPurchasing.fileUpload.efficientPurchasing_fileUploadSixteen')}
                            </span>
                        </ExButton>
                    )}
                </ButtonToolbar>
            </div>
        </div>
    );
};

export default DragDropWithErrors;
