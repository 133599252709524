import React, { memo } from 'react';
// COMPONENTS
import { CellExpanded } from '../CellPrice/CellExpanded';
import { CellContracted } from '../CellPrice/CellContracted';
import { Percentage } from '../CellPrice/Percentage';
// UTILS
import { percent } from '../../../../utils';
// INTERFACES
import { StatusCdo } from 'utils/interfaces';
// STYLES
import styles from '../../../TableTotals.module.css';
import { RenderRequestButton } from './RenderRequestButton';
import { RenderUpComingMessage } from './RenderUpComingMessage';
import { t } from 'i18next';

const CellCdo = ({
    expandedFooter,
    cdo_is_enabled,
    cdo_status_id,
    drugManufacturer,
    userLocaleCountryCode,
    subtotal,
    subTotalValueColumn,
    productQuantityByDrugManufacturer,
    quantityUnitsByDrugManufacturer,
    skeleton,
    drugManufacturerEnabled,
    user,
    min_purchase,
    hasDrugManufacturerError,
    drugManufacturerId,
}) => {
    const containerStyle = () => {
        return cdo_is_enabled
            ? expandedFooter
                ? styles['cell-price-expanded']
                : styles['cell-price']
            : styles['cell-cdo-disabled'];
    };

    const backgroundStyle = () => {
        return {
            background: !cdo_is_enabled ? '#BADBFF' : drugManufacturer.length % 2 !== 0 ? '#f0f5ff' : '#fff',
        };
    };

    return (
        <div className={containerStyle()} style={backgroundStyle()}>
            {!cdo_is_enabled ? (
                cdo_status_id === StatusCdo.DISABLED ? (
                    <RenderRequestButton skeleton={skeleton} />
                ) : (
                    <RenderUpComingMessage />
                )
            ) : (
                <p className={`${expandedFooter ? '' : styles['total']}`}>
                    {expandedFooter && !hasDrugManufacturerError ? (
                        <CellExpanded
                            user={user}
                            min_purchase={min_purchase}
                            subTotalValueColumn={subTotalValueColumn}
                            userLocaleCountryCode={userLocaleCountryCode}
                            skeleton={skeleton}
                            drugManufacturerEnabled={drugManufacturerEnabled(drugManufacturerId)}
                            productQuantityByDrugManufacturer={productQuantityByDrugManufacturer(drugManufacturerId)}
                            quantityUnitsByDrugManufacturer={quantityUnitsByDrugManufacturer(drugManufacturerId)}
                            t={t}
                            drugManufacturerId={drugManufacturerId}
                        />
                    ) : (
                        !hasDrugManufacturerError && (
                            <CellContracted
                                user={user}
                                min_purchase={min_purchase}
                                subTotalValueColumn={subTotalValueColumn}
                                skeleton={skeleton}
                                userLocaleCountryCode={userLocaleCountryCode}
                                drugManufacturerEnabled={drugManufacturerEnabled(drugManufacturerId)}
                                t={t}
                                drugManufacturerId={drugManufacturerId}
                                productQuantityByDrugManufacturer={productQuantityByDrugManufacturer(drugManufacturerId)}
                            />
                        )
                    )}
                </p>
            )}
            {cdo_is_enabled && expandedFooter && !hasDrugManufacturerError && (
                <Percentage
                    skeleton={skeleton}
                    drugManufacturerEnabled={drugManufacturerEnabled(drugManufacturerId)}
                    percent={percent(t, subTotalValueColumn, subtotal)}
                    productQuantityByDrugManufacturer={productQuantityByDrugManufacturer(drugManufacturerId)}
                />
            )}
        </div>
    );
};

export default memo(CellCdo);
