import { PanelGroup, Panel } from "rsuite";
import { Header } from "./Header";
import { Options } from "./OptionsChart";
import { ICardDrop } from "./CardDrop.interface";
import { HighchartsData } from "../HighchartsData";
import style from "./CardDrop.module.css"
import React from "react";

const CardDrop = ({ drugmanufacturers }: ICardDrop) => {
  const [optionsChart, setOptionsChart] = React.useState<any>();

  const handleChartClick = () => {
    const arrayChart = [];
    drugmanufacturers?.map((item) => {
      arrayChart.push({
        name: item.name,
        data: [item.percentage],
      });
    });

    const Chart = Options({ drugmanufacturers: arrayChart });
    setOptionsChart(Chart);
  };

  return (
    <>
      <PanelGroup
      className={`expand__purchase-rate-1 ${style["title-svg"]}`}
        accordion
        bordered
        onSelect={handleChartClick}
        style={{
          borderLeft: "1px solid white",
          borderRight: "1px solid white",
          borderRadius: 0,
        }}
      >
        <Panel header={<Header />}>
          <HighchartsData optionsChart={optionsChart} />
        </Panel>
      </PanelGroup>
    </>
  );
};
export default CardDrop;
