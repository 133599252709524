// react
import { useRef, useState, useEffect } from 'react';

// rsuite
import { Button, ButtonToolbar, CheckPicker, Checkbox } from 'rsuite';
// style
import style from './SelectList.module.css';
// interface
import { ISelectList } from './SelectList.interface';
// hooks
import { useDrugManufacturersByClientIdWithoutPagination, useUser } from 'utils/hooks';

// utils
import { fillSelector } from './utils/selector';
//gtag
import * as gtag from 'utils/gtag';

import { EX5057 } from 'config/flags';
import { useTranslation } from 'react-i18next';

const SelectList = ({
    icon,
    placeholder,
    setListDrugTerms,
    listDrugTerms,
    handlerClearFilters,
    selectedItem,
    setSelectedItem,
    timerSkeleton,
}: ISelectList) => {
    // ref
    const buttonRef = useRef(null);
    // hooks
    const { user } = useUser({});
    const [hasValueSet, setHasValueSet] = useState(false);
    const [selectedValues, setSelectedValues] = useState([]);
    const { t } = useTranslation();
    const { drugManufacturers } = useDrugManufacturersByClientIdWithoutPagination(
        user?.client?.id,
        false,
        'with_operations',
    );
    // handlers
    const handleChange = (value: any[]) => {
        setSelectedValues(value);
        setSelectedItem(value);
    };

    const handleApplyClick = (evt: any) => {
        evt.preventDefault();

        if (selectedValues.length > 0) {
            setHasValueSet(true);
            setListDrugTerms(selectedItem);
            buttonRef?.current.close();
        }
    };

    const handleCleanFilters = () => {
        handlerClearFilters('drugs', hasValueSet);
        buttonRef?.current.close();

        setTimeout(() => {
            setHasValueSet(false);
        }, 1000);
    };

    const recordset = fillSelector(drugManufacturers);

    const allValue = recordset?.map((item: any) => item.value);

    const selectDrugmanufacturer = (evt) => {
        gtag.event({
            action: 'click',
            category: 'my-orders',
            label: 'button__apply-filter-drugstore-1',
            value: 0,
        });
        handleApplyClick(evt);
    };

    return (
        <div className={style['wrapper-container']}>
            <CheckPicker
                disabled={timerSkeleton}
                ref={buttonRef}
                onClean={() => handleCleanFilters()}
                data={recordset}
                size="lg"
                className={style['select-picker']}
                placeholder={<span className={style['placeholder-text']}>{placeholder}</span>}
                cleanable={true}
                placement="bottom"
                onChange={handleChange}
                value={!!selectedItem ? selectedItem : listDrugTerms}
                renderExtraFooter={() => (
                    <div className={style['footer-container']}>
                        <Checkbox
                            inline
                            indeterminate={selectedItem?.length > 0 && selectedItem?.length < allValue?.length}
                            checked={selectedItem?.length === allValue?.length}
                            onChange={() => {
                                setSelectedItem(selectedItem?.length === allValue?.length ? [] : allValue);
                            }}
                        >
                            {!!EX5057 ? t('myOrders.labelSelectAll') : 'Seleccionar todo'}
                        </Checkbox>
                        <ButtonToolbar>
                            <Button
                                className={`button__apply-filter-drugstore-1 ${style['footer-button']}`}
                                appearance="primary"
                                size="sm"
                                onClick={(evt) => selectDrugmanufacturer(evt)}
                            >
                                {!!EX5057 ? t('myOrders.labelApply') : 'Aplicar'}
                            </Button>
                        </ButtonToolbar>
                    </div>
                )}
            />
            <div className={style['container-icon']}>{icon}</div>
        </div>
    );
};

export default SelectList;
