import { EX5057, EX5225 } from 'config/flags';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import { useTranslation } from 'react-i18next';
import { Grid, Row } from 'rsuite';
import { IHeader } from './Header.interface';
import style from './Header.module.css';
import SkeletonHeader from './SkeletonHeader';

const Header = ({ orderId, date }: IHeader) => {
    //translation by Country
    const { t, i18n } = useTranslation();

    // Config locale to serve as actual lang to dayjs
    const locale = i18n.language || 'es'; // Default fallback 'es'
    dayjs.locale(locale);

    // Format date
    const dateFormat = {
        es: 'DD [de] MMMM HH:mm [hs]',
        'en-US': 'MMMM DD [at] hh:mm A',
        'pt-br': 'DD [de] MMMM [às] HH:mm [h]',
    };

    const customDate = EX5225
        ? dayjs(date).format(dateFormat[locale] || dateFormat['es'])
        : dayjs(date).locale('es').format('DD [de] MMMM HH:mm [hs]'); // Default fallback 'es'
    return (
        <Grid fluid className={orderId && date ? style['header'] : style['header-load']}>
            {orderId && date ? (
                <>
                    <Row>
                        <span className={style['sub-title']}>#{orderId}</span>
                    </Row>

                    <Row>
                        <p className={style['title']}>
                            {!!EX5057 ? t('myOrders.labelOrderDone') : 'Pedido realizado'} | {customDate}
                        </p>
                    </Row>
                </>
            ) : (
                <SkeletonHeader />
            )}
        </Grid>
    );
};

export default Header;
