import { t } from 'i18next';
import { Button, Popover, Whisper, Tag } from 'rsuite';
import style from './DetailProduct.module.css';
import CloseIcon from '@mui/icons-material/Close';
import { EX5057 } from 'config/flags';
import { IDetailProduct } from './DatailProduct.interface';
import { RsuiteTooltip } from '../RsuiteTooltip';
import { separateCommas } from 'utils/strings/separateCommas';
import { capitalizeEveryFirstLetter } from 'utils/strings/capitalizeEveryFirstLetter';
import { useUser } from 'utils/hooks';

const DetailProduct = ({
    children,
    category,
    medicine,
    activePrinciple,
    labBrand,
    imageSrc = '',
    onLoad,
    fakeLoading,
    onClick = () => {},
    innerRef,
    imageLoad = false,
    disabled = false,
    placement,
    container,
    onOpen,
    onClose,
    laboratory,
    description,
    productName,
    presentation,
}: IDetailProduct) => {
    const { user } = useUser({});
    const EX4927 = user?.EX4927; // FF epic refactor v3

    const separateComma = separateCommas(activePrinciple);

    return (
        <Whisper
            ref={innerRef}
            container={container?.current || document.body}
            trigger="click"
            placement={placement}
            enterable
            disabled={disabled}
            onOpen={onOpen}
            onClose={onClose}
            speaker={
                <Popover arrow={true}>
                    <div className={style['container-popover']}>
                        <div className={style['container-button-close']}>
                            <Button className={style['button-close-icon']} onClick={onClick}>
                                <CloseIcon />
                            </Button>
                        </div>
                        <div className={style['container-header-popover']}>
                            <div className={style['container-image']}>
                                {!imageLoad && fakeLoading}

                                <img
                                    src={imageSrc}
                                    alt="imagen medicamento"
                                    onError={(e: any) => (e.target.src = '/assets/images/default.png')}
                                    onLoad={onLoad}
                                    width={100}
                                    height={100}
                                    className={style['image-icon-product']}
                                />
                            </div>
                            <div className={style['container-name-description']}>
                                {EX4927 ? (
                                    <>
                                        <p className={style['label-body-detail-lab']}>{laboratory}</p>
                                        {!!productName && (
                                            <span className={style['medicament-text']}>{productName}</span>
                                        )}
                                        {!!presentation && (
                                            <span className={style['medicament-presentation']}>{presentation}</span>
                                        )}
                                        {!productName && !presentation && (
                                            <RsuiteTooltip text={description} placement="bottom" trigger="hover">
                                                <span className={style['medicament-text']}>{description}</span>
                                            </RsuiteTooltip>
                                        )}
                                        <hr className={style['divisor-line']} />
                                        <div>
                                            <p className={style['title-body-detail']}>
                                                {!!EX5057 ? t('shoppingCart.labelMainDrug') : 'Principio activo'}
                                            </p>
                                            {separateComma?.map((elem, index) => {
                                                return (
                                                    <div key={`detailProduct_${index}`}>
                                                        <Tag size="sm" className={style['label-body-detail-tag']}>
                                                            {capitalizeEveryFirstLetter(elem)}
                                                        </Tag>
                                                        {index !== separateComma?.length - 1 && (
                                                            <span className={style['plus']}> + </span>
                                                        )}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <p
                                            className={style['category-text']}
                                            style={{ display: !!category ? '' : 'none' }}
                                        >
                                            {capitalizeEveryFirstLetter(category)}
                                        </p>
                                        <p className={style['medicament-text']}>
                                            {capitalizeEveryFirstLetter(medicine)}
                                        </p>
                                    </>
                                )}
                            </div>
                        </div>
                        {!!category && category !== null && (
                            <div className={style['container-body']}>
                                {EX4927 ? (
                                    <div>
                                        <p className={style['title-body-detail']}>
                                            {!!EX5057 ? t('shoppingCart.labelMainAction') : 'Acciones'}
                                        </p>
                                        <RsuiteTooltip
                                            text={category}
                                            placement="bottom"
                                            trigger="hover"
                                            disabled={category.length < 25 ? true : false}
                                        >
                                            <span className={style['label-body-detail-category']}>{category}</span>
                                        </RsuiteTooltip>
                                    </div>
                                ) : (
                                    <>
                                        <div>
                                            <p className={style['title-body-detail']}>
                                                {!!EX5057 ? t('shoppingCart.labelMainDrug') : 'Principio activo'}
                                            </p>
                                            <span className={style['label-body-detail']}>
                                                {capitalizeEveryFirstLetter(activePrinciple)}
                                            </span>
                                        </div>
                                        <div>
                                            <p className={style['title-body-detail']}>
                                                {!!EX5057
                                                    ? t('shoppingCart.labelDrugmanufacturers')
                                                    : 'Laboratorio / Marca'}
                                            </p>
                                            <span className={style['label-body-detail']}>
                                                {capitalizeEveryFirstLetter(labBrand)}
                                            </span>
                                        </div>
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                </Popover>
            }
        >
            {children}
        </Whisper>
    );
};

export default DetailProduct;
