import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(({ palette }) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  disable_hover: {
    "&:hover": {
      background: "none",
    },
  },
  success: {
    color: "#07AD4B",
    paddingLeft: "10px",
    fontSize: "14px",
  },
  error: {
    color: palette.Ma[400],
    paddingLeft: "10px",
    fontSize: "14px",
  },
  inputNumber: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  tabs: {
    display: "flex",
    width: "398px",
    borderRadius: 4,
    background: "#F5F6FA",
    height: 40,
    padding: 4,
  },
  formatRequest: {
    color: "#017C7A",
    fontSize: 12,
    fontFamily: "Source Sans Pro",
  },
  tab: {
    minWidth: 130,
    width: 195,
    cursor: "pointer",
    height: 32,
    display: "flex",
    border: "none",
    alignItems: "center",
    borderRadius: 4,
    background: "white",
    justifyContent: "center",
    fontSize: 14,
    fontWeight: 600,
    textTransform: "uppercase",
    color: "#0171E6",
    boxShadow: "1px 2px 6px #00000029",
  },
  button: {
    border: "none",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: 130,
    width: 195,
    height: 32,
    color: "#6B6F93",
    fontWeight: 400,
    fontSize: 14,
    textTransform: "uppercase",
  },
  selectItem: {
    width: 400,
    height: 40,
  },
  select: {
    width: 400,
    "&.MuiSelect-nativeInput": {
      paddingLeft: "20px",
    },

    "&.MuiInputLabel-shrink": {
      transform: "translate(0, -6.0px) scale(0.75)",
      paddingLeft: "0px",
      "&.Mui-focused": {
        color: "rgb(29, 202, 211)",
      },
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgb(29, 202, 211)",
    },
  },
  inputLabelSelect: {
    "&.MuiFormLabel-root.Mui-focused": {
      color: "rgb(29, 202, 211)",
    },
  },
  autoComplete: {
  },
}));

export default useStyles;
