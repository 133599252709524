// next
import Link from 'next/link';
// internal component
import { MyOrdersList, OrdersByDrugmanufacturer } from '.';
// components
import { Skeletons } from '../Skeletons';
// rsuite
import { Panel, Row, Col, Avatar, Whisper, Tooltip } from 'rsuite';
// mui
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// style
import style from './OrderCards.module.css';
// interface
import { IOrderCards, EOrderCards } from './OrderCards.interface';
// dayjs
import dayjs from 'dayjs';
const es = require('dayjs/locale/es');
const en = require('dayjs/locale/en');
const ptBR = require('dayjs/locale/pt-br');

// hook
import { useUser } from 'utils/hooks';
import { useTranslation } from 'react-i18next';
import useChangeLanguage from 'utils/hooks/use-change-language';
import { EX4396 } from 'config/flags';
import { backgroundColorChange } from './utils/bkcColorChange';
import { COUNTRY } from 'utils/country';
import useCurrencyFormatter from 'utils/hooks/useCurrencyFormatter/useCurrencyFormatter';

const OrderCards = ({
    dateTime,
    orderNumber,
    drugmanufactur,
    value,
    avatar,
    noMedicinals,
    client,
    oneDrugmanufacturerName,
    imageOneDrugmanufacturer,
    drugmanufacturers,
    selectorList,
    timerSkeleton,
    initialLettersDrugmanufName,
    orderNumberByDrugmanufact,
    referencePointOfSale,
    drug_manufacturer_id,
}: IOrderCards) => {
    //translation by Country
    const { t } = useTranslation();
    const { user, userLocaleCountryCode } = useUser({});
    const {formatCurrency} = useCurrencyFormatter();
    dayjs.locale(userLocaleCountryCode === COUNTRY.US ? en : userLocaleCountryCode === COUNTRY.BR ? ptBR : es);
    if (EX4396) useChangeLanguage(userLocaleCountryCode);
    //user avatar color
    const { first_name, last_name } = user;
    const isAvatarUser = avatar === `${first_name[0]}${last_name[0]}`;
    let backgroundColor = '';
    let textColor = '';

    if (!isAvatarUser) {
        const { backgroundColor: b, textColor: t } = backgroundColorChange(avatar);
        backgroundColor = b;
        textColor = t;
    }

    const colorUser = '#FFF4D9';
    const textColorUser = '#A16205';

    // href details
    const splitString = orderNumberByDrugmanufact && orderNumberByDrugmanufact.split('-');
    const linkDetails =
        selectorList.tag === EOrderCards.typeTwo
            ? `my-orders/deliveries/${orderNumber}/${splitString[0]}`
            : `my-orders/details/${orderNumber}`;

    // formatter
    const totalValue = formatCurrency({value}).fullValue;

    //handle redirect url
    const handleRedirect = () => {
        if (selectorList.tag === EOrderCards.typeOne) {
            localStorage.setItem('urlRedirect', linkDetails);
        } else {
            localStorage.setItem('urlRedirect', '');
        }
    };

    const gtagClass = selectorList?.tag === EOrderCards.typeOne ? 'card__order-detail' : 'card__purchase-order-detail';

    return (
        <div className={style['container']}>
            <Row>
                <Link
                    href={`/${user?.client?.id}/${linkDetails}`}
                    className={`${gtagClass} ${style['container-link']}`}
                    onClick={handleRedirect}
                >
                    <Col xs={24} className={gtagClass}>
                        <div className={style['wrapper-card']}>
                            <Panel className={style['panel-card']}>
                                {!!timerSkeleton ? (
                                    <Skeletons width={32} height={12} animation="wave" />
                                ) : (
                                    <>
                                        <p className={style['text-date-time']}>
                                            {userLocaleCountryCode === COUNTRY.US &&
                                                dayjs(dateTime).format('MMM D, YYYY - hh:mm A')}
                                            {userLocaleCountryCode !== COUNTRY.US &&
                                                dayjs(dateTime).format('D [de] MMMM YYYY - HH:mm [hs]')}
                                        </p>
                                    </>
                                )}

                                {!!timerSkeleton ? (
                                    <Skeletons width={160} height={14} animation="wave" />
                                ) : (
                                    (selectorList.tag === EOrderCards.typeOne && (
                                        <p className={style['text-order-number']}># {orderNumber}</p>
                                    )) ||
                                    (selectorList.tag === EOrderCards.typeTwo && (
                                        <p className={style['text-order-number']}># {orderNumberByDrugmanufact}</p>
                                    ))
                                )}

                                {!!timerSkeleton ? (
                                    <Skeletons width={120} height={12} animation="wave" />
                                ) : (
                                    <div className={style['row-container-name']}>
                                        {referencePointOfSale && (
                                            <span className={style['text-subtitle']}>{drugmanufactur}</span>
                                        )}
                                        <span className={style['text-drugmanufactur-title']}>
                                            {referencePointOfSale ? referencePointOfSale : drugmanufactur}
                                        </span>
                                    </div>
                                )}

                                {!!timerSkeleton ? (
                                    <Skeletons width={120} height={12} animation="wave" />
                                ) : (
                                    !!noMedicinals && <p className={style['text-no-medicinals']}>{noMedicinals}</p>
                                )}

                                <div className={style['container-footer']}>
                                    <Col xs={8}>
                                        {selectorList.tag === EOrderCards.typeOne && (
                                            <MyOrdersList
                                                drugmanufacturers={drugmanufacturers}
                                                timerSkeleton={timerSkeleton}
                                                initialLettersDrugmanufName={initialLettersDrugmanufName}
                                            />
                                        )}
                                        {selectorList.tag === EOrderCards.typeTwo && (
                                            <OrdersByDrugmanufacturer
                                                image={imageOneDrugmanufacturer}
                                                name={oneDrugmanufacturerName}
                                                timerSkeleton={timerSkeleton}
                                                drug_manufacturer_id={drug_manufacturer_id}
                                            />
                                        )}
                                    </Col>

                                    <div className={style['wrapper-footer']}>
                                        <Col xs={8}>
                                            <div className={style['avatar-container-wrapper']}>
                                                {!!timerSkeleton ? (
                                                    <Skeletons width={100} height={14} animation="wave" />
                                                ) : (
                                                    <p className={style['total-value']}>
                                                        {totalValue}
                                                    </p>
                                                )}
                                            </div>
                                        </Col>
                                        <Col xs={5}>
                                            <Whisper
                                                placement="bottom"
                                                speaker={
                                                    <Tooltip className={style['client-tooltip-label']}>
                                                        {client}
                                                    </Tooltip>
                                                }
                                            >
                                                {!!timerSkeleton ? (
                                                    <Skeletons
                                                        width={24}
                                                        height={24}
                                                        animation="wave"
                                                        variant="circular"
                                                    />
                                                ) : (
                                                    <Avatar
                                                        size="sm"
                                                        circle
                                                        className={style['avatar-icon']}
                                                        style={{
                                                            backgroundColor: isAvatarUser ? colorUser : backgroundColor,
                                                            color: isAvatarUser ? textColorUser : textColor,
                                                        }}
                                                    >
                                                        {avatar}
                                                    </Avatar>
                                                )}
                                            </Whisper>
                                        </Col>
                                    </div>
                                </div>
                            </Panel>
                            <div className={style['icon-arrow']}>
                                <ChevronRightIcon className={style['icon']} />
                            </div>
                        </div>
                    </Col>
                </Link>
            </Row>
        </div>
    );
};

export default OrderCards;
