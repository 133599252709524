import React from 'react';
// COMPONENTS
import styles from '../RecommendedCellProduct.module.css';

const LimitMessage = () => (
    <div className={styles['limit-recomendation-text']}>
        <p>No hay más recomendaciones para esta compra</p>
        <p>Mañana vas a encontrar nuevas recomendaciones</p>
    </div>
);

export default LimitMessage;
