enum COLORS {
    BLUE = '#EDF6FF',
    YELLOW = '#FFF9E6',
    RED = '#FFEBEB',
    DEFAULT = '#fff',
}

export const getBackgroundColor = (isFetching, blockerFormat, blockButton, isDragging, errors) => {
    if (isFetching) {
        return COLORS.BLUE;
    } else if ( blockButton) {
        return COLORS.YELLOW;
    } else if (blockerFormat ) {
        return COLORS.RED;
    } else if (errors) {
        return COLORS.RED;
    } else if (isDragging) {
        return COLORS.BLUE;
    } else {
        return COLORS.DEFAULT;
    }
};
