import React from "react";
import { Skeletons } from "commons/components/Orders/Skeletons";
import style from "./SkeletonCard.module.css";
function SkeletonCard() {
  return (
    <>
      <div className={style["first-row"]}>
        <Skeletons width={320} height={14} />
      </div>

      <div className={style["second-row"]}>
        <div className={style["secondRow-line"]}>
          <Skeletons width={200} height={14} />
          <Skeletons width={104} height={14} />
        </div>
        <div className={style["secondRow-line"]}>
          <Skeletons width={200} height={14} />
          <Skeletons width={104} height={14} />
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Skeletons width={200} height={14} />
          <Skeletons width={104} height={14} />
        </div>
      </div>

      <div style={{ padding: 24, borderBottom: "1px solid #D1D1D1" }}>
        <Skeletons width={240} height={14} />
      </div>

      <div className={style["third-row"]}>
        <Skeletons width={320} height={14} />
        <div style={{ display: "flex" }}>
          <div className={style["thirdRow-button"]} style={{marginRight:12}}></div>
          <div className={style["thirdRow-button"]}></div>
        </div>
      </div>
    </>
  );
}

export default SkeletonCard;
