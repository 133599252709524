import { useEffect, useState } from 'react';
// configs
import { EX6809 } from 'config/flags';
// contexts
import { useFetchingContext, useFooterContext } from 'context';
// utils && hooks
import { useUser } from 'utils/hooks';
import { useCalculate } from '../../../../hooks';
import { getUnitsSelectedByDrugManufacturerId } from './utils/getUnitsById';
import { getProductSelectedByDrugManufacturerId } from 'utils/productComparison/getProductSelectedByDrugManufacturerId';

export const useTotalCell = ({ drugManufacturerId }) => {
    const { getSubtotalById, products, units } = useCalculate();

    const { loading } = useFetchingContext();
    const { footerValues } = useFooterContext();

    const [init, setIinit] = useState<boolean>(true);
    // ------------ subtotal ---------------
    const [displayTotal, setDisplayTotal] = useState<string | number>('0');
    // ------------ total cell ---------------
    const [displayTotalCell, setDisplayTotalCell] = useState<string | number>('0');
    const [displayQuantityCell, setDisplayQuantityCell] = useState<number>(0);
    const [displayProductsCell, setDisplayProductsCell] = useState<number>(0);

    const { user } = useUser({});
    const EX5937 = user?.EX5937;

    // first load
    useEffect(() => {
        if (footerValues !== null && footerValues !== undefined) {
            if (init && Object.keys(footerValues)?.length > 0) {
                setDisplayTotal(footerValues?.total);
                setDisplayTotalCell(footerValues?.subtotals?.[drugManufacturerId] ?? 0);
                setDisplayQuantityCell(footerValues?.q_units?.[drugManufacturerId] ?? 0);
                setDisplayProductsCell(footerValues?.q_products?.[drugManufacturerId] ?? 0);
                setTimeout(() => {
                    setIinit(false);
                }, 1500);
            }
        }
    }, [footerValues]);

    useEffect(() => {
        if (loading) {
            setIinit(true);
        }
    }, [loading]);

    const DEPENDENCIES = EX6809
        ? [products, init, units]
        : [
              products?.reduce((count, { amountSelected }) => (count += amountSelected || 0), 0),
              getSubtotalById(drugManufacturerId),
          ];

    // after first load
    useEffect(() => {
        if (!init) {
            if (EX6809) {
                setDisplayTotal(products?.reduce((count, { amountSelected }) => (count += amountSelected || 0), 0));
            } else {
                setDisplayTotal(products?.reduce((count, { amountSelected }) => (count += amountSelected || 0), 0));
                setDisplayTotalCell(getSubtotalById(drugManufacturerId));
                setDisplayQuantityCell(getUnitsSelectedByDrugManufacturerId(drugManufacturerId, units, EX5937));
                setDisplayProductsCell(
                    getProductSelectedByDrugManufacturerId(drugManufacturerId, products, EX5937)?.length,
                );
            }
        }
    }, DEPENDENCIES);

    return {
        displayTotal,
        displayTotalCell,
        displayQuantityCell,
        displayProductsCell,
    };
};
