import { useCallback } from 'react';
// contexts
import { useDrugManufacturersContext, useProductsContext } from 'context';
import { useProductComparison } from 'context/product-comparison.context';
// utils
import { formatDrugManufacturers } from 'utils/drugManufacturers/formatDrugmanufacturersNames';
import { ProductInterface } from 'common/types';
import { useUser } from 'utils/hooks';

export const useParseIdToNameDrugManufacturer = () => {
    const { products } = useProductsContext();
    const { drugManufacturer, enabledOnOff, isEnabledDrugManufacturer } = useDrugManufacturersContext();
    const { userLocaleCountryCode, user } = useUser({});
    const EX5937 = user?.EX5937; // ff split purchase

    /**
     * Retrieves the unique drug manufacturer IDs for products that have a minimum or maximum quantity specified.
     * @returns An array of unique drug manufacturer IDs.
     */
    const drugManufacturerBelowOrUpperQuantity = () => {
        const ids = productUncheckedWithMinOrMaxRequired()
            ?.map((item) => {
                return Object.values(item?.products)
                    ?.map((product) => {
                        if (!!product?.mincant || !!product?.maxcant) {
                            return product?.drug_manufacturer_id;
                        }
                    })
                    .filter((id) => id); // Filter null values
            })
            .flat(); // Aplana la matriz resultante

        // Delete duplicate IDs
        const uniqueIds = [];
        const idTracker = {};

        ids.forEach((id) => {
            if (!idTracker[id]) {
                idTracker[id] = true;
                uniqueIds.push(id);
            }
        });

        return uniqueIds;
    };

    const productUncheckedWithMinOrMaxRequired = useCallback((): ProductInterface[] => {
        if (enabledOnOff) {
            const productsWithMinMaxRequired = products?.filter(
                ({ products, areAllItemChecked, drugManufacturerIdSelected, quantity }) => {
                    if (Array.isArray(drugManufacturerIdSelected)) {
                        const isEnabled = EX5937
                            ? drugManufacturerIdSelected
                                  ?.map((d) => {
                                      return isEnabledDrugManufacturer(d);
                                  })
                                  ?.some((item) => !!item)
                            : isEnabledDrugManufacturer(drugManufacturerIdSelected);
                        // add validation for min and max quantity products to exclude them from the list of unchecked products
                        return Object.values(products).some((product) => {
                            const hasMinCant = product?.mincant !== null && product?.mincant > 0;
                            const hasMaxCant = product?.maxcant !== null && product?.maxcant > 0;
                            return (!!hasMinCant || !!hasMaxCant) && areAllItemChecked && !isEnabled;
                        });
                    }
                },
            );

            return productsWithMinMaxRequired;
        }
        const productsWithMinMaxRequired = products?.filter(({ products, areAllItemChecked, quantity }) => {
            return Object.values(products).some((product) => {
                const hasMinCant = product?.mincant !== null && product?.mincant > 0;
                const hasMaxCant = product?.maxcant !== null && product?.maxcant > 0;
                return (!!hasMinCant || !!hasMaxCant) && areAllItemChecked;
            });
        });

        return productsWithMinMaxRequired;
    }, [products]);

    const parseIdToNameDrugManufacturer = () => {
        let namesArray = [];
        drugManufacturerBelowOrUpperQuantity()?.forEach((d) => {
            const namesById = drugManufacturer?.find((item) => item?.id === d);
            namesArray?.push(namesById?.name);
        });
        const drugManufacturersNameWithMinmax = formatDrugManufacturers(namesArray, userLocaleCountryCode);
        return drugManufacturersNameWithMinmax;
    };

    return { parseIdToNameDrugManufacturer };
};
