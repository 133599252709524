// components
import { Layout } from '../Layout';
import { FormAuth } from './FormAuth';

const Login = () => {
    return (
        <Layout>
            <FormAuth />
        </Layout>
    );
};

export default Login;
