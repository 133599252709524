import { useNewSearchProduct } from "context";
import { OnCheckedProps } from "../ActionCheckbox.interface";

const useCheckboxHandler = () => {
    const { setAggregationsTypes } = useNewSearchProduct();

    const onCheckedAction = (checkedProps: OnCheckedProps) => {
        const {checked, tag, evt} = checkedProps;
        setAggregationsTypes((prevState) => {
            const updatedState = { ...prevState };

            if (checked) {
                updatedState[tag] = [...(updatedState[tag] || []), evt];
            } else {
                if (Array.isArray(updatedState[tag])) {
                    updatedState[tag] = updatedState[tag].filter((item) => item !== evt);
                    if (updatedState[tag].length === 0) {
                        delete updatedState[tag];
                    }
                }
            }

            return updatedState;
        });
    }

    return {onCheckedAction}
}

export default useCheckboxHandler;