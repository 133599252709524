import { t } from 'i18next';
import { memo } from 'react';
// contenxt
import { useListBoxDrugManufacturersContext } from 'context';
// components
import { ExButton } from '@commons/EXComponentsLibrary';
// interfaces
import { IButtonItemProps } from './ButtonItem.interface';
// utils
import { capitalizeEveryFirstLetter } from 'utils/strings/capitalizeEveryFirstLetter';
// styles
import { useModalCredentialsContext } from 'context/ModalCredentials/ModalCredentials-context';
import styles from './Button.module.css';

const ButtonItem = ({ item }: IButtonItemProps): JSX.Element => {
    const { handleClickButton, idsDeleted, requestVendor } = useListBoxDrugManufacturersContext();
    const { orderByCredentialType, toggleModal, handlerDrugConnection } = useModalCredentialsContext();
    const getCredentialType = orderByCredentialType?.find((elem) => elem?.id === item?.id)?.credential_types;

    const triggerModal = (item) => {
        if (item?.type_id === 3) {
            requestVendor(item?.name, item?.id);
        } else if (item?.type_id === 2 || item?.type_id === 1) {
            toggleModal?.toggle();
            handlerDrugConnection('', item.id, getCredentialType);
        }
    };

    return (
        <ExButton
            size="xs"
            appearance="primary"
            className={styles['button']}
            onClick={() => {
                triggerModal(item);
            }}
            disabled={idsDeleted?.includes(item?.id)}
        >
            {capitalizeEveryFirstLetter(
                item?.type_id === 3
                    ? idsDeleted?.includes(item?.id)
                        ? t('home.widgetButtonRequested')
                        : t('home.widgetButtonRequest')
                    : t('home.widgetButtonConnect'),
            )}
        </ExButton>
    );
};

export default memo(ButtonItem);
