// react
import { useRef } from "react";

// component
import { TabSelector, OrderCards, EmptyOrderCard } from "commons/components";
import { ListOrdersFooter, ListDeliveries } from ".";


// interface
import { IListOrders } from "./ListOrders.interface";

// helpers
import {
  newOrdersArray,
  obtainInitialsLetter,
  obtainNameOfPointOfSale,
  obtainReferenceOfPointOfSale,
} from "./ListOrders.herlper";

// utils
import { addImageManufactured } from "./ListOrders.utils";
import { handlerSelectOrders } from "./utils/tabs";
import { useUser } from "utils/hooks";

// rsuite
import { Grid } from "rsuite";

//style
import style from "./ListOrders.module.css";

//gtag
import * as gtag from "utils/gtag";
// component
import { OutOfOrders } from "commons/modules";

const ListOrders = ({
  orders,
  deliveries,
  ordersPagination,
  deliveriesPagination,
  selectorList,
  setSelectorList,
  timerSkeleton,
  itemRow,
  setItemRow,
  pages,
  setPages,
  shouldOutOfOrders,
  listTagTerms,
  listDrugTerms,
  searchTerms,
  handlerClearFilters,
}: IListOrders) => {
  // ref
  const firstElement = useRef(null);

  // desestructuration
  const { pagination } = ordersPagination || deliveriesPagination || [];

  const weekFilterApply = localStorage.getItem("selectedFilter") === "false";

  const customsFilters =
    listTagTerms.length > 0 ||
    listDrugTerms.length > 0 ||
    searchTerms.length > 0 ||
    weekFilterApply;

  const ordersArray = newOrdersArray(orders);

  const handlerOrdersContainer = () => {
    gtag.event({
      action: "click",
      category: "my-orders",
      label: "card__order-detail",
      value: 0,
    });
  };

  return (
    <div className={style["container-list"]}>
      <Grid fluid>
        {!shouldOutOfOrders ? (
          <TabSelector
            handlerSelectOrders={handlerSelectOrders}
            selectorList={selectorList}
            setSelectorList={setSelectorList}
          />
        ) : (
          ""
        )}

        {shouldOutOfOrders && (
          <OutOfOrders
            handlerClearFilters={handlerClearFilters}
            customsFilters={customsFilters}
          />
        )}
        {!shouldOutOfOrders ? (
          <Grid>
            {!!timerSkeleton ? (
              <div className={style["wrapper-orders-cards"]}>
                <EmptyOrderCard />
              </div>
            ) : (
              <>
                {selectorList.tag === "myOrders" &&
                  ordersArray?.map(
                    (
                      {
                        created_at,
                        user,
                        point_of_sale_id,
                        point_of_sale_name,
                        totalOrders,
                        drug_manufacturer_orders,
                        id,
                      },
                      idx
                    ) => {
                      // desestructure
                      const { first_name, last_name, pointsOfSale } = user;
                      const initialName = obtainInitialsLetter(
                        `${first_name} ${last_name}`
                      );


                      const referencePointOfSale = obtainReferenceOfPointOfSale(
                        pointsOfSale,
                        point_of_sale_id
                      );

                      const drugList = addImageManufactured(
                        drug_manufacturer_orders,
                        "orders"
                      );

                      const nameOfDrugmanufacturer = drugList.map(({ name }) =>
                        obtainInitialsLetter(name)
                      );

                      return (
                        <div
                          className={style["wrapper-orders-cards"]}
                          ref={idx === 0 ? firstElement : null}
                          onClick={() => handlerOrdersContainer()}
                        >
                          <OrderCards
                            key={idx}
                            dateTime={created_at}
                            orderNumber={id}
                            referencePointOfSale={referencePointOfSale}
                            drugmanufactur={point_of_sale_name}
                            value={totalOrders}
                            avatar={initialName}
                            client={`${first_name} ${last_name}`}
                            selectorList={selectorList}
                            drugmanufacturers={drug_manufacturer_orders}
                            timerSkeleton={timerSkeleton}
                            initialLettersDrugmanufName={nameOfDrugmanufacturer}
                          />
                        </div>
                      );
                    }
                  )}
                {selectorList.tag === "ordersByDrugmanufacturer" && (
                  <ListDeliveries
                    deliveries={deliveries}
                    selectorList={selectorList}
                    timerSkeleton={timerSkeleton}
                    firstElement={firstElement}
                  />
                )}

                <ListOrdersFooter
                  pagination={pagination}
                  itemRow={itemRow}
                  pages={pages}
                  setItemRow={setItemRow}
                  setPages={setPages}
                  firstElement={firstElement}
                  orders={orders}
                  deliveries={deliveries}
                  timerSkeleton={timerSkeleton}
                />
              </>
            )}
          </Grid>
        ) : (
          ""
        )}
      </Grid>
    </div>
  );
};

export default ListOrders;
