import { CHIP_DISCOUNT_COLOR } from 'utils/enums/chipDiscount';
import { hasAnyVolDiscApplied } from '../../../../Chip/TagVolumeDiscount/utils';

export const determineChipColor = ({ product, bonus, isAppliedIndex, checked, isCellSplit = false }): string => {
    const hasQuantityDiscount = !!product?.quantityDiscountApplies;
    const hasBonusAndVolDisc = bonus?.length > 0 && hasAnyVolDiscApplied(isAppliedIndex);

    if (!!isCellSplit) {
        return CHIP_DISCOUNT_COLOR.STRONG_GREEN;
    } else if ((hasQuantityDiscount || hasBonusAndVolDisc) && checked) {
        return CHIP_DISCOUNT_COLOR.GREEN;
    } else {
        return CHIP_DISCOUNT_COLOR.ORANGE;
    }
};
