import { createContext, useContext } from 'react';
// hooks
import { usePrioritiesPercentagesCeLogic } from './hooks';

type ContextProps = {
    data: any;
    error: null | string;
    isLoading: boolean;
    refetch: () => Promise<void>;
    serviceId: number;
};

const defaultValues: ContextProps = {
    data: {},
    error: null,
    isLoading: false,
    refetch: () => null,
    serviceId: 1,
};

type Props = {
    children: React.ReactNode;
};

const PrioritiesPercentagesCeContext = createContext<ContextProps>(defaultValues);

export const PrioritiesPercentagesCeProvider = ({ children }: Props) => {
    const contextValue = usePrioritiesPercentagesCeLogic();

    return (
        <PrioritiesPercentagesCeContext.Provider value={contextValue}>
            {children}
        </PrioritiesPercentagesCeContext.Provider>
    );
};

export const usePrioritiesPercentagesCeContext = () => useContext(PrioritiesPercentagesCeContext);
