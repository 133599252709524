import React from "react";
import { Col } from "rsuite";
import DetailsDeliveries from "../DeliveriesCard/DetailsDeliveries";
import { IDeliveriesDetails } from "./DeliveriesDetails.interface";
import style from "./DeliveriesDetails.module.css";
import { DeliveriesCard } from 'components/Orders/OrderCards/DeliveriesDetails/DeliveriesCard';
import { EX4780 } from "config/flags";
import { useTranslation } from "react-i18next";

function DeliveriesDetails({ details, deliveries }: IDeliveriesDetails) {
  const { t } = useTranslation()
  return (
    <section className={style["container-out-of-orders"]}>
      <div className={style["container-banner"]} style={{ paddingBottom: EX4780 ? 50 : '' }}>
        <Col xs={24}>
          <DeliveriesCard deliveries={deliveries} />
          <div className={style["margin"]}></div>
          {deliveries?.drug_manufacturer && (
            <DetailsDeliveries
              order_deliveries={deliveries}
              type={deliveries?.delivery_type}
              deliveryDetail={true}
            />
          )}
          <div className={style['container-disclaimer']}>
            * { t('disclaimerOrder') }
          </div>
        </Col>
      </div>
    </section>
  );
}

export default DeliveriesDetails;

