import { useEffect, useState } from 'react';
import { t } from 'i18next';

export const usePasswordConditionWithItems = ({ password, callbackValidation }) => {
    const [validLength, setValidLength] = useState<boolean>(false);
    const [hasUppercase, setHasUppercase] = useState<boolean>(false);
    const [hasNumber, setHasNumber] = useState<boolean>(false);

    const passwordCondition = [
        { name: t('login.login13'), control: validLength },
        { name: t('login.login14'), control: hasUppercase },
        { name: t('login.login15'), control: hasNumber },
    ];

    const checkPassword = (password: string, callbackValidation: (v: boolean) => void) => {
        const isValidPassowrd = password?.length >= 8 && /[A-Z]/.test(password) && /\d/.test(password);
        setValidLength(password?.length >= 8);
        setHasUppercase(/[A-Z]/.test(password));
        setHasNumber(/\d/.test(password));
        callbackValidation && callbackValidation(isValidPassowrd);
    };

    useEffect(() => {
        checkPassword(password, callbackValidation);
    }, [password]);

    const iconPassword = (control) => {
        return !!control;
    };

    return {
        passwordCondition,
        iconPassword,
    };
};
