export const auxiliary = () => {
    /**
     * Filters errors by a specific code and extracts names.
     *
     * @param {Array} errors - The list of errors to filter.
     * @param {number} code - The error code to filter by.
     * @return {Object} An object containing:
     *                  - filteredErrors: An array of errors filtered by the specified code.
     *                  - names: A string of the filtered error names, separated by commas.
     */
    const getFilteredErrorsAndNames = (errors, code) => {
        const filteredErrors = errors?.filter((elem) => elem.error_type?.id === code) || [];
        const names = filteredErrors.map((item) => item.name).join(', ');
        const quantity = filteredErrors.length

        return { filteredErrors, names,quantity };
    };
    
    return {
        getFilteredErrorsAndNames,
    };
};
