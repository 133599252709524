// components
import { ExAutocompleteGmaps } from '@commons/EXComponentsLibrary';
import { FieldWithIcon, FieldSelectCountries, FieldWithMasked, FieldFormControl, FieldControlLabel } from '.';
// interface
import { IFormField, IState } from '../Suppliers.interface';
// rsuite
import { Col, Form } from 'rsuite';
// css
import style from './RenderFormField.module.css';
// dayjs
import dayjs from 'dayjs';
import 'dayjs/locale/es'; // importar el idioma español
dayjs.locale('es');

const RenderFormFields = (
    fields: IFormField[],
    key: string,
    handlerChangeInput,
    state: IState,
    countries: any,
    handlerSelectAddress,
) => {
    // required inputs
    const requiredSteps = ['step1', 'step2'];
    const COLS = 24;

    return fields.map((field, index) => {
        let inputComponent = null;
        switch (field.type) {
            case 'withIcon':
                inputComponent = <FieldWithIcon state={state} field={field} handlerChangeInput={handlerChangeInput} />;
                break;

            case 'select':
                inputComponent = (
                    <FieldSelectCountries
                        field={field}
                        state={state}
                        handlerChangeInput={handlerChangeInput}
                        countries={countries}
                    />
                );
                break;
            case 'autocomplete':
                inputComponent = (
                    <ExAutocompleteGmaps
                        label=""
                        name={field.name}
                        isRequired={false}
                        placeholder="Calle + número"
                        error={state.errorsForms[field.name]}
                        onSelect={handlerSelectAddress}
                        country={!!state.modifiedFields ? state.modifiedFields['country_name'] : state.country}
                        value={state.form['country_name'] === null ?  '' : state.form[field.name]}
                        maxWidth
                        disabled={state.form['country_name'] === null ? true : false}
                    />
                );
                break;
            case 'withMask':
                inputComponent = (
                    <FieldWithMasked field={field} handlerChangeInput={handlerChangeInput} state={state} />
                );
                break;

            default:
                inputComponent = (
                    <FieldFormControl
                        field={field}
                        handlerChangeInput={handlerChangeInput}
                        keyField={key}
                        requiredSteps={requiredSteps}
                        state={state}
                    />
                );
                break;
        }

        return (
            <Col key={index} xs={field.span} className={field.span === COLS ? style['col-1'] : style['col-2']}>
                <Form.Group>
                    <FieldControlLabel field={field} requiredSteps={requiredSteps} fieldKey={key} />
                    {inputComponent}
                </Form.Group>
            </Col>
        );
    });
};

export default RenderFormFields;
