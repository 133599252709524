// react
import { useState, useEffect } from 'react';
import { t } from 'i18next';
// interface
import { StatusCdo } from 'utils/interfaces';
// context
import { useModalCdOAlert } from 'context/alertModalCdo/alertModalCdo';
// rsuite
import { Modal, Button, Badge, Loader } from 'rsuite';
// style
import style from './AlertCdoModal.module.css';
// icon
import PlusIcon from '@rsuite/icons/Plus';
import CheckIcon from '@mui/icons-material/Check';
import { EX5057 } from 'config/flags';

const AlertCdOModal = ({ request, recordset, posRequire, loading, user, setHasSeenModalCDO }) => {
    // context
    const { isModalOpen, closeModal } = useModalCdOAlert();
    // state
    const [isRequired, setIsRequired] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    //init
    useEffect(() => {
        if (!!posRequire && posRequire?.cdo_status_id === StatusCdo.PENDING) {
            setIsRequired(true);
        }
    }, [posRequire?.cdo_status_id]);
    useEffect(() => {
        if (!!recordset && recordset.cdo_status_id === StatusCdo.PENDING) {
            let clientInfo = [];
            clientInfo?.push({
                idClient: user?.client?.id,
                idUser: user?.id,
                idPos: posRequire?.id,
                seen: true,
            });
            setIsSuccess(true);
            setTimeout(() => {
                setHasSeenModalCDO(true);

                localStorage.setItem('alertModalCDO', JSON.stringify(clientInfo));
                closeModal();
            }, 3000);
        }
    }, [recordset.cdo_status_id]);

    // close Modal and localstorage
    const closeAlertModal = () => {
        let clientInfo = [];
        clientInfo?.push({
            idClient: user?.client?.id,
            idUser: user?.id,
            idPos: posRequire?.id,
            seen: true,
        });
        closeModal();
        setHasSeenModalCDO(true);
        localStorage.setItem('alertModalCDO', JSON.stringify(clientInfo));
    };
    return (
        <Modal open={isModalOpen} className={style['modal']}>
            <Modal.Header closeButton={false} className={style['modal-header']}>
                <div className={style['container-image-modal']}>
                    <img src="/assets/images/oferta-skeleton.png" />
                </div>
            </Modal.Header>
            <Modal.Body className={style['body-modal']}>
                <div>
                    <Badge content="Nuevo" className={style['badge-modal']} />
                </div>
                <p className={style['title-modal']}>Te presentamos Central de ofertas</p>
                <p className={style['subtitle-modal']}>
                    Vas a poder acceder a una red exclusiva de ofertas y productos para vos, para que ahorres aún más en
                    cada compra.
                </p>
            </Modal.Body>
            <Modal.Footer>
                {!!isSuccess && (
                    <Button block className={style['success-button-active']}>
                        <CheckIcon />
                    </Button>
                )}

                {!isSuccess && (
                    <>
                        <Button
                            onClick={() => {
                                !isRequired && posRequire?.cdo_status_id === StatusCdo.DISABLED
                                    ? request()
                                    : closeAlertModal();
                            }}
                            block
                            className={
                                `${!isRequired && 'button__request-offer-center-1'} ` +
                                `${
                                    loading
                                        ? style['action-button-modal-loading']
                                        : isSuccess
                                        ? style['success-button-active']
                                        : style['action-button-modal']
                                }`
                            }
                        >
                            {isRequired ? (
                                <p className={style['text-button-active']}>Entendido</p>
                            ) : (
                                <>
                                    {!!loading ? (
                                        <Loader />
                                    ) : !!isSuccess ? (
                                        <CheckIcon />
                                    ) : (
                                        <>
                                            <PlusIcon className="button__request-offer-center-1" />
                                            <p
                                                className={`button__request-offer-center-1 ${style['text-button-active']}`}
                                            >
                                                Solicitar
                                            </p>
                                        </>
                                    )}
                                </>
                            )}
                        </Button>
                        {isRequired && (
                            <div className={style['required-container-modal']}>
                                <p>Central de Ofertas ya fue solicitado</p>
                            </div>
                        )}
                        {!isRequired && (
                            <Button
                                onClick={() => closeAlertModal()}
                                appearance="link"
                                block
                                className="link__not-now-offer-center"
                            >
                                {!!EX5057 ? t('walkthrough.labelNotNow') : 'Ahora no'}
                            </Button>
                        )}
                    </>
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default AlertCdOModal;
