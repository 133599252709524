// MUI
import Skeleton from '@mui/material/Skeleton';
// interface
import { ISkeletons } from './Skeletons.interface';

const Skeletons = ({ animation, variant, width, height, bgcolor }: ISkeletons) => {
  return (
    <Skeleton
      animation={animation}
      variant={variant}
      width={width}
      height={height}
      sx={{ bgcolor: bgcolor }}
    />
  );
};

export default Skeletons;
