import {
    InstitutionalHeader,
    PaymentForm,
    InstitutionalLayout,
    ErrorModal,
    PaymentFooter,
    PaymentError,
} from '@commons/components';
import Image from 'next/image';
import { SuccessView } from '.';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { healthcheckHash } from 'api/payment/payment';
import style from './Payments.module.css';
const Payments = () => {
    const router = useRouter();

    // Estado
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showModal, setShowModal] = useState<{ state: boolean; message: string }>({ state: false, message: '' });
    const [healthcheck, setHealtcheck] = useState<any>({});
    const [isHealthcheckLoaded, setIsHealthcheckLoaded] = useState(false);

    // Obtener hash desde la URL
    const params = new URLSearchParams(router?.asPath.split('?')[1]);
    const hash = params.get('hash');
    const customerObject = hash ? JSON.parse(atob(hash)) : {};

    // Llamada API de healthcheck
    const postHealthcheck = async () => {
        if (!hash) {
            // No hacemos nada si no hay hash, el loader sigue activo
            return;
        }

        const payload = {
            hash: hash,
        };

        try {
            const response = await healthcheckHash(payload);
            setHealtcheck(response?.data);
        } catch (ex: any) {
            console.error('Error al realizar el healthcheck:', ex.message);
            setShowModal({ state: true, message: 'No se pudo validar el hash. Intenta nuevamente más tarde.' });
        } finally {
            setIsHealthcheckLoaded(true); // Marcar que el healthcheck ha terminado
        }
    };

    // Ejecutar healthcheck solo cuando el hash esté disponible
    useEffect(() => {
        if (hash) {
            postHealthcheck();
        }
        // Si no hay hash, el loader sigue visible
    }, [hash]);

    const showSuccessView = isSubmitting || !!healthcheck?.has_subscription;

    return (
        <InstitutionalLayout>
            <InstitutionalHeader>
                <Image
                    src="/assets/images/institutional_logo.svg"
                    alt="institutional logo"
                    width={216.82}
                    height={28.89}
                    priority
                />
            </InstitutionalHeader>

            {isHealthcheckLoaded ? (
                <>
                    {showSuccessView && <SuccessView />}

                    {!healthcheck?.hash_is_expired && !healthcheck?.has_subscription && !showSuccessView && (
                        <PaymentForm
                            setIsSubmitting={setIsSubmitting}
                            customerObject={customerObject}
                            hash={hash}
                            setShowModal={setShowModal}
                        />
                    )}

                    {!!healthcheck?.hash_is_expired && !healthcheck?.has_subscription && !showSuccessView && (
                        <PaymentError />
                    )}
                </>
            ) : (
                // Mostrar un indicador de carga mientras se espera la respuesta
                <div className={style['loader-text']}>Cargando...</div>
            )}

            <PaymentFooter />

            <ErrorModal
                open={showModal.state}
                handleClose={setShowModal}
                title="Error"
                text={showModal.message}
                buttonText="Entendido"
            />
        </InstitutionalLayout>
    );
};

export default Payments;
