import React from 'react';
import { t } from 'i18next';
// UTILS
import { renderValue, renderWithoutStockText } from '../utils';
import { COUNTRY } from 'utils/country';
import useCurrencyFormatter from 'utils/hooks/useCurrencyFormatter/useCurrencyFormatter';

const PriceValue = ({ product, userLocale, chipColor, unitPrice }): JSX.Element => {
    const typeRenderFormat = [COUNTRY.ES];
    /* #region formatter currency */
    const { formatCurrency } = useCurrencyFormatter();
    const { detailedValues } = formatCurrency({
        value: product && !!unitPrice ? renderValue(product) : 0,
        digitsValidation: 7,
    });
    const { mainInteger: integer, decimalSeparator, currencySymbol: symbol, cents: decimal } = detailedValues;
    /* #endregion */

    return !!product ? (
        typeRenderFormat.includes(userLocale) ? (
            <>
                <span>{`${integer}${decimalSeparator}`}</span>
                <span style={{ fontSize: 12, alignSelf: 'baseline' }}>{decimal}</span>
                <span>{symbol}</span>
            </>
        ) : (
            <>
                <span>{symbol}</span>
                <span>{`${integer}${decimalSeparator}`}</span>
                <span style={{ fontSize: 12, alignSelf: 'baseline' }}>{decimal}</span>
            </>
        )
    ) : (
        <>{renderWithoutStockText(t)}</>
    );
};

export default PriceValue;
