import React from "react";
import cn from "classnames";
import {
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
} from "@material-ui/core";
import useStyles from "./TextField.styles";

export interface TextFieldProps extends Omit<MuiTextFieldProps, "variant"> {
  dirty?: boolean;
  endAdornment?:any;
}

function TextField({ dirty, endAdornment = null,...props }: TextFieldProps) {
  const classes = useStyles();
  
  return (
    <div style={{display:'flex', position:'relative'}}>
      <MuiTextField
        className={cn(classes.root, { [classes.dirty]: dirty })}
        fullWidth
        inputProps={{
          autocomplete: 'new-password',
          form: {
            autocomplete: 'off',
          },
        }}
        variant="outlined"
        size="small"
        {...props}
      />
      <div style={{position:'absolute', right:0, bottom:20}}>
      {endAdornment}
      </div>
    </div>
    );
}

export default TextField;
