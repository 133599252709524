export const handlerAddProductCart = (item, context, flag) => {
  if (flag === 'input') {
    context(item);
  } else {
    const newItem = {
      _source: {
        ean: [item.ean[0]],
        description: item.description,
      },
    };
    context(newItem);
  }
};
