import { useState } from 'react';

interface useSkeletonRecommendedProps {
    initialState: boolean;
}

// Custom hook para manejar el estado de skeleton
const useSkeletonRecommended = ({ initialState }: useSkeletonRecommendedProps) => {
    const [skeleton, setSkeleton] = useState<boolean>(initialState);

    return {
        skeleton,
        setSkeleton,
    };
};

export default useSkeletonRecommended;
