// COMPONENTS
import { YouCanSave } from './YouCanSave';
import { YouAreSaving } from './YouAreSaving';
// contexts
import { useDrugManufacturersContext, useProductsContext } from 'context';
// UTILS && HOOKS
import { useUser } from 'utils/hooks';
import { productHasCheckedQuantity } from 'utils/productComparison/productHasCheckedQuantity';
// STYLES
import styles from './TableSaving.module.css';

const TableSaving = ({
    skeleton,
    orderId,
    minimunPurchase,
    handlerModalMinimunPurchase,
    unselectedProductNotificationVisibility,
    withoutRecommendations,
    uncheckedProductsByDrugNamesMinOrMax,
    handlerModalMinMaxQuantity,
}) => {
    const { products } = useProductsContext();
    const { enabledOnOff, isEnabledDrugManufacturer } = useDrugManufacturersContext();

    const { user } = useUser({});
    const EX6144 = user?.EX6144;
    const EX5937 = user?.EX5937;
    const GRID_ROW_SAVING = EX6144 ? (withoutRecommendations ? 4 : 5) : withoutRecommendations ? 5 : 6;

    return (
        <div className={styles['grid-saving']} style={{ gridRow: GRID_ROW_SAVING }}>
            {/* left box */}
            <YouAreSaving skeleton={skeleton} />
            {/* right box */}
            <YouCanSave
                orderId={orderId}
                minimunPurchase={minimunPurchase}
                handlerModalMinimunPurchase={handlerModalMinimunPurchase}
                allProductHasChecked={
                    productHasCheckedQuantity(products, enabledOnOff, isEnabledDrugManufacturer, EX5937)?.length === 0
                }
                unselectedProductNotificationVisibility={unselectedProductNotificationVisibility}
                uncheckedProductsByDrugNamesMinOrMax={uncheckedProductsByDrugNamesMinOrMax}
                handlerModalMinMaxQuantity={handlerModalMinMaxQuantity}
                skeleton={skeleton}
            />
        </div>
    );
};

export default TableSaving;
