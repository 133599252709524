// style
import style from './VerticalLineDivider.module.css';

const VerticalLineDivider = () => {
  return (
    <hr className={style['vertical-line']} />
  )
}

export default VerticalLineDivider
