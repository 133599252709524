// react
import { useEffect, useState } from 'react';
// components
import { ActionCheckbox } from '.';
// style
import style from './FiltersResultsBody.module.css';
// mui
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
// utils
import { auxiliaryFunctions } from './utils';
// hooks
import { useUser } from 'utils/hooks';
import { ExButton } from '@commons/EXComponentsLibrary';
import useCheckboxHandler from './ActionCheckbox/hooks/useCheckboxHandler';
import { useTranslation } from 'react-i18next';

const FiltersResultsBody = ({ recordset, resultsCount } : {recordset: unknown, resultsCount: number}) => {
    const { t } = useTranslation()
    // hook
    const { user } = useUser({});
    const { onCheckedAction } = useCheckboxHandler();
    // state
    const [expanded, setExpanded] = useState({});

    // logic
    const actions = auxiliaryFunctions();

    return (
        <div className={style['container-body']}>
            {Object.entries(recordset).map(([key, values]: any) => {
                
                const maxItems = expanded[key] ? values.buckets.length : 5;
                const title = actions.keyLanguages(key, user);

                return (
                    <div key={key}>
                        <p className={style['label-filters']}>{title}</p>
                        <div className={style['container-checkbox']}>
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                {values.buckets.length > 0 ? values.buckets.slice(0, maxItems).map((value) => {
                                    return (
                                        <ActionCheckbox 
                                             key={value.key}
                                             value={value.key} 
                                             qty={value.doc_count} 
                                             tag={key} 
                                             onChecked={onCheckedAction}
                                        />
                                    );
                                }) : <ActionCheckbox qty={0} onChecked={() => {}} value={t('shoppingCart.placeholder.noResults')} tag='' disabled />}
                            </div>
                        </div>
                        <div className={style['container-action']}>
                            {values.buckets.length > 5 && (
                                <ExButton
                                    appearance="link"
                                    onClick={() => actions.toggleExpand(key, expanded, setExpanded)}
                                    EndIconComponent={expanded[key] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                >
                                        {expanded[key] ? 'Ver menos' :  'Ver más'}
                                </ExButton>
                            )}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default FiltersResultsBody;
