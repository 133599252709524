import { useEffect, useCallback } from 'react';
import { t } from 'i18next';
// context
import { ItemCheckbox, useProductComparison } from 'context/product-comparison.context';
// rsuite
import { Dropdown, Checkbox } from 'rsuite';
import style from './ToolbarCEDropdown.module.css';
import { useUser } from 'utils/hooks';
import { EX5057 } from 'config/flags';
import { usePointOfSale } from 'context';
import { isZettiFormat } from 'utils/pointOfSale';

const ToolbarCEDropdown = ({ setOpenDropdown, openDropdown }) => {
    const { user } = useUser({});
    // validation is zetti
    const { pointOfSale: pos } = usePointOfSale();
    const isZetti = isZettiFormat(pos);
    const EX4927 = user?.EX4927;
    const EX4800 = user?.EX4800;

    const { showModalPriceWithOutSelection, filters, setFilters } = useProductComparison();

    useEffect(() => {
        if (filters.length === 0) {
            let newFilters: ItemCheckbox[] = [
                {
                    label: EX5057 ? t('efficientPurchasing.comparative.labelCellOutOfStock') : 'Sin Stock',
                    id: 1,
                    name: 'Sin stock',
                    isChecked: false,
                },
                {
                    label: EX5057 ? t('efficientPurchasing.comparative.withDiscount') : 'Con Descuento',
                    id: 2,
                    name: 'Con descuento',
                    isChecked: false,
                },
            ];

            if (EX4800 && !isZetti) {
                newFilters.push({ label: 'Con Genéricos', id: 3, name: 'Con genéricos', isChecked: false });
            }

            setFilters(newFilters);
        }
            
        
    }, [filters]);
    

    const handleBlurModal = useCallback(() => {
        return showModalPriceWithOutSelection;
    }, [showModalPriceWithOutSelection]);

    const handleCheckItem = (itemId) => {
        setFilters((prevFilters) => {
            return prevFilters.map((filter) => {
                if (filter.id === itemId) {
                    const updatedFilter = { ...filter, isChecked: !filter.isChecked };
                    return updatedFilter;
                }
                return filter;
            });
        });
    };

    const checkedCount = filters.filter((filter) => filter.isChecked)?.length;

    return (
        <>
            {/* Este blur se elimina con rediseño v3. Quitar al remover la FF */}
            {!EX4927 && handleBlurModal() && <div className={style['blur-tooltip-container']}></div>}
            <Dropdown
                title={
                    <p className={style['dropdown-label']}>
                        {EX5057 ? t('efficientPurchasing.comparative.labelFilters') : 'Filtros'} ({checkedCount})
                    </p>
                }
                icon={<img src="/assets/images/tune.svg" />}
                className={style['dropdown']}
                noCaret={true}
                placement="bottomStart"
                onToggle={(e) => {
                    setOpenDropdown(e);
                }}
            >
                <div className={style['checkbox-group']}>
                    {filters?.map((item) => {
                        return (
                            <Checkbox
                                key={item.id}
                                className={style['item-checkbox']}
                                onChange={() => handleCheckItem(item.id)}
                                value={item.id}
                                checked={item.isChecked}
                                style={{
                                    borderRadius: item.isChecked ? '8px' : '',
                                    backgroundColor: item.isChecked ? '#DCEDFF' : '',
                                }}
                            >
                                {item.label}
                            </Checkbox>
                        );
                    })}
                </div>
            </Dropdown>
        </>
    );
};

export default ToolbarCEDropdown;
