import React from 'react';
// UTILS
import { COUNTRY } from 'utils/country';
// INTERFACES
import { CellContractedProps } from './CellContracted.interface';
import { Value } from '../Value';
import { EDrugManufacturerTypeId } from 'utils/enums/drugmanufacturerTypeId/DrugManufacturerTypeId';
import { CellButtonVendor } from '../../CellButtonVendor';

const CellContracted = ({
    user,
    min_purchase,
    subTotalValueColumn,
    skeleton,
    userLocaleCountryCode,
    drugManufacturerEnabled,
    t,
    drugManufacturerTypeId,
    drugManufacturerId,
    vendor_accepted,
    productQuantityByDrugManufacturer
}: CellContractedProps) => {
    const isDisabled = !drugManufacturerEnabled && !skeleton;

    return (
        <>
            {drugManufacturerTypeId === EDrugManufacturerTypeId.vendor && !vendor_accepted &&!!user?.EX6017 ? (
                <CellButtonVendor drugManufacturerId={drugManufacturerId} />
            ) : (
                <>
                    {/* render value */}
                    <Value
                        isDisabled={isDisabled}
                        user={user}
                        min_purchase={min_purchase}
                        subTotalValueColumn={subTotalValueColumn}
                        t={t}
                        typeRenderFormat={userLocaleCountryCode === COUNTRY.ES}
                        skeleton={skeleton}
                        productQuantityByDrugManufacturer={productQuantityByDrugManufacturer}
                    />
                </>
            )}
        </>
    );
};

export default CellContracted;
