import { IOptionsChart } from "./OptionsChart.interface";

export const Options = ({ drugmanufacturers }: IOptionsChart) => {
  const options = {
    chart: {
      type: "bar",
      renderTo: "container",
      margin: 0,
      gridLineWidth: 0,
      borderRadius: 4,
      marginBottom: 24,
      events: {
        load() {
          const chart = this;
          chart.yAxis[0].update({
            visible: false,
          });
          chart.xAxis[0].update({
            visible: false,
          });
        },
      },
    },
    tooltip: {
      headerFormat: null,
      valueSuffix: "%",
    },

    xAxis: {
      groupPadding: 0,
    },
    yAxis: {
      min: 0,
      title: {
        text: null,
      },
      gridLineWidth: 0,
      labels: {
        enabled: true,
      },
      minorTickLength: 0,
      tickLength: 0,
      minPadding: 0,
      maxPadding: 0,
    },
    layout: {
      padding: {
        left: -10,
      },
    },
    credits: {
      enabled: false,
    },
    legend: {
      reversed: true,
      align: 'left',
    },
    title: {
      text: "",
    },
    plotOptions: {
      series: {
        stacking: "percent",
        minPointLength: 1,
        pointPadding: 0,
        groupPadding: 0.2,
        pointWidth: 0,
        cursor: "pointer",
        animation: {
          duration: 2000,
        },
      },
      bar: {
        borderRadius: 8,
        groupPadding: 0.1,
        pointWidth: 20,
        pointPadding: 0,
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          formatter: function () {
            const value = this.y + "%";
            return value;
          },
          style: {
            color: "white",
            fontSize: "12px",
            textOutline: "none",
          },
          align: 'center'
        },
      },
    },
    series: drugmanufacturers,
  };

  return options;
};

