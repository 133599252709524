export const fullScreen = (setIsFullScreen, isFullScreen) => {
    const doc = window.document as any;

    const toggleFullScreen = () => {
        if (!isFullScreen) {
            if (doc.documentElement.requestFullscreen) {
                doc.documentElement.requestFullscreen();
            } else if (doc.documentElement.mozRequestFullScreen) {
                /* Firefox */
                doc.documentElement.mozRequestFullScreen();
            } else if (doc.documentElement.webkitRequestFullscreen) {
                /* Chrome, Safari & Opera */
                doc.documentElement.webkitRequestFullscreen();
            } else if (doc.documentElement.msRequestFullscreen) {
                /* IE/Edge */
                doc.documentElement.msRequestFullscreen();
            }
        } else {
            if (doc.exitFullscreen) {
                doc.exitFullscreen();
            } else if (doc.mozCancelFullScreen) {
                /* Firefox */
                doc.mozCancelFullScreen();
            } else if (doc.webkitExitFullscreen) {
                /* Chrome, Safari & Opera */
                doc.webkitExitFullscreen();
            } else if (doc.msExitFullscreen) {
                /* IE/Edge */
                doc.msExitFullscreen();
            }
        }
        setIsFullScreen(!isFullScreen);
    };

    doc.addEventListener('fullscreenchange', () => {
        if (
            !doc.fullscreenElement &&
            !doc.mozFullScreenElement &&
            !doc.webkitFullscreenElement &&
            !doc.msFullscreenElement
        ) {
            setIsFullScreen(false);
        }
    });
    toggleFullScreen();
};
