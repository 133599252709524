import { DrugManufacturersId, StatusCdo } from 'utils/interfaces';

/* #region click in radio by cell */
export const handlerClickRadio = ({ e, cdo_status_id, openModal, onChecked }) => {
    if (cdo_status_id === StatusCdo.DISABLED) {
        openModal();
        return;
    } else {
        onChecked(DrugManufacturersId.CentralDeOfertas);
        return;
    }
};
/* #endregion */
