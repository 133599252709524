export const handleParseNumber = (number, setter, min = 1) => {
  if (+number >= min) {
    return setter(+number)
  } else {
    return;
  };
};

export const handlerInputValidation = (evt, setter) => {
  return setter(evt)
};

export const handlerCounterAddition = (evt, setter) => {
  return handleParseNumber(evt, setter);
};

export const handlerCounterSubtraction = (evt, setter, min) => {
  return handleParseNumber(evt, setter, min);
};

export const handlerCounterInput = (evt, setter) => {
  return handleParseNumber(evt, setter);
};