import { memo, useRef } from 'react';
// contexts
import { useDrugManufacturerWidgetContext, usePointOfSale } from 'context';
// components
import { Title } from './title';
import { Dropdowns } from './Dropdowns';
import { AddOfflineMessage } from './AddOfflineMessage';
import { DropdownSkeleton } from './Skeleton/DropdownSkeleton';
import { AddOfflineMessageSkeleton } from './Skeleton/AddOfflineMessageSkeleton';
// utils && hooks
import { isGestorERPFormat, isZettiFormat } from 'utils/pointOfSale';
// styles
import styles from './DrugManufacturerWidget.module.css';

const DrugManufacturerWidget = () => {
    const parentRef = useRef(null);
    const { fetching } = useDrugManufacturerWidgetContext();
    const { pointOfSale } = usePointOfSale();
    const isZetti = isZettiFormat(pointOfSale);
    const isGestorERP = isGestorERPFormat(pointOfSale);

    return (
        <div className={styles['container']}>
            {/* title */}
            <Title />
            {fetching ? (
                // skeleton when is fetching
                <>
                    <div className={styles['content']}>
                        {/* dropdowns */}
                        <DropdownSkeleton />
                    </div>
                    {/* footer */}
                    <AddOfflineMessageSkeleton />
                </>
            ) : (
                <div className={styles['content']} ref={parentRef}>
                    {/* dropdowns */}
                    <Dropdowns parentRef={parentRef} />
                    {/* footer */}
                    {!isZetti && !isGestorERP && <AddOfflineMessage />}
                </div>
            )}
        </div>
    );
};

export default memo(DrugManufacturerWidget);
